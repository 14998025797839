import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/BésameenlaBoca.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const BodaEDianaYUriel = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion:' nuestra boda',
    esposo: "Uriel",
    esposa: "Diana",
    fechaDeBoda: "27 JUL, 2024",
    fechaNewDate: "July 27, 2024 14:30:00 GMT+00:00",
    imgPortada: "https://e1.pxfuel.com/desktop-wallpaper/566/124/desktop-wallpaper-wedding-mariage.jpg",
    numeroNovia: "+528333851971",
    numeroNovio: "+528334314735",
    novios:true,
    tituloConfirmacion:(
      <div>
        Espero puedan confirmarnos su asistencia. <br/>
Agradecemos de antemano su apreciable compañía.
      </div>
    ),
    aviso:(
      <div className="text-start">
        Toma en cuenta: <br />
*Solo la novia va de blanco.  <br />
*Queremos recuerdos hermosos, cuida a los niños. <br />
*Permite al fotógrafo hacer su trabajo. <br />
*Si quieres llevar un detalle, tendremos un buzón. <br />
*Duerme suficiente un día antes. <br />
*Acompañanos y Diviertete."
      </div>
    )
  };
 
  const peltaColores = {
    color1: "#E8DCD0",
    color2: "#fff7db",
  };

  const datosSeccion2 = {
    titulo:'¡Nos Casamos!',
    descipcion:(
      <div>
        “Amar no es mirarse el uno al otro; es mirar juntos en la misma dirección” <br />- Antoine de Saint-Expupery -<br /> Nos complace que sean participes de esta unión
      </div>
    ),
    fechaNewDate: "July 27, 2024 14:30:00 GMT+00:00",
    fotoIzquierda: "https://images.vexels.com/media/users/3/190565/isolated/preview/03bbb1ac2345c3033524180044575849-parejas-casadas-silueta-matrimonio.png",
    fotoDerecha: "https://www.pnguniverse.com/wp-content/uploads/2021/05/Silueta-pareja-110b6156.png",
  };

  const datosInvitados ={
    titulo:'¡Has sido invitado a la boda de Jimena y Antonio!',
    entradasText:'disfrutar nuestro dia especial'
  }

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda:'Padres de la novia',
    tituloDerecha:'Madre del novio',
    esposaMadre: "Catalina Silva Hernández",
    esposaPadre: "Rogaciano Vargas Villanueva ✟",
    esposoMadre: "Rebeca Elizabeth Ávila Morales",
    esposoPadre: "",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 27 de Julio de 2024.",
    ceremoniaHora: "2:30 p.m.",
    ceremoniaNombreLugar: "Parroquia Santiago Apostol, Altamira Centro",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://www.google.com.mx/maps/place/Parroquia+Santiago+Ap%C3%B2stol/@22.3925799,-97.9392529,16.5z/data=!4m10!1m2!2m1!1sPARROQUIA+SANTIAGO+APOSTOL!3m6!1s0x85d7fd2325555555:0xabb6607b8ea1407d!8m2!3d22.3928046!4d-97.9362943!15sChpQQVJST1FVSUEgU0FOVElBR08gQVBPU1RPTFocIhpwYXJyb3F1aWEgc2FudGlhZ28gYXBvc3RvbJIBD2NhdGhvbGljX2NodXJjaJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VSU2QyWlVaRmxCRUFF4AEA!16s%2Fg%2F1tdp9zmr?entry=ttu",
    recepcionFecha: "Sábado, 27 de Julio de 2024.",
    recepcionHora: "5:00 p.m.",
    recepcionNombreLugar: "Casa De La Novia; Mariano Matamoros 2, Aldama",
    recepcionLugarMap:
      "",
    recepcionUrl: "https://www.google.com/maps/dir//22.8447687,-97.9047123/@22.8440645,-97.9069811,17z/data=!4m2!4m1!3e0?entry=ttu",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo ={
    fondoInvitados:'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
  }

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());


  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://png.pngtree.com/thumb_back/fw800/background/20230901/pngtree-two-wedding-rings-with-white-flowers-and-a-paper-image_13171289.jpg",
    "https://e1.pxfuel.com/desktop-wallpaper/566/124/desktop-wallpaper-wedding-mariage.jpg",
    "https://images.vexels.com/media/users/3/190565/isolated/preview/03bbb1ac2345c3033524180044575849-parejas-casadas-silueta-matrimonio.png",
    "https://www.pnguniverse.com/wp-content/uploads/2021/05/Silueta-pareja-110b6156.png",
    "https://img.freepik.com/fotos-premium/cierrese-arriba-anillos-oro-boda-interseccion-fondo-madera-blanco_102583-16.jpg?size=626&ext=jpg",
    // Add more image URLs as needed
  ];
  

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {

    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda
      datosSeccion1 ={datosSeccion1}
      audioFondo = {audioFondo}
      />

      <Contador
        peltaColores = {peltaColores}
        datosSeccion2 = {datosSeccion2}
      />

      <Padres
        peltaColores={peltaColores}
        datosSeccion3={datosSeccion3}
      />
      
      <Ceremonia
        peltaColores={peltaColores}
        datosSeccion4={datosSeccion4}
      />


<ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />

    </div>
  );
};
