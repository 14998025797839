import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./cancionXVmonserrat.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";

export const XvEstefaniaLira = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Monserrat Lira",
      fechaDeBoda: "23 MARZO, 2024",
      fechaNewDate: "March 23, 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
      numeroNovia: "4494376837",
      numeroNovio: "4491516931",
    };
  
    const peltaColores = {
      color1: "#fff5ff",
      color2: "#fffbff",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Monserrat Lira!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí Y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "March 23, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
      fotoDerecha: "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Magdalena Vázquez Prieto",
      esposaPadre: "Adolfo Lira González",
      esposoMadre: "Rosio Garibay Cervantes",
      esposoPadre: "Juan Carlos Lira Vázquez",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 23 de Marzo de 2024.",
      ceremoniaHora: "6:00 p.m.",
      ceremoniaNombreLugar: "Parroquia del Divino Salvador",
      ceremoniaLugarMap:
        "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
      ceremoniaUrl:
        "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
      recepcionFecha: "Sábado, 23 de Marzo de 2024.",
      recepcionHora: "8:00 p.m.",
      recepcionNombreLugar: "El Herradero Jardín y Eventos",
      recepcionLugarMap:
        "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
      recepcionUrl:
        "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "6:00 pm ceremonia religiosa ",
      baileXV: "8:30 pm Baile de XV años",
      cena: "9:30 Disfruta de una deliciosa cena",
      recepcion: "8:00 pm recepción ",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "10:00 pm Sácale brilló a la pista 💃🏻🕺🏽",
    };
  
  
    const datosSeccion7 = {
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    };
  
    const datosSeccion8 = {
      descripcion:
        "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirme escoger mi regalo.",
      linkAmazon:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      linkLiverpool:
        "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    };
  
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
  
  
    const images = [
      "https://i.postimg.cc/MZ3t62Ly/435af0ee-b3c0-425b-b966-446fe26e78d5.jpg",
      "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
      "https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg",
      "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
      "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg"
      // Add more image URLs as needed
    ];
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
    }
  
    const names = [
            { name: "Angel Jaimes", guess: "2" },
            { name: "Adolfo Lira", guess: "5" },
            { name: "José Lino Macías", guess: "5" },
            { name: "Marco Antonio Vazquez", guess: "4" },
            { name: "Ramón Álvarez", guess: "3" },
            { name: "Alejandro Tapia", guess: "5" },
            { name: "Marielena Lira", guess: "5" },
            { name: "Adriana Solis", guess: "2" },
            { name: "Gaby Solis", guess: "3" },
            { name: "Jorge García", guess: "5" },
            { name: "Manuela González", guess: "2" },
            { name: "Óscar Yáñez", guess: "8" },
            { name: "Ricardo Lira", guess: "6" },
            { name: "Israel y Pris", guess: "2" },
            { name: "Gabriel Rodriguez", guess: "4" },
            { name: "Cristina Lira", guess: "2" },
            { name: "Adolfo Rodríguez", guess: "4" },
            { name: "Ismael Lira", guess: "4" },
            { name: "Andrés Lira", guess: "2" },
            { name: "Fernando Lira", guess: "4" },
            { name: "Rafael Luna", guess: "6" },
            { name: "Fernando Lira Ronces", guess: "2" },
            { name: "Juan Covarrubias", guess: "3" },
            { name: "Isela Lira", guess: "3" },
            { name: "Juan Mario Gutiérrez", guess: "2" },
            { name: "Juan Carlos Lira v", guess: "8" },
            { name: "José Manuel Lira", guess: "6" },
            { name: "José Antonio del Toro", guess: "2" },
            { name: "Carlos Lira", guess: "3" },
            { name: "Leonardo Lira", guess: "3" },
            { name: "Alfonso Martínez", guess: "3" },
            { name: "Gerardo Mendiola", guess: "4" },
            { name: "Eder Cardona", guess: "6" },
            { name: "Zaira Martínez", guess: "2" },
            { name: "Edgar Báez", guess: "2" },
            { name: "Natalie", guess: "2" },
            { name: "Verónica Olvera", guess: "2" },
            { name: "Alberto Martínez", guess: "2" },
            { name: "Cesar Núñez", guess: "6" },
            { name: "Karla Medina", guess: "3" },
            { name: "Eliza Flores", guess: "3" },
            { name: "Rafael luna", guess: "6" },
            { name: "Lola Serna", guess: "3" }
    ];
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
  
      const findName = names.find((e) => e.name == nameId);
      console.log(findName?.name);
  
      if (findName) {
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
      } else {
        window.location.href = "/";
      }
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Invitados
          NameInvitation={NameInvitation}
          numGuess={numGuess}
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
          datosInvitados={datosInvitados}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiPartyPopper  className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE DE XV AÑOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baileXV}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />
         
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3700.913330480417!2d-102.29668369999999!3d21.9378831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efa1a0aac581%3A0x2630401c2c5a339e!2sParroquia%20del%20Divino%20Salvador!5e0!3m2!1ses-419!2smx!4v1707959030520!5m2!1ses-419!2smx" width="100%" height="100%" className="fixed"></iframe>
              <a
                href={datosSeccion4.ceremoniaUrl}
                class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
              >
                Mapa de la Ceremonia
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div
              className="flex flex-col justify-end w-[90%] sm:mt-16 md:mt-0 rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3700.2236698011175!2d-102.3057911!3d21.9643771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429e561c6edc0a5%3A0x831a126aad45ec50!2sEl%20Herradero%20Jardin%20%26%20Eventos!5e0!3m2!1ses-419!2smx!4v1707958545444!5m2!1ses-419!2smx" width="100%" height="100%" className="fixed"></iframe>
              <a
                href={datosSeccion4.recepcionUrl}
                class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
              >
                Mapa de la Recepción
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:px-3 sm:py-10 md:p-0 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://static.vecteezy.com/system/resources/previews/011/794/068/non_2x/dollar-bills-falling-down-background-overlay-png.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
             
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://static.vecteezy.com/system/resources/previews/011/794/068/non_2x/dollar-bills-falling-down-background-overlay-png.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="md:flex sm:hidden flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              
            </div>
          </div>
        </div>
      </div>
  
        <Confirmacion
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
