import AOS from "aos";
import React, { useEffect, useState } from "react";

export const ContadorInfantilComponent = ({ peltaColores, datosSeccionContador }) => {
  const targetDate = new Date(
    datosSeccionContador.fechaNewDate || ""
  ).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionContador.textColor
          ? datosSeccionContador.textColor
          : "",
      }}
      className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col pt-[10vh] w-full items-center">
        <h1
          className="font-serif  sm:text-[52px] md:text-[92px] "
          data-aos="flip-up"
        >
          {datosSeccionContador.titulo
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
        </h1>
        <br />
        <p
          className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
          data-aos="flip-up"
        >
          {datosSeccionContador.descipcion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
        </p>
        <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
          <div
            style={{
              backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "45vh", // Ajusta la altura del contenedor
              display: "flex",
              backgroundPosition: "center center",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="rounded-[30%]"
            data-aos="fade-right"
          ></div>
          {datosSeccionContador.contador ? (
            <div
              className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
              data-aos="fade-up"
              style={{
                border: "15px solid transparent",
                padding: "15px",
                borderImage: datosSeccionContador.marcoEnable
                  ? "url('https://i.postimg.cc/zLZTRCYR/Marco2-2.png')"
                  : "",
                borderImageSlice: "46",
                borderImageWidth: "round",
                zIndex: 2,
              }}
            >
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.days}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Días</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.hours}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.minutes}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.seconds}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
              </div>

            </div>
          ) : (
            <div></div>
          )}

          <div
            style={{
              backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "45vh", // Ajusta la altura del contenedor
              display: "flex",
              backgroundPosition: "center center",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="rounded-full sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
            data-aos="fade-left"
          ></div>
        </div>
      </div>
    </div>
  );
};
