import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./DulceMontserrat.mp3";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { Vestimenta } from "../../../components/Vestimenta";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";

export const XvDulceMontserrat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const peltaColores = {
    color1: "#b3f2ff",
    color2: "#ffd7fa",
    messageVestimenta: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Vestimenta Casual",
    messageVestimenta: "",
    urlVestimentaMujer: "a",
    urlVestimentaHombre: "a",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPortada = {
    cancion: audioFondo,
    imgPortada: "https://i.postimg.cc/K8qtJFd6/IMG-20240416-WA0075-1.jpg",
    nombre: "Dulce Montserrat",
    fechaDeBoda: "1 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+527777893480",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
    fechaNewDate: "June 1, 2024 14:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/DzSLNHh8/IMG-20240416-WA0076-2.jpg",
    fotoDerecha: "https://i.postimg.cc/bvBfY7b1/IMG-20240418-WA0028.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Edith Martínez",
    padre: "Roberto Zarco",
    madrina: "Patricia Martínez",
    padrino: "Maximiliano Calixto",
    madrina2: "Benigno Martinez",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 1 de Junio de 2024.",
    ceremoniaHora: "2:00 p.m.",
    ceremoniaNombreLugar: "Parroquia Santa María de Guadalupe",
    ceremoniaLugarMap: "C. Amatista, La Joya, 62735 La Joya, Mor.",
    ceremoniaUrl: "https://maps.app.goo.gl/a7YH3wjBCEqE8FfX7",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 1 de Junio de 2024.",
    recepcionHora: "4:00 p.m.",
    recepcionNombreLugar: "Salon el Plateado",
    recepcionLugarMap: "Juan de la Barrera #4 col.amador Salazar Yautepec Mor.",
    recepcionUrl: "https://maps.app.goo.gl/bJrKTuERoe7RADkHA",
    imgFondo: "",
    textColor: "",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg")',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const images = [
    "https://i.postimg.cc/bvBfY7b1/IMG-20240418-WA0028.jpg",
    "https://i.postimg.cc/DzSLNHh8/IMG-20240416-WA0076-2.jpg",
    "https://i.postimg.cc/K8qtJFd6/IMG-20240416-WA0075-1.jpg",

    // Add more image URLs as needed
  ];

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} />
      <PortadaXvComponent
        datosSeccionPortada={datosSeccionPortada}
        audioFondo={audioFondo}
      />

      <ContadorXvComponent
        peltaColores={peltaColores}
        datosSeccionContador={datosSeccionContador}
      />

      <div
        style={{
          backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color: datosSeccionPadres.textColor
            ? datosSeccionPadres.textColor
            : "",
        }}
        className="sm:h-[130vh] md:h-[100vh] p-2 flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccionPadres.descipcion}
          </p>
          <div
            className={`grid sm:grid-cols-1 md:grid-cols-${
              datosSeccionPadres.tituloDerecha ? "2" : "1"
            } w-[80%] pt-[10vh]`}
          >
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl">
                {datosSeccionPadres.tituloIzquierda}
              </p>
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccionPadres.madre}
              </p>
              {datosSeccionPadres.padre ? (
                <p className="font-serif text-center text-xl">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl">
                {datosSeccionPadres.padre}
              </p>
            </div>
            {datosSeccionPadres.tituloDerecha ? (
              <div
                className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                data-aos="fade-left"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres.tituloDerecha}
                </p>
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.madrina}
                </p>
                {datosSeccionPadres.padrino ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.padrino}
                </p>
              </div>
            ) : (
              ""
            )}
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <br />
              <p className="font-serif text-center text-xl">Sara Sánchez</p>
              {datosSeccionPadres.padrino ? (
                <p className="font-serif text-center text-xl">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl">Javier Terán</p>
              {datosSeccionPadres.padrino ? (
                <p className="font-serif text-center text-xl">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl">Benigno Martinez</p>
            </div>
          </div>
        </div>
      </div>

      <CeremoniaXvComponent
        peltaColores={peltaColores}
        datosSeccionCeremonia={datosSeccionCeremonia}
      />

      <div
        style={{
          backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS ? "local" : "fixed",

          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccionVestimenta.vestimenta
              ? datosSeccionVestimenta.vestimenta
              : "FORMAL"}
          </p>
          <br />
          {datosSeccionVestimenta.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
              {datosSeccionVestimenta.messageVestimenta
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      <ConfirmacionXvComponent
        peltaColores={peltaColores}
        images={images}
        datosSeccionConfirmacion={datosSeccionConfirmacion}
      />
    </div>
  );
};
