import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaDafneYCesar = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.youtube.com/watch?v=jVvM4OifNxk",
    textoConfirmacion: "nuestra boda",
    esposo: "Cesar",
    esposa: "Dafne",
    fechaDeBoda: "27 SEPTIEMBRE, 2025",
    imgPortada:
      "https://i.postimg.cc/zvVDfvfx/464c12aa-bcca-4871-8fc1-89c8a4b72f63.jpg",
    titulo: "NO FALTES A NUESTRA BODA",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "El amor nos ha llevado a este momento mágico. \\nLos invitamos a ser testigos de nuestra promesa de amor eterno.",
    fechaNewDate: "September 28, 2025 01:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/vmtZqCjt/49fb446a-0dfb-411f-9eca-a0ca5cf25f4d.jpg",
    fotoDerecha:
      "https://i.postimg.cc/90Kmw2RC/53cf5552-a182-4120-b4bb-d20f95fe3c89.jpg",
    marcoEnable: true,
    imgFondo:
      "https://i.postimg.cc/BQgTCBgy/Beige-Floral-Leaf-Watercolor-Background-Your-Story.png",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra con nosotros este día tan maravilloso!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Juana Barrera González ✟",
    padre: "Héctor Medrano Torres ",
    titulo2: "Padres del Novio",
    madrina: "Bertha Gómez Méndez ",
    padrino: "Delfino Rodríguez Hernández ✟",
    titulo3: "Padrinos",
    madrina2: "Karina Galicia Monroy",
    padrino2: "Alejandro Gómez Méndez",
    fotoPadrinos:
      "https://i.postimg.cc/3xpRpkF6/8a797407-b125-4b3d-a3d4-45dda1965dd7.jpg",
    imgFondo:
      "https://i.postimg.cc/BQgTCBgy/Beige-Floral-Leaf-Watercolor-Background-Your-Story.png",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 27 de Septiembre de 2025",
    ceremoniaHora: "7:30 a.m.",
    ceremoniaNombreLugar: "Cenote Suytun",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/HL78UDBzoc1eufis7",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 27 de Septiembre de 2025",
    recepcionHora: "5:30 p.m.",
    recepcionNombreLugar: "Hotel Barcelo Maya Grand",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/61rZgVVfpyka5ti99",
    imgFondo:
      "https://i.postimg.cc/wj06wCKJ/Silver-Minimalist-New-Year-Party-Invitation-1.png",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Salida del hotel",
    titulo: "",
    descripcion: "",
    ceremoniaReligiosaImg:
      "https://i.postimg.cc/Z5xQfGyy/png-transparent-bus-stop-computer-icons-transport-bus-angle-rectangle-school-bus-thumbnail-removebg.png",
    ceremoniaReligiosaTitulo: "5:30 am",
    civil: "Ceremonia Maya \\n(Codigo de vestimenta blanco)",
    civilImg: "https://i.postimg.cc/zG8tRxPD/85321-1.png",
    civilTitulo: "8:00 am",
    recepcion: "Llegada al cenote",
    recepcionImg: "https://i.postimg.cc/SNW1sBdt/11199091.png",
    recepcionTitulo: "7:30 am",
    cena: "Salida del cenote",
    cenaImg:
      "https://i.postimg.cc/Z5xQfGyy/png-transparent-bus-stop-computer-icons-transport-bus-angle-rectangle-school-bus-thumbnail-removebg.png",
    cenaTitulo: "10:00 am",
    coctel: "Tiempo libre",
    coctelImg: "https://i.postimg.cc/VL1B7d9D/1417562.png",
    coctelTitulo: "12:00 pm",
    baile: "Ceremonia civil en la playa ",
    baileImg: "https://i.postimg.cc/G2KnY6Xc/l.png",
    baileTitulo: "5:30 pm",
    extra: "Cena",
    extraImg: "https://i.postimg.cc/cCRpCQXj/Dise-o-sin-t-tulo-58.png",
    extraTitulo: "7:00 pm",
    textColor: "",
    imgFondo:
      "https://i.postimg.cc/wj06wCKJ/Silver-Minimalist-New-Year-Party-Invitation-1.png",
    tipoLetra: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion: "",
    hotel1Titulo: "",
    hotel1lugar: "Cotización de vuelos",
    hotel1url: "",
    hotel2Titulo: "",
    hotel2lugar: "Para mas información sobre su estancia en el hotel",
    hotel2url: "https://i.postimg.cc/rFSb1kKf/Agregar-un-t-tulo-19.png",
    imgFondo:
      "https://i.postimg.cc/wj06wCKJ/Silver-Minimalist-New-Year-Party-Invitation-1.png",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+525574959857",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo:
      "https://i.postimg.cc/wj06wCKJ/Silver-Minimalist-New-Year-Party-Invitation-1.png",
    textColor: "",
  };

  const fontFamilyStyle = {
    fontFamily:
      datosSeccionProgramaBoda.tipoLetra ||
      "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `Hola, soy ${name} y confirmo mi asistencia ${
      datosSeccionConfirmacion.textoConfirmacion
    }${
      !datosSeccionConfirmacion?.enableNum ? ` con ${guests} invitado(s)` : ""
    }. ¡Nos vemos ahí!`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccionConfirmacion.numeroConfirmacion
    }&text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  const contador = true;
  const padres = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = true;
  const vestimenta = false;

  const images = [
    "https://i.postimg.cc/G23hZqnw/524aa983-8a6f-450e-b038-3b0d60271373.jpg	",
    "https://i.postimg.cc/7LkLNqBL/db49f64a-8693-41cc-b22d-31863a06005c.jpg ",
    "https://i.postimg.cc/xTXqfxJ9/6d06e9d9-f8a5-4b55-be3c-5d6b1c5fec11.jpg	",
    "https://i.postimg.cc/5976MdtP/d145ad22-5386-419c-b7d7-a52755931771.jpg	",
    "https://i.postimg.cc/j58dKPRW/26524b62-bedf-4d97-8c2c-c274cec2079f.jpg	",
    "https://i.postimg.cc/HsFnVwXX/af1639fc-c921-4040-8ad1-7519879f2f73.jpg	",
    "https://i.postimg.cc/d3x1RdXM/4546ab44-d1bf-4a6a-86c0-19cf9b291988.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionPadres.textColor || "",
            ...fontFamilyStyle, // Aplicar el tipo de letra a todo el div
          }}
          className="sm:h-auto md:h-auto py-[5vh] px-3 flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
              style={fontFamilyStyle} // Aplicar el tipo de letra al h1
            >
              {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <br />
            {datosSeccionPadres.descripcion ? (
              <p
                className="text-center pt-[5vh] text-xl"
                data-aos="flip-up"
                style={fontFamilyStyle} // Aplicar el tipo de letra al p
              >
                {datosSeccionPadres.descripcion
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            ) : (
              ""
            )}
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionPadres.cantidad || "1"
              } w-[80%] pt-[10vh]`}
            >
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <p
                  className="font-semibold text-center text-2xl"
                  style={fontFamilyStyle}
                >
                  {datosSeccionPadres.titulo1}
                </p>
                <br />
                <p className="text-center text-xl" style={fontFamilyStyle}>
                  {datosSeccionPadres.madre}
                </p>
                {datosSeccionPadres.padre ? (
                  <p className="text-center text-xl" style={fontFamilyStyle}>
                    &
                  </p>
                ) : (
                  ""
                )}
                <p className="text-center text-xl" style={fontFamilyStyle}>
                  {datosSeccionPadres.padre}
                </p>
              </div>

              {datosSeccionPadres.cantidad == 2 ||
              datosSeccionPadres.cantidad == 3 ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p
                    className="font-semibold text-center text-2xl"
                    style={fontFamilyStyle}
                  >
                    {datosSeccionPadres.titulo2}
                  </p>
                  <br />
                  <p className="text-center text-xl" style={fontFamilyStyle}>
                    {datosSeccionPadres.madrina}
                  </p>
                  {datosSeccionPadres.padrino ? (
                    <p className="text-center text-xl" style={fontFamilyStyle}>
                      &
                    </p>
                  ) : (
                    ""
                  )}
                  <p className="text-center text-xl" style={fontFamilyStyle}>
                    {datosSeccionPadres.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}

              {datosSeccionPadres.cantidad == 3 ? (
                <>
                  <div
                    style={{
                      backgroundImage: `url(${datosSeccionPadres.fotoPadrinos})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "20vh",
                      display: "flex",
                      backgroundPosition: "center center",
                      width: "20vh",
                      justifyContent: "center",
                      alignItems: "center",
                      justifySelf: "center",
                      alignContent:"center"
                    }}
                    className="rounded-[30%]"
                    data-aos="fade-right"
                  ></div>
                  <div
                    className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                    data-aos="fade-left"
                  >
                    <p
                      className="font-semibold text-center text-2xl"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionPadres.titulo3}
                    </p>
                    <br />
                    <p className="text-center text-xl" style={fontFamilyStyle}>
                      {datosSeccionPadres.madrina2}
                    </p>
                    {datosSeccionPadres.padrino2 ? (
                      <p
                        className="text-center text-xl"
                        style={fontFamilyStyle}
                      >
                        &
                      </p>
                    ) : (
                      ""
                    )}
                    <p className="text-center text-xl" style={fontFamilyStyle}>
                      {datosSeccionPadres.padrino2}
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionProgramaBoda.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionProgramaBoda.textColor || "",
            ...fontFamilyStyle,
          }}
          className="sm:h-auto md:h-auto flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
        >
          <div className="flex flex-col w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
              style={fontFamilyStyle}
            >
              {datosSeccionProgramaBoda.titulo || "Programa"}
            </h1>
            <br />
            <p
              className="text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
              style={fontFamilyStyle}
            >
              {datosSeccionProgramaBoda.descripcion ||
                "Hemos planeado un día lleno de diversión para disfrutar al máximo."}
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionProgramaBoda.ceremoniaReligiosa ? (
                  <>
                    {datosSeccionProgramaBoda.ceremoniaReligiosaImg ? (
                      <img
                        src={datosSeccionProgramaBoda.ceremoniaReligiosaImg}
                        className="w-[70px] h-[70px]"
                        alt="Ceremonia Religiosa"
                      />
                    ) : (
                      <PiChurchLight className="text-[60px]" />
                    )}
                    <br />
                    <p
                      className="font-serif text-xl text-center"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.ceremoniaReligiosaTitulo ||
                        "CEREMONIA RELIGIOSA"}
                    </p>
                    <br />
                    <p
                      className="font-serif text-xl text-center w-[60%]"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.ceremoniaReligiosa
                        .split("\\n")
                        .map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.recepcion ? (
                  <>
                    {datosSeccionProgramaBoda.recepcionImg ? (
                      <img
                        src={datosSeccionProgramaBoda.recepcionImg}
                        className="w-[70px] h-[70px]"
                        alt="Recepción"
                      />
                    ) : (
                      <FaPhotoVideo className="text-[60px]" />
                    )}
                    <br />
                    <p
                      className="font-serif text-xl text-center"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.recepcionTitulo || "RECEPCIÓN"}
                    </p>
                    <br />
                    <p
                      className="font-serif text-xl text-center w-[60%]"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.recepcion
                        .split("\\n")
                        .map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.civil ? (
                  <>
                    {datosSeccionProgramaBoda.civilImg ? (
                      <img
                        src={datosSeccionProgramaBoda.civilImg}
                        className="w-[70px] h-[70px]"
                        alt="Ceremonia Civil"
                      />
                    ) : (
                      <GiBigDiamondRing className="text-[60px]" />
                    )}
                    <br />
                    <p
                      className="font-serif text-xl text-center"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.civilTitulo ||
                        "CEREMONIA CIVIL"}
                    </p>
                    <br />
                    <p
                      className="font-serif text-xl text-center w-[60%]"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.civil
                        .split("\\n")
                        .map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>

              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionProgramaBoda.cena ? (
                  <>
                    {datosSeccionProgramaBoda.cenaImg ? (
                      <img
                        src={datosSeccionProgramaBoda.cenaImg}
                        className="w-[70px] h-[70px]"
                        alt="Cena"
                      />
                    ) : (
                      <ImSpoonKnife className="text-[60px]" />
                    )}
                    <br />
                    <p
                      className="font-serif text-xl text-center"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.cenaTitulo || "COMIDA"}
                    </p>
                    <br />
                    <p
                      className="font-serif text-xl text-center w-[60%]"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.cena
                        .split("\\n")
                        .map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <br />
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.coctel ? (
                  <>
                    {datosSeccionProgramaBoda.coctelImg ? (
                      <img
                        src={datosSeccionProgramaBoda.coctelImg}
                        className="w-[70px] h-[70px]"
                        alt="Cóctel"
                      />
                    ) : (
                      <FaChampagneGlasses className="text-[60px]" />
                    )}
                    <br />
                    <p
                      className="font-serif text-xl text-center"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.coctelTitulo || "COCTEL"}
                    </p>
                    <br />
                    <p
                      className="font-serif text-xl text-center w-[60%]"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.coctel
                        .split("\\n")
                        .map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.baile ? (
                  <>
                    {datosSeccionProgramaBoda.baileImg ? (
                      <img
                        src={datosSeccionProgramaBoda.baileImg}
                        className="w-[70px] h-[70px]"
                        alt="Baile"
                      />
                    ) : (
                      <GiMeshBall className="text-[60px]" />
                    )}
                    <br />
                    <p
                      className="font-serif text-xl text-center"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.baileTitulo || "BAILE"}
                    </p>
                    <br />
                    <p
                      className="font-serif text-xl text-center w-[60%]"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.baile
                        .split("\\n")
                        .map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
                {datosSeccionProgramaBoda.extra ? (
                  <>
                    {datosSeccionProgramaBoda.extraImg ? (
                      <img
                        src={datosSeccionProgramaBoda.extraImg}
                        className="w-[70px] h-[70px]"
                        alt="extra"
                      />
                    ) : (
                      <GiMeshBall className="text-[60px]" />
                    )}
                    <br />
                    <p
                      className="font-serif text-xl text-center"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.extraTitulo || "BAILE"}
                    </p>
                    <br />
                    <p
                      className="font-serif text-xl text-center w-[60%]"
                      style={fontFamilyStyle}
                    >
                      {datosSeccionProgramaBoda.extra
                        .split("\\n")
                        .map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionHospedaje.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionHospedaje.textColor || "",
            ...fontFamilyStyle,
          }}
          className="sm:h-auto py-[5vh] md:h-auto flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
            <h1
              className="text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
              style={fontFamilyStyle}
            >
              Hospedaje
            </h1>
            <br />
            <p
              className="text-center pt-[5vh] text-xl"
              data-aos="flip-up"
              style={fontFamilyStyle}
            >
              {datosSeccionHospedaje.descripcion
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>

            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionHospedaje.disponibleDos ? 2 : 1
              } w-[80%] pt-[8vh]`}
            >
              <div
                className="flex flex-col justify-center w-[100%] rounded-2xl text-black items-center bg-slate-100 drop-shadow-2xl h-auto"
                data-aos="fade-right"
              >
                <h1
                  className="text-center sm:text-lg md:text-[23px]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionHospedaje.hotel1Titulo}
                </h1>
                <br />
                <p
                  className="text-center sm:text-lg md:text-[23px] w-[80%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionHospedaje.hotel1lugar}
                </p>
                <br />
                <br />
                <a
                  href="https://wa.me/525534968587"
                  className="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  style={{ textDecoration: "none" }}
                >
                  Contacto!
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>

              {datosSeccionHospedaje.disponibleDos ? (
                <div
                  className="flex flex-col justify-center w-[100%] rounded-2xl text-black items-center bg-slate-100 drop-shadow-2xl h-auto sm:mt-4 md:mt-0"
                  data-aos="fade-right"
                >
                  <h1
                    className="text-center sm:text-lg md:text-[23px]"
                    style={fontFamilyStyle}
                  >
                    {datosSeccionHospedaje.hotel2Titulo}
                  </h1>
                  <br />
                  <p
                    className="text-center sm:text-lg md:text-[23px] w-[80%]"
                    style={fontFamilyStyle}
                  >
                    {datosSeccionHospedaje.hotel2lugar}
                  </p>
                  <br />
                  <br />
                  <a
                    href={datosSeccionHospedaje.hotel2url}
                    className="overflow-hidden relative p-2 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                    style={{ textDecoration: "none" }}
                  >
                    Ver información!
                    <span className="absolute top-0 left-0 w-full h-full bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span className="absolute top-0 left-0 w-full h-full bg-amber-200 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span className="absolute top-0 left-0 w-full h-full bg-amber-300 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute inset-0 flex items-center justify-center z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionConfirmacion.textColor || "",
          }}
          className="sm:h-auto md:h-auto flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
        >
          <h1
            className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
            data-aos="flip-up"
            style={fontFamilyStyle}
          >
            {datosSeccionConfirmacion.tituloConfirmacion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </h1>
          <br />
          <p
            className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]"
            style={fontFamilyStyle}
          >
            {datosSeccionConfirmacion.aviso.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>
          <br />
          <br />
          <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
            {images?.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[70vh]"
                />
              </div>
            ))}
          </Slider>
          <br />
          <br />
          {datosSeccionConfirmacion.numeroConfirmacion && (
            <div
              data-aos="fade-down-left"
              className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
            >
              <h2
                className="text-2xl text-gray-700 font-bold mb-4 justify-center flex"
                style={fontFamilyStyle}
              >
                {datosSeccionConfirmacion.textWhatsApp ||
                  "Confirmación de Asistencia al Evento"}{" "}
                <FaWhatsapp className="text-green-600 ml-2" />
              </h2>
              <form onSubmit={handleSubmit}>
                <label className="mb-2 flex">
                  <span className="text-gray-700" style={fontFamilyStyle}>
                    Nombre:
                  </span>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                    required
                  />
                </label>
                <br />
                {!datosSeccionConfirmacion?.enableNum && (
                  <label className="flex mb-2">
                    <span className="text-gray-700" style={fontFamilyStyle}>
                      Invitados:
                    </span>
                    <input
                      type="number"
                      value={guests}
                      onChange={(e) => setGuests(e.target.value)}
                      className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                      min={1}
                    />
                  </label>
                )}
                <br />
                <button
                  type="submit"
                  className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                  style={fontFamilyStyle}
                >
                  Confirmar Asistencia
                </button>
              </form>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
