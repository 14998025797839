import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/BrunoMars.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";

export const BodaRolandoYValeria = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#052800",
    color2: "#250909",
  };

  const datosSeccionPotadaBoda = {
    cancion: audioFondo,
    textoConfirmacion: " nuestra boda",
    esposo: "Rolando",
    esposa: "Valeria",
    fechaDeBoda: "10 AGO, 2024",
    imgPortada: "https://i.postimg.cc/tJs65F50/IMG-20240302-WA0064.jpg ",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "En un día tan especial, queremos estar rodeados de personas especiales como ustedes. \\n\\n Porque el amor no puede esperar \\n ''La birria tampoco porque se enfría'' \\n\\n¡Te esperamos! Nos harás muy felices con tu presencia. ",
    fechaNewDate: "August 10, 2024 14:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/T2sgPHdT/IMG-20230910-WA0000.jpg ",
    fotoDerecha: "https://i.postimg.cc/pdvDHh28/IMG-20240422-WA0019-1.jpg ",
    marcoEnable: true,
    imgFondo: "",
    textColor: "#fff",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Guillermina González Martínez",
    padre: "José Alfredo Cruz Soto",
    titulo2: "Padres del Novio",
    madrina: "Dolores Damián Pliego",
    padrino: "José Luis Gomez Sanchez",
    titulo3: "Padrinos",
    madrina2: "Rosa de la Cruz Huitron",
    padrino2: "José Trinidad Cruz Soto",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 10 de Agosto de 2024.",
    ceremoniaHora: "2:30 p.m.",
    ceremoniaNombreLugar: "Parroquia de San Francisco De Asis",
    ceremoniaLugarMap:
      "Alfredo del Mazo Ote. Manzana 005, Centro, 50450 Atlacomulco de Fabela, Méx.",
    ceremoniaUrl: "https://maps.app.goo.gl/yzTpYSypKGh1ZHn97",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 10 de Agosto de 2024.",
    recepcionHora: "4:10 p.m.",
    recepcionNombreLugar: "Salon Casa Bayo",
    recepcionLugarMap:
      "Sierra Blanca, Bongoni, 50453 Atlacomulco de Fabela, Méx.",
    recepcionUrl: "https://maps.app.goo.gl/RHUopk16BYHg9hmj8",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color blanco, exclusivo novia",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+527122107563",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/B6YDHm2c/IMG-20230716-WA0005.jpg",
    "https://i.postimg.cc/BbRKwtYj/IMG-20230304-WA0024.jpg",
    "https://i.postimg.cc/j5dPDpnS/IMG-20240422-WA0020.jpg",
    "https://i.postimg.cc/7YT7JMh0/IMG-20240302-WA0002.jpg",
    "https://i.postimg.cc/rp95FVmH/IMG-20240422-WA0018.jpg",
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const [adulto, setAdulto] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, soy ${name} y confirmo mi asistencia ${datosSeccionConfirmacion.textoConfirmacion} con ${guests} niños y ${adulto} adultos. ¡Nos vemos ahí!`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccionConfirmacion.numeroConfirmacion
    }&text=${encodeURIComponent(message)}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
        audioFondo={audioFondo}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionConfirmacion.textColor
              ? datosSeccionConfirmacion.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
        >
          <h1
            className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionConfirmacion.tituloConfirmacion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </h1>
          <br />
          <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
            {datosSeccionConfirmacion.aviso || ""}
          </p>
          <br />
          <br />
          <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
            {images?.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[60vh]"
                />
              </div>
            ))}
          </Slider>
          <br />
          <br />
          <div
            data-aos="fade-down-left"
            className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
          >
            <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
              {datosSeccionConfirmacion.textWhatsApp ||
                "Confirmación de Asistencia al Evento"}{" "}
              <FaWhatsapp className="text-green-600 ml-2" />
            </h2>
            <form onSubmit={handleSubmit}>
              <label className="mb-2 flex">
                <span className="text-gray-700">Nombre:</span>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  required
                />
              </label>
              <br />
              <label className="flex mb-2">
                <span className="text-gray-700">Numero de Niños:</span>
                <input
                  type="number"
                  value={guests}
                  onChange={(e) => setGuests(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  min={0}
                />
              </label>
              <br />
              <label className="flex mb-2">
                <span className="text-gray-700">Numero de Adultos:</span>
                <input
                  type="number"
                  value={adulto}
                  onChange={(e) => setAdulto(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  min={0}
                />
              </label>
              <br />
              <button
                type="submit"
                className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
              >
                Confirmar Asistencia
              </button>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
