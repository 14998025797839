import React, { useState, useEffect, useRef } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import Slider from "react-slick";
import confetti from 'canvas-confetti';

export const ConfirmacionGeneral = ({
  peltaColores,
  images,
  datosSeccion1,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
          observer.disconnect(); // Desconectar después de lanzar el confetti
        }
      },
      { threshold: 0.5 } // Lanzar cuando al menos el 50% del componente esté visible
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, soy ${name} y confirmo mi asistencia ${datosSeccion1.textoConfirmacion} con ${guests} invitado(s). ¡Nos vemos ahí!`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccion1.numeroNovia
    }&text=${encodeURIComponent(message)}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  return (
    <div
      ref={componentRef}
      style={{
        backgroundImage: `url(${datosSeccion1.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccion1.textColor || "",
      }}
      className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
    >
      <h1
        className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
        data-aos="flip-up"
      >
        {datosSeccion1.tituloConfirmacion || (
          <div>
            Espero puedan confirmarme su asistencia. <br />
            Agradezco de antemano su apreciable compañía.
          </div>
        )}
      </h1>
      <br />
      <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
        {(datosSeccion1.aviso || "").split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
      </p>
      <br />
      <br />
      <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
        {images?.map((imageUrl, index) => (
          <div data-aos="fade-down-right" className="rolImg" key={index}>
            <img
              src={imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-auto rounded-xl max-h-[60vh]"
            />
          </div>
        ))}
      </Slider>
      <br />
      <br />
      <div
        data-aos="fade-down-left"
        className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
      >
        <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
          {datosSeccion1.textWhatsApp || "Confirmación de Asistencia al Evento"}{" "}
          <FaWhatsapp className="text-green-600 ml-2" />
        </h2>
        <form onSubmit={handleSubmit}>
          <label className="mb-2 flex">
            <span className="text-gray-700">Nombre:</span>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
              required
            />
          </label>
          <br />
          <label className="flex mb-2">
            <span className="text-gray-700">Invitados:</span>
            <input
              type="number"
              value={guests}
              onChange={(e) => setGuests(e.target.value)}
              className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
              min={1}
              required
            />
          </label>
          <br />
          <button
            type="submit"
            className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
          >
            Confirmar Asistencia
          </button>
        </form>
      </div>
    </div>
  );
};
