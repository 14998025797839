import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import audioFondo from "./assets/MelodiaViolin.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PiChurchLight } from "react-icons/pi";
import { GiBigDiamondRing, GiMeshBall } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { FaChampagneGlasses } from "react-icons/fa6";
import { Vestimenta } from "../../../components/Vestimenta";

export const BodaGuadalupe = () => {
  const datosSeccion1 = {
    titulo: "RENOVACIÓN DE VOTOS",
    textoConfirmacion: " la renovación de votos",
    esposo: "Gabriel",
    esposa: "Guadalupe",
    fechaDeBoda: "01 JUNIO, 2024",
    fechaNewDate: "June 01, 2024 11:00:00 GMT+00:00",
    imgPortada:
      "https://i.postimg.cc/BbVHLQpq/308f56db-0bc8-4cc3-9df0-f7c87c9a6e0f.jpg",
    numeroNovia: "5540594467",
    numeroNovio: "4491516931",
    novios: true,
  };

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccion8 = {
    descripcion:
      "No hay mejor regalo que tener tu compañía, pero si deseas darnos un detalle lo agradecería mucho.",
    linkAmazon:
      "",
    linkLiverpool:
    "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51416941",
  };


  const datosSeccion2 = {
    titulo: "¡Volvemos a decir que si!",
    descipcion:(
      <div>
        Despues de 43 años caminando juntos, hoy estamos reiterando nuestro amor, agradeciendo la misericordia de Dios en nuestro matrimonio, por lo que nos complace invitarlos a la renovacion de nuestros votos. <br />
        "Cordón de tres dobleces no se rompe pronto"<br />
                               Eclesiastés 4:12.
      </div>
    ),
    fechaNewDate: "June 01, 2024 11:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/CK1yL7CS/1e9aba5e-9579-4c3b-8f20-79e0a8adf6e5.jpg",
    fotoDerecha: "https://i.postimg.cc/vZXFskV0/350b90ac-255a-4e99-8610-4e79f2584c6b.jpg",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Yolanda Bermúdez",
    esposaPadre: "Gustavo Revueltas (+)",
    esposoMadre: "Julia López (+)",
    esposoPadre: "Donaciano Alcántara (+)",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestra renovación de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 01 de Junio de 2024.",
    ceremoniaHora: "11:30 a.m.",
    ceremoniaNombreLugar: "Salón Excelencia",
    ceremoniaLugarMap: "Calle Bahia de Sta. Barbara 49, Verónica Anzúres, Miguel Hidalgo, 11300 Ciudad de México, CDMX",
    ceremoniaUrl: "https://maps.app.goo.gl/GNT4CMMZ9cxUjUVi8?g_st=iw",
    recepcionFecha: "Sábado, 01 de Junio de 2024.",
    recepcionHora: "11:30 a.m.",
    recepcionNombreLugar: "Salón Elegancia",
    recepcionLugarMap:"Calle Bahia de Sta. Barbara 49, Verónica Anzúres, Miguel Hidalgo, 11300 Ciudad de México, CDMX",
    recepcionUrl: "https://maps.app.goo.gl/GNT4CMMZ9cxUjUVi8?g_st=iw",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const images = [
    "https://i.postimg.cc/t4MnYZBY/IMG-20240325-WA0019.jpg",
    "https://i.postimg.cc/yN3g91Gn/IMG-20240325-WA0020.jpg",
    "https://i.postimg.cc/2yGVmVqj/IMG-20240325-WA0024.jpg",
    "https://i.postimg.cc/Tw8yc8Mx/Screenshot-20240324-195412-Facebook.jpg",
    // Add more image URLs as needed
  ];

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-amber-700 h-1 w-[60%]" />
              <br />
              <GiBigDiamondRing className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RENOVACIÓN DE VOTOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaCivil}
              </p>
              <br />
              <hr className="bg-amber-700 h-1 w-[60%]" />
              <br />
              
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-amber-700 h-1 w-[60%]" />
              <br />
              
            </div>
          </div>
        </div>
      </div>

      <Vestimenta
        peltaColores={peltaColores}
        imagenesFondo={imagenesFondo}
      />

      
      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicacion
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[90%] pt-[8vh]">
            <div></div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
               <div
                style={{
                  backgroundImage:
                  'url("https://i.pinimg.com/736x/96/a8/5f/96a85fb2854601d071ea4353772d6ce5.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              >
              </div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.429859675131!2d-99.17681879999999!3d19.437025000000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8b228046477%3A0x15e2f1e0c6ae9ffd!2sMonarqu%C3%ADa%20Coronado%20Excelencia!5e0!3m2!1ses!2smx!4v1711585847552!5m2!1ses!2smx"
                width="100%"
                height="50%"
                className="fixed"
              ></iframe>
              <a
                href={datosSeccion4.recepcionUrl}
                class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
              >
                Mapa de la Recepción
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:p-3 md:p-0 sm:py-[5vh] md:pt-[15vh] items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:w-[50%] sm:w-[80%] pt-[8vh]">  
            <div
              style={{
                backgroundImage:
                  'url("https://industriasneon.files.wordpress.com/2015/07/liverpoollogo-copia.png?w=1000")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "90%",
                display: "flex",
                backgroundPosition: "center center",
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              <a
                href={datosSeccion8.linkLiverpool}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mesa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
