import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/maroon.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaRubenYGeny = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#fff9e8",
    color2: "#ffe2d6",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDEfnrlXM5R_y2mate.com---Regalo-De-Dios-Piano-Version.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Rubén",
    esposa: "Geny",
    fechaDeBoda: "13 JUL, 2024",
    imgPortada: "https://i.postimg.cc/3J8NJr21/image2-3.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Hay momentos en la vida que son especiales por si solos, pero compartirlos con las personas que amamos los hace inolvidables. Con la Bendición de Dios y nuestros padres",
    fechaNewDate: "July 13, 2024 14:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/hthGTCy7/image1-3.jpg",
    fotoDerecha:
      "https://i.postimg.cc/Hxp90k1m/199f32e7-2b05-4b75-be76-fb75698420bf.jpg",
    marcoEnable: true,
    imgFondo:
      "https://i.postimg.cc/RZb5FTV4/Captura-de-pantalla-2024-05-01-114431.png",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Thania y Jorge!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "María Eugenia Valdovinos Alonso",
    padre: "Víctor Manuel Camacho Sedano",
    titulo2: "Padres del Novio",
    madrina: "Lorena Márquez Conn",
    padrino: "Rubén Solis Adame",
    titulo3: "Padrinos de Velacion",
    madrina2: "Lizette Alejandrina Camacho Valdovinos",
    padrino2: "Eloy Navarro Barragán ",
    imgFondo:
      "https://i.postimg.cc/RZb5FTV4/Captura-de-pantalla-2024-05-01-114431.png",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 13 de Julio de 2024.",
    ceremoniaHora: "2:00 p.m.",
    ceremoniaNombreLugar: "Templo del Sagrado corazón de Jesús",
    ceremoniaLugarMap:
      "Calle Felipe Carrillo Puerto 106, Centro, 99000 Centro, Zac.",
    ceremoniaUrl: "https://maps.app.goo.gl/boxsPtXuway5B57E6",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 13 de Julio de 2024.",
    recepcionHora: "3:00 p.m.",
    recepcionNombreLugar: "Salón “El Encanto “",
    recepcionLugarMap: "Calle Rio colorado # 22 col. Del Valle 99084",
    recepcionUrl: "https://maps.app.goo.gl/HLQx2Wsr7ygCH2Em6",
    imgFondo:
      "https://i.postimg.cc/RZb5FTV4/Captura-de-pantalla-2024-05-01-114431.png",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "1:00 pm Acompáñanos a recibir la bendición de Dios",
    civil: "4:10 pm No te pierdas nuestro intercambio de votos",
    recepcion: "4:00 pm Tómate fotos con nosotros",
    cena: "6:00 pm Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
    imgFondo:
      "https://i.postimg.cc/RZb5FTV4/Captura-de-pantalla-2024-05-01-114431.png",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta:
      "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Hotel Avid",
    hotel1lugar:
      "Paseo del Mineral No.101', Emiliano Zapata, 99010 Fresnillo, Zac.",
    hotel1url: "https://maps.app.goo.gl/M7GjR8CLD7HHZBUm7",
    hotel2Titulo: "Motel la fortuna",
    hotel2lugar: "Carretera panamericana #km 724.3 industrial 99030",
    hotel2url: "https://maps.app.goo.gl/Cuir41Pod3j266Ff8",
    imgFondo:
      "https://i.postimg.cc/RZb5FTV4/Captura-de-pantalla-2024-05-01-114431.png",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Lluvia de sobres",
    descripcion:
      "En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarnos algo, te agradeceríamos que lo envolvieras en un sobre, !! Nuesto hogar será en otro estado !!",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1: "",
    link1Enable: false,
    link2: "",
    imgLink2: "",
    link2Enable: false,
    link3: "",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo:
      "https://i.postimg.cc/RZb5FTV4/Captura-de-pantalla-2024-05-01-114431.png",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+523310240270",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo:
      "https://i.postimg.cc/RZb5FTV4/Captura-de-pantalla-2024-05-01-114431.png",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/3J8NJr21/image2-3.jpg",
    "https://i.postimg.cc/hthGTCy7/image1-3.jpg",
    "https://i.postimg.cc/bNmJGXTh/image4-2.jpg",
    "https://i.postimg.cc/Kcr42334/image0-3.jpg",
    "https://i.postimg.cc/rsJpC725/image3-3.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
        audioFondo={audioFondo}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionPadres.textColor
              ? datosSeccionPadres.textColor
              : "",
          }}
          className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              {datosSeccionPadres.descipcion}
            </p>
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionPadres.cantidad || "1"
              } w-[80%] pt-[10vh]`}
            >
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres.titulo1}
                </p>
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.madre}
                </p>
                {datosSeccionPadres.padre ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.padre}
                </p>
              </div>

              {datosSeccionPadres.cantidad == 2 ||
              datosSeccionPadres.cantidad == 3 ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres.titulo2}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.madrina}
                  </p>
                  {datosSeccionPadres.padrino ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}

              {datosSeccionPadres.cantidad == 3 ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres.titulo3}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.madrina2}
                  </p>
                  {datosSeccionPadres.padrino2 ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.padrino2}
                  </p>
                </div>
              ) : (
                ""
              )}

<div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    Padrinos de Lazo
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                  Ana Karen Solis Márquez
                  </p>
                    <p className="font-serif text-center text-xl">&</p>
                  <p className="font-serif text-center text-xl">
                  Luis Gabriel Gómez Bañuelos 
                  </p>
                </div>
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    Padrinos de Anillos
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                  Jaqueline Esthela Camacho Valdovinos 

                  </p>
                    <p className="font-serif text-center text-xl">&</p>
                  <p className="font-serif text-center text-xl">
                  Juan Ramón Reyes Basulto
                  </p>
                </div>
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    Padrinos de Arras
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                  Luzma Díaz De la Torre

                  </p>
                    <p className="font-serif text-center text-xl">&</p>
                  <p className="font-serif text-center text-xl">
                  Juan Antonio Aragón  Martínez
                  </p>
                </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
