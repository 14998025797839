import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaSamaraYGeoffrey = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ffffff",
    color2: "#ffffff",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEecAqibp7o_y2mate.com---Elvis-Presley-Burning-Love-Official-Lyric-Video.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Geoffrey",
    esposa: "Samara",
    fechaDeBoda: "07 SEPTIEMBRE, 2024",
    imgPortada: "https://i.postimg.cc/DfgMbxn0/IMG-20231103-WA0054.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Es un honor compartir esta celebración de amor con ustedes. Hoy agradecemos a Dios y al destino por habernos unido, por darnos la fortuna de amarnos y de construir un futuro juntos como uno solo.",
    fechaNewDate: "September 07, 2024 16:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/PxnR1cCp/Screenshot-20231215-145155-Instagram.jpg",
    fotoDerecha: "https://i.postimg.cc/6pSSpTwh/IMG-20231103-WA0035.jpg",
    marcoEnable: true,
    imgFondo: "https://i.postimg.cc/15BSp4Db/Invitaci-n-Vertical-Bautizo-Floral-Verde-4.png",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Patricia Rivero Velazquez",
    padre: "Enrique Escudero Loza",
    titulo2: "Padres del Novio",
    madrina: "Rosalie Trickey",
    padrino: "Owen Trickey",
    titulo3: "Padrinos",
    madrina2: "Tania Escudero Rivero",
    padrino2: "Arturo Nuñez Lara",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 07 de Septiembre de 2024.",
    ceremoniaHora: "4:00 p.m.",
    ceremoniaNombreLugar: "Catedral de Izcalli Santa María de la Asunción ",
    ceremoniaLugarMap: "Boreal 2, Atlanta, 54740 Cuautitlán Izcalli, Méx.",
    ceremoniaUrl: "https://maps.app.goo.gl/PFdPLfxjzmdByNdS9",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 07 de Septiembre de 2024.",
    recepcionHora: "5:30 p.m.",
    recepcionNombreLugar: "Jardin de Eventos Bredka",
    recepcionLugarMap:
      "CALLE GUADALUPE MIRANDA No 17, COLONIA LA PERLA, 54740 Cuautitlán Izcalli, Méx.",
    recepcionUrl: "https://maps.app.goo.gl/iT6fNQfauxV5bbba8",
    imgFondo: "https://i.postimg.cc/kXdzY9n9/Invitaci-n-Vertical-Bautizo-Floral-Verde-3.png",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 2,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1: "https://www.amazon.ca/wedding/registry/3NE4CQ0ZS8E76",
    imgLink1:
      "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2:
      "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51431699",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "https://i.postimg.cc/15BSp4Db/Invitaci-n-Vertical-Bautizo-Floral-Verde-4.png",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "",
    numeroConfirmacion: "+17786819115",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "https://i.postimg.cc/kXdzY9n9/Invitaci-n-Vertical-Bautizo-Floral-Verde-3.png",
    textColor: "",
    numeroNovia: "+17786819115",
    numeroNovio: "+17783223764",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/hth6nVFj/IMG-20231103-WA0020.jpg",
    "https://i.postimg.cc/PxnR1cCp/Screenshot-20231215-145155-Instagram.jpg",
    "https://i.postimg.cc/6pSSpTwh/IMG-20231103-WA0035.jpg",
    "https://i.postimg.cc/X7YDK9Y8/IMG-20230527-WA0005.jpg",
    "https://i.postimg.cc/X7YDK9Y8/IMG-20230527-WA0005.jpg",
    "https://i.postimg.cc/DfgMbxn0/IMG-20231103-WA0054.jpg",
    "https://i.postimg.cc/nzKw2kmd/Screenshot-20231215-145016-Instagram.jpg",
    "https://i.postimg.cc/KYMWkF8h/IMG-20230701-WA0081.jpg",
    "https://i.postimg.cc/wM048j0L/Screenshot-20231215-145103-Instagram.jpg",
    "https://i.postimg.cc/x80p4zy9/Screenshot-20231215-145121-Instagram.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Mariana Morales", guess: "2", id: 1 },
    { name: "Jessica Balmori", guess: "2", id: 2 },
    { name: "Vanessa Chavez", guess: "2", id: 3 },
    { name: "Jessica Muñoz", guess: "2", id: 4 },
    { name: "GIna Lara", guess: "2", id: 5 },
    { name: "Marya Nuñez", guess: "2", id: 6 },
    { name: "Monica Rivero", guess: "2", id: 7 },
    { name: "Antonia Velazquez", guess: "1", id: 8 },
    { name: "Alfredo Rivero", guess: "5", id: 9 },
    { name: "Ana Rivero", guess: "2", id: 10 },
    { name: "Sergio Vázquez", guess: "3", id: 11 },
    { name: "Margarita Loza", guess: "2", id: 12 },
    { name: "José Luis Rodríguez", guess: "4", id: 13 },
    { name: "Margarita Rodríguez", guess: "2", id: 14 },
    { name: "Elizabeth Flores", guess: "2", id: 15 },
    { name: "Guadalupe Escudero", guess: "2", id: 16 },
    { name: "Silvia Muñoz", guess: "2", id: 17 },
    { name: "Kristian Galván", guess: "2", id: 18 },
    { name: "Israel Galván", guess: "2", id: 19 },
    { name: "Ivan Galván", guess: "2", id: 20 },
    { name: "Victor Galván", guess: "2", id: 21 },
    { name: "Jonathan Escudero", guess: "1", id: 22 },
    { name: "Alfredo Castro", guess: "2", id: 23 },
    { name: "Salvador Escudero", guess: "2", id: 24 },
    { name: "Cintia Escudero", guess: "2", id: 25 },
    { name: "Jesus Escudero", guess: "2", id: 26 },
    { name: "Viridiana Albuquerque", guess: "2", id: 27 },
    { name: "Guadalupe Suarez", guess: "2", id: 28 },
    { name: "Antonia Dominguez", guess: "1", id: 29 },
    { name: "Hilda Paredes", guess: "1", id: 30 },
    { name: "Daniel Velazquez", guess: "3", id: 31 },
    { name: "Jose Luis Colombres", guess: "2", id: 32 },
    { name: "José Sosa", guess: "2", id: 33 },
    { name: "Miguel Herrera", guess: "1", id: 34 },
    { name: "Francisco Loza", guess: "2", id: 35 },
    { name: "Alonso Rojas", guess: "2", id: 36 },
    { name: "Mari Sun Amoros", guess: "1", id: 37 },
    { name: "Fernando Paz", guess: "2", id: 38 },
    { name: "Alfredo Gil", guess: "2", id: 39 },
    { name: "Raymundo Miranda", guess: "1", id: 40 },
    { name: "Armando Escudero Vidaña", guess: "2", id: 41 },
    { name: "José Luis Escudero Vidaña", guess: "2", id: 42 },
    { name: "Ana Lilia Rodríguez", guess: "2", id: 43 },
    { name: "Jesus Ramos", guess: "2", id: 44 },
    { name: "Nallely García", guess: "2", id: 45 },
    { name: "Alma Cortés", guess: "2", id: 46 }
];


  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la Boda años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
