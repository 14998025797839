import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaMonicaYDorian = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ffc5ab",
    color2: "#ffc5ab",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEkbWLrkHaw_y2mate.com---Matisse-Reik-Eres-T%C3%BA-Piano-Tutorial-Cover-Acordes-y-Letra-Karaoke-Instrumental-V2.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Dorian",
    esposa: "Monica",
    fechaDeBoda: "",
    imgPortada:
      "https://i.postimg.cc/tTkftB9t/Video-para-Dispositivos-M-viles-Boda-Floral-Beige-y-Dorado.png",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Este es el sueño que compartimos ... \\nEl que queremos cuidar a través de los años ... \\nEl que hablará por nosotros, más que toda palabra ... \\nEl que nos reencontrará a través de cualquier distancia ... \\nEste es el sueño que compartimos, y se llama Amor.",
    fechaNewDate: "December 12, 2024 00:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/y60d10Ph/FELM3517-1.jpg",
    fotoDerecha: "https://i.postimg.cc/fRRTfph5/my-d2-1.png",
    marcoEnable: true,
    imgFondo:
      "https://i.postimg.cc/zBX1Y142/Fondo-de-Pantalla-Flores-Femenino-Crema.png",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Magdalena Vázquez Prieto",
    padre: "Adolfo Lira González",
    titulo2: "Padrinos del Novio",
    madrina: "Rosio Garibay Cervantes",
    padrino: "Juan Carlos Lira Vázquez",
    titulo3: "Padrinos del Novio2",
    madrina2: "Rosio Garibay Cervantes",
    padrino2: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 14 de Septiembre de 2024.",
    ceremoniaHora: "1:00 p.m.",
    ceremoniaNombreLugar: "Capilla Hacienda San Martín",
    ceremoniaLugarMap:
      "Mexico - Toluca Km 44.5-Lote 11, Fracc. Hacienda San Martin, 52740 Ocoyoacac, Méx.",
    ceremoniaUrl: "https://maps.app.goo.gl/us3y164NXF38R6KG6",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 14 de Septiembre de 2024.",
    recepcionHora: "2:30 - 9:00 p.m.",
    recepcionNombreLugar: "Hacienda San Martín",
    recepcionLugarMap:
      "Mexico - Toluca Km 44.5-Lote 11, Fracc. Hacienda San Martin, 52740 Ocoyoacac, Méx.",
    recepcionUrl: "https://maps.app.goo.gl/us3y164NXF38R6KG6",
    imgFondo:
      "https://i.postimg.cc/zBX1Y142/Fondo-de-Pantalla-Flores-Femenino-Crema.png",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo: `https://i.postimg.cc/zBX1Y142/Fondo-de-Pantalla-Flores-Femenino-Crema.png`,
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51447357",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo:
      "https://i.postimg.cc/zBX1Y142/Fondo-de-Pantalla-Flores-Femenino-Crema.png",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "",
    numeroConfirmacion: "+527225038438",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo:
      "https://i.postimg.cc/zBX1Y142/Fondo-de-Pantalla-Flores-Femenino-Crema.png",
    textColor: "",
    numeroNovia: "+527225038438",
    numeroNovio: "+527223340473",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: `https://i.postimg.cc/SKjRk5Tj/TKFN9064-1.jpg`,
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "compartir con nosotros",
    colorFondoName: "",
  };

  const contador = true;
  const padres = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/8PHzWDxj/Portada-1.png",
    "https://i.postimg.cc/y60d10Ph/FELM3517-1.jpg",
    "https://i.postimg.cc/fRRTfph5/my-d2-1.png",
    "https://i.postimg.cc/QxsMHV42/10-1.jpg",
    "https://i.postimg.cc/cC74FNHk/HYFH6651-1.jpg",
    "https://i.postimg.cc/N0VMM4SD/JKZG1017-1.jpg",
    "https://i.postimg.cc/fRRTfph5/my-d2-1.png",
    "https://i.postimg.cc/SKjRk5Tj/TKFN9064-1.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Samuel Arroyo", guess: "3", id: 2 },
  ];

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <div
        style={{
          backgroundImage: `url(${datosSeccionPotadaBoda.imgPortada})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgb(0 0 0 / 22%)" }}
        >
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPotadaBoda.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
          <div className="w-full flex justify-end h-full items-start fixed ">
            <button
              onClick={handlePlayButtonClick}
              className=" text-3xl  rounded animate-bounce bg-black p-1"
            >
              {isMusicPlaying ? "🔊" : "🔇"}
            </button>
          </div>
          <br />
          <br />
          <h1
            className="font-serif sm:text-4xl md:text-[132px] text-white text-center flex"
            data-aos="flip-up"
          >
            {datosSeccionPotadaBoda.esposa} {datosSeccionPotadaBoda.esposo}
          </h1>
          <br />
          <br />
          <p
            className="text-white p-3 rounded-lg font-serif text-2xl text-center"
            data-aos="fade-up"
          >
            {datosSeccionPotadaBoda.titulo || " "} <br />{" "}
            {datosSeccionPotadaBoda.fechaDeBoda
              ? `- ${datosSeccionPotadaBoda.fechaDeBoda} -`
              : ""}
          </p>
          <br />
        </div>
      </div>

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
