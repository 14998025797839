import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvDanielyXimena = () => {
  const [isOpen, setIsOpen] = useState(false);

  const peltaColores = {
    color1: "#cbf0ff",
    color2: "#d9c9fe",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_04/NDAvhMvMamR_y2mate.com---Sabrina-Carpenter-Seamless-Letra-Ingl%C3%A9sEspa%C3%B1ol.mp3",
    imgPortada:
      "https://i.postimg.cc/nLzZKyVK/9cad02ca-f86e-4f64-93d3-15f3582cac08.png",
    nombre: "Daniel y Ximena",
    fechaDeBoda: "15 JUNIO, 2024",
    titulo: "NO FALTES A NUESTROS QUINCE AÑOS",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedas confirmarnos su asistencia agradecemos de antemano tu apreciable compañía",
    aviso: "",
    numeroConfirmacion: "+526644048235",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nuestros XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le damos las gracias a Dios por habernos permitido estar aquí, y a nuestros padres por darnos su amor, apoyo para comenzar una nueva etapa de nuestras vidas y los invitamos a que compartas este día tan espacial para nosotros.",
    fechaNewDate: "June 15, 2024 20:20:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/yNqHn0Kn/Whats-App-Image-2024-04-23-at-11-36-56-AM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/wBhdr3Tz/Whats-App-Image-2024-04-23-at-11-36-55-AM.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    tituloIzquierda: "Nuestros Padres",
    tituloDerecha: "Nuestros Padrinos",
    madre: "Norma Barba",
    padre: "Carlos Alberto Villalva",
    madrina: "Sofía Barba",
    padrino: "Saúl Cadena",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 15 de Junio de 2024.",
    ceremoniaHora: "5:30 p.m.",
    ceremoniaNombreLugar:
      "Parroquia Divino Maestro y Nuestra Señora de Guadalupe",
    ceremoniaLugarMap:
      "San Pedro Mártir 2901, Parque Industrial Otay, 22390 Tijuana, B.C.",
    ceremoniaUrl: "https://maps.app.goo.gl/pasW5H5RoRKzru1ZA",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 15 de Junio de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Hacienda Santa Monica",
    recepcionLugarMap:
      "Mesa de Otay, Vía Rápida Ote., Guadalupe Victoria, 22426 Tijuana, B.C.",
    recepcionUrl: "https://maps.app.goo.gl/RTu4hpSqp7Yn2KoS9",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Nuestro mejor regalo es compartir contigo este gran día, si deseas obsequiarnos algo. puedo sugerir las siguientes opciones: \\n\\n-Lluvia de sobres \\n\\n -Regalo sorpresa",
    link1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/nLzZKyVK/9cad02ca-f86e-4f64-93d3-15f3582cac08.png",
    "https://i.postimg.cc/yNqHn0Kn/Whats-App-Image-2024-04-23-at-11-36-56-AM.jpg",
    "https://i.postimg.cc/wBhdr3Tz/Whats-App-Image-2024-04-23-at-11-36-55-AM.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? <ModalXvCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPortada}
        peltaColores={peltaColores}
      /> : ""}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
