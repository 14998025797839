import { useEffect } from 'react';

const useUpdateMetaTags = (imgUrl) => {
  useEffect(() => {
    if (imgUrl) {
      const metaImage = document.querySelector('meta[property="og:image"]');
      const metaImageWidth = document.querySelector('meta[property="og:image:width"]');
      const metaImageHeight = document.querySelector('meta[property="og:image:height"]');

      if (metaImage) {
        metaImage.setAttribute('content', imgUrl);
      } else {
        const newMetaImage = document.createElement('meta');
        newMetaImage.setAttribute('property', 'og:image');
        newMetaImage.setAttribute('content', imgUrl);
        document.head.appendChild(newMetaImage);
      }

      if (metaImageWidth && metaImageHeight) {
        metaImageWidth.setAttribute('content', '1200');
        metaImageHeight.setAttribute('content', '630');
      } else {
        const newMetaWidth = document.createElement('meta');
        newMetaWidth.setAttribute('property', 'og:image:width');
        newMetaWidth.setAttribute('content', '1200');
        const newMetaHeight = document.createElement('meta');
        newMetaHeight.setAttribute('property', 'og:image:height');
        newMetaHeight.setAttribute('content', '630');
        document.head.appendChild(newMetaWidth);
        document.head.appendChild(newMetaHeight);
      }
    }
  }, [imgUrl]);
};

export default useUpdateMetaTags;
