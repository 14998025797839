import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaKateYUlises = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_05/NEliXRgcUrR_y2mate.com---Un-Amor-Asi-Carin-LeonLETRA-LYRIC.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Ulises",
    esposa: "Kate",
    fechaDeBoda: "28 DICIEMBRE, 2024",
    imgPortada:
      "https://i.postimg.cc/rmmkBJdj/FOTO-10.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 7 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean partícipes de esta unión...",
    fechaNewDate: "December 28, 2024 19:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/8zQT7M3k/FOTO-4.jpg",
    fotoDerecha: "https://i.postimg.cc/Pqdh4hTB/FOTO-3.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Rosa Delia Rendón López",
    padre: "Ezequiel Ruiz Orduño",
    titulo2: "Padres del Novio",
    madrina: "Silvia Ruiz Hernandez",
    padrino: "Antonio Rocha Saucedo",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 28 de Diciembre de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Salón Versalles",
    ceremoniaLugarMap: "Ejidal, 81020 Guasave, Sin.",
    ceremoniaUrl: "https://maps.app.goo.gl/eGzkx3ViQQGxC8956",
    recepcionEnable: false,
    recepcionFecha: "Sábado, 23 de Diciembre de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "El Herradero Jardín y Eventos",
    recepcionLugarMap:
      "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
    recepcionUrl:
      "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "",
    recepcion: "Tómate fotos con nosotros",
    cena: "9:30 p.m. Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Formal color negro",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Hotel El sembrador",
    hotel1lugar: "81000, Centro, 81000 Guasave, Sin.",
    hotel1url: "https://maps.app.goo.gl/M2zFZxpnxzEP2BaJ6",
    hotel2Titulo: "Point City Hotel",
    hotel2lugar: "G. Nelson 188, Centro, 81000 Guasave, Sin.",
    hotel2url: "https://maps.app.goo.gl/ax1xHKvrj5T6r4w86",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 2,
    titulo: "Mesa de regalos",
    descripcion:
      "Lo importante es que nos acompañes, pero si quieres tener un detalle con nosotros te dejamos estas opciones.",
    link1: "https://www.sears.com.mx/Mesa-de-Regalos/126863/kate-y-ulises",
    imgLink1: "https://i.postimg.cc/T3tF3SDs/Sears.webp",
    link1Enable: true,
    link2: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51382225",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "",
    imgLink3: "",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "NO NIÑOS",
    aviso:
      "Por favor, comprende que esta es una celebración exclusiva para adultos. Agradecemos tu cooperación al dejar a los niños en casa para que todos puedan disfrutar de la boda.",
    numeroConfirmacion: "+524776007929",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+524776007929",
    numeroNovio: "+524777083967",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
    imgInvitados:"https://i.postimg.cc/W3b6HyLr/flores-Diamante.png"
  };

  const contador = true;
  const padres = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/rmmkBJdj/FOTO-10.jpg", 
    "https://i.postimg.cc/8zQT7M3k/FOTO-4.jpg", 
    "https://i.postimg.cc/Pqdh4hTB/FOTO-3.png", 
    "https://i.postimg.cc/CMRZDCvb/FOTO-1-2.jpg", 
    "https://i.postimg.cc/Dz5hpDzm/FOTO-2-3.jpg",
    "https://i.postimg.cc/XJzb4Cdb/FOTO-5-2.jpg", 
    "https://i.postimg.cc/ZKyTYVwD/FOTO-6.jpg", 
    "https://i.postimg.cc/kGRd4gvt/FOTO-7.jpg", 
    "https://i.postimg.cc/fWPzh6f9/FOTO-8.jpg", 
    "https://i.postimg.cc/44jY1KMq/FOTO-9.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Familia Ruiz Rendón", guess: "5", id: 1 },
    { name: "Familia Ruiz Mariscales", guess: "6", id: 2 },
    { name: "Familia Reyes Ruiz", guess: "2", id: 3 },
    { name: "Familia Campos Ruiz", guess: "3", id: 4 },
    { name: "Familia Cota Campos", guess: "2", id: 5 },
    { name: "Familia Perez Ruiz", guess: "7", id: 6 },
    { name: "Familia Acosta Perez", guess: "2", id: 7 },
    { name: "Familia Ruiz Camargo", guess: "4", id: 8 },
    { name: "Familia Ruiz Mariscales", guess: "8", id: 9 },
    { name: "Familia Apodaca Ruiz", guess: "4", id: 10 },
    { name: "Silvia Ruiz Hernandez", guess: "1", id: 11 },
    { name: "Familia Montes Rocha", guess: "4", id: 12 },
    { name: "Familia Rocha Segoviano", guess: "2", id: 13 },
    { name: "Maricruz Vera Luna", guess: "1", id: 14 },
    { name: "Norma García", guess: "1", id: 15 },
    { name: "Familia Rojas Escobedo", guess: "4", id: 16 },
    { name: "Berenice García", guess: "1", id: 17 },
    { name: "Adan Anda", guess: "2", id: 18 },
    { name: "Erick Ortiz", guess: "1", id: 19 },
    { name: "Alonso Guzman", guess: "1", id: 20 },
    { name: "Familia Rangel Galván", guess: "2", id: 21 },
    { name: "Fanny Arenas", guess: "2", id: 22 },
    { name: "Familia Rendón Castro", guess: "5", id: 23 },
    { name: "Familia Fonseca Rendón", guess: "3", id: 24 },
    { name: "Walky Fonseca", guess: "2", id: 25 },
    { name: "Abel Fonseca", guess: "2", id: 26 },
    { name: "Rogelio Rendón López", guess: "2", id: 27 },
    { name: "Abraham Ruiz", guess: "2", id: 28 },
    { name: "Irving Ruiz", guess: "2", id: 29 },
    { name: "Familia Ruiz Orduño", guess: "5", id: 30 },
    { name: "Mayra Rendón", guess: "2", id: 31 },
    { name: "Aldo Ruiz", guess: "3", id: 32 },
    { name: "Adela Orduño", guess: "3", id: 33 },
    { name: "Socorro Orduño", guess: "2", id: 34 },
    { name: "Gudelia López Castro", guess: "3", id: 35 },
    { name: "Guadalupe Camargo", guess: "4", id: 36 },
    { name: "Familia Sanchez Montes", guess: "4", id: 37 },
    { name: "Familia Escobedo Imperial", guess: "4", id: 38 },
    { name: "Alam Contreras", guess: "1", id: 39 },
    { name: "Karen de Anda", guess: "2", id: 40 },
    { name: "Rogelio Náchez", guess: "2", id: 41 },
    { name: "Guillermo Breceda", guess: "2", id: 42 },
    { name: "Irma Mares", guess: "2", id: 43 },
    { name: "Fernando Velazquez", guess: "1", id: 44 },
    { name: "Carlos Araujo", guess: "2", id: 45 },
    { name: "Josefa Orduño", guess: "1", id: 46 },
    { name: "Eden Muñoz", guess: "4", id: 47 },
    { name: "Mercedes Orduño", guess: "4", id: 48 },
    { name: "Fabián Sánchez", guess: "2", id: 49 },
    { name: "Rodolfo Vera", guess: "2", id: 50 },
    { name: "Candido Orduño", guess: "5", id: 51 },
    { name: "Alfredo Orduño", guess: "2", id: 52 },
    { name: "David Cruz Campos", guess: "2", id: 53 },
    { name: "Carlos Raúl Ramírez López", guess: "1", id: 54 },
    { name: "Familia Rocha Gutiérrez", guess: "5", id: 55 },
    { name: "Sandra Pacheco", guess: "4", id: 56 },
    { name: "Familia Hernández Guerrero", guess: "2", id: 57 },
    { name: "Familia Gallardo López", guess: "4", id: 58 },
    { name: "Jorge Galván", guess: "1", id: 59 },
    { name: "Juan Camargo", guess: "2", id: 60 },
    { name: "Antonio Rocha Saucedo", guess: "1", id: 61 }
];

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la Boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
       <ModalCard isOpen={isOpen} setIsOpen={setIsOpen} datosSeccionPortada={datosSeccionPotadaBoda}/>
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
