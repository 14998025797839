import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/ThousandYearsPiano.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { MdLinkedCamera } from "react-icons/md";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import ModalCard from "../../../components/Modals/ModalCard";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";

export const BodaNalleliYJair = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDEtHhNDd2R_y2mate.com---PERFECT-Ed-Sheeran-Saxophone-Version.mp3",
    textoConfirmacion: "a nuestra boda",
    esposo: "Jair ",
    esposa: "Nalleli",
    fechaDeBoda: "30 noviembre, 2024",
    fechaNewDate: "November 30, 2024 15:00:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/B6xqCRfM/Principal.jpg",
    numeroNovia: "+525585354011",
    numeroNovio: "+525580186594",
    novios: true,
    titulo: "Nuestra Boda",
  };

  const peltaColores = {
    color1: "#f2e0d3",
    color2: "#ede7e1",
    // #f2e0d3
    messageVestimenta: "",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Las grandes aguas no pueden apagar el amor, ni los ríos arrastrarlo. Cantares 8:7",
    fechaNewDate: "November 30, 2024 15:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/J05zRvTG/Sección_de_galeria_8.jpg",
    fotoDerecha: "https://i.postimg.cc/zXpG6mbQ/Sección_de_galeria__4.jpg",
    textColor:""
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Nalleli y Jair!",
    entradasText: "disfrutar nuestro día especial",
    colorFondoName: "#b3abab",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Alicia Romero Mondragón",
    esposaPadre: "Marco Antonio Cejas López",
    esposoMadre: "Ana María Avila García",
    esposoPadre: "Ismael Camacho Nogueda",
    textColor:""
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 30 de noviembre de 2024.",
    ceremoniaHora: "3:30 p.m.",
    ceremoniaNombreLugar: "Jardín Thesan",
    ceremoniaLugarMap: "Tulio Estrada 125, Miguel Hidalgo, 62580 Temixco, Mor.",
    ceremoniaUrl: "https://maps.app.goo.gl/4X5GYRtgDfoBpYax5",
    recepcionFecha: "Sábado, 30 de noviembre de 2024.",
    recepcionHora: "6:00 p.m.",
    recepcionNombreLugar: "Jardín Thesan",
    recepcionLugarMap: "Tulio Estrada 125, Miguel Hidalgo, 62580 Temixco, Mor.",
    recepcionUrl: "https://maps.app.goo.gl/4X5GYRtgDfoBpYax5",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sácale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Hostería Las Quintas",
    hotel1lugar:
      "Blvd. Gustavo Díaz Ordaz 9, Cantarranas, 62448 Cuernavaca, Mor.",
    hotel1url: "https://maps.app.goo.gl/CCUekv7fLBLC77Q9A",
    hotel2Titulo: "Hotel Holiday Inn Cuernavaca",
    hotel2lugar:
      "Blvd. Gustavo Díaz Ordaz 86, Acapantzingo, 62440 Cuernavaca, Mor.",
    hotel2url: "https://maps.app.goo.gl/pAj5uSrkqdrQ5MQq5",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 2,
    titulo: "Mesa de regalos",
    descripcion:
      "Para nosotros tu presencia es nuestro mejor regalo, pero si deseas hacernos llegar un obsequio, te sugerimos las siguientes opciones",
    link1: "https://www.elpalaciodehierro.com/buscar?eventId=384642",
    imgLink1: "https://i.postimg.cc/4ygFxNnh/logo-palacio-de-hierro.png",
    link1Enable: true,
    link2: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51347771",
    imgLink2: "https://i.postimg.cc/gJndtPbL/EFD44-F23-84-B7-4-BD5-A554-CB4737-F8185-F.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor:"",
  };

  const datosSeccionVestimenta = {
    vestimenta: (
      <div>
        COCKTAIL <br /><br />
        <a href="https://acrobat.adobe.com/id/urn:aaid:sc:VA6C2:5b7c5ae3-0b82-45f7-953a-57bf8f5e57c0">Ver Más Ideas...</a>
      </div>
    ),
    messageVestimenta: "El blanco esta apartado para la novia.",
    urlVestimentaMujer: "https://i.postimg.cc/W19TJnWQ/Dise-o-sin-t-tulo-1.png",
    urlVestimentaHombre: "https://i.postimg.cc/FsV9hmjF/Dise-o-sin-t-tulo-2.png",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const imagenesFondo = {
    fondoInvitados: 'url("https://i.postimg.cc/sgNDfpJ9/Foto_para_frase.jpg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [pases, setPases] = useState(0);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/J05zRvTG/Sección_de_galeria_8.jpg",
    "https://i.postimg.cc/B6xqCRfM/Principal.jpg",
    "https://i.postimg.cc/zXpG6mbQ/Sección_de_galeria__4.jpg",
    "https://i.postimg.cc/sgNDfpJ9/Foto_para_frase.jpg",
    "https://i.postimg.cc/Ss8KvJCL/Sección_de_galeria__1.jpg",
    "https://i.postimg.cc/DwZwKC1w/Sección_de_galeria__2.jpg",
    "https://i.postimg.cc/CMG1CnFS/Sección_de_galeria__3.jpg",
    "https://i.postimg.cc/KYCzfnkv/Foto_final.jpg",
    "https://i.postimg.cc/SRhKFkfB/Sección_de_galeria__5.jpg",
    "https://i.postimg.cc/YqtSd29g/WhatsApp_Image_2024-04-29_at_20.31.25.jpg",
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Samuel Arroyo", guess: "3", id: 2 },
    { name: "Mamá", guess: "1", id: 3 },
    { name: "Papá", guess: "1", id: 4 },
    { name: "Fernanda Bouchain", guess: "1", id: 5 },
    { name: "Ángel", guess: "1", id: 6 },
    { name: "Nadia y Danahe", guess: "1", id: 7 },
    { name: "Alec", guess: "1", id: 8 },
    { name: "Cata Chávez", guess: "1", id: 9 },
    { name: "Selene, Valeria y Daniel", guess: "3", id: 10 },
    { name: "Chely y Tavo", guess: "2", id: 11 },
    { name: "Dana Cejas", guess: "2", id: 12 },
    { name: "Paulo Díaz", guess: "2", id: 13 },
    { name: "Tanya y Arturo", guess: "2", id: 14 },
    { name: "Armando y Tanya", guess: "3", id: 15 },
    { name: "Francisco y Karen", guess: "2", id: 16 },
    { name: "Rocío y Rodrigo", guess: "2", id: 17 },
    { name: "Liz y Óscar", guess: "2", id: 18 },
    { name: "Carla Rodríguez", guess: "2", id: 19 },
    { name: "Víctor y Juan Carlos", guess: "2", id: 20 },
    { name: "Martín Estrada", guess: "1", id: 21 },
    { name: "Ulises Alatorre", guess: "2", id: 22 },
    { name: "Yunuen Luna", guess: "2", id: 23 },
    { name: "Marco Arellano", guess: "2", id: 24 },
    { name: "Daniela Estrada", guess: "2", id: 25 },
    { name: "Emilio e Irma", guess: "2", id: 26 },
    { name: "Fabiola y Marú", guess: "2", id: 27 },
    { name: "Soila y familia", guess: "3", id: 28 },
    { name: "Cristian", guess: "2", id: 29 },
    { name: "Angélica", guess: "1", id: 30 },
    { name: "Sergio", guess: "1", id: 31 },
    { name: "Rodrigo", guess: "1", id: 32 },
    { name: "Tía Emma, Mari Jos y Wal", guess: "3", id: 33 },
    { name: "Tía Lucha", guess: "1", id: 34 },
    { name: "Tío Chucho", guess: "1", id: 35 },
    { name: "Tío Nato", guess: "1", id: 36 },
    { name: "Jessica Romero", guess: "1", id: 37 },
    { name: "Tania Romero", guess: "2", id: 38 },
    { name: "Mamá", guess: "1", id: 39 },
    { name: "Papá", guess: "1", id: 40 },
    { name: "Antonio Oviedo", guess: "2", id: 41 },
    { name: "Marío Avila", guess: "2", id: 42 },
    { name: "Selene Avila", guess: "2", id: 43 },
    { name: "Esther Avila", guess: "2", id: 44 },
    { name: "Alejandra Calderon", guess: "2", id: 45 },
    { name: "Carmela Avila", guess: "2", id: 46 },
    { name: "Salvador Morales", guess: "2", id: 47 },
    { name: "Mauricio Morales", guess: "2", id: 48 },
    { name: "Pamela Morales", guess: "2", id: 49 },
    { name: "José Luis Avila", guess: "1", id: 50 },
    { name: "Rodrigo y Favi", guess: "2", id: 51 },
    { name: "Francisco Vargas", guess: "2", id: 52 },
    { name: "Juan Antonio González", guess: "2", id: 53 },
    { name: "José Alfredo Miralrio", guess: "2", id: 54 },
    { name: "Mauro Miranda", guess: "2", id: 55 },
    { name: "Brenda Cadena", guess: "2", id: 56 },
    { name: "Rafael Montez", guess: "2", id: 57 },
    { name: "Itzamne López", guess: "2", id: 58 },
    { name: "Guillermo Arzola", guess: "1", id: 59 },
    { name: "Carlos Sánchez", guess: "1", id: 60 },
    { name: "Rafael Ramos", guess: "2", id: 61 },
    { name: "Johan Clark", guess: "2", id: 62 },
    { name: "Eli Camacho", guess: "2", id: 63 },
    { name: "Tía Bety", guess: "1", id: 64 },
    { name: "Luis Manuel Camacho", guess: "2", id: 65 },
    { name: "Susana Camacho", guess: "2", id: 66 },
    { name: "José Antonio Luna", guess: "2", id: 67 },
    { name: "Mario González", guess: "1", id: 68 },
    { name: "Abraham González", guess: "1", id: 69 },
    { name: "Daniel Camacho", guess: "2", id: 70 },
    { name: "Krystel", guess: "2", id: 71 },
    { name: "Invitado", guess: "1", id: 72 },
    { name: "Invitado", guess: "1", id: 73 },
    { name: "Invitado", guess: "1", id: 74 },
    { name: "Invitado", guess: "1", id: 75 },
    { name: "Invitado", guess: "1", id: 76 },
    { name: "Invitado", guess: "2", id: 77 },
    { name: "Invitado", guess: "2", id: 78 },
    { name: "Invitado", guess: "2", id: 79 },
    { name: "Invitado", guess: "2", id: 80 },
    { name: "Invitado", guess: "2", id: 81 },
  ];
  
  

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    console.log(nameId, numPases);
    setPases(numPases);
    const guestId = parseInt(nameId);

    const findName = names.find((e) => e.id === guestId);

    if (findName) {
      console.log(findName.name);

      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      console.log("Nombre no encontrado");
      window.location.href = "/";
    }

    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);

    const audioElement = document.getElementById("backgroundMusic");

    if (!isMusicPlaying && audioElement) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
  };

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccion1}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccion1} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Invitados
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        imagenesFondo={imagenesFondo}
        datosInvitados={datosInvitados}
      />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color:""
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicación
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.postimg.cc/8PhVvs8f/Recepcio-n-1.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.153908114133!2d-99.21578152348084!3d18.835823282319943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdd8eb6cc84dcf%3A0xc1e92ee0097a849d!2sThesan%20Jard%C3%ADn!5e0!3m2!1ses-419!2smx!4v1714508629624!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2  bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia y Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />

      <VestimentaXvComponent
        peltaColores={peltaColores}
        datosSeccionVestimenta={datosSeccionVestimenta}
      />

      <Hospedaje peltaColores={peltaColores} datosSeccion7={datosSeccion7} />

      <div
        style={{
          backgroundImage: `url(${datosSeccionRegalos.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color: datosSeccionRegalos.textColor
            ? datosSeccionRegalos.textColor
            : "",
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col py-[5vh] px-3 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionRegalos.titulo || "Mesa de regalos"}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccionRegalos.descripcion.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>

          <div
            className={`grid sm:grid-cols-1 md:grid-cols-${
              datosSeccionRegalos.cantidadRegalos || 2
            } w-[80%] pt-[8vh]`}
          >
            <div
              style={{
                backgroundImage: `url(${
                  datosSeccionRegalos.imgLink1 ||
                  "https://static.vecteezy.com/system/resources/previews/019/766/223/non_2x/amazon-logo-amazon-icon-transparent-free-png.png"
                })`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "120% 100%",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color2,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
              {datosSeccionRegalos.link1Enable ? (
                <a
                  href={datosSeccionRegalos.link1}
                  class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Ver mesa
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              ) : (
                ""
              )}
            </div>

            {datosSeccionRegalos.cantidadRegalos == 2 ||
            datosSeccionRegalos.cantidadRegalos == 3 ? (
              <div
                style={{
                  backgroundImage: `url(${
                    datosSeccionRegalos.imgLink2 ||
                    "https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png"
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "120% 100%",
                  display: "flex",
                  backgroundPosition: "center center",
                  backgroundColor: peltaColores.color2,
                }}
                className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
                data-aos="fade-right"
              >
                {datosSeccionRegalos.link2Enable ? (
                  <a
                    href={datosSeccionRegalos.link2}
                    class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Ver mesa
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {datosSeccionRegalos.cantidadRegalos == 3 ? (
              <div
                style={{
                  backgroundImage: `url(${
                    datosSeccionRegalos.imgLink3 ||
                    "https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png"
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                  backgroundColor: peltaColores.color1,
                }}
                className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
                data-aos="fade-right"
              >
                {datosSeccionRegalos.link3Enable ? (
                  <a
                    href={datosSeccionRegalos.link3}
                    class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Ver mesa
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: datosSeccion1.imgFondo || "",
          backgroundRepeat: "repeat",
          backgroundSize: "cover", // or "100% auto"
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: datosSeccion1.textColorConfirm || "",
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col py-[5vh] justify-center items-center w-full"
      >
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          {datosSeccion1.tituloConfirm || " Nuestro Día Especial"}
        </h1>
        <br />
        <p
          className=" text-center text-white p-[5vh] font-serif text-xl sm:w-[80%] md:w-[50%] rounded-2xl"
          data-aos="flip-up"
          style={{
            background: "rgb(0,0,0)",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0.7596288515406162) 28%, rgba(0,0,0,0.7596288515406162) 68%)",
          }}
        >
          Esperamos contar con tu presencia en nuestra boda y a los niños les
          deseamos dulces sueños en casa (no niños)
        </p>
        <br />
        <br />
        <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
          {datosSeccion1.aviso || ""}
        </p>
        <br />
        <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
          {images?.map((imageUrl, index) => (
            <div data-aos="fade-down-right" className="rolImg" key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-auto rounded-xl max-h-[60vh]"
              />
            </div>
          ))}
        </Slider>
        <br />
        <br />
        <h1
          className="font-serif text-center p-5 sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          Tu presencia hará nuestra boda aún más especial <br /> ¡Te esperamos!
          Nalleli y Jair
        </h1>
        <br />
        <br />
        <div
          data-aos="fade-down-left"
          className="md:w-[50%] sm:w-[80%]  mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
        >
          <h2 className="text-2xl font-bold mb-4 justify-center flex text-black">
            Confirmación de Asistencia {datosSeccion1.textoConfirmacion}{" "}
            <FaWhatsapp className="text-green-600 ml-2" />
          </h2>
          <div className="flex justify-center items-center">
            <div className="w-full text-center bg-green-700 p-2 mt-5 mx-2 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccion1.numeroNovia
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white text-center md:w-[60%] md:text-xl text-auto"
              >
                Confirma tu invitación con la novia 💌
              </a>
            </div>
            <div className="w-full text-center bg-green-700 p-2 mt-5 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccion1.numeroNovio
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white text-center md:w-[60%] md:text-xl text-auto"
              >
                Confirma tu invitación con el novio 💌
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
