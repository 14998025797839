import React, { useState } from 'react';

export const ImagenesIconos = () => {
  const [filtro, setFiltro] = useState('');

  const imagenes = [
    { url: "https://i.postimg.cc/BnGrrwQ0/Dise-o-sin-t-tulo.png", nombre: "Diseño sin título 1" },
    { url: "https://i.postimg.cc/hG8tFdqC/Dise-o-sin-t-tulo-31.png", nombre: "Diseño sin título 31" },
    { url: "https://i.postimg.cc/QtXxjd8j/Dise-o-sin-t-tulo-32.png", nombre: "Diseño sin título 32" },
    { url: "https://i.postimg.cc/mkkLFp1q/Dise-o-sin-t-tulo-41.png", nombre: "Diseño sin título 41" },
    { url: "https://i.postimg.cc/nz0VrfrB/Dise-o-sin-t-tulo-42.png", nombre: "Diseño sin título 42" },
    { url: "https://i.postimg.cc/90kWPPGf/Flyer-Despedida-de-Soltero-Ilustraci-n-Rojo-1.png", nombre: "Flyer Despedida de Soltero 1" },
    { url: "https://i.postimg.cc/GhB3LNCT/Pink-Vibrant-Cowgirl-Hat-Circle-Sticker.png", nombre: "Pink Vibrant Cowgirl Hat" },
    { url: "https://i.postimg.cc/htTSxdhS/Nashville-Bachelorette-Party-Logo.png", nombre: "Nashville Bachelorette Party" },
    { url: "https://i.postimg.cc/KYVSHc19/Dise-o-sin-t-tulo-54.png", nombre: "Diseño sin título 54" },
    { url: "https://i.postimg.cc/ZRdttk3J/Dise-o-sin-t-tulo-55.png", nombre: "Diseño sin título 55" },
    { url: "https://i.postimg.cc/LshFDg8Y/Dise-o-sin-t-tulo-56.png", nombre: "Diseño sin título 56" },
    { url: "https://i.postimg.cc/Hk6Cb9d1/Dise-o-sin-t-tulo-57.png", nombre: "Diseño sin título 57" },
    { url: "https://i.postimg.cc/cCRpCQXj/Dise-o-sin-t-tulo-58.png", nombre: "Diseño sin título 58" },
    { url: "https://i.postimg.cc/8cg8TyBM/Dise-o-sin-t-tulo-59.png", nombre: "Diseño sin título 59" },
    { url: "https://i.postimg.cc/KctS02xN/Dise-o-sin-t-tulo-53.png", nombre: "Diseño sin título 53" },
    { url: "https://i.postimg.cc/rFb1svRB/fa90720c3f648e3badafc2d8cacb2a93.png", nombre: "Diseño fa90720c3f648e3badafc2d8cacb2a93" },
    { url: "https://i.postimg.cc/1zyf3HMx/Cartel-P-ster-Cumplea-os-de-50-Elegante-Negro-y-Dorado-2.png", nombre: "Cartel Cumpleaños 50 Negro y Dorado" },
    { url: "https://i.postimg.cc/PfvZcSRK/50s.png", nombre: "50s" },
    { url: "https://i.postimg.cc/J4wJWbw8/Tarjeta-Cuadrada-Recuerdo-Boda-Minimalista-Blanco.png", nombre: "Tarjeta Boda Minimalista" },
    { url: "https://i.postimg.cc/HkYrpzBq/Dise-o-sin-t-tulo-34.png", nombre: "Diseño sin título 34" },
    { url: "https://i.postimg.cc/bwmsdn5s/Dise-o-sin-t-tulo-37.png", nombre: "Diseño sin título 37" },
    { url: "https://i.postimg.cc/mrhh9DxV/Dise-o-sin-t-tulo-38.png", nombre: "Diseño sin título 38" },
    { url: "https://i.postimg.cc/Dy2vsZLs/Cartel-P-ster-Cumplea-os-de-50-Elegante-Negro-y-Dorado-1.png", nombre: "Cartel Cumpleaños 50 Negro y Dorado 1" },
    { url: "https://i.postimg.cc/qqrk1BFP/Dise-o-sin-t-tulo-27.png", nombre: "Diseño sin título 27" },
    { url: "https://i.postimg.cc/VvFzpQQd/Dise-o-sin-t-tulo-28.png", nombre: "Diseño sin título 28" },
    { url: "https://i.postimg.cc/YqjPJBwZ/Dise-o-sin-t-tulo-29.png", nombre: "Diseño sin título 29" },
    { url: "https://i.postimg.cc/KvVWbpGz/Dise-o-sin-t-tulo-33.png", nombre: "Diseño sin título 33" },
    { url: "https://i.postimg.cc/rwrPwGs9/Dise-o-sin-t-tulo-44.png", nombre: "Diseño sin título 44" },
    { url: "https://i.postimg.cc/s2PHpVpQ/Dise-o-sin-t-tulo-46.png", nombre: "Diseño sin título 46" },
    { url: "https://i.postimg.cc/hGR3zJ7R/Dise-o-sin-t-tulo-47.png", nombre: "Diseño sin título 47" },
    { url: "https://i.postimg.cc/NMgJDW4N/Dise-o-sin-t-tulo-48.png", nombre: "Diseño sin título 48" },
    { url: "https://i.postimg.cc/TwCk4CNT/Dise-o-sin-t-tulo-49.png", nombre: "Diseño sin título 49" },
    { url: "https://i.postimg.cc/6q5tRjgR/Flyer-Despedida-de-Soltero-Ilustraci-n-Rojo-8.png", nombre: "Flyer Despedida de Soltero 8" },
    { url: "https://i.postimg.cc/yxJpw0St/trajes-hombre.png", nombre: "Trajes Hombre" },
    { url: "https://i.postimg.cc/6Q9jBkvd/vestidos-mujer.png", nombre: "Vestidos Mujer" },
    { url: "https://i.postimg.cc/DZNBdmYg/png-transparent-ballroom-dance-computer-icons-dance-party-ballroom-miscellaneous-text-sport-thumbnai.png", nombre: "Ballroom Dance Icon" },
    { url: "https://i.postimg.cc/XNcgYHnL/chips-snack-line-icon-illustration-vector-removebg-preview-1.png", nombre: "Chips Snack Line Icon" },
    { url: "https://i.postimg.cc/vT39LKBd/1930712-1-1.png", nombre: "1930712-1" },
    { url: "https://i.postimg.cc/5NrQ4LQp/857681-2-1.png", nombre: "857681-2" },
    { url: "https://i.postimg.cc/66cGSTV6/1930712.png", nombre: "1930712" },
    { url: "https://i.postimg.cc/G34HMzpK/10905489-1.png", nombre: "10905489-1" },
    { url: "https://i.postimg.cc/W1FFhnBt/After-Party-Logo-black-version-580x386-removebg-preview.png", nombre: "After Party Logo Black" },
    { url: "https://i.postimg.cc/Vvs6WBdy/Cuenta-Clave-012-180-015016204780-2-23-removebg-preview.png", nombre: "Cuenta Clave" },
    { url: "https://i.postimg.cc/2yH5cbZ1/23-removebg-preview.png", nombre: "23" },
    { url: "https://i.postimg.cc/R0nFZzXW/Dise-o-sin-t-tulo-zip-10.png", nombre: "Diseño sin título zip 10" },
    { url: "https://i.postimg.cc/y8xx2RRX/Dise-o-sin-t-tulo-zip-11.png", nombre: "Diseño sin título zip 11" },
    { url: "https://i.postimg.cc/7ZRh4LMv/Dise-o-sin-t-tulo-zip-12.png", nombre: "Diseño sin título zip 12" },
    { url: "https://i.postimg.cc/xjGXmnYH/Dise-o-sin-t-tulo-zip-13.png", nombre: "Diseño sin título zip 13" },
    { url: "https://i.postimg.cc/SNddLBZp/Dise-o-sin-t-tulo-zip-14.png", nombre: "Diseño sin título zip 14" },
    { url: "https://i.postimg.cc/BnGrrwQ0/Dise-o-sin-t-tulo.png", nombre: "Diseño sin título 1" },
    { url: "https://i.postimg.cc/V6ndDcCx/Dise-o-sin-t-tulo-zip-15.png", nombre: "Diseño sin título 2" },
    { url: "https://i.postimg.cc/5tZXBt65/Dise-o-sin-t-tulo-zip-16.png", nombre: "Diseño sin título 3" },
    { url: "https://i.postimg.cc/W11qjKBZ/Dise-o-sin-t-tulo-zip-17.png", nombre: "Diseño sin título 4" },
    { url: "https://i.postimg.cc/X73y1XxQ/Dise-o-sin-t-tulo-zip-18.png", nombre: "Diseño sin título 5" },
    { url: "https://i.postimg.cc/CLNn3YQY/Dise-o-sin-t-tulo-zip-19.png", nombre: "Diseño sin título 6" },
    { url: "https://i.postimg.cc/Lsf9M38f/Dise-o-sin-t-tulo-zip-2.png", nombre: "Diseño sin título 7" },
    { url: "https://i.postimg.cc/Mpg64Fqq/Dise-o-sin-t-tulo-zip-2.png", nombre: "Diseño sin título 8" },
    { url: "https://i.postimg.cc/rwfWrc1g/Dise-o-sin-t-tulo-zip-20.png", nombre: "Diseño sin título 9" },
    { url: "https://i.postimg.cc/xdqmG7gQ/Dise-o-sin-t-tulo-zip-21.png", nombre: "Diseño sin título 10" },
    { url: "https://i.postimg.cc/76kxPJ5R/Dise-o-sin-t-tulo-zip-3.png", nombre: "Diseño sin título 11" },
    { url: "https://i.postimg.cc/3JJxDGt5/Dise-o-sin-t-tulo-zip-3.png", nombre: "Diseño sin título 12" },
    { url: "https://i.postimg.cc/5N7N5trH/Dise-o-sin-t-tulo-zip-4.png", nombre: "Diseño sin título 13" },
    { url: "https://i.postimg.cc/1RKztBcr/Dise-o-sin-t-tulo-zip-5.png", nombre: "Diseño sin título 14" },
    { url: "https://i.postimg.cc/wMFxGxTG/Dise-o-sin-t-tulo-zip-6.png", nombre: "Diseño sin título 15" },
    { url: "https://i.postimg.cc/fyMTCSZd/Dise-o-sin-t-tulo-zip-7.png", nombre: "Diseño sin título 16" },
    { url: "https://i.postimg.cc/d12VGjMv/Dise-o-sin-t-tulo-zip-8.png", nombre: "Diseño sin título 17" },
    { url: "https://i.postimg.cc/768LCQWm/Dise-o-sin-t-tulo-zip-9.png", nombre: "Diseño sin título 18" },
    { url: "https://i.postimg.cc/CMmWX8Xw/l.png", nombre: "Logo 1" },
    { url: "https://i.postimg.cc/0NsWb5Ft/l-3.png", nombre: "Logo 2" },
    { url: "https://i.postimg.cc/YSFJ4yd3/l-4.png", nombre: "Logo 3" },
    { url: "https://i.postimg.cc/9Xsfnxz5/24.png", nombre: "Logo 4" },
    { url: "https://i.postimg.cc/2j16Tpk7/25.png", nombre: "Logo 5" },
    { url: "https://i.postimg.cc/65kTWV9S/sears-2-logo-png-transparent.png", nombre: "Logo Sears" },
    { url: "https://i.postimg.cc/PJBfqhkZ/liverpool-logo-black-and-white.png", nombre: "Logo Liverpool" },
    { url: "https://i.postimg.cc/DzKgXbcp/sanborns-logo-png-transparent.png", nombre: "Logo Sanborns" },
    { url: "https://i.postimg.cc/hPLbqGn9/l-6.png", nombre: "Logo 6" },
    { url: "https://i.postimg.cc/wT4JzRPR/l-7.png", nombre: "Logo 7" },
    { url: "https://i.postimg.cc/FK4KX99J/l-8.png", nombre: "Logo 8" },
    { url: "https://i.postimg.cc/K8NWm2LH/l-9.png", nombre: "Logo 9" },
    { url: "https://i.postimg.cc/26FG7dwX/l-11.png", nombre: "Logo 10" },
    { url: "https://i.postimg.cc/wBF357NQ/l-12.png", nombre: "Logo 11" },
    { url: "https://i.postimg.cc/yYLHTtYc/l-13.png", nombre: "Logo 12" },
    { url: "https://i.postimg.cc/xTDxmzMZ/IMG-5645.png", nombre: "Imagen personal 1" },
    { url: "https://i.postimg.cc/ZKWbWXPH/20-BBE563-49-CD-45-CC-BC7-F-2-BAEFA492545.png", nombre: "Imagen personal 2" },
    { url: "https://i.postimg.cc/hjGFH7ps/28553-AD3-7-FE5-4-A42-9-D15-C98-FEA6-EF7-D2.png", nombre: "Imagen personal 3" },
    { url: "https://i.postimg.cc/0QtvQCbh/2-F5222-D3-C9-B3-4837-B1-EF-64-FDE38584-D6.png", nombre: "Imagen personal 4" },
    { url: "https://i.postimg.cc/MTrkxKLS/3514-A8-FE-0016-4-BA4-A4-DD-E333917-F8485.png", nombre: "Imagen personal 5" },
    { url: "https://i.postimg.cc/SQBSzDZp/3906-DA55-1550-4-CBF-A065-BC90-EED65599.png", nombre: "Imagen personal 6" },
    { url: "https://i.postimg.cc/y8CM7GLk/505-E5-BC9-3076-4-A4-F-A9-B9-648610-E841-A9.png", nombre: "Imagen personal 7" },
    { url: "https://i.postimg.cc/Mp0hHYLt/5-A39-F528-4164-42-E7-AE8-F-8475-A3522612.png", nombre: "Imagen personal 8" },
    { url: "https://i.postimg.cc/4nkSNKbr/681-FA20-F-7-A31-4-B54-91-DB-CBC878633-FE7.png", nombre: "Imagen personal 9" },
    { url: "https://i.postimg.cc/59R1WncC/69-ACC199-B167-4617-B867-9-F8-AA51719-B6.png", nombre: "Imagen personal 10" },
    { url: "https://i.postimg.cc/Qdpr5DFQ/6-B999069-2310-48-FB-939-F-F2-CA9-EB1-BF74.png", nombre: "Imagen personal 11" },
    { url: "https://i.postimg.cc/1zBhCKc4/6-D28-DC1-D-6-BB4-4758-B02-E-E3510-FA10-AAA.png", nombre: "Imagen personal 12" },
    { url: "https://i.postimg.cc/jqwq5G4X/6-E0724-DC-ED1-F-4-ADD-9-BF7-D04-EC95701-C4.png", nombre: "Imagen personal 13" },
    { url: "https://i.postimg.cc/wvrrZ4tx/7-AB8-AFA7-81-ED-452-D-9-E29-5-CA9-AE2437-EA.png", nombre: "Imagen personal 14" },
    { url: "https://i.postimg.cc/vHnRFyLJ/7-E163-FE5-B598-4-D3-B-8279-70858212351-F.png", nombre: "Imagen personal 15" },
    { url: "https://i.postimg.cc/tJwHdCd5/946-BB3-BC-200-B-4-AB4-A902-E7164-D9-EAE27.png", nombre: "Imagen personal 16" },
    { url: "https://i.postimg.cc/DzR9gdHG/9494-E52-F-8-D7-E-47-E9-AFF4-5-F88-FB8-BDD52.png", nombre: "Imagen personal 17" },
    { url: "https://i.postimg.cc/tRWzP9g3/95093-E43-3764-40-EA-B2-CC-EA2431116-BAB.png", nombre: "Imagen personal 18" },
    { url: "https://i.postimg.cc/W1yykv1V/AEC49-DC4-C9-DC-4903-8101-56-D76-DDFD97-B.png", nombre: "Imagen personal 19" },
    { url: "https://i.postimg.cc/3NhfJtdM/AFE75-F38-D4-EB-42-DB-B59-A-1-B3723-C968-CB.png", nombre: "Imagen personal 20" },
    { url: "https://i.postimg.cc/MKHwYZ1X/B91-CA5-F7-43-C4-4-A69-B987-F9-CB86-F2-C35-B.png", nombre: "Imagen personal 21" },
    { url: "https://i.postimg.cc/26bnBhb2/C6-A92743-07-A2-4-A99-9-BCF-4-F151-EC6-C55-E.png", nombre: "Imagen personal 22" },
    { url: "https://i.postimg.cc/7h8DLwKP/D50-C69-AF-8-EF4-40-F1-BB5-C-A5-B35042473-D.png", nombre: "Imagen personal 23" },
    { url: "https://i.postimg.cc/TYzv0RVz/D5-A5-B086-E47-F-4558-A539-0387-B4-E79-FAD.png", nombre: "Imagen personal 24" },
    { url: "https://i.postimg.cc/mg0Q4kWB/F1-FF88-CB-B5-A9-4-CF5-A518-38-A6011285-CB.png", nombre: "Imagen personal 25" }
];


  const handleCopy = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert("¡Enlace copiado!");
    });
  };

  const handleFiltroChange = (e) => {
    setFiltro(e.target.value);
  };

  const imagenesFiltradas = imagenes.filter(imagen =>
    imagen.nombre.toLowerCase().includes(filtro.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Galería de Imágenes</h1>
      <div className="mb-6">
        <input
          type="text"
          placeholder="Filtrar por nombre"
          value={filtro}
          onChange={handleFiltroChange}
          className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {imagenesFiltradas.map((imagen, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
            <img src={imagen.url} alt={imagen.nombre} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="font-semibold text-lg mb-2 text-gray-800">{imagen.nombre}</h3>
              <button
                onClick={() => handleCopy(imagen.url)}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
              >
                Copiar enlace
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
