import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "../Boda-plata/assets/maroon.mp3";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import axios from "axios";
import { FaBuilding, FaChampagneGlasses } from "react-icons/fa6";
import { PiChurchLight } from "react-icons/pi";

export const BodaLupita = () => {
    const [seccionPortadaData, setSeccionPortadaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [seccionImagenes, setSeccionImagenes] = useState([]);
    const [estado, setEstado] = useState({});
  
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idClient = searchParams.get("id");
  
    // Función para hacer la solicitud HTTP
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/Eventos/${idClient}`);
        setEstado(response.data);
        console.log(response.data);
        try {
          setLoading(true);
          const response = await axios.get(
            `/api/Seccionportada/obtenerBoda/${idClient}`
          );
          setSeccionPortadaData(response.data);
          console.log(response.data);
          try {
            const responseImg = await axios.get(`/api/Imagenes?id=${idClient}`);
            const mapImg = responseImg.data.map((item) => item.urlFoto);
            setSeccionImagenes(mapImg);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            alert("Error");
          }
        } catch (error) {
          setLoading(false);
          alert("Error");
        }
      } catch (error) {
        setLoading(false);
        alert("Error");
      }
    };
  
    // Llamar a la función fetchData cuando el componente se monte
    useEffect(() => {
      fetchData();
    }, []);
  
    const peltaColores = {
      color1: seccionPortadaData?.colores?.color1 || "",
      color2: seccionPortadaData?.colores?.color2 || "",
    };
  
    const datosSeccionPotadaBoda = {
      cancion: seccionPortadaData?.portada?.cancion || "",
      textoConfirmacion: seccionPortadaData?.portada?.textoConfirmacion || "",
      esposo: seccionPortadaData?.portada?.esposo || "",
      esposa: seccionPortadaData?.portada?.esposa || "",
      fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
      imgPortada: seccionPortadaData?.portada?.imgPortada || "",
      titulo: seccionPortadaData?.portada?.titulo || "",
    };
  
    const datosSeccionContador = {
      titulo: seccionPortadaData?.contador?.titulo || "",
      descipcion: seccionPortadaData?.contador?.descripcion || "",
      fechaNewDate: seccionPortadaData?.contador?.fechaNewDate || "",
      fotoIzquierda: seccionPortadaData?.contador?.fotoIzquierda || "",
      fotoDerecha: seccionPortadaData?.contador?.fotoDerecha || "",
      marcoEnable: seccionPortadaData?.contador?.marcoEnable,
      imgFondo: seccionPortadaData?.contador?.imgFondo || "",
      textColor: seccionPortadaData?.contador?.textColor || "",
      contador: seccionPortadaData?.contador?.contador,
    };
  
    const datosInvitados = {
      titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
      entradasText: "disfrutar nuestro dia especial",
    };
  
    const datosSeccionPadres = {
      cantidad: seccionPortadaData?.padres?.cantidad || "",
      titulo: seccionPortadaData?.padres?.titulo || "",
      descipcion: seccionPortadaData?.padres?.descripcion || "",
      titulo1: seccionPortadaData?.padres?.titulo1 || "",
      madre: seccionPortadaData?.padres?.madre || "",
      padre: seccionPortadaData?.padres?.padre || "",
      titulo2: seccionPortadaData?.padres?.titulo2 || "",
      madrina: seccionPortadaData?.padres?.madrina || "",
      padrino: seccionPortadaData?.padres?.padrino || "",
      titulo3: seccionPortadaData?.padres?.titulo3 || "",
      madrina2: seccionPortadaData?.padres?.madrina2 || "",
      padrino2: seccionPortadaData?.padres?.padrino2 || "",
      imgFondo: seccionPortadaData?.padres?.imgFondo || "",
      textColor: seccionPortadaData?.padres?.textColor || "",
    };
  
    const datosSeccionCeremonia = {
      dobleEnable: seccionPortadaData?.ceremonia?.dobleEnable,
      ceremoniaEnable: seccionPortadaData?.ceremonia?.ceremoniaEnable,
      ceremoniaFecha: seccionPortadaData?.ceremonia?.ceremoniaFecha || "",
      ceremoniaHora: seccionPortadaData?.ceremonia?.ceremoniaHora || "",
      ceremoniaNombreLugar:
        seccionPortadaData?.ceremonia?.ceremoniaNombreLugar || "",
      ceremoniaLugarMap: seccionPortadaData?.ceremonia?.ceremoniaLugarMap || "",
      ceremoniaUrl: seccionPortadaData?.ceremonia?.ceremoniaUrl || "",
      recepcionEnable: seccionPortadaData?.ceremonia?.recepcionEnable,
      recepcionFecha: seccionPortadaData?.ceremonia?.recepcionFecha || "",
      recepcionHora: seccionPortadaData?.ceremonia?.recepcionHora || "",
      recepcionNombreLugar:
        seccionPortadaData?.ceremonia?.recepcionNombreLugar || "",
      recepcionLugarMap: seccionPortadaData?.ceremonia?.recepcionLugarMap || "",
      recepcionUrl: seccionPortadaData?.ceremonia?.recepcionUrl || "",
      imgFondo: seccionPortadaData?.ceremonia?.imgFondo || "",
      textColor: seccionPortadaData?.ceremonia?.textColor || "",
    };
  
    const datosSeccionProgramaBoda = {
      ceremoniaReligiosa: seccionPortadaData?.programa?.ceremoniaReligiosa || "",
      civil: seccionPortadaData?.programa?.civil || "",
      recepcion: seccionPortadaData?.programa?.recepcion || "",
      cena: seccionPortadaData?.programa?.cena || "",
      coctel: seccionPortadaData?.programa?.coctel || "",
      baile: seccionPortadaData?.programa?.baile || "",
    };
  
    const datosSeccionVestimenta = {
      vestimenta: seccionPortadaData?.vestimenta?.vestimenta || "",
      messageVestimenta: seccionPortadaData?.vestimenta?.messageVestimenta || "",
      urlVestimentaMujer:
        seccionPortadaData?.vestimenta?.urlVestimentaMujer || "",
      urlVestimentaHombre:
        seccionPortadaData?.vestimenta?.urlVestimentaHombre || "",
      imgFondo: seccionPortadaData?.vestimenta?.imgFondo || "",
      textColor: seccionPortadaData?.vestimenta?.textColor || "",
    };
  
    const datosSeccionHospedaje = {
      disponibleDos: seccionPortadaData?.hospedaje?.disponibleDos || "",
      descripcion: seccionPortadaData?.hospedaje?.descripcion || "",
      hotel1Titulo: seccionPortadaData?.hospedaje?.hotel1Titulo || "",
      hotel1lugar: seccionPortadaData?.hospedaje?.hotel1lugar || "",
      hotel1url: seccionPortadaData?.hospedaje?.hotel1url || "",
      hotel2Titulo: seccionPortadaData?.hospedaje?.hotel2Titulo || "",
      hotel2lugar: seccionPortadaData?.hospedaje?.hotel2lugar || "",
      hotel2url: seccionPortadaData?.hospedaje?.hotel2url || "",
      imgFondo: seccionPortadaData?.hospedaje?.imgFondo || "",
      textColor: seccionPortadaData?.hospedaje?.textColor || "",
    };
  
    const datosSeccionRegalos = {
      cantidadRegalos: seccionPortadaData?.regalos?.cantidadRegalos || "",
      titulo: seccionPortadaData?.regalos?.titulo || "",
      descripcion: seccionPortadaData?.regalos?.descripcion || "",
      link1: seccionPortadaData?.regalos?.link1 || "",
      imgLink1: seccionPortadaData?.regalos?.imgLink1 || "",
      link1Enable: seccionPortadaData?.regalos?.link1Enable || "",
      link2: seccionPortadaData?.regalos?.link2 || "",
      imgLink2: seccionPortadaData?.regalos?.imgLink2 || "",
      link2Enable: seccionPortadaData?.regalos?.link2Enable || "",
      link3: seccionPortadaData?.regalos?.link3 || "",
      imgLink3: seccionPortadaData?.regalos?.imgLink3 || "",
      link3Enable: seccionPortadaData?.regalos?.link3Enable || "",
      imgFondo: seccionPortadaData?.regalos?.imgFondo || "",
      textColor: seccionPortadaData?.regalos?.textColor || "",
    };
  
    const datosSeccionConfirmacion = {
      tituloConfirmacion:
        seccionPortadaData?.confirmacion?.tituloConfirmacion || "",
      aviso: seccionPortadaData?.confirmacion?.aviso || "",
      numeroConfirmacion:
        seccionPortadaData?.confirmacion?.numeroConfirmacion || "",
      textoConfirmacion:
        seccionPortadaData?.confirmacion?.textoConfirmacion || "",
      textWhatsApp: seccionPortadaData?.confirmacion?.textWhatsApp || "",
      textColor: seccionPortadaData?.confirmacion?.textColor || "",
      imgFondo: seccionPortadaData?.confirmacion?.imgFondo || "",
      textColor: seccionPortadaData?.confirmacion?.textColor || "",
    };
  
    const datosSeccionInvitados = {
      imgFondo:
        "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
      titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
      entradasText: "disfrutar mis XV años",
      colorFondoName: "",
    };
  
    const contador = estado?.enableContador;
    const padres = estado?.enablePadres;
    const hospedaje = estado?.enableHospedaje;
    const confirmacion = estado?.enableConfirmacion;
    const Regalos = estado?.enableRegalos;
    const ceremonia = estado?.enableCeremonia;
    const programa = estado?.enablePrograma;
    const vestimenta = estado?.enableVestimenta;
  
    const images = [
      "https://cdn0.bodas.com.mx/article-vendor/3443/3_2/960/jpg/analois-photoshoot-0055_5_193443-164815363528699.jpeg",
      "https://elolivar.es/olivar-content/uploads/2023/04/oficiante-de-bodas.png",
      "https://cnnespanol.cnn.com/wp-content/uploads/2023/06/wedding-trends-restricted-e1685615285359.jpeg?quality=100&strip=info",
      "https://images.ecestaticos.com/LceL6lnXGvDCBsnDEjupizBTGuA=/0x0:2272x1515/1200x900/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2Fe52%2Fd19%2Fcfe%2Fe52d19cfe44da0f12a62bc916ac069b1.jpg",
      // Add more image URLs as needed
    ];
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        {loading ? (
          <h2>Cargando...</h2>
        ) : (
          <>
            <PortadaBodaComponent
              datosSeccionPotada={datosSeccionPotadaBoda}
              audioFondo={audioFondo}
            />
  
            {contador ? (
              <ContadorBodaComponent
                peltaColores={peltaColores}
                datosSeccionContador={datosSeccionContador}
              />
            ) : (
              ""
            )}
  
            {padres ? (
              <PadresBodaComponent
                peltaColores={peltaColores}
                datosSeccionPadres={datosSeccionPadres}
              />
            ) : (
              ""
            )}
  
            {ceremonia ? (
              <div
              style={{
                backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: peltaColores.color2,
                color: datosSeccionCeremonia.textColor || "",
              }}
              className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
            >
              {/* =========================================================================================================================================== */}
              <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
                <div
                  className={`grid sm:grid-cols-1 md:grid-cols-${
                    datosSeccionCeremonia.dobleEnable ? "1" : "2"
                  } w-[80%] pt-[10vh]`}
                >
                  {datosSeccionCeremonia.ceremoniaEnable ? (
                    <div
                      className="flex flex-col justify-center items-center"
                      data-aos="fade-right"
                    >
                      <FaBuilding  className="text-[80px]" />
                      <br />
                      <p className="font-serif text-2xl">Ceremonia Civil</p>
                      <br />
                      <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                      <br />
                      <p className="font-serif text-center text-xl">
                        {datosSeccionCeremonia.ceremoniaFecha}
                      </p>
                      <p className="font-serif text-xl text-center">
                        {datosSeccionCeremonia.ceremoniaHora}
                      </p>
                      <br />
                      <p className="font-serif text-xl text-center">
                        {datosSeccionCeremonia.ceremoniaNombreLugar}
                      </p>
                      <br />
                      <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                        {datosSeccionCeremonia.ceremoniaLugarMap}
                      </p>
                      <br />
                      <a
                        href={datosSeccionCeremonia.ceremoniaUrl}
                        class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                      >
                        Ver mapa!
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                        <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                          Explore!
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
        
                  {datosSeccionCeremonia.dobleEnable ? (
                    <div
                      className="flex flex-col justify-center items-center"
                      data-aos="fade-right"
                    >
                      <div className="flex">
                        <PiChurchLight className="text-[80px]" />
                        <FaChampagneGlasses className="text-[80px]" />
                      </div>
                      <br />
                      <p className="font-serif text-center text-2xl">Ceremonia y Recepción</p>
                      <br />
                      <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                      <br />
                      <p className="font-serif text-center text-xl">
                        {datosSeccionCeremonia.ceremoniaFecha}
                      </p>
                      <p className="font-serif text-xl text-center">
                        {datosSeccionCeremonia.ceremoniaHora}
                      </p>
                      <br />
                      <p className="font-serif text-xl text-center">
                        {datosSeccionCeremonia.ceremoniaNombreLugar}
                      </p>
                      <br />
                      <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                        {datosSeccionCeremonia.ceremoniaLugarMap}
                      </p>
                      <br />
                      <a
                        href={datosSeccionCeremonia.ceremoniaUrl}
                        class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                      >
                        Ver mapa!
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                        <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                          Explore!
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
        
                  {datosSeccionCeremonia.recepcionEnable ? (
                    <div
                      className="flex flex-col justify-center items-center sm:pt-[5vh] md:pt-0"
                      data-aos="fade-right"
                    >
                      <FaChampagneGlasses className="text-[80px]" />
                      <br />
                      <p className="font-serif text-2xl">Recepción</p>
                      <br />
                      <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                      <br />
                      <p className="font-serif text-xl text-center">
                        {datosSeccionCeremonia.recepcionFecha}
                      </p>
                      <p className="font-serif text-xl text-center">
                        {datosSeccionCeremonia.recepcionHora}
                      </p>
                      <br />
                      <p className="font-serif text-xl text-center ">
                        {datosSeccionCeremonia.recepcionNombreLugar}
                      </p>
                      <br />
                      <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                        {datosSeccionCeremonia.recepcionLugarMap}
                      </p>
                      <br />
                      <a
                        href={datosSeccionCeremonia.recepcionUrl}
                        class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
                      >
                        Ver mapa!
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                        <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                        <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                          Explore!
                        </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            ) : (
              ""
            )}
  
            {programa ? (
              <ProgramaBodaComponent
                peltaColores={peltaColores}
                datosSeccionPrograma={datosSeccionProgramaBoda}
              />
            ) : (
              ""
            )}
  
            {vestimenta ? (
              <VestimentaXvComponent
                peltaColores={peltaColores}
                datosSeccionVestimenta={datosSeccionVestimenta}
              />
            ) : (
              ""
            )}
  
            {hospedaje ? (
              <HospedajeXvComponent
                peltaColores={peltaColores}
                datosSeccionHospedaje={datosSeccionHospedaje}
              />
            ) : (
              ""
            )}
  
            {Regalos ? (
              <RegalosXvComponent
                peltaColores={peltaColores}
                datosSeccionRegalos={datosSeccionRegalos}
              />
            ) : (
              ""
            )}
  
            {confirmacion ? (
              <ConfirmacionXvComponent
                peltaColores={peltaColores}
                images={seccionImagenes}
                messageWhats={messageWhats}
                datosSeccionConfirmacion={datosSeccionConfirmacion}
              />
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
}
