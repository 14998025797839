import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { PiChurchLight } from "react-icons/pi";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaChampagneGlasses } from "react-icons/fa6";

export const XvAri = () => {
  const [isOpen, setIsOpen] = useState(false);

  const peltaColores = {
    color1: "#75a8b6",
    color2: "#c0c4c4",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDEtbmacNJR_y2mate.com---One-Direction-Story-of-My-Life-Audio.mp3",
    imgPortada: "https://i.postimg.cc/L5dsK7PZ/Whats-App-Image-2024-04-28-at-10-54-13-PM.jpg",
    nombre: "Ari",
    fechaDeBoda: "01 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+524771798220",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "¡Te invito a compartir este día maravilloso y ser parte de mi historia! \\n¡Gracias a Dios y mis padres por el apoyo para realizar esta fiesta!",
    fechaNewDate: "June 01, 2024 18:30:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/c1YHMxq7/Whats-App-Image-2024-04-28-at-10-54-08-PM.jpg",
    fotoDerecha: "https://i.postimg.cc/dtjVp5VL/Whats-App-Image-2024-04-28-at-10-54-10-PM.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Maribel Guerrero Martínez",
    padre: "Rubén Alcaraz Gutiérrez ",
    madrina: "Lourdes Macrina López Quintanar",
    padrino: "José Adalberto Galván Galindo",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 01 de Junio de 2024.",
    ceremoniaHora: "6:30 p.m.",
    ceremoniaNombreLugar: "Centro Vocacional Legionarios de Cristo 'Apostólica'",
    ceremoniaLugarMap:
      "Blvd Vicente Valtierra 7801, Pedregal del Carmen, 37299 León de los Aldama, Gto.",
    ceremoniaUrl: "https://maps.app.goo.gl/nkaeAjVHp9DpKt6DA",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 01 de Junio de 2024.",
    recepcionHora: "8:30 p.m.",
    recepcionNombreLugar: "Salon de Eventos Vimar",
    recepcionLugarMap:
      "Blvd. San Pedro 108, San Isidro Nte, 37510 León de los Aldama, Gto. ",
    recepcionUrl: "https://maps.app.goo.gl/C9wBMCKirDUzhCKWA",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "6:30 p.m: Acompáñanos a recibir la bendición de Dios",
    recepcion: "8:30 p.m: Tómate fotos con nosotros",
    baileXV: "10:30 p.m: No te pierdas mi baile de XV años",
    cena: "9:30 p.m: Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/L5dsK7PZ/Whats-App-Image-2024-04-28-at-10-54-13-PM.jpg",
    "https://i.postimg.cc/c1YHMxq7/Whats-App-Image-2024-04-28-at-10-54-08-PM.jpg",
    "https://i.postimg.cc/dtjVp5VL/Whats-App-Image-2024-04-28-at-10-54-10-PM.jpg",
    "https://i.postimg.cc/Kjn8ZN7p/Whats-App-Image-2024-04-28-at-10-54-05-PM-1.jpg",
   
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
          peltaColores={peltaColores}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
         <div
         style={{
           backgroundImage:`url(${datosSeccionPrograma.imgFondo || ""})`,
           backgroundRepeat: "no-repeat",
           backgroundSize: "cover",
           display: "flex",
           backgroundPosition: "center center",
           justifyContent: "center",
           alignItems: "center",
           backgroundColor: peltaColores.color2,
           
           color: datosSeccionPrograma.textColor
             ? datosSeccionPrograma.textColor
             : "",
         }}
         className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
       >
         {/* =========================================================================================================================================== */}
         <div className="flex flex-col w-full justify-center items-center p-2">
           <h1
             className="font-serif text-center sm:text-[32px] md:text-[42px]"
             data-aos="flip-up"
           >
             Programa
           </h1>
           <br />
           <p
             className=" text-center pt-[5vh] font-serif text-xl"
             data-aos="flip-up"
           >
             Hemos planeado un día lleno de diversión para disfrutar al máximo.
           </p>
           <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
             <div
               className="flex flex-col justify-center items-center"
               data-aos="fade-right"
             >
               {datosSeccionPrograma.ceremoniaReligiosa ? (
                 <>
                   <PiChurchLight className="text-[60px]" />
                   <br />
                   <p className="font-serif text-xl text-center">
                     CEREMONIA RELIGIOSA
                   </p>
                   <br />
                   <p className="font-serif text-xl text-center w-[60%]">
                     {datosSeccionPrograma.ceremoniaReligiosa}
                   </p>
                   <br />
                   <hr className="bg-gray-700 h-1 w-[60%]" />
                   <br />
                 </>
               ) : (
                 ""
               )}
   
               {datosSeccionPrograma.recepcion ? (
                 <>
                   <FaPhotoVideo className="text-[60px]" />
                   <br />
                   <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                   <br />
                   <p className="font-serif text-xl text-center w-[60%]">
                     {datosSeccionPrograma.recepcion}
                   </p>
                   <br />
                   <hr className="bg-gray-700 h-1 w-[60%]" />
                   <br />
                 </>
               ) : (
                 ""
               )}
    {datosSeccionPrograma.cena ?(
                   <>
                    <ImSpoonKnife className="text-[60px]" />
               <br />
               <p className="font-serif text-xl text-center">CENA</p>
               <br />
               <p className="font-serif text-xl text-center w-[60%]">
                 {datosSeccionPrograma.cena}
               </p>
               <br />
               <br />
               <hr className="bg-gray-700 h-1 w-[60%]" />
               <br />
                   </>
               ):("")}
               
             </div>
   
             <div
               className="flex flex-col justify-center items-center"
               data-aos="fade-right"
             >
              {datosSeccionPrograma.baileXV ? (
                 <>
                   <GiPartyPopper className="text-[60px]" />
                   <br />
                   <p className="font-serif text-xl text-center">
                     BAILE DE XV AÑOS
                   </p>
                   <br />
                   <p className="font-serif text-xl text-center w-[60%]">
                     {datosSeccionPrograma.baileXV}
                   </p>
                   <br />
                   <hr className="bg-gray-700 h-1 w-[60%]" />
                   <br />
                 </>
               ) : (
                 ""
               )}
              
              {datosSeccionPrograma.coctel ? (
               <>
                <FaChampagneGlasses className="text-[60px]" />
               <br />
               <p className="font-serif text-xl text-center">CÓCTEL</p>
               <br />
               <p className="font-serif text-xl text-center w-[60%]">
                 {datosSeccionPrograma.coctel}
               </p>
               <br />
               <hr className="bg-gray-700 h-1 w-[60%]" />
               <br />
               </>
              ):("")}
              
              {datosSeccionPrograma.baile ? (
               <>
               <GiMeshBall className="text-[60px]" />
               <br />
               <p className="font-serif text-xl text-center">BAILE</p>
               <br />
               <p className="font-serif text-xl text-center w-[60%]">
                 {datosSeccionPrograma.baile}
               </p>
               <br />
               <hr className="bg-gray-700 h-1 w-[60%]" />
               <br />
               </>
              ):("")}
               
             </div>
           </div>
         </div>
       </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
