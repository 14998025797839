import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import 'tailwindcss/tailwind.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function ModalXv({isOpen,setIsOpen}) {

  useEffect(() => {
    // Inicializa AOS
    AOS.init({ duration: 2000 });
  }, []);

  const closeModal = () => setIsOpen(false);

  return (
    <div>
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="modal"
      unmountOnExit
      appear
    >
      <div className="modal fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
        <div
          className="modal-content relative"
          data-aos="fade-up"
        >
          <button className="absolute top-4 right-4 text-white bg-pink-500 hover:bg-pink-600 font-semibold py-2 px-4 rounded-md" onClick={closeModal}>Cerrar</button>
          <img src="https://cdn-icons-png.flaticon.com/512/5573/5573081.png" alt="Fondo de Invitación" className="w-full h-full object-cover" />
          <button className="absolute bottom-10 left-1/2 transform -translate-x-1/2 bg-pink-500 hover:bg-pink-600 text-white font-semibold sm:py-1 sm:px-2 md:py-3 md:px-6 rounded-full" onClick={closeModal}>Aceptar Invitación</button>
        </div>
      </div>
    </CSSTransition>
  </div>
  );
}

export default ModalXv;
