import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import ModalEventos from "../../../components/Modals/ModalEventos";
import { PortadaBodaBautizoComponent } from "../../../components/ComponentsBoda/PortadaBodaBautizoComponent";


export const BodaJazminYUriel = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#ffc7c7",
    color2: "#f5b593",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDBtvzru2oR_y2mate.com---Bruno-Mars-Marry-You-Official-Lyric-Video.mp3",
    textoConfirmacion: " nuestro festejo",
    esposo: "Uriel",
    esposa: "Mitzy Jazmin",
    bautizada: "Meredith Esmeralda",
    fechaDeBoda: "08 JUNIO, 2024",
    imgPortada: "https://i.postimg.cc/7Y1Bf68q/1000095418.jpg",
    titulo: "Boda y Bautizo",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 4 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de estos sacramentos de nuestra pequeña familia...",
    fechaNewDate: "June 08, 2024 12:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/sgVTSrs7/1000095311.jpg",
    fotoDerecha:
      "https://i.postimg.cc/hhFJbFD9/075ebdfb-3c98-42c0-8368-b5185ddac305.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Nuestros Padres",
    madre: "Norma Soriano Cardenas",
    padre: "C. Saúl Martinez Rodríguez",
    titulo2: "",
    madrina: "Guadalupe Romero Hernández",
    padrino: "Domingo Martinez Mendoza",
    titulo3: "Madrina de Meredith Esmeralda",
    madrina2: "Norma Martinez Soriano",
    padrino2: "",
    titulo4: "Padrinos de Velación",
    madrina3: "Leydi Estrellita Bojorges Cardenas",
    padrino3: "Carlos Prado Miranda",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 08 de Junio de 2024.",
    ceremoniaHora: "12:00 p.m.",
    ceremoniaNombreLugar: "Parroquia de San Miguel Arcángel",
    ceremoniaLugarMap:
      "Plaza de La Constitución S/N, San Miguel, 56270 Chiconcuac de Juárez, Méx.",
    ceremoniaUrl: "https://maps.app.goo.gl/sgVPv9fN7bDtZ51q6",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 08 de Junio de 2024.",
    recepcionHora: "2:00 p.m.",
    recepcionNombreLugar: "Salon El Castillo",
    recepcionLugarMap:
      "Papalotla Manzana 014, Santiago Chimalpa, 56030 Santiago Chimalpa, Méx.",
    recepcionUrl: "https://maps.app.goo.gl/rb6wDwsimP5wpLvT6",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "",
    civil: "",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa comida",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "FORMAL",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Su presencia es lo más importante en este día tan especial, pero si algo nos quieren regalar, en efectivo lo sabremos apreciar. El dia del evento habrá una caja donde podrán colocar sus sobres.",
    link1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+525616541717",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/7Y1Bf68q/1000095418.jpg",
    "https://i.postimg.cc/sgVTSrs7/1000095311.jpg",
    "https://i.postimg.cc/RCHpxdg6/1000095422.jpg",
    "https://i.postimg.cc/3RD17rF5/1000095420.jpg",
    "https://i.postimg.cc/cLXXBbLj/1000095413.jpg",
    "https://i.postimg.cc/8CcwKmFC/1000055931.jpg",
    "https://i.postimg.cc/Dy3jvGNC/1000095338.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalEventos
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
        peltaColores={peltaColores}
      />
      <PortadaBodaBautizoComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <>
          <PadresBodaComponent
            peltaColores={peltaColores}
            datosSeccionPadres={datosSeccionPadres}
          />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: peltaColores.color1,
              color: datosSeccionPadres.textColor
                ? datosSeccionPadres.textColor
                : "",
            }}
            className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
          >
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-1 w-[80%] pt-[0vh]`}
            >
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres.titulo4}
                </p>
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.madrina3}
                </p>
                {datosSeccionPadres.padrino3 ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.padrino3}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.postimg.cc/hGHzQBLp/2022-07-03.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.624049445824!2d-98.8949186!3d19.5577476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1e893dbb7b44b%3A0x2cb3826e1adfe53b!2sParroquia%20de%20San%20Miguel%20Arc%C3%A1ngel!5e0!3m2!1ses-419!2smx!4v1714266148268!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccionCeremonia.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.postimg.cc/HWtnd5hS/2022-05-12.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.2201134711777!2d-98.9039608!3d19.575068399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1e8dd36450527%3A0x9f09c001aa8cf3ff!2sEl%20Castillo!5e0!3m2!1ses-419!2smx!4v1714266261105!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccionCeremonia.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {programa ? (
        <div
        style={{
          backgroundImage:`url(${datosSeccionProgramaBoda.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          
          color: datosSeccionProgramaBoda.textColor
            ? datosSeccionProgramaBoda.textColor
            : "",
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              {datosSeccionProgramaBoda.ceremoniaReligiosa ? (
                <>
                  <PiChurchLight className="text-[60px]" />
                  <br />
                  <p className="font-serif text-xl text-center">
                    CEREMONIA RELIGIOSA
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center w-[60%]">
                    {datosSeccionProgramaBoda.ceremoniaReligiosa}
                  </p>
                  <br />
                  <hr className="bg-gray-700 h-1 w-[60%]" />
                  <br />
                </>
              ) : (
                ""
              )}
  
              {datosSeccionProgramaBoda.recepcion ? (
                <>
                  <FaPhotoVideo className="text-[60px]" />
                  <br />
                  <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                  <br />
                  <p className="font-serif text-xl text-center w-[60%]">
                    {datosSeccionProgramaBoda.recepcion}
                  </p>
                  <br />
                  <hr className="bg-gray-700 h-1 w-[60%]" />
                  <br />
                </>
              ) : (
                ""
              )}
  
              {datosSeccionProgramaBoda.civil ? (
                <>
                  <GiBigDiamondRing className="text-[60px]" />
                  <br />
                  <p className="font-serif text-xl text-center">
                    CEREMONIA CIVIL
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center w-[60%]">
                    {datosSeccionProgramaBoda.civil}
                  </p>
                  <br />
                  <hr className="bg-gray-700 h-1 w-[60%]" />
                  <br />
                </>
              ) : (
                ""
              )}
            </div>
  
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              {datosSeccionProgramaBoda.cena ?(
                  <>
                   <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">COMIDA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccionProgramaBoda.cena}
              </p>
              <br />
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
                  </>
              ):("")}
             
             {datosSeccionProgramaBoda.coctel ? (
              <>
               <FaChampagneGlasses className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CÓCTEL</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccionProgramaBoda.coctel}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              </>
             ):("")}
             
             {datosSeccionProgramaBoda.baile ? (
              <>
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccionProgramaBoda.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              </>
             ):("")}
              
            </div>
          </div>
        </div>
      </div>
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
