import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaChampagneGlasses } from "react-icons/fa6";
import { PiChurchLight } from "react-icons/pi";
import { TbClockHour2 } from "react-icons/tb";
import { GrMapLocation } from "react-icons/gr";
import { SiGooglemaps } from "react-icons/si";


export const CeremoniaXvComponentD2 = ({
  peltaColores,
  datosSeccionCeremonia,
}) => {
  const fontFamilyStyle = { fontFamily: datosSeccionCeremonia.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  const formatTime = (isoDateString) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    if (!isoDatePattern.test(isoDateString)) {
      return isoDateString;
    }

    const timePart = isoDateString.split("T")[1];
    const [hours, minutes] = timePart.split(":");

    const hoursNumber = parseInt(hours, 10);
    const period = hoursNumber >= 12 ? "p.m." : "a.m.";
    const formattedHours = hoursNumber % 12 || 12; // Convertir 0 a 12 para las horas en formato de 12 horas

    return `${formattedHours}:${minutes} ${period}`;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionCeremonia.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col items-center w-full px-2 py-5"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-${
            datosSeccionCeremonia.dobleEnable ? "1" : "2"
          } w-[100%] pt-[10vh]`}
        >
          {datosSeccionCeremonia.ceremoniaEnable ? (
            <div
              className="flex flex-col justify-center items-center py-4"
              style={{
                boxShadow: "-4px 4px 12px 0px rgba(0, 0, 0, 0.44)",
              }}
              data-aos="fade-right"
            >
              {datosSeccionCeremonia.ceremoniaImg ? (
                <img
                  src={datosSeccionCeremonia.ceremoniaImg}
                  className="w-[90%]"
                  alt=""
                />
              ) : (
                <PiChurchLight className="text-[80px]" />
              )}

              <br />
              <p className="font-serif text-2xl" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaTitulo || "Ceremonia"}
              </p>
              <br />
              <div className="flex px-4 items-start">
                <FaRegCalendarAlt className="m-2 text-red-800" />
                <p className="font-serif text-xl" style={fontFamilyStyle}>
                  {datosSeccionCeremonia.ceremoniaFecha
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              </div>
              <div className="flex px-4 w-full justify-start items-start">
                <TbClockHour2 className="m-2 text-red-800" />
                <p
                  className="font-serif text-xl text-start"
                  style={fontFamilyStyle}
                >
                  {formatTime(datosSeccionCeremonia.ceremoniaHora)}
                </p>
              </div>

              <br />
              <div className="flex px-4 w-full justify-start items-start">
              <GrMapLocation className="m-2 text-red-800" />
              <p
                className="font-serif text-xl text-start"
                style={fontFamilyStyle}
              >
                {datosSeccionCeremonia.ceremoniaNombreLugar
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              </div>
              {
                datosSeccionCeremonia.ceremoniaLugarMap ? (
                  <>
                  <br />
              <p
                className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]"
                style={fontFamilyStyle}
              >
                {datosSeccionCeremonia.ceremoniaLugarMap
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
                  </>
                ):""
              }
              
              <br />
              {datosSeccionCeremonia.ceremoniaUrl ? (
               <div className="flex px-4 w-full justify-start items-start">
                <div className="flex justify-start items-start rounded-md pr-2" style={{
                boxShadow: "-2px 2px 7px 0px rgba(0, 0, 0, 0.44)",
                ...fontFamilyStyle
              }}>
                <SiGooglemaps className="m-2 text-red-800" />
                 <a
                  href={datosSeccionCeremonia.ceremoniaUrl}
                  style={{
                    whiteSpace: "nowrap",
                color: datosSeccionCeremonia.textColor || "black",
                textDecoration:"none",
                  }}
                  className="text-lg sm:w-[70%] md:w-[80%] border-none cursor-pointer"
                >
                  Cómo llegar... 
                 
                </a>
                <a href="/"></a>
                </div>
                 
               </div>
              ) : (
                ""
              )}

            </div>
          ) : (
            ""
          )}

          {datosSeccionCeremonia.dobleEnable ? (
           <div
           className="flex flex-col justify-center items-center py-4"
           style={{
             boxShadow: "-4px 4px 12px 0px rgba(0, 0, 0, 0.44)",
           }}
           data-aos="fade-right"
         >
           {datosSeccionCeremonia.ceremoniaImg ? (
             <img
               src={datosSeccionCeremonia.ceremoniaImg}
               className="w-[90%]"
               alt=""
             />
           ) : (
             <PiChurchLight className="text-[80px]" />
           )}
          <br />
          <p className="font-serif text-2xl" style={fontFamilyStyle}>
            {datosSeccionCeremonia.ceremoniaTitulo || "Ceremonia"} y{" "}
            {datosSeccionCeremonia.recepcionTitulo || "Recepción"}
          </p>
          <br />
          <div className="flex px-4 items-start">
            <FaRegCalendarAlt className="m-2 text-red-800" />
            <p className="font-serif text-xl" style={fontFamilyStyle}>
              {datosSeccionCeremonia.ceremoniaFecha
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </div>
          <div className="flex px-4 w-full justify-start items-start">
            <TbClockHour2 className="m-2 text-red-800" />
            <p
              className="font-serif text-xl text-start"
              style={fontFamilyStyle}
            >
              {formatTime(datosSeccionCeremonia.ceremoniaHora)}
            </p>
          </div>
        
          <br />
          <div className="flex px-4 w-full justify-start items-start">
            <GrMapLocation className="m-2 text-red-800" />
            <p
              className="font-serif text-xl text-start"
              style={fontFamilyStyle}
            >
              {datosSeccionCeremonia.ceremoniaNombreLugar
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </div>
          {
            datosSeccionCeremonia.ceremoniaLugarMap ? (
              <>
                <br />
                <p
                  className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionCeremonia.ceremoniaLugarMap
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              </>
            ) : ""
          }
        
          <br />
          {datosSeccionCeremonia.ceremoniaUrl ? (
            <div className="flex px-4 w-full justify-start items-start">
              <div className="flex justify-start items-start rounded-md pr-2" style={{
                boxShadow: "-2px 2px 7px 0px rgba(0, 0, 0, 0.44)",
                ...fontFamilyStyle
              }}>
                <SiGooglemaps className="m-2 text-red-800" />
                <a
                  href={datosSeccionCeremonia.ceremoniaUrl}
                  style={{
                    whiteSpace: "nowrap",
                    color: datosSeccionCeremonia.textColor || "black",
                    textDecoration:"none",
                  }}
                  className="text-lg sm:w-[70%] md:w-[80%] border-none cursor-pointer"
                >
                  Cómo llegar... 
                </a>
              </div>
            </div>
          ) : ""}
        </div>
        
          ) : (
            ""
          )}

          {datosSeccionCeremonia.recepcionEnable ? (
            <div
            className="my-2 flex flex-col justify-center items-center py-4"
            style={{
              boxShadow: "-4px 4px 12px 0px rgba(0, 0, 0, 0.44)",
            }}
            data-aos="fade-right"
          >
            {datosSeccionCeremonia.recepcionImg ? (
              <img
                src={datosSeccionCeremonia.recepcionImg}
                className="w-[90%]"
                alt=""
              />
            ) : (
              <FaChampagneGlasses className="text-[80px]" />
            )}
          
            <br />
            <p className="font-serif text-2xl" style={fontFamilyStyle}>
              {datosSeccionCeremonia.recepcionTitulo || "Recepción"}
            </p>
            <br />
            <div className="flex px-4 items-start">
              <FaRegCalendarAlt className="m-2 text-red-800" />
              <p className="font-serif text-xl" style={fontFamilyStyle}>
                {datosSeccionCeremonia.recepcionFecha
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            </div>
            <div className="flex px-4 w-full justify-start items-start">
              <TbClockHour2 className="m-2 text-red-800" />
              <p
                className="font-serif text-xl text-start"
                style={fontFamilyStyle}
              >
                {formatTime(datosSeccionCeremonia.recepcionHora)}
              </p>
            </div>
          
            <br />
            <div className="flex px-4 w-full justify-start items-start">
              <GrMapLocation className="m-2 text-red-800" />
              <p
                className="font-serif text-xl text-start"
                style={fontFamilyStyle}
              >
                {datosSeccionCeremonia.recepcionNombreLugar
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            </div>
            {
              datosSeccionCeremonia.recepcionLugarMap ? (
                <>
                  <br />
                  <p
                    className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]"
                    style={fontFamilyStyle}
                  >
                    {datosSeccionCeremonia.recepcionLugarMap
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                </>
              ) : ""
            }
          
            <br />
            {datosSeccionCeremonia.recepcionUrl ? (
              <div className="flex px-4 w-full justify-start items-start">
                <div className="flex justify-start items-start rounded-md pr-2" style={{
                  boxShadow: "-2px 2px 7px 0px rgba(0, 0, 0, 0.44)",
                  ...fontFamilyStyle
                }}>
                  <SiGooglemaps className="m-2 text-red-800" />
                  <a
                    href={datosSeccionCeremonia.recepcionUrl}
                    style={{
                      whiteSpace: "nowrap",
                      color: datosSeccionCeremonia.textColor || "black",
                      textDecoration:"none",
                    }}
                    className="text-lg sm:w-[70%] md:w-[80%] border-none cursor-pointer"
                  >
                    Cómo llegar... 
                  </a>
                </div>
              </div>
            ) : ""}
          </div>
          
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
