import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaPatriciaYHector = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#adadad",
    color2: "#0042a9",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEovCwi5ccR_y2mate.com---Virlan-Garcia-Sientes-Lo-Que-Siento-Official-Video.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "",
    esposa: "",
    fechaDeBoda: "",
    imgPortada: "https://i.postimg.cc/yYD4b1ds/Tarjeta-matrimonio-sobria-con-plantas.png",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Nuestro amor ha pasado por muchas pruebas que han fortalecido aún más nuestros sentimientos. Ahora queremos dar el siguiente paso para confirmar el deseo de permanecer juntos por siempre y formar una hermosa familia. Esperamos que puedan honrarnos con nuestra presencia.",
    fechaNewDate: "June 15, 2024 17:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/KvB2WQbt/IMG-7310.jpg",
    fotoDerecha: "https://i.postimg.cc/HnMffWqT/IMG-7311.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Laura Maribel Trejo Alonso",
    padre: "Alan Palma Galarza",
    titulo2: "Padres del Novio",
    madrina: "Hilda Esmeralda Robles Jaramillo",
    padrino: "Héctor Rodríguez Reyes",
    titulo3: "Padrinos del Novio2",
    madrina2: "Rosio Garibay Cervantes",
    padrino2: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 15 de Junio de 2024.",
    ceremoniaHora: "5:00 p.m.",
    ceremoniaNombreLugar: "Templo de San Agustín",
    ceremoniaLugarMap:
      "C. Ignacio Allende Sur 24, Centro, 76000 Santiago de Querétaro, Qro.",
    ceremoniaUrl: "https://maps.app.goo.gl/kG7TSTbw921vchNN6",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 15 de Junio de 2024.",
    recepcionHora: "7:00 p.m.",
    recepcionNombreLugar: "Hacienda San Pedro",
    recepcionLugarMap:
      "C. Hidalgo 2B, San Pedro Martir, 76117 San Pedro Martir, Qro.",
    recepcionUrl: "https://maps.app.goo.gl/KHzY5F2jwdQTv75H6",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1:
      "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "Los niños son bienvenidos en nuestra boda.",
    numeroConfirmacion: "+524426680962",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "#fff",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/gjL8Pjqt/IMG-7312.jpg",
    "https://i.postimg.cc/KvB2WQbt/IMG-7310.jpg",
    "https://i.postimg.cc/HnMffWqT/IMG-7311.jpg",
    // Add more image URLs as needed
  ];

    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    const handlePlayButtonClick = () => {
      setIsMusicPlaying(!isMusicPlaying);
    };
  
    useEffect(() => {
      const audioElement = document.getElementById('backgroundMusic');
      if (isMusicPlaying) {
        audioElement.play();
      } else {
        audioElement.pause();
        audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
      }
    }, [isMusicPlaying]);

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <div
        style={{
          backgroundImage: `url(${datosSeccionPotadaBoda.imgPortada})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgb(0 0 0 / 22%)" }}
        >
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPotadaBoda.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
          <div className="w-full flex justify-end h-full items-start fixed ">
            <button
              onClick={handlePlayButtonClick}
              className=" text-3xl  rounded animate-bounce bg-black p-1"
            >
              {isMusicPlaying ? "🔊" : "🔇"}
            </button>
          </div>
          <br />
          <br />
          <br />
          <br />
          <p
            className="text-white p-3 rounded-lg font-serif text-2xl text-center"
            data-aos="fade-up"
          >
            {datosSeccionPotadaBoda.titulo || ""} <br />{" "}
            {datosSeccionPotadaBoda.fechaDeBoda
              ? `- ${datosSeccionPotadaBoda.fechaDeBoda} -`
              : ""}
          </p>
          <br />
        </div>
      </div>

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
