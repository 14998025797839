import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/SobreLasOlas.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const BodaAideYMario = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: " nuestra boda",
    esposo: "Aidé",
    esposa: "Mario",
    fechaDeBoda: "25 MAYO 2024",
    fechaNewDate: "May 25, 2024 21:00:00 GMT+00:00",
    imgPortada:
      "https://i.postimg.cc/pXC1NdS5/Whats-App-Image-2024-04-15-at-6-05-18-PM.jpg",
    numeroNovia: "+526121769836",
    numeroNovio: "",
    novios: true,
    titulo: "NUESTRA BODA 50 AÑOS",
    tituloConfirmacion:(
      <div>
        Espero puedan confirmarnos su asistencia. <br/>
Agradecemos de antemano su apreciable compañía.
      </div>
    )
  };

  const peltaColores = {
    color1: "#f8f8f2",
    color2: "#cec18d",
  };

  const datosSeccion2 = {
    titulo: "¡Volvemos a decir que sí!",
    descipcion:
      "Después de 50 años caminando juntos hemos decidido volver unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión.",
    fechaNewDate: "May 25, 2024 21:00:00 GMT+00:00",
    fotoIzquierda:
      "https://revistaronda.net/wp-content/uploads/2022/12/Bodas-cortesia-1.jpg",
    fotoDerecha:
      "https://conceptodefinicion.de/wp-content/uploads/2018/05/Bodas_de_Oro-2.jpg",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Mario y Aide!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Delfina Patron Murillo ✟",
    esposaPadre: "Ricardo Osuna Talamantes ✟",
    esposoMadre: "Margarita Aguilar",
    esposoPadre: "Rafael Valenzuela Osuna ✟",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
    ceremoniaHora: "5:30 p.m.",
    ceremoniaNombreLugar: "Capilla San Juan Bautista",
    ceremoniaLugarMap:
      "Sierra San Pedro Mártir, 8 de Octubre 2da Secc, 23085 La Paz, B.C.S.",
    ceremoniaUrl: "https://maps.app.goo.gl/Ky1kBPPxiEjLkoZK9?g_st=ic",
    recepcionFecha: "Sábado, 25 de Mayo de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "El Torote Salon evento",
    recepcionLugarMap: "Pino Payas, 23320 La Paz, B.C.S.",
    recepcionUrl: "https://maps.app.goo.gl/KrhAuAw81AL38uyA9?g_st=ic",
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/9FCNN9Lj/Whats-App-Image-2024-04-15-at-6-04-55-PM.jpg",
    "https://i.postimg.cc/c1QbmhMm/Whats-App-Image-2024-04-15-at-6-04-28-PM.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <div
        style={{
          backgroundImage: datosSeccion3.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color: datosSeccion3.textColor ? datosSeccion3.textColor : "",
        }}
        className="sm:h-[130vh] md:h-[100vh] p-2 flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccion3.titulo}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccion3.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl">
                {datosSeccion3.tituloIzquierda}
              </p>
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposaMadre}
              </p>
              {datosSeccion3.esposaPadre ? (
                <p className="font-serif text-center text-xl">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposaPadre}
              </p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl">
                {datosSeccion3.tituloDerecha}
              </p>
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposoMadre}
              </p>
              {datosSeccion3.esposoPadre ? (
                <p className="font-serif text-center text-xl">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposoPadre}
              </p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl">
                Padrinos de Velación
              </p>
              <br />
              <p className="font-serif text-center text-xl">
                Hilda Patrón Murillo
              </p>
              <p className="font-serif text-center text-xl">&</p>
              <p className="font-serif text-center text-xl">
                Sergio Peralta Patrón
              </p>
            </div>
          </div>
        </div>
      </div>

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
