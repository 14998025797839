import React, { useEffect, useState } from 'react'
import AOS from "aos";

export const PortadaNewBodaComponent = ({datosSeccionPotada}) => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);

    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    const handlePlayButtonClick = () => {
      setIsMusicPlaying(!isMusicPlaying);
    };
  
    useEffect(() => {
      const audioElement = document.getElementById('backgroundMusic');
      if (isMusicPlaying) {
        audioElement.play();
      } else {
        audioElement.pause();
        audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
      }
    }, [isMusicPlaying]);
    return (
      <div
          style={{
            backgroundImage: `url(${datosSeccionPotada.imgPortada})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh", // Ajusta la altura del contenedor
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="bg-slate-950 flex flex-col justify-center items-center w-full"
        >
          <div
            className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
            style={{ backgroundColor: "rgb(0 0 0 / 22%)" }}
          >
            <audio
            id="backgroundMusic"
            style={{ display: 'none' }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPotada.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
            <div className='w-full flex justify-end h-full items-start fixed '>
            <button
              onClick={handlePlayButtonClick}
              className=" text-3xl  rounded animate-bounce bg-black p-1"
            >
              {isMusicPlaying ? "🔊" : "🔇"}
            </button>
            </div>
          </div>
        </div>
    )
}
