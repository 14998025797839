import React, { useEffect, useState } from "react";
import { GiTicket } from "react-icons/gi";
import AOS from "aos";
import Slider from "react-slick";
import { FaWhatsapp } from "react-icons/fa6";

export const BodaEjemplo2 = () => {
  const targetDate = new Date(
    "April 12, 2024 19:00:00 GMT+00:00" || ""
  ).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/7LX3sqTJ/img1.jpg",
    "https://i.postimg.cc/J0HjzJYj/img7.jpg",
    "https://i.postimg.cc/k4pxCwsn/img5.jpg",
    "https://i.postimg.cc/t43t63q5/img6.jpg",
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear"
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden ">
      <meta name="og:image" content={"https://localhost:44411/boda-diamante-ejemplo2"+"https://media.glamour.mx/photos/63e2c6cd70f88f66b926f631/16:9/w_2560%2Cc_limit/bodas-gratis-en-mexico.jpg"}/>
      <div
        style={{
          backgroundImage:
            'url("https://cflvdg.avoz.es/sc/480x/default/2023/07/07/00121688723442818690299/Foto/boda3.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e7e3e3",
        }}
        className="flex flex-col items-center sm:h-screen w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <h2 className="text-white md:text-[7vh] sm:text-[5vh] mt-[10%] text-center txt-pre">
            NUESTRA BODA
          </h2>
          <br />
          <br />
          <div className="flex items-center">
            <p className="text-white md:text-[20vh] sm:text-[12vh] sm:p-2 md:p-10 txt-pre">
              A
            </p>
            <div className="h-full flex items-start justify-start p-2">
              <p className="text-white txt-orizontal txt-pre"> Angel</p>
            </div>
            <div className="sm:h-[40vh] md:h-full w-2 bg-slate-50" />
            <div className="h-full flex items-end justify-start p-2">
              <p className="text-white txt-orizontal txt-pre"> Mariana</p>
            </div>
            <p className="text-white md:text-[20vh] sm:text-[12vh] sm:p-2 md:p-10 txt-pre">
              M
            </p>
          </div>
          <br />
          <br />
          <p className="text-white md:text-[5vh] sm:text-[3vh] text-center txt-pre">
            29 / SEP / 2024
          </p>
        </div>
      </div>
      <div className="sm:h-auto md:h-[100vh] flex flex-col items-center px-4 my-32">
        <img
          src="https://i.postimg.cc/mrQ40bTZ/501998a2-4c97-4e15-877b-63f84614f5c6.jpg"
          alt=""
          className="h-[6vh]"
        />
        <br />
        <div className="w-[82%] ">
          <h1 className="text-center sm:text-[32px] md:text-[52px] font-semibold txt-pre">
            Nos Casamos
          </h1>
          <br />
          <br />
          <p className="text-center sm:text-[20px] md:text-[32px] txt-pre">
            ¡A ponerse guapos que nos vamos a casar! Tenemos todo… el vestido,
            el traje, el coche, las flores, la iglesia y el lugar de la
            recepción... Pero nos falta lo más importante: ¡Contar contigo en
            este día tan especial!
          </p>
        </div>
        <br />
        <br />
        <div className="w-auto sm:p-2 md:p-10 flex flex-col justify-center items-center border-4">
          <h1 className="text-center sm:text-[20px] md:text-[32px] txt-pre">
            MARIANA ELIAN
          </h1>
          <br />
          <p className="text-center sm:text-[20px] md:text-[32px] txt-pre">
            Has sido invitado a la boda de Zayn y Perrie
          </p>
          <br />
          <br />
          <GiTicket
            className="text-[50px] bg-slate-50 p-2 rounded-2xl"
            style={{
              color: "#a3a438",
            }}
          />
          <br />
          <br />
          <p className="text-center sm:text-[20px] md:text-[32px] txt-pre">
            Cuentas con 2 accesos para disfrutar nuestro dia especial
          </p>
        </div>
        <br />
        <br />
        <img
          src="https://i.postimg.cc/mrQ40bTZ/501998a2-4c97-4e15-877b-63f84614f5c6.jpg"
          alt=""
          className="h-[6vh]"
        />
        <br />
      </div>

      <div className="sm:h-auto md:h-[100vh] flex flex-col items-center px-4 my-32">
        <div className="flex items-center justify-between sm:w-full md:w-[60%]">
          <img
            src="https://media.admagazine.com/photos/641239e4d3f4fe9a251b2477/1:1/w_1999,h_1999,c_limit/destinos-para-bodas.jpg"
            alt=""
            className="object-cover sm:h-[28vh] sm:w-[50%] md:h-[40vh] md:w-[30vh] rounded-3xl"
          />

          <h1 className="text-center sm:text-[22px] md:text-[52px] txt-pre">
            SOLO FALTAN
          </h1>
        </div>
        <br />

        <div
          className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
          data-aos="fade-up"
          // style={{
          //   border: "15px solid transparent",
          //   padding: "15px",
          //   borderImage:
          //     "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')",
          //   borderImageSlice: "46",
          //   borderImageWidth: "round",
          // }}
        >
          <div className="flex flex-col m-4 justify-center items-center">
            <p className="font-serif sm:text-3xl md:text-3xl">
              {timeRemaining.days}
            </p>
            <p className="font-serif sm:text-xl md:text-2xl">Dias</p>
          </div>
          <div className="flex flex-col m-4 justify-center items-center">
            <p className="font-serif sm:text-3xl md:text-3xl">
              {timeRemaining.hours}
            </p>
            <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
          </div>
          <div className="flex flex-col m-4 justify-center items-center">
            <p className="font-serif sm:text-3xl md:text-3xl">
              {timeRemaining.minutes}
            </p>
            <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
          </div>
          <div className="flex flex-col m-4 justify-center items-center">
            <p className="font-serif sm:text-3xl md:text-3xl">
              {timeRemaining.seconds}
            </p>
            <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
          </div>
        </div>

        <br />
        <div className="flex items-center justify-between sm:w-full md:w-[60%]">
          <h1 className="text-center sm:text-[22px] md:text-[52px] txt-pre">
            PARA LA BODA
          </h1>
          <img
            src="https://s.ineventos.com/blog/2021/100128/013515-1080w.webp"
            alt=""
            className="sm:h-[28vh] sm:w-[50%] md:h-[40vh] md:w-[30vh] rounded-3xl object-cover"
          />
        </div>
      </div>

      <div className="sm:h-auto md:h-[100vh] justify-center flex flex-col items-center px-4 mt-[20vh] w-full">
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <img
            src="https://i.postimg.cc/5tzbBR1Z/inicio-Padres.png"
            alt=""
            className="h-[15vh]"
          />
          <br />
          <br />
          <h1
            className="txt-pre text-center sm:text-[32px] md:text-[52px]"
            data-aos="flip-up"
          >
            ¡Celebra el amor y apoyo de nuestros padres!
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] txt-pre sm:text-xl md:text-3xl"
            data-aos="flip-up"
          >
            Únete a nosotros para ser testigo de nuestra inolvidable unión.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl">
                Padres de la novia
              </p>
              <br />
              <p className="txt-pre text-center text-xl">ADAWDAWDAW</p>
              <p className="txt-pre text-center text-xl">&</p>
              <p className="txt-pre text-center text-xl">AWDAWDAWAWD</p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl">
                Padres del novio
              </p>
              <br />
              <p className="txt-pre text-center text-xl">ADAWDAWD</p>
              <p className="txt-pre text-center text-xl">&</p>
              <p className="txt-pre text-center text-xl">AWDAWDAWDAW</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="sm:h-auto md:h-[100vh] flex flex-col sm:items-start md:items-center  px-4 sm:my-[20vh] md:my-0">
        <h1 className="">CEREMONIA</h1>
        <div className="mt-10 md:w-1/2 absolute">
          <div class="rounded-2xl sobrePon azul md:min-h-[50vh] md:min-w-[50vh] sm:min-h-[25vh] sm:min-w-[25vh]">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/00090_CATEDRAL_BAS%C3%8DLICA_DE_LA_VIRGEN_DE_LA_ASUNCI%C3%93N.jpg/1200px-00090_CATEDRAL_BAS%C3%8DLICA_DE_LA_VIRGEN_DE_LA_ASUNCI%C3%93N.jpg" alt="" className="w-full h-full object-cover" />
          </div>
          <div class="drop-shadow-2xl rounded-2xl sobrePon rojo md:min-h-[50vh] md:min-w-[60vh] sm:h-auto sm:min-w-[25vh] sm:left-[12vh] sm:top-[15vh] md:left-[25vh] md:top-[25vh]  flex justify-center items-center flex-col">
          <h1>CATEDRAL DE AGUASCALIENTES</h1>
          <br />
          <p className="text-center">adwadmwaidawdaiw dajwndaw anwiduwand adniawua</p>
          <br />
          <a
              href={'/'}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mapa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
        </div>
      </div>
      <br />
      <div className="sm:h-auto md:h-[100vh] flex flex-col sm:items-start md:items-center  px-4 sm:my-[20vh] md:my-0">
        <h1 className="">CEREMONIA</h1>
        <div className="mt-10 md:w-1/2 absolute">
          <div class="rounded-2xl sobrePon azul md:min-h-[50vh] md:min-w-[50vh] sm:min-h-[25vh] sm:min-w-[25vh]">
          <img src="https://cdn0.bodas.com.mx/vendor/3923/3_2/960/jpg/img-9124_5_173923-168971286492127.jpeg" alt="" className="w-full h-full object-cover" />
          </div>
          <div class="drop-shadow-2xl rounded-2xl sobrePon rojo md:min-h-[50vh] md:min-w-[60vh] sm:h-auto sm:min-w-[25vh] sm:left-[12vh] sm:top-[15vh] md:left-[25vh] md:top-[25vh]  flex justify-center items-center flex-col">
          <h1>CATEDRAL DE AGUASCALIENTES</h1>
          <br />
          <p className="text-center">adwadmwaidawdaiw dajwndaw anwiduwand adniawua</p>
          <br />
          <a
              href={'/'}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mapa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="sm:h-auto md:h-[100vh] flex flex-col sm:items-start md:items-center  px-4 sm:my-[20vh] md:my-0">
      <h1 className="">HOSPEDAJE</h1>
        <div className="mt-10 md:w-1/2 absolute">
          <div class="rounded-2xl sobrePon azul md:min-h-[50vh] md:min-w-[50vh] sm:min-h-[25vh] sm:min-w-[25vh]">
          <img src="https://cdn0.bodas.com.mx/vendor/3923/3_2/960/jpg/img-9124_5_173923-168971286492127.jpeg" alt="" className="w-full h-full object-cover" />
          </div>
          <div class="drop-shadow-2xl rounded-2xl sobrePon rojo md:min-h-[50vh] md:min-w-[60vh] sm:h-auto sm:min-w-[25vh] sm:left-[12vh] sm:top-[15vh] md:left-[25vh] md:top-[25vh]  flex justify-center items-center flex-col">
          <h1>CATEDRAL DE AGUASCALIENTES</h1>
          <br />
          <p className="text-center">adwadmwaidawdaiw dajwndaw anwiduwand adniawua</p>
          <br />
          <a
              href={'/'}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mapa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
        </div>
      </div>
      
      <br /><br />

      <div className="m:h-auto md:h-[100vh] justify-center py-[20vh] flex flex-col items-center px-4 mt-[20vh] w-full bg-black">
      <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
        <h1
          className="font-serif text-white text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          Mesa de regalos
        </h1>
        <br />
        <p
          className=" text-center text-white pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
          data-aos="flip-up"
        >
          {'Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!'}
        </p>

        <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[8vh]">
          <div
            style={{
              backgroundImage:
                'url("https://centrosantafe.com.mx/cdn/shop/files/Sears.png?v=6586984057980492581")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
            }}
            className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[30vh]"
            data-aos="fade-right"
          >
            <a
              href={''}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mesa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
          <div
            style={{
              backgroundImage:
                'url("https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
            }}
            className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[30vh] sm:mt-4 md:mt-0"
            data-aos="fade-right"
          >
            <a
              href={''}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mesa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
          <div
            style={{
              backgroundImage:
                'url("https://www.gphlegal.mx/wp/wp-content/uploads/2019/07/financiacion-amazon.jpg")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
            }}
            className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[30vh] sm:mt-4 md:mt-0"
            data-aos="fade-right"
          >
            <a
              href={''}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mesa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
        </div>
      </div>
      </div>

      <div
        style={{
           backgroundImage:
             'url("https://invites-now.com/wp-content/uploads/2023/01/hojas-sombras.png")',
           backgroundRepeat: "repeat",
           backgroundSize:"cover", // or "100% auto"
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="sm:h-[150vh] md:h-[150vh] flex flex-col justify-center items-center w-full"
      >
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          Mi Día Especial
        </h1>
        <br />
        <p
          className=" text-center text-white p-[5vh] font-serif text-xl sm:w-[80%] md:w-[50%] rounded-2xl"
          data-aos="flip-up"
          style={{
            background: "rgb(0,0,0)",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0.7596288515406162) 28%, rgba(0,0,0,0.7596288515406162) 68%)",
          }}
        >
         Mi lugar favorito es donde todos estamos juntos.
        </p>
        <br />
        <br />
        <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
          {images?.map((imageUrl, index) => (
            <div data-aos="fade-down-right" className="rolImg" key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-auto rounded-xl max-h-[40vh]"
              />
            </div>
          ))}
        </Slider>
        <br />
        <img
            src="https://i.postimg.cc/5tzbBR1Z/inicio-Padres.png"
            alt=""
            className="h-[15vh]"
          />
        <br />
        <div
          data-aos="fade-down-left"
          className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
        >
          <h2 className="text-2xl font-bold mb-4 justify-center flex">
            Confirmación de Asistencia a la Boda{" "}
            <FaWhatsapp className="text-green-600 ml-2" />
          </h2>
          <div className="flex justify-center items-center">
            <div className="w-full text-center p-2 bg-green-700 mt-5 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  '4491516931'
                }&text=${encodeURIComponent('bla bla bla')}`}
                className="text-white text-center md:w-[60%] md:text-xl text-auto"
              >
                Confirma tu invitacón a la boda 💌
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
