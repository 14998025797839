import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./cancionXVmonserrat.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";

export const XvValeriaEstefania = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Valeria Estefania",
      fechaDeBoda: "13 ABRIL, 2024",
      fechaNewDate: "April 13, 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/BQkVfRgN/6f97a1a9-0608-4ea2-af9c-c90525110ddd-1.jpg",
      numeroNovia: "4491865690",
      numeroNovio: "",
      mensajeAsistencia:'Favor de confirmar antes del día 30 de Marzo de 2024.'
    };
  
    const peltaColores = {
      color1: "#cb8cff",
      color2: "#e6cdf7",
      messageVestimenta:'¡Importante! Prohibido llevar vestimenta color lila, este color está reservado exclusivamente para la quinceañera.'
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Valeria Estefania!',
      entradasText:'disfrutar mis XV años',
      colorFondoName: '#cb8cff'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy hace XV años mis padres daban gracias a Dios por mi. Hoy doy gracias a Dios por ellos,por cuidarme,por tenerme paciencia y consentirme. Doy gracias también a toda mi familia por hacer más especial este día. Gracias por acompañarme",
      fechaNewDate: "April 13, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/zB7QmBtR/9b0550c3-cf1f-41f8-97db-1d0fd4a31266.jpg",
      fotoDerecha: "https://i.postimg.cc/Qd7n8SYD/a1676603-8b8c-4915-96c9-f5f7400cbe1f.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Maria Guadalupe López García",
      esposaPadre: "Julio Jesús Torres Camarena",
      esposoMadre: "Nancy Torres Camarena",
      esposoPadre: "Jorge Salas Hernández",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 13 de Abril de 2024.",
      ceremoniaHora: "6:00 p.m.",
      ceremoniaNombreLugar: "PARROQUIA DIVINO NIÑO",
      ceremoniaLugarMap:
        "Villa del Rey 223, Lomas del Ajedrez, 20299 Aguascalientes, Ags.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/BNuH18Jv4XnQZzBv7",
        recepcionFecha: "Sábado, 13 de Abril de 2024.",
      recepcionHora: "8:00 p.m.",
      recepcionNombreLugar: "QUINTA LAS PALMAS",
      recepcionLugarMap:
        "Av. de los Maestros 145 C, Barandales de San José, 20342 Barandales de San José, Ags.",
      recepcionUrl:
        "https://maps.app.goo.gl/Erkc3KyDgQ12CWjp7",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "6:00 pm ceremonia religiosa ",
      baileXV: "9:00 pm Baile de XV años",
      cena: "10:00 Disfruta de una deliciosa cena",
      recepcion: "8:00 pm recepción ",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "10:30 pm Sácale brilló a la pista 💃🏻🕺🏽",
    };
  
  
    const datosSeccion7 = {
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    };
  
    const datosSeccion8 = {
      descripcion:
        "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo, peluches o perfumes . Gracias por permitirme escoger mi regalo.",
      linkAmazon:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      linkLiverpool:
        "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    };
  
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
  
  
    const images = [
      "https://i.postimg.cc/TY20k477/a6de21e1-374b-421a-bdc2-966769fd391b.jpg",
      "https://i.postimg.cc/XNkxkb8B/f426f214-a8ca-4cbf-88e6-a76dedce930e.jpg",
      "https://i.postimg.cc/02bB39pb/6f97a1a9-0608-4ea2-af9c-c90525110ddd.jpg",
      "https://i.postimg.cc/SKp1J8HM/8bb0b956-f48a-44cd-a71e-43c1bb8b33a3.jpg",
      "https://i.postimg.cc/HnPPxNCY/ace788cb-cc79-4dc3-9ec2-2e4eb08f7749.jpg",
      "https://i.postimg.cc/h4CYpXQk/8aa6c825-10b3-4eff-8cbc-bcd424aca57e.jpg",
      "https://i.postimg.cc/zB7QmBtR/9b0550c3-cf1f-41f8-97db-1d0fd4a31266.jpg"
      // Add more image URLs as needed
    ];
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/h4CYpXQk/8aa6c825-10b3-4eff-8cbc-bcd424aca57e.jpg")',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
    }
  
    const names = [
            { name: "Angel Jaimes", guess: "2" },
            { name: "MARIA DE JESUS TORRES", guess: "1" },
            { name: "CARMELA TORRES", guess: "1" },
            { name: "TORRES CERVANTES", guess: "4" },
            { name: "RAMIREZ TORRES", guess: "2" },
            { name: "CARRILLO RAMIREZ", guess: "4" },
            { name: "RAMIREZ MONCIVAIS", guess: "" },
            { name: "MENA TORRES", guess: "2" },
            { name: "SORIANO CAMARENA", guess: "3" },
            { name: "VERONICA SORIANO CAMARENA", guess: "4" },
            { name: "FABIOLA SORIANO CAMARENA", guess: "3" },
            { name: "SORIANO GUTIERREZ", guess: "5" },
            { name: "GARCIA CAMARENA", guess: "3" },
            { name: "ROBLEDO GARCIA", guess: "5" },
            { name: "MEDINA ROBLEDO", guess: "2" },
            { name: "ROBLEDO ORTIZ", guess: "2" },
            { name: "GARCIA CAMARENA", guess: "3" },
            { name: "CHAVEZ GARCIA", guess: "4" },
            { name: "GARCIA RUVALCABA", guess: "5" },
            { name: "GARCIA GARCIA", guess: "5" },
            { name: "CAMARENA TORRES", guess: "3" },
            { name: "SALAS TORRES", guess: "4" },
            { name: "TORRES CAMARENA", guess: "3" },
            { name: "LUNA TORRES", guess: "2" },
            { name: "LOPEZ GARCIA", guess: "2" },
            { name: "LOPEZ DE ALBA", guess: "5" },
            { name: "RODRIGUEZ LOPEZ", guess: "7" },
            { name: "TORRES LOPEZ", guess: "5" },
            { name: "LOPEZ GONZALEZ", guess: "6" },
            { name: "LOPEZ IBARRA", guess: "4" },
            { name: "GARCIA SANTOS", guess: "2" },
            { name: "CASTILLO GARCIA", guess: "10" },
            { name: "IBARRA CAMACHO", guess: "2" },
            { name: "LOPEZ RODRIGUEZ", guess: "2" },
            { name: "LOPEZ SILVA", guess: "2" },
            { name: "DIAZ LOPEZ", guess: "2" },
            { name: "TRIZTAN DIONICIO", guess: "5" },
            { name: "LEOS CUEVAS", guess: "4" },
            { name: "LARA LOMELI", guess: "7" },
            { name: "OSCAR JIMENEZ", guess: "2" },
            { name: "DANIEL MEDINA", guess: "2" },
            { name: "NEVAREZ PREZA", guess: "6" },
            { name: "ARMANDO MACIAS", guess: "2" },
            { name: "ALEJANDRO SAUCEDO", guess: "2" },
            { name: "RAMIREZ MARENTEZ", guess: "2" },
            { name: "DIEGO AQUINO", guess: "2" },
            { name: "IRVING GOMEZ", guess: "5" },
            { name: "ATON FLORES MUÑOZ", guess: "5" },
            { name: "ALAN DIAZ", guess: "2" },
            { name: "TERESITO GALLEGOS HERNANDEZ", guess: "3" },
            { name: "ALEJANDO MORALES RAMIREZ", guess: "2" },
            { name: "EDGAR ROLDAN MERCADO", guess: "4" },
            { name: "GARCIA LOERA", guess: "4" },
            { name: "GARCIA ZACARIAS", guess: "5" },
            { name: "KEYLA RODRIGUEZ", guess: "1" },
            { name: "FERNANDA RAMIREZ", guess: "1" },
            { name: "ALIZETH CAMPOS", guess: "1" },
            { name: "BIANCA CARRILLO", guess: "1" },
            { name: "ANNEL ADAME", guess: "1" },
            { name: "SOFIA CRUZ", guess: "1" },
            { name: "SARA GONZALEZ", guess: "1" },
            { name: "RAMIREZ MIRALES", guess: "4" },
            { name: "PALOS HERNANDEZ", guess: "2" },
            { name: "ANA VAZQUEZ", guess: "3" },
            { name: "JAIME ESPINOZA", guess: "2" },
            { name: "NERI GARCIA", guess: "4" },
            { name: "FAMILIA RODRIGUEZ HERNANDEZ", guess: "5" },
    ];
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
  
      const findName = names.find((e) => e.name == nameId);
      console.log(findName?.name);
  
      if (findName) {
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
      } else {
        window.location.href = "/";
      }
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Invitados
          NameInvitation={NameInvitation}
          numGuess={numGuess}
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
          datosInvitados={datosInvitados}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiPartyPopper  className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE DE XV AÑOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baileXV}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />
         
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3700.913330480417!2d-102.29668369999999!3d21.9378831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efa1a0aac581%3A0x2630401c2c5a339e!2sParroquia%20del%20Divino%20Salvador!5e0!3m2!1ses-419!2smx!4v1707959030520!5m2!1ses-419!2smx" width="100%" height="100%" className="fixed"></iframe>
              <a
                href={datosSeccion4.ceremoniaUrl}
                class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
              >
                Mapa de la Ceremonia
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div
              className="flex flex-col justify-end w-[90%] sm:mt-16 md:mt-0 rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1557.0695925355926!2d-102.32295455551537!3d21.84378146025993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ec40105d6e5f%3A0x8d906a50b66c725!2sQuinta%20Las%20Palmas!5e0!3m2!1ses!2smx!4v1710202878708!5m2!1ses!2smx" width="100%" height="100%" className="fixed"></iframe>
              <a
                href={datosSeccion4.recepcionUrl}
                class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
              >
                Mapa de la Recepción
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:px-3 sm:py-10 md:p-0 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://www.imoshperfumes.com/images/perfumes-guadalajara.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "90% 100%",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
             
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://images.vexels.com/media/users/3/263272/isolated/preview/513f5d260e57958943405d8abcbfd77e-icono-de-caa-da-de-billetes-de-negocio-de-dinero.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="md:flex  flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              
            </div>
          </div>
        </div>
      </div>
  
        <Confirmacion
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
