import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./assets/0808.MP3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { LuRat } from "react-icons/lu";
import { FaChampagneGlasses, FaWhatsapp } from "react-icons/fa6";
import { PiChurchLight } from "react-icons/pi";
import Slider from "react-slick";

export const BautizoEdgar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const datosSeccion1 = {
    textoConfirmacion: "al Bautizo",
    nombre: "Edgar Elian",
    fechaDeBoda: "30 NOVIEMBRE, 2024",
    fechaNewDate: "November 30, 2024 18:00:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/3RKSGSsm/IMG-8158.jpg",
    numeroNovia: "+525536438000",
    numeroNovio: "",
    titulo: "NO FALTES A MI BAUTIZO",
    imgFondo: "https://i.postimg.cc/G3ZBvVVD/pixelcut-export.jpg",
    aviso:
      "Código de vestimenta: \\n Formal\\n En colores claros\\n\\n Hola, amig@ respecto a la cordial invitación al cumple de nuestra pequeña, amablemente te informamos que la confirmación de asistencia es de carácter mandatorio. \\nAl no recibir tu confirmación antes del 18 de agosto estamos entiendiendo que declinas la invitación, por lo que tu lugar será trasladado a otro amiguito.\\nMuchas gracias por tu respuesta lindo día. 🎈🥳🤩\\n\\nSi gustas nosotros nos hospedaremos en Mar paraíso Queen",
  };

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccion2 = {
    titulo: (
      <div className="flex flex-col justify-center items-center">
        Mi Bautizo <br />
        <br />
        <LuRat />
      </div>
    ),
    descipcion:
      "En este día especial la luz de dios se encenderá en mi corazón para iluminar todo el camino de mi vida.",
    fechaNewDate: "November 30, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/ncWgpDhv/Dise-o-sin-t-tulo-2024-08-04-T150142-591.png",
    fotoDerecha:
      "https://i.postimg.cc/Xq7m7K5g/Dise-o-sin-t-tulo-2024-08-04-T150059-473.png",
    imgFondo: "https://i.postimg.cc/G3ZBvVVD/pixelcut-export.jpg",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de Edgar Elian",
    tituloDerecha: "Padrinos de Edgar Elian",
    esposaMadre: "Gabriela Olivia Rivas Alfaro ",
    esposaPadre: "Edgar Alcántara Trujillo",
    esposoMadre: "Olga Itzel Aparicio Zamora",
    esposoPadre: "Mario López Miranda",
    imgFondo: "https://i.postimg.cc/G3ZBvVVD/pixelcut-export.jpg",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 30 de Noviembre de 2024",
    ceremoniaHora: "12:00 p.m.",
    ceremoniaNombreLugar: "GRUPO FESADA 'CASA CABAÑA'",
    ceremoniaLugarMap:
      "Playa la Gloria, Blvrd Barra Vieja, Aeropuerto, 39893 Acapulco de Juárez, Gro.D",
    ceremoniaUrl: "https://maps.app.goo.gl/Jm2LsbYmLgVLsaGN6?g_st=ic",
    recepcionFecha: "Sábado, 30 de Noviembre de 2024",
    recepcionHora: "2:00 p.m.",
    recepcionNombreLugar: "GRUPO FESADA 'CASA CABAÑA'",
    recepcionLugarMap:
      "Playa la Gloria, Blvrd Barra Vieja, Aeropuerto, 39893 Acapulco de Juárez, Gro.",
    recepcionUrl: "https://maps.app.goo.gl/Jm2LsbYmLgVLsaGN6?g_st=ic",
    imgFondo: "https://i.postimg.cc/G3ZBvVVD/pixelcut-export.jpg",
    recepcionImg: "https://i.postimg.cc/ZRdttk3J/Dise-o-sin-t-tulo-55.png",
  };

  const images = [
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, soy ${name} y confirmo mi asistencia ${datosSeccion1.textoConfirmacion} ¡Nos vemos ahí!`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccion1.numeroNovia
    }&text=${encodeURIComponent(message)}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} />
      <Portada datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <div
        style={{
          backgroundImage: `url(${datosSeccion4.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: datosSeccion4.textColor || "",
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <div
            className="my-2 flex flex-col justify-center items-center py-4"
            style={{
              boxShadow: "-4px 4px 12px 0px rgba(0, 0, 0, 0.44)",
            }}
          >
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[80px]" />
              <br />
              <p className="font-serif text-2xl">Ceremonia</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion4.ceremoniaFecha}
              </p>
              <p className="font-serif text-xl text-center">
                {datosSeccion4.ceremoniaHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccion4.ceremoniaNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.ceremoniaLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.ceremoniaUrl}
                class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>

            <div
              className="flex flex-col justify-center items-center sm:pt-[5vh] md:pt-0"
              data-aos="fade-right"
            >
              {datosSeccion4.recepcionImg ? (
                <img
                  src={datosSeccion4.recepcionImg}
                  className="w-[80px]"
                  alt=""
                />
              ) : (
                <FaChampagneGlasses className="text-[80px]" />
              )}
              <br />
              <p className="font-serif text-2xl">Recepción</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionFecha}
              </p>
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center ">
                {datosSeccion4.recepcionNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.recepcionLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.recepcionUrl}
                class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${datosSeccion1.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: datosSeccion1.textColor || "",
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
      >
        <h1
          className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
          data-aos="flip-up"
        >
          {datosSeccion1.tituloConfirmacion || (
            <div>
              Espero puedan confirmarme su asistencia. <br />
              Agradezco de antemano su apreciable compañía.
            </div>
          )}
        </h1>
        <br />
        <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
          {(datosSeccion1.aviso || "").split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))}
        </p>
        <br />
        <br />
        <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
          {images?.map((imageUrl, index) => (
            <div data-aos="fade-down-right" className="rolImg" key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-auto rounded-xl max-h-[60vh]"
              />
            </div>
          ))}
        </Slider>
        <br />
        <br />
        <div
          data-aos="fade-down-left"
          className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
        >
          <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
            {datosSeccion1.textWhatsApp ||
              "Confirmación de Asistencia al Evento"}{" "}
            <FaWhatsapp className="text-green-600 ml-2" />
          </h2>
          <form onSubmit={handleSubmit}>
            <label className="mb-2 flex">
              <span className="text-gray-700">Nombre:</span>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                required
              />
            </label>
            <br />
            <button
              type="submit"
              className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
            >
              Confirmar Asistencia
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
