import React from 'react'
import { ImagenesIconos } from '../../components/ImagenesIconos'

export const Iconos = () => {
  return (
    <div>
        <ImagenesIconos/>
    </div>
  )
}
