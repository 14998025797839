import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChampagneFill, PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/maroon.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaJuanYNallely = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#dae7f1",
    color2: "#e9c19f",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEcrPNuAWzR_y2mate.com---Cant-Take-My-Eyes-off-You.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Carlos",
    esposa: "Fernanda",
    fechaDeBoda: "25 MAY, 2024",
    imgPortada: "https://i.postimg.cc/PqhDX5kj/IMG-3522.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 9 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    fechaNewDate: "May 25, 2024 15:30:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/WbBry7Wt/IMG-3583.jpg",
    fotoDerecha: "https://i.postimg.cc/RZwfxnyd/IMG-3417.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Fernanda y Carlos!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "María Gutiérrez",
    padre: "Hugo Jimenez",
    titulo2: "Padres del Novio",
    madrina: "Isidra Martinez",
    padrino: "Salvador Vega",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
    ceremoniaHora: "5:30 p.m.",
    ceremoniaNombreLugar: "",
    ceremoniaLugarMap: "Felipe Angeles, 45590 San Pedro Tlaquepaque, Jal.",
    ceremoniaUrl: "https://maps.app.goo.gl/WVuPiHwL6aGNeyvK6",
    recepcionEnable: false,
    recepcionFecha: "Sábado, 13 de Julio de 2024.",
    recepcionHora: "3:00 p.m.",
    recepcionNombreLugar: "Salón “El Encanto “",
    recepcionLugarMap: "Calle Rio colorado # 22 col. Del Valle 99084",
    recepcionUrl: "https://maps.app.goo.gl/HLQx2Wsr7ygCH2Em6",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "17:30 p.m",
    civil: "18:00 p.m",
    recepcion: "20:30 p.m",
    cena: "19:15 p.m",
    coctel: "21:30 p.m",
    baile: "01:00 a.m",
    imgFondo: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Informal",
    messageVestimenta: "El color azul esta reservado para los novios",
    urlVestimentaMujer: "https://i.postimg.cc/W19TJnWQ/Dise-o-sin-t-tulo-1.png",
    urlVestimentaHombre: "https://i.postimg.cc/FsV9hmjF/Dise-o-sin-t-tulo-2.png",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Hotel Avid",
    hotel1lugar:
      "Paseo del Mineral No.101', Emiliano Zapata, 99010 Fresnillo, Zac.",
    hotel1url: "https://maps.app.goo.gl/M7GjR8CLD7HHZBUm7",
    hotel2Titulo: "Motel la fortuna",
    hotel2lugar: "Carretera panamericana #km 724.3 industrial 99030",
    hotel2url: "https://maps.app.goo.gl/Cuir41Pod3j266Ff8",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Lluvia de sobres",
    descripcion:
      "En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo nos encantaría fuera efectivo . Gracias por permitirnos escoger nuestro regalo.",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1: "",
    link1Enable: false,
    link2: "",
    imgLink2: "",
    link2Enable: false,
    link3: "",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "3325798574",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/PqhDX5kj/IMG-3522.jpg",
    "https://i.postimg.cc/WbBry7Wt/IMG-3583.jpg",
    "https://i.postimg.cc/RZwfxnyd/IMG-3417.jpg",
    "https://i.postimg.cc/YS3WhBpm/IMG-3400.jpg",
    "https://i.postimg.cc/VvTMm9zG/IMG-3452.jpg",
    "https://i.postimg.cc/SN2CVPGw/IMG-3563.jpg",
    "https://i.postimg.cc/44Fp6Gp6/IMG-3608.jpg",
    "https://i.postimg.cc/pry8wcM5/IMG-3683.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
        audioFondo={audioFondo}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionProgramaBoda.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,

            color: datosSeccionProgramaBoda.textColor
              ? datosSeccionProgramaBoda.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionProgramaBoda.ceremoniaReligiosa ? (
                  <>
                    <FaChampagneGlasses className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      COCTEL DE BIENVENIDA
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.ceremoniaReligiosa}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.civil ? (
                  <>
                    <GiBigDiamondRing className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      CEREMONIA CIVIL
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.civil}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.cena ? (
                  <>
                    <ImSpoonKnife className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">CENA</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.cena}
                    </p>
                    <br />
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>

              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionProgramaBoda.recepcion ? (
                  <>
                    <PiChampagneFill className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">Brindis</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.recepcion}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
                
                {datosSeccionProgramaBoda.coctel ? (
                  <>
                    <GiPartyPopper className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">Fiesta</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.coctel}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.baile ? (
                  <>
                    <GiMeshBall className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">BAILE DE DESPEDIDA</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.baile}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
