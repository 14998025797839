import { XvAlondraDaniela } from "../screens/XV/xv-diamante/XvAlondraDaniela";
import { XVCarolina } from "../screens/XV/xv-plata/XVCarolina";
import { XvAlexaMedina } from "../screens/XV/xv-plata/XvAlexaMedina";
import { XvAlexiaPaola } from "../screens/XV/xv-plata/XvAlexiaPaola";
import { XVBibiana } from "../screens/XV/xv-plata/XvBibiana";
import { XvEjemploPlata } from "../screens/XV/xv-plata/XvEjemploPlata";
import { XvEstefanySarahí } from "../screens/XV/xv-plata/XvEstefanySarahí";
import { XvFernandaZoe } from "../screens/XV/xv-plata/XvFernandaZoe";
import { XvLauraJanett } from "../screens/XV/xv-plata/XvLauraJanett";
import { XvRosaValeria } from "../screens/XV/xv-plata/XvRosaValeria";
import { XvSusan } from "../screens/XV/xv-plata/XvSusan";
import { XvKarely, XvKarenGuadalupe } from "../screens/XV/xv-plata/XvKarely";
import { XvEjemploNew } from "../screens/XV/xv-plata/XvEjemploNew";
import { XvDulceMontserrat } from "../screens/XV/xv-plata/XvDulceMontserrat";
import { XvEstefaníaEsquivel } from "../screens/XV/xv-plata/XvEstefaníaEsquivel";
import { XvLuciaElena, XvLuciaElenaa } from "../screens/XV/xv-plata/XvLuciaElena";
import { XvGabyOrtiz } from "../screens/XV/xv-plata/XvGabyOrtiz";
import { XvLucia } from "../screens/XV/xv-plata/XvLucia";
import { XvDannaFernanda } from "../screens/XV/xv-plata/XvDannaFernanda";
import { XvSabrinaGuadalupe } from "../screens/XV/xv-plata/XvSabrinaGuadalupe";
import { XvXimena } from "../screens/XV/xv-plata/XvXimena";
import { XvAdrianna } from "../screens/XV/xv-plata/XvAdrianna";
import { XvMayra } from "../screens/XV/xv-plata/XvMayra";
import { XvHombreEjemplo } from "../screens/XV/xv-plata/XvHombreEjemplo";
import { XvMary } from "../screens/XV/xv-plata/XvMary";
import { XvDanielyXimena } from "../screens/XV/xv-plata/XvDanielyXimena";
import { XvKarla } from "../screens/XV/xv-plata/XvKarla";
import { XvIrene } from "../screens/XV/xv-plata/XvIrene";
import { XvLuzIrene } from "../screens/XV/xv-plata/XvLuzIrene";
import { XvJimena } from "../screens/XV/xv-plata/XvJimena";
import { XvHannaValeria } from "../screens/XV/xv-plata/XvHannaValeria";
import { XvElisa } from "../screens/XV/xv-plata/XvElisa";
import { XvAngelaMelissa } from "../screens/XV/xv-plata/XvAngelaMelissa";
import { XvAri } from "../screens/XV/xv-plata/XvAri";
import { XvMiaFernanda } from "../screens/XV/xv-plata/XvMiaFernanda";
import { XvMariaFernanda } from "../screens/XV/xv-plata/XvMariaFernanda";
import { XvMichel } from "../screens/XV/xv-plata/XvMichel";
import { XvDannaeGarcia } from "../screens/XV/xv-plata/XvDannaeGarcia";
import { XvMayteBerenice } from "../screens/XV/xv-plata/XvMayteBerenice";
import { XvEmilyMichelle } from "../screens/XV/xv-plata/XvEmilyMichelle";
import { XvZua } from "../screens/XV/xv-plata/XvZua";
import { XvNahimaMaricarmen } from "../screens/XV/xv-plata/XvNahimaMaricarmen";
import { XvMerari } from "../screens/XV/xv-plata/XvMerari";
import { XvNataliaAbigail } from "../screens/XV/xv-plata/XvNataliaAbigail";
import { XvMerari2 } from "../screens/XV/xv-plata/XvMerari2";
import { XvSaidKaleb } from "../screens/XV/xv-plata/XvSaidKaleb";
import { XvAlissonNicolle } from "../screens/XV/xv-plata/XvAlissonNicolle";
import { XvDanae } from "../screens/XV/xv-plata/XvDanae";
import { XvElisaOrtega } from "../screens/XV/xv-plata/XvElisaOrtega";
import { XvAlondra } from "../screens/XV/xv-plata/XvAlondra";
import { XvIngridNicole } from "../screens/XV/xv-plata/XvIngridNicole";
import { XvEjemploSinFoto } from "../screens/XV/xv-plata/XvEjemploSinFoto";

const RoutesXvPlata = [
  {
    path: "/evento-plata-xv",
    element: <XvEjemploPlata />,
  },
  {
    path: "/evento-plata-xv-fotos",
    element: <XvEjemploSinFoto />,
  },
  {
    path: "/evento-plata-xv-ejemplo",
    element: <XvEjemploNew/>,
  },
  {
    path: "/evento-plata-xv-alexiaPaola",
    element: <XvAlexiaPaola />,
    },
    {
      path: '/evento-plata-xv-carolina',
      element: <XVCarolina />
    },
    {
      path: '/evento-plata-xv-bibiana',
      element: <XVBibiana />
  },
  {
    path: "/evento-plata-xv-laura",
    element: <XvLauraJanett />,
  },
  {
    path: "/evento-plata-xv-rosa-valeria",
    element: <XvRosaValeria />,
  },
  {
    path: "/evento-plata-xv-susan",
    element: <XvSusan />,
  },
  {
    path: "/evento-plata-xv-fernanda-zoe",
    element: <XvFernandaZoe />,
  },
  {
    path: "/evento-plata-xv-estefany-sarahi",
    element: <XvEstefanySarahí/>,
  },
  {
    path: "/evento-plata-xv-alexia-medina",
    element: <XvAlexaMedina/>,
  },
  {
    path: "/evento-plata-xv-karely",
    element: <XvKarely/>,
  },
  {
    path: "/evento-plata-xv-dulce-montserrat",
    element: <XvDulceMontserrat/>,
  },
  {
    path: "/evento-plata-xv-estefanía-esquivel",
    element: <XvEstefaníaEsquivel/>,
  },
  {
    path: "/evento-plata-xv-gaby-ortiz",
    element: <XvGabyOrtiz/>,
  },
  {
    path: "/evento-plata-xv-lucia-elena",
    element: <XvLucia/>,
  },
  {
    path: "/evento-plata-xv-danna-fernanda",
    element: <XvDannaFernanda/>,
  },
  {
    path: "/evento-plata-xv-sabrina-guadalupe",
    element: <XvSabrinaGuadalupe/>,
  },
  {
    path: "/evento-plata-xv-ximena-mendoza",
    element: <XvXimena/>,
  },

  {
    path: "/evento-plata-xv-adriana",
    element: <XvAdrianna/>,
  },
  {
    path: "/evento-plata-xv-hombre-ejemplo",
    element: <XvHombreEjemplo />,
  },
  {
    path: "/evento-plata-xv-mayra",
    element: <XvMayra/>,
  },
  {
    path: "/evento-plata-xv-mary",
    element: <XvMary/>,
  },
  {
    path: "/evento-plata-xv-daniel-ximena",
    element: <XvDanielyXimena/>,
  },
  {
    path: "/evento-plata-xv-Leslie",
    element: <XvKarla/>,
  },
  {
    path: "/evento-plata-xv-danna",
    element: <XvIrene/>,
  },
  {
    path: "/evento-plata-xv-dira",
    element: <XvLuzIrene/>,
  },
  {
    path: "/evento-plata-xv-jimena",
    element: <XvJimena/>,
  },
  {
    path: "/evento-plata-xv-hanna-valeria",
    element: <XvHannaValeria/>,
  },
  {
    path: "/evento-plata-xv-elisa",
    element: <XvElisa/>,
  },
  {
    path: "/evento-plata-xv-angela-melissa",
    element: <XvAngelaMelissa/>,
  },
  {
    path: "/evento-plata-xv-ari",
    element: <XvAri/>,
  },
  {
    path: "/evento-plata-xv-mia-fernanda",
    element: <XvMiaFernanda/>,
  },
  {
    path: "/evento-plata-xv-maria-fernanda",
    element: <XvMariaFernanda/>,
  },
  {
    path: "/evento-plata-xv-michel-guadalupe",
    element: <XvMichel/>,
  },
  {
    path: "/evento-plata-xv-dannae-garcia",
    element: <XvDannaeGarcia/>,
  },
  {
    path: "/evento-plata-xv-jessica-berenice",
    element: <XvMayteBerenice/>,
  },
  {
    path: "/evento-plata-xv-emily-michelle",
    element: <XvEmilyMichelle />,
  },
  {
    path: "/evento-plata-xv-zua",
    element: <XvZua />,
  },
  {
    path: "/evento-plata-xv-nahima-maricarmen",
    element: <XvNahimaMaricarmen />,
  },
  {
    path: "/evento-plata-xv-merari",
    element: <XvMerari />,
  },
  {
    path: "/evento-plata-xv-merari2",
    element: <XvMerari2 />,
  },
  {
    path: "/evento-plata-xv-natalia-abigail",
    element: <XvNataliaAbigail />,
  },
  {
    path: "/evento-plata-xv-said-kaleb",
    element: <XvSaidKaleb />,
  },
  {
    path: "/evento-plata-xv-alisson-nicolle",
    element: <XvAlissonNicolle />,
  },
  {
    path: "/evento-plata-xv-danae",
    element: <XvDanae />,
  },
  {
    path: "/evento-plata-xv-elisa-ortega",
    element: <XvElisaOrtega />,
  },
  {
    path: "/evento-plata-xv-alondra",
    element: <XvAlondra />,
  },
  {
    path: "/evento-plata-xv-ingrid-nicole",
    element: <XvIngridNicole />,
  },
];

export default RoutesXvPlata;
