import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvIrene = () => {
  const [isOpen, setIsOpen] = useState(false);

  const peltaColores = {
    color1: "#ab4862",
    color2: "#75383f",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDCtNwTftuR_y2mate.com---Olivia-Rodrigo-making-the-bed-Official-Lyric-Video.mp3",
    imgPortada: "https://i.postimg.cc/3ND1Z8cJ/IMG-20240424-WA0133.jpg",
    nombre: "Danna Camila",
    fechaDeBoda: "01 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+526251341922",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Me siento eternamente agradecida con Dios por permitirme llegar a mis XV años y compartir este día con todos ustedes. Te invito a pasar conmigo esta fecha tan especial en compañía de mis padres y seres queridos. Desde niña soñé con ese gran momento en el que me sienta como una verdadera princesa.",
    fechaNewDate: "June 01, 2024 20:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/NMnbT9bz/IMG-20240424-WA0095.jpg",
    fotoDerecha: "https://i.postimg.cc/QN2ZZHsm/IMG-20240424-WA0081.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Irene Morales Chaparro",
    padre: "José Roberto Flores Lerma",
    madrina: "Martha Patricia Lerma Fierro",
    padrino: "Roberto Flores Batista",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 01 de Junio de 2024.",
    ceremoniaHora: "5:00 p.m.",
    ceremoniaNombreLugar: "Parroquia de San Judas Tadeo",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/FibTH9Ju7mk92ceN8",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 01 de Junio de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Salón Garden Junior",
    recepcionLugarMap: "Río Urique 3649, Amp Lerdo, 31520 Cuauhtémoc, Chih.",
    recepcionUrl: "https://maps.app.goo.gl/cmsQbnAKhkqmBN3N7",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Mujeres",
    messageVestimenta: "",
    messageVestimenta2: "Hombres",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/3ND1Z8cJ/IMG-20240424-WA0133.jpg",
    "https://i.postimg.cc/NMnbT9bz/IMG-20240424-WA0095.jpg",
    "https://i.postimg.cc/QN2ZZHsm/IMG-20240424-WA0081.jpg ",
    "https://i.postimg.cc/qBhHF8fg/IMG-20240424-WA0078-1.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  const VestimentaXvComponent = ({ peltaColores, datosSeccionVestimenta }) => {
    const [isIOS, setIsIOS] = useState(false);
    const textoConSaltosDeLinea = "Primera línea\\nSegunda línea\\nTercera línea";

    useEffect(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    }, []);
    return (
      <div
        style={{
          backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS ? "local" : "fixed",

          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          {/* <p
          className=" text-center pt-[5vh] font-serif text-xl"
          data-aos="flip-up"
        >
          {datosSeccionVestimenta.vestimenta
            ? datosSeccionVestimenta.vestimenta
            : "FORMAL"}
        </p> */}
          <br />
          {datosSeccionVestimenta.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
              {datosSeccionVestimenta.messageVestimenta
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          ) : (
            ""
          )}

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-left"
            >
              <p
                className=" text-center pt-[5vh] font-serif text-3xl"
                data-aos="flip-up"
              >
                {datosSeccionVestimenta.vestimenta}
              </p>

              <img
                src={
                  datosSeccionVestimenta.urlVestimentaMujer ||
                  "https://i.postimg.cc/X74pg0Wh/Dise-o-sin-t-tulo-18.png"
                }
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-1 text-center"></p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-right"
            >
              <p
                className=" text-center pt-[5vh] font-serif text-3xl"
                data-aos="flip-up"
              >
                {datosSeccionVestimenta.messageVestimenta2}
              </p>
              <img
                src={
                  datosSeccionVestimenta.urlVestimentaHombre ||
                  "https://i.postimg.cc/pTp4cVb1/suit-and-tie-isolated-on-white-background-flat-cartoon-design-black-suit-and-tie-icon-for-graduation.png"
                }
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-1 text-center">Cualquier tipo de ropa para Caballero. <br/>Color negro</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
          peltaColores={peltaColores}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
