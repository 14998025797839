import React from "react";
import { FaPhotoVideo } from "react-icons/fa";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { PiChurchLight } from "react-icons/pi";
import { WiTime2 } from "react-icons/wi";

export const ProgramaXvComponentD2 = ({ peltaColores, datosSeccionPrograma }) => {
  const fontFamilyStyle = {
    fontFamily: datosSeccionPrograma.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const renderSection = (sectionData, icon, index) => {
    const isEven = index % 2 === 0;
    const containerClass = `flex flex-col sm:flex-row ${isEven ? '' : 'sm:flex-row-reverse'} justify-center items-center`;

    return (
      <div key={index} className={containerClass} data-aos={isEven ? 'fade-right' : 'fade-left'}>
        <div className="flex flex-col items-center w-full sm:w-1/2 justify-center sm:justify-center sm:items-center text-center">
          {sectionData.img ? (
            <img src={sectionData.img} className="w-[70px] h-[70px]" alt={sectionData.title} />
          ) : (
            React.createElement(icon, { className: "text-[70px]" })
          )}
        </div>
        <div className="flex flex-col justify-center items-center w-full sm:w-1/2 text-center sm:text-left" style={fontFamilyStyle}>
          <p className="text-xl">{sectionData.title}</p>
          <p className="text-lg">
            {sectionData.text.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>
        </div>
        <hr className="bg-gray-700 h-1 w-[60%] sm:w-auto my-4" />
      </div>
    );
  };

  const sections = [
    { img: datosSeccionPrograma.ceremoniaReligiosaImg, title: datosSeccionPrograma.ceremoniaReligiosaTitulo || "CEREMONIA RELIGIOSA", text: datosSeccionPrograma.ceremoniaReligiosa, icon: PiChurchLight },
    { img: datosSeccionPrograma.recepcionImg, title: datosSeccionPrograma.recepcionTitulo || "RECEPCIÓN", text: datosSeccionPrograma.recepcion, icon: FaPhotoVideo },
    { img: datosSeccionPrograma.baileXvImg, title: datosSeccionPrograma.baileXvTitulo || "BAILE DE XV AÑOS", text: datosSeccionPrograma.baileXv, icon: GiPartyPopper },
    { img: datosSeccionPrograma.coctelImg, title: datosSeccionPrograma.coctelTitulo || "COCTEL", text: datosSeccionPrograma.coctel, icon: FaChampagneGlasses },
    { img: datosSeccionPrograma.cenaImg, title: datosSeccionPrograma.cenaTitulo || "CENA", text: datosSeccionPrograma.cena, icon: ImSpoonKnife },
    { img: datosSeccionPrograma.baileImg, title: datosSeccionPrograma.baileTitulo || "BAILE", text: datosSeccionPrograma.baile, icon: GiMeshBall },
  ];

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionPrograma.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionPrograma.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
    >
      <div className="flex flex-col w-full justify-center items-center p-2">
        <h1
          className="text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionPrograma.titulo || "Programa"}
        </h1>
        <br />
        <WiTime2 className="text-[60px] animate-pulse"/>
        <p
          className="text-center pt-[5vh] text-xl"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionPrograma.descripcion || "Hemos planeado un día lleno de diversión para disfrutar al máximo."}
        </p>
        <div className="flex flex-col w-[80%] pt-[10vh] space-y-8">
          {sections.map((section, index) => section.text && renderSection(section, section.icon, index))}
        </div>
      </div>
    </div>
  );
};
