import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaSilviaYEdgar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#a0a195",
    color2: "#edcac7",
    // #5C5F4E
    // #8D8E7C
    // #B08A81
    // #EFBFBB
    // #FFE5E4
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEit3tyR2yR_y2mate.com---Ed-Sheeran-Perfect.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Edgar",
    esposa: "Silvia",
    fechaDeBoda: "26 JULIO, 2024",
    imgPortada:
      "https://i.postimg.cc/63NzcLbp/Whats-App-Image-2024-05-16-at-23-39-13-2b5d18ef.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Hay momentos en la vida que son muy especiales por si solos, pero al compartirlos con personas tan especiales como tú, se convierten en momentos imposibles de olvidar , se parte de este momento inolvidable junto a nosotros",
    fechaNewDate: "July 26, 2024 14:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/zBS7cb4S/Whats-App-Image-2024-05-16-at-23-39-13-c45b78f2.jpg",
    fotoDerecha:
      "https://i.postimg.cc/Kj0NZXG1/Whats-App-Image-2024-05-16-at-23-39-13-b0dfcdaf.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Alberta Martínez Barreto",
    padre: "✟Silvino Quintana Villegas✟",
    titulo2: "Padres del Novio",
    madrina: "Araceli Salgado Rodríguez",
    padrino: "Edgar García Hernández",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Viernes, 26 de Julio de 2024.",
    ceremoniaHora: "2:00 p.m.",
    ceremoniaNombreLugar: "Capilla de Vistalegre",
    ceremoniaLugarMap: "Rosas 28, Vista Alegre, 62980 Tlaquiltenango, Mor.",
    ceremoniaUrl: "https://maps.app.goo.gl/wdKLybvGigmGmpEQA",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 26 de Julio de 2024.",
    recepcionHora: "3:30 p.m, Hora de boda civil 4:00 p.m",
    recepcionNombreLugar: "Casa Tlaqui Jardin",
    recepcionLugarMap:
      "C. 2a. Corregidora 40, Col de los Presidentes, 62980 Tlaquiltenango, Mor.",
    recepcionUrl: "https://maps.app.goo.gl/tHGy38XFrU4LjGdd9",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "CUENTA CLABE: 014540567049352180   Banco : Santander Titular. Edgar Daniel García Salgado",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "https://i.postimg.cc/FsfRkCZg/376.webp",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "'NO NIÑOS'",
    numeroConfirmacion: "+527341257477",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+527341257477",
    numeroNovio: "+527341066034",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/63NzcLbp/Whats-App-Image-2024-05-16-at-23-39-13-2b5d18ef.jpg",
    "https://i.postimg.cc/zBS7cb4S/Whats-App-Image-2024-05-16-at-23-39-13-c45b78f2.jpg",
    "https://i.postimg.cc/Kj0NZXG1/Whats-App-Image-2024-05-16-at-23-39-13-b0dfcdaf.jpg",
    "https://i.postimg.cc/8zJHVnqC/Whats-App-Image-2024-05-16-at-23-39-14-73ed72bf.jpg",
    "https://i.postimg.cc/jqcv6fHR/Whats-App-Image-2024-05-16-at-23-39-14-86412db2.jpg",
    "https://i.postimg.cc/ZqNV6DcQ/Whats-App-Image-2024-05-16-at-23-39-13-56c2384f.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Edgar García y Araceli Salgado", guess: "2", id: 1 },
    { name: "Joshua Amir García Salgado", guess: "2", id: 2 },
    { name: "Julia Rodríguez Castrejón", guess: "1", id: 3 },
    { name: "Mirna Salgado Rodríguez", guess: "1", id: 4 },
    { name: "Tahily Maquitíco Salgado", guess: "2", id: 5 },
    { name: "Anel García Hernández", guess: "2", id: 6 },
    { name: "Marco Antonio García Hernández", guess: "3", id: 7 },
    { name: "Eleael García Hernández", guess: "3", id: 8 },
    { name: "Eros García Hernández", guess: "3", id: 9 },
    { name: "René Salgado Rodríguez", guess: "2", id: 10 },
    { name: "Rodolfo Salgado Rodríguez", guess: "4", id: 11 },
    { name: "Paola Salgado Rodríguez", guess: "2", id: 12 },
    { name: "Jesus Salgado Mendoza", guess: "2", id: 13 },
    { name: "Alberta Martínez Barreto", guess: "2", id: 14 },
    { name: "Albert Quintana Martínez", guess: "2", id: 15 },
    { name: "María Barreto", guess: "2", id: 16 },
    { name: "Edgar Quintana", guess: "2", id: 17 },
    { name: "Carmen Castillo Martinez", guess: "2", id: 18 },
    { name: "Blanca Martinez Barreto", guess: "2", id: 19 },
    { name: "Jessica Medrano Martinez", guess: "1", id: 20 },
    { name: "David Martinez Barreto", guess: "2", id: 21 },
    { name: "Susana Martinez", guess: "2", id: 22 },
    { name: "Gustavo Gorostieta Martinez", guess: "2", id: 23 },
    { name: "Angel Gorostieta", guess: "2", id: 24 },
    { name: "Alejandra Castillo", guess: "2", id: 25 },
    { name: "Ethan García Quintana", guess: "1", id: 26 },
    { name: "Familia Camarena Velazquez", guess: "2", id: 27 },
    { name: "Pablo García Rico", guess: "2", id: 28 },
    { name: "Salvador Rodríguez", guess: "2", id: 29 },
    { name: "Vicente Terán", guess: "2", id: 30 },
    { name: "Eloy Morantes", guess: "2", id: 31 },
    { name: "Eduardo de la Roca", guess: "2", id: 32 },
    { name: "Adriana de la Roca", guess: "2", id: 33 },
  ];
  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
