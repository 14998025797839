import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import audioFondo from '../screens/eventos/Evento-plata/assets/DuelesMana.mp3'

export const Miamorcito = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);

    const datosSeccion1 = {
        nombre: "PARA MI NIÑA HERMOSA",
        fechaDeBoda: "POR SIEMPRE",
        fechaNewDate: "July 04, 2024 00:00:00 GMT+00:00",
        textoInicio: "Te amo 1 Millon",
        imgPortada:
          "https://i.postimg.cc/vH1F6Pfw/istockphoto-1396842911-612x612.png",
      };

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

    useEffect(() => {
        const audioElement = document.getElementById("backgroundMusic");
        if (isMusicPlaying) {
          audioElement.play();
        } else {
          audioElement.pause();
          audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
        }
      }, [isMusicPlaying]);
    
      useEffect(() => {
        AOS.init({ duration: 2000 });
      }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
         <div
    style={{
      backgroundImage: `url(${datosSeccion1.imgPortada})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100vh", // Ajusta la altura del contenedor
      display: "flex",
      backgroundPosition: "center center",
      justifyContent: "center",
      alignItems: "center",
    }}
    className="bg-slate-950 flex flex-col justify-center items-center w-full"
  >
    <div
      className="flex flex-col justify-center h-full w-full items-center pt-[40vh]"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <audio
        id="backgroundMusic"
        style={{ display: "none" }}
        autoPlay
        loop
        muted={!isMusicPlaying}
      >
        <source src={audioFondo} type="audio/mpeg" />
        {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
      </audio>
      <button
        onClick={handlePlayButtonClick}
        className=" px-4 text-3xl py-2 rounded"
      >
        {isMusicPlaying ? "🔊" : "🔇"}
      </button>
      <br />
      <h1
        className="font-serif sm:text-[52px] md:text-[132px] text-white text-center flex"
        data-aos="flip-up"
      >
        {datosSeccion1.nombre}
      </h1>
      <br />
      <p
        className="text-white p-3 rounded-lg font-serif text-2xl text-center"
        data-aos="fade-up"
        // style={{
        //   background: "rgb(0,0,0)",
        //   background:
        //     "linear-gradient(0deg, rgba(0,0,0,0.7596288515406162) 28%, rgba(0,0,0,0.7596288515406162) 68%)",
        // }}
      >
        - {datosSeccion1.textoInicio} - <br /> {datosSeccion1.fechaDeBoda}
      </p>
      <br />
    </div>
  </div>
    </div>
   
  )
}
