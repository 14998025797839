import { BautizoAutomaticoTrillizas } from "../screens/XV/xv-automatizado/BautizoAutomaticoTrillizas"
import { CumpleManuel } from "../screens/XV/xv-automatizado/CumpleManuel"
import { XvAutomatica } from "../screens/XV/xv-automatizado/XvAutomatica"
import { XvAutomatica4Regalos } from "../screens/XV/xv-automatizado/XvAutomatica4Regalos"
import { XvAutomaticaDiamante } from "../screens/XV/xv-automatizado/XvAutomaticaDiamante"
import { XvAutomaticaDiseño2 } from "../screens/XV/xv-automatizado/XvAutomaticaDiseño2"
import { XvAutomaticaProg } from "../screens/XV/xv-automatizado/XvAutomaticaProg"
import { XvAutomatica as XvAutomaticaEn } from "../screens/XV/xv-automatizado/ingles/XvAutomatica"
import { XvAutomaticaDiamante as XvAutomaticaDiamanteEn } from "../screens/XV/xv-automatizado/ingles/XvAutomaticaDiamante"

const RutaAutomaticaXv = [
    {
        path: '/xv-auto',
        element: <XvAutomatica />
    },
    {
        path: '/en/event-auto',
        element: <XvAutomaticaEn />
    },
    {
        path: '/xv-auto-mr',
        element: <XvAutomatica4Regalos />
    },
    {
        path: '/xv-auto-porg',
        element: <XvAutomaticaProg />
    },
    {
        path: '/xv-d2-auto',
        element: <XvAutomaticaDiseño2 />
    },
    {
        path: '/XV-auto-diamante',
        element: <XvAutomaticaDiamante />
    },
    {
        path: '/event-auto-diamond',
        element: <XvAutomaticaDiamanteEn />
    },
    {
        path: '/bautizo-ejemplo-auto',
        element: <BautizoAutomaticoTrillizas />
    },
    {
        path: '/cumple-ejemplo-auto',
        element: <CumpleManuel />
    },
]

export default RutaAutomaticaXv
