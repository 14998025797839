import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/youarestill.MP3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const BodaCeciliaYRaul = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: " nuestra boda",
    esposo: "Raul",
    esposa: "Cecilia",
    fechaDeBoda: "03 Mayo, 2024",
    fechaNewDate: "May 03, 2024 12:00:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/1zPt2QjH/DSC-2326.jpg",
    numeroNovia: "8661625487",
    numeroNovio: "8661455781",
    novios: true,
  };

  const peltaColores = {
    color1: "#f8f2f2",
    color2: "#f8f2f2",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Este es el sueño que compartimos, el que queremos cuidar a través de los años, el que hablará por nosotros, más que toda palabra, el que nos reencontrará a través de cualquier distancia, este es el sueño que compartimos, y se llama Amor.",
    fechaNewDate: "May 03, 2024 12:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/J7ghmJFt/DSC-2488.jpg",
    fotoDerecha: "https://i.postimg.cc/hjZtX7Py/DSC-2502.jpg",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Cecilia y Raul!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "María Guadalupe Rico Almanza ✟",
    esposaPadre: "José Hernández Martinez",
    esposoMadre: "María Isabel Solis Linares",
    esposoPadre: "Raúl Nájera Gallegos",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Viernes, 03 de Mayo de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Catedral Parroquia de Santiago Apóstol",
    ceremoniaLugarMap:
      "Venustiano Carranza 510, Zona Centro, 25700 Monclova, Coah.",
    ceremoniaUrl: "https://maps.app.goo.gl/axGpFW42AD8pQaCN6",
    recepcionFecha: "Viernes, 03 de Mayo de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "La Fontana",
    recepcionLugarMap: "Ermita 311, Zona Centro, 25700 Monclova, Coah.",
    recepcionUrl: "https://maps.app.goo.gl/2gaZpro8BBg5NBos9",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarnos algo me encantaría fuera efectivo . Gracias por permitirnos escoger nuestro regalo.",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    " https://i.postimg.cc/bJJ6TbR7/IMG-2997.jpgg",
    "https://i.postimg.cc/wBFfLFD5/IMG-2999.jpg",
    "https://i.postimg.cc/NFyfmfKg/DSC-2531.jpg",
    "https://i.postimg.cc/VLx59M92/DSC-2558.jpg ",
    "https://i.postimg.cc/Y0Tyj9YJ/IMG-2998.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://lh5.googleusercontent.com/p/AF1QipMNk4cWVA0Z3A_45GtxKqfbh4L48JtC8g4psF3-=w408-h269-k-no")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d56929.020666291326!2d-101.416137!3d26.9014705!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868bcd0a7f131cbd%3A0xe1cf54ab57a1fb28!2sCatedral%20Parroquia%20de%20Santiago%20Ap%C3%B3stol!5e0!3m2!1ses-419!2smx!4v1712956031138!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://lh5.googleusercontent.com/p/AF1QipPoYIZvBszZr9bb9jccmk-jGKnCq26NoOdTfpU3=w408-h306-k-no")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14231.759400389361!2d-101.4157023!3d26.905404!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868bcdf3b6f62a65%3A0x34468853b7729829!2sLa%20Fontana!5e0!3m2!1ses-419!2smx!4v1712956090047!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Vestimenta peltaColores={peltaColores} imagenesFondo={imagenesFondo} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto py-[5vh] md:h-[150vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
                <br />
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <FaChampagneGlasses className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CÓCTEL</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.coctel}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:px-3 sm:py-10 md:p-0 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://www.pngkey.com/png/full/94-944227_icono-dinero-prstamo-banknote-svg.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color2,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
             
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://www.pngkey.com/png/full/94-944227_icono-dinero-prstamo-banknote-svg.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="md:flex sm:hidden flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              
            </div>
          </div>
        </div>
      </div>

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
