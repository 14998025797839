import { BodaAlanYThania } from "../screens/bodas/Boda-oro/BodaAlanYThania";
import { BodaCeciliaYRaul } from "../screens/bodas/Boda-oro/BodaCeciliaYRaul";
import { BodaCinthyaYJonathan } from "../screens/bodas/Boda-oro/BodaCinthyaYJonathan";
import { BodaClaudiaYRoberto } from "../screens/bodas/Boda-oro/BodaClaudiaYRoberto";
import { BodaDulceYArmando } from "../screens/bodas/Boda-oro/BodaDulceYArmando";
import { BodaEjemplo } from "../screens/bodas/Boda-oro/BodaEjemplo";
import { BodaEjemploSinFoto } from "../screens/bodas/Boda-oro/BodaEjemploSinFoto";
import { BodaErizbethYVictor } from "../screens/bodas/Boda-oro/BodaErizbethYVictor";
import { BodaJazminYUriel } from "../screens/bodas/Boda-oro/BodaJazminYUriel";
import { BodaJuanYNallely } from "../screens/bodas/Boda-oro/BodaJuanYNallely";
import { BodaMadaiYFidel } from "../screens/bodas/Boda-oro/BodaMadaiYFidel";
import { BodaNeftaliYDeysi } from "../screens/bodas/Boda-oro/BodaNEftaliYDeysi";
import { BodaRolandoYValeria } from "../screens/bodas/Boda-oro/BodaRolandoYValeria";
import { BodaRubenYGeny } from "../screens/bodas/Boda-oro/BodaRubenYGeny";

const RoutesBodaOro = [
    {
      path: '/boda-oro-ejemplo',
      element: <BodaEjemplo />
    },
    {
      path: '/boda-oro-ejemplo-fotos',
      element: <BodaEjemploSinFoto />
    },
    {
      path: '/boda-oro-cecilia&raul',
      element: <BodaCeciliaYRaul />
    },
    {
      path: '/boda-oro-neftali&deysi',
      element: <BodaNeftaliYDeysi />
    },

    {
      path: '/boda-oro-valeria&rolando',
      element: <BodaRolandoYValeria />
    },

    {
      path: '/boda-oro-claudia&roberto',
      element: <BodaClaudiaYRoberto />
    },
    {
      path: '/boda-oro-jazmin&uriel',
      element: <BodaJazminYUriel />
    },
    {
      path: '/boda-oro-alan&Paola',
      element: <BodaAlanYThania />
    },
    {
      path: '/boda-oro-Ruben&Geny',
      element: <BodaRubenYGeny />
    },
    {
      path: '/boda-oro-juan&nallely',
      element: <BodaJuanYNallely />
    },
    {
      path: '/boda-oro-madai&fidel',
      element: <BodaMadaiYFidel />
    },
    {
      path: '/boda-oro-dulce&armando',
      element: <BodaDulceYArmando />
    },
    {
      path: '/boda-oro-cinthya&jonathan',
      element: <BodaCinthyaYJonathan />
    },
    {
      path: '/boda-oro-erizbeth&victor',
      element: <BodaErizbethYVictor />
    },
]

export default RoutesBodaOro;