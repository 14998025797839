import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import axios from "axios";

export const BautizoAutomaticoTrillizas = () => {
    const [seccionPortadaData, setSeccionPortadaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [seccionImagenes, setSeccionImagenes] = useState([]);
    const [estado, setEstado] = useState({});
  
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idClient = searchParams.get("id");
  
    // Función para hacer la solicitud HTTP
    const fetchData = async () => {
  
      try {
        const response = await axios.get(`/api/Eventos/${idClient}`);
        setEstado(response.data);
        console.log(response.data);
        try {
          setLoading(true);
          const response = await axios.get(
            `/api/Seccionportada/obtenerXv/${idClient}`
          );
          setSeccionPortadaData(response.data);
          console.log(response.data);
          try {
            const responseImg = await axios.get(`/api/Imagenes?id=${idClient}`);
            const mapImg = responseImg.data.map((item) => item.urlFoto);
            setSeccionImagenes(mapImg);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            alert("Error");
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
          alert("Error Intento Nuevamente");
        }
      } catch (error) {
        setLoading(false);
        alert("Error Evento Nuevamente");
      }
    };
  
    // Llamar a la función fetchData cuando el componente se monte
    useEffect(() => {
      fetchData();
    }, []);
  
    const peltaColores = {
      color1: seccionPortadaData?.colores?.color1 || "",
      color2: seccionPortadaData?.colores?.color2 || "",
    };
  
    const datosSeccionPotada = {
      cancion: seccionPortadaData?.portada?.cancion || "",
      textoConfirmacion: seccionPortadaData?.portada?.textoConfirmacion || "",
      nombre: seccionPortadaData?.portada?.nombre || "",
      fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
      imgPortada: seccionPortadaData?.portada?.imgPortada || "",
      titulo: seccionPortadaData?.portada?.titulo || "",
    };
  
    const datosSeccionContador = {
      titulo: seccionPortadaData?.contador?.titulo || "",
      descipcion: seccionPortadaData?.contador?.descripcion || "",
      fechaNewDate: seccionPortadaData?.contador?.fechaNewDate || "",
      fotoIzquierda: seccionPortadaData?.contador?.fotoIzquierda || "",
      fotoDerecha: seccionPortadaData?.contador?.fotoDerecha || "",
      marcoEnable: seccionPortadaData?.contador?.marcoEnable,
      imgFondo: seccionPortadaData?.contador?.imgFondo || "",
      textColor: seccionPortadaData?.contador?.textColor || "",
      contador: seccionPortadaData?.contador?.contador,
    };
  
    const datosInvitados = {
      titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
      entradasText: "disfrutar nuestro dia especial",
    };
  
    const datosSeccionPadres = {
      cantidad: seccionPortadaData?.padres?.cantidad || "",
      titulo: seccionPortadaData?.padres?.titulo || "",
      descipcion: seccionPortadaData?.padres?.descipcion || "",
      titulo1: seccionPortadaData?.padres?.titulo1 || "",
      madre: seccionPortadaData?.padres?.madre || "",
      padre: seccionPortadaData?.padres?.padre || "",
      titulo2: seccionPortadaData?.padres?.titulo2 || "",
      madrina: seccionPortadaData?.padres?.madrina || "",
      padrino: seccionPortadaData?.padres?.padrino || "",
      titulo3: seccionPortadaData?.padres?.titulo3 || "",
      madrina2: seccionPortadaData?.padres?.madrina2 || "",
      padrino2: seccionPortadaData?.padres?.padrino2 || "",
      imgFondo: seccionPortadaData?.padres?.imgFondo || "",
      textColor: seccionPortadaData?.padres?.textColor || "",
    };
  
    const datosSeccionCeremonia = {
      dobleEnable: seccionPortadaData?.ceremonia?.dobleEnable,
      ceremoniaEnable: seccionPortadaData?.ceremonia?.ceremoniaEnable,
      ceremoniaFecha: seccionPortadaData?.ceremonia?.ceremoniaFecha || "",
      ceremoniaHora: seccionPortadaData?.ceremonia?.ceremoniaHora || "",
      ceremoniaNombreLugar:
        seccionPortadaData?.ceremonia?.ceremoniaNombreLugar || "",
      ceremoniaLugarMap: seccionPortadaData?.ceremonia?.ceremoniaLugarMap || "",
      ceremoniaUrl: seccionPortadaData?.ceremonia?.ceremoniaUrl || "",
      recepcionEnable: seccionPortadaData?.ceremonia?.recepcionEnable,
      recepcionFecha: seccionPortadaData?.ceremonia?.recepcionFecha || "",
      recepcionHora: seccionPortadaData?.ceremonia?.recepcionHora || "",
      recepcionNombreLugar:
        seccionPortadaData?.ceremonia?.recepcionNombreLugar || "",
      recepcionLugarMap: seccionPortadaData?.ceremonia?.recepcionLugarMap || "",
      recepcionUrl: seccionPortadaData?.ceremonia?.recepcionUrl || "",
      imgFondo: seccionPortadaData?.ceremonia?.imgFondo || "",
      textColor: seccionPortadaData?.ceremonia?.textColor || "",
    };
  
    const datosSeccionPrograma = {
      ceremoniaReligiosa: seccionPortadaData?.programa?.ceremoniaReligiosa || "",
      recepcion: seccionPortadaData?.programa?.recepcion || "",
      baileXV: seccionPortadaData?.programa?.baileXV || "",
      cena: seccionPortadaData?.programa?.cena || "",
      coctel: seccionPortadaData?.programa?.coctel || "",
      baile: seccionPortadaData?.programa?.baile || "",
    };
  
    const datosSeccionVestimenta = {
      vestimenta: seccionPortadaData?.vestimenta?.vestimenta || "",
      messageVestimenta: seccionPortadaData?.vestimenta?.messageVestimenta || "",
      urlVestimentaMujer:
        seccionPortadaData?.vestimenta?.urlVestimentaMujer || "",
      urlVestimentaHombre:
        seccionPortadaData?.vestimenta?.urlVestimentaHombre || "",
      imgFondo: seccionPortadaData?.vestimenta?.imgFondo || "",
      textColor: seccionPortadaData?.vestimenta?.textColor || "",
    };
  
    const datosSeccionHospedaje = {
      disponibleDos: seccionPortadaData?.hospedaje?.disponibleDos || "",
      descripcion: seccionPortadaData?.hospedaje?.descripcion || "",
      hotel1Titulo: seccionPortadaData?.hospedaje?.hotel1Titulo || "",
      hotel1lugar: seccionPortadaData?.hospedaje?.hotel1lugar || "",
      hotel1url: seccionPortadaData?.hospedaje?.hotel1url || "",
      hotel2Titulo: seccionPortadaData?.hospedaje?.hotel2Titulo || "",
      hotel2lugar: seccionPortadaData?.hospedaje?.hotel2lugar || "",
      hotel2url: seccionPortadaData?.hospedaje?.hotel2url || "",
      imgFondo: seccionPortadaData?.hospedaje?.imgFondo || "",
      textColor: seccionPortadaData?.hospedaje?.textColor || "",
    };
  
    const datosSeccionRegalos = {
      cantidadRegalos: seccionPortadaData?.regalos?.cantidadRegalos || "",
      titulo: seccionPortadaData?.regalos?.titulo || "",
      descripcion: seccionPortadaData?.regalos?.descripcion || "",
      link1: seccionPortadaData?.regalos?.link1 || "",
      imgLink1: seccionPortadaData?.regalos?.imgLink1 || "",
      link1Enable: seccionPortadaData?.regalos?.link1Enable || "",
      link2: seccionPortadaData?.regalos?.link2 || "",
      imgLink2: seccionPortadaData?.regalos?.imgLink2 || "",
      link2Enable: seccionPortadaData?.regalos?.link2Enable || "",
      link3: seccionPortadaData?.regalos?.link3 || "",
      imgLink3: seccionPortadaData?.regalos?.imgLink3 || "",
      link3Enable: seccionPortadaData?.regalos?.link3Enable || "",
      imgFondo: seccionPortadaData?.regalos?.imgFondo || "",
      textColor: seccionPortadaData?.regalos?.textColor || "",
    };
  
    const datosSeccionConfirmacion = {
      tituloConfirmacion:
        seccionPortadaData?.confirmacion?.tituloConfirmacion || "",
      aviso: seccionPortadaData?.confirmacion?.aviso || "",
      numeroConfirmacion:
        seccionPortadaData?.confirmacion?.numeroConfirmacion || "",
      textoConfirmacion:
        seccionPortadaData?.confirmacion?.textoConfirmacion || "",
      textWhatsApp: seccionPortadaData?.confirmacion?.textWhatsApp || "",
      textColor: seccionPortadaData?.confirmacion?.textColor || "",
      imgFondo: seccionPortadaData?.confirmacion?.imgFondo || "",
      textColor: seccionPortadaData?.confirmacion?.textColor || "",
    };
  
    const datosSeccionInvitados = {
      imgFondo:
        "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
      titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
      entradasText: "disfrutar mis XV años",
      colorFondoName: "",
    };
  
    const contador = estado?.enableContador;
    const padres = estado?.enablePadres;
    const hospedaje = estado?.enableHospedaje;
    const confirmacion = estado?.enableConfirmacion;
    const Regalos = estado?.enableRegalos;
    const ceremonia = estado?.enableCeremonia;
    const programa = estado?.enablePrograma;
    const vestimenta = estado?.enableVestimenta;
  
    const images = [
      "https://cdn0.bodas.com.mx/article-vendor/3443/3_2/960/jpg/analois-photoshoot-0055_5_193443-164815363528699.jpeg",
      "https://elolivar.es/olivar-content/uploads/2023/04/oficiante-de-bodas.png",
      "https://cnnespanol.cnn.com/wp-content/uploads/2023/06/wedding-trends-restricted-e1685615285359.jpeg?quality=100&strip=info",
      "https://images.ecestaticos.com/LceL6lnXGvDCBsnDEjupizBTGuA=/0x0:2272x1515/1200x900/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2Fe52%2Fd19%2Fcfe%2Fe52d19cfe44da0f12a62bc916ac069b1.jpg",
      // Add more image URLs as needed
    ];
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        {loading ? (
          <div className="w-full h-screen flex justify-center items-center animate-spin">
            <h2>Cargando...</h2>
          </div>
        ) : (
          <>
            <PortadaXvComponent datosSeccionPortada={datosSeccionPotada} />
  
            {contador ? (
              <ContadorXvComponent
                peltaColores={peltaColores}
                datosSeccionContador={datosSeccionContador}
              />
            ) : (
              ""
            )}
  
            {padres ? (
              <div
              style={{
                backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: peltaColores.color1,
                color: datosSeccionPadres.textColor ? datosSeccionPadres.textColor : "",
              }}
              className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
            >
              {/* =========================================================================================================================================== */}
              <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
                <h1
                  className="font-serif text-center sm:text-[32px] md:text-[42px]"
                  data-aos="flip-up"
                >
                  {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
                </h1>
                <br />
                <p
                  className=" text-center pt-[5vh] font-serif text-xl"
                  data-aos="flip-up"
                >
                  {datosSeccionPadres.descipcion}
                </p>
                <div
                  className={`grid sm:grid-cols-1 md:grid-cols-${
                    datosSeccionPadres.cantidad || "1"
                  } w-[80%] pt-[10vh]`}
                >
                     <div
                      className="flex flex-col justify-center items-center"
                      data-aos="fade-right"
                    >
                      <p className="font-semibold text-center text-2xl">
                        Nuestros Padres
                      </p>
                      <br />
                      <p className="font-serif text-center text-xl">
                      Flor Nieto Tlaxcaltecatl
                      </p>
                      <p className="font-serif text-center text-xl">
                      Raybel Tlahuiz Coronel
                      </p>
                      <br />
                    </div>

                    <div
                      className="flex flex-col justify-center items-center"
                      data-aos="fade-right"
                    >
                      <p className="font-semibold text-center text-2xl">
                      Renata <br /> Madrina
                      </p>
                      <br />
                      <p className="font-serif text-center text-xl">
                        {datosSeccionPadres.madre}
                      </p>
                      {datosSeccionPadres.padre ? (
                        <p className="font-serif text-center text-xl">&</p>
                      ) : (
                        ""
                      )}
                      <p className="font-serif text-center text-xl">
                        {datosSeccionPadres.padre}
                      </p>
                    </div>
        
                  {
                    datosSeccionPadres.cantidad == 2 ||  datosSeccionPadres.cantidad == 3 ?(
                        <div
                      className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                      data-aos="fade-left"
                    >
                      <p className="font-semibold text-center text-2xl">
                      Regina <br /> Padrinos
                      </p>
                      <br />
                      <p className="font-serif text-center text-xl">
                        {datosSeccionPadres.madrina}
                      </p>
                      <p className="font-serif text-center text-xl">
                        {datosSeccionPadres.padrino}
                      </p>
                    </div>
                    ):""
                  }
        
        {
                    datosSeccionPadres.cantidad == 3 ?(
                        <div
                      className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                      data-aos="fade-left"
                    >
                      <p className="font-semibold text-center text-2xl">
                      Romina <br /> Padrinos
                      </p>
                      <br />
                      <p className="font-serif text-center text-xl">
                        {datosSeccionPadres.madrina2}
                      </p>
                      <p className="font-serif text-center text-xl">
                        {datosSeccionPadres.padrino2}
                      </p>
                    </div>
                    ):""
                  }
                  
                </div>
              </div>
            </div>
            ) : (
              ""
            )}
  
            {ceremonia ? (
              <CeremoniaXvComponent
                peltaColores={peltaColores}
                datosSeccionCeremonia={datosSeccionCeremonia}
              />
            ) : (
              ""
            )}
  
            {programa ? (
              <ProgramaXvComponent
                peltaColores={peltaColores}
                datosSeccionPrograma={datosSeccionPrograma}
              />
            ) : (
              ""
            )}
  
            {vestimenta ? (
              <VestimentaXvComponent
                peltaColores={peltaColores}
                datosSeccionVestimenta={datosSeccionVestimenta}
              />
            ) : (
              ""
            )}
  
            {hospedaje ? (
              <HospedajeXvComponent
                peltaColores={peltaColores}
                datosSeccionHospedaje={datosSeccionHospedaje}
              />
            ) : (
              ""
            )}
  
            {Regalos ? (
              <RegalosXvComponent
                peltaColores={peltaColores}
                datosSeccionRegalos={datosSeccionRegalos}
              />
            ) : (
              ""
            )}
  
            {confirmacion ? (
              <ConfirmacionXvComponent
                peltaColores={peltaColores}
                images={seccionImagenes}
                messageWhats={messageWhats}
                datosSeccionConfirmacion={datosSeccionConfirmacion}
              />
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
}
