import { ErrorMessage, Formik } from "formik";
import React from "react";
import { Form } from "react-router-dom";
import { Input } from "reactstrap";
import * as Yup from "yup";

export const CeremoniaForm = ({ initialValues }) => {
  const enviarDatosCeremonia = async (values) => {
    try {
      // Realizar la solicitud POST con los datos actualizados
      console.log(values);
      //const response = await axios.post(`/api/actualizarXv?id=${idClient}`, values);
      // console.log('Respuesta del servidor:', response.data);
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    ceremoniaId: Yup.number().required("Campo requerido"),
    ceremoniaEnable: Yup.boolean().required("Campo requerido"),
    ceremoniaFecha: Yup.string(),
    ceremoniaHora: Yup.string(),
    ceremoniaLugarMap: Yup.string(),
    ceremoniaNombreLugar: Yup.string(),
    ceremoniaUrl: Yup.string(),
    dobleEnable: Yup.boolean().required("Campo requerido"),
    recepcionEnable: Yup.boolean().required("Campo requerido"),
    recepcionFecha: Yup.string(),
    recepcionHora: Yup.string(),
    recepcionLugarMap: Yup.string(),
    recepcionNombreLugar: Yup.string(),
    recepcionUrl: Yup.string(),
    textColor: Yup.string(),
    imgFondo: Yup.string(),
  });

  const defoult = {
    ceremoniaId: "",
    ceremoniaEnable: "",
    ceremoniaFecha: "",
    ceremoniaHora: "",
    ceremoniaLugarMap: "",
    ceremoniaNombreLugar: "",
    ceremoniaUrl: "",
    dobleEnable: "",
    recepcionEnable: "",
    recepcionFecha: "",
    recepcionHora: "",
    recepcionLugarMap: "",
    recepcionNombreLugar: "",
    recepcionUrl: "",
    textColor: "",
    imgFondo: "",
  };
  return (
    <Formik
      initialValues={initialValues?.ceremonia || defoult}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        enviarDatosCeremonia(values);
      }}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <Form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
          <div className="mb-4 p-2">
            <label htmlFor="ceremoniaId" className="block font-medium mb-1">
              ID de Ceremonia:
            </label>
            <Input
              type="text"
              id="ceremoniaId"
              name="ceremoniaId"
              value={values?.ceremoniaId}
              onChange={handleChange}
              invalid={errors.ceremoniaId && touched.ceremoniaId}
            />
            <ErrorMessage
              name="ceremoniaId"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="ceremoniaEnable" className="block font-medium mb-1">
              Ceremonia Habilitada:
            </label>
            <Input
              type="checkbox"
              id="ceremoniaEnable"
              name="ceremoniaEnable"
              checked={values?.ceremoniaEnable}
              onChange={handleChange}
              invalid={errors.ceremoniaEnable && touched.ceremoniaEnable}
            />
            <ErrorMessage
              name="ceremoniaEnable"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="ceremoniaFecha" className="block font-medium mb-1">
              Fecha de Ceremonia:
            </label>
            <Input
              type="text"
              id="ceremoniaFecha"
              name="ceremoniaFecha"
              value={values?.ceremoniaFecha}
              onChange={handleChange}
              invalid={errors.ceremoniaFecha && touched.ceremoniaFecha}
            />
            <ErrorMessage
              name="ceremoniaFecha"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="ceremoniaHora" className="block font-medium mb-1">
              Hora de Ceremonia:
            </label>
            <Input
              type="text"
              id="ceremoniaHora"
              name="ceremoniaHora"
              value={values?.ceremoniaHora}
              onChange={handleChange}
              invalid={errors.ceremoniaHora && touched.ceremoniaHora}
            />
            <ErrorMessage
              name="ceremoniaHora"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label
              htmlFor="ceremoniaLugarMap"
              className="block font-medium mb-1"
            >
              Lugar de Ceremonia (Mapa):
            </label>
            <Input
              type="text"
              id="ceremoniaLugarMap"
              name="ceremoniaLugarMap"
              value={values?.ceremoniaLugarMap}
              onChange={handleChange}
              invalid={errors.ceremoniaLugarMap && touched.ceremoniaLugarMap}
            />
            <ErrorMessage
              name="ceremoniaLugarMap"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label
              htmlFor="ceremoniaNombreLugar"
              className="block font-medium mb-1"
            >
              Nombre del Lugar de Ceremonia:
            </label>
            <Input
              type="text"
              id="ceremoniaNombreLugar"
              name="ceremoniaNombreLugar"
              value={values?.ceremoniaNombreLugar}
              onChange={handleChange}
              invalid={
                errors.ceremoniaNombreLugar && touched.ceremoniaNombreLugar
              }
            />
            <ErrorMessage
              name="ceremoniaNombreLugar"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="ceremoniaUrl" className="block font-medium mb-1">
              URL de Ceremonia:
            </label>
            <Input
              type="text"
              id="ceremoniaUrl"
              name="ceremoniaUrl"
              value={values?.ceremoniaUrl}
              onChange={handleChange}
              invalid={errors.ceremoniaUrl && touched.ceremoniaUrl}
            />
            <ErrorMessage
              name="ceremoniaUrl"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="dobleEnable" className="block font-medium mb-1">
              Doble Habilitado:
            </label>
            <Input
              type="checkbox"
              id="dobleEnable"
              name="dobleEnable"
              checked={values?.dobleEnable}
              onChange={handleChange}
              invalid={errors.dobleEnable && touched.dobleEnable}
            />
            <ErrorMessage
              name="dobleEnable"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="recepcionEnable" className="block font-medium mb-1">
              Recepción Habilitada:
            </label>
            <Input
              type="checkbox"
              id="recepcionEnable"
              name="recepcionEnable"
              checked={values?.recepcionEnable}
              onChange={handleChange}
              invalid={errors.recepcionEnable && touched.recepcionEnable}
            />
            <ErrorMessage
              name="recepcionEnable"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="recepcionFecha" className="block font-medium mb-1">
              Fecha de Recepción:
            </label>
            <Input
              type="text"
              id="recepcionFecha"
              name="recepcionFecha"
              value={values?.recepcionFecha}
              onChange={handleChange}
              invalid={errors.recepcionFecha && touched.recepcionFecha}
            />
            <ErrorMessage
              name="recepcionFecha"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="recepcionHora" className="block font-medium mb-1">
              Hora de Recepción:
            </label>
            <Input
              type="text"
              id="recepcionHora"
              name="recepcionHora"
              value={values?.recepcionHora}
              onChange={handleChange}
              invalid={errors.recepcionHora && touched.recepcionHora}
            />
            <ErrorMessage
              name="recepcionHora"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label
              htmlFor="recepcionLugarMap"
              className="block font-medium mb-1"
            >
              Lugar de Recepción (Mapa):
            </label>
            <Input
              type="text"
              id="recepcionLugarMap"
              name="recepcionLugarMap"
              value={values?.recepcionLugarMap}
              onChange={handleChange}
              invalid={errors.recepcionLugarMap && touched.recepcionLugarMap}
            />
            <ErrorMessage
              name="recepcionLugarMap"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label
              htmlFor="recepcionNombreLugar"
              className="block font-medium mb-1"
            >
              Nombre del Lugar de Recepción:
            </label>
            <Input
              type="text"
              id="recepcionNombreLugar"
              name="recepcionNombreLugar"
              value={values?.recepcionNombreLugar}
              onChange={handleChange}
              invalid={
                errors.recepcionNombreLugar && touched.recepcionNombreLugar
              }
            />
            <ErrorMessage
              name="recepcionNombreLugar"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="recepcionUrl" className="block font-medium mb-1">
              URL de Recepción:
            </label>
            <Input
              type="text"
              id="recepcionUrl"
              name="recepcionUrl"
              value={values?.recepcionUrl}
              onChange={handleChange}
              invalid={errors.recepcionUrl && touched.recepcionUrl}
            />
            <ErrorMessage
              name="recepcionUrl"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="textColor" className="block font-medium mb-1">
              Color de Texto:
            </label>
            <Input
              type="text"
              id="textColor"
              name="textColor"
              value={values?.textColor}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 p-2">
            <label htmlFor="textColor" className="block font-medium mb-1">
              Imagen de fondo:
            </label>
            <Input
              type="text"
              id="imgFondo"
              name="imgFondo"
              value={values?.imgFondo}
              onChange={handleChange}
            />
          </div>
          <div className="col-span-2 flex justify-end p-2">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded "
            >
              Enviar Información
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
