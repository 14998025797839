import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { FaChampagneGlasses } from "react-icons/fa6";
import { PiChurchLight } from "react-icons/pi";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";

export const XvMelissaMartinez = () => {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ebb9f9",
    color2: "#ee91eb",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEosLIHWAJR_y2mate.com---Ma-Belle-Evangeline.mp3",
    imgPortada: "https://i.postimg.cc/tgwQ7Xc6/IMG-7291.jpg",
    nombre: "Melissa Roque",
    fechaDeBoda: "21 SEPTIEMBRE, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso:
      "¡NO NIÑOS!, Amamos a tus hijos, pero creemos que merecen disfrutar una noche libre.",
    numeroConfirmacion: "+525536604548",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí Y a mi madre, hermana, abuelos y tíos, por darme el apoyo para realizar el sueño de toda quinceañera. \\nHoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial conmigo.",
    fechaNewDate: "September 21, 2024 17:30:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/XJdhJ9dM/IMG-7286.jpg",
    fotoDerecha: "https://i.postimg.cc/y6F4YCyJ/IMG-7292.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mi mamá y mis padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Mamá de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Alina Roque Alvarado",
    padre: "",
    madrina: "Diana Roque Alvarado",
    padrino: "Marco Bernaga Guadarrama",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 23 de Diciembre de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia del Divino Salvador",
    ceremoniaLugarMap:
      "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
    ceremoniaUrl:
      "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 21 de Septiembre de 2024.",
    recepcionHora: "5:30 p.m.",
    recepcionNombreLugar: "Finca Don Gaspar",
    recepcionLugarMap: "Calle Allende 4, Capula, 54600 Tepotzotlán, Méx.",
    recepcionUrl: "https://maps.app.goo.gl/u2EkepHkPP6djq2T9",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "FORMAL",
    messageVestimenta:
      "El color lila y rosa palo está reservado para la quinceañera",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "One Cuautitlán",
    hotel1lugar:
      "Paseo de Las Hdas. s.n. lote 4, Norte, Jardines de la Hacienda, 54720 Cuautitlán Izcalli, Méx.",
    hotel1url: "https://maps.app.goo.gl/dD1GgE9JaPGknjhq9",
    hotel2Titulo: "Gran Hotel Real",
    hotel2lugar:
      "Av. Benito Juárez No. 35, Barrio Texcacoa, 54605, Tepotzotlán, Estado de México, México",
    hotel2url: "https://maps.app.goo.gl/qVQGFGET9Ztb4PDZ7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 2,
    titulo: "Mesa de regalos",
    descripcion:
      "Tu presencia es mi mayor regalo, pero si deseas tener un detalle conmigo lo agradecería mucho.",
    link1: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51433316",
    imgLink1: "https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png",
    link1Enable: true,
    link2: "",
    imgLink2:
      "https://i.postimg.cc/PrmQts6k/LLUVIA-DE-REGALOS3-removebg-preview.png",
    link2Enable: false,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    fondoInvitados: "https://i.postimg.cc/QdYvn8hz/IMG-7289.jpg",
    titulo: `¡Has sido invitado a los XV de ${datosSeccionPortada.nombre}!`,
    entradasText: "disfrutar mis XV años",
    colorFondoName: "#ff80e382",
  };

  const imagenesFondo = {
    fondoInvitados: 'url("https://i.postimg.cc/QdYvn8hz/IMG-7289.jpg")',
  };

  const images = [
    "https://i.postimg.cc/tgwQ7Xc6/IMG-7291.jpg",
    "https://i.postimg.cc/XJdhJ9dM/IMG-7286.jpg",
    "https://i.postimg.cc/y6F4YCyJ/IMG-7292.jpg",
    "https://i.postimg.cc/Jh9vMpyc/IMG-7288.jpg",
    "https://i.postimg.cc/Hkkf2cD5/IMG-7290.jpg",
    "https://i.postimg.cc/4N5DVvMs/IMG-7293.jpg",
    "https://i.postimg.cc/QdYvn8hz/IMG-7289.jpg",
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  const names = [
    { name: "Familia Roque Alvarado", guess: "2", id: 1 },
    { name: "Familia Bernaga Roque", guess: "2", id: 2 },
    { name: "Alina Roque Alvarado", guess: "2", id: 3 },
    { name: "Numa Vale Hernández", guess: "1", id: 4 },
    { name: "Antonio Roque Alvarado", guess: "1", id: 5 },
    { name: "Blanca Alvarado Villalobos", guess: "1", id: 6 },
    { name: "Lorena Camargo Soto", guess: "4", id: 7 },
    { name: "Michelle Roque Alvarado", guess: "1", id: 8 },
    { name: "Eduardo Delgadillo Rodríguez", guess: "1", id: 9 },
    { name: "Familia Vergara Orozco", guess: "2", id: 10 },
    { name: "Ana Karen Martínez", guess: "2", id: 11 },
    { name: "Elizabeth Jarquín", guess: "1", id: 12 },
    { name: "Jorge Arxes Veloz", guess: "1", id: 13 },
    { name: "Julio César Camacho", guess: "1", id: 14 },
    { name: "Familia Roque Aguilar", guess: "4", id: 15 },
    { name: "Christian Reyes & Miguel Hernández", guess: "2", id: 16 },
    { name: "Elvira Saucedo", guess: "1", id: 17 },
    { name: "Araceli Ortiz Saucedo", guess: "2", id: 18 },
    { name: "Familia Ortiz Cano", guess: "3", id: 19 },
    { name: "Sebastian Soto Roque", guess: "2", id: 20 },
    { name: "Armando Ortiz Saucedo", guess: "2", id: 21 },
    { name: "Familia Hernández Arzate", guess: "5", id: 22 },
    { name: "Familia Martínez Jaramillo", guess: "2", id: 23 },
    { name: "Azael Aguilar Roque", guess: "2", id: 24 },
    { name: "Erika Hernández", guess: "2", id: 25 },
    { name: "Adriana Cruz", guess: "2", id: 26 },
    { name: "Ramses Gama", guess: "2", id: 27 },
    { name: "Desire Castro", guess: "2", id: 28 },
    { name: "Nayeli Mata", guess: "2", id: 29 },
    { name: "Familia Ramírez Ruiz", guess: "4", id: 30 },
    { name: "Nahum Solano & Familia", guess: "4", id: 31 },
    { name: "Familia Aguilar Robledo", guess: "3", id: 32 },
    { name: "Alfredo Solis", guess: "1", id: 33 },
    { name: "Valeria López", guess: "1", id: 34 },
    { name: "Valentina García", guess: "1", id: 35 },
    { name: "Sofia Oliva", guess: "1", id: 36 },
    { name: "Fabricio Jara", guess: "1", id: 37 },
    { name: "Santiago Ramírez", guess: "1", id: 38 },
    { name: "Elisa Souza", guess: "1", id: 39 },
    { name: "Alejandro Carvajal", guess: "1", id: 40 },
    { name: "Leonardo Mora", guess: "1", id: 41 },
    { name: "Daniel Bravo", guess: "1", id: 42 },
    { name: "Luciano Solis", guess: "1", id: 43 },
    { name: "Daniela Castellanos", guess: "1", id: 44 },
    { name: "Rouge Reyes", guess: "1", id: 45 },
    { name: "Santiago Palomino", guess: "1", id: 46 },
    { name: "Isaac Adair", guess: "1", id: 47 },
    { name: "Renato Samudio", guess: "1", id: 48 },
    { name: "Matias Anaya", guess: "1", id: 49 },
    { name: "Liz Tabares", guess: "1", id: 50 },
    { name: "Tadeo Arteaga", guess: "1", id: 51 },
    { name: "Antonio Soto", guess: "2", id: 52 },
    { name: "Jan Vargas Trejo", guess: "2", id: 53 },
    { name: "Regina Montalvo", guess: "1", id: 54 },
    { name: "Romina Jiménez", guess: "1", id: 55 },
    { name: "Luna Masa", guess: "1", id: 56 },
    { name: "Carlos Vargas", guess: "1", id: 57 },
    { name: "Sebastián Martínez", guess: "1", id: 58 },
    { name: "Jóse Luis Acevedo", guess: "1", id: 59 },
    { name: "Diego Cuevas", guess: "1", id: 60 },
    { name: "Fabián González", guess: "1", id: 61 },
    { name: "Carlos Parra", guess: "1", id: 62 },
    { name: "Luis Hernández", guess: "1", id: 63 },
    { name: "Alejandro Ibarra", guess: "1", id: 64 },
    { name: "Familia Villegas Gómez", guess: "3", id: 65 },
    { name: "Francisco & Ma. Isabel Roque", guess: "2", id: 66 },
    { name: "Familia Alvarado Zuno", guess: "3", id: 67 },
    { name: "Elvira Ruiz Roque", guess: "3", id: 68 },
    { name: "Familia Enriquez Mandujano", guess: "2", id: 69 },
    { name: "Familia Aguilar Cortés", guess: "2", id: 70 },
    { name: "Rosalía Villalobos & Francisco Alvarado", guess: "2", id: 71 },
    { name: "Daniel Alejandro Luna Parrilla", guess: "1", id: 72 },
    { name: "David de la Torre", guess: "1", id: 73 },
    { name: "Familia Jarquín Martínez", guess: "2", id: 74 },
    { name: "Roberto Zamora", guess: "1", id: 75 },
    { name: "Familia Mendoza Alvarado", guess: "4", id: 76 },
    { name: "Tómas Roque Claudio", guess: "2", id: 77 },
    { name: "Víctor Manuel Loera", guess: "1", id: 78 },
    { name: "Lisieux Cerventes", guess: "2", id: 79 },
    { name: "Daniel Romero", guess: "1", id: 80 },
    { name: "Patricio Espinosa", guess: "2", id: 81 },
    { name: "Fernando Alvarado", guess: "2", id: 82 },
    { name: "Kevin Persa", guess: "1", id: 83 },
    { name: "Lic. Lisieux Cervantes", guess: "2", id: 84 },
    { name: "Daniel Romero", guess: "1", id: 85 },
    { name: "Patricio Espinosa", guess: "2", id: 86 },
    { name: "Fernando Alvarado", guess: "1", id: 87 },
    { name: "Kevin Persa", guess: "2", id: 88 },
    { name: "Víctor Loera", guess: "1", id: 89 },
    { name: "Uriel Alberto Enríquez", guess: "1", id: 90 },
    { name: "Miguel Ángel Nava Verdiguél", guess: "5", id: 91 },
    { name: "Roberto Zamora García", guess: "3", id: 92 },
    { name: "Sr. Gabriel Zamora & Familia", guess: "4", id: 93 },
    { name: "Sra. Emma Reyes Sánchez", guess: "2", id: 94 },
    { name: "Sr. Manuel Carvajal Herce", guess: "2", id: 95 },
  ];

  useEffect(() => {
    const findName = names.find((e) => e.id == nameId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {/* <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      /> */}

      <Invitados
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        imagenesFondo={imagenesFondo}
        datosInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionCeremonia.textColor || "",
          }}
          className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionCeremonia.dobleEnable ? "1" : "2"
              } w-[80%] pt-[10vh]`}
            >
              {datosSeccionCeremonia.ceremoniaEnable ? (
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <PiChurchLight className="text-[80px]" />
                  <br />
                  <p className="font-serif text-2xl">Ceremonia</p>
                  <br />
                  <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionCeremonia.ceremoniaFecha}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaHora}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaNombreLugar}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.ceremoniaLugarMap}
                  </p>
                  <br />
                  <a
                    href={datosSeccionCeremonia.ceremoniaUrl}
                    class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Ver mapa!
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}

              {datosSeccionCeremonia.dobleEnable ? (
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <div className="flex">
                    <PiChurchLight className="text-[80px]" />
                    <FaChampagneGlasses className="text-[80px]" />
                  </div>
                  <br />
                  <p className="font-serif text-center text-2xl">
                    Ceremonia y Recepción
                  </p>
                  <br />
                  <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionCeremonia.ceremoniaFecha}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaHora}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaNombreLugar}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.ceremoniaLugarMap}
                  </p>
                  <br />
                  <a
                    href={datosSeccionCeremonia.ceremoniaUrl}
                    class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Ver mapa!
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}

              {datosSeccionCeremonia.recepcionEnable ? (
                <div
                  className="flex flex-col justify-center items-center sm:pt-[5vh] md:pt-0"
                  data-aos="fade-right"
                >
                  <FaChampagneGlasses className="text-[80px]" />
                  <br />
                  <p className="font-serif text-2xl">Recepción</p>
                  <br />
                  <hr
                    className="bg-[#C29436] h-2 w-[80%]"
                    style={{ opacity: "100%" }}
                  />
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.recepcionFecha}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.recepcionHora}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center ">
                    {datosSeccionCeremonia.recepcionNombreLugar}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.recepcionLugarMap}
                  </p>
                  <br />
                  <a
                    href={datosSeccionCeremonia.recepcionUrl}
                    class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
                  >
                    Ver mapa!
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPrograma.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,

            color: datosSeccionPrograma.textColor
              ? datosSeccionPrograma.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionPrograma.recepcion ? (
                  <>
                    <FaPhotoVideo className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.recepcion}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.coctel ? (
                  <>
                    <FaChampagneGlasses className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">CÓCTEL</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.coctel}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.cena ? (
                  <>
                    <ImSpoonKnife className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">CENA</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.cena}
                    </p>
                    <br />
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>

              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionPrograma.baileXV ? (
                  <>
                    <GiPartyPopper className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      BAILE DE XV AÑOS
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.baileXV}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.baile ? (
                  <>
                    <GiMeshBall className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">BAILE</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.baile}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionHospedaje.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionHospedaje.textColor
              ? datosSeccionHospedaje.textColor
              : "",
          }}
          className="sm:h-auto py-[5vh] md:h-[100vh] flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Hospedaje
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              {datosSeccionHospedaje.descripcion
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>

            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionHospedaje.disponibleDos ? 2 : 1
              } w-[80%] pt-[8vh]`}
            >
              <div
                className="flex flex-col justify-center w-[100%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-auto bg-[#ebb9f9]"
                data-aos="fade-right"
              >
                <h1 className="font-serif text-center sm:text-lg md:text-[23px]">
                  {datosSeccionHospedaje.hotel1Titulo}
                </h1>
                <br />
                <p className="font-serif text-center sm:text-lg md:text-[23px]  w-[80%]">
                  {datosSeccionHospedaje.hotel1lugar}
                </p>
                <br />
                <br />
                <a
                  href={datosSeccionHospedaje.hotel1url}
                  class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Ver mapa!
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>

              {datosSeccionHospedaje.disponibleDos ? (
                <div
                  className="flex flex-col justify-center w-[100%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-auto sm:mt-4 md:mt-0 bg-[#ebb9f9]"
                  data-aos="fade-right"
                >
                  <h1 className="font-serif text-center sm:text-lg md:text-[23px]">
                    {datosSeccionHospedaje.hotel2Titulo}
                  </h1>
                  <br />
                  <p className="font-serif text-center sm:text-lg md:text-[23px] w-[80%]">
                    {datosSeccionHospedaje.hotel2lugar}
                  </p>
                  <br />
                  <br />
                  <a
                    href={datosSeccionHospedaje.hotel2url}
                    class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Ver mapa!
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
