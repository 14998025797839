import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvZua = () => {
  const [isOpen, setIsOpen] = useState(true);

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_05/NElfsZmMriR_0510.MP3",
    imgPortada: "https://i.postimg.cc/mDQq0JLQ/1000059094.jpg",
    nombre:
      "Porque de alguna manera has formado parte de mi vida, quiero invitarte a que disfrutes conmigo éste dia tan especial para mi...",
    fechaDeBoda: "31 Agosto, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion: "Por favor, confirma tu asistencia.",
    aviso: "Zúa",
    numeroConfirmacion: "+522481507826",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "",
    fechaNewDate: "August 30, 2024 18:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/yxKqxBQQ/997728c7-7530-426f-b32c-36cf2d069ebd.jpg",
    fotoDerecha:
      "https://i.postimg.cc/0QgFXPsk/bc990881-3322-4d1c-9ca3-d20860de5c03.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "",
    descipcion: "¡Acompañame a celebrar con la bendición de Dios y de mis padres!",
    tituloIzquierda: "",
    tituloDerecha: "",
    madre: "Marce",
    padre: "Mere",
    madrina: "",
    padrino: "",
    imgFondo: "https://i.postimg.cc/BQxs8nY4/klipartz-com.png",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Viernes, 31 de Agosto de 2024.",
    ceremoniaHora: "6:30 p.m.",
    ceremoniaNombreLugar: "Parroquia del Divino Salvador",
    ceremoniaLugarMap: "Lardizabal 505, col. La Purísima",
    ceremoniaUrl: "https://maps.app.goo.gl/cyEQYcz7tDFk3x9DA",
    recepcionEnable: true,
    recepcionFecha: "Sabado, 31 de Agosto de 2024.",
    recepcionHora: "6:30 p.m.",
    recepcionNombreLugar: "Salón Palma Real",
    recepcionLugarMap: "Lardizabal 505, col. La Purísima",
    recepcionUrl: "https://maps.app.goo.gl/7k5FMTQ4RZdJEVSL6",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Mi mejor regalo 🎁 es tu presencia, pero si deseas obsequiarme algo, y no sabes qué... ésta es una buena opción.",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3: " ",
    link3Enable: false,
    imgFondo: "https://i.postimg.cc/BQxs8nY4/klipartz-com.png",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/mDQq0JLQ/1000059094.jpg",
    "https://i.postimg.cc/yxKqxBQQ/997728c7-7530-426f-b32c-36cf2d069ebd.jpg",
    "https://i.postimg.cc/0QgFXPsk/bc990881-3322-4d1c-9ca3-d20860de5c03.png",
    "https://i.postimg.cc/7YmvHFg9/1000059093.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = false;

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <div
        style={{
          backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color: datosSeccionPadres.textColor ? datosSeccionPadres.textColor : "",
        }}
        className="sm:h-auto md:h-[100vh] p-2 flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccionPadres.descipcion}
          </p>
          <div className={`grid sm:grid-cols-1 md:grid-cols-${datosSeccionPadres.tituloDerecha ? '2':'1'} w-[80%] pt-[10vh]`}>
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-serif text-center text-xl">
                {datosSeccionPadres.madre}
              </p>
              {datosSeccionPadres.padre ? (
                <p className="font-serif text-center text-xl">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl">
                {datosSeccionPadres.padre}
              </p>
            </div>
            {datosSeccionPadres.tituloDerecha ? (
              <div
                className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                data-aos="fade-left"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres.tituloDerecha}
                </p>
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.madrina}
                </p>
                {datosSeccionPadres.padrino ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.padrino}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
