import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import audioFondo from './assets/DuelesMana.mp3'

export const FuneralDany = () => {
  const datosSeccion1 = {
    nombre: "DANY",
    fechaDeBoda: "28 FEBRERO, 2024",
    fechaNewDate: "July 04, 2024 00:00:00 GMT+00:00",
    textoInicio: "ANIVERSARIO LUCTUOSO",
    imgPortada:
      "https://i.postimg.cc/vH1F6Pfw/istockphoto-1396842911-612x612.png",
  };

  const datosSeccion2 = {
    descipcion:
      "Te invito a acompañarme en un momento de recuerdo y honor en el aniversario luctuoso de nuestro ser querido. Tu presencia es un gran consuelo en este día significativo. ",
    fechaNewDate: "July 04, 2024 00:00:00 GMT+00:00",
    titulo: "Te invito",
    fotoIzquierda:
      "https://i.postimg.cc/qM5HZcJx/76f02cd7-79bd-4095-b7bf-c34e0c4f1a3c.jpg",
  };

  const datosSeccion7 = {
    descripcion:
      "Te invito a unirte a mí en un momento de reflexión y conmemoración en honor al aniversario luctuoso de nuestro ser querido.",
    hotel1Titulo: "Capilla de Guadalupe Casa de Acción Católica",
    hotel1lugar:
      "Francisco I. Madero 405, Centro Historico, 78000 San Luis Potosí, S.L.P.",
    hotel1url:
      "https://maps.app.goo.gl/e8sb2eG1xgQ6Tx3MA",
  };

  const plantillaColores = {
    color1: "#fefefe",
    color2: "#fef3ff",
    color3: "#",
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, soy ${name} y confirmo mi asistencia a la boda con ${guests} invitado(s). ¡Nos vemos allí!`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=4491516931&text=${encodeURIComponent(
      message
    )}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/qM5HZcJx/76f02cd7-79bd-4095-b7bf-c34e0c4f1a3c.jpg",
    "https://i.postimg.cc/RCGBw528/9ac16e07-1a3b-4ac4-9cfd-925f6a38df6f.jpg",
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <div
        style={{
          backgroundImage: `url(${datosSeccion1.imgPortada})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center pt-[40vh]"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={audioFondo} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
          <button
            onClick={handlePlayButtonClick}
            className=" px-4 text-3xl py-2 rounded"
          >
            {isMusicPlaying ? "🔊" : "🔇"}
          </button>
          <br />
          <h1
            className="font-serif sm:text-[52px] md:text-[132px] text-white text-center flex"
            data-aos="flip-up"
          >
            {datosSeccion1.nombre}
          </h1>
          <br />
          <p
            className="text-white p-3 rounded-lg font-serif text-2xl text-center"
            data-aos="fade-up"
            // style={{
            //   background: "rgb(0,0,0)",
            //   background:
            //     "linear-gradient(0deg, rgba(0,0,0,0.7596288515406162) 28%, rgba(0,0,0,0.7596288515406162) 68%)",
            // }}
          >
            - {datosSeccion1.textoInicio} - <br /> {datosSeccion1.fechaDeBoda} <br /> 18:30 pm
          </p>
          <br />
        </div>
      </div>
      <div
        style={{
          backgroundImage:
            'url("https://i.postimg.cc/HktM0L45/Dise-o-sin-t-tulo-1.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: plantillaColores.color1,
        }}
        className="sm:h-[180vh] md:h-[120vh] flex flex-col items-center w-full p-2"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col pt-[10vh] w-full items-center">
          <h1
            className="font-serif sm:text-[52px] md:text-[92px] "
            data-aos="flip-up"
          >
            {datosSeccion2.titulo}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion2.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 items-center md:grid-cols-1 md:w-[30%] sm:w-[70%] pt-[8vh]">
            <div
              style={{
                backgroundImage: `url(${datosSeccion2.fotoIzquierda})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "45vh", // Ajusta la altura del contenedor
                display: "flex",
                backgroundPosition: "center center",
                width: "90%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded-[30%]"
              data-aos="fade-right"
            ></div>
            {/* <div
              className="flex p-3 rounded-lg justify-center items-center mt-10"
              data-aos="fade-up"
              style={{
                border: "10px solid transparent",
                padding: "15px",
                borderImage:
                  "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')",
                borderImageSlice: "46",
                borderImageWidth: "round",
              }}
            >
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.days}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Dias</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.hours}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.minutes}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.seconds}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/flagged/photo-1553475873-55d8c03e9f5f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTJ8fHxlbnwwfHx8fHw%3D")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: plantillaColores.color2,
        }}
        className="sm:h-[130vh] md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center text-white sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Lugar del Evento
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] text-white font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccion7.descripcion}
          </p>

          <div className="flex justify-center items-center w-[80%] pt-[8vh]">
            <div
              className="flex flex-col justify-center sm:w-[100%] md:w-[60%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl py-2"
              data-aos="fade-right"
            >
              <h1 className="font-serif font-semibold text-center sm:text-xl w-[85%] md:text-[23px]">
                {datosSeccion7.hotel1Titulo} <br /> 18:30 pm
              </h1>
              <br />
              <p className="font-serif text-center sm:text-xl md:text-[23px]  w-[80%]">
                {datosSeccion7.hotel1lugar}
              </p>
              <br />
              <br />
              <a
                href={datosSeccion7.hotel1url}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
            backgroundImage:
            'url("https://i.postimg.cc/HktM0L45/Dise-o-sin-t-tulo-1.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 90%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: plantillaColores.color1,
        }}
        className="sm:h-[150vh] md:h-[150vh] flex flex-col justify-center items-center w-full"
      >
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          ¡Te extraño!
        </h1>
        <br />
        <br />
        <br />
        <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
          {images?.map((imageUrl, index) => (
            <div data-aos="fade-down-right" className="rolImg" key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-auto rounded-xl max-h-[50vh]"
              />
            </div>
          ))}
        </Slider>
        <br />
        <br />
       
      </div>
    </div>
  );
};
