import audioFondo from "../../bodas/Boda-diamante/assets/maroon.mp3";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { FaChampagneGlasses } from "react-icons/fa6";
import { ImSpoonKnife } from "react-icons/im";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { PiChurchLight } from "react-icons/pi";
import { FaPhotoVideo } from "react-icons/fa";

export const XvLailaRamos = () => {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ffff",
    color2: "#f7f7dc",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEmx7Gdgs6R_y2mate.com---Coldplay-Yellow-Sub-Espa%C3%B1ol.mp3",
    imgPortada:
      "https://i.postimg.cc/Wb0NXQTm/926c1bd0-c113-48f1-ac20-052650bd3990.jpg",
    nombre: "Laila",
    fechaDeBoda: "22 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+524423502793",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí. \\n \\n Para compartir mis sueños y hacer una linda fiesta, quiero contar contigo para que mi noche sea perfecta.",
    fechaNewDate: "June 22, 2024 17:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/2SMrvxpK/45d165d4-042d-4383-9aaf-f3f3d352a541.jpg",
    fotoDerecha:
      "https://i.postimg.cc/66fWSRR8/12801d27-678f-4b7d-a970-1f0f20c97bfb.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡LA ESPERA TERMINO!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Mis Padres",
    tituloDerecha: "Mi Padrino",
    madre: "Veronica Aboytes luna",
    padre: "Pedro Ramos Hernández",
    madrina: "Jared Ruben luna Pérez ",
    padrino: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 22 de Junio de 2024.",
    ceremoniaHora: "4:00 p.m.",
    ceremoniaNombreLugar: "Santuario santa María del pueblito ",
    ceremoniaLugarMap:
      "Apdo. Postal, Capitán Pedro Urtiaga 27, El Pueblito, 76900 El Pueblito, Qro.",
    ceremoniaUrl: "https://maps.app.goo.gl/iTv3yzM1Noh2wr9u9",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 22 de Junio de 2024.",
    recepcionHora: "5:00 p.m.",
    recepcionNombreLugar: "Salon la luna",
    recepcionLugarMap: "76910 Corregidora, Qro.",
    recepcionUrl: "https://maps.app.goo.gl/Hrf8pDniMZDfDXtB7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "Favor de no llevar vestimenta blanca ni dorada.",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de un delicioso banquete",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "¡LLUVIA DE SOBRES!",
    descripcion:
      "Tu presencia y buenos deseos son lo mas importante para mí, pero si deseas dejarme elegir mi regalo habra lluvia de sobres. \\n \\n ¡Lluvia de sobres! \\n \\n La lluvia de sobres, es la tradición de regalar efectivo a la quinceañera en un sobre el día del evento.",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1:
      "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://i.postimg.cc/zv1yxTqB/50-People-Probably-Having-A-Worse-Day-Than-You-On-New-Year-s-i-2024.jpg",
    titulo: `¡Has sido invitado a los XV de ${datosSeccionPortada.nombre}!`,
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const images = [
    "https://i.postimg.cc/WzT2zbRp/d773b2bc-ebb6-44ae-a170-52a8711b14ea.jpg",
    "https://i.postimg.cc/fRJMJrPd/cc3107b2-6e24-443c-94ec-018300938cf3.jpg",
    "https://i.postimg.cc/2SMrvxpK/45d165d4-042d-4383-9aaf-f3f3d352a541.jpg",
    "https://i.postimg.cc/66fWSRR8/12801d27-678f-4b7d-a970-1f0f20c97bfb.jpg",
    "https://i.postimg.cc/pX02VHsx/154b16ec-df62-45f8-9e99-a2d0b6228767.jpg",
    "https://i.postimg.cc/m2ZLGDN8/7a965055-aa00-4262-b28f-b1df77a19155.jpg",
    "https://i.postimg.cc/FzphL5my/f84d7be8-3c3b-405b-a008-16848cb5868d.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  const names = [
    { name: "Familia Aboytes Alcantar", guess: "9", id: 1 },
    { name: "Diana y papás", guess: "3", id: 2 },
    { name: "Familia Aboytes Luna", guess: "3", id: 3 },
    { name: "Familia Vargas Leal", guess: "7", id: 4 },
    { name: "Familia Ramos Lira", guess: "4", id: 5 },
    { name: "Familia Rivera Ramos", guess: "4", id: 6 },
    { name: "Familia Ramos Hernandez", guess: "2", id: 7 },
    { name: "Juan Ramos y familia", guess: "5", id: 8 },
    { name: "Familia De Santiago Luna", guess: "3", id: 9 },
    { name: "Eva y acompañante", guess: "2", id: 10 },
    { name: "Jessy Zapata", guess: "3", id: 11 },
    { name: "Familia Aboytes Valencia", guess: "10", id: 12 },
    { name: "Familia Luna Perez", guess: "6", id: 13 },
    { name: "Tony y familia", guess: "4", id: 14 },
    { name: "Viviana Hernandez", guess: "3", id: 15 },
    { name: "Karla Suarez", guess: "4", id: 16 },
    { name: "Karen Paniagua", guess: "3", id: 17 },
    { name: "Edith Mejia", guess: "2", id: 18 },
    { name: "Lupita", guess: "1", id: 19 },
    { name: "Marco Almaraz", guess: "3", id: 20 },
    { name: "Marisol Luna", guess: "2", id: 21 },
    { name: "Magaly Almaraz", guess: "5", id: 22 },
    { name: "Jimena Juarez", guess: "3", id: 23 },
    { name: "Carlos Ortuño", guess: "3", id: 24 },
    { name: "Paco Santillan", guess: "2", id: 25 },
    { name: "Daniel Guerrero", guess: "3", id: 26 },
    { name: "Alfonso Villicaña", guess: "2", id: 27 },
    { name: "Ivan Ortiz", guess: "2", id: 28 },
    { name: "Alan Becerra", guess: "2", id: 29 },
    { name: "Isaias Santos", guess: "2", id: 30 },
    { name: "Daniel Bautista", guess: "2", id: 31 },
    { name: "Familia Ramos Gudiño", guess: "7", id: 32 },
    { name: "Familia Ramos Torres", guess: "3", id: 33 },
    { name: "Familia Villalobos Carrion", guess: "4", id: 34 },
    { name: "Rocio", guess: "2", id: 35 },
    { name: "Ana Araiza", guess: "2", id: 36 },
    { name: "Oscar Regalado", guess: "2", id: 37 },
    { name: "Familia Alvarez Aboytes", guess: "7", id: 38 },
    { name: "Margarito Aboytes", guess: "3", id: 39 },
    { name: "Familia Ramirez Miranda", guess: "4", id: 40 },
    { name: "Familia Campos Ramirez", guess: "4", id: 41 },
    { name: "Axel y Aaron", guess: "2", id: 42 },
    { name: "Ricardo Hernandez", guess: "4", id: 43 },
    { name: "Daniel Luna y familia", guess: "6", id: 44 },
    { name: "Familia Arroyo Luna", guess: "2", id: 45 },
    { name: "Familia Arroyo Silva", guess: "4", id: 46 },
    { name: "Familia Arroyo Martinez", guess: "4", id: 47 },
    { name: "Leonardo Luna", guess: "5", id: 48 },
    { name: "Familia Luna Gonzalez", guess: "2", id: 49 },
    { name: "Familia Hernandez Espindola", guess: "4", id: 50 },
    { name: "Nallely Jimenez", guess: "1", id: 51 },
    { name: "Amigo", guess: "1", id: 52 }
  ];
  
  useEffect(() => {
    const guestId = parseInt(nameId);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} /> : ""}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPrograma.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,

            color: datosSeccionPrograma.textColor
              ? datosSeccionPrograma.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionPrograma.ceremoniaReligiosa ? (
                  <>
                    <PiChurchLight className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      CEREMONIA RELIGIOSA
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.ceremoniaReligiosa}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.recepcion ? (
                  <>
                    <FaPhotoVideo className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.recepcion}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.cena ? (
                  <>
                    <ImSpoonKnife className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">BANQUETE</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.cena}
                    </p>
                    <br />
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>

              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionPrograma.baileXV ? (
                  <>
                    <GiPartyPopper className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      BAILE DE XV AÑOS
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.baileXV}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.baile ? (
                  <>
                    <GiMeshBall className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">BAILE</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.baile}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
