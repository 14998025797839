import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing, GiTicket } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ImagenComponent from "../../../components/ComponentsBoda/ImagenComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaLuisYKimberly = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");
  const [isIOS, setIsIOS] = useState(false);

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#fde1ff",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_06/NFkfc5uyKPR_y2mate.com---Mena-Massoud-Naomi-Scott-A-Whole-New-World-From-AladdinAudio-Only.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Luis",
    esposa: "Kimberly",
    fechaDeBoda: "23 NOVIEMBRE, 2024",
    imgPortada: "https://i.postimg.cc/NGZpshhq/IMG-0483.jpg",
    titulo: "NO FALTES A NUESTRA BODA",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Queremos agradecer a cada uno de ustedes por acompañarnos en nuestra boda y ser parte de este día tan especial… \\nNos sentimos afortunados de estar rodeados de nuestros seres queridos con quienes hemos tenido la oportunidad de compartir momentos inolvidables \\nEsperamos pasen un día memorable",
    fechaNewDate: "November 23, 2024 12:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/SNFzdhfn/7ff81f95-e59c-4ca3-b594-29a6ee6dbe4d.jpg",
    fotoDerecha: "https://i.postimg.cc/7hJGsQXS/IMG-0484.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Bertha Navarro de la Torre",
    padre: "Saúl Jorge Pimentel Ayala",
    titulo2: "Padres del Novio",
    madrina: "Manuela Flores Castro",
    padrino: "Sergio Montelongo Solís",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 23 de Noviembre de 2024.",
    ceremoniaHora: "12:00 p.m.",
    ceremoniaNombreLugar:
      "Parroquia de Nuestra Señora de San Juan de los Lagos",
    ceremoniaLugarMap: "",
    ceremoniaUrl:
      "https://maps.apple.com/?address=Avenida%20Guanajuato,%20Jardines%20del%20Moral,%2037160%20Le%C3%B3n,%20GTO,%20M%C3%A9xico&auid=5384091560236518883&ll=21.146903,-101.693938&lsp=9902&q=Parroquia%20de%20Nuestra%20Se%C3%B1ora%20de%20San%20Juan%20de%20los%20La",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 23 de Noviembre de 2024.",
    recepcionHora: "2:30 p.m.",
    recepcionNombreLugar: "Casa de Palmas ",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/a5L5kxWKgynNGtR88?g_st=ic",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    titulo:"",
    descripcion:"",
    ceremoniaReligiosaImg:"",
ceremoniaReligiosaTitulo:"",
    civil: "",
    civilImg:"",
    civilTitulo: "",
    recepcion: "Tómate fotos con nosotros",
    recepcionImg: "",
    recepcionTitulo:"",
    cena: "Disfruta de una deliciosa cena",
    cenaImg:"",
    cenaTitulo:  "Cena",
    coctel: "Disfruta de un refrescante cóctel",
    coctelImg:  "",
    coctelTitulo: "",
    baile: "Sacale brillo a la pista",
    baileImg:  "",
    baileTitulo: "",
    textColor: "",
    imgFondo: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "ETIQUETA RIGUROSA",
    messageVestimenta: "No llevar color fucsia, blanco, beige.",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Su presencia en nuestra boda es el mejor regalo que podríamos recibir. Sin embargo, si desean hacernos un obsequio adicional, les agradecemos de antemano por su generosidad. ¡Gracias por ser parte de este día tan especial!",
    link1: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51406215",
    imgLink1:
      "https://i.postimg.cc/gJndtPbL/EFD44-F23-84-B7-4-BD5-A554-CB4737-F8185-F.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "",
    numeroConfirmacion: "+524772435086",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+524772435086",
    numeroNovio: "+524775942192",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://i.postimg.cc/VLq0HVKD/847bb594-2ba6-4579-86fb-f61745cd8604.jpg",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "#fde1ff",
  };

  const datosSeccionFoto = {
    imgFondo: "https://i.postimg.cc/FKFxWNSc/IMG-0478.jpg",
    sizeFoto: "",
    imgContenidos: [
      "https://i.postimg.cc/MGnmTbNX/Comparte-con-nosotros-todas-tus-fotograf-as-del-evento-usando-el-siguiente-hashtag-en-todas-tus-pu.png",
    ],
  };

  const contador = true;
  const padres = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/ZR2F5NMF/IMG-0475.jpg",
    "https://i.postimg.cc/rwNJNKDM/IMG-0477.jpg",
    "https://i.postimg.cc/GpMx6VPf/IMG-0479.jpg",
    "https://i.postimg.cc/yY9nWfBJ/IMG-0480.jpg",
    "https://i.postimg.cc/Z0k91FD2/IMG-0481.jpg",
    "https://i.postimg.cc/pLHCjDXs/IMG-0476.jpg",
    "https://i.postimg.cc/jqDMYxF4/IMG-0482.jpg",

    // Add more image URLs as needed
  ];

  const names = [
    { name: "Bertha Navarro y Jorge Pimentel", guess: "3", id: 1 },
    { name: "Arely Pimentel", guess: "2", id: 2 },
    { name: "Dayana Villegas", guess: "2", id: 3 },
    { name: "Karen García", guess: "2", id: 4 },
    { name: "Belén Martínez", guess: "1", id: 5 },
    { name: "Familia Miguel Navarro", guess: "5", id: 6 },
    { name: "Fernando Esparza", guess: "2", id: 7 },
    { name: "Familia Fatima Navarro", guess: "4", id: 8 },
    { name: "Ma. Del Carmen Navarro", guess: "1", id: 9 },
    { name: "Jonathan Navarro", guess: "2", id: 10 },
    { name: "Jaqueline Navarro", guess: "2", id: 11 },
    { name: "María Berber", guess: "3", id: 12 },
    { name: "Elias Pimentel", guess: "1", id: 13 },
    { name: "Victor Pimentel", guess: "2", id: 14 },
    { name: "Graciela Pimentel", guess: "2", id: 15 },
    { name: "Karen Pimentel", guess: "2", id: 16 },
    { name: "Noemi Pimentel", guess: "2", id: 17 },
    { name: "Familia Ricardo Pimentel", guess: "6", id: 18 },
    { name: "Vania Pimentel", guess: "2", id: 19 },
    { name: "Alin Hernández", guess: "1", id: 20 },
    { name: "Marco Alberto Hernández", guess: "2", id: 21 },
    { name: "Liliana Serrano", guess: "2", id: 22 },
    { name: "Yaneli Pérez", guess: "2", id: 23 },
    { name: "Fernando Olmos", guess: "1", id: 24 },
    { name: "Pablo Guerrero e Irma García", guess: "2", id: 25 },
    { name: "Carlos Prado", guess: "2", id: 26 },
    { name: "Alejandro Galicia y Alejandra Chávez", guess: "2", id: 27 },
    { name: "Mildrett Meza", guess: "1", id: 28 },
    { name: "Saúl Guerrero", guess: "2", id: 29 },
    { name: "Aaron Ruíz", guess: "2", id: 30 },
    { name: "María Montelongo", guess: "2", id: 31 },
    { name: "Luz Montelongo", guess: "2", id: 32 },
    { name: "Juan Montelongo", guess: "2", id: 33 },
    { name: "Sergio Montelongo Y María Jose Gómez", guess: "2", id: 34 },
    { name: "Familia de Sergio Montelongo", guess: "3", id: 35 },
    { name: "Cecilia Montelongo", guess: "2", id: 36 },
    { name: "Paola Guerrero", guess: "2", id: 37 },
    { name: "Mauricio Varela", guess: "2", id: 38 },
    { name: "Priscila Zabala", guess: "1", id: 39 },
    { name: "Adrian Gallardo", guess: "1", id: 40 },
    { name: "Jesús Montelongo", guess: "2", id: 41 },
    { name: "Jorge Montelongo", guess: "2", id: 42 },
    { name: "Stephany Lomeli", guess: "2", id: 43 },
    { name: "Miriam Mendoza", guess: "2", id: 44 },
    { name: "Juan Barajas", guess: "2", id: 45 },
    { name: "Alberto Macias", guess: "1", id: 46 },
    { name: "Cecilia Garduño", guess: "2", id: 47 },
    { name: "Ana García", guess: "1", id: 48 },
    { name: "Familia de Ismael Montelongo", guess: "8", id: 49 },
    { name: "Familia de Angelica Montelongo", guess: "4", id: 50 },
    { name: "Familia de Eva Flores", guess: "3", id: 51 },
    { name: "Familia de Mercedes Flores", guess: "3", id: 52 },
    { name: "Familia de Guadalupe Flores", guess: "2", id: 53 },
    { name: "Familia de María de la Luz Flores", guess: "2", id: 54 },
    { name: "Familia Guerrero", guess: "6", id: 55 },
    { name: "Familia de Gregorio Flores", guess: "5", id: 56 },
    { name: "Familia de Leonardo Flores", guess: "4", id: 57 },
    { name: "Guadalupe Flores", guess: "2", id: 58 },
    { name: "Leonardo Flores", guess: "1", id: 59 },
    { name: "Familia de Jesús Mendez", guess: "2", id: 60 },
    { name: "Familia de Hugo Mendez", guess: "2", id: 61 },
    { name: "Juan Montelongo", guess: "1", id: 62 },
    { name: "Adriana Lázaro", guess: "1", id: 63 },
    { name: "Sahara Amador", guess: "1", id: 64 },
    { name: "Alberto Macías", guess: "2", id: 65 },
    { name: "Raúl Meza", guess: "1", id: 66 },
    { name: "Cecilia Araujo", guess: "2", id: 67 },
  ];

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la Boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

<div
      style={{
        backgroundImage: `url(${datosSeccionInvitados.imgFondo || ""})`,
        backgroundColor: peltaColores.color2,
        backgroundAttachment: isIOS ? 'local' : 'fixed',
        backgroundPosition:datosSeccionInvitados.sizeFoto? datosSeccionInvitados.sizeFoto: "center"
      }}
      className="sm:h-auto md:h-[100vh] bg-img-fixed sm:bg-local md:bg-fixed px-2 py-5"
    >
      <audio controls="controls" style={{ display: 'none' }}>
        <source src="tu_archivo.mp3" type="audio/mpeg" />
        <source src="tu_archivo.ogg" type="audio/ogg" />
      </audio>

      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px] bg-slate-50 p-2 rounded-2xl text-black"
          data-aos="flip-up"
        >
          {datosSeccionInvitados.titulo}
        </h1>
        <br />
        <br />
        <p
          className=" text-center text-white p-[3vh] rounded-tl-3xl rounded-br-3xl font-serif text-[35px]"
          data-aos="flip-up"
          style={{
            background: datosSeccionInvitados.colorFondoName? datosSeccionInvitados.colorFondoName : 'rgb(141,144,2)',
            background: datosSeccionInvitados.colorFondoName? datosSeccionInvitados.colorFondoName :  '#8d900273',
          }}
        >
          {NameInvitation}
        </p>
        <img
          src={datosSeccionInvitados.imgInvitados ? datosSeccionInvitados.imgInvitados : ""}
          className="md:w-[25%] sm:w-[75%] md:h-[23vh] md:mt-[-10px] sm:mt-[10px] object-contain"
          alt=""
        />
        <div className="grid sm:grid-cols-1 w-[80%] pt-[10vh]">
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            <GiTicket className="text-[50px] bg-slate-50 p-2 rounded-2xl" 
            style={{
              color: datosSeccionInvitados.colorFondoName? datosSeccionInvitados.colorFondoName : '#a3a438',
            }}/>
            <br />
            <p className="text-black font-serif text-center text-2xl bg-slate-50 p-2 rounded-2xl">
              Cuentas con {numGuess} accesos para {datosSeccionInvitados.entradasText}
            </p>
            <br />
            <p className="text-black font-serif text-center text-2xl bg-slate-50 p-2 rounded-2xl">
              No Niños
            </p>
          </div>
        </div>
      </div>
    </div>

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        // <HospedajeXvComponent
        //   peltaColores={peltaColores}
        //   datosSeccionHospedaje={datosSeccionHospedaje}
        // />
        <ImagenComponent datosSeccionFoto={datosSeccionFoto} />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
