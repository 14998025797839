import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./assets/MarryYou.mp3";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";

export const BodaMariaYVictor = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:' nuestra boda',
      esposo: "Víctor",
      esposa: "María",
      fechaDeBoda: "27 ABRIL, 2024",
      fechaNewDate: "April 27, 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/T2cr5gbD/image0.jpg",
      numeroNovia: "+528991336546",
      numeroNovio: "+528991038577",
      novios:true,
      titulo:"NUESTRA BODA",
      aviso:"No Niños"
    };
   
    const peltaColores = {
      color1: "#e7e3e3",
      color2: "#e7e3e3",
    };
  
    const datosSeccion2 = {
      titulo:'¡Nos Casamos!',
      descipcion:
        "Hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
      fechaNewDate: "April 27, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/yYQcvKv6/image1.jpg",
      fotoDerecha: "https://i.postimg.cc/NffR6F8P/image2.jpg",
    };
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a la boda de Jimena y Antonio!',
      entradasText:'disfrutar nuestro dia especial'
    }
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor y apoyo de nuestros padres!",
      descipcion:
        "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
      tituloIzquierda:'Padres de la novia',
      tituloDerecha:'Padres del novio',
      esposaMadre: "María Guadalupe Sánchez Puente",
      esposaPadre: "Pedro Torres Zarate",
      esposoMadre: "María Guadalupe Rivera Hernández",
      esposoPadre: "Víctor Hugo Hernández Villa",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "",
      ceremoniaHora: "",
      ceremoniaNombreLugar: "",
      ceremoniaLugarMap: "",
      ceremoniaUrl: "",
      recepcionFecha: "Sábado, 27 de Abril de 2024.",
      recepcionHora: "6:00 p.m.",
      recepcionNombreLugar: "",
      recepcionLugarMap:
        "Calle Lic. Carlos González Zamora #775",
      recepcionUrl: "https://maps.app.goo.gl/jCnJRFi3BTxtecmL7?g_st=ic",
    };
  
  
    const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
  
    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const difference = targetDate - now;
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
  
    const images = [
      "https://i.postimg.cc/T153DxTc/65ee4b07-4108-4ac6-b4bf-6c61f29d0a1c.jpg",
      "https://i.postimg.cc/T2cr5gbD/image0.jpg",
      "https://i.postimg.cc/yYQcvKv6/image1.jpg",
      "https://i.postimg.cc/NffR6F8P/image2.jpg",
      // Add more image URLs as needed
    ];
    
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
  
      AOS.init({ duration: 2000 });
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <PortadaBoda
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
        
        
    <div
        style={{
          backgroundImage:datosSeccion4.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color:datosSeccion4.textColor || "",
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[10vh]">
            <div></div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <FaChampagneGlasses className="text-[80px]" />
              <br />
              <p className="font-serif text-2xl">Recepción</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionFecha}
              </p>
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center ">
                {datosSeccion4.recepcionNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.recepcionLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.recepcionUrl}
                class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>
  
  
  <ConfirmacionGeneral
            peltaColores={peltaColores}
            images={images}
            datosSeccion1={datosSeccion1}
          />
  
      </div>
    );
}
