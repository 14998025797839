import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./btsCover.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import Slider from "react-slick";
import { FaWhatsapp } from "react-icons/fa";

export const XvAlexiaPaola = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Alexia Paola",
      fechaDeBoda: "4 MAYO, 2024",
      fechaNewDate: "May 04, 2024 17:00:00 GMT+00:00",
      imgPortada: "https://img-fotki.yandex.ru/get/39232/112265771.a30/0_ce0a6_4376cc9d_XL.jpg",
      numeroNovia: "9511022085",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#f884ff",
      color2: "#f884ff",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Alexia paola!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
      "En esta noche tan especial, puedo tocar las estrellas con mis manos, puedo pedirlas prestadas al cielo y dar una por una a mis familiares y a mis amigos más queridos. Por eso quiero que vengas a buscar tu estrella, en mi fiesta que se celebrará con motivo de mis 15 Años. Será un placer compartir contigo esta velada",
      fechaNewDate: "May 04, 2024 17:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/cHNXWBBP/4d55c0e8-6f33-45ac-8dad-727f40a824fa.jpg",
      fotoDerecha: "https://i.postimg.cc/FKSDwB3j/2e579a3e-84c3-4746-a1cd-fbf0dfb512e7.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra con amor, acompañada de mi madre y mi padrino!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Madre de la Quinceañera',
        tituloDerecha:'Padrino de la Quinceañera',
      esposaMadre: "Alejandra Sànchez Chàvez",
      esposaPadre: "",
      esposoMadre: "Sr. Sergio Enrique Garcia de Jesús",
      esposoPadre: "",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 4 de Mayo de 2024.",
      ceremoniaHora: "5:00 p.m.",
      ceremoniaNombreLugar: "Capilla de Belem",
      ceremoniaLugarMap:
        "Calle de José María Pino Suárez, RUTA INDEPENDENCIA, Centro, 68000 Oaxaca de Juárez, Oax.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/LjY4omBHoKJ7JJ9p6",
      recepcionFecha: "Sábado, 4 de Mayo de 2024.",
      recepcionHora: "6:30 p.m.",
      recepcionNombreLugar: "",
      recepcionLugarMap:
        "Cuauhtémoc 712, 68026 Oaxaca de Juárez, Oax.",
      recepcionUrl:
        "https://maps.app.goo.gl/ropgMyEpcKmyVLXBA",
    };

  
  
  
    const images = [
      "https://i.postimg.cc/8zjym5CX/1d258b34-ffc1-455a-a7b3-d4e961d8911f.jpg",
      "https://i.postimg.cc/cHNXWBBP/4d55c0e8-6f33-45ac-8dad-727f40a824fa.jpg",
      "https://i.postimg.cc/sgqT6rQj/d483df11-8bea-4628-bfad-4d5ed2f6b721.jpg",
      "https://i.postimg.cc/FKSDwB3j/2e579a3e-84c3-4746-a1cd-fbf0dfb512e7.jpg",
      // Add more image URLs as needed
    ];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        cssEase: "linear"
      };
  
    const [messageWhats, setMessageWhats] = useState("");
    const [name, setName] = useState("");
    const [guests, setGuests] = useState(0);
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Assuming you have some logic to format the message for WhatsApp
        const message = `Hola, soy ${name} y confirmo mi asistencia a los XV años con ${guests} invitado(s). ¡Nos vemos allí!`;
    
        // Replace with the actual WhatsApp API URL and encode the message
        const whatsappUrl = `https://api.whatsapp.com/send?phone=9511022085&text=${encodeURIComponent(
          message
        )}`;
    
        // Redirect to the WhatsApp URL
        window.location.href = whatsappUrl;
      };

    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />

<div
            style={{
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:peltaColores.color1
            }}
            className="sm:h-[150vh] md:h-[150vh] flex flex-col justify-center items-center w-full"
          >
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              ¡Te espero para festejar mis XV años!
            </h1>
            <br />
            <br />
            <br/>
            <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
              {images?.map((imageUrl, index) => (
                <div  data-aos="fade-down-right" className="rolImg" key={index}>
                  <img
                    src={imageUrl}
                    alt={`Slide ${index + 1}`}
                    className="w-auto rounded-xl max-h-[50vh]"
                  />
                </div>
              ))}
            </Slider>
            <br />
            <br />
            <div data-aos="fade-down-left" className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4 justify-center flex">
                Confirmación de Asistencia al Evento{" "}
                <FaWhatsapp className="text-green-600 ml-2" />
              </h2>
              <form onSubmit={handleSubmit}>
                <label className="mb-2 flex">
                  <span className="text-gray-700">Nombre:</span>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                    required
                  />
                </label>
                <br />
                <label className="flex mb-2">
                  <span className="text-gray-700">Invitados:</span>
                  <input
                    type="number"
                    value={guests}
                    onChange={(e) => setGuests(e.target.value)}
                    className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                    min={1}
                    required
                  />
                </label>
                <br />
                <button
                  type="submit"
                  className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                >
                  Confirmar Asistencia
                </button>
              </form>
            </div>
          </div>
  
      </div>
    );
}
