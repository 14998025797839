import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./cancionXVmonserrat.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { Helmet } from "react-helmet";
import Modal2Xv from "../../../components/Modals/Modal2Xv";

export const XvEjemploPlata = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Monserrat Lira",
      fechaDeBoda: "23 DICIEMBRE, 2024",
      fechaNewDate: "December 12, 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
      numeroNovia: "4491516931",
      numeroNovio: "4491516931",
    };
  
    const peltaColores = {
      color1: "#fff5ff",
      color2: "#fffbff",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Monserrat Lira!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "December 12, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
      fotoDerecha: "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Magdalena Vázquez Prieto",
      esposaPadre: "Adolfo Lira González",
      esposoMadre: "Rosio Garibay Cervantes",
      esposoPadre: "Juan Carlos Lira Vázquez",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 23 de Diciembre de 2024.",
      ceremoniaHora: "6:00 p.m.",
      ceremoniaNombreLugar: "Parroquia del Divino Salvador",
      ceremoniaLugarMap:
        "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
      ceremoniaUrl:
        "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
      recepcionFecha: "Sábado, 23 de Diciembre de 2024.",
      recepcionHora: "8:00 p.m.",
      recepcionNombreLugar: "El Herradero Jardín y Eventos",
      recepcionLugarMap:
        "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
      recepcionUrl:
        "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    };
  
  
    const images = [
      "https://i.postimg.cc/MZ3t62Ly/435af0ee-b3c0-425b-b966-446fe26e78d5.jpg",
      "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
      "https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg",
      "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
      "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg"
      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
         <Helmet>
                <meta charSet="utf-8" />
                <link rel="canonical" href="https://digitalrsvp.mx/evento-plata-xv"></link>
                <title>{datosSeccion1.nombre}</title>
                <meta property="og:locale" content="es_MX"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:description" content="Invitación DEMO"></meta>
                <meta property="og:url" content="https://digitalrsvp.mx/evento-plata-xv">
                </meta>
                <meta property="og:image" content="https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg"></meta>
                <meta property="og:image:width" content="630"></meta>
                <meta property="og:image:height" content="420"></meta>
                <meta property="og:image:type" content="image/jpg"></meta>
            </Helmet>
        <ModalXvCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccion1}
        peltaColores={peltaColores}
      />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
