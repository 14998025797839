import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./LoveMeHarder.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import {Helmet} from "react-helmet";
import { Vestimenta } from "../../../components/Vestimenta";
import { FaChampagneGlasses } from "react-icons/fa6";

export const XvRosaValeria = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "ROSA VALERIA",
      fechaDeBoda: "04 MAYO, 2024",
      fechaNewDate: "May 04, 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/PfBVhS7Q/1712362041001.jpg",
      numeroNovia: "9371199005",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#fff6ed",
      color2: "#fdf1f1",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Rosa Valeria!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial, primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "May 04, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/mZcp0vmr/1712362040966.jpg",
      fotoDerecha: "https://i.postimg.cc/qqCKjXXk/1712362041079.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Amelia Montiel Flores",
      esposaPadre: "Andrés López Olsin",
      esposoMadre: "Marisela de la Cruz Tiquet",
      esposoPadre: "Julio César López Naranjo",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "",
      ceremoniaHora: "",
      ceremoniaNombreLugar: "",
      ceremoniaLugarMap:
        "",
      ceremoniaUrl:
        "",
      recepcionFecha: "Sábado, 04 de Mayo de 2024.",
      recepcionHora: "6:00 p.m.",
      recepcionNombreLugar: "Salón Quinta el Paraíso",
      recepcionLugarMap:
        "Cam. del Arenal 451, Sta Maria Periferico, 86553 Cárdenas, Tab.",
      recepcionUrl:
        "https://maps.app.goo.gl/zbWCDdrGkP6jQ1wF8",
    };

    const datosSeccion8 = {
        descripcion:
          "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía, sin embargo si deseas obsequiarme algo me encantaría fuera efectivo. Gracias por permitirme escoger mi regalo.",
        linkAmazon:
          "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
        linkLiverpool:
          "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
      };
    
    const imagenesFondo ={
        fondoInvitados:'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
        fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
      }
  
  
    const images = [
      "https://i.postimg.cc/c6ynjgXf/1712362040975.jpg",
      "https://i.postimg.cc/cJsQ03Zm/1712362041105.jpg",
      "https://i.postimg.cc/63qnGN84/1712362041125.jpg",
      "https://i.postimg.cc/RFYf7bts/1712362041138.jpg",
      "https://i.postimg.cc/LsNjXs8M/1712362041148.jpg"
      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
    return (
        <div className="w-full overflow-x-hidden bg-neutral-500">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Digital RSVP - XV {datosSeccion1.nombre}</title>
                <link rel="canonical" href="https://digitalrsvp.mx/" />
                <meta property="og:image" content="https://img.freepik.com/vector-premium/mis-xv-anos-lettering-celebracion-quinceanera_634954-217.jpg"/>
                <meta property="og:image:width" content="480"/>
                <meta property="og:image:height" content="320"/>
            </Helmet>
          <ModalXv 
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          />
          <Portada
          datosSeccion1 ={datosSeccion1}
          audioFondo = {audioFondo}
          />
    
          <Contador
            peltaColores = {peltaColores}
            datosSeccion2 = {datosSeccion2}
          />
    
          <Padres
            peltaColores={peltaColores}
            datosSeccion3={datosSeccion3}
          />
  
          <Vestimenta
            peltaColores={peltaColores}
            imagenesFondo={imagenesFondo}
          />
    
    <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[10vh]">
            <div></div>
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              
              <div className="flex">
              <FaChampagneGlasses className="text-[80px]" />
              </div>
              <br />
              <p className="font-serif text-2xl">Recepción</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion4.recepcionFecha}
              </p>
              <p className="font-serif text-xl"></p>
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.recepcionLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.recepcionUrl}
                class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:px-3 sm:py-10 md:py-28 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/pT8SZzXT/Dise-o-sin-t-tulo-7.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color2,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
             
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/pT8SZzXT/Dise-o-sin-t-tulo-7.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color2,
              }}
              className="md:flex sm:hidden flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              
            </div>
          </div>
        </div>
      </div>
    
          <ConfirmacionGeneral
            peltaColores={peltaColores}
            images={images}
            datosSeccion1={datosSeccion1}
          />
    
        </div>
      );
}
