import audioFondo from "../../bodas/Boda-diamante/assets/maroon.mp3";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";

export const XvLunaSakura = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#fffaf0",
    color2: "#f5c2cc",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_04/NDxgjwhsM3u_Y2meta.app---Tercer-Cielo---No-Crezcas-M%C3%A1s-Letras-Lyric-128-kbps-.mp3",
    imgPortada:
      "https://i.postimg.cc/fy2JvPtf/White.png",
    nombre: "Luna Sakura",
    fechaDeBoda: "29 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+529831143797",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy hace quince años mis padres daban gracias a Dios por mi. \\n Hoy doy gracias a Dios por ellos, por cuidarme, por tenerme paciencia y aconsejarme. \\n Doy gracias también por toda mi familia por hacer especiales mis días. \\n \\n Deléitate en el señor, y él concederá los deseos de tu corazón. \\n SALMO 37:4",
    fechaNewDate: "Junio 29, 2024 19:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/bJ230GMT/Sakura-Cherry-Floral-Wedding-Invitation-Card1.png",
    fotoDerecha:
      "https://i.postimg.cc/3NRqktsv/Documento-A4-Hoja-de-Papel-Floreado-Blanco-y-Negro.png",
    marcoEnable: true,
    imgFondo: "https://i.postimg.cc/50DJ2CdW/Pink-Watercolor.png",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Adriana del Jesús Mayo Rosado",
    padre: "Felipe Torres Lara",
    madrina: "Fátima Maricuz Chagolla Aguilar",
    padrino: "Luis Gilberto Mayo Rosado",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 29 de Junio de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Iglesia de la Santa Cruz",
    ceremoniaLugarMap:
      "C. 68 65 y 67, Centro, 77200 Felipe Carrillo Puerto, Q.R.",
    ceremoniaUrl:
      "https://maps.app.goo.gl/jvnmxHyzMiEAEptc6",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 29 de Junio de 2024.",
    recepcionHora: "8:45 p.m.",
    recepcionNombreLugar: "Salón Haltún",
    recepcionLugarMap:
      "C. 44, Plan de Ayala, 77214 Felipe Carrillo Puerto, Q.R.",
    recepcionUrl:
      "https://maps.app.goo.gl/AUueiEpZFPtboRw76",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "a",
    urlVestimentaHombre: "a",
    imgFondo:
      "https://i.postimg.cc/vHSFzh4H/Cherry-Blossoms-Scripture-Instagram-Post.png",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Tu asistencia es lo mas importante para nosotros, pero, si deseas tener un detalle, aquí te presentamos algunas sugerencias.",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/dl/invite/98L752r?ref_=wl_share",
    imgLink1: "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51430942",
    imgLink2: "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/hjQ16FND/b0dec89a-a0d5-4993-a92c-df241f545c02.jpg",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados ={
    imgFondo:"https://i.postimg.cc/vHSFzh4H/Cherry-Blossoms-Scripture-Instagram-Post.png",
    titulo: "¡Has sido invitado a los XV años de Luna Sakura!",
    entradasText: "disfrutar mis XV años",
    colorFondoName:"#f5c2cc"
  }

  const images = [

    "https://i.postimg.cc/C5VwtB9s/Cherry-Blossoms-Scripture-Instagram-Story.png"
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Samuel Arroyo", guess: "3", id: 2 },
    { name: "Beatriz Lara Segovia", guess: "2", id: 3 },
    { name: "Nicanor Anguiano Segovia", guess: "2", id: 4 },
    { name: "Familia Gongora Torres", guess: "6", id: 5 },
    { name: "Familia Tamayo Torres", guess: "4", id: 6 },
    { name: "Familia Tamayo Chiclin", guess: "2", id: 7 },
    { name: "Familia Baas Jimenez", guess: "3", id: 8 },
    { name: "Familia Torres Hidalgo", guess: "5", id: 9 },
    { name: "Familia Esquivel Torres", guess: "2", id: 10 },
    { name: "Magaly Urbina Castellanos", guess: "1", id: 11 },
    { name: "Maria del Carmen Rosado Medina", guess: "1", id: 12 },
    { name: "José Guadalupe Mayo Rosado", guess: "1", id: 13 },
    { name: "Familia Baas Mayo", guess: "2", id: 14 },
    { name: "Familia Chi Baas", guess: "3", id: 15 },
    { name: "Oscar Alexis Pat Mayo", guess: "1", id: 16 },
    { name: "Alissa Irari Pat Mayo", guess: "2", id: 17 },
    { name: "Familia Torres López", guess: "10", id: 18 },
    { name: "Familia Mayo Chagolla", guess: "3", id: 19 },
    { name: "Maria Virgina Aguilar Tamayo", guess: "2", id: 20 },
    { name: "André Haziel Chagolla García", guess: "2", id: 21 },
    { name: "Pablo Abel Chagolla Balam", guess: "2", id: 22 },
    { name: "Rodrigo Octavio Balam Pacab", guess: "2", id: 23 },
    { name: "Manuel Jesús Cuevas Caamal", guess: "2", id: 24 },
    { name: "Milton Enrique Tuz Ceme", guess: "2", id: 25 },
    { name: "Jesús Abelardo Salazar Moguel", guess: "2", id: 26 },
    { name: "Eddy Alexander Chan Balan", guess: "2", id: 27 },
    { name: "Maestra Gloria", guess: "2", id: 28 },
    { name: "Maestra Ana", guess: "2", id: 29 },
    { name: "Julio Cesar Castillo Catzin", guess: "3", id: 30 },
    { name: "Eddy Fernando Yanes Velasquez", guess: "2", id: 31 },
    { name: "Fernando Emanuel Nieto Torres", guess: "2", id: 32 },
    { name: "Natsumi Jimenez Aviles", guess: "3", id: 33 },
    { name: "Ian Miguel Marin Borges", guess: "2", id: 34 },
    { name: "Dayana Sarai Dzul Pat", guess: "2", id: 35 },
    { name: "Citlali Esmeralda Hernández Montalvo", guess: "2", id: 36 },
    { name: "Jorge Emilio Barzon Chi", guess: "2", id: 37 },
    { name: "Jessica Isabel Navarrete Coh", guess: "2", id: 38 },
    { name: "Camila Aurora Martinez González", guess: "2", id: 39 },
    { name: "Fernanda Isabel Montero López", guess: "2", id: 40 },
    { name: "Set Castro Montero", guess: "2", id: 41 },
    { name: "Victor Alonso Cajun Cahuich", guess: "2", id: 42 },
    { name: "Daniela Rocio Cardenas Poot", guess: "2", id: 43 },
    { name: "Felipa Cumi Salazar", guess: "2", id: 44 },
    { name: "Joseph Zuriel Rodríguez Caamal", guess: "3", id: 45 },
];

const [isIOS, setIsIOS] = useState(false);
const textoConSaltosDeLinea = "Primera línea\\nSegunda línea\\nTercera línea"; 

useEffect(() => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  setIsIOS(/iphone|ipad|ipod/.test(userAgent));
}, []);

  useEffect(() => {
    const findName = names.find((e) => e.name == nameId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
       {
        modal? (
          <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} />
        ):("")
      }
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
         <div
         style={{
           backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
           backgroundColor: peltaColores.color1,
           backgroundAttachment: isIOS ? "local" : "fixed",
   
           color: peltaColores.textColor ? peltaColores.textColor : "",
         }}
         className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
       >
         <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
           <h1
             className="font-serif text-center sm:text-[32px] md:text-[42px]"
             data-aos="flip-up"
           >
             Código de vestimenta
           </h1>
           <br />
           <p
             className=" text-center pt-[5vh] font-serif text-xl"
             data-aos="flip-up"
           >
             {datosSeccionVestimenta.vestimenta
               ? datosSeccionVestimenta.vestimenta
               : "FORMAL"}
           </p>
           <br />
           {datosSeccionVestimenta.messageVestimenta ? (
             <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
               {datosSeccionVestimenta.messageVestimenta
                 .split("\\n")
                 .map((linea, index) => (
                   <React.Fragment key={index}>
                     {linea}
                     <br />
                   </React.Fragment>
                 ))}
             </p>
           ) : (
             ""
           )}
   
           
         </div>
       </div>
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
        peltaColores={peltaColores}
        images={images}
        messageWhats={messageWhats}
        datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
