import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import videoProceso from "../../../../assets/proceso-compra.mp4";
import { FaArrowDown } from "react-icons/fa6";

// Example images - replace with actual paths
const headerImages = [
  "https://i.postimg.cc/YSgv9kw8/pexels-luis-zambrano-3782493-18664656.jpg",
  "https://i.postimg.cc/ncnVXLHg/pexels-misha-earle-366060-1777843.jpg",
  "https://i.postimg.cc/RZ0ct92R/Whats-App-Image-2024-07-28-at-17-16-50-2.jpg",
  
];
const plataImage =
  "https://i.postimg.cc/ncnVXLHg/pexels-misha-earle-366060-1777843.jpg";
const oroImage =
  "https://i.postimg.cc/9QnVCwQM/pexels-angel-ayala-321556-27903364.jpg";
const diamanteImage =
  "https://i.postimg.cc/5yV4q48H/Sin_título_(4000_x_2500_px).png";

const packages = [
  {
    name: "SILVER",
    imageUrl: plataImage,
    designs: [
      {
        preciceDec: 50,
        precice: 19.95,
        name: "Open Invitation",
        link: "/en/wedding-auto?id=3323",
        name2: "Example without Photos",
        link2: "/boda-plata-ejemplo-fotos",
      },
    ],
  },
  {
    className: "DIAMOND",
    imageUrl: diamanteImage,
    designs: [
      {
        preciceDec: 130,
        precice: 59.95,
        name: "Open Invitation",
        link: "/wedding-auto-diamond?idInvitado=49674",
        name2: "Examples without Photos",
        link2: "/boda-diamante-ejemplo-fotos?name=Angel%20Jaimes",
      },
    ],
  },
  {
    name: "GOLD",
    imageUrl: oroImage,
    designs: [
      {
        preciceDec: 90,
        precice: 39.95,
        name: "Open invitation",
        link: "/en/wedding-auto?id=3584",
        name2: "Examples without Photos",
        link2: "/boda-oro-ejemplo-fotos",
      },
    ],
  },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

export const EjemplosBodaEn = () => {
  return (
    <div className="relative w-full min-h-screen bg-gradient-to-b from-gray-100 to-gray-200"
    style={{
      backgroundImage: `url(https://i.postimg.cc/0jMnP4H1/Quince-a-os-Sweet-16-Presentaci-n.png)`,
    }}>
      <div className="relative mx-auto overflow-x-hidden max-w-7xl">
        {/* Header Section */}
        <div className="w-full shadow-lg">
          <Slider {...sliderSettings}>
            {headerImages.map((image, index) => (
              <div key={index} className="relative h-[60vh] md:h-[70vh]">
                <img
                  src={image}
                  className="h-full w-full object-cover"
                  alt=""
                />
                <div className="absolute inset-0 bg-opacity-40 flex items-center justify-center">
                  <p className="text-2xl md:text-4xl font-serif text-white text-center px-4 py-2 bg-black bg-opacity-50 rounded-lg">
                    Take a Look at our Special Deals
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Packages Section */}
        <div className="w-full p-8 mx-auto mt-16 bg-white bg-opacity-90 rounded-lg shadow-xl">
          {packages.map((pkg, index) => (
            <div
              key={index}
              className="mb-20 flex flex-col justify-center items-center"
            >
              <h2 className="text-3xl font-serif md:text-4xl font-bold mb-8 text-center text-gray-800">
                {pkg.name}
              </h2>
              <div className="w-full md:flex md:items-center md:space-x-8">
                <img
                  src={pkg.imageUrl}
                  alt={`${pkg.name} package`}
                  className="w-full md:w-1/2 h-[50vh] mb-6 rounded-lg shadow-lg object-cover transition-transform duration-300 hover:scale-105"
                />
                <div className="md:w-1/2">
                  {pkg.designs.map((design, idx) => (
                    <div
                      key={idx}
                      className="p-6 text-center bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                    >
                      <h3 className="text-xl font-serif md:text-2xl font-semibold mb-4 text-gray-800">
                        ON SALE!
                      </h3>
                      <h4 className="text-lg md:text-xl font-semibold mb-6 text-gray-700">
                        <span className="text-red-500 mx-2 line-through">
                          ${design.preciceDec}
                        </span>
                        <span className="mx-2 text-green-600">${design.precice}</span>
                      </h4>
                      <div className="space-y-4">
                        <a
                          href={design.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block py-3 px-6 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-full hover:from-blue-600 hover:to-blue-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                        >
                          {design.name}
                        </a>
                       {/* <a
                          href={design.link2}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block py-3 px-6 bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-full hover:from-purple-600 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                        >
                          {design.name2}
                        </a> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="p-8 w-full flex flex-col items-center bg-white bg-opacity-90 rounded-lg shadow-xl mt-16">
          <h1 className="text-3xl text-center font-serif mb-6 text-gray-800">How to get your invitation</h1>
          <FaArrowDown className="text-5xl text-blue-600 animate-bounce mb-8" />
          <div className="relative w-full md:hidden" style={{ paddingBottom: "150%" }}>
            <video
              className="absolute inset-0 w-full h-full object-contain md:object-cover rounded-lg shadow-lg"
              src={videoProceso}
              controls
              autoPlay
              loop
              muted
            />
          </div>
          <div className="hidden md:block relative w-3/4 lg:w-2/3 xl:w-1/2" style={{ paddingBottom: "42.1875%" }}>
            <video
              className="absolute inset-0 w-full h-full object-contain rounded-lg shadow-lg"
              src={videoProceso}
              controls
              autoPlay
              loop
              muted
            />
          </div>
        </div> */}
        <div className="h-16"></div> {/* Spacer */}
      </div>
    </div>
  );
};
