import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { PiChurchLight } from "react-icons/pi";

export const XvMonserrat = () => {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ba0000",
    color2: "#e8d8d8",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_05/NEpckr405UR_lana-del-rey.MP3",
    imgPortada: "https://i.postimg.cc/B6tFBGJ4/IMG-7308.jpg",
    nombre: "Monserrat",
    fechaDeBoda: "31 MAYO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso:
      "Recuerda que es muy importante confirmar tu asistencia para poder hacerte llegar tu pase.",
    numeroConfirmacion: "+526563957625",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Doy gracias a Dios por darme la oportunidad de celebrar mis XV años y a mis padres por su amor incondicional.Las cosas mas bonitas de la vida siempre son mejores si las compartes con la personas que amas, por eso quiero que seas parte de este día tan especial para mi.",
    fechaNewDate: "May 31, 2024 18:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/pds8jdbs/IMG-7304.jpg",
    fotoDerecha: "https://i.postimg.cc/8c6rxBFY/IMG-7302.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "#fff",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "",
    madre: "Delia Castro Galindo",
    padre: "Rogelio Ulloa García",
    madrina: "",
    padrino: "",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Viernes, 31 de Mayo de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia Sagrado Corazón de Jesús",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/v65o4pgNWmcNE55C7",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 31 de Mayo de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "Nuvó Centro de Eventos Salón Cristal",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/tL4wyagXiEk7zeWa6",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Contar con ustedes es el mejor regalo. Pero si desean hacerme un obsequio, éste podrán realizarlo en efectivo colocándolo en el buzón de dinero que encontrarán en la entrada del salón.",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/900GtS1H/cartoon-illustration-of-dollar-money-in-envelope-free-vector-1.jpg",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/pds8jdbs/IMG-7304.jpg",
    titulo: `¡Has sido invitado a los Xv de ${datosSeccionPortada.nombre}!`,
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const images = [
    "https://i.postimg.cc/B6tFBGJ4/IMG-7308.jpg",
    "https://i.postimg.cc/pds8jdbs/IMG-7304.jpg",
    "https://i.postimg.cc/8c6rxBFY/IMG-7302.jpg",
    "https://i.postimg.cc/ThvWxLg3/IMG-7301.jpg",
    "https://i.postimg.cc/hjwdC3Gx/IMG-7303.jpg",
    "https://i.postimg.cc/wjcD6VW8/IMG-7305.jpg",
    "https://i.postimg.cc/ncmvWZBL/IMG-7307.jpg",
    "https://i.postimg.cc/FRKyCLVt/IMG-7309.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  const names = [
    { name: "Antonio Galindo", guess: "1", id: 1 },
    { name: "Héctor Galindo", guess: "1", id: 2 },
    { name: "Fam. Galindo Solís", guess: "5", id: 3 },
    { name: "Araceli Galindo", guess: "1", id: 4 },
    { name: "Enelida Galindo", guess: "1", id: 5 },
    { name: "Fam. Galindo Vargas", guess: "4", id: 6 },
    { name: "Fam. Méndez Castellón", guess: "2", id: 7 },
    { name: "Fam. Pérez Galindo", guess: "4", id: 8 },
    { name: "Alma Galindo", guess: "3", id: 9 },
    { name: "Paloma Galindo", guess: "3", id: 10 },
    { name: "Fam. Sealey Castro", guess: "3", id: 11 },
    { name: "Fam. Castro Hernández", guess: "3", id: 12 },
    { name: "Graciela Galindo", guess: "1", id: 13 },
    { name: "Carolina Rodríguez", guess: "2", id: 14 },
    { name: "Luz Maria Muñoz", guess: "2", id: 15 },
    { name: "Blanca Ramos", guess: "2", id: 16 },
    { name: "Erika Ramos", guess: "4", id: 17 },
    { name: "Irma Vázquez", guess: "2", id: 18 },
    { name: "Viridiana Madrid", guess: "4", id: 19 },
    { name: "Fam. Gasca Solís", guess: "4", id: 20 },
    { name: "Fam. Solís Ochoa", guess: "4", id: 21 },
    { name: "Sandra Vázquez", guess: "4", id: 22 },
    { name: "Fam. Neri Gómez", guess: "6", id: 23 },
    { name: "Fam. Avila Martínez", guess: "2", id: 24 },
    { name: "Rogelio Ulloa", guess: "2", id: 25 },
    { name: "Fam. González", guess: "5", id: 26 },
    { name: "Erick Ulloa", guess: "2", id: 27 },
    { name: "Elena Meza", guess: "3", id: 28 },
    { name: "Rubén Mata", guess: "2", id: 29 },
    { name: "Isabel Domínguez", guess: "2", id: 30 },
    { name: "Mireya García", guess: "2", id: 31 },
    { name: "Adán Ortíz", guess: "2", id: 32 },
    { name: "Victor Medel", guess: "4", id: 33 },
    { name: "Eduardo Castillo", guess: "3", id: 34 },
    { name: "Adrian Guerra", guess: "2", id: 35 },
    { name: "Ricardo Valenzuela", guess: "2", id: 36 },
    { name: "Flor Zubia", guess: "5", id: 37 },
    { name: "Marco Alvarado", guess: "2", id: 38 },
    { name: "Rogelio Casado", guess: "4", id: 39 },
    { name: "Óscar Galván", guess: "2", id: 40 },
    { name: "Javier Jiménez", guess: "2", id: 41 },
    { name: "Isair Orona", guess: "2", id: 42 },
    { name: "Juan Antonio Ruíz", guess: "2", id: 43 },
    { name: "Evelyn López", guess: "1", id: 44 },
    { name: "Ana Murillo", guess: "1", id: 45 },
    { name: "Sofía Aguilar", guess: "1", id: 46 },
    { name: "Fany Chavez", guess: "3", id: 47 },
    { name: "Aby Cruz", guess: "1", id: 48 },
    { name: "Valeria Pompa", guess: "2", id: 49 },
    { name: "Italia Tapia", guess: "1", id: 50 },
    { name: "Rodrigo Vizcaino", guess: "2", id: 51 },
    { name: "Alexis Moreno", guess: "1", id: 52 },
    { name: "Ángel Solís", guess: "1", id: 53 },
    { name: "Axel Beltrán", guess: "1", id: 54 },
    { name: "Cithlaly Franco", guess: "1", id: 55 },
    { name: "Maite Carrillo", guess: "1", id: 56 },
    { name: "Vianney Trejo", guess: "1", id: 57 },
    { name: "Keyla Acosta", guess: "2", id: 58 },
    { name: "Fernanda Armendariz", guess: "2", id: 59 },
    { name: "Valentina Garza", guess: "2", id: 60 },
    { name: "Lesly Camacho", guess: "2", id: 61 },
    { name: "Ximena Miranda", guess: "1", id: 62 },
    { name: "Amairany Renteria", guess: "1", id: 63 },
    { name: "Yuliana Castro", guess: "2", id: 64 },
    { name: "Ayleen Ramos", guess: "2", id: 65 },
    { name: "Paola", guess: "2", id: 66 },
    { name: "Daniel Arreola", guess: "1", id: 67 },
    { name: "David Chairez", guess: "3", id: 68 },
    { name: "Carlos Alba", guess: "1", id: 69 },
    { name: "César Cruz", guess: "1", id: 70 },
    { name: "Emiliano Ramírez", guess: "1", id: 71 },
    { name: "Andy Terrazas", guess: "2", id: 72 },
    { name: "Gael Santoyo", guess: "2", id: 73 },
    { name: "Aimee", guess: "2", id: 74 },
    { name: "Vanessa", guess: "2", id: 75 },
    { name: "Alexa", guess: "2", id: 76 },
    { name: "Liz", guess: "2", id: 77 },
    { name: "Sam", guess: "2", id: 78 },
    { name: "Luisa", guess: "2", id: 79 },
    { name: "Mariane", guess: "2", id: 80 },
    { name: "Camila", guess: "2", id: 81 },
    { name: "Angelica", guess: "2", id: 82 },
    { name: "Alberto López", guess: "2", id: 83 },
    { name: "Giovany Reyes", guess: "2", id: 84 },
    { name: "Osvaldo Guillen", guess: "2", id: 85 },
    { name: "Aslan Durán", guess: "1", id: 86 },
    { name: "Fam. Barraza García", guess: "7", id: 87 },
    { name: "Ana Ramirez", guess: "3", id: 88 },
    { name: "Fam. Alaniz Gómez", guess: "4", id: 89 },
  ];

  useEffect(() => {
    const guestId = parseInt(nameId);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} /> : ""}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPrograma.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,

            color: datosSeccionPrograma.textColor
              ? datosSeccionPrograma.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionPrograma.ceremoniaReligiosa ? (
                  <>
                    <PiChurchLight className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      CEREMONIA RELIGIOSA
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.ceremoniaReligiosa}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.recepcion ? (
                  <>
                    <FaPhotoVideo className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.recepcion}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionPrograma.baileXV ? (
                  <>
                    <GiPartyPopper className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      BAILE DE XV AÑOS
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.baileXV}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>

              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionPrograma.baile ? (
                  <>
                    <GiMeshBall className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">BAILE</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionPrograma.baile}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
