import React, { useState } from "react";
import { useField } from "formik";
import { Label } from "reactstrap";

export const ImageFondo = ({ label,ingles, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    {
      label: ingles ? "No Background Texture" : "Sin Fondo de Texturas",
      value: "",
    },
    {
      label: ingles ? "Purple Flowers Background" : "Fondo Flores Moradas",
      value:
        "https://digitalrsvp.mx/api/Images/884e3829-7d3a-4ea5-8bc8-cd03e9a1e3ea",
    },
    {
      label: ingles ? "Black Background with Stars" : "Fondo Negro con Estrellas",
      value:
        "https://digitalrsvp.mx/api/Images/81dad425-36cc-4e25-bf9b-2569eafd68ab",
    },
    {
      label: ingles ? "Mexican Background" : "Fondo Mexicano",
      value:
        "https://digitalrsvp.mx/api/Images/0fff99be-83e0-41b0-abc0-12fa29167a1c",
    },
    {
      label: ingles ? "Silver Background" : "Fondo Plateado",
      value:
        "https://digitalrsvp.mx/api/Images/76e3850a-fb7b-4621-b3f4-3cd0eb1b5cef",
    },
    {
      label: ingles ? "Golden Background" : "Fondo Dorado",
      value: "https://digitalrsvp.mx/api/Images/3a73ee2d-ffd7-4250-8a1c-5dd97d7b1264",
    },
    {
      label: ingles ? "Marbled Background" : "Fondo Marmoleado",
      value:
        "https://digitalrsvp.mx/api/Images/ac65eeb8-5eb3-4223-9ddf-0e1292e1a9c6",
    },
    {
      label: ingles ? "Golden Marbled Background" : "Fondo Marmoleado Dorado",
      value:
        "https://digitalrsvp.mx/api/Images/a33877c9-dd6e-4cc0-ba91-9b09ac59fa60",
    },
    {
      label: ingles ? "Light Marbled Background" : "Fondo Marmoleado Claro",
      value:
        "https://digitalrsvp.mx/api/Images/ebdf3678-b771-4453-bfac-bb1bb942ad76",
    },
    {
      label: ingles ? "Graduation Background" : "Fondo Graduación",
      value:
        "https://digitalrsvp.mx/api/Images/c22bf00a-9bd8-4cdc-ba94-fc7e2c57fe2f",
    },
    {
      label: ingles ? "Subtle Plant Background" : "Fondo Sutil Plantas",
      value:
        "https://digitalrsvp.mx/api/Images/ac26212c-8bcb-49dd-8355-1fd7cc07cfc5",
    },
    {
      label: ingles ? "Dark Green Background" : "Fondo Verde Oscuro",
      value:
        "https://digitalrsvp.mx/api/Images/004118d3-c57b-4dd7-9359-00824d74e82b",
    },
    {
      label: ingles ? "Purple Background with Butterflies" : "Fondo Morado con Mariposas",
      value:
        "https://digitalrsvp.mx/api/Images/6bbb5249-1fe2-4324-a189-db5f43193451	",
    },
    {
      label: ingles ? "Flower Background" : "Fondo Flores",
      value:
        "https://digitalrsvp.mx/api/Images/fdad2717-8a9a-4b6c-bdea-f0a4802cd092",
    },
    {
      label: ingles ? "Rose Background" : "Fondo Rosas",
      value:
        "https://digitalrsvp.mx/api/Images/ba928da6-dac3-4f51-bee4-c81fe53d59f3",
    },
    {
      label: ingles ? "Strawberry Background" : "Fondo Fresas",
      value: "https://digitalrsvp.mx/api/Images/8b1de41c-d820-4bd7-9df9-73545d0c6bbd",
    },
    {
      label: ingles ? "Black Background with Gold" : "Fondo Negro con Dorado",
      value:
        "https://digitalrsvp.mx/api/Images/9d7f01c9-81a4-4316-9166-cdfa72079fc4",
    },
    {
      label: ingles ? "Golden Touch Background" : "Fondo Toques Dorado",
      value:
        "https://digitalrsvp.mx/api/Images/30879c71-8071-4d03-92ce-de71697bb31e",
    },
    {
      label: ingles ? "Beach Background" : "Fondo Playa",
      value:
        "https://digitalrsvp.mx/api/Images/0f7832c4-59e3-4de1-947b-bbd6401789ec",
    },
    {
      label: ingles ? "Rose Gold Background" : "Fondo Rosa Gold",
      value:
        "https://digitalrsvp.mx/api/Images/794ad4d2-817c-487e-96d9-4c69195773bf",
    },
    {
      label: ingles ? "Red Canvas Background" : "Fondo Lona Roja",
      value:
        "https://digitalrsvp.mx/api/Images/72973984-090e-4f08-a21b-744e7df38906",
    },
    {
      label: ingles ? "Pink Marbled Background" : "Fondo Rosa Marmoleado",
      value:
        "https://digitalrsvp.mx/api/Images/05a5a3e4-8528-4c0f-840c-2588490f6bc7",
    },
    {
      label: ingles ? "Blue Marbled Background" : "Fondo Azul Marmoleado",
      value:
        "https://digitalrsvp.mx/api/Images/87c14890-53a4-4161-996a-3a579c895e17",
    },
  ];

  return (
    <div className="w-full">
      <Label>{label}</Label>
      <div className="relative">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`form-select ${
            meta.touched && meta.error ? "input-error" : ""
          }`}
        >
          {field.value ? (
            <img
              src={field.value}
              alt={ingles ? "Choose an option" : "Opción seleccionada"}
              className="w-[70%] h-[50vh] object-cover"
            />
          ) : (
            ingles ? "Choose an option" : "Seleccione una opción"
          )}
        </button>
        {isOpen && (
          <div className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
            {options.map((option) => (
              <div
                key={option.value}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  setValue(option.value);
                  setIsOpen(false);
                }}
              >
                {option.value ? (
                  <img
                    src={option.value}
                    alt={option.label}
                    className="w-30 h-20 object-cover mr-2"
                  />
                ) : (
                  ""
                )}

                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className="color-error">{meta.error}</div>
      ) : null}
    </div>
  );
};
