import React from "react";
import { FaPhotoVideo } from "react-icons/fa";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { PiChurchLight } from "react-icons/pi";

export const ProgramaXvComponent = ({ peltaColores, datosSeccionPrograma }) => {
  // Aplica el tipo de letra si está definido
  const fontFamilyStyle = {
    fontFamily:
      datosSeccionPrograma.tipoLetra ||
      "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  // Verifica si está en inglés
  const isEnglish = datosSeccionPrograma.ingles;

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionPrograma.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionPrograma.textColor || "",
        ...fontFamilyStyle, // Aplicar el tipo de letra a todo el div
      }}
      className="sm:h-auto md:h-auto flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col w-full justify-center items-center p-2">
        <h1
          className="text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
          style={fontFamilyStyle} // Aplicar el tipo de letra al h1
        >
          {isEnglish ? "Program" : datosSeccionPrograma.titulo || "Programa"}
        </h1>
        <br />
        <p
          className="text-center pt-[5vh] text-xl"
          data-aos="flip-up"
          style={fontFamilyStyle} // Aplicar el tipo de letra al p
        >
          {isEnglish
            ? "We've planned a day full of fun to enjoy to the fullest."
            : datosSeccionPrograma.descripcion ||
              "Hemos planeado un día lleno de diversión para disfrutar al máximo."}
        </p>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            {datosSeccionPrograma.ceremoniaReligiosa ? (
              <>
                {datosSeccionPrograma.ceremoniaReligiosaImg ? (
                  <img
                    src={datosSeccionPrograma.ceremoniaReligiosaImg}
                    className="w-[70px] h-[70px]"
                    alt={
                      isEnglish ? "Religious Ceremony" : "Ceremonia Religiosa"
                    }
                  />
                ) : (
                  <PiChurchLight className="text-[60px]" />
                )}

                <br />
                <p className="text-xl text-center" style={fontFamilyStyle}>
                  {isEnglish
                    ? datosSeccionPrograma.ceremoniaReligiosaTitulo ||
                      "RELIGIOUS CEREMONY"
                    : datosSeccionPrograma.ceremoniaReligiosaTitulo ||
                      "CEREMONIA RELIGIOSA"}
                </p>
                <br />
                <p
                  className="text-xl text-center w-[60%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionPrograma.ceremoniaReligiosa
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
              </>
            ) : (
              ""
            )}

            {datosSeccionPrograma.recepcion ? (
              <>
                {datosSeccionPrograma.recepcionImg ? (
                  <img
                    src={datosSeccionPrograma.recepcionImg}
                    className="w-[70px] h-[70px]"
                    alt={isEnglish ? "Reception" : "Recepción"}
                  />
                ) : (
                  <FaPhotoVideo className="text-[60px]" />
                )}
                <br />
                <p className="text-xl text-center" style={fontFamilyStyle}>
                  {isEnglish
                    ? datosSeccionPrograma.recepcionTitulo || "RECEPTION"
                    : datosSeccionPrograma.recepcionTitulo || "RECEPCIÓN"}
                </p>
                <br />
                <p
                  className="text-xl text-center w-[60%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionPrograma.recepcion
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
              </>
            ) : (
              ""
            )}

            {datosSeccionPrograma.baileXv ? (
              <>
                {datosSeccionPrograma.baileXvImg ? (
                  <img
                    src={datosSeccionPrograma.baileXvImg}
                    className="w-[70px] h-[70px]"
                    alt={isEnglish ? "XV Dance" : "Baile de XV Años"}
                  />
                ) : (
                  <GiPartyPopper className="text-[60px]" />
                )}
                <br />
                <p className="text-xl text-center" style={fontFamilyStyle}>
                  {isEnglish
                    ? datosSeccionPrograma.baileXvTitulo || "XV DANCE"
                    : datosSeccionPrograma.baileXvTitulo || "BAILE DE XV AÑOS"}
                </p>
                <br />
                <p
                  className="text-xl text-center w-[60%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionPrograma.baileXv
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
              </>
            ) : (
              ""
            )}
          </div>

          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            {datosSeccionPrograma.coctel ? (
              <>
                {datosSeccionPrograma.coctelImg ? (
                  <img
                    src={datosSeccionPrograma.coctelImg}
                    className="w-[70px] h-[70px]"
                    alt={isEnglish ? "Cocktail" : "Cóctel"}
                  />
                ) : (
                  <FaChampagneGlasses className="text-[60px]" />
                )}
                <br />
                <p className="text-xl text-center" style={fontFamilyStyle}>
                  {isEnglish
                    ? datosSeccionPrograma.coctelTitulo || "COCKTAIL"
                    : datosSeccionPrograma.coctelTitulo || "COCTEL"}
                </p>
                <br />
                <p
                  className="text-xl text-center w-[60%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionPrograma.coctel
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
              </>
            ) : (
              ""
            )}

            {datosSeccionPrograma.cena ? (
              <>
                {datosSeccionPrograma.cenaImg ? (
                  <img
                    src={datosSeccionPrograma.cenaImg}
                    className="w-[70px] h-[70px]"
                    alt={isEnglish ? "Dinner" : "Cena"}
                  />
                ) : (
                  <ImSpoonKnife className="text-[60px]" />
                )}
                <br />
                <p className="text-xl text-center" style={fontFamilyStyle}>
                  {isEnglish
                    ? datosSeccionPrograma.cenaTitulo || "DINNER"
                    : datosSeccionPrograma.cenaTitulo || "CENA"}
                </p>
                <br />
                <p
                  className="text-xl text-center w-[60%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionPrograma.cena
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                <br />
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
              </>
            ) : (
              ""
            )}

            {datosSeccionPrograma.baile ? (
              <>
                {datosSeccionPrograma.baileImg ? (
                  <img
                    src={datosSeccionPrograma.baileImg}
                    className="w-[70px] h-[70px]"
                    alt="Baile"
                  />
                ) : (
                  <GiMeshBall className="text-[60px]" />
                )}
                <br />
                <p className="text-xl text-center" style={fontFamilyStyle}>
                {isEnglish
                    ? datosSeccionPrograma.baileTitulo || "DANCE"
                    : datosSeccionPrograma.baileTitulo || "BAILE"}
                </p>
                <br />
                <p className="text-xl text-center w-[60%]" style={fontFamilyStyle}>
                  {datosSeccionPrograma.baile
                    .split("\\n")
                    .map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
              </>
            ) : ("")}
          </div>
        </div>
      </div>
    </div>
  );
};
