import React from "react";
import { GrMapLocation } from "react-icons/gr";
import { SiGooglemaps } from "react-icons/si";

export const HospedajeXvComponentD2 = ({
  peltaColores,
  datosSeccionHospedaje,
}) => {
  const fontFamilyStyle = {
    fontFamily:
      datosSeccionHospedaje.textColor ||
      "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionHospedaje.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionHospedaje.textColor || "black",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col items-center w-full px-2 py-5"
    >
      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <h1
          className="text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          Hospedaje
        </h1>
        <br />
        <p
          className="text-center pt-[5vh] text-xl"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionHospedaje.descripcion
            .split("\\n")
            .map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
        </p>
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-${
            datosSeccionHospedaje.disponibleDos ? 2 : 1
          } w-[100%] pt-[10vh]`}
        >
          {/* Hotel 1 */}
          <div
            className="h-[50vh]"
            style={{
              boxShadow: "-4px 4px 12px 0px rgba(0, 0, 0, 0.44)",
              backgroundImage: `url(${datosSeccionHospedaje.imgFondo || "https://lh3.googleusercontent.com/gps-proxy/ALd4DhHtLCvwak6q8m8le6w-tiGbU2Til2hIvcI_XPSHSUUAefls6IY3bEjUt8oaHYfUihnL3V9AS2_M-DQw7Aj91GQzYAt_-p5wOGNg0-ZaIJ9gZx0jiRJ_WRs-jkBYqHkETiXRuvEd0H3Ww74lfdfDVb1LQOHhxoDTcoGC2PskMrOn9iMkJ0ljxLlEsA=w417-h240-k-no"})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            data-aos="fade-right"
          >
            <div
            style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
            className="w-full h-full flex flex-col justify-end px-2 items-center py-4 text-white">
            <h1
              className="text-center sm:text-lg md:text-[23px]"
              style={fontFamilyStyle}
            >
              {datosSeccionHospedaje.hotel1Titulo}
            </h1>
            <br />
            {datosSeccionHospedaje.hotel1lugar ? (
              <p
                className="text-center sm:text-lg md:text-[23px] w-[80%]"
                style={fontFamilyStyle}
              >
                {datosSeccionHospedaje.hotel1lugar}
              </p>
            ) : (
              ""
            )}
            {datosSeccionHospedaje.hotel1url && (
              <a
                href={datosSeccionHospedaje.hotel1url}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-xl animate-bounce px-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                  cursor: "pointer",
                  border: "1px solid transparent",
                  padding: "3px",
                  backgroundColor: "white",
                  boxShadow: "-2px 2px 7px 0px rgba(0, 0, 0, 0.44)",
                  ...fontFamilyStyle,
                }}
              >
                <SiGooglemaps className="m-2 text-red-800" />
                <span>Cómo Llegar...</span>
              </a>
            )}
            </div>
          </div>

          {/* Hotel 2 (if applicable) */}
          {datosSeccionHospedaje.disponibleDos && (
            <div
              className="h-[50vh] sm:mt-4 md:mt-0"
              style={{
                boxShadow: "-4px 4px 12px 0px rgba(0, 0, 0, 0.44)",
                backgroundImage: `url(${datosSeccionHospedaje.imgFondo || "https://lh5.googleusercontent.com/p/AF1QipN_onj11iEaAoyH-Gj8HqQf8QcMyAq2ZzJ5wyFG=w408-h271-k-no"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
              data-aos="fade-right"
            >
                <div 
                style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                className="w-full h-full flex flex-col justify-end px-2 items-center py-4 text-white">
                    <h1
                className="text-center sm:text-lg md:text-[23px]"
                style={fontFamilyStyle}
              >
                {datosSeccionHospedaje.hotel2Titulo}
              </h1>
              <br />
              {datosSeccionHospedaje.hotel2lugar ? (
                <p
                  className="text-[23px] wcenter sm:text-lg md:text--[80%]"
                  style={fontFamilyStyle}
                >
                  {datosSeccionHospedaje.hotel2lugar}
                </p>
              ) : (
                ""
              )}

              {datosSeccionHospedaje.hotel2url && (
                <a
                  href={datosSeccionHospedaje.hotel2url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-xl animate-bounce px-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                    border: "1px solid transparent",
                    padding: "3px",
                    backgroundColor: "white",
                    boxShadow: "-2px 2px 7px 0px rgba(0, 0, 0, 0.44)",
                    ...fontFamilyStyle,
                  }}
                >
                  <SiGooglemaps className="m-2 text-red-800" />
                  <span>Cómo Llegar...</span>
                </a>
              )}
                </div>
              
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
