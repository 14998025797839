import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/maroon.mp3";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import Aos from "aos";
import { CSSTransition } from "react-transition-group";

export const BodaGabrielaYBrandon = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, Quiero informacion acerca de las reservaciones y vuelos para la boda de Gabriela y Brando`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccionConfirmacion.numeroConfirmacion
    }&text=${encodeURIComponent(message)}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  const peltaColores = {
    color1: "#EDE8DE",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDzuX2tO231_y2mate.com---Through-The-Years-Piano-Instrumental-Kenny-Roger-with-lyrics.mp3",
    textoConfirmacion: " Su boda",
    esposo: "Brando",
    esposa: "Gabriela",
    fechaDeBoda: "",
    imgPortada:
      "https://i.postimg.cc/Df6zdzNb/19b3759c-9fa5-4ecd-b476-3dd39da411a1.jpg",
    titulo: "06 de Septiembre 2024",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 10 años juntos, llegó el día en el que uniremos nuestras vidas con un lazo aún más fuerte y nos encantaría tenerte ahí.",
    fechaNewDate: "September 06, 2024 17:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/pXdL0HNc/d91d89dc-eded-46b4-89d7-eb339e661da7.jpg",
    fotoDerecha: "https://i.postimg.cc/3R2t5m29/image0-2.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion: "Queremos que seas testigo de nuestro gran dia.",
    titulo1: "Padres del Novio",
    madre: "Eduardo Orozco",
    padre: "",
    titulo2: "Padres de la Novia que desde el cielo nos acompañarán",
    madrina: "✟Patricia Puerta✟",
    padrino: "✟Hiram González✟",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Viernes, 06 de Septiembre de 2024.",
    ceremoniaHora: "5:00 p.m.",
    ceremoniaNombreLugar: "Hotel Hard Rock Riviera Maya, Q.R.",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/jzsMpKHSSTZRaJ9R7",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 06 de Septiembre de 2024.",
    recepcionHora: "6:00 p.m.",
    recepcionNombreLugar: "Hotel Hard Rock Riviera Maya, Q.R.",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/jzsMpKHSSTZRaJ9R7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Mujeres",
    messageVestimenta: "",
    messageVestimenta2: "Hombres",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 2,
    titulo: "Mesa de regalos",
    descripcion:
      "Tú presencia es nuestro mejor regalo pero si quieres obsequiarnos algo te lo agradecemos de todo corazón",
    link1: "https://www.amazon.com.mx/wedding/share/brandoygaby",
    imgLink1:
      "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/6q284h6x/Historia-de-Instagram-Lunes-Positivo-Minimalista-beige-1.png",
    link2Enable: false,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Esperamos puedan acompañarnos en este día tan especial.",
    aviso: "",
    numeroConfirmacion: "+528131385370",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/rFg4hKqJ/43d964c5-a305-4c96-92b5-dff121cd07a9.jpg",
    "https://i.postimg.cc/Df6zdzNb/19b3759c-9fa5-4ecd-b476-3dd39da411a1.jpg",
    "https://i.postimg.cc/3R2t5m29/image0-2.jpg",
    "https://i.postimg.cc/pXdL0HNc/d91d89dc-eded-46b4-89d7-eb339e661da7.jpg",
    "https://i.postimg.cc/nrmSttCC/e00fffe1-f3c0-4ddd-9d4c-970600f28337.jpg"
    // Add more image URLs as needed
  ];

  const [isOpen, setIsOpen] = useState(true);

  const [messageWhats, setMessageWhats] = useState("");

  const ContadorBodaComponent = ({ peltaColores, datosSeccionContador }) => {
    const targetDate = new Date(
      datosSeccionContador.fechaNewDate || ""
    ).getTime();
    const [timeRemaining, setTimeRemaining] = useState(
      calculateTimeRemaining()
    );

    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }

    useEffect(() => {
      Aos.init({ duration: 2000 });
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);

      return () => clearInterval(interval);
    }, []);
    return (
      <div
        style={{
          backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color: datosSeccionContador.textColor
            ? datosSeccionContador.textColor
            : "",
        }}
        className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col pt-[10vh] w-full items-center">
          <h1
            className="font-serif  sm:text-[52px] md:text-[92px] "
            data-aos="flip-up"
          >
            {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccionContador.descipcion.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>
          <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "70vh", // Ajusta la altura del contenedor
                display: "flex",
                backgroundPosition: "center center",
                width: "90%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded-[30%]"
              data-aos="fade-right"
            ></div>
            {datosSeccionContador.contador ? (
              <div
                className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
                data-aos="fade-up"
                style={{
                  border: "15px solid transparent",
                  padding: "15px",
                  borderImage: datosSeccionContador.marcoEnable
                    ? "url('https://i.postimg.cc/gkBHH4Z5/marco-rosa-2.png')"
                    : "",
                  borderImageSlice: "46",
                  borderImageWidth: "round",
                }}
              >
                <div className="flex flex-col m-2 justify-center items-center">
                  <p className="font-serif sm:text-xl md:text-2xl">
                    {timeRemaining.days}
                  </p>
                  <p className="font-serif sm:text-xl md:text-2xl">Días</p>
                </div>
                <div className="flex flex-col m-2 justify-center items-center">
                  <p className="font-serif sm:text-xl md:text-2xl">
                    {timeRemaining.hours}
                  </p>
                  <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
                </div>
                <div className="flex flex-col m-2 justify-center items-center">
                  <p className="font-serif sm:text-xl md:text-2xl">
                    {timeRemaining.minutes}
                  </p>
                  <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
                </div>
                <div className="flex flex-col m-2 justify-center items-center">
                  <p className="font-serif sm:text-xl md:text-2xl">
                    {timeRemaining.seconds}
                  </p>
                  <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div
              style={{
                backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "70vh", // Ajusta la altura del contenedor
                display: "flex",
                backgroundPosition: "center center",
                width: "90%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded-full sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
              data-aos="fade-left"
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const VestimentaXvComponent = ({ peltaColores, datosSeccionVestimenta }) => {
    const [isIOS, setIsIOS] = useState(false);
    const textoConSaltosDeLinea = "Primera línea\\nSegunda línea\\nTercera línea";

    useEffect(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    }, []);
    return (
      <div
        style={{
          backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS ? "local" : "fixed",

          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          {/* <p
          className=" text-center pt-[5vh] font-serif text-xl"
          data-aos="flip-up"
        >
          {datosSeccionVestimenta.vestimenta
            ? datosSeccionVestimenta.vestimenta
            : "FORMAL"}
        </p> */}
          <br />
          {datosSeccionVestimenta.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
              {datosSeccionVestimenta.messageVestimenta
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          ) : (
            ""
          )}

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-left"
            >
              <p
                className=" text-center pt-[5vh] font-serif text-3xl"
                data-aos="flip-up"
              >
                {datosSeccionVestimenta.vestimenta}
              </p>

              <img
                src={
                  datosSeccionVestimenta.urlVestimentaMujer ||
                  "https://i.postimg.cc/c4MjL7qb/cfb-117345-removebg-preview.png"
                }
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-1 text-center">
                Vestidos colores cálidos o colores pastel
              </p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-right"
            >
              <p
                className=" text-center pt-[5vh] font-serif text-3xl"
                data-aos="flip-up"
              >
                {datosSeccionVestimenta.messageVestimenta2}
              </p>
              <img
                src={
                  datosSeccionVestimenta.urlVestimentaHombre ||
                  "https://i.postimg.cc/XvbRMrPF/Cream-Brown-Minimalist-Simple-Birthday-Invitation.png"
                }
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-1">Pantalon Beige/Guayabera</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
        audioFondo={audioFondo}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      <div
        style={{
          backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: datosSeccionConfirmacion.textColor
            ? datosSeccionConfirmacion.textColor
            : "",
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
      >
        <h1
          className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
          data-aos="flip-up"
        >
          {datosSeccionConfirmacion.tituloConfirmacion
            .split("\\n")
            .map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
        </h1>
        <br />
        <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
          {datosSeccionConfirmacion.aviso.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))}
        </p>
        <br />
        <br />
        <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
          {images?.map((imageUrl, index) => (
            <div data-aos="fade-down-right" className="rolImg" key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-auto rounded-xl max-h-[60vh]"
              />
            </div>
          ))}
        </Slider>
        <br />
        <br />
        {datosSeccionConfirmacion.numeroConfirmacion ? (
          <div
            data-aos="fade-down-left"
            className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
          >
            <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
              {datosSeccionConfirmacion.textWhatsApp ||
                "Para reservar habitaciones o vuelos. Contactar aqui"}{" "}
              <FaWhatsapp className="text-green-600 ml-2" />
            </h2>
            <form onSubmit={handleSubmit}>
              <br />
              <button
                type="submit"
                className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                style={{alignContent: "center"}}
              >
                Contactar Agencia
              </button>
            </form>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
