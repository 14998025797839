import React from 'react'

export const Padres = ({peltaColores,datosSeccion3}) => {
  return (
    <div
        style={{
          backgroundImage: `url(${datosSeccion3.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color:datosSeccion3.textColor ? datosSeccion3.textColor : '' 
        }}
        className="sm:h-auto md:h-[100vh] p-2 flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccion3.titulo}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccion3.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl">{datosSeccion3.tituloIzquierda}</p>
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposaMadre}
              </p>
              {
                datosSeccion3.esposaPadre?(
                  
              <p className="font-serif text-center text-xl">&</p>
                ):
                ''
              }
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposaPadre}
              </p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl">{datosSeccion3.tituloDerecha}</p>
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposoMadre}
              </p>
              {
                datosSeccion3.esposoPadre?(
                  
              <p className="font-serif text-center text-xl">&</p>
                ):
                ''
              }
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposoPadre}
              </p>
            </div>
          </div>
        </div>
      </div>
  )
}
