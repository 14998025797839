import React, { useEffect, useState } from "react";

export const VestimentaXvComponent = ({
  peltaColores,
  datosSeccionVestimenta,
}) => {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  // Definimos el estilo de la fuente
  const fontFamilyStyle = { fontFamily: datosSeccionVestimenta.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  const traducirTexto = (español, ingles) => datosSeccionVestimenta.ingles ? ingles : español;

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
        backgroundColor: datosSeccionVestimenta.color1,
        backgroundAttachment: isIOS ? "local" : "fixed",
        color: datosSeccionVestimenta.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
    >
      <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {traducirTexto("Código de vestimenta", "Dress Code")}
        </h1>
        <br />
        <p
          className="text-center pt-[5vh] font-serif text-xl"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionVestimenta.vestimenta || traducirTexto("FORMAL", "FORMAL")}
        </p>
        <br />
        {datosSeccionVestimenta.messageVestimenta ? (
          <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]" style={fontFamilyStyle}>
            {datosSeccionVestimenta.messageVestimenta
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </p>
        ) : (
          ""
        )}

        {datosSeccionVestimenta.urlVestimentaMujer === "Nada" ? (
          ""
        ) : (
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-left"
            >
              <img
                src={
                  datosSeccionVestimenta.urlVestimentaMujer ||
                  "https://i.postimg.cc/NMPR4p2z/2.png"
                }
                className="h-[30vh]"
                alt={traducirTexto("Vestimenta Mujer", "Women's Attire")}
              />
              <br />
              <p className="font-serif text-2xl" style={fontFamilyStyle}>
                {traducirTexto("Mujeres", "Women")}
              </p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-right"
            >
              <img
                src={
                  datosSeccionVestimenta.urlVestimentaHombre ||
                  "https://i.postimg.cc/d3krSr8t/3.png"
                }
                className="h-[30vh]"
                alt={traducirTexto("Vestimenta Hombre", "Men's Attire")}
              />
              <br />
              <p className="font-serif text-2xl" style={fontFamilyStyle}>
                {traducirTexto("Hombres", "Men")}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
