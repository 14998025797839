import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/maroon.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaEjemploPortada2 = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a nuestra boda',
      esposo: "Carlos",
      esposa: "Gina",
      fechaDeBoda: "12 DIC, 2024",
      fechaNewDate: "December 12, 2024 00:00:00 GMT+00:00",
      imgPortada: "https://cdn0.bodas.com.mx/article-vendor/3443/3_2/960/jpg/analois-photoshoot-0055_5_193443-164815363528699.jpeg",
      numeroNovia: "4491516931",
      numeroNovio: "4491516931",
      novios:true,
    };
   
    const peltaColores = {
      color1: "#e7e3e3",
      color2: "#f8f2f2",
    };
  
    const datosSeccion2 = {
      titulo:'¡Nos Casamos!',
      descipcion:
        "Después de 4 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
      fechaNewDate: "December 12, 2024 00:00:00 GMT+00:00",
      fotoIzquierda: "https://miboda.love/demo4/img/novia.jpg",
      fotoDerecha: "https://miboda.love/demo4/img/novio.jpg",
    };
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a la boda de Jimena y Antonio!',
      entradasText:'disfrutar nuestro dia especial'
    }
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor y apoyo de nuestros padres!",
      descipcion:
        "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
      tituloIzquierda:'Padres de la novia',
      tituloDerecha:'Padres del novio',
      esposaMadre: "Amparo Martha Cuellar González",
      esposaPadre: "Víctor Eduardo Cabrera Solana",
      esposoMadre: "Guadalupe Victoria Gómez",
      esposoPadre: "Eduardo Jaimes Zapata",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 12 de Diciembre de 2024.",
      ceremoniaHora: "7:00 p.m.",
      ceremoniaNombreLugar: "Templo del Señor del Encino",
      ceremoniaLugarMap: "Jardin, Barrio del Encino, 20240 Aguascalientes",
      ceremoniaUrl: "https://maps.app.goo.gl/3Pbq9J7utP79GfLD9",
      recepcionFecha: "Sábado, 12 de Diciembre de 2024.",
      recepcionHora: "8:00 p.m.",
      recepcionNombreLugar: "Lago del Marqués",
      recepcionLugarMap:
        "Hacienda Nueva, San Ignacio, 20313 Aguascalientes, Ags.",
      recepcionUrl: "https://maps.app.goo.gl/tvvBqqmLXgrN7XaY6",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
      ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
      cena: "Disfruta de una deliciosa cena",
      recepcion: "Tómate fotos con nosotros",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
    };
  
    const datosSeccion7 = {
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    };
  
    const datosSeccion8 = {
      descripcion:
        "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
      linkAmazon:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      linkLiverpool:
        "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    };
  
    const imagenesFondo ={
      fondoInvitados:'url("https://img.freepik.com/fotos-premium/hermosa-ceremonia-boda-junto-playa-al-atardecer-altar-magico-sinfonia-naturaleza_878783-14772.jpg"',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
    }
  
    const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
    const [pases, setPases] = useState(0);
  
    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const difference = targetDate - now;
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
  
    const images = [
      "https://cdn0.bodas.com.mx/article-vendor/3443/3_2/960/jpg/analois-photoshoot-0055_5_193443-164815363528699.jpeg",
      "https://elolivar.es/olivar-content/uploads/2023/04/oficiante-de-bodas.png",
      "https://cnnespanol.cnn.com/wp-content/uploads/2023/06/wedding-trends-restricted-e1685615285359.jpeg?quality=100&strip=info",
      "https://images.ecestaticos.com/LceL6lnXGvDCBsnDEjupizBTGuA=/0x0:2272x1515/1200x900/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2Fe52%2Fd19%2Fcfe%2Fe52d19cfe44da0f12a62bc916ac069b1.jpg",
      // Add more image URLs as needed
    ];
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      cssEase: "linear",
    };
  
    const names = [
      { name: "Angel Jaimes", guess: "2" },
      
  ];
  
    
  
    const [messageWhats, setMessageWhats] = useState("");
    const handlePlayButtonClick = () => {
        setIsMusicPlaying(!isMusicPlaying);
      };
  
    useEffect(() => {
      console.log(nameId, numPases);
      setPases(numPases);
  
      const findName = names.find((e) => e.name === nameId);
  
      if (findName) {
        console.log(findName.name);
  
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
      } else {
        console.log("Nombre no encontrado");
        window.location.href = "/";
      }
  
      AOS.init({ duration: 2000 });
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const audioElement = document.getElementById('backgroundMusic');
        if (isMusicPlaying) {
          audioElement.play();
        } else {
          audioElement.pause();
          audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
        }
      }, [isMusicPlaying]);
  
    
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">

<div className="fixed top-5 right-0 m-4">
<audio
          id="backgroundMusic"
          style={{ display: 'none' }}
          autoPlay
          loop
          muted={!isMusicPlaying}
        >
          <source src={audioFondo} type="audio/mpeg" />
          {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
        </audio>
          <button
            onClick={handlePlayButtonClick}
            className=" px-4 text-3xl py-2 rounded"
          >
            {isMusicPlaying ? "🔊" : "🔇"}
          </button>
    </div>

<div
        style={{
          backgroundImage:
            'url("https://cdn0.bodas.com.mx/article/3583/3_2/960/jpg/53853-boda.jpeg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e7e3e3",
        }}
        className="flex flex-col items-center sm:h-screen w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <h2 data-aos="fade-up" className="text-white md:text-[7vh] sm:text-[5vh] mt-[10%] text-center txt-pre">
            NUESTRA BODA
          </h2>
          <br />
          <br />
          <div className="flex items-center">
            <p data-aos="fade-up" className="text-white md:text-[20vh] sm:text-[12vh] sm:p-2 md:p-10 txt-pre">
              G
            </p>
            <div className="h-full flex items-start justify-start p-2">
              <p className="text-white txt-orizontal txt-pre"> {datosSeccion1.esposa}</p>
            </div>
            <div className="sm:h-[40vh] md:h-full w-2 bg-slate-50" />
            <div className="h-full flex items-end justify-start p-2">
              <p className="text-white txt-orizontal txt-pre"> {datosSeccion1.esposo}</p>
            </div>
            <p data-aos="fade-up" className="text-white md:text-[20vh] sm:text-[12vh] sm:p-2 md:p-10 txt-pre">
              C
            </p>
          </div>
          <br />
          <br />
          <p data-aos="fade-up" className="text-white md:text-[5vh] sm:text-[3vh] text-center txt-pre">
            29 / SEP / 2024
          </p>
        </div>
      </div>
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Invitados
          NameInvitation={NameInvitation}
          numGuess={numGuess}
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
          datosInvitados={datosInvitados}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
        
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
  <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
          }}
          className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Ubicaciones
            </h1>
            <br />
  
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
              <div
                className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                <div
                  style={{
                    backgroundImage:
                    'url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/P%C3%B3rtico_del_Templo_del_Se%C3%B1or_del_Encino%2C_Aguascalientes%2C_Ags..JPG/1200px-P%C3%B3rtico_del_Templo_del_Se%C3%B1or_del_Encino%2C_Aguascalientes%2C_Ags..JPG")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.544796121481!2d-102.29306919999999!3d21.875087300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee796b62a955%3A0x6f40669481f287ec!2sTemplo%20del%20Se%C3%B1or%20del%20Encino!5e0!3m2!1ses-419!2smx!4v1708319635105!5m2!1ses-419!2smx"
                    width="100%"
                    height="50%"
                    className="fixed"
                  ></iframe>
                  <a
                    href={datosSeccion4.ceremoniaUrl}
                    class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Mapa de la Ceremonia
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              </div>
              <div
                className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                 <div
                  style={{
                    backgroundImage:
                    'url("https://lh3.googleusercontent.com/p/AF1QipONJ5sUXCnKhS9Ard5Bdanz5CKPn9B1G4T5B4P2=w1080-h608-p-no-v0")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.978330627437!2d-102.35711590000001!3d21.896910200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429e91a7cb08e6b%3A0xbc87e841133aa4ba!2sLago%20del%20Marqu%C3%A9s!5e0!3m2!1ses-419!2smx!4v1708319702363!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>
  
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
        <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <PiChurchLight className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">
                  CEREMONIA RELIGIOSA
                </p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.ceremoniaReligiosa}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <GiBigDiamondRing className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">CEREMONIA CIVIL</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.ceremoniaCivil}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <ImSpoonKnife className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">CENA</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.cena}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
              </div>
  
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <FaPhotoVideo className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.recepcion}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <FaChampagneGlasses className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">CÓCTEL</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.coctel}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <GiMeshBall className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">BAILE</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.baile}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
              </div>
            </div>
          </div>
        </div>
  
        <Hospedaje
          peltaColores={peltaColores}
          datosSeccion7={datosSeccion7}
        />
  
        <Regalos
          peltaColores={peltaColores}
          datosSeccion8={datosSeccion8}
        />
  
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
