import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { LugarfiestaXvComponent } from "../../../components/ComponentsXv/LugarFiestaXvComponent";
import { ContadorInfantilComponent } from "../../../components/ComponentsXv/ContadorEventoInfantilComponent";
import { LuPartyPopper } from "react-icons/lu";

export const CumpleElizabeth = () => {
  const peltaColores = {
    color1: "#ffffff",
    color2: "#fc5bfc",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEbavGTIY8s_Y2meta.app---A%C3%BAn-Es-Joven-Se%C3%B1ora-128-kbps-.mp3",
    imgPortada:
      "https://i.postimg.cc/T3HZJ11C/888fe3f5-5584-40b3-b873-3fb7a3533411.jpg",
    nombre: "Elizabeth Gutiérrez",
    fechaDeBoda: "15 JUNIO, 2024",
    titulo:
      "Te invito a mi gran cumpleaños, es un día muy especial para mí y espero contar con tu presencia.",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Esperamos nos puedan confirmar su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "También estaremos celebrando nuestro matrimonio Rubén Áviña y Elizabeth Gutiérrez",
    numeroConfirmacion: "+526564447129",
    textoConfirmacion: "al cumpleaños. ",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mi Cumpleaños!",
    descipcion:
      "También estaremos celebrando el matrimonio de Rubén Áviña y Elizabeth Gutiérrez",
    fechaNewDate: "June 15, 2024 20:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/wMYw3kHG/IMG-20230106-WA0012.jpg",
    fotoDerecha: "https://i.postimg.cc/br8Mw2VN/IMG-20201216-WA0000.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion: "Únete a nosotros para ser testigo de mi bautizo.",
    tituloIzquierda: "Padres de la Bautizada",
    tituloDerecha: "Padrinos de la Bautizada",
    madre: "Magdalena Vázquez Prieto",
    padre: "Adolfo Lira González",
    madrina: "Rosio Garibay Cervantes",
    padrino: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaFecha: "Sábado, 15 de Junio de 2024.",
    ceremoniaHora: "8:00 p.m.",
    ceremoniaNombreLugar: "Victoria recepción",
    ceremoniaLugarMap: "Av 16 de septiembre #2474",
    ceremoniaUrl: "https://maps.app.goo.gl/6ZzwA8RKzqc7R53B7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Lluvia de sobres",
    descripcion:
      "Habra buzón de dinero",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/T3HZJ11C/888fe3f5-5584-40b3-b873-3fb7a3533411.jpg",
    "https://i.postimg.cc/NfsShmrd/IMG-20170923-085907571.jpg",
    "https://i.postimg.cc/br8Mw2VN/IMG-20201216-WA0000.jpg",
    "https://i.postimg.cc/KYkCqbgF/IMG-20210514-WA0004.jpg",
    "https://i.postimg.cc/dVDf3F6d/IMG-20171231-194234682.jpg",
    "https://i.postimg.cc/Y03PGqTN/IMG-20180513-113230989.jpg",
    "https://i.postimg.cc/BZckwMt8/IMG-20220916-184326.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionCeremonia.textColor || "",
          }}
          className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionCeremonia.dobleEnable ? "1" : "2"
              } w-[80%] pt-[10vh]`}
            >
              {datosSeccionCeremonia.dobleEnable ? (
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <div className="flex">
                    <LuPartyPopper className="text-[80px]" />
                  </div>
                  <br />
                  <p className="font-serif text-2xl">Recepción</p>
                  <br />
                  <hr className="bg-amber-700 h-2 w-[80%]" />
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionCeremonia.ceremoniaFecha}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaHora}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaNombreLugar}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.ceremoniaLugarMap}
                  </p>
                  <br />
                  <a
                    href={datosSeccionCeremonia.ceremoniaUrl}
                    class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Ver mapa!
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
