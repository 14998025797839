import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./cancionXVmonserrat.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvEjemploSinFoto = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: "a los XV",
    nombre: "Monserrat Lira",
    fechaDeBoda: "23 DICIEMBRE, 2024",
    fechaNewDate: "March 23, 2024 18:00:00 GMT+00:00",
    imgPortada:
    "https://i.postimg.cc/BbFjyFfF/Beauty-Flowers-Watercolor-Background-Instagram-Story.png",
    numeroNovia: "4491516931",
    numeroNovio: "4491516931",
  };

  const peltaColores = {
    color1: "#fff5ff",
    color2: "#fffbff",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a los XV años de Monserrat Lira!",
    entradasText: "disfrutar mis XV años",
  };

  const datosSeccion2 = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí Y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
    fechaNewDate: "December 23, 2024 18:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/R0ZgwSwf/C53-E03-B8-FD20-460-C-9876-0-F5-E103-DAA56.png",
    fotoDerecha: "https://i.postimg.cc/xdptRwPZ/2-F5222-D3-C9-B3-4837-B1-EF-64-FDE38584-D6.png",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    esposaMadre: "Magdalena Vázquez Prieto",
    esposaPadre: "Adolfo Lira González",
    esposoMadre: "Rosio Garibay Cervantes",
    esposoPadre: "Juan Carlos Lira Vázquez",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 23 de Marzo de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia del Divino Salvador",
    ceremoniaLugarMap:
      "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
    ceremoniaUrl:
      "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
    recepcionFecha: "Sábado, 23 de Marzo de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "El Herradero Jardín y Eventos",
    recepcionLugarMap:
      "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
    recepcionUrl:
      "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "6:00 pm ceremonia religiosa ",
    baileXV: "8:30 pm Baile de XV años",
    cena: "9:30 Disfruta de una deliciosa cena",
    recepcion: "8:00 pm recepción ",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "10:00 pm Sácale brilló a la pista 💃🏻🕺🏽",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirme escoger mi regalo.",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const images = [
    // Add more image URLs as needed
  ];

  const imagenesFondo = {
    fondoInvitados:
      'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalXvCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccion1}
        peltaColores={peltaColores}
      />
      <Portada datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <Vestimenta peltaColores={peltaColores} imagenesFondo={imagenesFondo} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiPartyPopper className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE DE XV AÑOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baileXV}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>
      
      <Regalos peltaColores={peltaColores} datosSeccion8={datosSeccion8} />

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
