import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import ModalCard from "../../../components/Modals/ModalCard";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";

export const BodaCinthyaYJonathan = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEifitnud7p_Y2meta.app---INVITACI%C3%93N-VIRTUAL-INTERACTIVA-PARA-BODA-2024---WEDDING-INVITATION-MERIAN-INVITACIONES-DIGITALES-128-kbps-.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Jonathan",
    esposa: "Cinthya",
    fechaDeBoda: "30 NOVIEMBRE, 2024",
    imgPortada:
      "https://i.postimg.cc/3wqK68dF/Whats-App-Image-2024-05-05-at-4-16-57-PM.jpg",
    titulo: "",
  };

  const peltaColores = {
    color1: "#ffeee8",
    color2: "#ffffff",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Amamos y adoramos a sus hijos, pero creemos que necesitan una noche libre para ustedes, queremos que la pasen genial al igual que nosotros. \\n\\n Respetuosamente \\n No niños",
    aviso: "",
    numeroConfirmacion: "+526121110762",
    textoConfirmacion: "a la Boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Las mejores cosas de la vida son aún más fantásticas, cuando son compartidas con personas tan maravillosas como tú, depues de una relacion de 3 años, hemos decidido caminar de la mano para siempre. Por eso queremos que seas parte de este dia tan importante para nosotros.",
    fechaNewDate: "November 30, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/HWXpZCHb/Whats-App-Image-2024-05-05-at-4-16-54-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/pdrWqhXd/Whats-App-Image-2024-05-05-at-4-16-57-PM-2.jpg",
  };
  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Las mejores cosas de la vida son aún más fantásticas, cuando son compartidas con personas tan maravillosas como tú, después de una relacion de 3 años, hemos decidido caminar de la mano para siempre.",
    fechaNewDate: "November 30, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/HWXpZCHb/Whats-App-Image-2024-05-05-at-4-16-54-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/pdrWqhXd/Whats-App-Image-2024-05-05-at-4-16-57-PM-2.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "#000000",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };
  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con la bendición de dios y de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Novia",
    tituloDerecha: "Padres del Novio",
    madre: "Guadalupe Rosselliz Ceseña",
    padre: "Ramon Meza Apodaca",
    madrina: "Elsa Ramirez Osorio",
    padrino: "Martin López Villavicencio",
    imgFondo: "",
    textColor: "#000000",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 30 de Noviembre de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Iglesia Santa Bárbara",
    ceremoniaLugarMap: "Álvaro Obregón 20, Centro, 23920 Santa Rosalía, B.C.S.",
    ceremoniaUrl: "https://maps.app.goo.gl/w2CsaKqnbYBNesta8",
    recepcionFecha: "Sábado, 30 de Noviembre de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Salón Mutualista Progreso",
    recepcionLugarMap: "ÁC. 6 4, Centro, 23920 Centro, B.C.S.",
    recepcionUrl: "https://maps.app.goo.gl/G8T89qBfqVopCcuc6",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Hotel El Morro",
    hotel1lugar:
      "Carr. Transpeninsular, Costa Azul, 23920 Santa Rosalía, B.C.S.",
    hotel1url: "https://maps.app.goo.gl/2vX1ojBZ2aF6QJ2M7",
    hotel2Titulo: "Hotel del Real",
    hotel2lugar: "Av. Manuel F. Montoya 7, Centro, 23920 Santa Rosalía, B.C.S.",
    hotel2url: "https://maps.app.goo.gl/jLZNDV7VSC4gEuCdA",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Hotel El Morro",
    hotel1lugar:
      "Carr. Transpeninsular, Costa Azul, 23920 Santa Rosalía, B.C.S.",
    hotel1url: "https://maps.app.goo.gl/2vX1ojBZ2aF6QJ2M7",
    hotel2Titulo: "Hotel del Real",
    hotel2lugar: "Av. Manuel F. Montoya 7, Centro, 23920 Santa Rosalía, B.C.S.",
    hotel2url: "https://maps.app.goo.gl/jLZNDV7VSC4gEuCdA",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccion8 = {
    descripcion:
      "Para nosotros, su presencia es muy valiosa e importante, pero si desean llevar un presente, sería dinero en efectivo o una lluvia de sobres.",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Luvia de sobres",
    descripcion:
      "Tu presencia es lo más importante para nosotros, pero si algo nos quieres regalar en EFECTIVO lo sabremos apreciar. \\n ¡Gracias!",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "#000000",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/3wqK68dF/Whats-App-Image-2024-05-05-at-4-16-57-PM.jpg",
    "https://i.postimg.cc/HWXpZCHb/Whats-App-Image-2024-05-05-at-4-16-54-PM.jpg",
    "https://i.postimg.cc/pdrWqhXd/Whats-App-Image-2024-05-05-at-4-16-57-PM-2.jpg",
    "https://i.postimg.cc/wM89qw5t/Whats-App-Image-2024-05-05-at-4-16-55-PM.jpg",
    "https://i.postimg.cc/5NJfDkNT/Whats-App-Image-2024-05-05-at-4-16-57-PM-1.jpg",
    "https://i.postimg.cc/y8v6mXK5/Whats-App-Image-2024-05-05-at-4-16-58-PM.jpg",
    "https://i.postimg.cc/5yBb68ps/Whats-App-Image-2024-05-05-at-4-16-59-PM.jpg",
    "https://i.postimg.cc/RFnMxkt5/Whats-App-Image-2024-05-05-at-4-23-34-PM.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />

      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      <ContadorXvComponent
        peltaColores={peltaColores}
        datosSeccionContador={datosSeccionContador}
      />

      <PadresXvComponent
        peltaColores={peltaColores}
        datosSeccionPadres={datosSeccionPadres}
      />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.postimg.cc/W1Tb9qYY/iglesia-Santa-Barbara.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3544.2354565284045!2d-112.26847349715484!3d27.337106407171625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x81345fbc03ca8a85%3A0x97a0cb25c95e40bc!2sParroquia%20de%20Santa%20B%C3%A1rbara%20Doncella!5e0!3m2!1ses-419!2smx!4v1715107239089!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.postimg.cc/Hsjb6JZJ/Whats-App-Image-2024-05-08-at-21-43-30-e6f67691.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3544.2779422322674!2d-112.27086109999999!3d27.3357778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDIwJzA4LjgiTiAxMTLCsDE2JzE1LjEiVw!5e0!3m2!1ses-419!2smx!4v1715225273907!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VestimentaXvComponent
        peltaColores={peltaColores}
        datosSeccionVestimenta={datosSeccionVestimenta}
      />

      <HospedajeXvComponent
        peltaColores={peltaColores}
        datosSeccionHospedaje={datosSeccionHospedaje}
      />

      <RegalosXvComponent
        peltaColores={peltaColores}
        datosSeccionRegalos={datosSeccionRegalos}
      />

      <ConfirmacionXvComponent
        peltaColores={peltaColores}
        images={images}
        datosSeccionConfirmacion={datosSeccionConfirmacion}
      />
    </div>
  );
};
