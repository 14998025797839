import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./cancionXVmonserrat.mp3";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvMariamyMariel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const peltaColores = {
    color1: "#d9c9fe",
    color2: "#f8f2f2",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_04/NDzrEz1i0m1_ImagineCover.mp3",
    imgPortada: "https://i.postimg.cc/DwvwVtYH/IMG-6192.jpg",
    nombre: "Mariam y Mariel",
    fechaDeBoda: "25 MAYO, 2024",
    titulo: "NO FALTES A NUESTROS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion: "",
    aviso: "",
    numeroConfirmacion: "",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nuestros XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le damos las gracias a Dios por habernos permitido estar aquí Y a nuestros padres por darnos el apoyo para realizar el sueño de toda quinceañera. \\nHoy dejaremos de ser unas niñas para comenzar una nueva etapa de nuestra vida y te invitamos a que compartas este día tan espacial para nosotras.",
    fechaNewDate: "May 25, 2024 15:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/tgfqx7CG/IMG_6267.jpg",
    fotoDerecha: "https://i.postimg.cc/SsBxwRwf/IMG_6142.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    tituloIzquierda: "Padres de las Quinceañeras",
    tituloDerecha: "",
    madre: "CLAUDIA",
    padre: "BRAULIO ",
    madrina: "",
    padrino: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 18 de Mayo de 2024.",
    ceremoniaHora: "12:00 p.m.",
    ceremoniaNombreLugar: "SANTUARIO DE LA VIRGEN DE JUQUILA",
    ceremoniaLugarMap: "Revolucion, Grande, 71900 Santa Catarina Juquila, Oax.",
    ceremoniaUrl: "https://maps.app.goo.gl/9cvQ9qbL3VLqcJtz9",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 25 de Mayo de 2024.",
    recepcionHora: "3:00 p.m.",
    recepcionNombreLugar: "LAS PENCAS",
    recepcionLugarMap:
      "C. 6 Nte. 13, El Calvario, Centro, 75487 Tecamachalco, Pue.",
    recepcionUrl: "https://maps.app.goo.gl/bjSTPjaCwYXYEJwu7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Vestimenta Casual",
    messageVestimenta: "",
    urlVestimentaMujer: "a",
    urlVestimentaHombre: "a",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "",
    cena: "",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/DwvwVtYH/IMG-6192.jpg ",
    "https://i.postimg.cc/LX1HBRnY/IMG-6222.jpg",
    "https://i.postimg.cc/YSj20Q7x/IMG-6214.jpg",
    "https://i.postimg.cc/jdrjcW6s/IMG-6180.jpg",
    "https://i.postimg.cc/Y0BtdVWX/IMG-6235.jpg",
    "https://i.postimg.cc/7YMZWxXS/IMG-6171.jpg",
    // Add more image URLs as needed
  ];

  const VestimentaXvComponent = ({ peltaColores, datosSeccionVestimenta }) => {
    const [isIOS, setIsIOS] = useState(false);
    const textoConSaltosDeLinea = "Primera línea\\nSegunda línea\\nTercera línea";

    useEffect(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    }, []);
    return (
      <div
        style={{
          backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS ? "local" : "fixed",

          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccionVestimenta.vestimenta
              ? datosSeccionVestimenta.vestimenta
              : "FORMAL"}
          </p>
          <br />
          {datosSeccionVestimenta.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
              {datosSeccionVestimenta.messageVestimenta
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
          peltaColores={peltaColores}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
