import React from "react";
import "./InvitacionesXv.css"; // Asegúrate de importar el archivo CSS

export const InvitacionesXv = () => {
  return (
    <div className="w-full h-auto">
      <div className="w-full p-4 justify-center flex-col bg-slate-500 bg-[url('https://i.postimg.cc/4xcQNQGg/Black-Minimalist-Diamond-Instagram-Post.png')] items-center flex min-h-screen">
        <h1 className="m-4 text-white">INVITACIONES DIAMANTE</h1>
        <div className="w-full sm:flex-col md:grid md:grid-cols-3">
          <a href="/XV-auto-diamante?idInvitado=1183">
          <div className="w-full p-2 h-[50vh] flex justify-center items-center">
            <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
              <img
                src="https://i.postimg.cc/NMTCHvmd/a.jpg"
                className="h-full w-[80%] rounded-lg pulsate"
                alt=""
              />
              <div className="overlay">
                <div className="text">Ver más...</div>
              </div>
            </div>
          </div>
          </a>
          <a href="/XV-auto-diamante?idInvitado=1060">
            <div className="w-full p-2 h-[50vh] flex justify-center items-center">
              <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
                <img
                  src="https://i.postimg.cc/NMXKRpNs/15A2880.jpg"
                  className="h-full w-[80%] rounded-lg pulsate"
                  alt=""
                />
                <div className="overlay">
                  <div className="text">Ver más...</div>
                </div>
              </div>
            </div>
          </a>
          <div className="w-full p-2 h-[50vh] flex justify-center items-center">
            <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
              <img
                src="https://i.postimg.cc/Jn11h8yW/pro-FKp-ARDQL.jpg"
                className="h-full w-[80%] rounded-lg pulsate"
                alt=""
              />
              <div className="overlay">
                <div className="text">Ver más...</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-4 justify-center flex-col bg-slate-500 bg-cover bg-no-repeat bg-[url('https://i.postimg.cc/T1bLVGcH/Golden-Modern-Certificate-800-x-2000-px.png')] items-center flex min-h-screen">
        <h1 className="m-4 text-white">INVITACIONES ORO</h1>
        <div className="w-full sm:flex-col md:grid md:grid-cols-3">
          <div className="w-full p-2 h-[50vh] flex justify-center items-center">
            <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
              <img
                src="https://i.postimg.cc/NMTCHvmd/a.jpg"
                className="h-full w-[80%] rounded-lg pulsate"
                alt=""
              />
              <div className="overlay">
                <div className="text">Ver más...</div>
              </div>
            </div>
          </div>
          <a href="/xv-auto?id=455">
            <div className="w-full p-2 h-[50vh] flex justify-center items-center">
              <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
                <img
                  src="https://i.postimg.cc/NMXKRpNs/15A2880.jpg"
                  className="h-full w-[80%] rounded-lg pulsate"
                  alt=""
                />
                <div className="overlay">
                  <div className="text">Ver más...</div>
                </div>
              </div>
            </div>
          </a>
          <div className="w-full p-2 h-[50vh] flex justify-center items-center">
            <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
              <img
                src="https://i.postimg.cc/Jn11h8yW/pro-FKp-ARDQL.jpg"
                className="h-full w-[80%] rounded-lg pulsate"
                alt=""
              />
              <div className="overlay">
                <div className="text">Ver más...</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-4 justify-center flex-col bg-slate-500 bg-cover bg-no-repeat bg-[url('https://i.postimg.cc/sf8LkRVf/Historia-De-Instagram-Feliz-A-o-Nuevo-2024-Elegante-Plateado.png')] items-center flex min-h-screen">
        <h1 className="m-4 text-white">INVITACIONES PLATA</h1>
        <div className="w-full sm:flex-col md:grid md:grid-cols-3">
          <div className="w-full p-2 h-[50vh] flex justify-center items-center">
            <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
              <img
                src="https://i.postimg.cc/NMTCHvmd/a.jpg"
                className="h-full w-[80%] rounded-lg pulsate"
                alt=""
              />
              <div className="overlay">
                <div className="text">Ver más...</div>
              </div>
            </div>
          </div>
         <a href="/xv-auto?id=456">
         <div className="w-full p-2 h-[50vh] flex justify-center items-center">
            <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
              <img
                src="https://i.postimg.cc/NMXKRpNs/15A2880.jpg"
                className="h-full w-[80%] rounded-lg pulsate"
                alt=""
              />
              <div className="overlay">
                <div className="text">Ver más...</div>
              </div>
            </div>
          </div>
         </a>
          <div className="w-full p-2 h-[50vh] flex justify-center items-center">
            <div className="relative cursor-pointer flex justify-center items-center h-full w-full">
              <img
                src="https://i.postimg.cc/Jn11h8yW/pro-FKp-ARDQL.jpg"
                className="h-full w-[80%] rounded-lg pulsate"
                alt=""
              />
              <div className="overlay">
                <div className="text">Ver más...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
