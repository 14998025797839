import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/AThousandYearsSpanish.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const BodaDianaYRoberto = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: " nuestra boda",
    esposo: "Roberto",
    esposa: "Diana",
    fechaDeBoda: "15 JUNIO, 2024",
    imgPortada:
      "https://i.postimg.cc/FFxj05tv/6024d554-6050-47ba-97fc-413ae77c6c2e.jpg",
    numeroNovia: "+524921712449",
    numeroNovio: "+524921023050",
    novios: true,
    tituloConfirmacion: (
      <div>
        Espero puedan confirmarnos su asistencia. <br /> Agradecemos de antemano
        su apreciable compañia.
      </div>
    ),
  };

  const peltaColores = {
    color1: "#c1e0fd",
    color2: "#dadaff",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Hay momentos en la vida que son especiales por si solos, pero al compartirlos con personas tan especiales como tú, se convierten en momentos imposibles de olvidar ¡Te esperamos en nuestra boda!",
    fechaNewDate: "June 15, 2024 13:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/FHq6fYMR/7c08218d-ffaf-4bd9-9a0d-b8d998024f4d.jpg",
    fotoDerecha: "https://i.postimg.cc/fR1XMMdc/IMG-20240420-215516.jpg",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Juana Maria Castañeda Arredondo",
    esposaPadre: "Victor Manuel Canales Torres",
    esposoMadre: "Leticia Mauricio Garcia ✟",
    esposoPadre: "Martin Ibarra Martinez ✟",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 15 de Junio de 2024.",
    ceremoniaHora: "1:00 p.m.",
    ceremoniaNombreLugar: "Parroquia San Miguel Arcángel",
    ceremoniaLugarMap: "Miguel Auza SN, Centro, 98330 Miguel Auza, Zac.",
    ceremoniaUrl: "https://maps.app.goo.gl/ejSqjy29v5UGN4ob6",
    recepcionFecha: "Sábado, 15 de Junio de 2024.",
    recepcionHora: "7:00 p.m.",
    recepcionNombreLugar: "Salón de Eventos Las Flores",
    recepcionLugarMap: "Calle Zaragoza #47 Miguel Auza, Zacatecas",
    recepcionUrl: "https://maps.app.goo.gl/wF6SsmJZtFigNnYW6",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/fLcYH1Jx/Screenshot-2024-0420-220012.jpg",
    "https://i.postimg.cc/MHzVgtQg/Screenshot-2024-0420-220255.jpg",
    "https://i.postimg.cc/FFxj05tv/6024d554-6050-47ba-97fc-413ae77c6c2e.jpg",
    "https://i.postimg.cc/FHq6fYMR/7c08218d-ffaf-4bd9-9a0d-b8d998024f4d.jpg",
    "https://i.postimg.cc/fR1XMMdc/IMG-20240420-215516.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
