import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import YouTube from "react-youtube";
import { CiPause1, CiPlay1 } from "react-icons/ci";

/**
 * Valida si una cadena de texto es un enlace de YouTube.
 * @param {string} input - La cadena de texto a validar.
 * @returns {boolean} - `true` si la cadena es un enlace de YouTube, `false` en caso contrario.
 */
const isYouTubeLink = (input) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return youtubeRegex.test(input);
};

/**
 * Extrae el ID del video de un enlace de YouTube.
 * @param {string} url - El enlace de YouTube.
 * @returns {string|null} - El ID del video de YouTube o `null` si no se puede extraer.
 */
const extractYouTubeID = (url) => {
  const idRegex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(idRegex);
  return match ? match[1] : null;
}

export const PortadaXvComponentD2 = ({datosSeccionPortada, peltaColores}) => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);
    const [isYouTube, setIsYouTube] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [cancionYoutube, setCancionYoutube] = useState("");
    const playerRef = useRef(null);
  
    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    const reproYoutube = (vali) => {
      if (vali) {
        setIsYouTube(true);
        const youtubeID = extractYouTubeID(datosSeccionPortada.cancion);
        console.log("ID del video de YouTube:", youtubeID);
        setCancionYoutube(youtubeID);
      }
    };
  
    const onReady = (event) => {
      playerRef.current = event.target;
      playerRef.current.playVideo(); // Autoplay the video
    };
  
    const handlePlayButtonClick = () => {
      setIsMusicPlaying(!isMusicPlaying);
    };

    //CONTADOR===========================================
    const targetDate = new Date(
      datosSeccionPortada.fechaNewDate || ""
    ).getTime();
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const difference = targetDate - now;
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
    //===============================================================

    useEffect(() => {
      AOS.init({ duration: 2000 });
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    useEffect(() => {
      const validacion = isYouTubeLink(datosSeccionPortada.cancion);
      reproYoutube(validacion);
      const audioElement = document.getElementById("backgroundMusic");
      if (isMusicPlaying) {
        audioElement.play();
      } else {
        audioElement.pause();
        audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
      }
    }, [isMusicPlaying]);
  
    return (
      <div
        style={{
          backgroundImage: `url(${datosSeccionPortada.imgPortada})`,
          backgroundRepeat: "no-repeat",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col md:bg-full sm:bg-cover justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-end h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          {isYouTube ? (
            <YouTube
              videoId={cancionYoutube}
              opts={{
                height: "1", // Cambia a un tamaño pequeño pero visible
                width: "1",
                playerVars: {
                  autoplay: 1,
                  controls: 0,
                },
              }}
              onReady={onReady}
            />
          ) : (
            <audio
              id="backgroundMusic"
              style={{ display: "none" }}
              autoPlay
              loop
              muted={!isMusicPlaying}
            >
              <source src={datosSeccionPortada.cancion} type="audio/mpeg" />
              {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
            </audio>
          )}
  
  {
    datosSeccionPortada.cancion ? (
      <div className="w-full flex justify-end h-full items-start pb-10 fixed ">
            {isYouTube ? (
              <button
                onClick={() => {
                  if (playerRef.current) {
                    if (isPlaying) {
                      playerRef.current.pauseVideo();
                    } else {
                      playerRef.current.playVideo();
                    }
                    setIsPlaying(!isPlaying);
                  }
                }}
                className="text-3xl m-2 p-2 rounded-full animate-pulse"
                style={{
                  backgroundColor:`${peltaColores.color1}7d`
                }}
              >
                {isPlaying ? <CiPlay1 /> : <CiPause1 />}
              </button>
            ) : (
              <button
                onClick={handlePlayButtonClick}
                className="text-3xl m-2 p-2 rounded-full animate-pulse"
                style={{
                  backgroundColor:`${peltaColores.color1}7d`
                }}
              >
                {isMusicPlaying ? <CiPlay1 /> : <CiPause1 />}
              </button>
            )}
          </div>
    ):""
  }
          
          <h1
            className={`letra-cursiva sm:text-6xl md:text-[132px] ${
              datosSeccionPortada.textColor
                ? datosSeccionPortada.textColor
                : "text-white"
            } text-center flex`}
            data-aos="flip-up"
          >
            {datosSeccionPortada.nombre.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <br />
          {
            datosSeccionPortada.titulo ?(
              <p
              className={`p-2 rounded-lg letra-text sm:text-xl md:text-2xl pb-0 mb-0 text-center ${
                datosSeccionPortada.textColor
                  ? datosSeccionPortada.textColor
                  : "text-white"
              }`}
            >
              {datosSeccionPortada.titulo ? `- ${datosSeccionPortada.titulo} -`:""} 
            </p>
            ):""
          }     
          <br />
        </div>
      </div>
    );
}
