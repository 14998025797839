import { BodaCecilia } from "../screens/bodas/Boda-plata/BodaCecilia";
import { BodaEjemplo } from "../screens/bodas/Boda-plata/BodaEjemplo";
import { BodaGuadalupe } from "../screens/bodas/Boda-plata/BodaGuadalupe";
import { BodaKarinaYAndres } from "../screens/bodas/Boda-plata/BodaKarinaYAndres";
import { BodaPlataJavier } from "../screens/bodas/Boda-plata/BodaPlataJavier";
import { BodaMariaYVictor } from "../screens/bodas/Boda-plata/BodaMariaYVictor";
import { BodaSofiYDaniel } from "../screens/bodas/Boda-plata/BodaSofiYDaniel";
import { BodaAideYMario } from "../screens/bodas/Boda-plata/BodaAideYMario";
import { BodaEDianaYUriel } from "../screens/bodas/Boda-plata/BodaDianaYUriel";
import { BodaEjemploNew } from "../screens/bodas/Boda-plata/BodaEjemploNew";
import { BodaEstelaYFrancisco } from "../screens/bodas/Boda-plata/BodaEstelaYFrancisco";
import { BodaDavidYMichel } from "../screens/bodas/Boda-plata/BodaDavidYMichel";
import { BodaNavaYJulio } from "../screens/bodas/Boda-plata/BodaNavaYJulio";
import { BodaDianaYRoberto } from "../screens/bodas/Boda-plata/BodaDianaYRoberto";
import { BodaGuadalupeYJose } from "../screens/bodas/Boda-plata/BodaGuadalupeYJose";
import { BodaErikaYAlvaro } from "../screens/bodas/Boda-plata/BodaErikaYAlvaro";
import { BodaKarenYAlejandro } from "../screens/bodas/Boda-plata/BodaKarenYAlejandro";
import { BodaPatyYGabriel } from "../screens/bodas/Boda-plata/BodaPatyYGabriel";
import { BodaGabrielaYBrandon } from "../screens/bodas/Boda-plata/BodaGabrielaYBrandon";
import { BodaFabiyOscar } from "../screens/bodas/Boda-plata/BodaFabiyOscar";
import { BodaCandyYGustavo } from "../screens/bodas/Boda-plata/BodaCandyYGustavo";
import { BodaKarenYElias } from "../screens/bodas/Boda-plata/BodaKarenYElias";
import { BodaDanielYNayeli } from "../screens/bodas/Boda-plata/BodaDanielYNayeli";
import { BodaYazminYAaron } from "../screens/bodas/Boda-plata/BodaYazminYAaron";
import { BodaCitlalinYErick } from "../screens/bodas/Boda-plata/BodaCitlalinYErick";
import { BodaKatiaYLuis } from "../screens/bodas/Boda-plata/BodaKatiaYLuis";
import { BodaJazminYCesar } from "../screens/bodas/Boda-plata/BodaJazminYCesar";
import { BodaElisaYElias } from "../screens/bodas/Boda-plata/BodaElisaYElias";
import { BodaNidiaYValery } from "../screens/bodas/Boda-plata/BodaNidiaYValery";
import { BodaCitlaliYEric } from "../screens/bodas/Boda-plata/BodaCitlaliYEric";
import { BodaMariaYMiguel } from "../screens/bodas/Boda-plata/BodaMariaYMiguel";
import { BodaMonicaYGuillermo } from "../screens/bodas/Boda-plata/BodaMonicaYGuillermo";
import { BodaMargaritaYVictor } from "../screens/bodas/Boda-plata/BodaMargaritaYVictor";
import { BodaCarmenYJose } from "../screens/bodas/Boda-plata/BodaCarmenYJose";
import { BodaLeslieYRicardo } from "../screens/bodas/Boda-plata/BodaLeslieYRicardo";
import { BodaCrystalYChristian } from "../screens/bodas/Boda-plata/BodaCrystalYChristian";
import { BodaLauraYGustavo } from "../screens/bodas/Boda-plata/BodaLauraYGustavo";
import { BodaLilianaYAdrian } from "../screens/bodas/Boda-plata/BodaLilianaYAdrian";
import { BodaMariaYRosendo } from "../screens/bodas/Boda-plata/BodaMariaYRosendo";
import { BodaDiannaYArturo } from "../screens/bodas/Boda-plata/BodaDiannaYArturo";
import { BodaPatriciaYHector } from "../screens/bodas/Boda-plata/BodaPatriciaYHector";
import { BodaMonicaYGuillermoDoble } from "../screens/bodas/Boda-plata/BodaMonicaYGuillermoDoble";
import { BodaLupitaYManuel } from "../screens/bodas/Boda-plata/BodaLupitaYManuel";
import { BodaYeniferYJoel } from "../screens/bodas/Boda-plata/BodaYeniferYJoel";
import { BodaAngelicaYAlexis } from "../screens/bodas/Boda-plata/BodaAngelicaYAlexis";
import { BodaDafneYCesar } from "../screens/bodas/Boda-plata/BodaDafneYCesar";
import { BodaEjemploSinFoto } from "../screens/bodas/Boda-plata/BodaEjemploSinFoto";


const RoutesBodaPlata = [
  {
    path: '/boda-plata-ejemplo',
    element: <BodaEjemplo />
  },
  {
    path: '/boda-plata-ejemplo-fotos',
    element: <BodaEjemploSinFoto />
  },
  {
    path: '/boda-plata-ejemplo-new',
    element: <BodaEjemploNew />
  },
  {
    path: '/boda-plata-cecilia&jonathan',
    element: <BodaCecilia />
  },
  {
    path: '/boda-plata-alondra&javier',
    element: <BodaPlataJavier />
  },
  {
    path: '/boda-plata-guadalupe&gabriel',
    element: <BodaGuadalupe />
  },
  {
    path: '/boda-plata-karina&andres',
    element: <BodaKarinaYAndres />
  },
  {
    path: '/boda-plata-maría&víctor',
    element: <BodaMariaYVictor />
  },
  {
    path: '/boda-plata-sofi&daniel',
    element: <BodaSofiYDaniel />
  },
  {
    path: '/boda-plata-aide&mario',
    element: <BodaAideYMario />
  },
  {
    path: '/boda-plata-diana&uriel',
    element: <BodaEDianaYUriel />
  },
  {
    path: '/boda-plata-estela&francisco',
    element: <BodaEstelaYFrancisco />
  },
  {
    path: '/boda-plata-david&michel',
    element: <BodaDavidYMichel />
  },
  {
    path: '/boda-plata-eva&julio',
    element: <BodaNavaYJulio />
  },
  {
    path: '/boda-plata-diana&roberto',
    element: <BodaDianaYRoberto />
  },

  {
    path: '/boda-plata-guadalupe&jose',
    element: <BodaGuadalupeYJose />
  },

  {
    path: '/boda-plata-erika&alvaro',
    element: <BodaErikaYAlvaro />
  },

  {
    path: '/boda-plata-karen&alejandro',
    element: <BodaKarenYAlejandro />
  },
  {
    path: '/boda-plata-paty&gabriel',
    element: <BodaPatyYGabriel />
  },
  {
    path: '/boda-plata-gabriela&brando',
    element: <BodaGabrielaYBrandon />
  },
  {
    path: '/boda-plata-fabi&oscar',
    element: <BodaFabiyOscar />
  },
  {
    path: '/boda-plata-candy&gustavo',
    element: <BodaCandyYGustavo />
  },
  {
    path: '/boda-plata-karen&elias',
    element: <BodaKarenYElias />
  },
  {
    path: '/boda-plata-daniel&nayeli',
    element: <BodaDanielYNayeli />
  },
  {
    path: '/boda-plata-yazmin&aaron',
    element: <BodaYazminYAaron />
  },
  {
    path: '/boda-plata-citlalin&erick',
    element: <BodaCitlalinYErick />
  },
  {
    path: '/boda-plata-katia&luis',
    element: <BodaKatiaYLuis />
  },
  {
    path: '/boda-plata-jazmin&cesar',
    element: <BodaJazminYCesar />
  },
  {
    path: '/boda-plata-elisa&elias',
    element: <BodaElisaYElias />
  },
  {
    path: '/boda-plata-citlali&eric',
    element: <BodaCitlaliYEric />
  },
  {
    path: '/boda-plata-nidia&valery',
    element: <BodaNidiaYValery />
  },
  {
    path: '/boda-plata-maria&miguel',
    element: <BodaMariaYMiguel />
  },
  {
    path: '/boda-plata-monica&guillermo',
    element: <BodaMonicaYGuillermo />
  },
  {
    path: '/boda-plata-monica&guillermo-doble',
    element: <BodaMonicaYGuillermoDoble />
  },
  {
    path: '/boda-plata-margarita&victor',
    element: <BodaMargaritaYVictor />
  },
  {
    path: '/boda-plata-carmen&jose',
    element: <BodaCarmenYJose />
  },
  {
    path: '/boda-plata-leslie&ricardo',
    element: <BodaLeslieYRicardo />
  },
  {
    path: '/boda-plata-crystal&christian',
    element: <BodaCrystalYChristian />
  },
  {
    path: '/boda-plata-laura&gustavo',
    element: <BodaLauraYGustavo />
  },
  {
    path: '/boda-plata-liliana&adrian',
    element: <BodaLilianaYAdrian />
  },
  {
    path: '/boda-plata-maria&rosendo',
    element: <BodaMariaYRosendo />
  },
  {
    path: '/boda-plata-diana&arturo',
    element: <BodaDiannaYArturo />
  },
  {
    path: '/boda-plata-patricia&hector',
    element: <BodaPatriciaYHector />
  },
  {
    path: '/boda-plata-lupita&manuel',
    element: <BodaLupitaYManuel />
  },
  {
    path: '/boda-plata-yenifer&joel',
    element: <BodaYeniferYJoel />
  },
  {
    path: '/boda-plata-angelica&alexis',
    element: <BodaAngelicaYAlexis />
  },
  {
    path: '/boda-plata-dafne&cesar',
    element: <BodaDafneYCesar />
  },
  
  ];
 
  export default RoutesBodaPlata;