import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaElisaYElias = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#f7f1df",
    color2: "#dff2f7",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_05/NEcsQoRK6aR_y2mate.com---Michael-Bubl%C3%A9-Sway-Official-Lyric-Video.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Elias",
    esposa: "Elisa",
    fechaDeBoda: "20 JULIO, 2024",
    imgPortada:
      "https://i.postimg.cc/Xvc7dFhp/Civil-Elisa-x-Elias-124.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "En las historias de amor, siempre hay hermosos momentos y aunque esto apenas comienza, junto a ustedes queremos hacer de esto, una hermosa celebración. Te invitamos a acompañarnos a nuestra unión y ser testigos de nuestro 'Felices Por Siempre'.",
    fechaNewDate: "July 20, 2024 18:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/TPX3KQtz/Civil-Elisa-x-Elias-137.jpg",
    fotoDerecha: "https://i.postimg.cc/DZ6rjSWz/97fc11c4-20d2-4b32-958c-bb11f0a70217.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Ma. Guadalupe Tun Romero",
    padre: "Marcos Martín Tzab Lara",
    titulo2: "Padres del Novio",
    madrina: "Leticia Vallejo Pérez",
    padrino: "✟Pablo Delgadillo Vallín✟",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 20 de Julio de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Iglesia San Martín Obispo, Tixpehual, Yucatán.",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/H4Q8xxGXCceSAZPKA",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 20 de Julio de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "Hacienda San Pedro Palomeque",
    recepcionLugarMap:
      "Anillo Periferico Sur Km 4.5, Buenavista, 97127 Mérida, Yuc.",
    recepcionUrl: "https://maps.app.goo.gl/WyurXJNE3rnP8CF38",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51397548",
    imgLink1:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link1Enable: true,
    link2: "",
    imgLink2:
      "",
    link2Enable: true,
    link3: "",
    imgLink3:
      "",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Agradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "4491516931",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/Xvc7dFhp/Civil-Elisa-x-Elias-124.jpg",
    "https://i.postimg.cc/TPX3KQtz/Civil-Elisa-x-Elias-137.jpg",
    "https://i.postimg.cc/DZ6rjSWz/97fc11c4-20d2-4b32-958c-bb11f0a70217.png",
    
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionConfirmacion.textColor
              ? datosSeccionConfirmacion.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
        >
          <h1
            className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionConfirmacion.tituloConfirmacion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </h1>
          <br />
          <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
            {datosSeccionConfirmacion.aviso || ""}
          </p>
          <br />
          <br />
          <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
            {images?.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[60vh]"
                />
              </div>
            ))}
          </Slider>
          <br />
          <br />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
