import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./assets/CorazondeNiño.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { LuRat } from "react-icons/lu";

export const BautizoEmily = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'al Bautizo',
      nombre: "Emily Nicole",
      fechaDeBoda: "04 MAYO, 2024",
      fechaNewDate: "May 04, 2024 13:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/y6pCVRsD/1000011954.jpg ",
      numeroNovia: "5539375620",
      numeroNovio: "",
      titulo:"NO FALTES A MI BAUTIZO"
      
    };
  
    const peltaColores = {
      color1: "#e7e3e3",
      color2: "#f8f2f2",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Emily Nicole!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:(
        <div className="flex flex-col justify-center items-center">
          Mi Bautizo <br />
          <br />
          <LuRat/>
        </div>
      ),
      descipcion:
        "Hoy, en un día especial, doy gracias a Dios y a mis padres por su apoyo en este momento único. En mi bautizo, inicio una nueva etapa espiritual y deseo compartir esta ocasión especial contigo. Que este día esté lleno de bendiciones y amor. ¡Gracias por ser parte de este momento importante para mí!",
      fechaNewDate: "May 04, 2024 13:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/q7T9mXX5/1000011956.jpg",
      fotoDerecha: "https://i.postimg.cc/W4mPjf1V/1000011957.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de Emily Nicole',
        tituloDerecha:'Padrinos de Emily Nicole',
      esposaMadre: "Anayeli Mendez Gutiérrez",
      esposaPadre: "Tim Van Dam",
      esposoMadre: "Magali Méndez Gutiérrez",
      esposoPadre: "Jose Manuel Méndez Gutiérrez",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 04 de Mayo de 2024.",
      ceremoniaHora: "1:00 p.m.",
      ceremoniaNombreLugar: "Parroquia María Reyna",
      ceremoniaLugarMap:
        "Pinos no. 13 unidad independencia CP.10200",
      ceremoniaUrl:
        "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
      recepcionFecha: "Sábado, 04 de Mayo de 2024.",
      recepcionHora: "3:00 p.m.",
      recepcionNombreLugar: "",
      recepcionLugarMap:
        "Fuente Cantos 30 fracc. Rincon del Pedregal CP. 14120",
      recepcionUrl:
        "https://maps.app.goo.gl/hXxcaARdkKb36THH8",
    };
  
  
    const images = [
      "https://i.postimg.cc/4xtDbFtc/1000011959.jpg",
      "https://i.postimg.cc/qq5PQPjs/1000011960.jpg",
      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
