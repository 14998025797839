import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./Photograph.mp3";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";

export const XvGabyOrtiz = () => {
    const [isOpen, setIsOpen] = useState(false);

    const peltaColores = {
      color1: "#d3efbe",
      color2: "#efb810",
    };
  
    const datosSeccionPortada = {
      cancion:audioFondo,
      imgPortada:
        "https://static.vecteezy.com/system/resources/previews/001/380/222/non_2x/flowers-on-green-watercolor-background-free-vector.jpg",
      nombre: "Gaby Ortíz",
      fechaDeBoda: "10 AGOSTO, 2024",
      titulo: "NO FALTES A MIS 18 AÑOS",
    };
  
    const datosSeccionConfirmacion = {
      tituloConfirmacion:
        "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
      aviso: "",
      numeroConfirmacion: "+527291787837",
      textoConfirmacion: "a mi festejo ",
      textWhatsApp: "",
      textColor: "",
      imgFondo: "https://i.pinimg.com/originals/33/4f/10/334f103f0e1c97efbcbf81b56a9f3022.jpg",
      textColor: "",
    };
  
    const datosSeccionContador = {
      titulo: "¡Mis 18 años!",
      descipcion:
        "“La familia es donde comienza la vida y el amor nunca termina” \\nGracias por estar hoy aquí.",
      fechaNewDate: "Augost 10, 2024 18:00:00 GMT+00:00",
      fotoIzquierda:
        "https://i.postimg.cc/x87hKyQw/G.png",
      fotoDerecha:
        "https://images.vexels.com/media/users/3/194543/isolated/preview/56affe8e232e558cad64be081dcf5677-adorno-de-trazo-de-vestido-de-quinceanera.png",
      marcoEnable: true,
      imgFondo: "",
      textColor: "",
      contador: true,
    };
  
    const datosSeccionPadres = {
      titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
      tituloIzquierda: "Padres de la Festejada",
      tituloDerecha: "Padrinos de la Festejada",
      madre: "Julia Torres",
      padre: "Bonifacio Ortíz",
      madrina: "Isabel Ortíz",
      padrino: "Isidro Gonzalez",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionCeremonia = {
      dobleEnable: false,
      ceremoniaEnable: true,
      ceremoniaFecha: "Sábado, 10 de Agosto de 2024.",
      ceremoniaHora: "5:00 p.m.",
      ceremoniaNombreLugar: "Parroquia del divino Salvador, capultitlan",
      ceremoniaLugarMap:
        "Av, P.º Cristóbal Colón 102, Delegación Capultitlán, 50260 Capultitlán, Méx.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/SUxnAJxEepZcKsNX7",
      recepcionEnable: true,
      recepcionFecha: "Sábado, 10 de Agosto de 2024.",
      recepcionHora: "6:00 p.m.",
      recepcionNombreLugar: "Salón Reforma",
      recepcionLugarMap:
        "Calle Reforma 119, Delegación Capultitlán, 50260 Capultitlán, Méx.",
      recepcionUrl:
        "https://maps.app.goo.gl/GALcN1dPCccyZ7Sw7",
      imgFondo: "https://i.pinimg.com/originals/33/4f/10/334f103f0e1c97efbcbf81b56a9f3022.jpg",
      textColor: "",
    };
  
    const datosSeccionVestimenta = {
      vestimenta: "",
      messageVestimenta: "",
      urlVestimentaMujer: "",
      urlVestimentaHombre: "",
      imgFondo:
        "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
      textColor: "",
    };
  
    const datosSeccionPrograma = {
      ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
      recepcion: "Tómate fotos con nosotros",
      baileXV: "",
      cena: "Disfruta de una deliciosa cena",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
      imgFondo:"https://i.pinimg.com/originals/33/4f/10/334f103f0e1c97efbcbf81b56a9f3022.jpg",
      textColor:""
    };
  
    const datosSeccionHospedaje = {
      disponibleDos: false,
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionRegalos = {
      cantidadRegalos: 1,
      titulo: "Lluvia de sobres",
      descripcion:
        "",
      link1: "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      imgLink1: "https://invitado.mx/wp-content/uploads/2021/10/LLUVIA-DE-SOBRES.png",
      link1Enable: false,
      link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
      imgLink2: "",
      link2Enable: true,
      link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
      imgLink3: "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
      link3Enable: false,
      imgFondo:"",
      textColor:""
    };
  
    const images = [
        "https://static.vecteezy.com/system/resources/previews/001/380/222/non_2x/flowers-on-green-watercolor-background-free-vector.jpg",
        "https://img.freepik.com/fotos-premium/letra-g-com-logotipo-floral-dourado-em-um-fundo-branco-e-composicao-dourada_880100-2316.jpg",
         "https://images.vexels.com/media/users/3/194543/isolated/preview/56affe8e232e558cad64be081dcf5677-adorno-de-trazo-de-vestido-de-quinceanera.png",
      // Add more image URLs as needed
    ];
  
    const contador = true;
    const padres = true;
    const ceremonia = true;
    const vestimenta = true;
    const programa = true;
    const hospedaje = false;
    const confirmacion = true;
    const Regalos = true;
  
    useEffect(() => {
      setIsOpen(true);
      AOS.init({ duration: 2000 });
    }, []);
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
         <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} /> 
        <PortadaXvComponent
          datosSeccionPortada={datosSeccionPortada}
        />
        {contador ? (
          <ContadorXvComponent
            peltaColores={peltaColores}
            datosSeccionContador={datosSeccionContador}
          />
        ) : (
          ""
        )}
  
        {padres ? (
          <PadresXvComponent
            peltaColores={peltaColores}
            datosSeccionPadres={datosSeccionPadres}
          />
        ) : (
          ""
        )}
  
        {ceremonia ? (
          <CeremoniaXvComponent
            peltaColores={peltaColores}
            datosSeccionCeremonia={datosSeccionCeremonia}
          />
        ) : (
          ""
        )}
        {vestimenta ? (
          <VestimentaXvComponent
            peltaColores={peltaColores}
            datosSeccionVestimenta={datosSeccionVestimenta}
          />
        ) : (
          ""
        )}
  
        {programa ? (
          <ProgramaXvComponent
            peltaColores={peltaColores}
            datosSeccionPrograma={datosSeccionPrograma}
          />
        ) : (
          ""
        )}
  
        {hospedaje ? (
          <HospedajeXvComponent
            peltaColores={peltaColores}
            datosSeccionHospedaje={datosSeccionHospedaje}
          />
        ) : (
          ""
        )}
  
        {Regalos ? (
          <RegalosXvComponent
            peltaColores={peltaColores}
            datosSeccionRegalos={datosSeccionRegalos}
          />
        ) : (
          ""
        )}
  
        {confirmacion ? (
          <ConfirmacionXvComponent
            peltaColores={peltaColores}
            images={images}
            datosSeccionConfirmacion={datosSeccionConfirmacion}
          />
        ) : (
          ""
        )}
      </div>
    );
}