import React, { useState } from "react";
import CryptoJS from "crypto-js";
import axios from "axios";
import toast from "react-hot-toast";

export const LoginEventos = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [dataEvento, setDataEvento] = useState([]);

  const generateToken = (username, password) => {
    const rawToken = `${username}-${password}-BeastInvitation`;
    return CryptoJS.SHA256(rawToken).toString();
  };

  const handleLogin = async () => {
    toast.loading(`Validando Credenciales...`);

    try {
      const response = await axios.get(
        `/api/Admins/login?user=${username}&pass=${password}`
      );
      setDataEvento(response.data);
      console.log(response.data);

      if (
        username === response?.data?.user &&
        password === response?.data?.pass
      ) {
        const token = generateToken(username, password);
        console.log(token);
        response.data.token = token;
        await axios.put(
          `/api/Admins/${response?.data?.idAdmin}`,
          response?.data
        );

        // Almacenar el token y el admin en localStorage como un string JSON
        localStorage.setItem('token', JSON.stringify({ token: token, admin: response?.data?.nombre }));

        toast.dismiss();
        toast.success("Login exitoso");
        window.location.href = "/admin-eventos";
        setError("");
      } else {
        toast.dismiss();
        toast.error("Usuario o contraseña inválidos");
        setError("Usuario o contraseña inválidos");
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Usuario o contraseña inválidos");
      setError("Usuario o contraseña inválidos");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
        <h2 className="text-4xl font-bold mb-6 text-gray-800">Login</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-lx font-bold mb-2"
              htmlFor="username"
            >
              Username
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-lx font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleLogin}
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
