import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvElisa = () => {
  const [isOpen, setIsOpen] = useState(false);

  const peltaColores = {
    color1: "#000000",
    color2: "#000000",
  };

  const datosSeccionPortada = {
    cancion:"https://www.cjoint.com/doc/24_04/NDEez5qHaKR_y2mate.com---Son-Calenda.mp3",
    imgPortada:
      "https://i.postimg.cc/jC04r0nB/Dise-o-sin-t-tulo-9.png",
    nombre: "Elisa Arhirani",
    fechaDeBoda: "29 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "No olvides tu regalo",
    numeroConfirmacion: "+525513331380",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "https://i.postimg.cc/xjBqztB6/Historia_de_Instagram_Invitación_Fiesta_Mexicana.png",
    textColor: "#ffffff",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. \\nHoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
    fechaNewDate: "June 29, 2024 15:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/76Qy9XJ5/Dise-o-sin-t-tulo-11.png",
    fotoDerecha:
      "https://i.postimg.cc/pTdHK3rN/Dise-o-sin-t-tulo-10.png",
    marcoEnable: true,
    imgFondo: "https://i.postimg.cc/5NZvfxxN/Historia-de-Instagram-Invitaci-n-Fiesta-Mexicana-2.png",
    textColor: "#ffffff",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Ilsen Ponce Jurado",
    padre: "Hugo Contreras Aparicio",
    madrina: "Edith E. Ponce Jurado",
    padrino: "Omar Palacios Jurado  ",
    imgFondo: "https://i.postimg.cc/0j1R8MH0/Historia-de-Instagram-Invitaci-n-Fiesta-Mexicana-1.png",
    textColor: "#ffffff",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 29 de Junio de 2024.",
    ceremoniaHora: "12:00 p.m.",
    ceremoniaNombreLugar: "Iglesia de San Nicolas",
    ceremoniaLugarMap:
      "Av. Emiliano Zapata S/N. San Nicolas Tetelco Tlahuac CdMx.",
    ceremoniaUrl:
      "https://maps.app.goo.gl/gBYqg7N62RB2gCCA9",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 29 de Junio de 2024.",
    recepcionHora: "3:00 p.m.",
    recepcionNombreLugar: "Morelos #30, San Pablo Atlazalpan Chalco Edo de México.",
    recepcionLugarMap:
      "",
    recepcionUrl:
      "https://maps.app.goo.gl/493SQryZ1azAvVNV7",
    imgFondo: "https://i.postimg.cc/5NZvfxxN/Historia-de-Instagram-Invitaci-n-Fiesta-Mexicana-2.png",
    textColor: "#ffffff",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "Fiesta Mexicana",
    urlVestimentaMujer: "https://i.postimg.cc/1XDHGGRy/Respetuosa-ente-2.png",
    urlVestimentaHombre: "https://i.postimg.cc/C1BJ1yWB/Respetuosa-ente-3.png",
    imgFondo:
      "https://i.postimg.cc/bYDRQVVM/Poster-vertical-fiesta-mexicana-colorido-blanco-con-verde.png",
    textColor: "https://i.postimg.cc/qvdjjH3m/Invitaci-n-Noche-Mexicana-Verde.png",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "https://i.postimg.cc/qvdjjH3m/Invitaci-n-Noche-Mexicana-Verde.png",
    textColor: "#ffffff",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1: "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3: "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo:"",
    textColor:""
  };

  const images = [
    "https://i.postimg.cc/MZ3t62Ly/435af0ee-b3c0-425b-b966-446fe26e78d5.jpg",
    "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
    "https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg",
    "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
    "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
        {
        modal? (
            <ModalXvCard
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            datosSeccionPortada={datosSeccionPortada}
            peltaColores={peltaColores}
          />
        ):("")
      }
      <PortadaXvComponent
        datosSeccionPortada={datosSeccionPortada}
      />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
