import { BodaAutomatica } from "../screens/bodas/Boda-automatica/BodaAutomatica";
import { BodaAutomaticaDiamCivil } from "../screens/bodas/Boda-automatica/BodaAutomaticaDiamCivil";
import { BodaAutomaticaDiamante } from "../screens/bodas/Boda-automatica/BodaAutomaticaDiamante";
import { BodaAutomaticaDiamante as BodaAutomaticaDiamanteEn } from "../screens/bodas/Boda-automatica/ingles/BodaAutomaticaDiamante";
import { BodaLupita } from "../screens/bodas/Boda-automatica/BodaLupita";
import { BodaAutomatica as BodaAutomaticaEn } from "../screens/bodas/Boda-automatica/ingles/BodaAutomatica";

const RutaAutomaticaBoda = [
    {
        path: '/boda-auto',
        element: <BodaAutomatica />
    },
    {
        path: '/en/wedding-auto',
        element: <BodaAutomaticaEn />
    },
    {
        path: '/boda-auto-diamante',
        element: <BodaAutomaticaDiamante />
    },
    {
        path: '/wedding-auto-diamond',
        element: <BodaAutomaticaDiamanteEn />
    },
    {
        path: '/boda-auto-lupita-manuel',
        element: <BodaLupita />
    },
    {
        path: '/boda-auto-diamante-cv',
        element: <BodaAutomaticaDiamCivil />
    },
]


export default RutaAutomaticaBoda;