import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";

export const BodaNidiaYValery = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");
  const [isIOS, setIsIOS] = useState(false);

  const peltaColores = {
    color1: "#000000",
    color2: "#bababa",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEck6pTB6wR_y2mate.com---Melendi-HaAsh-Destino-o-Casualidad-Letra.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Valery",
    esposa: "Abril",
    fechaDeBoda: "15 JUNIO, 2024",
    imgPortada:
      "https://i.postimg.cc/pXk6HYXB/Whats-App-Image-2024-05-01-at-9-09-45-PM.jpg",
    titulo: "Nuestra Boda",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de coincidir en el camino y ambas tomar el mismo viaje en busca de lo mismo, hemos decidido unir nuestras vidas en una sola, compartiendo nuestra union matrimonial con las personas que han estado presente a lo largo de esta travesía.",
    fechaNewDate: "June 15, 2024 18:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/8cS01Z6Z/Whats-App-Image-2024-05-01-at-9-14-33-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/K8zsvk1h/Whats-App-Image-2024-05-01-at-9-09-44-PM.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "#fff",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "",
    madre: "",
    padre: "",
    titulo2: "",
    madrina: "",
    padrino: "",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 15 de Junio de 2024.",
    ceremoniaHora: "6:30 p.m.",
    ceremoniaNombreLugar: "Circuito Nápoles 1405 Residencial San Marino",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/v24449q7T8WFJ3gb7",
    recepcionEnable: false,
    recepcionFecha: "Sábado, 23 de Diciembre de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "El Herradero Jardín y Eventos",
    recepcionLugarMap:
      "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
    recepcionUrl:
      "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Colores Claros",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1:
      "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+526647239281",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/pXk6HYXB/Whats-App-Image-2024-05-01-at-9-09-45-PM.jpg",
    "https://i.postimg.cc/8cS01Z6Z/Whats-App-Image-2024-05-01-at-9-14-33-PM.jpg",
    "https://i.postimg.cc/K8zsvk1h/Whats-App-Image-2024-05-01-at-9-09-44-PM.jpg",
    "https://i.postimg.cc/dtJxpSv1/Whats-App-Image-2024-05-01-at-9-09-44-PM-1.jpg",
    "https://i.postimg.cc/LXfWKcnq/Whats-App-Image-2024-05-01-at-9-09-45-PM-1.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
            backgroundColor: peltaColores.color1,
            backgroundAttachment: isIOS ? "local" : "fixed",

            color: peltaColores.textColor ? peltaColores.textColor : "",
          }}
          className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
        >
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Código de vestimenta
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              {datosSeccionVestimenta.vestimenta
                ? datosSeccionVestimenta.vestimenta
                : "FORMAL"}
            </p>
            <br />
            {datosSeccionVestimenta.messageVestimenta ? (
              <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
                {datosSeccionVestimenta.messageVestimenta
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            ) : (
              ""
            )}

            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-left"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaMujer ||
                    "https://i.postimg.cc/PrYyHMQW/vestido2.png"
                  }
                  className="h-[30vh]"
                  alt=""
                />
                <br />
                <p className="font-serif text-2xl">Mujeres</p>
              </div>
              <div
                className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                data-aos="fade-right"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaHombre ||
                    "https://i.postimg.cc/6pK0pGMD/traje2.png"
                  }
                  className="h-[30vh]"
                  alt=""
                />
                <br />
                <p className="font-serif text-2xl">Hombres</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
