import React, { useEffect, useState } from 'react'
import { GiTicket } from 'react-icons/gi';

export const InvitadosXvComponentD2 = ({ NameInvitation, numGuess, peltaColores, datosSeccionInvitados }) => {
    const [isIOS, setIsIOS] = useState(false);
  
    useEffect(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    }, []);
  
    const fontFamilyStyle = { fontFamily: datosSeccionInvitados.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };
  
    return (
      <div
        style={{
          backgroundImage: `url(${datosSeccionInvitados.imgFondo || ""})`,
          backgroundColor: peltaColores.color2,
          backgroundAttachment: isIOS ? 'local' : 'fixed',
          backgroundPosition: datosSeccionInvitados.sizeFoto ? datosSeccionInvitados.sizeFoto : "center",
        }}
        className="sm:h-auto md:h-auto bg-img-fixed sm:bg-local md:bg-fixed"
      >
  
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center py-8 px-2" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px] p-2 rounded-2xl text-white"
            data-aos="flip-up"
            style={fontFamilyStyle}
          >
            {datosSeccionInvitados.titulo} <br /><br /> {NameInvitation} <br /><br />Tienes {numGuess} accesos
            
          </h1> 
          <GiTicket className="text-[50px] p-2 text-white  text-center"
                style={{
                  color: datosSeccionInvitados.colorFondoName ? datosSeccionInvitados.colorFondoName : '#a3a438',
                }}
              />
         
        </div>
      </div>
    );
  };
  