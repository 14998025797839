/* eslint-disable eqeqeq */
import {
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";

export const CarouselComponent = ({ values, setValues, elementClass, ingles }) =>
{
    var items = values.carousel??[];
    var divClass = elementClass??"col-span-1 m-3";
    const UrlChange = (event) => {
        var index = parseInt(event.currentTarget.getAttribute("carouselindex"));
        var img = `carouselImageImg${index}`;
        document.getElementById(img).setAttribute("src", event.currentTarget.value);
        items[index].url = event.currentTarget.value;
        setValues("carousel", items);
    };

    const FileChange = (event) =>{
        var index = parseInt(event.currentTarget.getAttribute("carouselindex"));
        var img = `carouselImageImg${index}`;

        document.getElementById(img).setAttribute("src", URL.createObjectURL( event.currentTarget.files[0]));

        items[index].replacement = event.currentTarget.files[0];
        setValues("carousel", items);
    };

    const AddNew = () =>{
        items.push({id:0, url:"", replacement: null, deleted: false});
        setValues("carousel", items);
    }

    const DeleteImage = (event) =>{
        var id = parseInt(event.currentTarget.getAttribute("carouselid"));
        var index = parseInt(event.currentTarget.getAttribute("carouselindex"));
        if(id == 0){
            items.splice(index, 1);
        }
        else {
            items[index].deleted = !(items[index].deleted??false);
        }
        setValues("carousel", items);
    }

    return (
        <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <h2 className="text-center md:col-span-2 title-form">
                 {ingles ? "Photo Gallery" : "Galeria (Fotos al final de la invitación)"}
            </h2>
            {items.map(
                (x, index)=> {
                    return (
                        <div className={divClass} key={`carouselImage${index}`}>
                            <div className="row">
                                <div className="md-4">
                                    <img id={`carouselImageImg${index}`} alt="" style={{with:100, height:100, objectFit:'cover'}} src={x.url} />
                                </div>
                                <div className="md-8">
                                    <Input
                                        type="text"
                                        carouselid={x.id}
                                        carouselindex={index}
                                        value={x.url}
                                        onChange={UrlChange}
                                    />
                                    <Input
                                        type="file"
                                        carouselid={x.id}
                                        carouselindex={index}
                                        onChange={FileChange}
                                    />
                                    <Button carouselindex={index} carouselid={x.id} onClick={DeleteImage}>
                                        {x.deleted??false 
                                            ? (ingles ? "Don't remove" : "No remover") 
                                            : (x.id == 0 
                                                ? (ingles ? "Remove" : "Remover") 
                                                : (ingles ? "Mark for removal" : "Marcar para remover")
                                            )
                                        }
                                    </Button>
                                </div>
                                
                            </div>
                            
                        </div>
                    )
                }
            )}
            <Button onClick={AddNew}>{ingles ? "Add image" : "Agregar imagen"}</Button>
        </div>
    )

}