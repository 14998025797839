import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./assets/Moonlight.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { Vestimenta } from "../../../components/Vestimenta";

export const CumpleEvelynPamela = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los 18 años',
      nombre: "Evelyn Pamela",
      fechaDeBoda: "15 JUNIO, 2024",
      fechaNewDate: "June 15, 2024 20:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/KjPbbKVs/434145168-1229718105100024-1652719484023678575-n.jpg",
      numeroNovia: "5571464847",
      numeroNovio: "",
      aviso:"Para mí lo más importante es tu presencia, pero si deseas darme un obsequio lo puedes hacer en efectivo colocándolo en el sobre que se te entregara al entrar... Para mí graduación como enfermera 🎓",
      titulo:"NO FALTES A MIS 18 AÑOS"
    };
  
    const peltaColores = {
      color1: "#ffffff",
      color2: "#ff0000",
      messageVestimenta:"El Rojo es un color hermoso, pero en mis 18. *Es esclusivo para mí y mi familia* todos los demás colores están disponibles...."
    };
  
    const datosSeccion2 = {
      titulo:'¡Mis 18 años!',
      descipcion:
        "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí Y a mis padres por darme el apoyo para realizar este sueño. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "June 15, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/tJQb8drx/434146425-397912956334708-9018265525193042266-n.jpg",
      fotoDerecha: "https://i.postimg.cc/R0Y4LxKG/434250661-1648606795875726-5794087791871600877-n.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de Evelyn',
        tituloDerecha:'Padrinos de Evelyn',
      esposaMadre: "Rosa Elena Cruz Durán",
      esposaPadre: "Victor Manuel Florencia Zaldívar",
      esposoMadre: "Yanelli Guadalupe Cruz Durán",
      esposoPadre: "Raúl Alejandro Jiménez Barojas",
    };
  
    const imagenesFondo ={
        fondoInvitados:'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
        fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
      }

    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 15 de Junio de 2024.",
      ceremoniaHora: "7:00 p.m.",
      ceremoniaNombreLugar: "Parroquia de Cristo Salvador y Santa María Guadalupe",
      ceremoniaLugarMap:
        "Av. de la Escollera S/N, Acueducto, Gustavo A. Madero, 07279 Ciudad de México, CDMX.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/Ag6ZcBCwuqhD4ddAA",
      recepcionFecha: "Sábado, 15 de Junio de 2024.",
      recepcionHora: "8:00 p.m.",
      recepcionNombreLugar: "Salón Ideal",
      recepcionLugarMap:
        "MZ103 LT47, D.F., Carr. Tenayuca Chalmita, Zona Escolar, Ciudad de México, CDMX",
      recepcionUrl:
        "https://maps.app.goo.gl/WL8wjMHqjXH2CFM9A",
        textColor:"#ffff"
    };
  
  
    const images = [
      "https://i.postimg.cc/hGvKmtzf/434403974-1165165128186877-7637304361216868151-n.jpg",
      "https://i.postimg.cc/KjPbbKVs/434145168-1229718105100024-1652719484023678575-n.jpg",
      "https://i.postimg.cc/R0Y4LxKG/434250661-1648606795875726-5794087791871600877-n.jpg"
      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
        
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
