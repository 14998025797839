import { XvAmanda } from "../screens/XV/xv-oro/XvAmanda";
import { XvAndrea } from "../screens/XV/xv-oro/XvAndrea";
import { XvClaudia, XvMariamyMariel } from "../screens/XV/xv-oro/XvCMariamYMariel";
import { XvEjemploOro } from "../screens/XV/xv-oro/XvEjemploOro"
import { XvEjemploSinFoto } from "../screens/XV/xv-oro/XvEjemploSinFoto";
import { XvKeiraDorantes } from "../screens/XV/xv-oro/XvKeiraDorantes";

const RoutesXvOro = [
    {
      path: '/evento-oro-xv',
      element: <XvEjemploOro />
    },
    {
      path: '/evento-oro-xv-fotos',
      element: <XvEjemploSinFoto />
    },
    {
      path: '/evento-oro-xv-keira-dorantes',
      element: <XvKeiraDorantes />
    },
    {
      path: '/evento-oro-xv-mariam&mariel',
      element: <XvMariamyMariel
      />
    },
    {
      path: '/evento-oro-xv-adrea',
      element: <XvAndrea />
    },
    {
      path: '/evento-oro-xv-amanda',
      element: <XvAmanda />
    },
]

export default RoutesXvOro;