import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/maroon.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaFabiyOscar = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const peltaColores = {
      color1: "#adadad",
      color2: "#f9893a",
    };
  
    const datosSeccionPotadaBoda = {
      cancion: "https://www.cjoint.com/doc/24_04/NDzxgcpCcI1_y2mate.com---DEFTONES-SEXTAPEsub-espa%C3%B1ol.mp3",
      textoConfirmacion: " nuestra boda",
      esposo: "Óscar",
      esposa: "Fabiola",
      fechaDeBoda: "25 MAYO, 2024",
      imgPortada:
        "https://i.postimg.cc/6q6f4t9n/IMG-20240424-WA0003.jpg",
      titulo: "",
    };
  
    const datosSeccionContador = {
      titulo: "¡Nos Casamos!",
      descipcion:
        "Después de 8 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
      fechaNewDate: "May 25, 2024 17:30:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/vBtvmcdG/IMG-20240424-WA0000.jpg",
      fotoDerecha: "https://i.postimg.cc/VNMR3KGr/IMG-20240424-WA0002.jpg",
      marcoEnable: true,
      imgFondo: "",
      textColor: "",
      contador: true,
    };
  
    const datosInvitados = {
      titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
      entradasText: "disfrutar nuestro dia especial",
    };
  
    const datosSeccionPadres = {
      cantidad: 2,
      titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
      titulo1: "Padres de la Novia",
      madre: "Hermelinda Saenz luna",
      padre: "Juan Román Urzua Cárdenas",
      titulo2: "Padres del Novio",
      madrina: "Francisca López Mendez",
      padrino: "Pedro Luis Torres López",
      titulo3: "Padrinos del Novio2",
      madrina2: "Rosio Garibay Cervantes",
      padrino2: "Juan Carlos Lira Vázquez",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionCeremonia = {
      dobleEnable: false,
      ceremoniaEnable: true,
      ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
      ceremoniaHora: "5:30 p.m.",
      ceremoniaNombreLugar: "Parroquia de la Santa Cruz",
      ceremoniaLugarMap:
        "Calle Río Culiacan, Independencia, 21290 Mexicali, B.C.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/wmDnyYTH19C563re8",
      recepcionEnable: true,
      recepcionFecha: "Sábado, 25 de Mayo de 2024.",
      recepcionHora: "8:00 p.m.",
      recepcionNombreLugar: "Cena a las 9:00 p.m",
      recepcionLugarMap:
        "Jardin de Eventos La Arboleda",
      recepcionUrl:
        "https://maps.app.goo.gl/rYeX9jUqHPCACG4g8",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionProgramaBoda = {
      ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
      civil: "No te pierdas nuestro intercambio de votos",
      recepcion: "Tómate fotos con nosotros",
      cena: "Disfruta de una deliciosa cena",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
    };
  
    const datosSeccionVestimenta = {
      vestimenta: "FORMAL",
      messageVestimenta: "NO LLEVAR COLOR ROJO, BEIGE y BLANCO",
      urlVestimentaMujer: "",
      urlVestimentaHombre: "",
      imgFondo:
        "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
      textColor: "",
    };
  
    const datosSeccionHospedaje = {
      disponibleDos: false,
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionRegalos = {
      cantidadRegalos: 3,
      titulo: "Mesa de regalos",
      descripcion:
        "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
      link1:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      imgLink1: "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
      link1Enable: true,
      link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
      imgLink2: "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
      link2Enable: true,
      link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
      imgLink3:
        "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
      link3Enable: false,
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionConfirmacion = {
      tituloConfirmacion:
        "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
      aviso: "NO NIÑOS",
      numeroConfirmacion: "+526861279561",
      textoConfirmacion: "a la boda",
      textWhatsApp: "",
      textColor: "",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionInvitados = {
      imgFondo:
        "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
      titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
      entradasText: "disfrutar mis XV años",
      colorFondoName: "",
    };
  
    const contador = true;
    const padres = true;
    const hospedaje = false;
    const confirmacion = true;
    const Regalos = false;
    const ceremonia = true;
    const programa = false;
    const vestimenta = true;
  
    const images = [
      "https://i.postimg.cc/LXpTRjwy/IMG-20240424-WA0004.jpg",
      "https://i.postimg.cc/DwR6j9g3/IMG-20240424-WA0001.jpg",
      // Add more image URLs as needed
    ];
  
    const [messageWhats, setMessageWhats] = useState("");
    const [isOpen, setIsOpen] = useState(true);
  
    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
       
       <ModalCard isOpen={isOpen} setIsOpen={setIsOpen} datosSeccionPortada={datosSeccionPotadaBoda}/>        <PortadaBodaComponent
          datosSeccionPotada={datosSeccionPotadaBoda}
          audioFondo={audioFondo}
        />
  
        {contador ? (
          <ContadorBodaComponent
            peltaColores={peltaColores}
            datosSeccionContador={datosSeccionContador}
          />
        ) : (
          ""
        )}
  
        {padres ? (
          <PadresBodaComponent
            peltaColores={peltaColores}
            datosSeccionPadres={datosSeccionPadres}
          />
        ) : (
          ""
        )}
  
        {ceremonia ? (
          <CeremoniaXvComponent
            peltaColores={peltaColores}
            datosSeccionCeremonia={datosSeccionCeremonia}
          />
        ) : (
          ""
        )}
  
        {programa ? (
          <ProgramaBodaComponent
            peltaColores={peltaColores}
            datosSeccionPrograma={datosSeccionProgramaBoda}
          />
        ) : (
          ""
        )}
  
        {vestimenta ? (
          <VestimentaXvComponent
            peltaColores={peltaColores}
            datosSeccionVestimenta={datosSeccionVestimenta}
          />
        ) : (
          ""
        )}
  
        {hospedaje ? (
          <HospedajeXvComponent
            peltaColores={peltaColores}
            datosSeccionHospedaje={datosSeccionHospedaje}
          />
        ) : (
          ""
        )}
  
        {Regalos ? (
          <RegalosXvComponent
            peltaColores={peltaColores}
            datosSeccionRegalos={datosSeccionRegalos}
          />
        ) : (
          ""
        )}
  
        {confirmacion ? (
          <ConfirmacionXvComponent
            peltaColores={peltaColores}
            images={images}
            messageWhats={messageWhats}
            datosSeccionConfirmacion={datosSeccionConfirmacion}
          />
        ) : (
          ""
        )}
      </div>
    );
}
