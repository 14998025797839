import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiAstronautHelmet, GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/Interstellar.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodMariaYVictor = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: "a nuestra boda",
    esposo: "Víctor",
    esposa: "Maria",
    fechaDeBoda: "17 AGOSTO, 2024",
    fechaNewDate: "August 17, 2024 14:30:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/QxLGs8XS/IMG-0122.jpg",
    numeroNovia: "5580221334",
    numeroNovio: "4491516931",
    novios: true,
    textColor: "#ffff",
  };

  const peltaColores = {
    color1: "#04047b",
    color2: "#ffffff",
    messageVestimenta: "Mujeres no llevar vestimenta blanca",
    textColor: "#ffff",
  };

  const datosSeccion2 = {
    textColor: "#ffff",
    titulo: (
      <div className="text-center flex flex-col justify-center items-center">
        ¡Nos Casamos! <br />
        <GiAstronautHelmet />
      </div>
    ),
    descipcion:
      "El amor es la única cosa que podemos percibir y que trasciende dimensiones de tiempo y espacio.",
    fechaNewDate: "August 17, 2024 14:30:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/Kc3SdkyR/IMG-0092.jpg",
    fotoDerecha: "https://i.postimg.cc/76Cd8cdp/IMG-0226.jpg",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Maria y Víctor!",
    entradasText: "disfrutar nuestro dia especial",
    colorFondoName: "#04047b",
  };

  const datosSeccion3 = {
    textColor: "#ffff",
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Regina Guadalupe Uribe",
    esposaPadre: "Jorge Valdés",
    esposoMadre: "Maria del Rosario Álvarez",
    esposoPadre: "José Trinidad Morales",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 17 de Agosto de 2024.",
    ceremoniaHora: "2:30 p.m.",
    ceremoniaNombreLugar: "Iglesia Nacional Presbiteriana Príncipe de Paz",
    ceremoniaLugarMap:
      "Humboldt 50, Colonia Centro, Centro, Cuauhtémoc, 06040 Centro, CDMX",
    ceremoniaUrl: "https://maps.app.goo.gl/RFoiGn72ANuFXV8BA",
    recepcionFecha: "Sábado, 17 de Agosto de 2024.",
    recepcionHora: "7:00 p.m.",
    recepcionNombreLugar: "Salon Real San Jose",
    recepcionLugarMap:
      "Cuauhtémoc 7, San José, Iztapalapa, 09000 Ciudad de México, CDMX",
    recepcionUrl: "https://maps.app.goo.gl/i48RCDFqFDxDbTm77",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "¡Embárcate en una travesía encantadora mientras exploras nuestras opciones para contribuciones monetarias a la boda! Cada aporte refleja amor y consideración, convirtiendo tu participación en un gesto inolvidable.",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo = {
    fondoInvitados: 'url("https://i.postimg.cc/cLvtgKBT/IMG-1755.jpg"',
    fondoVestimenta:
      'url("https://p4.wallpaperbetter.com/wallpaper/137/163/699/stars-4k-8k-interstellar-wallpaper-preview.jpg")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [pases, setPases] = useState(0);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/C5k2d8F7/IMG_0183.jpg",
    "https://i.postimg.cc/jdY9mMXF/IMG_0157.jpg",
    "https://i.postimg.cc/dQyp5b8C/IMG_0161.jpg",
    "https://i.postimg.cc/SNW0xBR4/IMG_0171.jpg",
    "https://i.postimg.cc/bJKMzgjd/IMG_0202.jpg",
    "https://i.postimg.cc/kXNZmWYX/IMG_0238.jpg",
    "https://i.postimg.cc/L8HW6hGk/IMG_0250.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Angel Jaimes", guess: "2" },
    { name: "Invitado", guess: "1" },
    { name: "Invitados", guess: "2" },
    { name: "Jorge Valdés", guess: "2" },
    { name: "Juan", guess: "2" },
    { name: "Uriel", guess: "2" },
    { name: "Jorge", guess: "2" },
    { name: "Daniel", guess: "2" },
    { name: "Fidel Alejandro", guess: "2" },
    { name: "Maria del Rosario", guess: "6" },
    { name: "José", guess: "6" },
    { name: "Rosy", guess: "6" },
    { name: "Ezequiel", guess: "6" },
    { name: "Luz Kerena", guess: "3" },
    { name: "Itandehui", guess: "3" },
    { name: "Jaqueline", guess: "2" },
    { name: "Julieta", guess: "2" },
    { name: "Tania", guess: "3" },
    { name: "Tonantzi", guess: "2" },
    { name: "Marta Valdés", guess: "3" },
    { name: "Magdalena", guess: "2" },
    { name: "Azael", guess: "5" },
    { name: "Arodi", guess: "6" },
    { name: "Marisol", guess: "2" },
    { name: "Melva", guess: "2" },
    { name: "Marta", guess: "2" },
    { name: "Carmen", guess: "2" },
    { name: "Alizair", guess: "2" },
    { name: "Jesús", guess: "2" },
    { name: "Carmen y Gabriel", guess: "2" },
    { name: "Fam. Amezcua", guess: "4" },
    { name: "Valeria", guess: "2" },
    { name: "Miguel", guess: "2" },
    { name: "Roberto", guess: "2" },
    { name: "Ana", guess: "2" },
    { name: "Sofía", guess: "2" },
    { name: "Esteban", guess: "2" },
    { name: "Giselle", guess: "1" },
    { name: "Ines", guess: "4" },
    { name: "Sac- Nicté", guess: "3" },
    { name: "Alex", guess: "2" },
    { name: "Areli", guess: "3" },
    { name: "Noemí", guess: "3" },
    { name: "Mimi", guess: "4" },
    { name: "David Sánchez", guess: "2" },
    { name: "Celeste", guess: "3" },
    { name: "Omar", guess: "1" },
    { name: "Erick", guess: "1" },
    { name: "Azalia", guess: "4" },
    { name: "Minerva", guess: "2" },
    { name: "Abdiel", guess: "2" },
    { name: "Georgina", guess: "2" },
    { name: "Adelaida", guess: "4" },
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    console.log(nameId, numPases);
    setPases(numPases);

    const findName = names.find((e) => e.name === nameId);

    if (findName) {
      console.log(findName.name);

      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      console.log("Nombre no encontrado");
      window.location.href = "/";
    }

    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);

    const audioElement = document.getElementById("backgroundMusic");

    if (!isMusicPlaying && audioElement) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
  };

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Invitados
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        imagenesFondo={imagenesFondo}
        datosInvitados={datosInvitados}
      />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] sm:py-[5vh] text-white flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://mexico.listadodeiglesias.com/wp-content/uploads/2021/05/AF1QipOYPdgJ98epQ7qJlXKyXn7oQg7v5UDZjY7_laKSw408-h271-k-no.jpeg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15050.02731283699!2d-99.148158!3d19.4337032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8d4d422d8b7%3A0x5fd874602acc861a!2sIglesia%20Nacional%20Presbiteriana%20Pr%C3%ADncipe%20de%20Paz!5e0!3m2!1ses-419!2smx!4v1712161804468!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXPrwDRfsKayj3sDnGPUXHoLeT6WBDuXG-S4MKZ_G8Yw&s")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.0765317451983!2d-99.0886118!3d19.3658386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fdc29735576b%3A0xc60b9f627592cd87!2sSalon%20Real%20San%20Jose!5e0!3m2!1ses-419!2smx!4v1712161845736!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Vestimenta peltaColores={peltaColores} imagenesFondo={imagenesFondo} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] sm:py-[5vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />

              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <FaChampagneGlasses className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CÓCTEL</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.coctel}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] text-white flex flex-col sm:p-3 md:p-0 sm:py-[5vh] md:pt-[15vh] items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Datos Bancarios
          </h1> 
          <br />
          {/* <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p> */}

          <div className="grid sm:grid-cols-1 md:w-[50%] sm:w-[80%] ">
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/26PwqmpM/E3-A45653-72-CA-4-E1-B-B798-BC2-F34950-DF8.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto 100%",
                display: "flex",
                backgroundPosition: "center center",
              }}
              className="flex flex-col justify-end w-full rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            ></div>
          </div>
        </div>
      </div>

      <Confirmacion
        peltaColores={peltaColores}
        images={images}
        messageWhats={messageWhats}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
