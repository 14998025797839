import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { BsBrightnessHigh } from "react-icons/bs";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { IoFastFoodOutline } from "react-icons/io5";
import { HiOutlineCake } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaDulceYArmando = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEfubdtKZjp_y2mate.com---Coldplay-A-Sky-Full-of-Stars-Traducida-al-Espa%C3%B1ol.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Dulce",
    esposa: "Armando",
    fechaDeBoda: "01 JUNIO, 2024",
    imgPortada:
      "https://i.postimg.cc/d1rNSzzb/45473e3c-237b-45e2-b63e-736ce9e2ad50.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de un caminar juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    fechaNewDate: "June 01, 2024 13:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/nL6w2HwK/c1e4fd36-e954-4304-bc38-d35d3951f567.jpg",
    fotoDerecha:
      "https://i.postimg.cc/52KrH5BR/29014e05-a163-413c-9fa1-20479eb46987.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran boda inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Ma. Ramona Morales Lara",
    padre: "Margarito Vasquez Parra",
    titulo2: "Padres del Novio",
    madrina: "Ma. Mercedes Vargas Caudillo",
    padrino: "Víctor Felipe Guardado Perez",
    titulo3: "Padrinos del Novio2",
    madrina2: "Rosio Garibay Cervantes",
    padrino2: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 01 de Junio de 2024.",
    ceremoniaHora: "1:00 p.m.",
    ceremoniaNombreLugar: "Hacienda San Antonio El Rico SR. De La Caridad",
    ceremoniaLugarMap: "San Antonio El Rico, 36813 San Antonio el Rico, Gto.",
    ceremoniaUrl: "https://maps.app.goo.gl/gDsVeoWDku8oNo176",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 01 de Junio de 2024.",
    recepcionHora: "3:30 p.m.",
    recepcionNombreLugar: "",
    recepcionLugarMap:
      "Salón la fuente calle revolución #3 San Antonio el Rico",
    recepcionUrl: "https://maps.app.goo.gl/ahkWij2k62gFDQNj8",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Ceremonia",
    civil: "Comida",
    recepcion: "Aperitivos",
    cena: "Photocall",
    coctel: "Pastel",
    baile: "¡Brindis!",
    extra: "Iluminaremos la noche!!",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1:
      "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+524623064189",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/sDyqbgfw/364344b7-07d8-4e3b-8f18-d13e3719660a.jpg",
    "https://i.postimg.cc/MGcF1ctK/3fb1e203-6bde-469f-98b5-db170c525feb.jpg",
    "https://i.postimg.cc/Z55gKsdN/57233ff2-111b-4383-bf1f-54c2aee4bc9c.jpg",
    "https://i.postimg.cc/Nf4Stv5Y/42d5ada6-cdc8-4a9d-a9b3-bd817a0b27a2.jpg",
    "https://i.postimg.cc/7hNsqSPX/eeec2744-ea4d-4e72-9855-2ebbd007838f.jpg",
    "https://i.postimg.cc/Pf106wHC/9ced603a-e4a3-4f37-b955-b9a61eaa85a4.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://lh5.googleusercontent.com/p/AF1QipMHqdN4i0HNIUtOZjXTJY_Fxhzb8ZfZ0dkJGDEq=w426-h240-k-no")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3729.196780828964!2d-101.37500899999999!3d20.8237588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842b836453f7f421%3A0x199c9be04bd00cf3!2sHacienda%20San%20Antonio%20El%20Rico%20SR.%20De%20La%20Caridad!5e0!3m2!1ses-419!2smx!4v1714938492848!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccionCeremonia.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.etsystatic.com/23778625/r/il/d2a1fb/3226739197/il_570xN.3226739197_mii1.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3729.1781057866497!2d-101.37366152474898!3d20.824513180774247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjDCsDQ5JzI4LjMiTiAxMDHCsDIyJzE1LjkiVw!5e0!3m2!1ses-419!2smx!4v1714938445277!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccionCeremonia.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {programa ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionProgramaBoda.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,

            color: datosSeccionProgramaBoda.textColor
              ? datosSeccionProgramaBoda.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionProgramaBoda.ceremoniaReligiosa ? (
                  <>
                    <PiChurchLight className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">1:00 pm</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.ceremoniaReligiosa}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.recepcion ? (
                  <>
                    <IoFastFoodOutline className="text-[60px]"/>
                    <br />
                    <p className="font-serif text-xl text-center">2:30 pm</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.recepcion}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.civil ? (
                  <>
                    <ImSpoonKnife className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">
                      3:00 a 5:30 pm
                    </p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.civil}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>

              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {datosSeccionProgramaBoda.cena ? (
                  <>
                    <FaPhotoVideo className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">6:00 pm</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.cena}
                    </p>
                    <br />
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.coctel ? (
                  <>
                    <HiOutlineCake className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">6:30 pm</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.coctel}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.baile ? (
                  <>
                    <FaChampagneGlasses className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">7:00 pm</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.baile}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}

                {datosSeccionProgramaBoda.extra ? (
                  <>
                    <BsBrightnessHigh className="text-[60px]" />
                    <br />
                    <p className="font-serif text-xl text-center">8:00 pm</p>
                    <br />
                    <p className="font-serif text-xl text-center w-[60%]">
                      {datosSeccionProgramaBoda.extra}
                    </p>
                    <br />
                    <hr className="bg-gray-700 h-1 w-[60%]" />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
