import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses, FaWhatsapp } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaEdithYRafael = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const peltaColores = {
    color1: "#e6e9ea",
    color2: "#cfcfcf",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEfxRforcWR_y2mate.com---Back-To-The-Future-Earth-Angel-Sub-Espa%C3%B1ol-Ingl%C3%A9s-FMV.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Rafael",
    esposa: "Edith",
    fechaDeBoda: "30 NOVIEMBRE, 2024",
    imgPortada:
      "https://i.postimg.cc/tJhvrzVS/Whats-App-Image-2024-05-01-at-6-09-38-PM.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Ya lo tenemos todo… El traje, la decoración, las flores y la comida, pero aun nos falta algo super importante, tú presencia en nuestra boda!!",
    fechaNewDate: "November 30, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/1R6d5yYG/Whats-App-Image-2024-05-01-at-6-09-43-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/kGrhFHDR/Whats-App-Image-2024-05-01-at-6-09-37-PM.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    titulo1: "Padres de la Esposa",
    madre: "Maricela Saucedo",
    padre: "✟Bernardo González✟",
    titulo2: "Mamá del Esposo",
    madrina: "Carmen Alarcón",
    padrino: "",
    titulo3: "Padrinos de velación",
    madrina2: "Consuelo Hernández",
    padrino2: "Marco González",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 30 de Noviembre de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Templo de Nuestra Señora del Carmen",
    ceremoniaLugarMap: "Del Carmen 116, Zona Centro, 44100 Guadalajara, Jal.",
    ceremoniaUrl: "https://maps.app.goo.gl/TdsAiKBrLYBiib33A",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 30 de Noviembre de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Catania Eventos Toluquilla",
    recepcionLugarMap:
      "Aquiles Serdán, Tlaquepaque, 45610 San Pedro Tlaquepaque, Jal.",
    recepcionUrl: "https://maps.app.goo.gl/NJBJUShmB9Pjfgb49",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "City Express Junior by Marriott",
    hotel1lugar:
      "Anillo Perif. Sur Manuel Gómez Morín 5964, Artesanos, 45590 Guadalajara, Jal.",
    hotel1url:
      "https://www.google.com/maps/place/City+Express+Junior+by+Marriott+Guadalajara+Perif%C3%A9rico+Sur/@20.5785564,-103.345508,15z/data=!4m20!1m10!3m9!1s0x8428b2b15cb6f17d:0xcd435efeb5828d97!2sCity+Express+Junior+by+Marriott+Guadalajara+Perif%C3%A9rico+Sur!5m2!4m1!1i2!8m2!3d20.5785564!4d-103.345508!16s%2Fg%2F1tr7d3bv!3m8!1s0x8428b2b15cb6f17d:0xcd435efeb5828d97!5m2!4m1!1i2!8m2!3d20.5785564!4d-103.345508!16s%2Fg%2F1tr7d3bv?entry=ttu",
    hotel2Titulo: "Radisson Hotel",
    hotel2lugar:
      "A Chapala Km 6.5, El Tapatío, 45588 San Pedro Tlaquepaque, Jal.",
    hotel2url:
      "https://www.google.com/maps/place/Radisson+Hotel+Tapatio+Guadalajara/@20.6114095,-103.3239784,17z/data=!4m9!3m8!1s0x8428b2fb1eda792f:0x3d6099dfb010c41f!5m2!4m1!1i2!8m2!3d20.6114095!4d-103.3214035!16s%2Fg%2F1q64jdwj3?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "¡Gracias por creer en nosotros y acompañarnos en está ocasión tan especial!, Si quieres tener un detalle con nosotros preferimos recibirlo en nuestra cuenta BBVA 298 970 4515 con Concepto: ‘’Regalo de Boda’’ o bien en un buzón que tendremos en nuestra mesa, no olvides incluir un mensaje de buenos deseos.   ",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/132zbD1N/Beige_Money_Pattern_Square_Pillow.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "",
    numeroConfirmacion: "+523324924592",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+523324924592",
    numeroNovio: "+523315848534",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/tJhvrzVS/Whats-App-Image-2024-05-01-at-6-09-38-PM.jpg",
    "https://i.postimg.cc/1R6d5yYG/Whats-App-Image-2024-05-01-at-6-09-43-PM.jpg",
    "https://i.postimg.cc/kGrhFHDR/Whats-App-Image-2024-05-01-at-6-09-37-PM.jpg",
    "https://i.postimg.cc/q75FgqTL/Whats-App-Image-2024-05-01-at-6-09-39-PM.jpg",
    "https://i.postimg.cc/7ZPtV0P9/Whats-App-Image-2024-05-01-at-6-09-39-PM-1.jpg",
    "https://i.postimg.cc/wTQbL0z3/Whats-App-Image-2024-05-01-at-6-09-40-PM.jpg",
    "https://i.postimg.cc/X7bzrtTT/Whats-App-Image-2024-05-01-at-6-09-40-PM-1.jpg",
    "https://i.postimg.cc/5t67cJCF/Whats-App-Image-2024-05-01-at-6-09-41-PM.jpg",
    "https://i.postimg.cc/QtKy7DNr/Whats-App-Image-2024-05-01-at-7-21-48-PM.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Fam. Saucedo", guess: "3", id: 1 },
    { name: "Fam. Villalvazo González", guess: "2", id: 2 },
    { name: "Fam. González Frias", guess: "4", id: 3 },
    { name: "Fam. Hernandez López", guess: "4", id: 4 },
    { name: "Fam. Acosta Saucedo", guess: "2", id: 5 },
    { name: "Fam. Acosta Vázquez", guess: "5", id: 6 },
    { name: "Fam. Saucedo Bermudez", guess: "6", id: 7 },
    { name: "Alondra Saucedo", guess: "3", id: 8 },
    { name: "Fam. Saavedra Acosta", guess: "4", id: 9 },
    { name: "Fam. Mendoza Acosta", guess: "4", id: 10 },
    { name: "Fam. González Pérez", guess: "4", id: 11 },
    { name: "Natali González", guess: "5", id: 12 },
    { name: "Erika González", guess: "2", id: 13 },
    { name: "Fam. González Hernández", guess: "6", id: 14 },
    { name: "Federico Saucedo", guess: "1", id: 15 },
    { name: "Dolores Saucedo", guess: "1", id: 16 },
    { name: "Fam. González Villalvazo", guess: "2", id: 17 },
    { name: "Gloria González", guess: "1", id: 18 },
    { name: "Madai Saucedo", guess: "3", id: 19 },
    { name: "Martha Saucedo", guess: "2", id: 20 },
    { name: "Fam. Alarcón Salas", guess: "9", id: 21 },
    { name: "Jaime Alarcón", guess: "3", id: 22 },
    { name: "Carlos Venegas", guess: "2", id: 23 },
    { name: "Miriam Venegas", guess: "4", id: 24 },
    { name: "Juan Pablo Toscano", guess: "6", id: 25 },
    { name: "Aranzazu Alarcón", guess: "5", id: 26 },
    { name: "Diego Alarcón", guess: "4", id: 27 },
    { name: "Carmen Alarcón", guess: "4", id: 28 },
    { name: "Dina González", guess: "4", id: 29 },
    { name: "Marisol Nuñez", guess: "2", id: 30 },
    { name: "Karla Silva", guess: "3", id: 31 },
    { name: "Leti González", guess: "2", id: 32 },
    { name: "Esmeralda Flores", guess: "2", id: 33 },
    { name: "Francisco Viorato", guess: "2", id: 34 },
    { name: "Martin Iturrubiates", guess: "2", id: 35 },
    { name: "Elsa Rodriguez", guess: "2", id: 36 },
    { name: "Tomas Martinez", guess: "2", id: 37 },
    { name: "Norma Vazquez", guess: "2", id: 38 },
    { name: "Eric Knell", guess: "2", id: 39 },
    { name: "Vanesa Cabrera", guess: "2", id: 40 },
    { name: "Patricia González", guess: "2", id: 41 },
    { name: "Felipe Delgado", guess: "2", id: 42 },
    { name: "Jorge Santiago", guess: "2", id: 43 },
    { name: "Felipe Torres", guess: "2", id: 44 },
    { name: "Carlos Monroi", guess: "2", id: 45 },
    { name: "Sarahi Lozano", guess: "2", id: 46 },
    { name: "Fam. Baquerie Alarcón", guess: "5", id: 47 },
    { name: "Gabriela Baquerie", guess: "2", id: 48 },
    { name: "Laura Alarcón", guess: "2", id: 49 },
    { name: "Leticia Valdivia", guess: "3", id: 50 },
    { name: "Eduardo Alarcón", guess: "2", id: 51 },
    { name: "Paty Velez", guess: "1", id: 52 },
    { name: "Alfredo Saucedo", guess: "2", id: 53 },
  ];
  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
            backgroundRepeat: "repeat",
            backgroundSize: "cover", // or "100% auto"
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionConfirmacion.textColorConfirm || "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col py-[5vh] justify-center items-center w-full"
        >
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionConfirmacion.tituloConfirm || " Mi Día Especial"}
          </h1>
          <br />
          <p
            className=" text-center text-white p-[5vh] font-serif text-xl sm:w-[80%] md:w-[50%] rounded-2xl"
            data-aos="flip-up"
            style={{
              background: "rgb(0,0,0)",
              background:
                "linear-gradient(0deg, rgba(0,0,0,0.7596288515406162) 28%, rgba(0,0,0,0.7596288515406162) 68%)",
            }}
          >
            Compartir estos momentos con ustedes los hace inolvidables.
          </p>
          <br />
          <br />
          <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
            {" "}
            {datosSeccionConfirmacion.aviso.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>
          <br />
          <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
            {images?.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[70vh]"
                />
              </div>
            ))}
          </Slider>
          <br />
          <br />
          <div
            data-aos="fade-down-left"
            className="md:w-[50%] sm:w-[80%]  mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
          >
            <h2 className="text-2xl font-bold mb-4 justify-center flex text-black">
              Confirmación de Asistencia{" "}
              {datosSeccionConfirmacion.textoConfirmacion}{" "}
              <FaWhatsapp className="text-green-600 ml-2" />
            </h2>
            <div className="flex justify-center items-center">
              <div className="w-full text-center bg-green-700 p-2 mt-5 mx-2 rounded-lg">
                <a
                  href={`https://api.whatsapp.com/send?phone=${
                    datosSeccionConfirmacion.numeroNovia
                  }&text=${encodeURIComponent(messageWhats)}`}
                  className="text-white text-center md:w-[60%] md:text-xl text-auto"
                >
                  Confirma tu invitación con la novia 💌
                </a>
              </div>
              <div className="w-full text-center bg-green-700 p-2 mt-5 rounded-lg">
                <a
                  href={`https://api.whatsapp.com/send?phone=${
                    datosSeccionConfirmacion.numeroNovio
                  }&text=${encodeURIComponent(messageWhats)}`}
                  className="text-white text-center md:w-[60%] md:text-xl text-auto"
                >
                  Confirma tu invitación con el novio 💌
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
