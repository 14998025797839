import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./WeekndCan.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";

export const XvLauraJanett = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Laura Janett",
      fechaDeBoda: "11 MAYO, 2024",
      fechaNewDate: "May 11, 2024 18:30:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/2687J8BZ/Fondo-de-Pantalla-Gradiente-Rosa-y-Celeste-Pastel-2.png",
      numeroNovia: "5531155456",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#736773",
      color2: "#7e6982",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Laura Janett!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí Y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "May 11, 2024 18:30:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/tJVpH61Z/Dise-o-sin-t-tulo-5.png",
      fotoDerecha: "https://i.postimg.cc/CMZqK6kD/Dise-o-sin-t-tulo-2.png",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Rocio Suárez",
      esposaPadre: "Juan Martínez",
      esposoMadre: "Patricia Sánchez",
      esposoPadre: "Jorge Guerrero",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 11 de Mayo de 2024.",
      ceremoniaHora: "6:30 p.m.",
      ceremoniaNombreLugar: "Salón girasoles",
      ceremoniaLugarMap:
        "Av. Manuel Morelos 227, Morelos 1ra Secc, 55718 San Francisco Coacalco, Méx.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/gVWLVWen8EUNDuqD9",
      recepcionFecha: "Sábado, 11 de Mayo de 2024.",
      recepcionHora: "6:30 p.m.",
      recepcionNombreLugar: "Salón girasoles",
      recepcionLugarMap:
        "Av. Manuel Morelos 227, Morelos 1ra Secc, 55718 San Francisco Coacalco, Méx.",
      recepcionUrl:
        "https://maps.app.goo.gl/gVWLVWen8EUNDuqD9",
    };
  
  
    const images = [
        "https://i.postimg.cc/tJVpH61Z/Dise-o-sin-t-tulo-5.png",
        "https://images.vexels.com/media/users/3/194543/isolated/preview/56affe8e232e558cad64be081dcf5677-adorno-de-trazo-de-vestido-de-quinceanera.png",
        "https://i.postimg.cc/CMZqK6kD/Dise-o-sin-t-tulo-2.png",
      "https://dbdzm869oupei.cloudfront.net/img/sticker/preview/48814.png",
      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500 text-white">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[10vh]">
            <div></div>
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              
              <div className="flex">
              <PiChurchLight className="text-[80px]" />
              <FaChampagneGlasses className="text-[80px]" />
              </div>
              <br />
              <p className="font-serif text-2xl">Ceremonia y Recepción</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion4.ceremoniaFecha}
              </p>
              <p className="font-serif text-xl">&</p>
              <p className="font-serif text-xl text-center">
                {datosSeccion4.ceremoniaHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccion4.ceremoniaNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.ceremoniaLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.ceremoniaUrl}
                class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
