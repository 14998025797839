import React from "react";

export const PadresBodaComponent = ({ peltaColores, datosSeccionPadres }) => {
  // Aplica el tipo de letra si está definido
  const fontFamilyStyle = {
    fontFamily: datosSeccionPadres.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionPadres.textColor || "",
        ...fontFamilyStyle, // Aplicar el tipo de letra a todo el div
      }}
      className="sm:h-auto md:h-auto py-[5vh] px-3 flex flex-col items-center w-full"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <h1
          className="text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
          style={fontFamilyStyle} // Aplicar el tipo de letra al h1
        >
          {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))}
        </h1>
        <br />
        {datosSeccionPadres.descripcion ? (
          <p
            className="text-center pt-[5vh] text-xl"
            data-aos="flip-up"
            style={fontFamilyStyle} // Aplicar el tipo de letra al p
          >
            {datosSeccionPadres.descripcion.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>
        ) : ""}
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-${datosSeccionPadres.cantidad || "1"} w-[80%] pt-[10vh]`}
        >
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            <p className="font-semibold text-center text-2xl" style={fontFamilyStyle}>
              {datosSeccionPadres.titulo1}
            </p>
            <br />
            <p className="text-center text-xl" style={fontFamilyStyle}>
              {datosSeccionPadres.madre}
            </p>
            {datosSeccionPadres.padre ? (
              <p className="text-center text-xl" style={fontFamilyStyle}>&</p>
            ) : ""}
            <p className="text-center text-xl" style={fontFamilyStyle}>
              {datosSeccionPadres.padre}
            </p>
          </div>

          {datosSeccionPadres.cantidad == 2 || datosSeccionPadres.cantidad == 3 ? (
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl" style={fontFamilyStyle}>
                {datosSeccionPadres.titulo2}
              </p>
              <br />
              <p className="text-center text-xl" style={fontFamilyStyle}>
                {datosSeccionPadres.madrina}
              </p>
              {datosSeccionPadres.padrino ? (
                <p className="text-center text-xl" style={fontFamilyStyle}>&</p>
              ) : ""}
              <p className="text-center text-xl" style={fontFamilyStyle}>
                {datosSeccionPadres.padrino}
              </p>
            </div>
          ) : ""}
          
          {datosSeccionPadres.cantidad == 3 ? (
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl" style={fontFamilyStyle}>
                {datosSeccionPadres.titulo3}
              </p>
              <br />
              <p className="text-center text-xl" style={fontFamilyStyle}>
                {datosSeccionPadres.madrina2}
              </p>
              {datosSeccionPadres.padrino2 ? (
                <p className="text-center text-xl" style={fontFamilyStyle}>&</p>
              ) : ""}
              <p className="text-center text-xl" style={fontFamilyStyle}>
                {datosSeccionPadres.padrino2}
              </p>
            </div>
          ) : ""}
        </div>
      </div>
    </div>
  );
};
