import React from "react";

export const RegalosXvComponentD2 = ({ peltaColores, datosSeccionRegalos }) => {
  // Definimos el estilo de la fuente
  const fontFamilyStyle = {
    fontFamily:
      datosSeccionRegalos.tipoLetra ||
      "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  console.log("Tipo de letra regalos", datosSeccionRegalos.tipoLetra)

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionRegalos.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionRegalos.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col py-[5vh] px-3 items-center w-full"
    >
      <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
        <h1
          className="text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionRegalos.titulo || "Mesa de regalos"}
        </h1>
        <br />
        <p
          className="text-center pt-[5vh] text-xl sm:max-w-[90%] md:max-w-[60%]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionRegalos.descripcion.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))}
        </p>

        <div
          className={`grid sm:grid-cols-1 md:grid-cols-${
            datosSeccionRegalos.cantidadRegalos || 2
          } w-[80%] pt-[3vh]`}
        >
          <div
            style={{
              backgroundPosition: "center center",
              backgroundColor: peltaColores.color1,
            }}
            className="grid grid-rows-2 justify-center w-[100%] rounded-2xl md:mx-2 items-center bg-slate-100 h-[30vh]"
            data-aos="fade-right"
          >
            <img
              className="h-full"
              src={`${
                datosSeccionRegalos.imgLink1 ||
                "https://static.vecteezy.com/system/resources/previews/019/766/223/non_2x/amazon-logo-amazon-icon-transparent-free-png.png"
              }`}
              alt=""
            />
            <div className="flex flex-col justify-center items-center">
              <p style={fontFamilyStyle}>123872138204</p>
              {datosSeccionRegalos.link1Enable ? (
                <a
                  href={datosSeccionRegalos.link1}
                  style={fontFamilyStyle}
                  className=" text-center border-none text-xl font-bold cursor-pointer"
                >
                  Ver mesa!
                </a>
              ) : (
                ""
              )}
            </div>
          </div>

          {datosSeccionRegalos.cantidadRegalos == 2 ||
          datosSeccionRegalos.cantidadRegalos == 3 ? (
            <div
              style={{
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="grid grid-rows-2 justify-center w-[100%] rounded-2xl md:mx-2 sm:mt-2 md:mt-0 items-center bg-slate-100 h-[30vh]"
              data-aos="fade-right"
            >
              <img
                className="h-full"
                src={`${
                  datosSeccionRegalos.imgLink2 ||
                  "https://static.vecteezy.com/system/resources/previews/019/766/223/non_2x/amazon-logo-amazon-icon-transparent-free-png.png"
                }`}
                alt=""
              />
              <div className="flex flex-col justify-center items-center">
                <p style={fontFamilyStyle}>123872138204</p>
                {datosSeccionRegalos.link2Enable ? (
                  <a
                    href={datosSeccionRegalos.link2}
                    style={fontFamilyStyle}
                    className=" text-center border-none text-xl font-bold cursor-pointer"
                  >
                    Ver mesa!
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {datosSeccionRegalos.cantidadRegalos == 3 ? (
            <div
              style={{
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="grid grid-rows-2 justify-center w-[100%] rounded-2xl md:mx-2 sm:mt-2 md:mt-0 items-center bg-slate-100 h-[30vh]"
              data-aos="fade-right"
            >
              <img
                className="h-full"
                src={`${
                  datosSeccionRegalos.imgLink3 ||
                  "https://static.vecteezy.com/system/resources/previews/019/766/223/non_2x/amazon-logo-amazon-icon-transparent-free-png.png"
                }`}
                alt=""
              />
              <div className="flex flex-col justify-center items-center">
                <p className="">123872138204</p>
                {datosSeccionRegalos.link3Enable ? (
                  <a
                    href={datosSeccionRegalos.link3}
                    className=" text-center border-none text-xl font-bold cursor-pointer"
                  >
                    Ver mesa!
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
