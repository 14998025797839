import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./assets/DuelesMana.mp3";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import Slider from "react-slick";
import { FaWhatsapp } from "react-icons/fa6";
import videoMartin from "./assets/videoMartin2.mp4";
import { FaPhotoVideo } from "react-icons/fa";

export const EventoMartinWinder = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ffff",
    color2: "#fff",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_04/NDyvfTVEZR1_musicaEpica.MP3",
    imgPortada: "https://i.postimg.cc/hP1qdsV2/logo-WTF.jpg",
    nombre: "",
    fechaDeBoda: "18 OCTUBRE, 2024",
    titulo: (
      <div>
        ESTUDIOS <br />
        CHURUBUSCO
      </div>
    ),
  };

  const datosSeccionContador = {
    titulo: "¡World Therapy Fest 2024!",
    descipcion:
      "Te invitamos al mejor evento de desarrollo espiritual, conciencia y salud del 2024 \\n\\n ¡Descubre el evento imperdible del año! Únete a nosotros en el World Therapy Fest 2024, una experiencia transformadora que se llevará a cabo en la Ciudad de México, el 18 de octubre. \\nEste evento contará con la participación de destacadas personalidades internacionales como: el Príncipe de Camerún Jean Louis Bingna, el Actor y Guía Espiritual Valentino Lanús, Dra. Lirio Herrera, Dra. Roció Clapés, Dr. Roberto Gama, Dr. Jorge Hidalgo, Dr. Andrés Blanco y Dr. Emilio Winder Organizador de World Therapy Fest. \\n\\n Iniciamos \\n\\n13 horas\\n\\n  Finalizamos\\n\\n 20 horas\\n\\n Número de Conferencias\\n\\n 10 presentaciones \\n\\n Para \\n\\n Adolecentes, Adultos y Adulto Mayor",
    fechaNewDate: "Octover 18, 2024 13:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/59PmqxJR/CARTEL-WTF.jpg",
    fotoDerecha: "https://i.postimg.cc/85gJb9K5/ESTRELLA-1.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Magdalena Vázquez Prieto",
    padre: "Adolfo Lira González",
    madrina: "Rosio Garibay Cervantes",
    padrino: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 23 de Diciembre de 2024.",
    ceremoniaHora: "1:00 p.m. a 8:00 pm",
    ceremoniaNombreLugar: "Parroquia del Divino Salvador",
    ceremoniaLugarMap:
      "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
    ceremoniaUrl:
      "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 18 de Octubre de 2024.",
    recepcionHora: "1:00 p.m. a 8:00 pm",
    recepcionNombreLugar: "Estudios Churubusco Azteca",
    recepcionLugarMap:
      "C. Atletas 2, Country Club Churubusco, Coyoacán, 04210 Ciudad de México, CDMX",
    recepcionUrl: "https://maps.app.goo.gl/WfEVANcoYe5KNggU7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/0QV7fCTw/ESTRELLA-1.jpg",
    titulo: "¡Has sido invitado al World Therapy Fest 2024!",
    entradasText: "disfrutar el evento",
    colorFondoName: "#393937c2",
    imgInvitados:
      "https://images.vexels.com/media/users/3/143357/isolated/preview/cb7c5470e3d788ae7db96c9f56a23bd2-destello-de-lente-estrella-de-seis-puntos.png",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Es tiempo para el cambio \\n\\nEs tiempo para sanar \\n\\nDe despertar \\n\\nElige amar \\n\\nElige amarte \\n\\nQuien mira hacia afuera, sueña \\n\\nQuien mira hacia adentro, despierta \\n\\nPero quien respira con conciencia vive de verdad ",
    numeroConfirmacion: "5547686114",
    textoConfirmacion: "al Evento",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/J4vcvPG3/grupo.jpg",
    "https://i.postimg.cc/pLjYHzFw/WTF-PRINCIPAL.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = false;

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Samuel Arroyo", guess: "3", id: 2 },
  ];

  const targetDate = new Date(
    datosSeccionContador.fechaNewDate || ""
  ).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const [Correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, soy ${name} y confirmo mi asistencia al evento con ${guests} invitado(s), mi correo electrónico es ${Correo} y mi numero de teléfono es ${telefono}.`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccionConfirmacion.numeroConfirmacion
    }&text=${encodeURIComponent(message)}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  const [qr, setQr] = useState(0);

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} /> : ""}

      <div
        style={{
          backgroundImage: `url(${datosSeccionPortada.imgPortada})`,
          backgroundRepeat: "no-repeat",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundSize: "100% 100%",
        }}
        className="bg-slate-950 flex flex-col md:bg-full justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPortada.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
          <button
            onClick={handlePlayButtonClick}
            className="px-4 text-3xl py-2 rounded animate-bounce"
          >
            {isMusicPlaying ? "🔊" : "🔇"}
          </button>
          <br />
          <br />
          <h1
            className="font-serif sm:text-4xl md:text-[132px] text-white text-center flex"
            data-aos="flip-up"
          >
            {datosSeccionPortada.nombre.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <br />
          <br />
          <p
            className="text-white p-3 rounded-lg font-serif text-2xl text-center"
            data-aos="fade-up"
          >
            - {datosSeccionPortada.titulo} - <br />{" "}
            {datosSeccionPortada.fechaDeBoda}
          </p>
          <br />
        </div>
      </div>
      {contador ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionContador.textColor
              ? datosSeccionContador.textColor
              : "",
          }}
          className="sm:h-auto md:h-auto flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col pt-[10vh] w-full items-center">
            <h1
              className="font-serif  sm:text-[52px] md:text-[92px] "
              data-aos="flip-up"
            >
              {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
              data-aos="flip-up"
            >
              {datosSeccionContador.descipcion
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
            <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "45vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-md"
                data-aos="fade-right"
              ></div>
              {datosSeccionContador.contador ? (
                <div
                  className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
                  data-aos="fade-up"
                  style={{
                    border: "15px solid transparent",
                    padding: "15px",
                    borderImage: datosSeccionContador.marcoEnable
                      ? "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')"
                      : "",
                    borderImageSlice: "46",
                    borderImageWidth: "round",
                  }}
                >
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.days}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Días</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.hours}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.minutes}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.seconds}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "45vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-md sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
                data-aos="fade-left"
              ></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      <div className="w-full py-24 px-14 font-serif  sm:text-[52px] md:text-[92px] text-white ">
        <h1>
          Acceso QR
        </h1>
        <br />
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={qr}
          viewBox={`0 0 256 256`}
        />
      </div> */}

      {ceremonia ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionCeremonia.textColor || "",
          }}
          className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionCeremonia.dobleEnable ? "1" : "1"
              } w-[80%] pt-[10vh]`}
            >
              {datosSeccionCeremonia.recepcionEnable ? (
                <div
                  className="flex flex-col justify-center items-center sm:pt-[5vh] md:pt-0"
                  data-aos="fade-right"
                >
                  <FaPhotoVideo className="text-[80px]" />
                  <br />
                  <p className="font-serif text-2xl">Evento</p>
                  <br />
                  <hr className="bg-amber-700 h-2 w-[80%]" />
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.recepcionFecha}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.recepcionHora}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center ">
                    {datosSeccionCeremonia.recepcionNombreLugar}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.recepcionLugarMap}
                  </p>
                  <br />
                  <a
                    href={datosSeccionCeremonia.recepcionUrl}
                    class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
                  >
                    Ver mapa!
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto py-10 text-white md:h-auto flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center p-2 sm:text-[28px] md:text-[42px] text-black"
            data-aos="flip-up"
          >
            Participaciones Destacadas:
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col my-2 md:px-2 w-[100%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[50vh]"
              data-aos="fade-right"
            >
              <img
                src="https://i.postimg.cc/DzGBRMhD/ANDR-S-BLANCO.jpg
"
                alt=""
                className="w-full h-full"
              />
            </div>
            <div
              className="flex flex-col my-2 md:px-2 w-[100%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[50vh]"
              data-aos="fade-right"
            >
              <img
                src="https://i.postimg.cc/zDFPJ6hY/Jorge-Hidalgo.jpg"
                alt=""
                className="w-full h-full"
              />
            </div>
            <div
              className="flex flex-col my-2 md:px-2 w-[100%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[50vh]"
              data-aos="fade-right"
            >
              <img
                src="https://i.postimg.cc/28HcWWTH/Karola-Espinosa.jpg
"
                alt=""
                className="w-full h-full"
              />
            </div>
            <div
              className="flex flex-col my-2 md:px-2 w-[100%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[50vh]"
              data-aos="fade-right"
            >
              <img
                src="https://i.postimg.cc/tTH2bNCg/LIRIO-HERRERA.jpg"
                alt=""
                className="w-full h-full"
              />
            </div>
            <div
              className="flex flex-col my-2 md:px-2 w-[100%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[50vh]"
              data-aos="fade-right"
            >
              <img
                src="https://i.postimg.cc/D0v6JfGk/PRINCIPE.jpg"
                alt=""
                className="w-full h-full"
              />
            </div>
            <div
              className="flex flex-col my-2 md:px-2 w-[100%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[50vh]"
              data-aos="fade-right"
            >
              <img
                src="https://i.postimg.cc/V6B4k0dW/VALENTINO-LANUS.jpg"
                alt=""
                className="w-full h-full"
              />
            </div>
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-1 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[100%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://media.resumen.cl/wp-content/uploads/2022/10/estudios-churubusco-31-1400x788.80.jpeg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15057.259874378835!2d-99.1426579!3d19.35551!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fe339d66b6d3%3A0x3dd5fe2638b6dc8d!2sEstudios%20Churubusco%20Azteca!5e0!3m2!1ses!2smx!4v1713847486186!5m2!1ses!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccionCeremonia.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa del Evento
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative py-[25vh]" style={{ paddingBottom: "56.25%" }}>
        <video
          className="absolute inset-0 w-full h-full object-cover"
          src={videoMartin}
          controls
          autoPlay
          loop
          muted
        />
      </div>

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionConfirmacion.textColor
              ? datosSeccionConfirmacion.textColor
              : "",
          }}
          className="sm:h-auto md:h-auto flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
        >
          <h1
            className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionConfirmacion.tituloConfirmacion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </h1>
          <br />
          <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
            {datosSeccionConfirmacion.aviso || ""}
          </p>
          <br />
          <br />
          <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
            {images?.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[60vh]"
                />
              </div>
            ))}
          </Slider>
          <br />
          <br />
          <div className="flex justify-center items-center w-full p-3">
            <a
              href="https://www.facebook.com/profile.php?id=61553911351305&mibextid=LQQJ4d"
              className="p-4"
            >
              <img
                src="https://talestin.com/wp-content/uploads/2023/03/Facebook-logo-blue-circle-large-transparent-png.png"
                alt=""
                width={80}
              />
            </a>
          </div>
          <div
            data-aos="fade-down-left"
            className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
          >
            <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
              {datosSeccionConfirmacion.textWhatsApp ||
                "Confirmación de Asistencia al Evento"}{" "}
              <FaWhatsapp className="text-green-600 ml-2" />
            </h2>
            <form onSubmit={handleSubmit}>
              <label className="mb-2 flex">
                <span className="text-gray-700">Nombre Completo:</span>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  required
                />
              </label>
              <br />
              <label className="mb-2 flex">
                <span className="text-gray-700">Correo Electrónico:</span>
                <input
                  type="text"
                  value={Correo}
                  onChange={(e) => setCorreo(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  required
                />
              </label>
              <br />
              <label className="mb-2 flex">
                <span className="text-gray-700">Número de Teléfono Móvil:</span>
                <input
                  type="text"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  required
                />
              </label>
              <br />
              <label className="flex mb-2">
                <span className="text-gray-700">
                  Numero de Espacios Requeridos:
                </span>
                <div className="px-3">
                  <input
                    type="radio"
                    id="guests1"
                    name="guests"
                    value="1"
                    checked={guests === "1"}
                    onChange={() => setGuests("1")}
                    className="mr-1"
                  />
                  <label htmlFor="guests1">1</label>
                  <input
                    type="radio"
                    id="guests2"
                    name="guests"
                    value="2"
                    checked={guests === "2"}
                    onChange={() => setGuests("2")}
                    className="ml-2 mr-1"
                  />
                  <label htmlFor="guests2">2</label>
                  <input
                    type="radio"
                    id="guests2"
                    name="guests"
                    value="3"
                    checked={guests === "3"}
                    onChange={() => setGuests("3")}
                    className="ml-2 mr-1"
                  />
                  <label htmlFor="guests2">3</label>
                  <input
                    type="radio"
                    id="guests2"
                    name="guests"
                    value="4"
                    checked={guests === "4"}
                    onChange={() => setGuests("4")}
                    className="ml-2 mr-1"
                  />
                  <label htmlFor="guests2">4</label>
                  <input
                    type="radio"
                    id="guests2"
                    name="guests"
                    value="5"
                    checked={guests === "5"}
                    onChange={() => setGuests("5")}
                    className="ml-2 mr-1"
                  />
                  <label htmlFor="guests2">5</label>
                </div>
              </label>
              <br />
              <label className="mb-2 flex">
                <span className="text-gray-700">
                  Costo de Acceso $1,500 pesos mexicanos
                </span>
              </label>
              <br />

              <button
                type="submit"
                className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
              >
                Confirmar Asistencia
              </button>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
