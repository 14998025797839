import React, { useEffect, useState } from 'react'
import AOS from "aos";

export const PortadaBodaBautizoComponent = ({datosSeccionPotada, audioFondo}) => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);

    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    const handlePlayButtonClick = () => {
      setIsMusicPlaying(!isMusicPlaying);
    };
  
    useEffect(() => {
      const audioElement = document.getElementById('backgroundMusic');
      if (isMusicPlaying) {
        audioElement.play();
      } else {
        audioElement.pause();
        audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
      }
    }, [isMusicPlaying]);
    return (
      <div
          style={{
            backgroundImage: `url(${datosSeccionPotada.imgPortada})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh", // Ajusta la altura del contenedor
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="bg-slate-950 flex flex-col justify-center items-center w-full"
        >
          <div
            className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
            style={{ backgroundColor: "rgb(0 0 0 / 22%)" }}
          >
            <audio
            id="backgroundMusic"
            style={{ display: 'none' }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPotada.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
            <button
              onClick={handlePlayButtonClick}
              className=" px-4 text-3xl py-2 rounded animate-bounce"
            >
              {isMusicPlaying ? "🔊" : "🔇"}
            </button>
            <br />
            <br />
            <h1
              className="font-serif sm:text-4xl md:text-[132px] text-white text-center flex"
              data-aos="flip-up"
            >
               {datosSeccionPotada.esposa}{" "}
              <span>
                <img
                  src="https://i.postimg.cc/6Qqt7rrB/Dise-o-sin-t-tulo-15.png"
                  className="sm:h-[62px] md:h-[138px]"
                  alt=""
                />
              </span>{" "}
              {datosSeccionPotada.esposo}
            </h1>
            <h1
              className="font-serif sm:text-4xl md:text-[132px] text-white text-center flex"
              data-aos="flip-up"
            >
               {datosSeccionPotada.bautizada}{" "}
            </h1>
            <br />
            <br />
            <p
              className="text-white p-3 rounded-lg font-serif text-2xl text-center"
              data-aos="fade-up"
            >
            - {datosSeccionPotada.titulo || 'CEREMONIA RELIGIOSA'} -    <br />- {datosSeccionPotada.fechaDeBoda} -
            </p>
            <br />
          </div>
        </div>
    )
}
