import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "../xv-diamante/NoCrezcas.mp3";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import Slider from "react-slick";
import { FaWhatsapp } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

export const XvLucia = () => {
  const [isOpen, setIsOpen] = useState(false);

  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pases = searchParams.get("pases");

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPortada = {
    cancion: audioFondo,
    imgPortada:
      "https://i.postimg.cc/Y9WNX3Cc/Whats-App-Image-2024-04-19-at-11-31-19-AM.jpg",
    nombre: "Lucia Elena 'Lenilu'",
    fechaDeBoda: "13 JULIO, 2024 \\n (Desliza hacia abajo) \\n 🡣",
    titulo: "NO FALTES A MIS XV  ",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+522969646045",
    textoConfirmacion: "a los XV años",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. \\nHoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
    fechaNewDate: "July 13, 2024 14:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/4yv6GxcB/Whats-App-Image-2024-04-19-at-11-31-03-AM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/cJjMMjZM/Whats-App-Image-2024-04-19-at-11-31-03-AM-1.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra mis XV, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Blanca Denisse Sanchez Ramirez",
    padre: "Miguel Mendez Andrade",
    madrina: "Rosa Jazmin Azamar Arizmendi",
    padrino: "Manuel Sanchez Huesca",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 13 de Julio de 2024.",
    ceremoniaHora: "2:00 p.m.",
    ceremoniaNombreLugar: "Parroquia Nuestra Señora del Refugio",
    ceremoniaLugarMap:
      "Maestros Veracruzanos S/N, INFONAVIT Pomona, 91049 Xalapa-Enríquez, Ver.",
    ceremoniaUrl: "https://maps.app.goo.gl/G6QBtQe81u6Xs6hK7",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 13 de Julio de 2024.",
    recepcionHora: "3:00 p.m.",
    recepcionNombreLugar: "Salón Magnolias",
    recepcionLugarMap:
      "91060, Av. 20 de Noviembre 400, Los Ángeles, Xalapa-Enríquez, Ver.",
    recepcionUrl: "https://maps.app.goo.gl/SVXJ9MkYtGpGtnTJ6",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    cuantos: 2,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "¡Para mi lo mas importante es tu presencia! \\n Si desean tener un detalle conmigo, agradezco que sea en efectivo.",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: false,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/sxCYbhMM/Whats-App-Image-2024-04-19-at-11-31-03-AM-2.jpg",
    "https://i.postimg.cc/Y9WNX3Cc/Whats-App-Image-2024-04-19-at-11-31-19-AM.jpg",
    "https://i.postimg.cc/4yv6GxcB/Whats-App-Image-2024-04-19-at-11-31-03-AM.jpg",
    "https://i.postimg.cc/cJjMMjZM/Whats-App-Image-2024-04-19-at-11-31-03-AM-1.jpg",
    "https://i.postimg.cc/zf3Fh5mG/Whats-App-Image-2024-04-19-at-10-27-59-PM.jpg",
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, soy ${name} y confirmo mi asistencia ${datosSeccionConfirmacion.textoConfirmacion} con ${pases} invitado(s). ¡Nos vemos ahí!`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccionConfirmacion.numeroConfirmacion
    }&text=${encodeURIComponent(message)}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const regalos = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {/* <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      <PortadaXvComponent
        datosSeccionPortada={datosSeccionPortada}
        audioFondo={audioFondo}
      />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionConfirmacion.textColor
              ? datosSeccionConfirmacion.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
        >
          <h1
            className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionConfirmacion.tituloConfirmacion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </h1>
          <br />
          <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
            {datosSeccionConfirmacion.aviso || ""}
          </p>
          <br />
          <br />
          <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
            {images?.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[60vh]"
                />
              </div>
            ))}
          </Slider>
          <br />
          <br />
          <div
            data-aos="fade-down-left"
            className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
          >
            <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
              {datosSeccionConfirmacion.textWhatsApp ||
                "Confirmación de Asistencia al Evento"}{" "}
              <FaWhatsapp className="text-green-600 ml-2" />
            </h2>
            <form onSubmit={handleSubmit}>
              <label className="mb-2 flex">
                <span className="text-gray-700">Nombre:</span>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  required
                />
              </label>
              <br />
              <label className="flex mb-2">
                <span className="text-gray-700 italic">Tienes {pases} Invitado(s) Disponibles. </span>
              </label>
              <br />
              <button
                type="submit"
                className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
              >
                Confirmar Asistencia
              </button>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
