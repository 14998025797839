import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./rapunzel.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const XvEstefanySarahí = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Estefany Sarahí",
      fechaDeBoda: "28 JUNIO, 2024",
      fechaNewDate: "JUNE 28, 2024 20:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/bND46rX3/IMG-20240416-WA0012.jpg",
      numeroNovia: "3311034591",
      numeroNovio: "",
      aviso:"Por favor, omitir el color lila y morado en la vestimenta"
    };
  
    const peltaColores = {
      color1: "#c884e8",
      color2: "#f788e9",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Estefany Sarahí!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy hace quince años mis padres daban gracias a Dios por mí. Hoy doy gracias a Dios por ellos por cuidarme, tenerme paciencia y aconsejarme. Doy gracias también a toda mi familia por hacer más especial este día. Por eso quiero que vengas a celebrar este gran día conmigo.",
      fechaNewDate: "June 28, 2024 20:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/mk5TJSr2/6c45dec51461ba80825f16e9752c0603-21.jpg",
      fotoDerecha: "https://i.postimg.cc/BZ8vP9Jy/IMG-20240416-WA0014.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Hoy hace Quince años mis padres daban gracias a Dios por mi.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Karina Gamero Vazquez",
      esposaPadre: "Martin González Zuñiga",
      esposoMadre: "Fernanda González Gamero",
      esposoPadre: "Vicente Gamero Vazquez",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Viernes, 28 de Junio de 2024.",
      ceremoniaHora: "5:00 p.m.",
      ceremoniaNombreLugar: "Santuario de Nuestra Señora de Guadalupe",
      ceremoniaLugarMap:
        "Álvaro Obregón 300, Cerrito de la reina, 45400 Tonalá, Jal.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/QEptnxVTxfnN8wzV9",
      recepcionFecha: "Viernes, 28 de Junio de 2024.",
      recepcionHora: "8:00 p.m.",
      recepcionNombreLugar: "Gran Salon 3 Soles ",
      recepcionLugarMap:
        "C. Francisco I. Madero 318, Tonalá Centro, 45400 Tonalá, Jal.",
      recepcionUrl:
        "https://maps.app.goo.gl/rdDWHUdmjAw9zmqL6",
    };
  
  
    const images = [
      "https://i.postimg.cc/ryvwSnmp/IMG-20240416-WA0015.jpg",
      "https://i.postimg.cc/DmVH8b72/IMG-20240416-WA0019.jpg",
      "https://i.postimg.cc/gjL1zKFS/IMG-20210518-WA0029.jpg",
      "https://i.postimg.cc/BZ8vP9Jy/IMG-20240416-WA0014.jpg"
      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
