export const ConvertDataContador = (items, ingles) => {
    const locale = ingles ? "en-US" : "es-ES"; // Determinar el locale basado en el valor de ingles
    const date = new Date(items?.portada?.fechaDeBoda || "");
  
    const dayName = date.toLocaleString(locale, { weekday: "long" }); // Día de la semana
    const monthName = date.toLocaleString(locale, { month: "long" }); // Mes
    const dateNumber = date.getDate(); // Día del mes
    const year = date.getFullYear(); // Año
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (ingles){
        items.portada.fechaDeBoda = `${monthName.toUpperCase()} ${dateNumber}, ${year}`;
    }else{
        items.portada.fechaDeBoda = `${dateNumber} ${monthName.toUpperCase()}, ${year}`;
    }
  
    switch (monthName.toLowerCase()) {
      case ingles ? "january" : "enero":
        items.contador.fechaNewDate = `January ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "february" : "febrero":
        items.contador.fechaNewDate = `February ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "march" : "marzo":
        items.contador.fechaNewDate = `March ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "april" : "abril":
        items.contador.fechaNewDate = `April ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "may" : "mayo":
        items.contador.fechaNewDate = `May ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "june" : "junio":
        items.contador.fechaNewDate = `June ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "july" : "julio":
        items.contador.fechaNewDate = `July ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "august" : "agosto":
        items.contador.fechaNewDate = `August ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "september" : "septiembre":
        items.contador.fechaNewDate = `September ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "october" : "octubre":
        items.contador.fechaNewDate = `October ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "november" : "noviembre":
        items.contador.fechaNewDate = `November ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case ingles ? "december" : "diciembre":
        items.contador.fechaNewDate = `December ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      default:
        console.error("Nombre de mes no válido");
        break;
    }
  
    const dia = capitalizeWords(dayName);
    const mes = capitalizeWords(monthName);
    
    
    if (ingles) {
        if (items.ceremonia.ceremoniaFecha === "Sábado, 25 de Mayo de 2024.") {
      items.ceremonia.ceremoniaFecha = `${dateNumber} ${dia} ${mes} ${year}`;
    }
  
    if (items.ceremonia.recepcionFecha === "Sábado, 25 de Mayo de 2024.") {
      items.ceremonia.recepcionFecha = `${dateNumber} ${dia} ${mes} ${year}`;
    }
    }else{
        if (items.ceremonia.ceremoniaFecha === "Sábado, 25 de Mayo de 2024.") {
            items.ceremonia.ceremoniaFecha = `${dia}, ${dateNumber} de ${mes} de ${year}`;
          }
        
          if (items.ceremonia.recepcionFecha === "Sábado, 25 de Mayo de 2024.") {
            items.ceremonia.recepcionFecha = `${dia}, ${dateNumber} de ${mes} de ${year}`;
          }
    }
  
    console.log(
      dayName,
      monthName,
      dateNumber,
      year,
      hours,
      "December 12, 2024 00:00:00 GMT+00:00",
      items.ceremonia.ceremoniaFecha
    );
    return items;
  };
  
  function capitalizeWords(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  