import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/GloriaEstefan.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaGuadalupeYFelipe = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a Nuestro Aniversario 50 Años',
      esposo: "Felipe",
      esposa: "Lupita",
      fechaDeBoda: "15 Junio, 2024",
      fechaNewDate: "June 15, 2024 14:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/FzHF6shf/86d77a10-57c0-4190-8a36-f3db6a3996b7.jpg",
      numeroNovia: "+525512993916",
      numeroNovio: "+525527848821",
      novios:true,
      titulo:"Aniversario 50 años",
      tituloConfirm:"Nuestro Día Especial",
      aviso:""
    };
   
    const peltaColores = {
      color1: "#ebe4c0",
      color2: "#e3d0d0",
      messageVestimenta:""
    };
  
    const datosSeccion2 = {
      titulo:'¡Volvemos a decir que sí!',
      descipcion:
        "Después de 50 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
      fechaNewDate: "June 15, 2024 00:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/pTwzJXgs/d6ff1e22-1dc5-489b-ae99-4ba3d47af104.jpg",
      fotoDerecha: "https://i.postimg.cc/L6shCvsy/eedbefb5-de5d-4365-8c80-3faf994a300a.jpg",
    };
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a la boda de Lupita y Felipe!',
      entradasText:'disfrutar nuestro día especial',
      colorFondoName:"#e3d1d1cf"
    }
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 15 de Junio de 2024.",
      ceremoniaHora: "2:00 p.m.",
      ceremoniaNombreLugar: "Parroquia de la Asunción de María",
      ceremoniaLugarMap: "C. de Morelos 60, Rancho Nuevo, 62730 Yautepec de Zaragoza, Mor.",
      ceremoniaUrl: "https://maps.app.goo.gl/SfXzsFEkVGimPmLu5",
      recepcionFecha: "Sábado, 15 de Junio de 2024.",
      recepcionHora: "3:30 p.m.",
      recepcionNombreLugar: "Quinta Casa Blanca",
      recepcionLugarMap:
        "Limones 66, San Carlos, 62737 San Carlos, Mor.",
      recepcionUrl: "https://www.google.com/maps/place/Quinta+Casa+Blanca,+Limones+66,+San+Carlos,+62737+San+Carlos,+Mor./@18.8786481,-99.0241993,13z/data=!4m6!3m5!1s0x85ce73a0de297ccd:0xfd0a7fbf271dc015!8m2!3d18.8786481!4d-99.0241993!16s%2Fg%2F11s7yvnj99",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
      ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
      cena: "Disfruta de un banquete especial",
      recepcion: "Tómate fotos con nosotros",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
    };
  
    

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo con nosotros y nuestros padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda:'Padrinos',
    tituloDerecha:'',
    esposaMadre: "Alejandro Felipe Angeles Garcia ",
    esposaPadre: "Maricruz Rivera Cruz",
    imgFondo:'url("https://img.freepik.com/fotos-premium/anillos-oro-boda-yacen-sobre-velo-blanco-o-espacio-libre-tul-texto_768518-48.jpg?w=360"'
  };
  
    const datosSeccion8 = {
      descripcion:
        "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirnos escoger nuestro regalo.",
      linkAmazon:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      linkLiverpool:
        "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    };
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/rw0scmxx/54d7a4ae-befd-4abc-9997-563c227d03fb.jpg"',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
      vestimenta:"CASUAL / FORMAL"
    }
  
    const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
    const [pases, setPases] = useState(0);
  
    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const difference = targetDate - now;
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
  
    const images = [
      "https://i.postimg.cc/sXygp484/50dfa6b8-8d08-46ff-9d58-9de00d2da28f.jpg",
      "https://i.postimg.cc/W3nbSJcL/a804322c-ad3a-4592-ab0c-c1a4758b4857.jpg",
      "https://i.postimg.cc/sXRvTrBS/c38ed8d6-f8fb-43c7-9e6b-47f264544843.jpg",
      "https://i.postimg.cc/NFRTJvtB/6c6366b7-1a23-4462-aa9d-361d817cf8a6.jpg",
      "https://i.postimg.cc/J0rtW55z/6498cec5-0700-40d2-b54d-a040e26a13a7.jpg",
      "https://i.postimg.cc/9fNGzB15/75453297-deb8-4377-9157-95824d21454d.jpg",
      // Add more image URLs as needed
    ];
  
  
    const names = [
        { name: "Angel Jaimes", guess: "2" },
        { name: "Alejandro Angeles Garcia", guess: "4" },
        { name: "Christian Ayala", guess: "5" },
        { name: "Rafael Gonzalez", guess: "9" },
        { name: "Daniel Gomez", guess: "2" },
        { name: "Ricardo Ramirez", guess: "2" },
        { name: "Blanca Gomez", guess: "3" },
        { name: "Martin Diaz", guess: "2" },
        { name: "Eleazar Arreola", guess: "2" },
        { name: "Lorenzo Jaramillo", guess: "3" },
        { name: "Juan Luis Nava", guess: "4" },
        { name: "Salvador Jaramillo", guess: "4" },
        { name: "Clemente Jaramillo", guess: "2" },
        { name: "Edgar Jaramillo", guess: "4" },
        { name: "Luis Polanco", guess: "4" },
        { name: "Bryon Dill", guess: "3" },
        { name: "Juan Carlos Jaramillo", guess: "2" },
        { name: "Gil Morales", guess: "4" },
        { name: "Rafael Angeles", guess: "1" },
        { name: "Irma Angeles", guess: "1" },
        { name: "David Fuentes", guess: "4" },
        { name: "Daniel Rivera Nuñez", guess: "2" },
        { name: "Daniel Rivera Cruz", guess: "3" },
        { name: "Juan Rivera Cruz", guess: "4" },
        { name: "Diego Rivera Cruz", guess: "2" },
        { name: "Ivonne Angeles", guess: "3" }
    ];
    
  
    
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
      console.log(nameId, numPases);
      setPases(numPases);
  
      const findName = names.find((e) => e.name === nameId);
  
      if (findName) {
        console.log(findName.name);
  
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
      } else {
        console.log("Nombre no encontrado");
        window.location.href = "/";
      }
  
      AOS.init({ duration: 2000 });
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
    const [isIOS, setIsIOS] = useState(false);
  
  
    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsIOS(/iphone|ipad|ipod/.test(userAgent));
      }, []);

    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <PortadaBoda
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
  <div
      style={{
        backgroundImage: imagenesFondo.fondoInvitados,
        backgroundColor: peltaColores.color2,
        backgroundAttachment: isIOS ? 'local' : 'fixed',
        backgroundPosition:imagenesFondo.sizeFoto? imagenesFondo.sizeFoto: "center",
        backgroundSize:"cover"
      }}
      className="sm:h-screen md:h-[100vh] bg-img-fixed sm:bg-local md:bg-fixed px-0 "
    >

      <div className="flex flex-col w-full justify-between items-center py-2 h-full"
      style={{ backgroundColor: "rgb(0 0 0 / 22%)" }}>
        <div className="flex flex-col pt-5 justify-center items-center">
        <h1
          className="font-serif w-[90%] sm:text-4xl md:text-[132px] text-black  p-2 rounded-2xl"
          data-aos="flip-up"
          style={{
            background: "#fff"
          }}
        >
          {datosInvitados.titulo}
        </h1>
        <br />
        <br />
        <p
          className=" text-center text-white p-[3vh] rounded-tl-3xl rounded-br-3xl font-serif text-[35px] w-[80%]"
          data-aos="flip-up"
          style={{
            background: datosInvitados.colorFondoName ||  '#00000094',
          }}
        >
          {NameInvitation}
        </p>
        </div>
        <div className="grid sm:grid-cols-1 w-[80%] pt-[10vh]">
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            <GiTicket className="text-[50px]  p-2 rounded-2xl" 
            style={{
              color:'#fff',
            }}/>
            <br />
            <p className="text-black font-serif text-center text-2xl  p-2 rounded-2xl"
            style={{
              background: '#fff',
            }}
            >
              Cuentas con {numGuess} accesos para {datosInvitados.entradasText}
            </p>
          </div>
        </div>
      </div>
    </div>

    <Padres
        peltaColores={peltaColores}
        datosSeccion3={datosSeccion3}
      />
  
        {/* <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        /> */}
        
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
  <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
          }}
          className="sm:h-auto py-[5vh] md:h-[100vh] flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Ubicaciones
            </h1>
            <br />
  
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
              <div
                className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                <div
                  style={{
                    backgroundImage:
                    'url("https://lh5.googleusercontent.com/p/AF1QipNs6sPEoRgUVVcN2iNNVRvCbReRfcouKpuwtMiN=w408-h306-k-no")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15100.26024080391!2d-99.0632687!3d18.8841947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce73ce0e256afb%3A0x434e11dd0c508db8!2sParroquia%20La%20Asunci%C3%B3n%20de%20Maria!5e0!3m2!1ses-419!2smx!4v1712888969169!5m2!1ses-419!2smx"
                    width="100%"
                    height="50%"
                    className="fixed"
                  ></iframe>
                  <a
                    href={datosSeccion4.ceremoniaUrl}
                    class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Mapa de la Ceremonia
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              </div>
              <div
                className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                 <div
                  style={{
                    backgroundImage:
                    'url("https://lh5.googleusercontent.com/p/AF1QipPSZ3_JkjsNvQyNrUAQRzZHoKIjfdLvfwo_CvgQ=w408-h306-k-no")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15100.760206375733!2d-99.0241993!3d18.8786481!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce73a0de297ccd%3A0xfd0a7fbf271dc015!2sQuinta%20Casa%20Blanca!5e0!3m2!1ses-419!2smx!4v1712888992790!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>
  
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
        <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
          }}
          className="sm:h-auto md:h-[150vh] py-[5vh] flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <PiChurchLight className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">
                  CEREMONIA RELIGIOSA
                </p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.ceremoniaReligiosa}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                
                <br />
                <ImSpoonKnife className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">BANQUETE</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.cena}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
              </div>
  
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <br />
                <FaPhotoVideo className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.recepcion}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                
                <br />
                <GiMeshBall className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">BAILE</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.baile}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
              </div>
            </div>
          </div>
        </div>

  
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
