import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import axios from "axios";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ModalCard from "../../../components/Modals/ModalCard";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponentD2 } from "../../../components/ComponentsXvDiseño2/PortadaXvComponentD2";
import { MensajeXvComponetD2 } from "../../../components/ComponentsXvDiseño2/MensajeXvComponetD2";
import { InvitadosXvComponentD2 } from "../../../components/ComponentsXvDiseño2/InvitadosXvComponentD2";
import { CeremoniaXvComponentD2 } from "../../../components/ComponentsXvDiseño2/CeremoniaXvComponentD2";
import { ContadorXvComponentD2 } from "../../../components/ComponentsXvDiseño2/ContadorXvComponentD2";
import { PadresXvComponentD2 } from "../../../components/ComponentsXvDiseño2/PadresXvComponentD2";
import { ProgramaXvComponentD2 } from "../../../components/ComponentsXvDiseño2/ProgramaXvComponentD2";
import { VestimentaXvComponentD2 } from "../../../components/ComponentsXvDiseño2/VestimentaXvComponentD2";
import { HospedajeXvComponentD2 } from "../../../components/ComponentsXvDiseño2/HospedajeXvComponentD2";
import { RegalosXvComponentD2 } from "../../../components/ComponentsXvDiseño2/RegalosXvComponentD2";
import { RedesXvComponent } from "../../../components/ComponentsXvDiseño2/RedesXvComponent";

export const XvAutomaticaDiseño2 = () => {
  const [seccionPortadaData, setSeccionPortadaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seccionImagenes, setSeccionImagenes] = useState([]);
  const [estado, setEstado] = useState({});
  const [dataInvitado, setDataInvitado] = useState({});
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("idInvitado");

  // Función para hacer la solicitud HTTP
  const fetchData = async () => {
    setLoading(true);

    const guestId = parseInt(idInvitado);

    try {
      console.log("si esta pasando");
      let responseInvitado = await axios.get(
        `/api/Invitado/lista/${idInvitado}`
      );
      console.log(responseInvitado.data, "datos Invitado");

      setDataInvitado(responseInvitado.data);
      let idEvento = responseInvitado.data.idEvento;

      try {
        const responseEvento = await axios.get(`/api/Eventos/${idEvento}`);
        setEstado(responseEvento.data);
        console.log(responseEvento.data);

        try {
          const response = await axios.get(
            `/api/Seccionportada/obtenerXv/${idEvento}`
          );
          const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
          const validarDate = regex.test(response.data?.portada?.fechaDeBoda);

          if (validarDate) {
            const dataConvertida = await converData(response.data);
            setSeccionPortadaData(dataConvertida);
          } else {
            setSeccionPortadaData(response.data);
          }
          console.log(response.data, responseInvitado.data);
          const massageWhatss = `Hola, soy ${responseInvitado.data.nombre} y confirmo mi asistencia ${response.data.invitados.entradasText} con ${responseInvitado.data.accesos} invitado(s). ¡Nos vemos allí!`;
          setMessageWhats(massageWhatss);

          try {
            const responseImg = await axios.get(`/api/Imagenes?id=${idEvento}`);
            const mapImg = responseImg.data.map((item) => item.urlFoto);
            setSeccionImagenes(mapImg);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            alert("Error, responseImg");
          }
        } catch (error) {
          setLoading(false);
          alert("Error, obtenerXv");
        }
      } catch (error) {
        setLoading(false);
        alert("Error, Eventos");
      }
    } catch (error) {
      setLoading(false);
      alert("Error, Invitado");
      window.location.href = "/";
    }
  };

  const converData = (items) => {
    const date = new Date(items?.portada?.fechaDeBoda || "");

    const dayName = date.toLocaleString("default", { weekday: "long" }); // Día de la semana
    const monthName = date.toLocaleString("default", { month: "long" }); // Mes
    const dateNumber = date.getDate(); // Día del mes
    const year = date.getFullYear(); // Año
    const hours = date.getHours();
    const minutes = date.getMinutes();

    items.portada.fechaDeBoda = `${dateNumber} ${monthName.toUpperCase()}, ${year}`;

    switch (monthName.toLowerCase()) {
      case "enero":
        items.contador.fechaNewDate = `January ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "febrero":
        items.contador.fechaNewDate = `February ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "marzo":
        items.contador.fechaNewDate = `March ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "abril":
        items.contador.fechaNewDate = `April ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "mayo":
        items.contador.fechaNewDate = `May ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "junio":
        items.contador.fechaNewDate = `June ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "julio":
        items.contador.fechaNewDate = `July ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "agosto":
        items.contador.fechaNewDate = `August ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "septiembre":
        items.contador.fechaNewDate = `September ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "octubre":
        items.contador.fechaNewDate = `October ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "noviembre":
        items.contador.fechaNewDate = `November ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "diciembre":
        items.contador.fechaNewDate = `December ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      default:
        console.error("Nombre de mes no válido");
        break;
    }

    const dia = capitalizeWords(dayName);
    const mes = capitalizeWords(monthName);

    if (items.ceremonia.ceremoniaFecha == "Sábado, 25 de Mayo de 2024.") {
      items.ceremonia.ceremoniaFecha = `${dia}, ${dateNumber} de ${mes} de ${year}`;
    }

    if (items.ceremonia.recepcionFecha == "Sábado, 25 de Mayo de 2024.") {
      items.ceremonia.recepcionFecha = `${dia}, ${dateNumber} de ${mes} de ${year}`;
    }

    console.log(
      dayName,
      monthName,
      dateNumber,
      year,
      hours,
      "December 12, 2024 00:00:00 GMT+00:00",
      items.ceremonia.ceremoniaFecha
    );
    return items;
  };

  function capitalizeWords(string) {
    return string
      .toLowerCase() // Convertir toda la cadena a minúsculas para un formato consistente
      .split(" ") // Dividir la cadena en un array de palabras
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar la primera letra de cada palabra
      .join(" "); // Volver a unir el array en una sola cadena
  }

  const peltaColores = {
    color1: seccionPortadaData?.colores?.color1 || "",
    color2: seccionPortadaData?.colores?.color2 || "",
  };

  const datosSeccionPotada = {
    cancion: seccionPortadaData?.portada?.cancion || "",
    textoConfirmacion: seccionPortadaData?.portada?.textoConfirmacion || "",
    nombre: seccionPortadaData?.portada?.nombre || "",
    fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
    imgPortada: seccionPortadaData?.portada?.imgPortada || "",
    titulo: seccionPortadaData?.portada?.titulo || "",
    tipoLetra:seccionPortadaData?.portada?.tipoLetra || "",
  };

  const datosSeccionContador = {
    titulo: seccionPortadaData?.contador?.titulo || "",
    descipcion: seccionPortadaData?.contador?.descripcion || "",
    fechaNewDate: seccionPortadaData?.contador?.fechaNewDate || "",
    fotoIzquierda: seccionPortadaData?.contador?.fotoIzquierda || "",
    fotoDerecha: seccionPortadaData?.contador?.fotoDerecha || "",
    marcoEnable: seccionPortadaData?.contador?.marcoEnable,
    imgFondo: seccionPortadaData?.contador?.imgFondo || "",
    textColor: seccionPortadaData?.contador?.textColor || "",
    contador: seccionPortadaData?.contador?.contador,
    tipoLetra:seccionPortadaData?.contador?.tipoLetra,
  };

  const datosSeccionPadres = {
    cantidad: seccionPortadaData?.padres?.cantidad || "",
    titulo: seccionPortadaData?.padres?.titulo || "",
    descipcion: seccionPortadaData?.padres?.descipcion || "",
    titulo1: seccionPortadaData?.padres?.titulo1 || "",
    madre: seccionPortadaData?.padres?.madre || "",
    padre: seccionPortadaData?.padres?.padre || "",
    titulo2: seccionPortadaData?.padres?.titulo2 || "",
    madrina: seccionPortadaData?.padres?.madrina || "",
    padrino: seccionPortadaData?.padres?.padrino || "",
    titulo3: seccionPortadaData?.padres?.titulo3 || "",
    madrina2: seccionPortadaData?.padres?.madrina2 || "",
    padrino2: seccionPortadaData?.padres?.padrino2 || "",
    imgFondo: seccionPortadaData?.padres?.imgFondo || "",
    textColor: seccionPortadaData?.padres?.textColor || "",
    tipoLetra: seccionPortadaData?.padres?.tipoLetra || "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: seccionPortadaData?.ceremonia?.dobleEnable,
    ceremoniaEnable: seccionPortadaData?.ceremonia?.ceremoniaEnable,
    ceremoniaImg: seccionPortadaData?.ceremonia?.ceremoniaImg,
    ceremoniaTitulo: seccionPortadaData?.ceremonia?.ceremoniaTitulo,
    ceremoniaFecha: seccionPortadaData?.ceremonia?.ceremoniaFecha || "",
    ceremoniaHora: seccionPortadaData?.ceremonia?.ceremoniaHora || "",
    ceremoniaNombreLugar:
      seccionPortadaData?.ceremonia?.ceremoniaNombreLugar || "",
    ceremoniaLugarMap: seccionPortadaData?.ceremonia?.ceremoniaLugarMap || "",
    ceremoniaUrl: seccionPortadaData?.ceremonia?.ceremoniaUrl || "",
    recepcionEnable: seccionPortadaData?.ceremonia?.recepcionEnable,
    recepcionImg: seccionPortadaData?.ceremonia?.recepcionImg,
    recepcionTitulo: seccionPortadaData?.ceremonia?.recepcionTitulo,
    recepcionFecha: seccionPortadaData?.ceremonia?.recepcionFecha || "",
    recepcionHora: seccionPortadaData?.ceremonia?.recepcionHora || "",
    recepcionNombreLugar:
      seccionPortadaData?.ceremonia?.recepcionNombreLugar || "",
    recepcionLugarMap: seccionPortadaData?.ceremonia?.recepcionLugarMap || "",
    recepcionUrl: seccionPortadaData?.ceremonia?.recepcionUrl || "",
    imgFondo: seccionPortadaData?.ceremonia?.imgFondo || "",
    textColor: seccionPortadaData?.ceremonia?.textColor || "",
    tipoLetra:seccionPortadaData?.ceremonia?.tipoLetra || "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: seccionPortadaData?.programa?.ceremoniaReligiosa || "",
    ceremoniaReligiosaImg:
      seccionPortadaData?.programa?.ceremoniaReligiosaImg || "",
    titulo: seccionPortadaData?.programa?.titulo || "",
    descripcion: seccionPortadaData?.programa?.descripcion || "",
    ceremoniaReligiosaTitulo:
      seccionPortadaData?.programa?.ceremoniaReligiosaTitulo || "",
    recepcion: seccionPortadaData?.programa?.recepcion || "",
    recepcionImg: seccionPortadaData?.programa?.recepcionImg || "",
    recepcionTitulo: seccionPortadaData?.programa?.recepcionTitulo || "",
    baileXv: seccionPortadaData?.programa?.baileXv || "",
    baileXvImg: seccionPortadaData?.programa?.baileXvImg || "",
    baileXvTitulo: seccionPortadaData?.programa?.baileXvTitulo || "",
    cena: seccionPortadaData?.programa?.cena || "",
    cenaImg: seccionPortadaData?.programa?.cenaImg || "",
    cenaTitulo: seccionPortadaData?.programa?.cenaTitulo || "",
    coctel: seccionPortadaData?.programa?.coctel || "",
    coctelImg: seccionPortadaData?.programa?.coctelImg || "",
    coctelTitulo: seccionPortadaData?.programa?.coctelTitulo || "",
    baile: seccionPortadaData?.programa?.baile || "",
    baileImg: seccionPortadaData?.programa?.baileImg || "",
    baileTitulo: seccionPortadaData?.programa?.baileTitulo || "",
    imgFondo: seccionPortadaData?.programa?.imgFondo || "",
    textColor: seccionPortadaData?.programa?.textColor || "",
    tipoLetra:seccionPortadaData?.programa?.tipoLetra || "",
  };

  const datosSeccionVestimenta = {
    vestimenta: seccionPortadaData?.vestimenta?.vestimenta || "",
    messageVestimenta: seccionPortadaData?.vestimenta?.messageVestimenta || "",
    urlVestimentaMujer:
      seccionPortadaData?.vestimenta?.urlVestimentaMujer || "",
    urlVestimentaHombre:
      seccionPortadaData?.vestimenta?.urlVestimentaHombre || "",
    imgFondo: seccionPortadaData?.vestimenta?.imgFondo || "",
    textColor: seccionPortadaData?.vestimenta?.textColor || "",
    tipoLetra:seccionPortadaData?.vestimenta?.tipoLetra || "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: seccionPortadaData?.hospedaje?.disponibleDos || "",
    descripcion: seccionPortadaData?.hospedaje?.descripcion || "",
    hotel1Titulo: seccionPortadaData?.hospedaje?.hotel1Titulo || "",
    hotel1lugar: seccionPortadaData?.hospedaje?.hotel1lugar || "",
    hotel1url: seccionPortadaData?.hospedaje?.hotel1url || "",
    hotel2Titulo: seccionPortadaData?.hospedaje?.hotel2Titulo || "",
    hotel2lugar: seccionPortadaData?.hospedaje?.hotel2lugar || "",
    hotel2url: seccionPortadaData?.hospedaje?.hotel2url || "",
    imgFondo: seccionPortadaData?.hospedaje?.imgFondo || "",
    textColor: seccionPortadaData?.hospedaje?.textColor || "",
    tipoLetra:seccionPortadaData?.hospedaje?.tipoLetra || "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: seccionPortadaData?.regalos?.cantidadRegalos || "",
    titulo: seccionPortadaData?.regalos?.titulo || "",
    descripcion: seccionPortadaData?.regalos?.descripcion || "",
    link1: seccionPortadaData?.regalos?.link1 || "",
    imgLink1: seccionPortadaData?.regalos?.imgLink1 || "",
    link1Enable: seccionPortadaData?.regalos?.link1Enable || "",
    link2: seccionPortadaData?.regalos?.link2 || "",
    imgLink2: seccionPortadaData?.regalos?.imgLink2 || "",
    link2Enable: seccionPortadaData?.regalos?.link2Enable || "",
    link3: seccionPortadaData?.regalos?.link3 || "",
    imgLink3: seccionPortadaData?.regalos?.imgLink3 || "",
    link3Enable: seccionPortadaData?.regalos?.link3Enable || "",
    imgFondo: seccionPortadaData?.regalos?.imgFondo || "",
    textColor: seccionPortadaData?.regalos?.textColor || "",
    tipoLetra:seccionPortadaData?.regalos?.tipoLetra || "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      seccionPortadaData?.confirmacion?.tituloConfirmacion || "",
    aviso: seccionPortadaData?.confirmacion?.aviso || "",
    numeroConfirmacion:
      seccionPortadaData?.confirmacion?.numeroConfirmacion || "",
    textoConfirmacion:
      seccionPortadaData?.confirmacion?.textoConfirmacion || "",
    textWhatsApp: seccionPortadaData?.confirmacion?.textWhatsApp || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    imgFondo: seccionPortadaData?.confirmacion?.imgFondo || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    enableNum: seccionPortadaData?.confirmacion?.enableNum || "",
    tipoLetra:seccionPortadaData?.confirmacion?.tipoLetra || "",
  };

  const datosSeccionInvitados = {
    imgFondo: seccionPortadaData?.invitados?.imgFondo,
    titulo: `¡Has sido invitado ${
      seccionPortadaData?.invitados?.eventoTitulo || "a los XV años"
    } de ${seccionPortadaData?.portada?.nombre}!`,
    entradasText: seccionPortadaData?.invitados?.entradasText,
    colorFondoName: seccionPortadaData?.invitados?.colorFondoName,
    tipoLetra:seccionPortadaData?.invitados?.tipoLetra,
  };


  const contador = estado?.enableContador;
  const padres = estado?.enablePadres;
  const hospedaje = estado?.enableHospedaje;
  const confirmacion = estado?.enableConfirmacion;
  const Regalos = estado?.enableRegalos;
  const ceremonia = estado?.enableCeremonia;
  const programa = estado?.enablePrograma;
  const vestimenta = estado?.enableVestimenta;

  const [messageWhats, setMessageWhats] = useState("");

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Samuel Arroyo", guess: "3", id: 2 },
  ];

  useEffect(() => {
    AOS.init({ duration: 2000 });
    fetchData();
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {loading ? (
        <h2>Cargando...</h2>
      ) : (
        <>
          <ModalXvCard
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            datosSeccionPortada={datosSeccionPotada}
          />
          <PortadaXvComponentD2
            datosSeccionPortada={datosSeccionPotada}
            peltaColores={peltaColores}
          />

          {contador ? (
            <MensajeXvComponetD2
              peltaColores={peltaColores}
              datosSeccionContador={datosSeccionContador}
            />
          ) : (
            ""
          )}

{contador ? (
            <ContadorXvComponentD2
              peltaColores={peltaColores}
              nombres={datosSeccionPotada.nombre}
              fechaEvento={datosSeccionPotada.fechaDeBoda}
              recepcionNombreLugar={datosSeccionCeremonia.recepcionNombreLugar}
              datosSeccionContador={datosSeccionContador}
            />
          ) : (
            ""
          )}

          {ceremonia ? (
            <CeremoniaXvComponentD2
              peltaColores={peltaColores}
              datosSeccionCeremonia={datosSeccionCeremonia}
            />
          ) : (
            ""
          )}

          <div
            style={{
              backgroundImage: `url(${datosSeccionContador.fotoDerecha || ""})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: peltaColores.color1,
              color: datosSeccionContador.textColor || "",
            }}
            className="sm:h-[55vh] md:h-[50vh] flex flex-col items-center w-full px-2 py-5"
          ></div>

          {padres ? (
            <PadresXvComponentD2
              peltaColores={peltaColores}
              datosSeccionPadres={datosSeccionPadres}
            />
          ) : (
            ""
          )}

          {programa ? (
            <ProgramaXvComponentD2
              peltaColores={peltaColores}
              datosSeccionPrograma={datosSeccionPrograma}
            />
          ) : (
            ""
          )}

          {vestimenta ? (
            <VestimentaXvComponentD2
              peltaColores={peltaColores}
              datosSeccionVestimenta={datosSeccionVestimenta}
            />
          ) : (
            ""
          )}

          {hospedaje ? (
            <HospedajeXvComponentD2
              peltaColores={peltaColores}
              datosSeccionHospedaje={datosSeccionHospedaje}
            />
          ) : (
            ""
          )}

          <RedesXvComponent
                      NameInvitation={dataInvitado.nombre}
                      numGuess={dataInvitado.accesos}
                      peltaColores={peltaColores}
                      datosSeccionInvitados={datosSeccionInvitados}
                    />

          {Regalos ? (
            <RegalosXvComponentD2
              peltaColores={peltaColores}
              datosSeccionRegalos={datosSeccionRegalos}
            />
          ) : (
            ""
          )}

          <InvitadosXvComponentD2
            NameInvitation={dataInvitado.nombre}
            numGuess={dataInvitado.accesos}
            peltaColores={peltaColores}
            datosSeccionInvitados={datosSeccionInvitados}
          />

          {confirmacion ? (
            <ConfirmacionDiamXvComponent
              peltaColores={peltaColores}
              images={seccionImagenes}
              messageWhats={messageWhats}
              datosSeccionConfirmacion={datosSeccionConfirmacion}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
