import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const BabyShowerRebeca = () => {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const IdInv = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const [isIOS, setIsIOS] = useState(false);

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#fc60ce",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEpdK02eIrp_Y2meta.app---Tu-Fidelidad-128-kbps-.mp3",
    imgPortada:
      "https://i.postimg.cc/8cTN7wMk/Whats-App-Image-2024-05-14-at-4-24-08-PM.jpg",
    nombre: "Rebeca",
    fechaDeBoda: "01 JUNIO, 2024",
    titulo: "NO FALTES A MI BABY SHOWER",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+529241109489",
    textoConfirmacion: "al baby shower",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mi Baby Shower!",
    descipcion:
      "Tu creaste mis entrañas; me formaste en el vientre de mi madre. \\n¡Te alabo porque soy una creación admirable! \\n¡Tus obras son maravillosas y esto lo sé muy bien! \\nSalmos 139:13 -14 \\nLa vida nos ha sorprendido con un regalo muy especial, la llegada de nuestra Rebeca. Estamos agradecidos con Dios y muy emocionados por compartir con ustedes este momento. ",
    fechaNewDate: "June 01, 2024 11:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/zBCqD4LP/Whats-App-Image-2024-05-14-at-4-36-21-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/WbJ41kXP/original-1-A5804-D6-B09-B-4990-A27-C-17-F833-E8-BBCF1.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Magdalena Vázquez Prieto",
    padre: "Adolfo Lira González",
    madrina: "Rosio Garibay Cervantes",
    padrino: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 23 de Diciembre de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia del Divino Salvador",
    ceremoniaLugarMap:
      "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
    ceremoniaUrl:
      "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 01 de Junio de 2024.",
    recepcionHora: "11:00 a.m.",
    recepcionNombreLugar: "Salón RYS",
    recepcionLugarMap:
      "C. Jose Ma. Pino Suarez 520B, La Palma, 96056 Acayucan, Ver.",
    recepcionUrl: "https://maps.app.goo.gl/eCJhY3SUK4UHjvFB9",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Vestimenta Casual",
    messageVestimenta: "Llevar tonos blancos o claros, beige o arena.",
    urlVestimentaMujer: "a",
    urlVestimentaHombre: "a",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para el baby shower. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51442951",
    imgLink1: "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://i.postimg.cc/DZbLFRMy/original-8-BEC0-CA8-2079-4952-ADBF-B634-F165-E2-D0.jpg",
    titulo: `¡Has sido invitado al baby shower de ${datosSeccionPortada.nombre}!`,
    entradasText: "disfrutar mi baby shower",
    colorFondoName: "",
  };

  const images = [
    "https://i.postimg.cc/DZbLFRMy/original-8-BEC0-CA8-2079-4952-ADBF-B634-F165-E2-D0.jpg",
    "https://i.postimg.cc/Hsygcj4F/original-635-C810-F-815-F-4539-A8-AF-770210-B487-B9.jpg",
    "https://i.postimg.cc/L47S8jfz/original-1-A5804-D6-B09-B-4990-A27-C-17-F833-E8-BBCF.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = false;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  const names = [
    { name: "Invitado", guess: 1, id: 1 },
    { name: "Invitado", guess: 2, id: 2 },
    { name: "Invitado", guess: 3, id: 3 },
    { name: "Invitado", guess: 4, id: 4 },
    { name: "Invitado", guess: 5, id: 5 },
    { name: "Invitado", guess: 6, id: 6 },
    { name: "Invitado", guess: 7, id: 7 }
];

  useEffect(() => {
    const guestId = parseInt(IdInv);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia al baby shower con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <div
        style={{
          backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS ? "local" : "fixed",

          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccionVestimenta.vestimenta
              ? datosSeccionVestimenta.vestimenta
              : "FORMAL"}
          </p>
          <br />
          {datosSeccionVestimenta.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
              {datosSeccionVestimenta.messageVestimenta
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
