import React, { useEffect, useState } from "react";
import { SiGooglemaps } from "react-icons/si";
import marcoImagen from './Assets/marcoDorado.png';
import { Link } from "react-router-dom"; // Si usas react-router-dom

const formatGoogleCalendarDateTime = (dateTime) => {
  const date = new Date(dateTime);
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateTime);
    return "";
  }
  return date.toISOString().replace(/-|:|\.\d+/g, "").slice(0, 15);
};

const calculateTimeRemaining = (targetDate) => {
  const now = new Date().getTime();
  const difference = targetDate - now;

  if (difference > 0) {
    const dias = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { dias, hours, minutes, seconds };
  } else {
    return { dias: 0, hours: 0, minutes: 0, seconds: 0 };
  }
};

export const ContadorXvComponentD2 = ({
  peltaColores,
  nombres,
  fechaEvento,
  recepcionNombreLugar,
  datosSeccionContador,
}) => {
  const [urlCalendario, setUrlCalendario] = useState("");

  const formattedStartDateTime = formatGoogleCalendarDateTime(
    datosSeccionContador.fechaNewDate
  );
  const formattedEndDateTime = formatGoogleCalendarDateTime(
    new Date(datosSeccionContador.fechaNewDate).setHours(
      new Date(datosSeccionContador.fechaNewDate).getHours() + 1
    )
  );

  const targetDate = new Date(datosSeccionContador.fechaNewDate || "").getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  useEffect(() => {
    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      `Evento de ${nombres}`
    )}&dates=${formattedStartDateTime}/${formattedEndDateTime}&location=${encodeURIComponent(
      recepcionNombreLugar
    )}`;
    setUrlCalendario(googleCalendarUrl);
  }, [nombres, formattedStartDateTime, formattedEndDateTime, recepcionNombreLugar]);

  const fontFamilyStyle = { fontFamily: datosSeccionContador.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionContador.imgFondo || "https://i.postimg.cc/KYJVZxp1/c.jpg	"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionContador.textColor || "#ffffff",
        zIndex: 10,
        position: "relative"
      }}
      className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full "
    >
      <div className="flex flex-col pt-[10vh] pb-4 w-full h-full items-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
        <h1
          className="font-serif sm:text-[25px] md:text-[52px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {/* {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))} */}
          {fechaEvento}
        </h1>
        <h1
          className="font-serif sm:text-[25px] md:text-[52px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {/* {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))} */}
          Faltan
        </h1>
       <br />
        <div
          className="flex sm:flex-col md:flex-row justify-center w-full sm:h-[70vh] md:h-[40vh] items-center mt-2"
          style={{ zIndex: 20 }}
        >
          {["dias", "hours", "minutes", "seconds"].map((unit, index) => (
            <div
              key={unit}
              className="flex flex-col sm:m-1 md:mx-6 justify-center items-center"
              style={{
                backgroundImage: `url(${marcoImagen})`,
                backgroundSize: "100% 100%",
                padding: "5px",
                borderRadius: "10px",
                width: "150px",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p className="letra-text sm:text-[23px] md:text-[43px]">
                {timeRemaining[unit]}
              </p>
              <p className="letra-text sm:text-[23px] md:text-[43px]">
                {unit.charAt(0).toUpperCase() + unit.slice(1)}
              </p>
            </div>
          ))}
        </div>
        <br />
        <a
          href={urlCalendario}
          target="_blank"
          rel="noopener noreferrer"
          className="rounded-xl"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "black",
            cursor: "pointer",
            border: "1px solid transparent",
            padding: "10px",
            backgroundColor: "white",
            boxShadow: "-2px 2px 7px 0px rgba(0, 0, 0, 0.44)",
            ...fontFamilyStyle,
          }}
        >
          <SiGooglemaps className="m-2 text-red-800" />
          <span>Agregar al Calendario</span>
        </a>
      </div>
    </div>
  );
};
