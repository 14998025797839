import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { LugarfiestaXvComponent } from "../../../components/ComponentsXv/LugarFiestaXvComponent";

export const XvSaidKaleb = () => {
  const [isOpen, setIsOpen] = useState(true);

  const peltaColores = {
    color1: "#000000",
    color2: "#999999",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_05/NEhr1SHNmVR_panckakeCortada.MP3",
    imgPortada:
      "https://i.postimg.cc/WzXy5P6c/1072bf10-f462-40e3-9062-ae35f245edfc.jpg",
    nombre: "Said Kaleb",
    fechaDeBoda: "18 MAYO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion: "Espero contar con su presencia",
    aviso: "",
    numeroConfirmacion: "+526671820346",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy primero doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo en esta etapa de mi vida. Te invito a festejar este día tan especial junto a mi.",
    fechaNewDate: "May 18, 2024 20:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/c4kjY0LQ/f4dde6e7-6371-42e9-847a-123a18144879.jpg",
    fotoDerecha:
      "https://i.postimg.cc/HskRVVdY/05d0365b-56ce-4996-9b51-0f0c82aa8113.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "#fff",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "",
    tituloDerecha: "",
    madre: "Vianca Isela Lopez Heras",
    padre: "Said Aarón Ramírez Ramírez",
    madrina: "",
    padrino: "",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 18 de Mayo de 2024.",
    ceremoniaHora: "8:30 p.m.",
    ceremoniaNombreLugar: "Salon de Eventos Majaxi",
    ceremoniaLugarMap: "Juntas de Humaya, 80050 Culiacán Rosales, Sin.",
    ceremoniaUrl:
      "https://maps.apple.com/?ll=24.810437,-107.415387&q=Marcador&t=h",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 18 de Mayo de 2024.",
    recepcionHora: "8:30 p.m.",
    recepcionNombreLugar: "Salon de Eventos Majaxi",
    recepcionLugarMap: "Juntas de Humaya, 80050 Culiacán Rosales, Sin.",
    recepcionUrl:
      "https://maps.apple.com/?ll=24.810437,-107.415387&q=Marcador&t=h",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion: "Regalo en efectivo!",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/MHzDtT2T/cash-money-icon-simple-style-vector.jpg",
    link1Enable: false,
    link2: "",
    imgLink2: "",
    link2Enable: true,
    link3: "",
    imgLink3: "",
    link3Enable: false,
    imgFondo: "",
    textColor: "#fff",
  };

  const images = [
    "https://i.postimg.cc/WzXy5P6c/1072bf10-f462-40e3-9062-ae35f245edfc.jpg",
    "https://i.postimg.cc/c4kjY0LQ/f4dde6e7-6371-42e9-847a-123a18144879.jpg",
    "https://i.postimg.cc/HskRVVdY/05d0365b-56ce-4996-9b51-0f0c82aa8113.jpg",
    "https://i.postimg.cc/L4TG9zdQ/1d7b1fa6-2b1d-4f25-a9be-0aaa59096351.jpg",
    "https://i.postimg.cc/Wz2Km4Z7/cd36627e-d220-4870-9d7d-167ddca8fa01.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = false;

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionPadres.textColor
              ? datosSeccionPadres.textColor
              : "",
          }}
          className="sm:h-auto md:h-[100vh] p-2 flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionPadres.tituloDerecha ? "2" : "1"
              } w-[80%] pt-[10vh]`}
            >
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres.tituloIzquierda}
                </p>
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.madre}
                </p>
                {datosSeccionPadres.padre ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.padre}
                </p>
              </div>
              {datosSeccionPadres.tituloDerecha ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres.tituloDerecha}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.madrina}
                  </p>
                  {datosSeccionPadres.padrino ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              {datosSeccionPadres.descipcion}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
