import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import RoutesBodaPlata from "./Routes/RoutesBodaPlata";
import "./custom.css";
import RoutesBodaDiamante from "./Routes/RoutesBodaDiamante";
import RoutesXvDiamante from "./Routes/RoutesXxDiamante";
import RoutesXvPlata from "./Routes/RoutesXvPlata";
import RoutesEventoPlata from "./Routes/RoutesEventoPlata";
import RoutesBodaOro from "./Routes/RoutesBodaOro";
import RoutesXvOro from "./Routes/RoutesXvOro";
import RutaAutomaticaBoda from "./Routes/RutaAutomaticaBoda";
import RutaAutomaticaXv from "./Routes/RutaAutomaticaXv";
import RoutesAdmin from "./Routes/RoutesAdmin";
import toast, { Toaster } from "react-hot-toast";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    
    if (location.pathname === "/boda-ejemplo-auto" && id) {
      navigate(`/boda-auto?id=${id}`, { replace: true });
    } else if (location.pathname === "/xv-ejemplo-auto" && id) {
      navigate(`/xv-auto?id=${id}`, { replace: true });
    }
  }, [location, navigate]);

  return (
    <div>
      <Toaster
  position="top-center"
        className="max-w-[50%]"
  reverseOrder={false}
/>
    <Routes>
      {AppRoutes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {RoutesBodaPlata.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {RoutesBodaOro.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {RoutesBodaDiamante.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {RoutesXvDiamante.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {RoutesXvOro.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {RoutesXvPlata.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {RoutesEventoPlata.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
      
      {RutaAutomaticaBoda.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
      
      {RutaAutomaticaXv.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
      
      {RoutesAdmin.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}

      {/* Route for handling 404 - redirecting to "/" */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
    </div>
  );
};

export default App;
