import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/CaminardeTuMano.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaVeroYHector = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a nuestra boda',
      esposo: "Héctor",
      esposa: "Vero",
      fechaDeBoda: "27 ABRIL, 2024",
      fechaNewDate: "April 27, 2024 19:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/t42YsZtz/PHOTO-2024-04-08-08-32-17-5.jpg",
      numeroNovia: "4494145104",
      numeroNovio: "4494145038",
      novios:true,
    };
   
    const peltaColores = {
      color1: "#e7e3e3",
      color2: "#edc88f",
      messageVestimenta:"Respetuosamente No niños",
    };
  
    const datosSeccion2 = {
      titulo:'¡Nos Casamos!',
      descipcion:
        "Después de un largo camino compartiendo juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
      fechaNewDate: "April 27, 2024 19:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/bN0GjFZW/PHOTO-2024-04-08-08-32-17-6.jpg",
      fotoDerecha: "https://i.postimg.cc/bYtDmvpz/PHOTO-2024-04-08-08-32-17-1.jpg",
    };
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a la boda de Vero y Héctor!',
      entradasText:'disfrutar nuestro dia especial',
      colorFondoName:"#edc88f"
    }
  
    const datosSeccion3 = {
      titulo: "¡Con la Bendición de Dios y de Nuestros Padres",
      descipcion:
        "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
      tituloIzquierda:'Padres de la novia',
      tituloDerecha:'Madre del novio',
      esposaMadre: "Verónica de Lira Palomo",
      esposaPadre: "Juan Carlos Macías Quevedo",
      esposoMadre: "Sanjuana Briones Rivas",
      esposoPadre: "",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 27 de Abril de 2024.",
      ceremoniaHora: "7:00 p.m.",
      ceremoniaNombreLugar: "Nuevo Templo de la Medallita Milagrosa",
      ceremoniaLugarMap: "Gral. José María Arteaga, Gremial, 20030 Aguascalientes, Ags.",
      ceremoniaUrl: "https://maps.app.goo.gl/VNcD2VFwvdtCE3wRA",
      recepcionFecha: "Sábado, 27 de Abril de 2024.",
      recepcionHora: "9:00 p.m.",
      recepcionNombreLugar: "Salón Punta del Sol",
      recepcionLugarMap:
        "Abelardo L. Rodríguez #1707, La Soledad, 20326 Aguascalientes, Ags.",
      recepcionUrl: "https://maps.app.goo.gl/gpjBynAqT2M9kpQD8",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
      ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
      cena: "Disfruta de una deliciosa cena",
      recepcion: "Tómate fotos con nosotros",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
    };
  
    const datosSeccion7 = {
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    };
  
    const datosSeccion8 = {
      descripcion:(
        <div>
            ¡Que nos acompañes es lo más importante! <br /><br /> Si deseas tener una muestra de cariño hacia nosotros estaremos muy agradecidos.
        </div>
      ),
      linkAmazon:
      "https://www.amazon.com.mx/wedding/registry/231OEI0SIKQ4W",
      linkLiverpool:
        "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51402427",
    };
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/sXtB3k9d/PHOTO-2024-04-08-08-32-17_(2).jpg"',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
    }
  
    const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
    const [pases, setPases] = useState(0);
  
    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const difference = targetDate - now;
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
  
    const images = [
      "https://i.postimg.cc/8CcsMpZg/PHOTO-2024-04-08-08-32-17.jpg",
      "https://i.postimg.cc/sXtB3k9d/PHOTO-2024-04-08-08-32-17-2.jpg",
      "https://i.postimg.cc/Z5TWYF3R/PHOTO-2024-04-08-08-32-17-3.jpg",
      "https://i.postimg.cc/bN0GjFZW/PHOTO-2024-04-08-08-32-17-6.jpg",
      "https://i.postimg.cc/TYvyFX3n/PHOTO-2024-04-08-08-32-17-7.jpg",
      "https://i.postimg.cc/T1gK1tCy/PHOTO-2024-04-08-08-32-17-8.jpg"
      // Add more image URLs as needed
    ];
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      cssEase: "linear",
    };
  
    const names = [
        { name: "Angel Jaimes", guess: "2" },
        { name: "Mami Conchis", guess: "1" },
        { name: "Esthela y Noé", guess: "2" },
        { name: "Dany Luna", guess: "2" },
        { name: "Conchita", guess: "3" },
        { name: "Marisol Ramos", guess: "2" },
        { name: "Mariana y Daniel", guess: "2" },
        { name: "Guillermo de Lira", guess: "1" },
        { name: "Miguel Angel de Lira", guess: "4" },
        { name: "Andrea de Lira", guess: "2" },
        { name: "Vero y Juan", guess: "2" },
        { name: "Dulce", guess: "1" },
        { name: "Alma Macías", guess: "2" },
        { name: "Guadalupe y Claudia", guess: "4" },
        { name: "Refugio Macías", guess: "1" },
        { name: "Laura Macias", guess: "2" },
        { name: "Victor Macías", guess: "2" },
        { name: "Brayan", guess: "2" },
        { name: "Luis y Karla", guess: "2" },
        { name: "María Reyes Briones", guess: "2" },
        { name: "Maria de La Luz Brionez", guess: "1" },
        { name: "Marilu", guess: "2" },
        { name: "Sanjuana", guess: "1" },
        { name: "Erika Sanchez", guess: "2" },
        { name: "Valeria y Victor", guess: "2" },
        { name: "Lupita Muñoz", guess: "2" },
        { name: "Ale Romero", guess: "2" },
        { name: "Elsa Cardona", guess: "2" },
        { name: "Cristina Romo", guess: "2" },
        { name: "Bety Gonzalez", guess: "2" },
        { name: "Miriam Cervantes", guess: "2" },
        { name: "Dany Escobedo", guess: "2" },
        { name: "Wendy", guess: "2" },
        { name: "Daniel Suarez", guess: "2" },
        { name: "Flor Chong", guess: "2" },
        { name: "Anahí Guerra", guess: "2" },
        { name: "Monica García", guess: "2" },
        { name: "Cinthia", guess: "2" },
        { name: "Marina Guevara", guess: "2" },
        { name: "Jorge A. Perez", guess: "2" },
        { name: "Lili Cisneros", guess: "2" },
        { name: "Emma Alamillo", guess: "2" },
        { name: "Diego Frsán", guess: "2" },
        { name: "Aella", guess: "2" },
        { name: "Rosy Elvira", guess: "3" },
        { name: "Juan David", guess: "2" },
        { name: "Elizabeth Najera", guess: "2" },
        { name: "Aracely Guevara", guess: "2" },
        { name: "Juan José Shaadi R.", guess: "2" },
        { name: "Nallely y Alan", guess: "2" },
        { name: "Sr. Juan y Sra. Angelita", guess: "4" },
        { name: "Sandra García", guess: "2" },
        { name: "Cinthya Vázquez", guess: "2" },
        { name: "Rafael Juaréz", guess: "2" },
        { name: "Margarita González", guess: "1" },
        { name: "Mely de Lira", guess: "2" },
        { name: "Diana de Lira", guess: "2" },
        { name: "Genoveva Palomo", guess: "3" },
        { name: "Laura Palomo", guess: "3" },
        { name: "Sofía y Luis", guess: "2" },
        { name: "Alex y Pau", guess: "2" },
        { name: "Karen Parra", guess: "2" },
        { name: "Tania", guess: "1" },
        { name: "Omar", guess: "1" },
        { name: "Clarita", guess: "1" },
        { name: "Vero y Hector", guess: "2" },
        { name: "Guadalupe de Lira", guess: "2" },
        { name: "Dariana", guess: "2" },
        { name: "Invitado Especial", guess: "1" },
        { name: "Vanessa ", guess: "2" },
        { name: "Familia de Lira García", guess: "4" },
    ];
    
  
    
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
      console.log(nameId, numPases);
      setPases(numPases);
  
      const findName = names.find((e) => e.name === nameId);
  
      if (findName) {
        console.log(findName.name);
  
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
      } else {
        console.log("Nombre no encontrado");
        window.location.href = "/";
      }
  
      AOS.init({ duration: 2000 });
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);

    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    }, []);
  
    const handlePlayButtonClick = () => {
      setIsMusicPlaying(!isMusicPlaying);
  
      const audioElement = document.getElementById("backgroundMusic");
  
      if (!isMusicPlaying && audioElement) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    };
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <PortadaBoda
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
  <div
      style={{
        backgroundImage: imagenesFondo.fondoInvitados,
        backgroundColor: peltaColores.color2,
        backgroundAttachment: isIOS ? 'local' : 'fixed',
        backgroundPosition:imagenesFondo.sizeFoto? imagenesFondo.sizeFoto: "center"
      }}
      className="sm:h-auto md:h-[100vh] bg-img-fixed sm:bg-local md:bg-fixed px-2 py-5"
    >

      <div className="flex flex-col h-full sm:pt-[2vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px] bg-slate-50 p-2 rounded-2xl text-black"
          data-aos="flip-up"
        >
          {datosInvitados.titulo}
        </h1>
        <br />
        <p
          className=" text-center text-white p-[3vh] rounded-tl-3xl rounded-br-3xl font-serif text-[35px]"
          data-aos="flip-up"
          style={{
            background: datosInvitados.colorFondoName? datosInvitados.colorFondoName : 'rgb(141,144,2)',
            background: datosInvitados.colorFondoName? datosInvitados.colorFondoName :  '#8d900273',
          }}
        >
          {NameInvitation}
        </p>
        {/* <img
          src="https://i.postimg.cc/W3b6HyLr/flores-Diamante.png"
          className="md:w-[25%] sm:w-[75%] md:h-[23vh] md:mt-[-10px] sm:mt-[10px] object-contain"
          alt=""
        /> */}
        <div className="grid sm:grid-cols-1 w-[80%] pt-[10vh]">
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
          <br /><br />
            <GiTicket className="text-[50px] bg-slate-50 p-2 rounded-2xl" 
            style={{
              color: datosInvitados.colorFondoName? datosInvitados.colorFondoName : '#a3a438',
            }}/>
            <br />
            <p className="text-black font-serif text-center text-2xl bg-slate-50 p-2 rounded-2xl">
              Cuentas con {numGuess} accesos para {datosInvitados.entradasText}
            </p>
          </div>
        </div>
      </div>
    </div>
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
        
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
  <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
          }}
          className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Ubicaciones
            </h1>
            <br />
  
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
              <div
                className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                <div
                  style={{
                    backgroundImage:
                    'url("https://lh5.googleusercontent.com/p/AF1QipOP9sYo8SS_6wdmLhuPvWWM0E3DdnaYZ-Rl5aZa=w408-h306-k-no")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.135437022028!2d-102.2903913!3d21.8908598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee662b6a2c95%3A0x4d96774f71cc08a2!2sNuevo%20Templo%20de%20la%20Medallita%20Milagrosa!5e0!3m2!1ses-419!2smx!4v1712685440505!5m2!1ses-419!2smx"
                    width="100%"
                    height="50%"
                    className="fixed"
                  ></iframe>
                  <a
                    href={datosSeccion4.ceremoniaUrl}
                    class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Mapa de la Ceremonia
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              </div>
              <div
                className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                 <div
                  style={{
                    backgroundImage:
                    'url("https://lh5.googleusercontent.com/p/AF1QipMwH7thXB2Nt_LFsscCVS_1w2utyJYs_rgBRKUb=w427-h240-k-no")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.320694926585!2d-102.33608679999999!3d21.8837232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eeb0c731df49%3A0x46cfad5c7eecaa92!2sSal%C3%B3n%20De%20Eventos%20PUNTA%20Del%20SOL!5e0!3m2!1ses-419!2smx!4v1712685565409!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>
  
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
        <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full py-[5vh]"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <PiChurchLight className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">
                  CEREMONIA RELIGIOSA
                </p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.ceremoniaReligiosa}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <FaPhotoVideo className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.recepcion}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                {/* <GiBigDiamondRing className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">CEREMONIA CIVIL</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.ceremoniaCivil}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" /> */}
                
              </div>
  
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <br />
                <ImSpoonKnife className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">CENA</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.cena}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <GiMeshBall className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">BAILE</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.baile}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
              </div>
            </div>
          </div>
        </div>
  
        <Regalos
          peltaColores={peltaColores}
          datosSeccion8={datosSeccion8}
        />
  
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
