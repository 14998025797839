import React, { useState, useEffect, useRef } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import Slider from "react-slick";
import confetti from 'canvas-confetti';

export const ConfirmacionXvComponent = ({
  peltaColores,
  images,
  datosSeccionConfirmacion,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
          observer.disconnect(); // Desconectar después de lanzar el confetti
        }
      },
      { threshold: 0.5 } // Lanzar cuando al menos el 50% del componente esté visible
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Verifica si está en inglés
    const isEnglish = datosSeccionConfirmacion.ingles;
  
    // Mensaje condicional basado en el idioma
    const message = isEnglish
      ? `Hello, I'm ${name} and I confirm my attendance ${datosSeccionConfirmacion.textoConfirmacion}${!datosSeccionConfirmacion?.enableNum ? ` with ${guests} guests` : ""}.`
      : `Hola, soy ${name} y confirmo mi asistencia ${datosSeccionConfirmacion.textoConfirmacion}${!datosSeccionConfirmacion?.enableNum ? ` con ${guests} acceso(s)` : ""}. ¡Nos vemos ahí!`;
  
    const whatsappUrl = datosSeccionConfirmacion.ingles ? `sms:${datosSeccionConfirmacion.numeroConfirmacion}?&amp;body=${encodeURIComponent(message)}`:`https://api.whatsapp.com/send?phone=${datosSeccionConfirmacion.numeroConfirmacion}&text=${encodeURIComponent(message)}`;
    
    window.location.href = whatsappUrl;
  };

  const fontFamilyStyle = {
    fontFamily: datosSeccionConfirmacion.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  return (
    <div
      ref={componentRef}
      style={{
        backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionConfirmacion.textColor || "",
      }}
      className="sm:h-auto md:h-auto flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
    >
      <h1
        className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
        data-aos="flip-up"
        style={fontFamilyStyle}
      >
        {datosSeccionConfirmacion.tituloConfirmacion
          .split("\\n")
          .map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))}
      </h1>
      <br />
      <p
        className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]"
        style={fontFamilyStyle}
      >
        {datosSeccionConfirmacion.aviso.split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
      </p>
      <br />
      <br />
      <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
        {images?.map((imageUrl, index) => (
          <div data-aos="fade-down-right" className="rolImg" key={index}>
            <img
              src={imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-auto rounded-xl max-h-[70vh]"
            />
          </div>
        ))}
      </Slider>
      <br />
      <br />
      {datosSeccionConfirmacion.numeroConfirmacion && (
        <div
          data-aos="fade-down-left"
          className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
        >
          <h2
            className="text-2xl text-gray-700 font-bold mb-4 justify-center flex"
            style={fontFamilyStyle}
          >
            {datosSeccionConfirmacion.textWhatsApp ||
             datosSeccionConfirmacion.ingles? "Save your spot!": "Confirmación de Asistencia al Evento"}{" "}
            <FaWhatsapp className="text-green-600 ml-2" />
          </h2>
          <form onSubmit={handleSubmit}>
            <label className="mb-2 flex">
              <span className="text-gray-700" style={fontFamilyStyle}>
                {datosSeccionConfirmacion.ingles? "Name:":"Nombre:"}
              </span>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                required
              />
            </label>
            <br />
            {!datosSeccionConfirmacion?.enableNum && (
              <label className="flex mb-2">
                <span className="text-gray-700" style={fontFamilyStyle}>
                  {datosSeccionConfirmacion.ingles? "Guests:":"Invitados:"}
                </span>
                <input
                  type="number"
                  value={guests}
                  onChange={(e) => setGuests(e.target.value)}
                  className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                  min={1}
                />
              </label>
            )}
            <br />
            <button
              type="submit"
              className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
              style={fontFamilyStyle}
            >
              {datosSeccionConfirmacion.ingles? "Confirm Attendance":"Confirmar Asistencia"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};
