import { BodMariaYVictor } from "../screens/bodas/Boda-diamante/BodMariaYVictor";
import { BodaEjemplo } from "../screens/bodas/Boda-diamante/BodaEjemplo";
import { BodaEjemplo2 } from "../screens/bodas/Boda-diamante/BodaEjemplo2";
import { BodaEjemploPortada2 } from "../screens/bodas/Boda-diamante/BodaEjemploPortada2";
import { BodaFernandaYEduardo } from "../screens/bodas/Boda-diamante/BodaFernandaYEduardo";
import { BodaGloriaYEfrain } from "../screens/bodas/Boda-diamante/BodaGloriaYEfrain";
import { BodaGuadalupeYFelipe } from "../screens/bodas/Boda-diamante/BodaGuadalupeYFelipe";
import { BodaRicardo } from "../screens/bodas/Boda-diamante/BodaRicardo";
import { BodaRicardoGeneral } from "../screens/bodas/Boda-diamante/BodaRicardoGeneral";
import { BodaVeroYHector } from "../screens/bodas/Boda-diamante/BodaVeroYHector";
import { BodaBetsyYAlex } from "../screens/bodas/Boda-diamante/BodaBetsyYAlex";
import { BodaNalleliYJair } from "../screens/bodas/Boda-diamante/BodaNalleliYJair";
import { BodaEjemploNew } from "../screens/bodas/Boda-diamante/BodaEjemploNew";
import { BodaMarianaYMartin } from "../screens/bodas/Boda-diamante/BodaMarianaYMartin";
import { BodaGemaYJosue } from "../screens/bodas/Boda-diamante/BodaGemaYJosue";
import { BodaSamaraYGeoffrey } from "../screens/bodas/Boda-diamante/BodaSamaraYGeoffrey";
import { BodaSamanthaYEduardo } from "../screens/bodas/Boda-diamante/BodaSamanthaYEduardo";
import { BodaEdithYRafael } from "../screens/bodas/Boda-diamante/BodaEdithYRafael";
import { BodaMariellaYVictor } from "../screens/bodas/Boda-diamante/BodaMariellaYVictor";
import { BodaSilviaYEdgar } from "../screens/bodas/Boda-diamante/BodaSilviaYEdgar";
import { BodaMonicaYDorian } from "../screens/bodas/Boda-diamante/BodaMonicaYDorian";
import { BodaCarlosYRosy } from "../screens/bodas/Boda-diamante/BodaCarlosYRosy";
import { BodaCeciliaYJose } from "../screens/bodas/Boda-diamante/BodaCeciliaYJose";
import { BodaYareliYJhonatan } from "../screens/bodas/Boda-diamante/BodaYareliYJhonatan";
import { BodaKateYUlises } from "../screens/bodas/Boda-diamante/BodaKateYUlises";
import { BodaOmarYNohemi } from "../screens/bodas/Boda-diamante/BodaOmarYNohemi";
import { BodaLuisYKimberly } from "../screens/bodas/Boda-diamante/BodaLuisYKimberly";
import { BodaEjemploSinFoto } from "../screens/bodas/Boda-diamante/BodaEjemploSinFoto";

const RoutesBodaDiamante = [
  {
    path: "/boda-diamante-ejemplo",
    element: <BodaEjemplo />,
  },
  {
    path: "/boda-diamante-ejemplo-fotos",
    element: <BodaEjemploSinFoto />,
  },
  {
    path: "/boda-diamante-ejemplo2",
    element: <BodaEjemplo2 />,
  },
  {
    path: "/boda-diamante-ejemplo3",
    element: <BodaEjemploPortada2 />,
  },
  {
    path: "/boda-diamante-ejemplo-new",
    element: <BodaEjemploNew />,
  },
  {
    path: "/boda-diamante-ricardo&karla",
    element: <BodaRicardo />,
  },
  {
    path: "/boda-diamante-ricardo&karla-general",
    element: <BodaRicardoGeneral />,
  },
  {
    path: "/boda-diamante-fernanda&eduardo",
    element: <BodaFernandaYEduardo />,
  },
  {
    path: "/boda-diamante-maria&victor",
    element: <BodMariaYVictor />,
  },
  {
    path: "/boda-diamante-gloria&efrain",
    element: <BodaGloriaYEfrain />,
  },
  {
    path: "/boda-diamante-vero&hector",
    element: <BodaVeroYHector />,
  },
  {
    path: "/boda-diamante-guadalupe&felipe",
    element: <BodaGuadalupeYFelipe />,
  },
  {
    path: "/boda-diamante-betsy&alex",
    element: <BodaBetsyYAlex />,
  },
  {
    path: "/boda-diamante-nalleli&jair",
    element: <BodaNalleliYJair />,
  },
  {
    path: "/boda-diamante-mariana&martin",
    element: <BodaMarianaYMartin />,
  },
  {
    path: '/boda-diamante-gema&josue',
    element: <BodaGemaYJosue/>
  },
  {
    path: '/boda-diamante-samara&geoffrey',
    element: <BodaSamaraYGeoffrey />
  },
  {
    path: '/boda-diamante-samantha&eduardo',
    element: <BodaSamanthaYEduardo />
  },
  {
    path: '/boda-diamante-edith&rafael',
    element: <BodaEdithYRafael />
  },
  {
    path: '/boda-diamante-mariella&victor',
    element: <BodaMariellaYVictor />
  },
  {
    path: '/boda-diamante-silvia&edgar',
    element: <BodaSilviaYEdgar />
  },
  {
    path: '/boda-diamante-monica&dorian',
    element: <BodaMonicaYDorian />
  },
  {
    path: '/boda-diamante-carlos&rosy',
    element: <BodaCarlosYRosy />
  },
  {
    path: '/boda-diamante-cecilia&je',
    element: <BodaCeciliaYJose />
  },
  {
    path: '/boda-diamante-yarely&jhonatan',
    element: <BodaYareliYJhonatan />
  },
  {
    path: '/boda-diamante-kate&ulises1s',
    element: <BodaKateYUlises />
  },
  {
    path: '/boda-diamante-omar&nohemi',
    element: <BodaOmarYNohemi />
  },
  {
    path: '/boda-diamante-luis&kimberly',
    element: <BodaLuisYKimberly />
  },
];

export default RoutesBodaDiamante;
