import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./xvkarely.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";

export const XvKarely = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Karely",
      fechaDeBoda: "4 MAYO, 2024",
      fechaNewDate: "May 4 , 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/85cSkNZm/Diseño_sin_título.png",
      numeroNovia: "6441241682",
      numeroNovio: "",
      aviso:"Respetuosamente No Niños."
    };
  
    const peltaColores = {
      color1: "#ffffff",
      color2: "#eec3dd",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Karely!',
      entradasText:'disfrutar mis XV años'
    }

    const datosSeccionContador = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "December 12, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/KcpkxY84/4.png",
      fotoDerecha: "https://i.postimg.cc/WpWg98RD/Dise-o-sin-t-tulo-5.png",
      marcoEnable: true,
      imgFondo: "",
      textColor: "#f9647e",
      contador: false,
    };
  
    const datosSeccionPadres = {
      titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      madre: "Karen Guadalupe Rosas Rosas",
      padre: "Eduardo Lopez Pellegaud",
      madrina: "Nallely Montoya Rosas",
      padrino: "Ignacio Javier Rosas Rosas",
      imgFondo: "",
      textColor: "#f9647e",
    };
  
    const datosSeccion4 = {
      dobleEnable: false,
      ceremoniaEnable: true,
      ceremoniaFecha: "Sábado, 4 de Mayo de 2024.",
      ceremoniaHora: "6:00 p.m.",
      ceremoniaNombreLugar: "Parroquia San Judas Tadeo",
      ceremoniaLugarMap:
        "C. Lago Hanka, Real del Sol, 85019 Cdad. Obregón, Son.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/Zn5h448JuejCwp5i8",
        recepcionEnable: true,
      recepcionFecha: "Sábado, 4 de Mayo de 2024.",
      recepcionHora: "9:00 p.m.",
      recepcionNombreLugar: "Jardín La Roca",
      recepcionLugarMap:
        "CARRETERA INTERNACIONAL #KM 4, Centro, 85000 Ciudad Obregón, Son.",
      recepcionUrl:
        "https://maps.app.goo.gl/ayJi4CMChK3rsnaT7",
    
        imgFondo: "",
        textColor: "#fff",
    };

    const datosSeccionConfirmacion = {
      tituloConfirmacion:
        "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
      aviso: "",
      numeroConfirmacion: "6441241682",
      textoConfirmacion: "a los XV",
      textWhatsApp: "",
      textColor: "",
      imgFondo: "",
      textColor: "#fff",
    };
  
    const images = [
      "https://i.postimg.cc/HxNRz5df/Dise-o-sin-t-tulo-3.png",
      "https://i.postimg.cc/d3TXx10D/Dise-o-sin-t-tulo-4.png"

      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <ContadorXvComponent
          peltaColores = {peltaColores}
          datosSeccionContador = {datosSeccionContador}
        />
  
  <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
  
  <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccion4}
        />

<div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color:"#f9647e"
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:px-3 sm:py-10 md:py-28 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>

          <div className="grid sm:grid-cols-1 md:grid-cols-1 w-[100%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/hjQ16FND/b0dec89a-a0d5-4993-a92c-df241f545c02.jpg")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[100%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
             
            </div>
          </div>
        </div>
      </div>
  
      <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
  
      </div>
    );
}
