import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./assets/MiCorazonViolin.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { Vestimenta } from "../../../components/Vestimenta";

export const BodaPlataJavier = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: "la boda",
    esposo: "Javier",
    esposa: "Alondra",
    fechaDeBoda: "24 MAYO, 2024",
    fechaNewDate: "May 24, 2024 19:30:00 GMT+00:00",
    imgPortada:
      "https://i.postimg.cc/q7sF8W9f/0c08352f-3930-4113-8b1b-9b40cc558dc1.jpg",
    numeroNovia: "8119876674",
    numeroNovio: "8132500118",
    novios: true,
    textColor:"#ffff",
    aviso:(
        <div>
          No niños<br />
          Aunque amamos a sus pequeños, hagan de este día una cita y pasémosla increible.
        </div>
      ),
    
  };

  const peltaColores = {
    color1: "#000000",
    color2: "#ccd6e3",
  };

  const datosSeccion8 = {
    descripcion:
      "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirnos escoger nuestro regalo.",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo = {
    fondoInvitados: 'url("https://i.postimg.cc/jdSz8MV3/img6-transformed.jpg")',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 2 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    fechaNewDate: "May 25, 2024 19:30:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/LsfpK5mR/NZ8-2892.jpg",
    fotoDerecha: "https://i.postimg.cc/0Qsq8vNS/NZ8-2852.jpg",
    textColor:"#ffff"
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Aída Garza de la Rosa",
    esposaPadre: "Jesús Sánchez Guzman",
    esposoMadre: "Maria Elena Mendoza",
    esposoPadre: "Jesús José Sosa",
    textColor:"#ffff"
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Viernes, 24 de Mayo de 2024.",
    ceremoniaHora: "4:30 p.m.",
    ceremoniaNombreLugar: "Parroquia La Preciosísima Sangre de Cristo",
    ceremoniaLugarMap: "Calle Río Potosí #118 (esquina con calle Nuevo León), Colonia, San Antonio, 66430 San Nicolás de los Garza, N.L.",
    ceremoniaUrl: "https://maps.app.goo.gl/2MXn8VX2PTuQyYQ8A",
    recepcionFecha: "Viernes, 24 de Mayo de 2024.",
    recepcionHora: "7:30 p.m.",
    recepcionNombreLugar: "Fundador Eventos",
    recepcionLugarMap:
      "Sta. Isabel 100, Santa Alicia, 66612 Cdad. Apodaca, N.L.",
    recepcionUrl: "https://maps.app.goo.gl/Qn5BGq3mUFmyJBu6A",
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/Xq2McykK/NZ8-2421.jpg",
    "https://i.postimg.cc/W3wczyDH/NZ8-3157.jpg",
    "https://i.postimg.cc/LsfpK5mR/NZ8-2892.jpg",
    "https://i.postimg.cc/0Qsq8vNS/NZ8-2852.jpg",
    // Add more image URLs as needed
  ];
  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const audioElement = document.getElementById('backgroundMusic');
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <div className="fixed top-5 right-0 m-4">
        <audio
          id="backgroundMusic"
          style={{ display: "none" }}
          autoPlay
          loop
          muted={!isMusicPlaying}
        >
          <source src={audioFondo} type="audio/mpeg" />
          {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
        </audio>
        <button
          onClick={handlePlayButtonClick}
          className="bg-black px-4 text-3xl py-2 rounded animate-bounce"
        >
          {isMusicPlaying ? "🔊" : "🔇"}
        </button>
      </div>

      <div
        style={{
          backgroundImage: 'url("https://i.postimg.cc/W3wczyDH/NZ8-3157.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e7e3e3",
        }}
        className="flex flex-col items-center sm:h-screen w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <h2
            data-aos="fade-up"
            className="text-white md:text-[7vh] sm:text-[5vh] mt-[10%] text-center txt-pre"
          >
            NUESTRA BODA
          </h2>
          <br />
          <br />
          <div className="flex items-center">
            <p
              data-aos="fade-up"
              className="text-white md:text-[20vh] sm:text-[12vh] sm:p-2 md:p-10 txt-pre"
            >
              A
            </p>
            <div className="h-full flex items-start justify-start p-2">
              <p className="text-white txt-orizontal txt-pre">
                {" "}
                {datosSeccion1.esposa}
              </p>
            </div>
            <div className="sm:h-[40vh] md:h-full w-2 bg-slate-50" />
            <div className="h-full flex items-end justify-start p-2">
              <p className="text-white txt-orizontal txt-pre">
                {" "}
                {datosSeccion1.esposo}
              </p>
            </div>
            <p
              data-aos="fade-up"
              className="text-white md:text-[20vh] sm:text-[12vh] sm:p-2 md:p-10 txt-pre"
            >
              J
            </p>
          </div>
          <br />
          <br />
          <p
            data-aos="fade-up"
            className="text-white md:text-[5vh] sm:text-[3vh] text-center txt-pre"
          >
            24 / MAYO / 2024
          </p>
        </div>
      </div>

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      
    <Vestimenta peltaColores={peltaColores} imagenesFondo={imagenesFondo} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:px-3 sm:py-10 md:p-0 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://www.pngkey.com/png/full/94-944227_icono-dinero-prstamo-banknote-svg.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color2,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
             
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://www.pngkey.com/png/full/94-944227_icono-dinero-prstamo-banknote-svg.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="md:flex sm:hidden flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              
            </div>
          </div>
        </div>
      </div>

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
