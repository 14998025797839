import React, { useState, useEffect } from "react";
import axios from "axios";

const GaleriaTable = ({ idEvento }) => {
  const [galeria, setGaleria] = useState([]);
  const [newGalerium, setNewGalerium] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/Imagenes?id=${idEvento}`);
        setGaleria(response.data);
      } catch (error) {
        setError("Error al cargar los datos");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewGalerium((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdd = async () => {
    const guestId = parseInt(idEvento);

    const addImagen = {
      urlFoto: newGalerium.UrlFoto,
      idEvento: guestId
    }
    console.log(addImagen)
    try {
      const response = await axios.post(
        "/api/Imagenes",
        addImagen
      );
      setGaleria((prevState) => [...prevState, response.data]);
      setNewGalerium({});
    } catch (error) {
      setError("Error al agregar el elemento");
    }
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setNewGalerium(galeria[index]);
  };

  const handleUpdate = async () => {
    const id = newGalerium.idGaleria;
    try {
      const response = await axios.put(
        `/api/Imagenes/${id}`,
        newGalerium
      );
      const updatedGaleria = galeria.map((item, index) =>
        index === editingIndex ? response.data : item
      );
      setGaleria(updatedGaleria);
      setNewGalerium({});
      setEditingIndex(null);
    } catch (error) {
      setError("Error al editar el elemento");
    }
  };

  const handleDelete = async (index) => {
    const id = galeria[index].idGaleria;
    try {
      await axios.delete(`/api/Imagenes/${id}`);
      setGaleria((prevState) => prevState.filter((item, i) => i !== index));
    } catch (error) {
      setError("Error al eliminar el elemento");
    }
  };

  if (loading) return <div className="text-center">Cargando...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="container mx-auto">
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border border-gray-300">URL Foto</th>
            <th className="py-2 px-4 border border-gray-300">ID Evento</th>
            <th className="py-2 px-4 border border-gray-300">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {galeria.map((item, index) => (
            <tr key={index} className="bg-white">
              <td className="py-2 px-4 border border-gray-300">
                {index === editingIndex ? (
                  <input
                    type="text"
                    name="UrlFoto"
                    value={newGalerium.UrlFoto || ""}
                    onChange={handleChange}
                    className="border border-gray-300 rounded py-1 px-2"
                  />
                ) : (
                  item.urlFoto
                )}
              </td>
              <td className="py-2 px-4 border border-gray-300">
                {index === editingIndex ? (
                  <input
                    type="text"
                    name="idEvento"
                    value={idEvento}
                    onChange={handleChange}
                    className="border border-gray-300 rounded py-1 px-2"
                  />
                ) : (
                  item.idEvento
                )}
              </td>
              <td className="py-2 px-4 border border-gray-300">
                {index === editingIndex ? (
                  <button
                    onClick={handleUpdate}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 mr-2 rounded"
                  >
                    Guardar
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => handleEdit(index)}
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 mr-2 rounded"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => handleDelete(index)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    >
                      Eliminar
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <input
          type="text"
          name="UrlFoto"
          placeholder="URL Foto"
          value={newGalerium.UrlFoto || ""}
          onChange={handleChange}
          className="border border-gray-300 rounded py-2 px-4 mr-2"
        />
        <input
          type="text"
          name="idEvento"
          placeholder="ID Evento"
          value={idEvento}
          onChange={handleChange}
          className="border border-gray-300 rounded py-2 px-4 mr-2"
        />
        <button
          onClick={handleAdd}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Agregar
        </button>
      </div>
    </div>
  );
};

export default GaleriaTable;
