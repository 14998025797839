import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import ModalCard from "../../../components/Modals/ModalCard";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";

export const BodaMadaiYFidel = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEeehhZ6SqR_y2mate.com---Ed-Sheeran-Thinking-out-Loud-Lyrics.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Fidel",
    esposa: "Madai",
    fechaDeBoda: "20 DICIEMBRE, 2024",
    imgPortada:
      "https://i.postimg.cc/WbxXzcjP/Imagen-de-Whats-App-2024-05-03-a-las-15-25-08-79eb06e2-1.jpg",
    titulo: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Su presencia en nuestra boda será el mejor regalo, pero si desean darnos otro obsequio pueden hacerlo en efectivo, el día de la boda contaremos con un cofre en el que podrán depositarlo, \\n¡no olviden añadir una tarjeta con sus deseos para nuestro matrimonio!",
    aviso: "",
    numeroConfirmacion: "+524431854424",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "#fff",
  };

  const peltaColores = {
    color1: "#ffffff",
    color2: "#791a3d",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "''Ninguna medida de tiempo contigo sera suficiente, comencemos con un para siempre”por lo que nos complace que sean participes de está unión...",
    fechaNewDate: "December 20, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/W4G9xWBc/Imagen-de-Whats-App-2024-05-03-a-las-15-26-06-d15c909b-1.jpg",
    fotoDerecha:
      "https://i.postimg.cc/0Q9tvGvM/Imagen-de-Whats-App-2024-05-03-a-las-15-24-26-3bb03c9c-1.jpg",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Judith Sánchez Cervantes",
    padre: "David Tafolla Torres y Miguel Ángel Baltazar Gómez",
    titulo2: "Mamá del novio",
    madrina: "Alicia Flores Peréz",
    padrino: "",
    titulo3: "Padrinos de velación",
    madrina2: "María Angélica Rangel Polo",
    padrino2: "Gerardo Alvarado Ávila",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Viernes, 20 de Diciembre de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Rectoría de Santa rosa de Lima ",
    ceremoniaLugarMap:
      "C. de Santiago Tapia S/N, Centro histórico de Morelia, 58000 Morelia, Mich.",
    ceremoniaUrl: "https://maps.app.goo.gl/dG6gHSjSN9BZnE6K6?g_st=iw",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 20 de Diciembre de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Jardín de eventos “Jerico”",
    recepcionLugarMap:
      "Acatempan No. 29, Col. Loma del Piñón, 58056 Morelia, Mich.",
    recepcionUrl: "https://maps.app.goo.gl/gpbjMTYBGNpeAuyR8",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/WbxXzcjP/Imagen-de-Whats-App-2024-05-03-a-las-15-25-08-79eb06e2-1.jpg",
    "https://i.postimg.cc/W4G9xWBc/Imagen-de-Whats-App-2024-05-03-a-las-15-26-06-d15c909b-1.jpg",
    "https://i.postimg.cc/0Q9tvGvM/Imagen-de-Whats-App-2024-05-03-a-las-15-24-26-3bb03c9c-1.jpg",
    "https://i.postimg.cc/tTDB41Rr/Imagen-de-Whats-App-2024-05-03-a-las-15-23-37-4f53feb9-1.jpg",
    "https://i.postimg.cc/x8CtJ0c9/Imagen-de-Whats-App-2024-05-03-a-las-15-34-27-d04d2d68-1.jpg",
    "https://i.postimg.cc/sDMT3Dkc/Imagen-de-Whats-App-2024-05-03-a-las-15-25-08-0fa2140d-1.jpg",
    "https://i.postimg.cc/MHFt1hQV/Imagen-de-Whats-App-2024-05-03-a-las-15-26-06-6123562d-1.jpg",
    "https://i.postimg.cc/nVRd8QPr/Imagen-de-Whats-App-2024-05-03-a-las-15-25-08-fae71a00-1.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />

      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <PadresBodaComponent
        peltaColores={peltaColores}
        datosSeccionPadres={datosSeccionPadres}
      />

      <CeremoniaXvComponent
        peltaColores={peltaColores}
        datosSeccionCeremonia={datosSeccionCeremonia}
      />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.postimg.cc/258Lz4RH/20230608-191428.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3756.1818057063583!2d-101.194918!3d19.7048824!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e7002aa72df%3A0xf385b1f3d29ec992!2sRector%C3%ADa%20de%20Santa%20Rosa%20de%20Lima%20(Las%20Rosas)!5e0!3m2!1ses-419!2smx!4v1714796268733!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccionCeremonia.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://i.postimg.cc/FHKhzGzJ/82128881-177739580295392-3906043009321402368-n.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              ></div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15027.490098595423!2d-101.2227679384747!3d19.67544316316283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0dcc78dc63b1%3A0xaf54a5a9dadeac82!2sJard%C3%ADn%20Jerico!5e0!3m2!1ses-419!2smx!4v1714796368116!5m2!1ses-419!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccionCeremonia.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VestimentaXvComponent
        peltaColores={peltaColores}
        datosSeccionVestimenta={datosSeccionVestimenta}
      />

      <ConfirmacionXvComponent
        peltaColores={peltaColores}
        images={images}
        messageWhats={messageWhats}
        datosSeccionConfirmacion={datosSeccionConfirmacion}
      />
    </div>
  );
};
