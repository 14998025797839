import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { motion } from 'framer-motion';
import { CheckIcon, XIcon, ExternalLinkIcon, SparklesIcon } from 'lucide-react';
import './Home.css';

const plans = [
  {
    name: 'PLATA',
    originalPrice: 600,
    price: 295,
    color: 'bg-gradient-to-br from-gray-300 to-gray-500',
    features: [
      'App móvil para compartir tus invitaciones (iOS y Android)',
      'Plantilla de colores personalizable',
      'Confirmación vía WhatsApp',
      'Detalles completos del evento',
      'Cuenta regresiva interactiva',
      'Ubicación del evento y ceremonia en Maps',
      'Galería con 4 fotografías',
      'Música de fondo personalizable'
    ],
    examples: [{text:'Ejemplo de XV años', url:"/ejemplos-xv"}, {text:'Ejemplos de Boda', url:'/ejemplos-boda'}]
  },
  {
    name: 'DIAMANTE',
    originalPrice: 2500,
    price: 895,
    color: 'bg-gradient-to-br from-blue-400 to-blue-600',
    features: [
      'Todo lo incluido en el plan PLATA',
      'Personalización avanzada de invitaciones',
      'Confirmación directa a los anfitriones',
      'Sección de hospedaje para invitados',
      'Integración con mesa de regalos',
      'Código de vestimenta interactivo',
      'Sección especial para padres y padrinos',
      'Galería extendida con 8 fotografías'
    ],
    examples: [{text:'Ejemplo de XV años', url:"/ejemplos-xv"}, {text:'Ejemplos de Boda', url:'/ejemplos-boda'}],
    popular: true
  },
  {
    name: 'ORO',
    originalPrice: 1200,
    price: 595,
    color: 'bg-gradient-to-br from-yellow-300 to-yellow-500',
    features: [
      'Todo lo incluido en el plan PLATA',
      'Sección de hospedaje para invitados',
      'Código de vestimenta interactivo',
      'Sección especial para padres y padrinos',
      'Galería con 6 fotografías',
      'Diseño premium personalizado'
    ],
    excludedFeatures: [
      'Personalización avanzada de invitaciones',
      'Integración con mesa de regalos'
    ],
    examples: [{text:'Ejemplo de XV años', url:"/ejemplos-xv"}, {text:'Ejemplos de Boda', url:'/ejemplos-boda'}]
  }
];

export const Home = () => {
  const [timeLeft, setTimeLeft] = useState(24 * 60 * 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handlePlanSelect = (planName) => {
    const whatsappMessage = `Hola, me interesa el paquete ${planName}.`;
    const whatsappUrl = `https://wa.me/4491516931?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="mx-auto overflow-x-hidden bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Header Section */}
      <header className="text-center p-14 mx-auto bg-gradient-to-b from-white to-gray-100">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-[#b7825e] text-4xl text-center md:text-6xl font-bold mb-8 animate-fade-in-down">INVITACIONES DIGITALES</h1>
          <div className="mt-8 flex justify-center">
            <img src="https://i.postimg.cc/mgYMx0Zq/430144566-122120440238202986-3770862004602796662-n.jpg" alt="Invitaciones" className="w-72 sm:w-[40vh] rounded-3xl shadow-lg transform hover:scale-105 transition-transform duration-300" />
          </div>
          <h2 className="mt-12 text-[#6d6d6d] text-2xl md:text-3xl font-semibold">Ejemplos de Nuestro Trabajo</h2>
        </div>
      </header>

      {/* Ejemplos Section */}
      <section className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {[
              { title: "Cumpleaños", img: "https://i.postimg.cc/jSp4Fpjb/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-3.png", url: "/evento-plata-cumplecamila" },
              { title: "Boda", img: "https://i.postimg.cc/rFgH7w6L/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-1.png", url: "/boda-diamante-ejemplo?name=Angel%20Jaimes" },
              { title: "Eventos", img: "https://i.postimg.cc/W1zsHyHX/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-2.png", url: "/evento-plata-graducion-ejemplo?name=Angel%20Jaimes" },
              { title: "XV Años", img: "https://i.postimg.cc/g0rr3jpc/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-4.png", url: "/evento-diamante-xv?name=Angel%20Jaimes" }
            ].map((example, idx) => (
              <motion.div
                key={example.title}
                className="flex flex-col items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: idx * 0.2 }}
              >
                <h3 className="text-3xl font-allura text-[#6d6d6d] mb-4">{example.title}</h3>
                <a href={example.url} className="group">
                  <div className="relative overflow-hidden rounded-3xl shadow-lg">
                    <img src={example.img} alt={example.title} className="w-full transition-transform duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <span className="text-white text-lg font-semibold">Ver Más</span>
                    </div>
                  </div>
                </a>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Packages Section */}
      <section className="py-20 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-800">Elige tu Plan Perfecto</h1>
          <div className="text-2xl font-semibold mb-12 text-center flex items-center justify-center">
            <SparklesIcon className="inline-block mr-2 text-yellow-500" />
            <span>Oferta especial termina en: </span>
            <span className="ml-2 text-red-600">{formatTime(timeLeft)}</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-7xl">
            {plans.map((plan, index) => (
              <motion.div
                key={plan.name}
                className={`bg-white rounded-lg shadow-lg overflow-hidden flex flex-col ${plan.popular ? 'border-4 border-blue-500' : ''}`}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <div className={`${plan.color} p-6 text-center`}>
                  <h2 className="text-3xl font-bold text-white">{plan.name}</h2>
                  {plan.popular && <span className="bg-yellow-400 text-gray-800 text-xs font-semibold px-2 py-1 rounded-full uppercase mt-2 inline-block">Más Popular</span>}
                </div>
                <div className="p-8 flex-grow">
                  <div className="text-center mb-8">
                    <span className="text-gray-400 line-through text-xl">${plan.originalPrice}</span>
                    <p className="text-5xl font-bold text-gray-800 mt-2">${plan.price}</p>
                    <p className="text-sm text-green-600 font-semibold mt-2">Ahorra ${plan.originalPrice - plan.price}</p>
                  </div>
                  <div className="text-center mb-6 font-semibold text-gray-700">Elige uno de nuestros diseños exclusivos</div>
                  <div className="mb-6">
                    <p className="font-semibold mb-3 text-gray-700">Ejemplos:</p>
                    <ul className="space-y-2">
                      {plan.examples.map((example, i) => (
                        <motion.li key={i} className="flex items-center space-x-2" initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: i * 0.1 }}>
                          <ExternalLinkIcon className="w-5 h-5 text-blue-500" />
                          <a href={example.url} className="text-blue-500 hover:underline">{example.text}</a>
                        </motion.li>
                      ))}
                    </ul>
                  </div>
                  <p className="font-semibold mb-3 text-gray-700">Incluye:</p>
                  <ul className="space-y-3">
                    {plan.features.map((feature, i) => (
                      <motion.li key={i} className="flex items-start space-x-3" initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: i * 0.1 }}>
                        <CheckIcon className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
                        <span className="text-gray-600">{feature}</span>
                      </motion.li>
                    ))}
                  </ul>
                </div>
                <motion.button
                  className="w-full bg-blue-600 text-white py-4 font-bold text-lg uppercase tracking-wide mt-auto hover:bg-blue-700 transition-colors duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handlePlanSelect(plan.name)}
                >
                  Seleccionar Plan
                </motion.button>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-16">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <p className="text-xl mb-4">¡Te estamos esperando!</p>
          <h2 className="text-4xl font-bold mb-8">VAMOS A CONECTAR</h2>
          <div className="flex justify-center space-x-8 mb-12">
            <a href="https://www.facebook.com/profile.php?id=61556089589338&mibextid=LQQJ4d" className="text-white hover:text-blue-400 transition-colors duration-300">
              <FaFacebookF size={32} />
            </a>
            <a href="https://www.instagram.com/digitalrsvp20?igsh=MTY3amx0dXV5cnpmMA%3D%3D&utm_source=qr" className="text-white hover:text-pink-400 transition-colors duration-300">
              <FaInstagram size={32} />
            </a>
            <a href="https://wa.me/4491516931?text=Hola, me gustaria adquirir una invitación." className="text-white hover:text-green-400 transition-colors duration-300">
              <FaWhatsapp size={32} />
            </a>
          </div>
          <p className="text-gray-400">Copyright © 2024 | Invitaciones Digitales</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
