import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./cancionXVmonserrat.mp3";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvIngridNicole = () => {
  const [isOpen, setIsOpen] = useState(true);

  const peltaColores = {
    color1: "#ffdede",
    color2: "#ffdbf1",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_07/NGcpw4E5w7R_y2mate.com---Ed-Sheeran-Thinking-out-Loud-Lyrics-1-.mp3",
    imgPortada: "https://i.postimg.cc/VkvP9T2N/IMG-20240626-WA0009.jpg",
    nombre: "Ingrid Nicole",
    fechaDeBoda: "20 JULIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+523312199182",
    textoConfirmacion: "al Evento",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV!",
    descipcion:
      "Hoy en este día tan especial le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda niña.\\nComenzaré una nueva y hermosa etapa de mi vida. \\nTe invito a que compartas este día conmigo.",
    fechaNewDate: "July 20, 2024 18:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/KYgXwTGS/IMG-20240626-WA0004.jpg",
    fotoDerecha: "https://i.postimg.cc/3xq5t1HF/IMG-20240626-WA0006.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra con nosotros este día tan maravilloso!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Miriam Carolina Olivares García",
    padre: "Jesús Everardo Coronado García",
    madrina: "Amalia Lisset García Gutiérrez",
    padrino: "Ricardo Anaya Martínez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionPadres2 = {
    titulo: "¡Celebra con nosotros este día tan maravilloso!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    titulo1: "Mis Padrinos",
    madre: "Zoraida Verónica Camarena",
    padre: "Álvaro García Gutiérrez ",
    madrina: "",
    padrino: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionPadres3 = {
    titulo: "¡Celebra con nosotros este día tan maravilloso!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    titulo1: "Mis Padrinos",
    madre: "Amalia Lisset García Gutiérrez",
    padre: "Ricardo Anaya Martínez",
    madrina: "",
    padrino: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 20 de Julio de 2024",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Templo Sagrado Corazón de Jesús",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/g5iuGuz57Xe42VmC7",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 20 de Julio de 2024",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Salón Alameda ",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/6LrdQ1gXDaGyT3oL7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/MKd8QxP4/IMG-20240626-WA0002.jpg",
    "https://i.postimg.cc/KYgXwTGS/IMG-20240626-WA0004.jpg",
    "https://i.postimg.cc/3xq5t1HF/IMG-20240626-WA0006.jpg",
    "https://i.postimg.cc/VkvP9T2N/IMG-20240626-WA0009.jpg",
    "https://i.postimg.cc/KzSX2YjK/IMG-20240626-WA0010.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <>
          <div
            style={{
              backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: peltaColores.color1,
              color: datosSeccionPadres.textColor
                ? datosSeccionPadres.textColor
                : "",
            }}
            className="sm:h-auto md:h-[100vh] p-2 flex flex-col items-center w-full"
          >
            {/* =========================================================================================================================================== */}
            <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
              </h1>
              <br />
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                {datosSeccionPadres.descipcion}
              </p>
              <div
                className={`grid sm:grid-cols-1 md:grid-cols-${
                  datosSeccionPadres.tituloDerecha ? "2" : "1"
                } w-[80%] pt-[10vh]`}
              >
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres.tituloIzquierda}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.madre}
                  </p>
                  {datosSeccionPadres.padre ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.padre}
                  </p>
                </div>
                {datosSeccionPadres.tituloDerecha ? (
                  <div
                    className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                    data-aos="fade-left"
                  >
                    <p className="font-semibold text-center text-2xl">
                      {datosSeccionPadres.tituloDerecha}
                    </p>
                    <br />
                    <p className="font-serif text-center text-xl">
                      {datosSeccionPadres.madrina}
                    </p>
                    {datosSeccionPadres.padrino ? (
                      <p className="font-serif text-center text-xl">&</p>
                    ) : (
                      ""
                    )}
                    <p className="font-serif text-center text-xl">
                      {datosSeccionPadres.padrino}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: peltaColores.color1,
              color: datosSeccionPadres.textColor
                ? datosSeccionPadres.textColor
                : "",
            }}
            className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
          >
            {/* =========================================================================================================================================== */}
            <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Mi Primera Comunion
              </h1>
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Miriam Daniela Coronado Olivares
              </h1>
              <br />
              <img
                src="https://i.postimg.cc/KjPVvs5J/chalice-for-first-holy-communion-free-vector-1-removebg-preview.png"
                style={{ height: "150px", width: "150px" }}
              />
              <br />
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Hoy, en un día especial, doy gracias a Dios y a mis padres por
                su apoyo en este momento único. En mi primera comunion, inicio
                una nueva etapa espiritual y deseo compartir esta ocasión
                especial contigo. Que este día esté lleno de bendiciones y amor.
                ¡Gracias por ser parte de este momento importante para mí!.
              </p>
              <div
                className={`grid sm:grid-cols-1 md:grid-cols-${
                  datosSeccionPadres2.cantidad || "1"
                } w-[80%] pt-[10vh]`}
              >
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres2.titulo1}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.madre}
                  </p>
                  {datosSeccionPadres2.padre ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.padre}
                  </p>
                </div>
              </div>
              {datosSeccionPadres2.cantidad == 2 ||
              datosSeccionPadres2.cantidad == 3 ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres2.titulo2}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.madrina}
                  </p>
                  {datosSeccionPadres2.padrino ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: peltaColores.color1,
              color: datosSeccionPadres.textColor
                ? datosSeccionPadres.textColor
                : "",
            }}
            className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
          >
            {/* =========================================================================================================================================== */}
            <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Mi Bautizo
              </h1>
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                María José Coronado Olivares
              </h1>
              <br />
              <img
                src="https://i.postimg.cc/zDSQMbCP/bautismo.png"
                style={{ height: "150px", width: "150px" }}
              />
              <br />
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Hoy, en un día especial, doy gracias a Dios y a mis padres por
                su apoyo en este momento único. En mi bautizo, inicio una nueva
                etapa espiritual y deseo compartir esta ocasión especial
                contigo. Que este día esté lleno de bendiciones y amor. ¡Gracias
                por ser parte de este momento importante para mí!.
              </p>
              <div
                className={`grid sm:grid-cols-1 md:grid-cols-${
                  datosSeccionPadres3.cantidad || "1"
                } w-[80%] pt-[10vh]`}
              >
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres3.titulo1}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.madre}
                  </p>
                  {datosSeccionPadres3.padre ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.padre}
                  </p>
                </div>
              </div>
              {datosSeccionPadres3.cantidad == 2 ||
              datosSeccionPadres3.cantidad == 3 ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres3.titulo2}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.madrina}
                  </p>
                  {datosSeccionPadres3.padrino ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
