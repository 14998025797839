import React, { useEffect, useState } from "react";

const ImagenComponent = ({ datosSeccionFoto }) => {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  const containerStyle = {
    backgroundImage: `url(${datosSeccionFoto.imgFondo || ""})`,
    backgroundAttachment: isIOS ? "local" : "fixed",
    backgroundPosition: datosSeccionFoto.sizeFoto || "center",
    backgroundSize: "cover",
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
  };

  return (
    <div style={containerStyle}>
      <div style={styles.content}>
        {datosSeccionFoto.imgContenidos && datosSeccionFoto.imgContenidos.map((imgSrc, index) => (
          <img
            key={index}
            src={imgSrc}
            alt={`Imagen ${index + 1}`}
            style={styles.image}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  content: {
    position: "relative",
    zIndex: 1,
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",  // Cambiado a columna para apilar las imágenes
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    boxSizing: "border-box",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    objectFit: "contain",
    marginBottom: "20px"  // Margen entre imágenes
  },
};

export default ImagenComponent;
