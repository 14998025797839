import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./cancionXVmonserrat.mp3";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { Vestimenta } from "../../../components/Vestimenta";
export const XvEstefaníaEsquivel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const peltaColores = {
    color1: "#ffffff",
    color2: "#eda8b7",
  };
  const datosSeccionPortada = {
    cancion:audioFondo,
    imgPortada:
      "https://i.postimg.cc/85cSkNZm/Diseño_sin_título.png",
    nombre: "Estefanía Esquivel",
    fechaDeBoda: "18 MAYO, 2024",
    titulo: "NO FALTES A MIS XV",
  };
  const datosSeccionConfirmacion = {
    tituloConfirmacion:"Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso:"",
    numeroConfirmacion: "+528715641108",
    textoConfirmacion: "a los XV",
    textWhatsApp:"",
    textColor:"",
    imgFondo:"",
    textColor:""
  };
  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
    fechaNewDate: "May 18, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/C5YzdMXy/P-ster-Bienvenido-a-la-Fiesta-de-XV-A-os-Floreado-Rojo-2.png",
    fotoDerecha:
      "https://i.postimg.cc/Vvq1rjM4/P-ster-Bienvenido-a-la-Fiesta-de-XV-A-os-Floreado-Rojo-4.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };
  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mi madre!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Madre de la Quinceañera",
    tituloDerecha: "",
    madre: "Gemma García García",
    padre: "",
    madrina: "",
    padrino:"",
    imgFondo: "",
    textColor: "",
  };
  const datosSeccionCeremonia = {
    dobleEnable:false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 18 de Mayo de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Iglesia San Francisco de Asís",
    ceremoniaLugarMap:
      "34306 Durango, Dgo.",
    ceremoniaUrl:
      "https://maps.app.goo.gl/EnSFjVtJT4uM5pc27",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 18 de Mayo de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Quinta Colibrí ",
    recepcionLugarMap:
      "Prol. Morelos #13 col. San Isidro Lerdo, Dgo",
    recepcionUrl:
      "https://maps.app.goo.gl/N5JdHmYs9HeDvvmMA",
    imgFondo: "",
    textColor: "",
  };
  const imagenesFondo ={
    fondoInvitados:'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
  }
  const images = [
    "https://i.postimg.cc/Vvq1rjM4/P-ster-Bienvenido-a-la-Fiesta-de-XV-A-os-Floreado-Rojo-4.png",
    "https://i.postimg.cc/C5YzdMXy/P-ster-Bienvenido-a-la-Fiesta-de-XV-A-os-Floreado-Rojo-2.png"
    // Add more image URLs as needed
  ];
  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} />
      <PortadaXvComponent
        datosSeccionPortada={datosSeccionPortada}
        audioFondo={audioFondo}
      />
      <ContadorXvComponent
        peltaColores={peltaColores}
        datosSeccionContador={datosSeccionContador}
      />
      <PadresXvComponent
        peltaColores={peltaColores}
        datosSeccionPadres={datosSeccionPadres}
      />
      <CeremoniaXvComponent
        peltaColores={peltaColores}
        datosSeccionCeremonia={datosSeccionCeremonia}
      />
      
      <ConfirmacionXvComponent
        peltaColores={peltaColores}
        images={images}
        datosSeccionConfirmacion={datosSeccionConfirmacion}
      />
    </div>
  );
};