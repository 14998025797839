import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { Portada } from "../../../components/Portada";
import audioFondo from "./assets/CarmenMiranda.mp3";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { Vestimenta } from "../../../components/Vestimenta";

export const CumpleCarmen = () => {
  const datosSeccion1 = {
    textoConfirmacion: "al cumpleaños",
    nombre: "Carmen",
    fechaDeBoda: "5 OCTUBRE, 2024",
    fechaNewDate: "October 05, 2024 20:00:00 GMT+00:00",
    imgPortada:
      "https://i.postimg.cc/xT144Ys1/Whats-App-Image-2024-04-15-at-11-16-12-AM.jpg",
    numeroNovia: "+526624308341",
    numeroNovio: "",
    titulo: "TE INVITO A FESTEJAR MIS 50 AÑOS !!",
  };

  const datosSeccion2 = {
    descipcion: "Llega mi cumpleaños y te invito a celebrar. ¡ No Faltes !",
    fechaNewDate: "October 05, 2024 20:00:00 GMT+00:00",
    titulo: "¡Te esperamos!",
    fotoIzquierda:
      "https://i.postimg.cc/br3FwFvP/Whats-App-Image-2024-04-15-at-11-16-44-AM.jpg",
  };

  const datosSeccion7 = {
    descripcion:
      "Te esperamos para festejar mi cumpleaños, no faltes.",
    hotel1Titulo: "Casa de Día",
    hotel1lugar: "Saturnino Campoy y Calle del Maestro, Colonia Magisterial",
    hotel1url: "https://maps.app.goo.gl/Qzoyt9g3goumDVZv5",
  };

  const peltaColores = {
    color1: "#ff96ff",
    color2: "#e3d3ba",
  };

  const datosSeccion8 = {
    descripcion:
      "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirme escoger mi regalo.",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
    vestimenta: "CASUAL",
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, soy ${name} y confirmo mi asistencia a la boda con ${guests} invitado(s). ¡Nos vemos allí!`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=4491516931&text=${encodeURIComponent(
      message
    )}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/c1mzh6Bz/Whats-App-Image-2024-04-15-at-11-16-26-AM.jpg4-AM.jpg",
    "https://i.postimg.cc/xT144Ys1/Whats-App-Image-2024-04-15-at-11-16-12-AM.jpg",
    "https://i.postimg.cc/br3FwFvP/Whats-App-Image-2024-04-15-at-11-16-44-AM.jpg",
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <Portada datosSeccion1={datosSeccion1} audioFondo={audioFondo} />
      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-[180vh] md:h-[120vh] flex flex-col items-center w-full p-2"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col pt-[10vh] w-full items-center">
          <h1
            className="font-serif sm:text-[52px] md:text-[92px] "
            data-aos="flip-up"
          >
            {datosSeccion2.titulo}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion2.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 items-center md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage: `url(${datosSeccion2.fotoIzquierda})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "45vh", // Ajusta la altura del contenedor
                display: "flex",
                backgroundPosition: "center center",
                width: "90%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded-[30%]"
              data-aos="fade-right"
            ></div>
            <div
              className="flex p-3 rounded-lg justify-center items-center mt-10"
              data-aos="fade-up"
              style={{
                border: "10px solid transparent",
                padding: "15px",
                borderImage:
                  "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')",
                borderImageSlice: "46",
                borderImageWidth: "round",
              }}
            >
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.days}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Dias</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.hours}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.minutes}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.seconds}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-[130vh] md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Lugar del Evento
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccion7.descripcion}
          </p>

          <div className="flex justify-center items-center w-[80%] pt-[8vh]">
            <div
              className="flex flex-col justify-center sm:w-[100%] md:w-[60%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
              <h1 className="font-serif text-center sm:text-xl md:text-[23px] text-red-800">
                {datosSeccion7.hotel1Titulo}
              </h1>
              <br />
              <p className="font-serif text-center sm:text-xl md:text-[23px]  w-[80%]">
                {datosSeccion7.hotel1lugar}
              </p>
              <br />
              <br />
              <a
                href={datosSeccion7.hotel1url}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: peltaColores.color2,

          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {imagenesFondo.vestimenta ? imagenesFondo.vestimenta : "FORMAL"}
          </p>
          <br />
          {peltaColores.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
              {peltaColores.messageVestimenta}{" "}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
