import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";
import Aos from "aos";

export const BodaYeniferYJoel = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_05/NErsl7bJR4R_videoplayback.m4a",
    textoConfirmacion: " nuestra boda",
    esposo: "Joel",
    esposa: "Yenifer",
    fechaDeBoda: "14 SEPTIEMBRE, 2024",
    imgPortada: "https://i.postimg.cc/28DvP8Y3/IMG-20210725-WA0018-1.jpg",
    titulo: "NUESTRA BODA",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 11 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    fechaNewDate: "September 14, 2024 10:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/C5j8z9GF/IMG-20210725-WA0043-2.jpg",
    fotoDerecha: "https://i.postimg.cc/mgZ9gXts/998-1.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Rosa Hernández Aguilar ",
    padre: "Juventino Urcid Sanchez ",
    titulo2: "Padres del Novio",
    madrina: "Virginia Ramos Hernández ",
    padrino: "Joel Juarez Gomez ",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionPadres3 = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de mis padres y mi padrino!",
    descipcion: "Emiliano Juarez Urcid",
    titulo1: "Mis padres",
    madre: "Yenifer",
    padre: "Joel",
    titulo2: "Mi padrino",
    madrina: "Daniel Ramiro Garcia",
    padrino: "",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 14 de Septiembre de 2024.",
    ceremoniaHora: "10:00 a.m",
    ceremoniaNombreLugar: "Santuario del peñón jonotla puebla",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/NMQpefmVozwEYSZL8",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 14 de Septiembre de 2024.",
    recepcionHora: "12:00 p.m",
    recepcionNombreLugar:
      "Casa familia Urcid Hernández Reyes de Vallarta, Tuzamapan de Galeana",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/gEV3pQKhVuhsCBn88",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1:
      "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+522331084703",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = false;
  const vestimenta = false;

  const images = [
    "https://i.postimg.cc/28DvP8Y3/IMG-20210725-WA0018-1.jpg",
    "https://i.postimg.cc/W4hJMdGY/1027-1.jpg",
    "https://i.postimg.cc/C5j8z9GF/IMG-20210725-WA0043-2.jpg",
    "https://i.postimg.cc/mgZ9gXts/998-1.jpg",
    // Add more image URLs as needed
  ];

  const targetDate = new Date(
    datosSeccionContador.fechaNewDate || ""
  ).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionContador.textColor
              ? datosSeccionContador.textColor
              : "",
          }}
          className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col pt-[10vh] w-full items-center">
            <h1
              className="font-serif  sm:text-[52px] md:text-[92px] "
              data-aos="flip-up"
            >
              {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
              data-aos="flip-up"
            >
              {datosSeccionContador.descipcion
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
            <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "45vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-[30%]"
                data-aos="fade-right"
              ></div>
              <br />
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Padres de la novia
              </h1>
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Rosa Hernández Aguilar
                <p className="font-serif text-center text-xl">&</p>
                Juventino Urcid Sanchez
              </p>
              <br />
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Padres del Novio
              </h1>
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Virginia Ramos Hernández
                <p className="font-serif text-center text-xl">&</p>
                Joel Juarez Gomez
              </p>
              <br />
              {datosSeccionContador.contador ? (
                <div
                  className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
                  data-aos="fade-up"
                  style={{
                    border: "15px solid transparent",
                    padding: "15px",
                    borderImage: datosSeccionContador.marcoEnable
                      ? "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')"
                      : "",
                    borderImageSlice: "46",
                    borderImageWidth: "round",
                  }}
                >
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.days}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Días</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.hours}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.minutes}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.seconds}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "45vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-full sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
                data-aos="fade-left"
              ></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {padres ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionPadres.textColor
              ? datosSeccionPadres.textColor
              : "",
          }}
          className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Mi Bautizo
            </h1>
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Emiliano Juarez Urcid
            </h1>
            <br />
            <img
              src="https://i.postimg.cc/W4hJMdGY/1027-1.jpg"
              style={{ height: "auto", width: "auto" }}
            />
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hoy, en un día especial, doy gracias a Dios y a mis padres por su
              apoyo en este momento único. En mi bautizo, inicio una nueva etapa
              espiritual y deseo compartir esta ocasión especial contigo. Que
              este día esté lleno de bendiciones y amor. ¡Gracias por ser parte
              de este momento importante para mí!.
            </p>
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionPadres3.cantidad || "1"
              } w-[80%] pt-[10vh]`}
            >
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres3.titulo1}
                </p>
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres3.madre}
                </p>
                {datosSeccionPadres3.padre ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres3.padre}
                </p>
              </div>
            </div>
            {datosSeccionPadres3.cantidad == 2 ||
            datosSeccionPadres3.cantidad == 3 ? (
              <div
                className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                data-aos="fade-left"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres3.titulo2}
                </p>
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres3.madrina}
                </p>
                {datosSeccionPadres3.padrino ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres3.padrino}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
