import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaSamanthaYEduardo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ffffff",
    color2: "#e9c4ff",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEfaTSZkqUR_y2mate.com---Regalo-De-Dios.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Eduardo",
    esposa: "Samantha",
    fechaDeBoda: "18 MAYO, 2024",
    imgPortada:
      "https://i.postimg.cc/vBRNWmPt/Whats-App-Image-2024-05-01-at-9-12-04-PM.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Nos conocimos de la manera más inesperada Nos enamoramos cita tras cita . Vivimos momentos inolvidables , Y ahora es el momento en el que nos daremos el 'Si Acepto'",
    fechaNewDate: "May 18, 2024 18:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/wjvZzZyL/Whats-App-Image-2024-05-01-at-9-12-01-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/ZKJX7CJf/Whats-App-Image-2024-05-01-at-9-12-06-PM.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Liliana Contreras Mendoza",
    padre: "Elíseo Hernández Rubio",
    titulo2: "Padres del Novio",
    madrina: "Dalia Nuñez De León ",
    padrino: "✟Julian Cepeda Meléndez✟",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 18 de Mayo de 2024.",
    ceremoniaHora: "6:30 p.m.",
    ceremoniaNombreLugar: "Casino Embajador",
    ceremoniaLugarMap:
      "Agustín de Iturbide 200, Lázaro Cárdenas, 66058 Cdad. Gral. Escobedo, N.L.",
    ceremoniaUrl: "https://maps.app.goo.gl/vS5ePD3RJQdER8u8A",
    recepcionEnable: false,
    recepcionFecha: "Sábado, 18 de Mayo de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "El Herradero Jardín y Eventos",
    recepcionLugarMap:
      "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
    recepcionUrl:
      "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3: " ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "",
    numeroConfirmacion: "+528133917154",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+528133917154",
    numeroNovio: "+528122567471",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/vBRNWmPt/Whats-App-Image-2024-05-01-at-9-12-04-PM.jpg",
    "https://i.postimg.cc/wjvZzZyL/Whats-App-Image-2024-05-01-at-9-12-01-PM.jpg",
    "https://i.postimg.cc/ZKJX7CJf/Whats-App-Image-2024-05-01-at-9-12-06-PM.jpg",
    "https://i.postimg.cc/MKtNhqTz/Whats-App-Image-2024-05-01-at-9-12-07-PM.jpg",
    "https://i.postimg.cc/t4Mf4kWf/Whats-App-Image-2024-05-01-at-9-12-04-PM-1.jpg",
    "https://i.postimg.cc/k4xhwdz3/Whats-App-Image-2024-05-01-at-9-12-05-PM.jpghttps://i.postimg.cc/wBRryNkB/Whats-App-Image-2024-05-01-at-9-12-07-PM-1.jpg ",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Fam.Contreras Piñon", guess: "5", id: 1 },
    { name: "Sr.Cesar Paez", guess: "1", id: 2 },
    { name: "Sra.Anabel Magaña", guess: "1", id: 3 },
    { name: "Sr.Hugo Contreras", guess: "1", id: 4 },
    { name: "Sra.Alejandra Contrera", guess: "2", id: 5 },
    { name: "Sra.Karen Contreras", guess: "2", id: 6 },
    { name: "Fam.Contreras Villareal", guess: "2", id: 7 },
    { name: "Fam.Vazquez Contreras", guess: "5", id: 8 },
    { name: "Sr.Aldo Contreras", guess: "3", id: 9 },
    { name: "Sr.Antonio Contreras", guess: "2", id: 10 },
    { name: "Sra.Silvia Robles", guess: "1", id: 11 },
    { name: "Sr,Armando Contreras", guess: "2", id: 12 },
    { name: "Sra,Clara Contreras", guess: "4", id: 13 },
    { name: "Sr.Jorge Contreras", guess: "6", id: 14 },
    { name: "Fam.Contreras Martinez", guess: "2", id: 15 },
    { name: "Sr.Cande Mendoza", guess: "2", id: 16 },
    { name: "Sr.Juan Eduardo Navarra", guess: "4", id: 17 },
    { name: "Sra.Yolanda Mendoza", guess: "1", id: 18 },
    { name: "Sr.Victor Rojo", guess: "4", id: 19 },
    { name: "Sr.Luis Rojo", guess: "3", id: 20 },
    { name: "Sra.Maria de los angeles", guess: "1", id: 21 },
    { name: "Sr.Raul Gutierres", guess: "3", id: 22 },
    { name: "Sr.Ivan Gutierres", guess: "2", id: 23 },
    { name: "Fam.Galvez Mendoza", guess: "4", id: 24 },
    { name: "Sra.Silva Mendoza", guess: "5", id: 25 },
    { name: "Sra.Maribel Mendoza", guess: "3", id: 26 },
    { name: "Sr.Carlos Mendoza", guess: "1", id: 27 },
    { name: "Fam.Mendoza Logoria", guess: "2", id: 28 },
    { name: "Fam.Mendoza Vidal", guess: "4", id: 29 },
    { name: "Sr.Gavino Nava", guess: "4", id: 30 },
    { name: "Fam.Luna Martinez", guess: "4", id: 31 },
    { name: "Fam.Martinez Hernandez", guess: "6", id: 32 },
    { name: "Fam.Vazquez Hernandez", guess: "4", id: 33 },
    { name: "Fam.Perez Solis", guess: "3", id: 34 },
    { name: "Fam.Martinez Tovar", guess: "4", id: 35 },
    { name: "Fam.Gonzalez Treviño", guess: "4", id: 36 },
    { name: "Fam.Treviño Lopez", guess: "6", id: 37 },
    { name: "Fam.Treviño  Quiroz", guess: "2", id: 38 },
    { name: "Sra.Mary Cardona", guess: "2", id: 39 },
    { name: "Sra.Paola Hernandez", guess: "2", id: 40 },
    { name: "Fam.Hernandez Contreras", guess: "4", id: 41 },
    { name: "Fam.Sanchez Hernandez", guess: "2", id: 42 },
    { name: "Fam.Roque Gonzalez", guess: "5", id: 43 },
    { name: "Sra.Eugenia Rubio", guess: "3", id: 44 },
    { name: "Fam.Gonzalez Hernandez", guess: "2", id: 45 },
    { name: "Fam.Sanchez Hernandez", guess: "3", id: 46 },
    { name: "Fam.Sanchez Hernandez", guess: "4", id: 47 },
    { name: "Fam.Sanchez Hernandez", guess: "3", id: 48 },
    { name: "Fam.Hernandez Aran", guess: "5", id: 49 },
    { name: "Fam.Magallanez Mendoza", guess: "4", id: 50 },
    { name: "Srta. Sofia Romero", guess: "2", id: 51 },
    { name: "Srta. Calorina Rodrigez", guess: "2", id: 52 },
    { name: "Srta Paola Gomez", guess: "2", id: 53 },
    { name: "Srta..Maryana Sauceda", guess: "2", id: 54 },
    { name: "Srta..Mileny Vega", guess: "2", id: 55 },
    { name: "Srta..Lilia Sandoval", guess: "2", id: 56 },
    { name: "Sr.Jose Manuel Reyes", guess: "2", id: 57 },
    { name: "Fam.Estrada Nuñez", guess: "8", id: 58 },
    { name: "Fam.Pecina Nuñez", guess: "3", id: 59 },
    { name: "Fam.Pecina Nuñez", guess: "6", id: 60 },
    { name: "Sr.Rodolfo Perez", guess: "1", id: 61 },
    { name: "Sr.Jose Perez", guess: "1", id: 62 },
    { name: "Fam.Martinez Nuñez", guess: "2", id: 63 },
    { name: "Fam.Diaz Cepeda", guess: "2", id: 64 },
    { name: "Fam.Cepeda Soto", guess: "3", id: 65 },
    { name: "Fam.Garcia Rodrigez", guess: "4", id: 66 },
    { name: "Sra.Martina Garcia", guess: "4", id: 67 },
    { name: "Fam.Estrada Garcia", guess: "2", id: 68 },
    { name: "Srta.Keyla Cordoba", guess: "2", id: 69 },
    { name: "Fam.Garcia Camacho", guess: "3", id: 70 },
    { name: "Fam.Torres Ruiz", guess: "4", id: 71 },
    { name: "Fam.Arreazola Flores", guess: "3", id: 72 },
    { name: "Fam.Flores Ruiz", guess: "2", id: 73 },
    { name: "Sra.Nuñez", guess: "2", id: 74 },
    { name: "Fam.Pecina", guess: "3", id: 75 },
    { name: "Fam.Contreras Pecina", guess: "3", id: 76 },
    { name: "Srta.Dalia Perez", guess: "2", id: 77 },
    { name: "Sr.Sergio Estrada", guess: "2", id: 78 },
    { name: "Fam.Hernandez Nuñez", guess: "2", id: 79 },
    { name: "Fam.Olivera Pecina", guess: "3", id: 80 },
    { name: "Sr.Osiel Coronado", guess: "2", id: 81 },
    { name: "Sra.Juani", guess: "2", id: 82 },
    { name: "Familia Solis", guess: "6", id: 83 },
    { name: "Invitado Especial", guess: "2", id: 84 },
  ];

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
