import React, { useState, useEffect, useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Slider from "react-slick";
import confetti from 'canvas-confetti';

export const Confirmacion = ({
  peltaColores,
  images,
  messageWhats,
  datosSeccion1,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
          observer.disconnect(); // Desconectar después de lanzar el confetti
        }
      },
      { threshold: 0.5 } // Lanzar cuando al menos el 50% del componente esté visible
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);
  
  return (
    <div
    ref={componentRef}
      style={{
        // backgroundImage:
        //   'url("https://static.vecteezy.com/system/resources/thumbnails/009/657/375/small/natural-branch-watercolor-png.png")',
        //   backgroundRepeat: "repeat",
        //   backgroundSize: "25%", // or "100% auto"
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
      }}
      className="sm:auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full "
    >
      <h1
        className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
        data-aos="flip-up"
      >
        {datosSeccion1.tituloConfirmacion || (
          <div>
            Espero puedan confirmarme su asistencia. <br />
            Agradezco de antemano su apreciable compañía.
          </div>
        )}
      </h1>
      <br />
      <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
        {(datosSeccion1.aviso || "").split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
      </p>
      <br />
      <br />
      <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
        {images?.map((imageUrl, index) => (
          <div data-aos="fade-down-right" className="rolImg" key={index}>
            <img
              src={imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-auto rounded-xl max-h-[50vh]"
            />
          </div>
        ))}
      </Slider>
      <br />
      <br />
      <div
        data-aos="fade-down-left"
        className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
      >
        <h2 className="text-2xl font-bold mb-4 justify-center flex">
          Confirmación de Asistencia {datosSeccion1.textoConfirmacion}{" "}
          <FaWhatsapp className="text-green-600 ml-2" />
        </h2>
        <br />
        {datosSeccion1.mensajeAsistencia ? (
          <p className="text-center font-semibold">
            {datosSeccion1.mensajeAsistencia}
          </p>
        ) : (
          ""
        )}
        <div className="flex justify-center items-center">
          <div className="w-full text-center p-4 bg-green-700 mt-5 rounded-lg">
            <a
              href={`https://api.whatsapp.com/send?phone=${
                datosSeccion1.numeroNovia
              }&text=${encodeURIComponent(messageWhats)}`}
              className="text-white text-center md:w-[60%] md:text-xl text-auto"
            >
              Confirma tu invitacón con{" "}
              {datosSeccion1.novios == true ? "nosotros" : "la quinceañera"} 💌
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
