import { BautizoEmily } from "../screens/eventos/Evento-plata/BautizoEmily";
import { CumpleAurora } from "../screens/eventos/Evento-plata/CumpleAurora";
import { CumpleCamila } from "../screens/eventos/Evento-plata/CumpleCamila";
import { CumpleEvelynPamela } from "../screens/eventos/Evento-plata/CumpleEvelynPamela";
import { EventoEjemplo } from "../screens/eventos/Evento-plata/EventoEjemplo";
import { EventoGraduacionEjemplo } from "../screens/eventos/Evento-plata/EventoGraduacionEjemplo";
import { FuneralDany } from "../screens/eventos/Evento-plata/FuneralDany";
import { CumpleCarmen } from "../screens/eventos/Evento-plata/CumpleCarmen";
import { EventoMartinWinder } from "../screens/eventos/Evento-plata/EventoMartinWinder";
import { EventoBautizoLiam } from "../screens/eventos/Evento-plata/EventoBautizoLiam";
import { CumpleIrma } from "../screens/eventos/Evento-plata/CumpleIrma";
import { QrEventWinder } from "../screens/QR/QrEventWinder";
import { BautizoGetsemaniSunduri } from "../screens/eventos/Evento-plata/BautizoGetsemaniSunduri";
import { EventoBautizo } from "../screens/eventos/Evento-plata/EventoBautizo";
import { EventoInfantilEjemplo } from "../screens/eventos/Evento-plata/EventoInfantilEjemplo";
import { EventoInfantil } from "../screens/eventos/Evento-plata/EventoInfantil";
import { CumpleIsela } from "../screens/eventos/Evento-plata/CumpleIsela";
import { EventoDiaMadreMaricela } from "../screens/eventos/Evento-plata/EventoDiaMadreMaricela";
import { EventoBodaYBautizoMarcoYReyna, EventoBodaYBautizoMarks } from "../screens/eventos/Evento-plata/EventoBodaYBautizoMark";
import { CumpleMiriam } from "../screens/eventos/Evento-plata/CumpleMiriam";
import { BautizoLandy } from "../screens/eventos/Evento-plata/BautizoLandy";
import { PrimeraComunionMagda } from "../screens/eventos/Evento-plata/PrimeraComunionMagda";
import { CumpleElizabeth } from "../screens/eventos/Evento-plata/CumpleElizabeth";
import { PrimeraComunionAnelisse } from "../screens/eventos/Evento-plata/PrimeraComunionAnelisse";
import { BabyShowerAlice } from "../screens/eventos/Evento-plata/BabyShowerAlice";
import { CumpleChuyMeza } from "../screens/eventos/Evento-plata/CumpleChuyMeza";
import { CumpleEmilyCoronado } from "../screens/eventos/Evento-plata/CumpleEmilyCoronado";
import { GraduacionColegioTabasco } from "../screens/eventos/Evento-plata/GraduacionColegioTabasco";
import { BautizoEdgar } from "../screens/eventos/Evento-plata/BautizoEdgar";

const RoutesEventoPlata = [
    {
      path: '/evento-plata-ejemplo',
      element: <EventoEjemplo />
    },
    {
      path: '/evento-plata-graducion-ejemplo',
      element: <EventoGraduacionEjemplo />
    },
    {
      path: '/evento-plata-dany',
      element: <FuneralDany />
    },
    {
      path: '/evento-plata-cumplecamila',
      element: <CumpleCamila />
    },
    {
      path: '/evento-plata-cumpleaurora',
      element: <CumpleAurora />
    },
    {
      path: '/evento-plata-cumple-evelynPamela',
      element: <CumpleEvelynPamela />
    },
    {
      path: '/evento-plata-bautizo-emily',
      element: <BautizoEmily />
    },
    {
      path: '/evento-plata-cumplecarmen',
      element: <CumpleCarmen />
    },
    {
      path: '/evento-Martin',
      element: <EventoMartinWinder />
    },
    {
      path: '/evento-Martin-acceso',
      element: <QrEventWinder />
    },
    {
      path: '/evento-BautizoLiam',
      element: <EventoBautizoLiam />
    },
    {
      path: '/evento-CumpleIrma',
      element: <CumpleIrma />
    },
    {
      path: '/evento-BautizoGetsemaniSunduri',
      element: <BautizoGetsemaniSunduri />
    },
    {
      path: '/evento-bautizo-ejemplo',
      element: <EventoBautizo />
    },
    {
      path: '/evento-cumpleaños-infantil-ejemplo',
      element: <EventoInfantilEjemplo />
    },
    {
      path: '/evento-infantil-ejemplo',
      element: <EventoInfantil />
    },
    {
      path: '/evento-cumple-isela',
      element: <CumpleIsela />
    },
    {
      path: '/evento-madres-escuela',
      element: <EventoDiaMadreMaricela />
    },
    {
      path: '/evento-boda-bautizo-marco&reyna',
      element: <EventoBodaYBautizoMarcoYReyna/>
    },
    {
      path: '/evento-cumple-hilda',
      element: <CumpleMiriam />
    },
    {
      path: '/evento-cumple-karim',
      element: <BautizoLandy />
    },
    {
      path: '/evento-comunion-angel',
      element: <PrimeraComunionMagda />
    },
    {
      path: '/evento-cumple-Elizabeth',
      element: <CumpleElizabeth />
    },
    {
      path: '/evento-comunion-Anelisse',
      element: <PrimeraComunionAnelisse />
    },
    {
      path: '/evento-baby-shower-alice',
      element: <BabyShowerAlice />
    },
    {
      path: '/evento-cumple-chuy-meza',
      element: <CumpleChuyMeza />
    },
    {
      path: '/evento-cumple-emily-coronado',
      element: <CumpleEmilyCoronado />
    },
    {
      path: '/evento-gracuacion-colegio-tabasco',
      element: <GraduacionColegioTabasco />
    },
    {
      path: '/evento-bautizo-edgar-elian',
      element: <BautizoEdgar />
    },
]

export default RoutesEventoPlata;