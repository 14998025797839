import React from "react";

export const HospedajeXvComponent = ({
  peltaColores,
  datosSeccionHospedaje,
}) => {
  const fontFamilyStyle = { fontFamily: datosSeccionHospedaje.tipoLetra || "Cormorant Garamond, serif" };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionHospedaje.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionHospedaje.textColor || "#333",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto py-[5vh] md:h-auto flex flex-col w-full"
    >
      <div className="container mx-auto h-auto px-4">
        <div className="bg-white bg-opacity-90 rounded-lg shadow-2xl p-8 md:p-12 max-w-4xl mx-auto">
          <h1
            className="text-3xl md:text-4xl font-light text-center mb-8 border-b border-gray-300 pb-4"
            data-aos="fade-down"
            style={fontFamilyStyle}
          >
            {datosSeccionHospedaje.ingles ? "Accommodation" : "Hospedaje"}
          </h1>
          <p
            className="text-center text-lg md:text-xl mb-12 leading-relaxed"
            data-aos="fade-up"
            style={fontFamilyStyle}
          >
            {datosSeccionHospedaje.descripcion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </p>

          <div className={`grid gap-8 ${datosSeccionHospedaje.disponibleDos ? 'md:grid-cols-2' : 'md:grid-cols-1'}`}>
            <HotelCard
              title={datosSeccionHospedaje.hotel1Titulo}
              location={datosSeccionHospedaje.hotel1lugar}
              url={datosSeccionHospedaje.hotel1url}
              isEnglish={datosSeccionHospedaje.ingles}
            />

            {datosSeccionHospedaje.disponibleDos && (
              <HotelCard
                title={datosSeccionHospedaje.hotel2Titulo}
                location={datosSeccionHospedaje.hotel2lugar}
                url={datosSeccionHospedaje.hotel2url}
                isEnglish={datosSeccionHospedaje.ingles}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const HotelCard = ({ title, location, url, isEnglish }) => (
  <div
    className="bg-gray-100 rounded-lg overflow-hidden transition duration-300 hover:shadow-lg"
    data-aos="fade-up"
  >
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-3 text-gray-800">{title}</h2>
      <p className="text-gray-600 mb-6">{location}</p>
      <a
        href={url}
        className="inline-block bg-gray-800 text-white py-2 px-4 rounded-full transition duration-300 hover:bg-gray-700"
      >
        {isEnglish ? "View Map" : "Ver Mapa"}
      </a>
    </div>
  </div>
);
