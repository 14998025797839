import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvBetzaliYBetzabeth = () => {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#db889b",
    color2: "#f099c7",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_06/NFdvRIrcLtR_y2mate.com---Calvin-Harris-Rihanna-This-Is-What-You-Came-For-Lyrics.mp3",
    imgPortada:
      "https://i.postimg.cc/VL0vhbfq/45d955aa-5cb8-46a6-ad7d-3be1e152c3c4.jpg",
    nombre: "Betzali & Betzabeth",
    fechaDeBoda: "16 AGOSTO, 2024",
    titulo: "NO FALTES A NUESTROS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso:
      "Se les pide por favor puntualidad ya que todo está programado y no se cortará la secuencia del evento.",
    numeroConfirmacion: "+525521429852",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo:
      "https://i.postimg.cc/1tQ2B41Q/deced15b921fa821d57b0841b40e4f36.jpg",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nuestros XV!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le damos gracias a Dios por habernos permitido estar aquí y a nuestros padres por darnos el apoyo para realizar el sueño de toda quinceañera. Hoy dejaremos una etapa para comenzar una nueva de nuestras vidas. Te invito a que compartas este día tan especial para nosotras.",
    fechaNewDate: "August 16, 2024 15:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/nzLcGXSQ/b811fca6-21f0-47e2-a867-dac2c6798448.jpg",
    fotoDerecha:
      "https://i.postimg.cc/G9wGb6Jf/f92e0644-7811-4b50-95f8-593b40d3f186.jpg",
    marcoEnable: true,
    imgFondo:
      "https://i.postimg.cc/1tQ2B41Q/deced15b921fa821d57b0841b40e4f36.jpg",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Nuestros Padres",
    tituloDerecha: "Nuestros Padrinos",
    madre: "Adriana Marisol Sosa ",
    padre: "Luis López ",
    madrina: "Ivon Rodríguez ",
    padrino: "Salvador González ",
    imgFondo:
      "https://i.postimg.cc/1tQ2B41Q/deced15b921fa821d57b0841b40e4f36.jpg",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Viernes, 16 de Agosto de 2024.",
    ceremoniaHora: "3:30 p.m.",
    ceremoniaNombreLugar: "Catedral Jesús Señor de la Misericordia",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/NYdLy32zoNg63ed7A",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 16 de Agosto de 2024.",
    recepcionHora: "5:30 p.m.",
    recepcionNombreLugar: "Salon de Fiestas la Hacienda",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/DgbbV9rxVLRLBEzE8",
    imgFondo:
      "https://i.postimg.cc/1tQ2B41Q/deced15b921fa821d57b0841b40e4f36.jpg",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
    imgFondo:
      "https://i.postimg.cc/1tQ2B41Q/deced15b921fa821d57b0841b40e4f36.jpg",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://i.postimg.cc/N0B0LJ1D/058794dd-404e-4d55-899f-fc770d5d3eeb.jpg",
    titulo: `¡Has sido invitado a los XV de ${datosSeccionPortada.nombre}!`,
    entradasText: "disfrutar nuestros XV años",
    colorFondoName: "",
  };

  const images = [
    "https://i.postimg.cc/N0B0LJ1D/058794dd-404e-4d55-899f-fc770d5d3eeb.jpg	",
    "https://i.postimg.cc/c124f4Rr/33ce548b-c8dd-4a5d-b6ba-e525d499596b.jpg	",
    "https://i.postimg.cc/fbCRdcCN/37e92f6d-7b72-46a0-831f-dadf4ea15dc4.jpg	",
    "https://i.postimg.cc/VL0vhbfq/45d955aa-5cb8-46a6-ad7d-3be1e152c3c4.jpg	",
    "https://i.postimg.cc/j2wxBGbx/59eb4d6e-d740-417d-8128-094998db92af.jpg	",
    "https://i.postimg.cc/2S46kRB3/6ecd5054-4ba9-43d6-b23c-f7fb1c64ad79.jpg	",
    "https://i.postimg.cc/vBS8qGC2/77792f3f-a7a3-447f-aa9f-8ae1ddbe5eba.jpg	",
    "https://i.postimg.cc/nzLcGXSQ/b811fca6-21f0-47e2-a867-dac2c6798448.jpg	",
    "https://i.postimg.cc/G9wGb6Jf/f92e0644-7811-4b50-95f8-593b40d3f186.jpg	",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  const names = [
    { name: "Mario Sosa", guess: "4", id: 1 },
    { name: "Veronica Sosa", guess: "4", id: 2 },
    { name: "Fernando Delgadillo", guess: "5", id: 3 },
    { name: "Mario Sosa", guess: "6", id: 4 },
    { name: "Celerino García", guess: "3", id: 5 },
    { name: "Manuel Torres", guess: "2", id: 6 },
    { name: "Juan Manuel Torres", guess: "4", id: 7 },
    { name: "Juan Carlos Torres", guess: "4", id: 8 },
    { name: "Eucevia Torres", guess: "4", id: 9 },
    { name: "Alberto García", guess: "3", id: 10 },
    { name: "Lucia Torres", guess: "3", id: 11 },
    { name: "Eduardo Sosa", guess: "4", id: 12 },
    { name: "Sergio Sosa", guess: "6", id: 13 },
    { name: "Carlos Ruiz", guess: "6", id: 14 },
    { name: "Adrian Sosa", guess: "2", id: 15 },
    { name: "Miguel Angel Penilla", guess: "4", id: 16 },
    { name: "Karina Ruiz", guess: "4", id: 17 },
    { name: "Susana Luna", guess: "4", id: 18 },
    { name: "Nancy Luna", guess: "2", id: 19 },
    { name: "Felipe Luna", guess: "6", id: 20 },
    { name: "Yisel Belmontes", guess: "4", id: 21 },
    { name: "Fernando Hernández", guess: "4", id: 22 },
    { name: "Fernando Monsalvo", guess: "6", id: 23 },
    { name: "Arturo Monsalvo", guess: "6", id: 24 },
    { name: "Jatziri Sosa", guess: "3", id: 25 },
    { name: "Karla Raigoza", guess: "3", id: 26 },
    { name: "Elvira González", guess: "4", id: 27 },
    { name: "Selene Nanguel", guess: "3", id: 28 },
    { name: "Edgar Paredes", guess: "4", id: 29 },
    { name: "Fernando Mendoza", guess: "4", id: 30 },
    { name: "Sergio Jiménez", guess: "3", id: 31 },
    { name: "Gabriel Calvo", guess: "3", id: 32 },
    { name: "Pedro López", guess: "8", id: 33 },
    { name: "Uriel Pérez", guess: "4", id: 34 },
    { name: "Arturo Segovia", guess: "2", id: 35 },
    { name: "Juan Rodríguez", guess: "1", id: 36 },
    { name: "Alejandro Velazco", guess: "2", id: 37 },
    { name: "Leticia Espinoza", guess: "2", id: 38 },
    { name: "Felipe Rodríguez", guess: "6", id: 39 },
    { name: "Cristina Espinoza", guess: "4", id: 40 },
    { name: "Alejandra Espinoza", guess: "3", id: 41 },
    { name: "Antonio Espinoza", guess: "6", id: 42 },
    { name: "Cricel Hernández", guess: "4", id: 43 },
    { name: "Alejandra Hernández", guess: "3", id: 44 },
    { name: "Alan Espinoza", guess: "2", id: 45 },
    { name: "Ivón Rodríguez", guess: "6", id: 46 },
    { name: "Invitado", guess: "1", id: 47 }
];

  useEffect(() => {
    const findName = names.find((e) => e.id == nameId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionPadres.textColor
              ? datosSeccionPadres.textColor
              : "",
          }}
          className="sm:h-auto md:h-[100vh] p-2 flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              {datosSeccionPadres.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              {datosSeccionPadres.descipcion}
            </p>
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionPadres.tituloDerecha ? "2" : "1"
              } w-[80%] pt-[10vh]`}
            >
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <p className="font-semibold text-center text-2xl">
                  {datosSeccionPadres.tituloIzquierda}
                </p>
                <br />
                <img
                  src="https://i.postimg.cc/1XZRD9RL/Whats-App-Image-2024-06-04-at-20-53-19-95adfcb5.jpg"
                  style={{ height: "auto", width: "auto" }}
                />
                <br />
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.madre}
                </p>
                {datosSeccionPadres.padre ? (
                  <p className="font-serif text-center text-xl">&</p>
                ) : (
                  ""
                )}
                <p className="font-serif text-center text-xl">
                  {datosSeccionPadres.padre}
                </p>
              </div>
              {datosSeccionPadres.tituloDerecha ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres.tituloDerecha}
                  </p>
                  <br />
                  <img
                    src="https://i.postimg.cc/Ss9NXX46/Whats-App-Image-2024-06-04-at-20-53-19-5fe41366.jpg"
                    style={{ height: "auto", width: "auto" }}
                  />
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.madrina}
                  </p>
                  {datosSeccionPadres.padrino ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
