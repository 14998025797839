import React, { useEffect, useState } from "react";

export const VestimentaXvComponentD2 = ({
    peltaColores,
    datosSeccionVestimenta,
  }) => {
    const [isIOS, setIsIOS] = useState(false);
  
    useEffect(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    }, []);
  
    // Definimos el estilo de la fuente
    const fontFamilyStyle = { fontFamily: datosSeccionVestimenta.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };
  
    return (
      <div
        style={{
          backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
          backgroundColor: datosSeccionVestimenta.color1,
          backgroundAttachment: isIOS ? "local" : "fixed",
          color: datosSeccionVestimenta.textColor || "",
          ...fontFamilyStyle,
        }}
        className="sm:h-auto bg-img-fixed md:h-auto w-full py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
            style={fontFamilyStyle}
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className="text-center pt-[5vh] font-semibold font-serif text-xl"
            data-aos="flip-up"
            style={fontFamilyStyle}
          >
            {datosSeccionVestimenta.vestimenta || "FORMAL"}
          </p>
          {datosSeccionVestimenta.messageVestimenta ? (
            <p className="text-center pt-[5vh] text-xl w-[80%]" style={fontFamilyStyle}>
              {datosSeccionVestimenta.messageVestimenta
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          ) : (
            ""
          )}
  
          {datosSeccionVestimenta.urlVestimentaMujer === "Nada" ? (
            ""
          ) : (
            <div className="grid grid-cols-2 w-[95%] pt-[5vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-left"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaMujer ||
                    "https://i.postimg.cc/NMPR4p2z/2.png"
                  }
                  className="h-max-[30vh]"
                  alt="Vestimenta Mujer"
                />
                <br />
                <p className="font-serif text-2xl" style={fontFamilyStyle}>Mujeres</p>
              </div>
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaHombre ||
                    "https://i.postimg.cc/d3krSr8t/3.png"
                  }
                  className="h-max-[30vh]"
                  alt="Vestimenta Hombre"
                />
                <br />
                <p className="font-serif text-2xl" style={fontFamilyStyle}>Hombres</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  