import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { CSSTransition } from "react-transition-group";
import Aos from "aos";

export const XvMariaFernanda = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const [flapOpen, setFlapOpen] = useState(false);
  const toggleFlap = () => {
    setFlapOpen(!flapOpen);
  };

  const peltaColores = {
    color1: "#ffffff",
    color2: "#eba2f5",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEbkwdByzFR_y2mate.com---Halo-Made-Famous-by-Beyonce.mp3",
    imgPortada: "https://i.postimg.cc/j55NYTvf/IMG-20240328-WA0060.jpg",
    nombre: "María Fernanda",
    fechaDeBoda: "07 JULIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso:
      "Se puntual a la cita y disfruta con nosotros este bello momento tan especial.",
    numeroConfirmacion: "+524771915766",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "https://i.postimg.cc/wM20q6x0/Invitaci-n-para-15-a-os-moderna-ilustrada-mariposas-morada-3.png",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hay momentos inolvidables que se atesoran con el corazón para siempre, por esa razón quiero que compartas conmigo en este día tan especial. Señor, te doy gracias porque tu mano amorosa ha estado siempre en mi vida. Sobre todo, agradezco por tener una familia que está en cada momento especial de mi vida: mis padres, mis padrinos, que hacen todo por guiarme y brindarme sus mejores consejos y su amor incondicional.",
    fechaNewDate: "July 07, 2024 15:30:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/WpGkxjvc/IMG-20231231-WA0010.jpg",
    fotoDerecha: "https://i.postimg.cc/43wt0yvZ/IMG-20240328-WA0050.jpg",
    marcoEnable: true,
    imgFondo: "htps://i.postimg.cc/rpSW2M9b/white-purple-modern-hello-march-phone-wallpaper.png",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Claudia Elvira Herrera Hernández",
    padre: "Rogelio Rodríguez Amaro",
    madrina: "María Lourdes Amaro Zuñiga",
    padrino: "Pedro Gómez Segura",
    imgFondo: "https://i.postimg.cc/rpSW2M9b/white-purple-modern-hello-march-phone-wallpaper.png",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Domingo, 07 de Julio de 2024.",
    ceremoniaHora: "2:00 p.m.",
    ceremoniaNombreLugar: "Templo de Nuestro Señor de la Resurrección ",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/dCKX4GHxd6mrEQKZA",
    recepcionEnable: true,
    recepcionFecha: "Domingo, 07 de Julio de 2024.",
    recepcionHora: "3:30 p.m.",
    recepcionNombreLugar: "Salón Extravaganza",
    recepcionLugarMap:
      "Blvr. Mariano Escobedo Pte. 4821, Cd Satelite, 37407 León de los Aldama, Gto.",
    recepcionUrl: "https://maps.app.goo.gl/KX6qutfr48AEQkry9",
    imgFondo: "https://i.postimg.cc/wM20q6x0/Invitaci-n-para-15-a-os-moderna-ilustrada-mariposas-morada-3.png",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/y8m0FvkD/IMG-20240429-205115-217.jpg",
    "https://i.postimg.cc/43wt0yvZ/IMG-20240328-WA0050.jpg",
    "https://i.postimg.cc/XYSwGT7M/IMG-20231231-WA0010.jpg",
    "https://i.postimg.cc/3w3m9mvn/IMG-20240429-205427-961.jpg",
    "https://i.postimg.cc/j55NYTvf/IMG-20240328-WA0060.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <div>
          <CSSTransition
            in={isOpen}
            timeout={2000}
            classNames="modal"
            unmountOnExit
            appear
          >
            <div className="modal container">
              <div
                className={`envelope-wrapper ${flapOpen ? "flap" : ""}`}
                onClick={toggleFlap}
              >
                <div className="envelope">
                  <div
                    className="letter"
                    style={{
                      backgroundImage: `url(${
                        datosSeccionPortada.imgPortada || ""
                      })`,
                    }}
                  >
                    <div className="text">
                      <button
                        className="ver-invitacion-btn"
                        onClick={closeModal}
                      >
                        Ver Invitación
                      </button>
                    </div>
                  </div>
                </div>
                <div className="heart"></div>
                <p className="texto-corazon">
                  {datosSeccionPortada.nombre || ""}
                </p>
              </div>
            </div>
          </CSSTransition>
        </div>
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
