import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { FaPhotoVideo } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import audioFondo from "./assets/CamiloMachu.mp3";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { GiPalmTree } from "react-icons/gi";
import { Invitados } from "../../../components/Invitados";
import { Hospedaje } from "../../../components/Hospedaje";

export const BodaFernandaYEduardo = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion: "a nuestra boda",
      esposo: "Iker   ",
      esposa: "Brenda",
      fechaDeBoda: "26 ABRIL, 2024",
      fechaNewDate: "April 26, 2024 13:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/m2qqkjDs/B17T9829.jpg",
      numeroNovia: "3291043781",
      numeroNovio: "4491014040",
      novios: true,
      aviso:""
    };
  
    const peltaColores = {
      color1: "#d4f3f9",
      color2: "#ffe7de",
      messageVestimenta:"¡Mujeres evitar el color negro y reservado el blanco para la novia y el azul claro para las damas de honor!"
    };

    const datosSeccion7 = {
      descripcion:(
        <div>
          ¡AVISO! <br /> Salida de transporte de Aguascalientes en estacionamiento del Soriana de Casa Blanca a las 4:30 AM el jueves 25 de Abril. <br /> <br /> Salida de transporte de La Chona en la glorieta de 13 de septiembre esq. Con Gral. Anaya a las 5:30 AM el jueves 25 de Abril.
        </div>
      ),
      hotel1Titulo:"Krystal Grand Puerto Vallarta",
      hotel1lugar:
        "Ave De Las Garzas 136, Zona Hotelera, Zona Hotelera Nte., 48333 Puerto Vallarta, Jal.",
      hotel1url:
        "https://maps.app.goo.gl/2Ua81TS87TkqNE566",
    };
  
    const datosSeccion2 = {
      titulo: "¡Nos Casamos!",
      descipcion:
      (
        <div>
        Usted ha sido seleccionado para asistir a nuestro enlace y ¿pudiera haber mejor cosa que compartir nuestro amor con ustedes? Pues claro!! Una fiesta masiva. <br/> Por favor está invitación es única e intransferible y se autodestruirá en cinco minutos por lo que rogamos tu confirmación inmediatamente. PD. No nos hacemos responsables de los que pase en la fiesta.
        </div>
      ),
      fechaNewDate: "April 26, 2024 13:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/GtmN8SGc/B17T9628.jpg",
      fotoDerecha: "https://i.postimg.cc/MKXNzNz5/B17T9706.jpg",
    };
  
    const datosInvitados = {
      titulo: "¡Has sido invitado a la boda de Brenda e Iker!",
      entradasText: "disfrutar nuestro dia especial",
      colorFondoName:"#31312fc2"
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor y apoyo de nuestros padres!",
      descipcion:
        "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
      tituloIzquierda: "Madre de la novia",
      tituloDerecha: "Padres del novio",
      esposaMadre: "Martha Romo Quiroz",
      esposaPadre: "",
      esposoMadre: "María del Rocío Herrera Fernández",
      esposoPadre: "Eduardo Argüelles González",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Viernes, 26 de Abril de 2024.",
      ceremoniaHora: "1:00 p.m.",
      ceremoniaNombreLugar: "Parroquia de nuestra señora del Refugio, Puerto Vallarta",
      ceremoniaLugarMap: "C. Perú s/n, 5 de Diciembre, 48350 Puerto Vallarta, Jal.",
      ceremoniaUrl: "https://maps.app.goo.gl/EyTkCC3dng2EP6nF6",
      recepcionFecha: "Viernes, 26 de Abril de 2024.",
      recepcionHora: "5:00 p.m.",
      recepcionNombreLugar: "Krystal Grand Puerto Vallarta",
      recepcionLugarMap:
        "Ave De Las Garzas 136, Zona Hotelera, Zona Hotelera Nte., 48333 Puerto Vallarta, Jal.",
      recepcionUrl: "https://maps.app.goo.gl/2Ua81TS87TkqNE566",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "1 pm Boda religiosa en la iglesia de nuestra señora del Refugio en Puerto Vallarta ",
      ceremoniaCivil: "5 pm Boda simbólica a pie de playa del hotel Krystal Grand Puerto Vallarta",
      cena: "Disfruta de una deliciosa cena",
      recepcion: "6 pm a 11 pm Comienza la fiesta a pie de playa hotel Krystal Grand Puerto Vallarta",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
    };

    const datosSeccion8 = {
      descripcion:
        "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
      linkSears: "https://www.sears.com.mx/Mesa-de-Regalos/64631/invitacion",
      linkLiverpool:
        "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51182191",
        linkAmazon: "https://www.amazon.com.mx/wedding/registry/1S3HPM7KTVMK0",
    };
  
    const imagenesFondo = {
      fondoInvitados: 'url("https://i.postimg.cc/Gpn5JpGg/B17T9687.jpg")',
      fondoVestimenta:
        'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
        sizeFoto:"",
        colorFondoName:"#31312fc2"
    };
  
    const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
    const [pases, setPases] = useState(0);
  
    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const difference = targetDate - now;
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
  
    const images = [
      "https://i.postimg.cc/RZHYHt6n/B17T9607.jpg",
      "https://i.postimg.cc/zBj6f1Lt/B17T9613.jpg",
      "https://i.postimg.cc/2j0gRrN3/B17T9621.jpg",
      "https://i.postimg.cc/SR1PW7fc/B17T9674.jpg",
      "https://i.postimg.cc/m2qqkjDs/B17T9829.jpg",
      "https://i.postimg.cc/MKXNzNz5/B17T9706.jpg"
      // Add more image URLs as needed
    ];
  
    const names = [
      { name: "Angel Jaimes", guess: "2" },
      { name: "Martha Romo", guess: "1" },
      { name: "Angel Rugerio", guess: "1" },
      { name: "María del Rocío Herrera", guess: "1" },
      { name: "Eduardo Arguelles González", guess: "1" },
      { name: "Adrian Montañez", guess: "1" },
      { name: "Yadira Mata", guess: "1" },
      { name: "Carolina Romo", guess: "1" },
      { name: "Estefania Romo", guess: "1" },
      { name: "Alonso Medina", guess: "1" },
      { name: "Osvaldo Cervantes", guess: "1" },
      { name: "Carlos Viramontes", guess: "1" },
      { name: "Aaron Nuñez", guess: "1" },
      { name: "Alejandro Rodríguez", guess: "1" },
      { name: "César Medina", guess: "1" },
      { name: "Tzunaly Acosta", guess: "1" },
      { name: "Diego Díaz", guess: "1" },
      { name: "Paola Gama", guess: "1" },
      { name: "Elias Machain", guess: "1" },
      { name: "Mara García", guess: "1" },
      { name: "Paola Bautista", guess: "1" },
      { name: "Karla Rebeca Macías", guess: "1" },
      { name: "Gilberto Olalde", guess: "1" },
      { name: "Hilary Ruiz", guess: "1" },
      { name: "Karla Saldivar", guess: "1" },
      { name: "Kassandra Saldivar", guess: "1" },
      { name: "Irham Díaz", guess: "1" },
      { name: "Mónica Sandoval", guess: "1" },
      { name: "Oscar Valdivia", guess: "1" },
      { name: "Osvaldo Valdivia", guess: "1" },
      { name: "Jessica Pliego", guess: "1" },
      { name: "Brenda Paulina Sánchez", guess: "1" },
      { name: "Eduardo Arguelles", guess: "1" },
      { name: "Gustavo Valdez", guess: "1" },
      { name: "Rocío Arguelles", guess: "1" },
      { name: "Jairo Cortes", guess: "1" },
      { name: "María Ofelia Juan", guess: "1" },
      { name: "Luis Enrique Gómez", guess: "1" },
      { name: "Cheyenne López", guess: "1" },
      { name: "Carlos Martín del Campo", guess: "1" },
      { name: "Sergio García", guess: "1" },
      { name: "Fátima Montañez", guess: "1" },
      { name: "María Alatorre", guess: "1" },
      { name: "Raul Osorio", guess: "1" },
      { name: "Andrea Padilla", guess: "1" },
      { name: "Yesenia Barron", guess: "1" }
  ];
  
  
    const [isOpen, setIsOpen] = useState(false);
    const [messageWhats, setMessageWhats] = useState("");
    const [isIOS, setIsIOS] = useState(false);
  
    useEffect(() => {
      console.log(nameId, names.length);
      setPases(numPases);
      setIsOpen(true);
  
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  
      const findName = names.find((e) => e.name == nameId);
      console.log(findName.name);
  
      if (findName) {
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
  
        document.title = `Título para ${findName.name}`;
        const metaDescription = document.createElement("meta");
        metaDescription.setAttribute("name", "description");
        metaDescription.setAttribute("content", `Descripción para ${findName.name}`);
        document.head.appendChild(metaDescription);
  
        const metaImage = document.createElement("meta");
        metaImage.setAttribute("property", "og:image");
        metaImage.setAttribute("content", "https://cdn.pixabay.com/photo/2014/11/13/17/04/heart-529607_1280.jpg");
        document.head.appendChild(metaImage);
      } else {
        window.location.href = "/";
      }
  
      AOS.init({ duration: 2000 });
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    const handlePlayButtonClick = () => {
      setIsMusicPlaying(!isMusicPlaying);
  
      const audioElement = document.getElementById("backgroundMusic");
  
      if (!isMusicPlaying && audioElement) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    };
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        {/* <ModalBoda
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          /> */}
  
        <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />
  
        <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />
  
        <Invitados
          NameInvitation={NameInvitation}
          numGuess={numGuess}
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
          datosInvitados={datosInvitados}
        />
  
        <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />
  
        <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />
  
        <Vestimenta peltaColores={peltaColores} imagenesFondo={imagenesFondo} />
  
        <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full sm:py-[5vh] md:pt-[15vh]"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Programa
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              Hemos planeado un día lleno de diversión para disfrutar al máximo.
            </p>
            <div className="grid sm:grid-cols-1 md:grid-cols-1 w-[80%] pt-[10vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                <PiChurchLight className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">
                  CEREMONIA RELIGIOSA
                </p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.ceremoniaReligiosa}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <GiPalmTree className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">BODA SIMBOLICA</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.ceremoniaCivil}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
                <br />
                <FaPhotoVideo className="text-[60px]" />
                <br />
                <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                <br />
                <p className="font-serif text-xl text-center w-[60%]">
                  {datosSeccion6.recepcion}
                </p>
                <br />
                <hr className="bg-gray-700 h-1 w-[60%]" />
              </div>
  
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-right"
              >
                {/* <br />
                  <GiMeshBall className="text-[60px]" />
                  <br />
                  <p className="font-serif text-xl text-center">BAILE</p>
                  <br />
                  <p className="font-serif text-xl text-center w-[60%]">
                    {datosSeccion6.baile}
                  </p>
                  <br />
                  <hr className="bg-gray-700 h-1 w-[60%]" /> */}
              </div>
            </div>
          </div>
        </div>
  
        <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
          }}
          className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Ubicaciones
            </h1>
            <br />
  
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
              <div
                className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                <div
                  style={{
                    backgroundImage:
                    'url("https://mexico.listadodeiglesias.com/wp-content/uploads/2021/05/AF1QipN9y-K1MV8WjqmLOb98cFZhKnoZHDUBiPyqrDisw408-h306-k-no.jpeg")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3734.3425314662327!2d-105.2312458!3d20.614890499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842145452b464c8d%3A0x2b92c65a4fb924fd!2sParroquia%20Nuestra%20Se%C3%B1ora%20del%20Refugio!5e0!3m2!1ses!2smx!4v1710175182476!5m2!1ses!2smx"
                    width="100%"
                    height="50%"
                    className="fixed"
                  ></iframe>
                  <a
                    href={datosSeccion4.ceremoniaUrl}
                    class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    Mapa de la Ceremonia
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              </div>
              <div
                className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
                data-aos="fade-right"
              >
                 <div
                  style={{
                    backgroundImage:
                    'url("https://dynamic-media-cdn.tripadvisor.com/media/photo-o/26/c8/69/1e/krystal-altitude-vallarta.jpg?w=700&h=-1&s=1")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    backgroundPosition: "center center",
                  }}
                  className="min-h-[50%] w-full flex flex-col justify-end"
                >
                </div>
                <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.51894286234!2d-105.2417009!3d20.648456099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8421459f9dd2c143%3A0xfed79d5200a9ce25!2sKrystal%20Grand%20Puerto%20Vallarta!5e0!3m2!1ses!2smx!4v1710175244807!5m2!1ses!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.recepcionUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Recepción
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Hospedaje
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccion7.descripcion}
          </p>

          <div className="flex justify-center  w-[80%] pt-[8vh]">
            <div
              className="flex flex-col h-auto justify-center w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl"
              data-aos="fade-right"
            >
              <h1 className="font-serif text-center sm:text-lg md:text-[23px]">
                {datosSeccion7.hotel1Titulo}
              </h1>
              <br />
              <p className="font-serif text-center sm:text-lg md:text-[23px]  w-[80%]">
                {datosSeccion7.hotel1lugar}
              </p>
              <br />
              <br />
              <a
                href={datosSeccion7.hotel1url}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
  
        <div
          style={{
            // backgroundImage:
            //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
          }}
          className="sm:h-auto md:h-[100vh] flex flex-col sm:py-[5vh] md:pt-[15vh] items-center w-full"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Mesa de regalos
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
              data-aos="flip-up"
            >
              {datosSeccion8.descripcion}
            </p>
  
            <div className="grid sm:grid-cols-1 md:grid-cols-1 md:w-[40%] sm:w-[80%] pt-[8vh]">
              
              <div
                style={{
                  backgroundImage:
                    'url("https://ams3.digitaloceanspaces.com/graffica/2023/03/amazon_logo._CB633267048_.png")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                  backgroundColor: peltaColores.color1,
                }}
                className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
                data-aos="fade-right"
              >
                <a
                  href={datosSeccion8.linkAmazon}
                  class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Ver mesa!
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
  
        <Confirmacion
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
      </div>
    );
}
