import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaErizbethYVictor = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#ebebeb",
    color2: "#000000",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEycQUgw1CR_videoplayback-1-.m4a",
    textoConfirmacion: " nuestra boda",
    esposo: "Victor",
    esposa: "Erizbeth",
    fechaDeBoda: "26 OCTUBRE, 2024",
    imgPortada: "https://i.postimg.cc/VNBkWZzv/IMG-8036.jpg",
    titulo: "NUESTRA BODA",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "'Amor Es Solo Una Palabra,Hasta Que Alguien LlegaPara Darle Sentido.'~Paulo Coelho. ",
    fechaNewDate: "October 26, 2024 14:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/fyqtD8Yd/IMG-8034.jpg",
    fotoDerecha: "https://i.postimg.cc/DyJfMw7t/IMG-8029.jpg",
    marcoEnable: true,
    imgFondo:
      "https://i.postimg.cc/1RLR6hZs/Aesthetic-Watercolor-Background-Gray-White-Pastel-Inspirational-Quote-Instagram-Post.png",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion: "",
    titulo1: "Padres de la Novia",
    madre: "Ma Dolores Martínez Hernández ",
    padre: "Alejandro Tejeda Gutiérrez  ",
    titulo2: "Padres del Novio",
    madrina: "Constanza Ramírez Sánchez ",
    padrino: "Marco Polo Pérez Loredo ",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo:
      "https://i.postimg.cc/1RLR6hZs/Aesthetic-Watercolor-Background-Gray-White-Pastel-Inspirational-Quote-Instagram-Post.png",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 26 de Octubre de 2024.",
    ceremoniaHora: "2:15 p.m.",
    ceremoniaNombreLugar: "Jardín Cortes",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/QZCWmKWnbfGw6nPV8?g_st=ic",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 26 de Octubre de 2024.",
    recepcionHora: "2:00 p.m.",
    recepcionNombreLugar: "Jardín Cortes ",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/QZCWmKWnbfGw6nPV8?g_st=ic",
    imgFondo: "",
    textColor: "#ffff",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color Blanco, Crema o Beige.",
    urlVestimentaMujer: "https://i.postimg.cc/xTmb0S8x/Dise-o-sin-t-tulo-2.png",
    urlVestimentaHombre:
      "https://i.postimg.cc/sgRGx0ZV/Dise-o-sin-t-tulo-1.png",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "El regalo más valioso para nosotros es su presencia, en caso de desear hacernos un regalo, les presentamos las siguientes opciones:\\n\\nTendremos una caja de sobres el día del evento, por si deseas hacernos un regalo en efectivo \\n\\nProporcionaremos una CLABE interbancaria, por si deseas hacer una transferencia electrónica.\\nBBVA\\nDeni Erizbeth Tejeda Martinez\\n0121 8001 5741 123291",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1:
      "https://i.postimg.cc/sxRH3HJY/Estado-Whats-App-M-todos-de-Pago-Lindo-Rosa-Pastel-2.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso:
      "Esta noche queremos que disfrutes, por eso nuestro evento es para adultos. \\n\\nNo niños",
    numeroConfirmacion: "+525561073150",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "#fff",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/Ssxk7yZ1/IMG-8028.jpg",
    "https://i.postimg.cc/DyJfMw7t/IMG-8029.jpg",
    "https://i.postimg.cc/P51XdVpy/IMG-8030.jpg",
    "https://i.postimg.cc/0QXxVLFk/IMG-8031.jpg",
    "https://i.postimg.cc/mZ62jHWY/IMG-8032.jpg",
    "https://i.postimg.cc/vBYHq7mz/IMG-8033.jpg",
    "https://i.postimg.cc/T1h2GRg5/IMG-8034.jpg",
    "https://i.postimg.cc/C1cMWZBV/IMG-8035.jpg",
    "https://i.postimg.cc/VNBkWZzv/IMG-8036.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datosSeccionPortada={datosSeccionPotadaBoda}
      />
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionCeremonia.textColor || "",
          }}
          className="sm:h-auto md:h-auto flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
            <div
              className={`grid sm:grid-cols-1 md:grid-cols-${
                datosSeccionCeremonia.dobleEnable ? "1" : "2"
              } w-[80%] pt-[10vh]`}
            >
              {datosSeccionCeremonia.recepcionEnable ? (
                <div
                  className="flex flex-col justify-center items-center sm:pt-[5vh] md:pt-0"
                  data-aos="fade-right"
                >
                  <FaChampagneGlasses className="text-[80px]" />
                  <br />
                  <p className="font-serif text-2xl">Recepción</p>
                  <br />
                  <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.recepcionFecha
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.recepcionHora
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center ">
                    {datosSeccionCeremonia.recepcionNombreLugar
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.recepcionLugarMap
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  {datosSeccionCeremonia.recepcionUrl ? (
                    <a
                      href={datosSeccionCeremonia.recepcionUrl}
                      class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
                    >
                      Ver mapa!
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                      <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                        Explore!
                      </span>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {datosSeccionCeremonia.dobleEnable ? (
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <div className="flex">
                    <PiChurchLight className="text-[80px]" />
                    <FaChampagneGlasses className="text-[80px]" />
                  </div>
                  <br />
                  <p className="font-serif text-center text-2xl">
                    Ceremonia y Recepción
                  </p>
                  <br />
                  <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionCeremonia.ceremoniaFecha
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaHora
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaNombreLugar
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.ceremoniaLugarMap
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  {datosSeccionCeremonia.ceremoniaUrl ? (
                    <a
                      href={datosSeccionCeremonia.ceremoniaUrl}
                      class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                    >
                      Ver mapa!
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                      <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                        Explore!
                      </span>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {datosSeccionCeremonia.ceremoniaEnable ? (
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <PiChurchLight className="text-[80px]" />
                  <br />
                  <p className="font-serif text-2xl">Ceremonia</p>
                  <br />
                  <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionCeremonia.ceremoniaFecha
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaHora
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center">
                    {datosSeccionCeremonia.ceremoniaNombreLugar
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                    {datosSeccionCeremonia.ceremoniaLugarMap
                      .split("\\n")
                      .map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  <br />
                  {datosSeccionCeremonia.ceremoniaUrl ? (
                    <a
                      href={datosSeccionCeremonia.ceremoniaUrl}
                      class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                    >
                      Ver mapa!
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                      <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                      <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                        Explore!
                      </span>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
