import React, { useEffect, useState } from 'react'

export const Vestimenta = ({peltaColores,imagenesFondo}) => {

    const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <div
        style={{
          backgroundImage:imagenesFondo.fondoVestimenta,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS ? 'local' : 'fixed',
          
          color:peltaColores.textColor ? peltaColores.textColor : '' 
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
             {imagenesFondo.vestimenta ? imagenesFondo.vestimenta :"FORMAL"}
          </p>
          <br />
          {
            peltaColores.messageVestimenta ? (
              <p className='text-center pt-[5vh] font-semibold text-xl w-[80%]'>{peltaColores.messageVestimenta} </p>
            ) : 
            ''
          }
          
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-left"
            >
              <img
                src={imagenesFondo.urlVestimentaMujer || "https://i.postimg.cc/X74pg0Wh/Dise-o-sin-t-tulo-18.png"}
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-2xl">Mujeres</p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-right"
            >
              <img
                src={imagenesFondo.urlVestimentaHombre || "https://i.postimg.cc/hPW9WwBb/Dise-o-sin-t-tulo-17.png"}
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-2xl">Hombres</p>
            </div>
          </div>
        </div>
      </div>
  )
}
