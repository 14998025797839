import React from "react";
import emailjs from "emailjs-com";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Input, Label } from "reactstrap";

export const FormularioEventoPlata = () => {
    const initialValues = {
        color1: "#e7e3e3",
        color2: "#f8f2f2",
        D1esposo: "",
        D1NombreCumple: "",
        D1fechaDeBoda: "",
        D1numeroQuinceañera: "",
        D1numeroNovio: "",
        D1numeroWiddingAdmin: "",
        D1compradorInv: "",
        D2descipcion:
          "Te invito a mi gran cumpleaños, es un día muy especial para mí y espero contar con tu presencia.",
        D3QuinceañeraMadre: "",
        D3QuinceañeraPadre: "",
        D3QuinceañeraMadrina: "",
        D3QuinceañeraPadrino: "",
        D4ceremoniaFecha: "",
        D4ceremoniaNombreLugar: "",
        D4ceremoniaUrl: "",
        D4recepcionFecha: "",
        D4recepcionNombreLugar: "",
        D4recepcionUrl: "",
        // D5ceremonia: "",
        // D5ceremoniaCivil: "",
        // D5cena: "",
        // D5recepcion: "",
        // D5coctel: "",
        // D5baile: "",
        D7hotel1lugar: "",
        D7hotel1url: "",
        D7hotel2lugar: "",
        D7hotel2url: "",
        D8linkRegalo1: "",
        D8linkRegalo2: "",
        D8linkRegalo3: "",
        D8linkRegalo4: "",
        Detalles: "",
      };
    
      const onSubmit = (values) => {
        console.log("Datos del formulario:", values);
        // Puedes agregar lógica para enviar los datos a través de un servicio, como emailjs.
        const message = `Formulario para Evento Hola, soy ${values.D1compradorInv} y estos son mis datos:
        color1: "${values.color1}",
        color2: "${values.color2}",
        Nombre cumpleañero: "${values.D1NombreCumple}",
        Fecha y hora del evento: "${values.D1fechaDeBoda}",
        Mensaje especial para los Invitados: "${values.D2descipcion}",
        Numero Novia: "${values.D1numeroQuinceañera}",
        Numero Novio: "${values.D1numeroNovio}",
        Fecha y hora del evento: "${values.D4ceremoniaFecha}",
        Lugar del evento: "${values.D4ceremoniaNombreLugar}",
        URL del lugar del evento: "${values.D4ceremoniaUrl}",
        Detalles: "${values.Detalles}"`;
    
        const templateParams = {
          to_name: 'digital.rsvp.invitation@gmail.com', // Opcional: puedes especificar el destinatario
          message_html: message
        };
      
        emailjs.send('service_5jkl7vp', 'template_zgfkvfiadw', templateParams, 'Gcy3d_JxmbsgftDiI')
          .then((response) => {
            console.log('Correo electrónico enviado con éxito:', response);
            window.location.href = '/';
            alert('Correo electrónico enviado con éxito')
          }, (error) => {
            console.error('Error al enviar correo electrónico:', error);
            alert('Error al enviar correo electrónico')
          });
      };

      
  return (
    <div className="w-full bg-black p-10"
    style={{
      backgroundImage: `url(https://digitalrsvp.mx/api/Images/1cb8085a-ac04-46ca-b7aa-b1a20dd68a30)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundPosition: "center center",
      backgroundAttachment: 'fixed' ,
    }}
    >
      <Formik
      initialValues={initialValues}
      onSubmit={(e) => {
        onSubmit(e);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleReset,
        errors,
        touched,
      }) => (
        <Form
          className="modal-form-content  flex flex-col items-center md:p-24 sm:p-10 "
          onSubmit={handleSubmit}
        >
          <br />
          <h2 className="title-form text-center">
            FORMULARIO PARA EVENTO PLATA
          </h2>
          <br />
          <br />
          <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <div className="col-span-1 m-3">
              <Label>Nombre del Comprador</Label>
              <Input
                type="text"
                maxLength="50"
                name="D1compradorInv"
                onChange={handleChange}
                value={values.D1compradorInv}
                className={errors.D1compradorInv ? "input-error" : ""}
              />
              {touched.D1compradorInv && errors.D1compradorInv && (
                <div className="color-error">{errors.D1compradorInv}</div>
              )}
            </div>
            {/* <div className="col-span-1 m-3">
              <Label>Numero Wedding planner</Label>
              <Input
                type="text"
                maxLength="50"
                name="D1numeroWiddingAdmin"
                onChange={handleChange}
                value={values.D1numeroWiddingAdmin}
                className={errors.D1numeroWiddingAdmin ? "input-error" : ""}
              />
              {touched.D1numeroWiddingAdmin && errors.D1numeroWiddingAdmin && (
                <div className="color-error">{errors.D1numeroWiddingAdmin}</div>
              )}
            </div> */}
            <div className="col-span-1 m-3">
              <Label>Nombre del cumpleañero o cumpleañera</Label>
              <Input
                type="text"
                maxLength="50"
                name="D1NombreCumple"
                onChange={handleChange}
                value={values.D1NombreCumple}
                className={errors.D1NombreCumple ? "input-error" : ""}
              />
              {touched.D1NombreCumple && errors.D1NombreCumple && (
                <div className="color-error">{errors.D1NombreCumple}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Fecha y hora del evento</Label>
              <Input
                type="datetime-local"
                name="D1fechaDeBoda"
                onChange={handleChange}
                value={values.D1fechaDeBoda}
                className={errors.D1fechaDeBoda ? "input-error" : ""}
              />
              {touched.D1fechaDeBoda && errors.D1fechaDeBoda && (
                <div className="color-error">{errors.D1fechaDeBoda}</div>
              )}
            </div>
            {/* ... Puedes seguir agregando más campos aquí ... */}
            <div className="col-span-1 m-3">
              <Label>Mensaje especial para los Invitados</Label>
              <br />
              <textarea
                type="text"
                name="D2descipcion"
                onChange={handleChange}
                value={values.D2descipcion}
                className={`w-[90%] h-28 ${
                  errors.D2descipcion ? "input-error" : ""
                }`}
              />
              {touched.D2descipcion && errors.D2descipcion && (
                <div className="color-error">{errors.D2descipcion}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Número de quien recibirá la confirmación</Label>
              <Input
                type="text"
                name="D1numeroQuinceañera"
                onChange={handleChange}
                value={values.D1numeroQuinceañera}
                className={errors.D1numeroQuinceañera ? "input-error" : ""}
              />
              {touched.D1numeroQuinceañera && errors.D1numeroQuinceañera && (
                <div className="color-error">{errors.D1numeroQuinceañera}</div>
              )}
            </div>
            <h2 className="text-center mt-4 md:col-span-2 title-form">
              COLORES DE FONDO
            </h2>
            <div className="col-span-1 m-3">
              <Label>Color 1</Label>
              <Input
                type="color"
                name="color1"
                onChange={handleChange}
                value={values.color1}
                className={errors.color1 ? "input-error" : ""}
              />
              {touched.color1 && errors.color1 && (
                <div className="color-error">{errors.color1}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Color 2</Label>
              <Input
                type="color"
                name="color2"
                onChange={handleChange}
                value={values.color2}
                className={errors.color2 ? "input-error" : ""}
              />
              {touched.color2 && errors.color2 && (
                <div className="color-error">{errors.color2}</div>
              )}
            </div>
            {/* <div className="col-span-1 m-3">
              <Label>Numero Novio</Label>
              <Input
                type="text"
                name="D1numeroNovio"
                onChange={handleChange}
                value={values.D1numeroNovio}
                className={errors.D1numeroNovio ? "input-error" : ""}
              />
              {touched.D1numeroNovio && errors.D1numeroNovio && (
                <div className="color-error">{errors.D1numeroNovio}</div>
              )}
            </div> */}
          </div>

          {/* <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <div className="col-span-1 m-3">
              <Label>Nombre de la madre de la Quinceañera</Label>
              <Input
                type="text"
                name="D3QuinceañeraMadre"
                onChange={handleChange}
                value={values.D3QuinceañeraMadre}
                className={errors.D3QuinceañeraMadre ? "input-error" : ""}
              />
              {touched.D3QuinceañeraMadre && errors.D3QuinceañeraMadre && (
                <div className="color-error">{errors.D3QuinceañeraMadre}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Nombre del padre de la Quinceañera</Label>
              <Input
                type="text"
                name="D3QuinceañeraPadre"
                onChange={handleChange}
                value={values.D3QuinceañeraPadre}
                className={errors.D3QuinceañeraPadre ? "input-error" : ""}
              />
              {touched.D3QuinceañeraPadre && errors.D3QuinceañeraPadre && (
                <div className="color-error">{errors.D3QuinceañeraPadre}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Nombre de la Madrina</Label>
              <Input
                type="text"
                name="D3QuinceañeraMadrina"
                onChange={handleChange}
                value={values.D3QuinceañeraMadrina}
                className={errors.D3QuinceañeraMadrina ? "input-error" : ""}
              />
              {touched.D3QuinceañeraMadrina && errors.D3QuinceañeraMadrina && (
                <div className="color-error">{errors.D3QuinceañeraMadrina}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Nombre del Padrino</Label>
              <Input
                type="text"
                name="D3QuinceañeraPadrino"
                onChange={handleChange}
                value={values.D3QuinceañeraPadrino}
                className={errors.D3QuinceañeraPadrino ? "input-error" : ""}
              />
              {touched.D3QuinceañeraPadrino && errors.D3QuinceañeraPadrino && (
                <div className="color-error">{errors.D3QuinceañeraPadrino}</div>
              )}
            </div>
          </div> */}
          <br />
          <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <h2 className="text-center md:col-span-2 title-form">EVENTO </h2>
            {/* <div className="col-span-1 m-3">
              <Label>Fecha y hora del evento</Label>
              <Input
                type="datetime-local"
                name="D4ceremoniaFecha"
                onChange={handleChange}
                value={values.D4ceremoniaFecha}
                className={errors.D4ceremoniaFecha ? "input-error" : ""}
              />
              {touched.D4ceremoniaFecha && errors.D4ceremoniaFecha && (
                <div className="color-error">{errors.D4ceremoniaFecha}</div>
              )}
            </div> */}
            <div className="col-span-1 m-3">
              <Label>Lugar del evento</Label>
              <Input
                type="text"
                name="D4ceremoniaNombreLugar"
                onChange={handleChange}
                value={values.D4ceremoniaNombreLugar}
                className={errors.D4ceremoniaNombreLugar ? "input-error" : ""}
              />
              {touched.D4ceremoniaNombreLugar &&
                errors.D4ceremoniaNombreLugar && (
                  <div className="color-error">
                    {errors.D4ceremoniaNombreLugar}
                  </div>
                )}
            </div>
            <div className="col-span-1 m-3">
              <Label>URL del lugar del evento</Label>
              <Input
                type="text"
                name="D4ceremoniaUrl"
                onChange={handleChange}
                value={values.D4ceremoniaUrl}
                className={errors.D4ceremoniaUrl ? "input-error" : ""}
              />
              {touched.D4ceremoniaUrl && errors.D4ceremoniaUrl && (
                <div className="color-error">{errors.D4ceremoniaUrl}</div>
              )}
            </div>
          </div>
          <br />
          {/* <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <br />
            <h2 className="text-center md:col-span-2 title-form">RECEPCION </h2>
            <div className="col-span-1 m-3">
              <Label>Fecha y hora de la Recepción</Label>
              <Input
                type="datetime-local"
                name="D4recepcionFecha"
                onChange={handleChange}
                value={values.D4recepcionFecha}
                className={errors.D4recepcionFecha ? "input-error" : ""}
              />
              {touched.D4recepcionFecha && errors.D4recepcionFecha && (
                <div className="color-error">{errors.D4recepcionFecha}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Lugar de la Recepción</Label>
              <Input
                type="text"
                name="D4recepcionNombreLugar"
                onChange={handleChange}
                value={values.D4recepcionNombreLugar}
                className={errors.D4recepcionNombreLugar ? "input-error" : ""}
              />
              {touched.D4recepcionNombreLugar &&
                errors.D4recepcionNombreLugar && (
                  <div className="color-error">
                    {errors.D4recepcionNombreLugar}
                  </div>
                )}
            </div>
            <div className="col-span-1 m-3">
              <Label>URL del lugar de la Recepción</Label>
              <Input
                type="text"
                name="D4recepcionUrl"
                onChange={handleChange}
                value={values.D4recepcionUrl}
                className={errors.D4recepcionUrl ? "input-error" : ""}
              />
              {touched.D4recepcionUrl && errors.D4recepcionUrl && (
                <div className="color-error">{errors.D4recepcionUrl}</div>
              )}
            </div>
          </div> */}
          {/* <br />
          <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <br />
            <h2 className="text-center md:col-span-2 title-form">
              HOSPEDAJE 1
            </h2>
            <div className="col-span-1 m-3">
              <Label>Direccion del Hotel</Label>
              <Input
                type="text"
                name="D7hotel1lugar"
                onChange={handleChange}
                value={values.D7hotel1lugar}
                className={errors.D7hotel1lugar ? "input-error" : ""}
              />
              {touched.D7hotel1lugar && errors.D7hotel1lugar && (
                <div className="color-error">{errors.D7hotel1lugar}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>URL de la Direccion del Hotel</Label>
              <Input
                type="text"
                name="D7hotel1url"
                onChange={handleChange}
                value={values.D7hotel1url}
                className={errors.D7hotel1url ? "input-error" : ""}
              />
              {touched.D7hotel1url && errors.D7hotel1url && (
                <div className="color-error">{errors.D7hotel1url}</div>
              )}
            </div>
          </div>
          <br />
          <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <br />
            <h2 className="text-center md:col-span-2 title-form">
              HOSPEDAJE 2
            </h2>
            <div className="col-span-1 m-3">
              <Label>Direccion del Hotel</Label>
              <Input
                type="text"
                name="D7hotel2lugar"
                onChange={handleChange}
                value={values.D7hotel2lugar}
                className={errors.D7hotel2lugar ? "input-error" : ""}
              />
              {touched.D7hotel2lugar && errors.D7hotel2lugar && (
                <div className="color-error">{errors.D7hotel2lugar}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>URL de la Direccion del Hotel</Label>
              <Input
                type="text"
                name="D7hotel2url"
                onChange={handleChange}
                value={values.D7hotel2url}
                className={errors.D7hotel2url ? "input-error" : ""}
              />
              {touched.D7hotel2url && errors.D7hotel2url && (
                <div className="color-error">{errors.D7hotel2url}</div>
              )}
            </div>
          </div>

          <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <br />
            <h2 className="text-center md:col-span-2 title-form">
              MESA DE REGALOS
            </h2>
            <div className="col-span-1 m-3">
              <Label>Link de la Mesa de Regalos 1</Label>
              <Input
                type="text"
                name="D8linkRegalo1"
                onChange={handleChange}
                value={values.D8linkRegalo1}
                className={errors.D8linkRegalo1 ? "input-error" : ""}
              />
              {touched.D8linkRegalo1 && errors.D8linkRegalo1 && (
                <div className="color-error">{errors.D8linkRegalo1}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Link de la Mesa de Regalos 2</Label>
              <Input
                type="text"
                name="D8linkRegalo2"
                onChange={handleChange}
                value={values.D8linkRegalo2}
                className={errors.D8linkRegalo2 ? "input-error" : ""}
              />
              {touched.D8linkRegalo2 && errors.D8linkRegalo2 && (
                <div className="color-error">{errors.D8linkRegalo2}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Link de la Mesa de Regalos 2</Label>
              <Input
                type="text"
                name="D8linkRegalo3"
                onChange={handleChange}
                value={values.D8linkRegalo3}
                className={errors.D8linkRegalo3 ? "input-error" : ""}
              />
              {touched.D8linkRegalo3 && errors.D8linkRegalo3 && (
                <div className="color-error">{errors.D8linkRegalo3}</div>
              )}
            </div>
            <div className="col-span-1 m-3">
              <Label>Link de la Mesa de Regalos 2</Label>
              <Input
                type="text"
                name="D8linkRegalo4"
                onChange={handleChange}
                value={values.D8linkRegalo4}
                className={errors.D8linkRegalo4 ? "input-error" : ""}
              />
              {touched.D8linkRegalo4 && errors.D8linkRegalo4 && (
                <div className="color-error">{errors.D8linkRegalo4}</div>
              )}
            </div>
          </div>
          <br /> */}
          <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
            <br />
            <h2 className="text-center md:col-span-2 title-form">
              COMENTARIOS O DETALLES
            </h2>
            <div className="md:col-span-2 m-3">
              <Label>Detalles</Label>
              <br />
              <textarea
                type="text"
                name="Detalles"
                onChange={handleChange}
                value={values.Detalles}
                className={`w-full h-[20vh] ${
                  errors.Detalles ? "input-error" : ""
                }`}
              />
              {touched.Detalles && errors.Detalles && (
                <div className="color-error">{errors.Detalles}</div>
              )}
            </div>
          </div>
          <br />
          <br />
          <div className="mb-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Enviar Formulario
            </button>
          </div>
        </Form>
      )}
    </Formik>
    </div>
  )
}
