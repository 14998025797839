import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import YouTube from "react-youtube";
import ImagenComponent from "../../../components/ComponentsBoda/ImagenComponent";

/**
 * Valida si una cadena de texto es un enlace de YouTube.
 * @param {string} input - La cadena de texto a validar.
 * @returns {boolean} - `true` si la cadena es un enlace de YouTube, `false` en caso contrario.
 */
const isYouTubeLink = (input) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return youtubeRegex.test(input);
};

/**
 * Extrae el ID del video de un enlace de YouTube.
 * @param {string} url - El enlace de YouTube.
 * @returns {string|null} - El ID del video de YouTube o `null` si no se puede extraer.
 */
const extractYouTubeID = (url) => {
  const idRegex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(idRegex);
  return match ? match[1] : null;
};

export const GraduacionColegioTabasco = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isYouTube, setIsYouTube] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cancionYoutube, setCancionYoutube] = useState("");
  const playerRef = useRef(null);

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_06/NFtuD3lD7AR_y2mate.com---Wiz-Khalifa-See-You-Again-ft-Charlie-Puth-Official-Instrumental.mp3",
    imgPortada: "https://i.postimg.cc/RVy0BYsY/Invitacion-graduacion-azul-y-dorado-elegante.png",
    nombre: "",
    nombre2: "",
    fechaDeBoda: "",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Graduamos!",
    descipcion:
      "Queridos amigos y familiares. \\n\\nHoy culmina una etapa importante en nuestra vida. Este logro es gracias al esfuerzo, dedicación y apoyo de todos ustedes.\\n\\nGracias a nuestros padres, maestros y amigos por su amor y confianza. Sin ustedes, esto no sería posible.\\n\\nAunque despedimos estos años de estudio, damos la bienvenida a nuevas oportunidades. \\n\\nSi nos ven volar, recuerden que ustedes nos dieron las alas. ¡Gracias por ser parte de esta aventura!",
    fechaNewDate: "July 06, 2024 20:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/MKLnprnV/Dise-o-sin-t-tulo-50.png",
    fotoDerecha: "https://i.postimg.cc/qB5Js6pd/Dise-o-sin-t-tulo-52.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Magdalena Vázquez Prieto",
    padre: "Adolfo Lira González",
    madrina: "Rosio Garibay Cervantes",
    padrino: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionFoto = {
    imgFondo:
      "https://i.postimg.cc/0Nmhf6VH/Dise-o-sin-t-tulo-26.png",
    sizeFoto: "",
    imgContenidos: [
      "https://i.postimg.cc/7hVxCDKr/Comparte-con-nosotros-todas-tus-fotograf-as-del-evento-usando-el-siguiente-hashtag-en-todas-tus-pu.png",
      "https://i.postimg.cc/DyJjy50B/Comparte-con-nosotros-todas-tus-fotograf-as-del-evento-usando-el-siguiente-hashtag-en-todas-tus-pu.png",
      "https://i.postimg.cc/HWPvgFYr/Comparte-con-nosotros-todas-tus-fotograf-as-del-evento-usando-el-siguiente-hashtag-en-todas-tus-pu.png"
      ]
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 23 de Diciembre de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia del Divino Salvador",
    ceremoniaLugarMap:
      "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
    ceremoniaUrl:
      "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 6 de Julio de 2024",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Gran Salón del Hotel Hyatt Regency Villahermosa",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/orZjUCRW5ioJi7qV8",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "ETIQUETA",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Los Esperamos",
    aviso: "",
    numeroConfirmacion: "",
    textoConfirmacion: "",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/s1dtw26b/IMG-2029.jpg	",
    "https://i.postimg.cc/gcXjSBtK/IMG-2030.jpg	",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = false;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const reproYoutube = (vali) => {
    if (vali) {
      setIsYouTube(true);
      const youtubeID = extractYouTubeID(datosSeccionPortada.cancion);
      console.log("ID del video de YouTube:", youtubeID);
      setCancionYoutube(youtubeID);
    }
  };

  const onReady = (event) => {
    playerRef.current = event.target;
    playerRef.current.playVideo(); // Autoplay the video
  };

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    const validacion = isYouTubeLink(datosSeccionPortada.cancion);
    reproYoutube(validacion);
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <div
        style={{
          backgroundImage: `url(${datosSeccionPortada.imgPortada})`,
          backgroundRepeat: "no-repeat",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col md:bg-full sm:bg-cover justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          {isYouTube ? (
            <YouTube
              videoId={cancionYoutube}
              opts={{
                height: "1", // Cambia a un tamaño pequeño pero visible
                width: "1",
                playerVars: {
                  autoplay: 1,
                  controls: 0,
                },
              }}
              onReady={onReady}
            />
          ) : (
            <audio
              id="backgroundMusic"
              style={{ display: "none" }}
              autoPlay
              loop
              muted={!isMusicPlaying}
            >
              <source src={datosSeccionPortada.cancion} type="audio/mpeg" />
              {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
            </audio>
          )}

          {datosSeccionPortada.cancion ? (
            <div className="w-full flex justify-end h-full items-start fixed ">
              {isYouTube ? (
                <button
                  onClick={() => {
                    if (playerRef.current) {
                      if (isPlaying) {
                        playerRef.current.pauseVideo();
                      } else {
                        playerRef.current.playVideo();
                      }
                      setIsPlaying(!isPlaying);
                    }
                  }}
                  className="text-3xl rounded animate-bounce bg-black p-1"
                >
                  {isPlaying ? "🔊" : "🔇"}
                </button>
              ) : (
                <button
                  onClick={handlePlayButtonClick}
                  className="text-3xl rounded animate-bounce bg-black p-1"
                >
                  {isMusicPlaying ? "🔊" : "🔇"}
                </button>
              )}
            </div>
          ) : (
            ""
          )}

          <br />
          <br />
          <h1
            className={`font-serif sm:text-4xl md:text-[132px] ${
              datosSeccionPortada.textColor
                ? datosSeccionPortada.textColor
                : "text-white"
            } text-center flex`}
            data-aos="flip-up"
          >
            {datosSeccionPortada.nombre.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <h1
            className={`font-serif sm:text-4xl md:text-[132px] ${
              datosSeccionPortada.textColor
                ? datosSeccionPortada.textColor
                : "text-white"
            } text-center flex`}
            data-aos="flip-up"
          >
            
          </h1>
          <br />
          <br />
          <p
            className={`p-3 rounded-lg font-serif text-2xl text-center ${
              datosSeccionPortada.textColor
                ? datosSeccionPortada.textColor
                : "text-white"
            }`}
            data-aos="fade-up"
          >
            {datosSeccionPortada.titulo
              ? `- ${datosSeccionPortada.titulo} -`
              : ""}{" "}
            <br />
          </p>
          <br />
        </div>
      </div>
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      <ImagenComponent datosSeccionFoto={datosSeccionFoto} />

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
