import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaMarianaYMartin = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_05/NEcbm5Cw17p_%C3%8Dndigo-LetraLyrics.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Martín",
    esposa: "Pamela",
    fechaDeBoda: "03 AGOSTO, 2024",
    imgPortada: "https://i.postimg.cc/8sQQ3J3W/1000010740.jpg",
    titulo: "NUESTRA BODA Y REVELACIÓN ",
  };

  const datosSeccionContador = {
    titulo: "¡Nos casamos!",
    descipcion:"Después de 5 años caminando juntos, hemos decidido unir nuestras vidas en matrimonio y queremos que seas cómplice de esta unión… \\n\\n¡Celebra el amor con nosotros y acompáñanos a descubrir el Género de nuestro bebé!",
    fechaNewDate: "August 03, 2024 14:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/TYgpPrTz/1000011346.jpg",
    fotoDerecha: "https://i.postimg.cc/VkFvqmr4/1000006898.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra boda.",
    titulo1: "Padres de la Novia",
    madre: "Veronica Gaytán Otero.",
    padre: "Marco Antonio Pérez Jiménez.",
    titulo2: "Padres del Novio",
    madrina: "María Isabel Vargas Flores.",
    padrino: "Benito Hernández Visuet.",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 03 de Agosto de 2024.",
    ceremoniaHora: "2:00 p.m.",
    ceremoniaNombreLugar: "Jardín las Lunas",
    ceremoniaLugarMap:
      "Sur 27 273, Leyes de Reforma 1ra Secc, Iztapalapa, 09310 Ciudad de México, CDMX",
    ceremoniaUrl:
      "https://maps.app.goo.gl/MMNMReJSTTJmudPr7",
    recepcionEnable: false,
    recepcionFecha: "Sábado, 03 de Agosto de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "El Herradero Jardín y Eventos",
    recepcionLugarMap:
      "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
    recepcionUrl:
      "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa comida",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 2,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/wedding/registry/30DP7GD1Q9WGY",
    imgLink1:
      "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51433283",
    imgLink2:
      "https://i.postimg.cc/gJndtPbL/EFD44-F23-84-B7-4-BD5-A554-CB4737-F8185-F.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm:"Nuestro Día Especial",
    aviso: "¡EQUIPO NIÑO VS EQUIPO NIÑA! \\n\\nPara la Revelación de Género de nuestro baby te pedimos ser partícipe. \\n\\nSi tu eres \\nEQUIPO NIÑO TRAE TOALLITAS HUMEDAS \\n\\nSi eres \\nEQUIPO NIÑA TRAE UN PAQUETE DE PAÑALES \\n (UNISEX)",
    numeroConfirmacion: "4491516931",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "https://i.postimg.cc/x1P6HXqf/Invitaci-n-Revelaci-n-de-Genero-Moderno-Azul-y-Rosa.png",
    textColor: "",
    numeroNovia: "+525535108528",
    numeroNovio: "+525580198816",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/tTcH3mNB/1000011152.jpg",
    "https://i.postimg.cc/W1Vtqh3h/1000006889.jpg",
    "https://i.postimg.cc/056zLTF2/1000010631.jpg",
    "https://i.postimg.cc/rpFmg35T/1000010720.jpg",
    "https://i.postimg.cc/yNQNGjTZ/1000011096.jpg ",
    "https://i.postimg.cc/qB9N0mHc/1000015428.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "VERONICA GAYTAN OTERO", guess: "4", id: 1 },
    { name: "MAGDALENA GAYTAN OTERO", guess: "1", id: 2 },
    { name: "IMELDA GAYTAN OTERO", guess: "1", id: 3 },
    { name: "SEBASTIAN PEREZ GAYTAN", guess: "3", id: 4 },
    { name: "ESTEBAN MADRIGAL GAYTAN", guess: "2", id: 5 },
    { name: "LOURDES GAYTAN OTERO", guess: "3", id: 6 },
    { name: "MARIA ELENA LOPEZ MUÑOZ", guess: "3", id: 7 },
    { name: "ESTEBAN NAVARRO GAYTAN", guess: "3", id: 8 },
    { name: "MARGARITA NAVARRO GAYTAN", guess: "3", id: 9 },
    { name: "MARCO ANTONIO PEREZ JIMENEZ", guess: "1", id: 10 },
    { name: "IRVING PEREZ", guess: "4", id: 11 },
    { name: "PAOLA JIMENEZ", guess: "2", id: 12 },
    { name: "DANIEL PEREZ", guess: "2", id: 13 },
    { name: "FERNANDO JIMENEZ FIGUEROA", guess: "2", id: 14 },
    { name: "LETICIA ROJAS VAZQUEZ", guess: "3", id: 15 },
    { name: "MARTIN GARCIA ROJAS", guess: "5", id: 16 },
    { name: "ELISA JIMENEZ FIGUEROA", guess: "4", id: 17 },
    { name: "ADRIANA JAIME", guess: "4", id: 18 },
    { name: "GABRIEL PEREZ", guess: "2", id: 19 },
    { name: "AMERICA RAMON UCEDA", guess: "2", id: 20 },
    { name: "RICARDO AZUCENO", guess: "1", id: 21 },
    { name: "GABRIELA ROMERO", guess: "3", id: 22 },
    { name: "DAVID LECHUGA", guess: "3", id: 23 },
    { name: "Claudia Alanis", guess: "2", id: 24 },
    { name: "María Isabel Vargas Flores", guess: "1", id: 25 },
    { name: "Belem Hernández Vargas", guess: "3", id: 26 },
    { name: "Salustia Vargas Flores", guess: "4", id: 27 },
    { name: "Elena Vargas Flores", guess: "2", id: 28 },
    { name: "Elia Vargas Flores", guess: "2", id: 29 },
    { name: "Omar Martínez Vargas", guess: "3", id: 30 },
    { name: "Mariana Martínez Vargas", guess: "2", id: 31 },
    { name: "Severiano Vargas Flores", guess: "4", id: 32 },
    { name: "Rafael Vargas Castillo", guess: "3", id: 33 },
    { name: "Elsa Torres Vargas", guess: "2", id: 34 },
    { name: "Victor Jiménez Rangel", guess: "3", id: 35 },
    { name: "Amira Abundis Moreno", guess: "2", id: 36 },
    { name: "Graciela Pérez", guess: "2", id: 37 },
    { name: "Yazmin", guess: "1", id: 38 },
    { name: "Jonathan Omar German Pérez", guess: "2", id: 39 },
    { name: "Leandro Beriain", guess: "1", id: 40 },
    { name: "Benito Hernández Visuet", guess: "1", id: 41 },
    { name: "Eduardo García", guess: "3", id: 42 },
];




  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la Boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <div
        style={{
          backgroundImage:`url(${datosSeccionProgramaBoda.imgFondo || ""})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          
          color: datosSeccionProgramaBoda.textColor
            ? datosSeccionProgramaBoda.textColor
            : "",
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              {datosSeccionProgramaBoda.ceremoniaReligiosa ? (
                <>
                  <PiChurchLight className="text-[60px]" />
                  <br />
                  <p className="font-serif text-xl text-center">
                    CEREMONIA RELIGIOSA
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center w-[60%]">
                    {datosSeccionProgramaBoda.ceremoniaReligiosa}
                  </p>
                  <br />
                  <hr className="bg-gray-700 h-1 w-[60%]" />
                  <br />
                </>
              ) : (
                ""
              )}
  
              {datosSeccionProgramaBoda.recepcion ? (
                <>
                  <FaPhotoVideo className="text-[60px]" />
                  <br />
                  <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                  <br />
                  <p className="font-serif text-xl text-center w-[60%]">
                    {datosSeccionProgramaBoda.recepcion}
                  </p>
                  <br />
                  <hr className="bg-gray-700 h-1 w-[60%]" />
                  <br />
                </>
              ) : (
                ""
              )}
  
              {datosSeccionProgramaBoda.civil ? (
                <>
                  <GiBigDiamondRing className="text-[60px]" />
                  <br />
                  <p className="font-serif text-xl text-center">
                    CEREMONIA CIVIL
                  </p>
                  <br />
                  <p className="font-serif text-xl text-center w-[60%]">
                    {datosSeccionProgramaBoda.civil}
                  </p>
                  <br />
                  <hr className="bg-gray-700 h-1 w-[60%]" />
                  <br />
                </>
              ) : (
                ""
              )}
            </div>
  
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              {datosSeccionProgramaBoda.cena ?(
                  <>
                   <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">COMIDA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccionProgramaBoda.cena}
              </p>
              <br />
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
                  </>
              ):("")}
             
             {datosSeccionProgramaBoda.coctel ? (
              <>
               <FaChampagneGlasses className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CÓCTEL</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccionProgramaBoda.coctel}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              </>
             ):("")}
             
             {datosSeccionProgramaBoda.baile ? (
              <>
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccionProgramaBoda.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              </>
             ):("")}
              
            </div>
          </div>
        </div>
      </div>
      ) : (
        ""
      )}

      {vestimenta ? (
        <div
        style={{
          backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: "fixed",
  
          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccionVestimenta.vestimenta
              ? datosSeccionVestimenta.vestimenta
              : "FORMAL"}
          </p>
          <br />
          {datosSeccionVestimenta.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
              {datosSeccionVestimenta.messageVestimenta
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          ) : (
            ""
          )}
  
        </div>
      </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
        peltaColores={peltaColores}
        images={images}
        messageWhats={messageWhats}
        datosSeccion1={datosSeccionConfirmacion}
      />
      ) : (
        ""
      )}
    </div>
  );
};
