import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaRegTrashAlt, FaWhatsapp, FaEye, FaPlus, FaPaperPlane } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const InvitadosTablaEn = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idEvento = searchParams.get("id");

  const [invitados, setInvitados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nuevoInvitado, setNuevoInvitado] = useState({
    Nombre: "",
    Accesos: "",
  });
  const [dataEvento, setDataEvento] = useState({});
  const [totalInvitados, setTotalInvitados] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const fetchInvitados = async () => {
    try {
      setLoading(true);
      const responseEvento = await axios.get(`/api/Eventos/${idEvento}`);
      setDataEvento(responseEvento.data.tipoEvento == "Boda"? "wedding":"event");
      const response = await axios.get(`/api/Invitado/evento/${idEvento}`);
      setInvitados(response.data);
      setTotalInvitados(response.data.length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching guests:", error);
    }
  };

  const handleAgregarInvitado = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    try {
      const guestId = parseInt(idEvento);
      const invitado = {
        nombre: nuevoInvitado.Nombre,
        accesos: nuevoInvitado.Accesos,
        idEvento: guestId,
        link: "",
        mensajeInvitado: "",
      };
      const response = await axios.post("/api/Invitado", invitado);
      setInvitados([...invitados, response.data]);
      setTotalInvitados(totalInvitados + 1);
      setNuevoInvitado({ Nombre: "", Accesos: "" });
      setShowForm(false);
      setIsAdding(false);
    } catch (error) {
      console.error("Error adding guest:", error);
      alert(error.response.data);
      setIsAdding(false);
    }
  };

  const deleteInvitado = async (invitadoId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this guest?"
    );
    if (confirmed) {
      try {
        await axios.delete(`/api/Invitado/${invitadoId.idInvitado}`);
        setInvitados(
          invitados.filter((inv) => inv.idInvitado !== invitadoId.idInvitado)
        );
        setTotalInvitados(totalInvitados - 1);
      } catch (error) {
        console.error("Error deleting guest:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoInvitado({ ...nuevoInvitado, [name]: value });
  };

  useEffect(() => {
    fetchInvitados();
  }, []);

  return (
    <div className="container mx-auto px-4 h-auto py-8 bg-gradient-to-r from-blue-100 to-purple-100 min-h-screen">
      <div className="flex justify-center mt-4 mb-6">
      <FaPaperPlane className="text-blue-500 text-5xl md:text-6xl" />
      </div>
      <h1 className="text-2xl md:text-4xl font-bold text-center mb-8 text-gray-800">
        Guest Management
      </h1>

      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-8">
        <p className="text-lg md:text-2xl font-semibold text-center text-gray-800 mb-4">
          Total Guests: {totalInvitados}
        </p>
        <button
          onClick={() => setShowForm(!showForm)}
          className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-2 md:py-3 px-4 rounded-md hover:from-blue-600 hover:to-purple-700 transition duration-300 flex items-center justify-center"
        >
          <FaPlus className="mr-2" /> {showForm ? "Cancel" : "Add New Guest"}
        </button>
      </div>

      {showForm && (
        <form
          onSubmit={handleAgregarInvitado}
          className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-8"
        >
          <div className="mb-4">
            <label
              htmlFor="nombre"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Guest Name
            </label>
            <input
              type="text"
              id="nombre"
              name="Nombre"
              value={nuevoInvitado.Nombre}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              placeholder="Enter name"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="accesos"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Number of Passes
            </label>
            <input
              type="number"
              id="accesos"
              name="Accesos"
              value={nuevoInvitado.Accesos}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              placeholder="Enter number of passes"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-green-500 to-green-600 text-white font-bold py-2 md:py-3 px-4 rounded-md hover:from-green-600 hover:to-green-700 transition duration-300"
            disabled={isAdding}
          >
            {isAdding ? "Adding..." : "Confirm New Guest"}
          </button>
        </form>
      )}

      <div className="bg-white w-full rounded-lg shadow-md overflow-hidden">
        {loading ? (
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-gray-600">Loading guests...</p>
          </div>
        ) : (
          <div className="overflow-x-auto" style={{ maxHeight: "400px" }}>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-4 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-4 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Accesses
                  </th>
                  <th className="px-4 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {invitados.slice().reverse().map((invitado) => (
                  <tr
                    key={invitado.idInvitado}
                    className="hover:bg-gray-50 transition duration-300"
                  >
                    <td className="px-4 py-2 md:px-6 md:py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {invitado.nombre}
                    </td>
                    <td className="px-4 py-2 md:px-6 md:py-4 whitespace-nowrap text-sm text-gray-500">
                      {invitado.accesos}
                    </td>
                    <td className="px-4 py-2 md:px-6 md:py-4 whitespace-nowrap text-sm font-medium">
                      <div className="flex space-x-3">
                        <a
                          href={`https://api.whatsapp.com/send?text=${window.location.origin}/${dataEvento}-auto-diamond?idInvitado=${invitado.idInvitado}`}
                          className="text-green-600 hover:text-green-900 transition duration-300"
                          title="Share via WhatsApp"
                        >
                          <FaWhatsapp className="h-5 w-5 md:h-6 md:w-6" />
                        </a>
                        <a
                          href={`/${dataEvento}-auto-diamond?idInvitado=${invitado.idInvitado}`}
                          className="text-blue-600 hover:text-blue-900 transition duration-300"
                          title="View Invitation"
                        >
                          <FaEye className="h-5 w-5 md:h-6 md:w-6" />
                        </a>
                        <button
                          onClick={() => deleteInvitado(invitado)}
                          className="text-red-600 hover:text-red-900 transition duration-300"
                          title="Delete Guest"
                        >
                          <FaRegTrashAlt className="h-5 w-5 md:h-6 md:w-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <br /><br />
    </div>
  );
};

export default InvitadosTablaEn;
