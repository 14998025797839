import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "tailwindcss/tailwind.css";
import Aos from "aos";

const ModalXvCard = ({
  isOpen,
  setIsOpen,
  datosSeccionPortada,
  peltaColores,
  ingles
}) => {
  const [flapOpen, setFlapOpen] = useState(true);

  useEffect(() => {
    // Inicializa AOS
    Aos.init({ duration: 2000 });

    console.log("si pasa xv", isOpen);
  }, []);

  const toggleFlap = () => {
    setFlapOpen(!flapOpen);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <CSSTransition in={isOpen} timeout={2000} unmountOnExit appear>
        <div className="modal-co container">
          <div
            className={`envelope-wrapper ${flapOpen ? "flap" : ""}`}
            onClick={toggleFlap}
          >
            <div className="envelope">
              <div
                className="letter"
                style={{
                  backgroundImage: `url(${
                    datosSeccionPortada.imgPortada || ""
                  })`,
                }}
              >
                <div className="text">
                  <button className="ver-invitacion-btn" onClick={closeModal}>
                  {ingles?"Open Invitation":"Ver Invitación"}
                  </button>
                </div>
              </div>
            </div>
            <div className="heart"></div>
            <p className="texto-corazon">
              {datosSeccionPortada.nombre.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              )) || ""}
            </p>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ModalXvCard;
