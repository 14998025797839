import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaLeslieYRicardo = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#c2c2c2",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_05/NEfxvH6ivoR_y2mate.com---Perfect-Saxophone-Instrumental.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Ricardo",
    esposa: "Leslie",
    fechaDeBoda: "02 MAYO, 2025",
    imgPortada:
      "https://i.postimg.cc/bJSrTFr1/Whats-App-Image-2024-05-02-at-8-46-46-AM.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 10 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    fechaNewDate: "May 02, 2025 11:30:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/fTXWhZtx/20-BBE563-49-CD-45-CC-BC7-F-2-BAEFA492545.png",
    fotoDerecha: "https://i.postimg.cc/mgybtwSk/1125-B56-E-87-FE-452-F-96-B9-0-F33-A8-F28225.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Magdalena Vázquez Prieto",
    padre: "Adolfo Lira González",
    titulo2: "Padrinos del Novio",
    madrina: "Rosio Garibay Cervantes",
    padrino: "Juan Carlos Lira Vázquez",
    titulo3: "Padrinos del Novio2",
    madrina2: "Rosio Garibay Cervantes",
    padrino2: "Juan Carlos Lira Vázquez",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Viernes, 02 de Mayo de 2025.",
    ceremoniaHora: "11:30 a.m.",
    ceremoniaNombreLugar: "Mayan Secret",
    ceremoniaLugarMap:
      "Km 9, 5 Carretera, 77900 Chetumal, Q.R.",
    ceremoniaUrl:
      "https://maps.app.goo.gl/mjn11waSuGebrSQb8",
    recepcionEnable: false,
    recepcionFecha: "",
    recepcionHora: "",
    recepcionNombreLugar: "",
    recepcionLugarMap:
      "",
    recepcionUrl:
      "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "4491516931",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://cdn0.bodas.com.mx/article-vendor/3443/3_2/960/jpg/analois-photoshoot-0055_5_193443-164815363528699.jpeg",
    "https://elolivar.es/olivar-content/uploads/2023/04/oficiante-de-bodas.png",
    "https://cnnespanol.cnn.com/wp-content/uploads/2023/06/wedding-trends-restricted-e1685615285359.jpeg?quality=100&strip=info",
    "https://images.ecestaticos.com/LceL6lnXGvDCBsnDEjupizBTGuA=/0x0:2272x1515/1200x900/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2Fe52%2Fd19%2Fcfe%2Fe52d19cfe44da0f12a62bc916ac069b1.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalCard isOpen={isOpen} setIsOpen={setIsOpen} datosSeccionPortada={datosSeccionPotadaBoda}/>
      <PortadaBodaComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
