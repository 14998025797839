import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/Pachelbel.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const BodaSofiYDaniel = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear"
  };

  const datosSeccion1 = {
    textoConfirmacion:' nuestra boda',
    esposo: "Daniel",
    esposa: "Sofía",
    fechaDeBoda: "27 JUL, 2024",
    fechaNewDate: "July 27, 2024 18:00:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/LsD8Mm2C/20240404-103927.jpg",
    numeroNovia: "",
    numeroNovio: "",
    novios:true,
    tituloConfirmacion:(
      <div>
Agradecemos de antemano su apreciable compañía.
      </div>
    )
  };
 
  const peltaColores = {
    color1: "#e8e3e3",
    color2: "#f7ebeb",
  };

  const datosSeccion2 = {
    titulo:'¡Nos Casamos!',
    descipcion:(
      <div>
        Y dijo: Por tanto, el hombre dejará a su padre y a su madre, y se unirá a su esposa, y los dos serán una sola carne. Así que, no son ya más dos, sino una sola carne; por tanto, lo que Dios ha unido, no lo separe el hombre. <br />
        Mateo 19:4–6
      </div>
    ),
    fechaNewDate: "July 27, 2024 19:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/nzLcFNLH/20240404-105344.jpg",
    fotoDerecha: "https://i.postimg.cc/76QPfXCS/20240404-105313.jpg",
  };

  const datosInvitados ={
    titulo:'¡Has sido invitado a la boda de Sofía y Daniel!',
    entradasText:'disfrutar nuestro dia especial'
  }

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda:'Padres de la novia',
    tituloDerecha:'Padres del novio',
    esposaMadre: "Guadalupe Gómez",
    esposaPadre: "Carlos Vargas Núñez",
    esposoMadre: "Lourdes Cahuich Chi",
    esposoPadre: "Santos Cruz Aparicio",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 27 de Julio de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "I.N.P. Eben-Ezer, Región 99 Manzana 55 Lote 9-1",
    ceremoniaLugarMap: "C. 141 Cancún, Quintana Roo",
    ceremoniaUrl: "https://maps.app.goo.gl/HrDrJn7C6dgk9YbE9",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo ={
    fondoInvitados:'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
  }

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());


  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/g0m25xFL/20240404-103714.jpg",
    "https://i.postimg.cc/Z5kR8fwc/20240404-105128.jpg",
    "https://i.postimg.cc/LsD8Mm2C/20240404-103927.jpg",
"https://i.postimg.cc/nzLcFNLH/20240404-105344.jpg",
"https://i.postimg.cc/76QPfXCS/20240404-105313.jpg",
    // Add more image URLs as needed
  ];
  

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {

    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda
      datosSeccion1 ={datosSeccion1}
      audioFondo = {audioFondo}
      />

      <Contador
        peltaColores = {peltaColores}
        datosSeccion2 = {datosSeccion2}
      />

      <Padres
        peltaColores={peltaColores}
        datosSeccion3={datosSeccion3}
      />
      
      <div
        style={{
          backgroundImage:datosSeccion4.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color:datosSeccion4.textColor || "",
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <div className="flex">
              <PiChurchLight className="text-[80px] p-1" />
              <FaChampagneGlasses className="text-[80px] p-1" />
              </div>
              <br />
              <p className="font-serif text-2xl">Ceremonia y Recepción</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion4.ceremoniaFecha}
              </p>
              <p className="font-serif text-xl text-center">
                {datosSeccion4.ceremoniaHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccion4.ceremoniaNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.ceremoniaLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.ceremoniaUrl}
                class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>


      <div
    style={{
      display: "flex",
      backgroundPosition: "center center",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor:peltaColores.color2,
      color:datosSeccion1.textColor ? datosSeccion1.textColor : '' 
    }}
    className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
  >
    <h1
      className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
      data-aos="flip-up"
    >
      {datosSeccion1.tituloConfirmacion || (<div>
        Espero puedan confirmarme su asistencia. <br/>
Agradezco de antemano su apreciable compañía.
      </div>)}
    </h1>
    <br />
    <p className='sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]'>{datosSeccion1.aviso || ''}</p>
    <br />
    <br/>
    <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
      {images?.map((imageUrl, index) => (
        <div  data-aos="fade-down-right" className="rolImg" key={index}>
          <img
            src={imageUrl}
            alt={`Slide ${index + 1}`}
            className="w-auto rounded-xl max-h-[60vh]"
          />
        </div>
      ))}
    </Slider>
    <br />
  </div>

    </div>
  );
};
