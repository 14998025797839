import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/La Adictiva  El Amor De Mi Vida LyricVideo.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const BodaNavaYJulio = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: " nuestra boda",
    esposo: "Julio",
    esposa: "Eva",
    fechaDeBoda: "21 JUNIO, 2024",
    imgPortada:
      "https://i.postimg.cc/65sx3zGc/Whats-App-Image-2024-04-24-at-11-23-01-AM-1.jpg",
    numeroNovia: "+523326159952",
    numeroNovio: "+523315594010",
    novios: true,
    tituloConfirmacion:(
        <div>
            Espero puedan confirmarnos su asistencia. <br /> Agradecemos de antemano su apreciable compañia.
        </div>
    )
  };

  const peltaColores = {
    color1: "#ffffff",
    color2: "#709cfd",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Cuando Dios bendice tu matrimonio se crea una hermosa historia de amor que nunca tiene final bienvenidos aún nuevo capitulo de la historia que es y será la más bella de las casualidades.",
    fechaNewDate: "June 21, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/BZPG4Rtt/Whats-App-Image-2024-04-24-at-11-23-01-AM-3.jpg",
    fotoDerecha:
      "https://i.postimg.cc/sxWFmGb5/Whats-App-Image-2024-04-24-at-11-23-01-AM-2.jpg ",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Evangelina Ruiz",
    esposaPadre: "Rogelio nava",
    esposoMadre: "Lourdes Mendoza",
    esposoPadre: "Ramón Alemán",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Viernes, 21 de Junio de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia sagrado corazón",
    ceremoniaLugarMap: "Calle José rosas #256 col buenos Aires",
    ceremoniaUrl: "https://maps.app.goo.gl/EMxtnEtgC5hUxfNv8",
    recepcionFecha: "Viernes, 21 de Junio de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Terraza los tres toritos",
    recepcionLugarMap: "Anillo periférico #1340 paraísos el coli Zapopan jal",
    recepcionUrl: "https://maps.app.goo.gl/8RuoQinjVc23H2vJ9",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://cdn0.bodas.com.mx/article-vendor/8162/3_2/960/jpg/18032018-dsc6343_5_158162.jpeg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/65sx3zGc/Whats-App-Image-2024-04-24-at-11-23-01-AM-1.jpg",
    "https://i.postimg.cc/BZPG4Rtt/Whats-App-Image-2024-04-24-at-11-23-01-AM-3.jpg",
    "https://i.postimg.cc/sxWFmGb5/Whats-App-Image-2024-04-24-at-11-23-01-AM-2.jpg ",
    "https://i.postimg.cc/Yq1Kmg6H/Whats-App-Image-2024-04-24-at-11-23-01-AM.jpg",
    "https://i.postimg.cc/bJWc19Vz/Whats-App-Image-2024-04-24-at-11-23-01-AM-4.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <div
        style={{
          backgroundImage:datosSeccion3.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color:datosSeccion3.textColor ? datosSeccion3.textColor : '' 
        }}
        className="sm:h-[130vh] md:h-[100vh] p-2 flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccion3.titulo}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccion3.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl">{datosSeccion3.tituloIzquierda}</p>
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposaMadre}
              </p>
              {
                datosSeccion3.esposaPadre?(
                  
              <p className="font-serif text-center text-xl">&</p>
                ):
                ''
              }
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposaPadre}
              </p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl">{datosSeccion3.tituloDerecha}</p>
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposoMadre}
              </p>
              {
                datosSeccion3.esposoPadre?(
                  
              <p className="font-serif text-center text-xl">&</p>
                ):
                ''
              }
              <p className="font-serif text-center text-xl">
                {datosSeccion3.esposoPadre}
              </p>
            </div>
          </div>

          
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl">Padrinos de Velación</p>
              <br />
              <p className="font-serif text-center text-xl">
              María Márquez
              </p>
              {
                datosSeccion3.esposaPadre?(
                  
              <p className="font-serif text-center text-xl">&</p>
                ):
                ''
              }
              <p className="font-serif text-center text-xl">
              José fuentes
              </p>
            </div>
          </div>
        </div>
      </div>

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <ConfirmacionGeneral
        peltaColores={peltaColores}
        images={images}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
