import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses, FaWhatsapp } from "react-icons/fa6";
import { GiBigDiamondRing, GiTicket } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./assets/ThousandYears.mp3";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { PortadaBoda } from "../../../components/PortadaBoda";
import Slider from "react-slick";
import "./BodaGloriaYEfrain.css";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";

export const BodaGloriaYEfrain = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    titulo: "NUESTRA BODA",
    textoConfirmacion: "a nuestra boda",
    esposo: "Efraín",
    esposa: "Isabel",
    fechaDeBoda: "10 AGOSTO, 2024",
    fechaNewDate: "August 10, 2024 19:00:00 GMT+00:00",
    imgPortada:
      "https://i.postimg.cc/5jcFdRpM/WhatsApp_Image_2024-04-07_at_7.59.33_AM.jpg",
    numeroNovia: "+524925833626",
    numeroNovio: "+524921751196",
    novios: true,
    textColorConfirm: "",
    imgFondo:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
      aviso:""
  };

  const peltaColores = {
    color1: "#EDE1CF",
    color2: "#EDE1CF",
    messageVestimenta:
      "Adoramos tanto a los niños que no deseamos que se desvelen ni que pasen frio durante la celebración, por eso dejalos durmiendo plácidamente en casa.",
    textColor: "#000",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Las coincidencias no son solo coincidir , son todo un plan de Dios para que las cosas pasen. Dios nos permitió cruzar nuestros caminos y juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que seas partícipe de esta unión.",
    fechaNewDate: "August 10, 2024 19:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/T3JQ0VYP/WhatsApp_Image_2024-04-07_at_8.00.39_AM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/br41YJyW/Whats-App-Image-2024-04-07-at-7-59-32-AM.jpg",
    imgFondo:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Isabel y Efrain!",
    entradasText: "disfrutar nuestro dia especial",
    colorFondoName: "#e3cca9",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Madre de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Ludivina Nava López ",
    esposaPadre: "",
    esposoMadre: "Claudia Pinedo Aguayo",
    esposoPadre: "Efraín Ortiz Domínguez",
    imgFondo:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 10 de Agosto de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Parroquia de Nuestra Señora Fátima",
    ceremoniaLugarMap: "De Fátima 110, Sierra de Alica, 98050 Zacatecas, Zac.",
    ceremoniaUrl: "https://maps.app.goo.gl/oE6mRUGrT2wo8Qe66",
    recepcionFecha: "Sábado, 10 de Agosto de 2024.",
    recepcionHora: "8:30 p.m.",
    recepcionNombreLugar: "Hotel Baruk Teleférico, Salón Mabarak",
    recepcionLugarMap:
      "P. O Paseo Díaz Ordaz 602, Zacatecas Centro, 98000 Zacatecas, Zac.",
    recepcionUrl: "https://maps.app.goo.gl/hmRX3KZZLG3AqGnm7",
    textColor: "",
    imgFondo:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos esta sugerencia de hospedaje.",
    hotel1Titulo: "Hotel Baruk Teleférico y Mina",
    hotel1lugar:
      "Diaz Ordaz , número 602, colonia centro, Zacatecas, Zacatecas",
    hotel1url: "https://maps.app.goo.gl/TCSxGvaZM3MCWsHT6?g_st=ic",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion: (
      <div>
        Nuestra historia está llena de sueños por cumplir, si quisieran hacernos
        un regalo para estar más cerca de alcanzarlos, ¡se los agradecemos
        muchísimo! <br /> <br />
        ¡Lluvia de sobres! <br />
        Dinero en efectivo dentro de un sobre el día del evento.
        <br />
        <br />
        ¡Regalo sorpresa! <br />
        Estamos seguros que nos encantará.
      </div>
    ),

    linkAmazon: "https://www.sears.com.mx/Mesa-de-Regalos/134238/invitacion",
    linkLiverpool:
      "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51419312",
    imgFondo:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const imagenesFondo = {
    fondoInvitados:
      'url("https://i.postimg.cc/V6xjmvLT/WhatsApp_Image_2024-04-07_at_7.59.33_AM_(1).jpg"',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [pases, setPases] = useState(0);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/T3JQ0VYP/Whats-App-Image-2024-04-07-at-8-00-39-AM.jpg",
    "https://i.postimg.cc/HW82RDsR/Whats-App-Image-2024-04-07-at-8-00-40-AM.jpg",
    "https://i.postimg.cc/y6RmnmLz/Whats-App-Image-2024-04-07-at-8-07-46-AM.jpg",
    "https://i.postimg.cc/VLyRcGJ6/Whats-App-Image-2024-04-07-at-8-00-40-AM-3.jpg",
    // Add more image URLs as needed
  ];

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/V6xjmvLT/Whats-App-Image-2024-04-07-at-7-59-33-AM-1.jpg",
    titulo: `¡Has sido invitado a la boda de ${datosSeccion1.esposa} y ${datosSeccion1.esposo}!`,
    entradasText: "disfrutar nuestra gran boda",
    colorFondoName: "#9e1d00c7",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Ludivina Nava López", guess: "2", id: 2 },
    { name: "Lupita González", guess: "3", id: 3 },
    { name: "Alicia Ahumada", guess: "1", id: 4 },
    { name: "Alondra Ahumada", guess: "1", id: 5 },
    { name: "Alma González", guess: "2", id: 6 },
    { name: "Linsey Martínez", guess: "1", id: 7 },
    { name: "Hanna González", guess: "2", id: 8 },
    { name: "Cristina Campos", guess: "2", id: 9 },
    { name: "Diana Fernandez", guess: "1", id: 10 },
    { name: "Sayda Escareño", guess: "2", id: 11 },
    { name: "Patricia Landeros", guess: "2", id: 12 },
    { name: "Mariana Rivera", guess: "2", id: 13 },
    { name: "Alexia Silva", guess: "2", id: 14 },
    { name: "Marlen Flores", guess: "2", id: 15 },
    { name: "Patricia Chavez", guess: "1", id: 16 },
    { name: "Valeria Calderón", guess: "2", id: 17 },
    { name: "Yesica Bernal", guess: "2", id: 18 },
    { name: "Alejandro Zamorano", guess: "2", id: 19 },
    { name: "Dulce Esparza", guess: "2", id: 20 },
    { name: "Gerardo Monreal", guess: "2", id: 21 },
    { name: "José Esparza", guess: "2", id: 22 },
    { name: "josehany Rodarte", guess: "2", id: 23 },
    { name: "Ana Rivera", guess: "2", id: 24 },
    { name: "Evelyn Simental", guess: "1", id: 25 },
    { name: "Yesenia Esparza", guess: "3", id: 26 },
    { name: "Cesar Venegas y Sra.", guess: "2", id: 27 },
    { name: "Héctor Torres y Sra.", guess: "2", id: 28 },
    { name: "Jesús Ávila", guess: "1", id: 29 },
    { name: "Susana Flores", guess: "1", id: 30 },
    { name: "Rocio Soriano", guess: "1", id: 31 },
    { name: "Erik Martinez", guess: "1", id: 32 },
    { name: "Liliana Muñoz", guess: "1", id: 33 },
    { name: "Lorena Marquez", guess: "2", id: 34 },
    { name: "Karla Gaytan", guess: "2", id: 35 },
    { name: "Jimi Jenneskens", guess: "1", id: 36 },
    { name: "Paola Barretero", guess: "2", id: 37 },
    { name: "Norma Rendon", guess: "2", id: 38 },
    { name: "Yetlanetzi Quiroz", guess: "2", id: 39 },
    { name: "Jeysson Rodriguez", guess: "1", id: 40 },
    { name: "Joel Camiruaga", guess: "1", id: 41 },
    { name: "Viridiana de la Rosa", guess: "2", id: 42 },
    { name: "Daniel Ulloa", guess: "1", id: 43 },
    { name: "Vanesa juarez", guess: "1", id: 44 },
    { name: "Fatima Encina", guess: "2", id: 45 },
    { name: "Claudia Pinedo", guess: "1", id: 46 },
    { name: "Efrain Ortiz", guess: "1", id: 47 },
    { name: "Carlos Manuel Ortiz", guess: "1", id: 48 },
    { name: "Ramiro Pinedo Aguayo", guess: "1", id: 49 },
    { name: "Ana Lilia Martinez", guess: "1", id: 50 },
    { name: "Karen Pinedo Martinez", guess: "3", id: 51 },
    { name: "Michelle Pinedo Martinez", guess: "2", id: 52 },
    { name: "Alejandro Pinedo Martinez", guess: "1", id: 53 },
    { name: "Ramiro Pinedo Martinez", guess: "2", id: 54 },
    { name: "Silvia Pinedo Aguayo", guess: "1", id: 55 },
    { name: "Mario Martinez Pinedo", guess: "3", id: 56 },
    { name: "Ma. Del Carmen Pinedo Aguayo", guess: "1", id: 57 },
    { name: "Jorge De Luna", guess: "4", id: 58 },
    { name: "Manuel Pinedo Aguayo", guess: "2", id: 59 },
    { name: "Jorge Pinedo Aguayo", guess: "2", id: 60 },
    { name: "Jorge Antonio Pinedo Salinas", guess: "1", id: 61 },
    { name: "Manuel Alejandro Pinedo Salinas", guess: "1", id: 62 },
    { name: "Dulce Pinedo López", guess: "2", id: 63 },
    { name: "Carlos Arturo Martinez Pinedo y Sra.", guess: "3", id: 64 },
    { name: "German Rdz Ortega y Sra.", guess: "2", id: 65 },
    { name: "Gerardo Dominguez y Sra.", guess: "2", id: 66 },
    { name: "Juan Jesus de Luna y Sra.", guess: "2", id: 67 },
    { name: "Ernesto Campos y Prometida", guess: "2", id: 68 },
    { name: "César Herrera y Prometida", guess: "2", id: 69 },
    { name: "Alejandro Arellano Delgado", guess: "2", id: 70 },
    { name: "Jhovany Ramos Chávez", guess: "2", id: 71 },
    { name: "Iswari Castillo y Esposa", guess: "2", id: 72 },
    { name: "Manuel Ruíz Guzman", guess: "2", id: 73 },
    { name: "Omar Loera y Sra.", guess: "2", id: 74 },
    { name: "Brayan Cardona y Esposa", guess: "2", id: 75 },
    { name: "Pedro Cardona", guess: "2", id: 76 },
    { name: "Griselda Ramirez Rodriguez", guess: "2", id: 77 },
    { name: "Cuahutémoc Rodriguez y Esposa", guess: "2", id: 78 },
    { name: "Rafael Cota Ortiz", guess: "1", id: 79 },
    { name: "Elvia Ortiz Dominguez", guess: "2", id: 80 },
    { name: "Raymundo Moreno Romero", guess: "2", id: 81 },
    { name: "José Antonio García Ortiz", guess: "2", id: 82 },
    { name: "Alma Ortiz Dominguez", guess: "1", id: 83 },
    { name: "Beatriz Monroy", guess: "1", id: 84 },
    { name: "Fabiola Monroy Ortiz y Esposo", guess: "2", id: 85 },
    { name: "Cristina Monroy Ortiz", guess: "5", id: 86 },
    { name: "Cecilia Ortiz Dominguez", guess: "2", id: 87 },
    { name: "Gabriela Ramirez Ortiz", guess: "1", id: 88 },
    { name: "Francisco Ramirez Ortiz", guess: "1", id: 89 },
    { name: "Ruth Ramirez Ortiz", guess: "1", id: 90 },
    { name: "Claudia Ortiz Dominguez", guess: "5", id: 91 },
    { name: "Rosalina Ortiz Dominguez", guess: "2", id: 92 },
    { name: "Jaime Ortiz Dominguez", guess: "4", id: 93 },
    { name: "Graciela Barrón", guess: "2", id: 94 },
    { name: "Edna Aguilera", guess: "2", id: 95 },
    { name: "Marcos Francisco Hernández", guess: "1", id: 96 },
    { name: "Guadalupe Berumen", guess: "1", id: 97 },
    { name: "Verónica Medina y Esposo", guess: "2", id: 98 },
    { name: "Javier Juarez y Esposa", guess: "2", id: 99 },
    { name: "Delia Salazar", guess: "2", id: 100 },
    { name: "Lorena Candelas y Esposo", guess: "2", id: 101 },
    { name: "Lilia Valdez y Esposo", guess: "2", id: 102 },
    { name: "Ana Bañuelos", guess: "1", id: 103 },
    { name: "Sofia Bañuelos", guess: "1", id: 104 },
    { name: "Carlos Aguayo y Esposa", guess: "2", id: 105 },
    { name: "Erika y Esposo", guess: "2", id: 106 },
    { name: "Silvia Ugalde Murillo", guess: "1", id: 107 },
    { name: "Lupita Esparza", guess: "2", id: 108 },
    { name: "Gabriela Solís", guess: "2", id: 109 },
    { name: "Malynali Esquivel", guess: "2", id: 110 },
    { name: "Ernesto Silva", guess: "2", id: 111 },
    { name: "Andrés Farias", guess: "2", id: 112 },
  ];


  

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    console.log(idInvitado, numPases);
    setPases(numPases);
    const guestId = parseInt(idInvitado);

    const findName = names.find((e) => e.id === guestId);
    // const mapUrl = names.map((e)=>
    //   `https://digitalrsvp.mx/boda-diamante-gloria&efrain?id=${e.id}`
    // )

  

    if (findName) {
      console.log(findName.name);

      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      console.log("Nombre no encontrado");
      window.location.href = "/";
    }

    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [isIOS, setIsIOS] = useState(false);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);
  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <div
        style={{
          backgroundImage: `url(${datosSeccion1.imgPortada})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgb(0 0 0 / 22%)" }}
        >
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={audioFondo} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
          <button
            onClick={handlePlayButtonClick}
            className=" px-4 text-3xl py-2 rounded animate-bounce"
          >
            {isMusicPlaying ? "🔊" : "🔇"}
          </button>
          <br />
          <br />
          <h1
            className="font-serif sm:text-4xl md:text-[132px] text-white text-center flex boda-cursive"
            data-aos="flip-up"
          >
            {datosSeccion1.esposa}{" "}
            <span>
              <img
                src="https://i.postimg.cc/6Qqt7rrB/Dise-o-sin-t-tulo-15.png"
                className="sm:h-[62px] md:h-[138px]"
                alt=""
              />
            </span>{" "}
            {datosSeccion1.esposo}
          </h1>
          <br />
          <br />
          <p
            className="text-white boda-cursive p-3 rounded-lg font-serif text-2xl text-center"
            data-aos="fade-up"
          >
            - {datosSeccion1.titulo || "CEREMONIA RELIGIOSA"} - <br />-{" "}
            {datosSeccion1.fechaDeBoda} -
          </p>
          <br />
        </div>
      </div>

      <div
        style={{
          backgroundImage: datosSeccion2.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color: datosSeccion2.textColor ? datosSeccion2.textColor : "",
        }}
        className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col pt-[10vh] w-full items-center">
          <h1
            className="font-serif boda-cursive sm:text-[52px] md:text-[92px] "
            data-aos="flip-up"
          >
            {datosSeccion2.titulo}
          </h1>
          <br />
          <p
            className=" text-center boda-cursive pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion2.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage: `url(${datosSeccion2.fotoIzquierda})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "45vh", // Ajusta la altura del contenedor
                display: "flex",
                backgroundPosition: "center center",
                width: "90%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded-[30%]"
              data-aos="fade-right"
            ></div>
            <div
              className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
              data-aos="fade-up"
              style={{
                border: "15px solid transparent",
                padding: "15px",
                borderImage:
                  "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')",
                borderImageSlice: "46",
                borderImageWidth: "round",
              }}
            >
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.days}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl boda-cursive">
                  Dias
                </p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.hours}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl boda-cursive">
                  Hrs
                </p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.minutes}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl boda-cursive">
                  Mins
                </p>
              </div>
              <div className="flex flex-col m-2 justify-center items-center">
                <p className="font-serif sm:text-xl md:text-2xl">
                  {timeRemaining.seconds}
                </p>
                <p className="font-serif sm:text-xl md:text-2xl boda-cursive">
                  Segs
                </p>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${datosSeccion2.fotoDerecha})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "45vh", // Ajusta la altura del contenedor
                display: "flex",
                backgroundPosition: "center center",
                width: "90%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded-full sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
              data-aos="fade-left"
            ></div>
          </div>
        </div>
      </div>

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      <div
        style={{
          backgroundImage: datosSeccion3.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color: datosSeccion3.textColor ? datosSeccion3.textColor : "",
        }}
        className="sm:h-auto md:h-[100vh] p-2 flex flex-col items-center py-5 w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px] boda-cursive"
            data-aos="flip-up"
          >
            {datosSeccion3.titulo}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl boda-cursive"
            data-aos="flip-up"
          >
            {datosSeccion3.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl boda-cursive">
                {datosSeccion3.tituloIzquierda}
              </p>
              <br />
              <p className="font-serif text-center text-xl boda-cursive">
                {datosSeccion3.esposaMadre}
              </p>
              {datosSeccion3.esposaPadre ? (
                <p className="font-serif text-center text-xl boda-cursive">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl boda-cursive">
                {datosSeccion3.esposaPadre}
              </p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-left"
            >
              <p className="font-semibold text-center text-2xl boda-cursive">
                {datosSeccion3.tituloDerecha}
              </p>
              <br />
              <p className="font-serif text-center text-xl boda-cursive">
                {datosSeccion3.esposoMadre}
              </p>
              {datosSeccion3.esposoPadre ? (
                <p className="font-serif text-center text-xl boda-cursive">&</p>
              ) : (
                ""
              )}
              <p className="font-serif text-center text-xl boda-cursive">
                {datosSeccion3.esposoPadre}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: datosSeccion4.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: datosSeccion4.textColor || "",
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[80px]" />
              <br />
              <p className="font-serif text-2xl boda-cursive">Ceremonia</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl boda-cursive">
                {datosSeccion4.ceremoniaFecha}
              </p>
              <p className="font-serif text-xl text-center boda-cursive">
                {datosSeccion4.ceremoniaHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                {datosSeccion4.ceremoniaNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl boda-cursive text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.ceremoniaLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.ceremoniaUrl}
                class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black boda-cursive text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>

            <div
              className="flex flex-col justify-center items-center sm:pt-[5vh] md:pt-0"
              data-aos="fade-right"
            >
              <FaChampagneGlasses className="text-[80px]" />
              <br />
              <p className="font-serif text-2xl boda-cursive">Recepción</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                {datosSeccion4.recepcionFecha}
              </p>
              <p className="font-serif text-xl text-center boda-cursive">
                {datosSeccion4.recepcionHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center boda-cursive ">
                {datosSeccion4.recepcionNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center boda-cursive sm:w-[70%] md:w-[80%]">
                {datosSeccion4.recepcionLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.recepcionUrl}
                class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white boda-cursive border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: imagenesFondo.fondoVestimenta,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS ? "local" : "fixed",

          color: peltaColores.textColor ? peltaColores.textColor : "",
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px] boda-cursive"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl boda-cursive"
            data-aos="flip-up"
          >
            {imagenesFondo.vestimenta ? imagenesFondo.vestimenta : "Formal"}
          </p>
          <br />
          {peltaColores.messageVestimenta ? (
            <p className="text-center pt-[5vh] font-semibold text-base w-[80%] boda-cursive">
              {peltaColores.messageVestimenta}{" "}
            </p>
          ) : (
            ""
          )}

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[100%] pt-[5vh]">
            <div
              className="grid grid-cols-2"
              data-aos="fade-left"
            >
              <div className="flex w-1/2 flex-col justify-center items-center">
                <div className="flex">
                  <img
                    src="https://i.postimg.cc/jSbX8QFm/Dise-o-sin-t-tulo-1.png"
                    className="h-[25vh]"
                    alt=""
                  />
                  <img
                    src="https://i.postimg.cc/y603f4C6/Dise-o-sin-t-tulo-3.png"
                    className="h-[25vh]"
                    alt=""
                  />
                </div>
                <br />
              </div>
              <p className="text-center font-semibold p-2 text-base  boda-cursive">
              Mujeres <br /><br />
              Les pedimos amablemente que eviten tonalidades de rosa, beige y blanco en su vestimenta, para mantener una variedad de colores en nuestro entorno.
              </p>
              <br />
            </div>

            <div
              className="grid grid-cols-2"
              data-aos="fade-left"
            >
              <p className="text-center font-semibold p-2 text-base boda-cursive">
              Hombres <br /><br />
              Les recordamos la importancia de vestir de manera formal, lo que refleja nuestro compromiso con la excelencia en nuestra boda.
              </p>
              <div className="flex w-1/2 flex-col justify-center items-center">
                <div className="flex">
                  <img
                    src="https://i.postimg.cc/xCYbKtpT/Dise-o-sin-t-tulo-2.png"
                    className="h-[25vh]"
                    alt=""
                  />
                  <img
                    src="https://i.postimg.cc/nzznbmCG/Dise-o-sin-t-tulo-4.png"
                    className="h-[25vh]"
                    alt=""
                  />
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage:
            'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: "",
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center p-[5vh] w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px] boda-cursive"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl boda-cursive"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%] boda-cursive">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-[#5b3305] opacity-50 h-1 w-[60%]" />
              <br />
              <GiBigDiamondRing className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                CEREMONIA CIVIL
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%] boda-cursive">
                {datosSeccion6.ceremoniaCivil}
              </p>
              <br />
              <hr className="bg-[#5b3305] opacity-50 h-1 w-[60%]" />
              <br />
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                CENA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%] boda-cursive">
                {datosSeccion6.cena}
              </p>
              <br />
              <hr className="bg-[#5b3305] opacity-50 h-1 w-[60%]" />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <br />
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                RECEPCIÓN
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%] boda-cursive">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-[#5b3305] opacity-50 h-1 w-[60%]" />
              <br />
              <FaChampagneGlasses className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                CÓCTEL
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%] boda-cursive">
                {datosSeccion6.coctel}
              </p>
              <br />
              <hr className="bg-[#5b3305] opacity-50 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center boda-cursive">
                BAILE
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%] boda-cursive">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-[#5b3305] opacity-50 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage:
            'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: "",
        }}
        className="sm:h-auto px-3 md:h-[100vh] flex flex-col items-center py-[5vh] w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px] boda-cursive"
            data-aos="flip-up"
          >
            Hospedaje
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl boda-cursive"
            data-aos="flip-up"
          >
            {datosSeccion7.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[8vh]">
            <div></div>
            <div
              className="flex flex-col justify-center w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-auto p-2"
              data-aos="fade-right"
            >
              <h1 className="font-serif boda-cursive text-center sm:text-lg text-black md:text-[23px]">
                {datosSeccion7.hotel1Titulo}
              </h1>
              <br />
              <p className="font-serif boda-cursive text-center text-black sm:text-lg md:text-[23px]  w-[80%]">
                {datosSeccion7.hotel1lugar}
              </p>
              <br />
              <br />
              <a
                href={datosSeccion7.hotel1url}
                class="overflow-hidden boda-cursive relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: datosSeccion8.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col py-[5vh] px-3 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px] boda-cursive"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif boda-cursive text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
              <a
                href={datosSeccion8.linkAmazon}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none boda-cursive rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mesa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              <a
                href={datosSeccion8.linkLiverpool}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md boda-cursive text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mesa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: datosSeccion1.imgFondo || "",
          backgroundRepeat: "repeat",
          backgroundSize: "cover", // or "100% auto"
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
          color: datosSeccion1.textColorConfirm || "",
        }}
        className="sm:h-auto py-5 md:h-[150vh] flex flex-col justify-center items-center w-full"
      >
        <h1
          className="font-serif boda-cursive text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          Mi Dia Especial
        </h1>
        <br />
        <p
          className=" text-center boda-cursive text-white p-[5vh] font-serif text-xl sm:w-[80%] md:w-[50%] rounded-2xl"
          data-aos="flip-up"
          style={{
            background: "rgb(0,0,0)",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0.7596288515406162) 28%, rgba(0,0,0,0.7596288515406162) 68%)",
          }}
        >
          Compartir estos momentos con ustedes los hace inolvidables.
        </p>
        <br />
        <br />
        <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
          {images?.map((imageUrl, index) => (
            <div data-aos="fade-down-right" className="rolImg" key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-auto rounded-xl max-h-[40vh]"
              />
            </div>
          ))}
        </Slider>
        <br />
        <br />
        <div
          data-aos="fade-down-left"
          className="md:w-[50%] sm:w-[80%]  mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
        >
          <h2 className="text-2xl boda-cursive font-bold mb-4 justify-center flex text-black">
            Confirmación de Asistencia {datosSeccion1.textoConfirmacion}{" "}
            <FaWhatsapp className="text-green-600 ml-2" />
          </h2>
          <div className="flex justify-center items-center">
            <div className="w-full text-center bg-green-700 p-2 mt-5 mx-2 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccion1.numeroNovia
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white boda-cursive text-center md:w-[60%] md:text-xl text-auto"
              >
                Confirma tu invitación con la novia 💌
              </a>
            </div>
            <div className="w-full text-center bg-green-700 p-2 mt-5 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccion1.numeroNovio
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white boda-cursive text-center md:w-[60%] md:text-xl text-auto"
              >
                Confirma tu invitación con el novio 💌
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
