import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./TeEsperaba.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { Programa } from "../../../components/Programa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { PiChurchLight } from "react-icons/pi";
import Slider from "react-slick";

export const XvAlexaMedina = () => {
  const [isOpen, setIsOpen] = useState(false);

  const datosSeccion1 = {
    textoConfirmacion: "a los XV",
    nombre: "Alexa Medina",
    fechaDeBoda: "04 MAYO, 2024",
    fechaNewDate: "May 04, 2024 21:00:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/VNtTPkTj/IMG-20240417-WA0009.jpg",
    numeroNovia: "+528661313158",
    numeroNovio: "",
  };

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccion2 = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
    fechaNewDate: "May 04, 2024 21:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/rFh7Sbxt/FB-IMG-1713394302722.jpg",
    fotoDerecha: "https://i.postimg.cc/JzGvKq5Y/IMG-20240417-WA0010.jpg",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor, con apoyo de mis padres!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "",
    esposaMadre: "Lizbeth Garza Pineda",
    esposaPadre: "Daniel Medina Lira",
    esposoMadre: "",
    esposoPadre: "",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 04 de Mayo de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Parroquia San Isidro Labrador",
    ceremoniaLugarMap:
      "Venustiano Carranza & Javier Mina, 25490 Primero de Mayo, Coah.",
    ceremoniaUrl: "https://maps.app.goo.gl/aomKrYGY93gwUKUL9",
    recepcionFecha: "Sábado, 04 de Mayo de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "",
    recepcionLugarMap: "Calle Oriental Ejido Primero de Mayo",
    recepcionUrl: "https://maps.app.goo.gl/depSbSFHyFU2TjnB8",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const images = [
    "https://i.postimg.cc/63NPWNJF/IMG-20240417-WA0011.jpg ",
    "https://i.postimg.cc/XYmhPC2j/IMG-20240417-WA0008.jpg",
    "https://i.postimg.cc/VNtTPkTj/IMG-20240417-WA0009.jpg",
    "https://i.postimg.cc/rFh7Sbxt/FB-IMG-1713394302722.jpg",
    "https://i.postimg.cc/JzGvKq5Y/IMG-20240417-WA0010.jpg",
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear"
  };

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} />
      <Portada datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      
    <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiPartyPopper  className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE DE XV AÑOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baileXV}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>

      <div
    style={{
      display: "flex",
      backgroundPosition: "center center",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor:peltaColores.color2,
      color:datosSeccion1.textColor ? datosSeccion1.textColor : '' 
    }}
    className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
  >
    <h1
      className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
      data-aos="flip-up"
    >
      {datosSeccion1.tituloConfirmacion || (<div>
Agradezco de antemano su apreciable compañía.
      </div>)}
    </h1>
    <br />
    <p className='sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]'>{datosSeccion1.aviso || ''}</p>
    <br />
    <br/>
    <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
      {images?.map((imageUrl, index) => (
        <div  data-aos="fade-down-right" className="rolImg" key={index}>
          <img
            src={imageUrl}
            alt={`Slide ${index + 1}`}
            className="w-auto rounded-xl max-h-[60vh]"
          />
        </div>
      ))}
    </Slider>
    <br />
    <br />
  </div>
    </div>
  );
};
