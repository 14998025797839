import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./NoCrezcas.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyFlags, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaChampagneGlasses } from "react-icons/fa6";

export const XvCarmen = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Carmen",
      fechaDeBoda: "13 ABRIL, 2024",
      fechaNewDate: "April 13, 2024 17:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/vTRhhhFJ/2d1ad229-130d-45ac-b319-9c9a04450c64-transformed.jpg",
      numeroNovia: "9511992068",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#ffe4ee",
      color2: "#ffffe8",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Carmen!',
      entradasText:'disfrutar mis XV años',
      colorFondoName:"#fcabc9"
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "La vida es un camino lleno de sorpresas y oportunidades, y hoy celebro mis quince años con la certeza de que lo mejor esta por venir. Los espero para que juntos celebremos la vida y el amor.",
      fechaNewDate: "April 13, 2024 17:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/3RzS67pb/4801f5dc-422c-441d-908a-f9d6b62368d1.jpg",
      fotoDerecha: "https://i.postimg.cc/2STHYnhW/bfd6a67a-5e5d-498b-a787-7de809c756ab.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Dra. Bertha Haydee Benitez Cruz",
      esposaPadre: "C.P.José Martín Lopez Ojeda",
      esposoMadre: "Arq. David Miguel Benitez Cruz ",
      esposoPadre: "Dr. Pedro Antonio Benitez Cruz",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 13 de Abril de 2024.",
      ceremoniaHora: "5:00 p.m.",
      ceremoniaNombreLugar: "Templo del Carmen Alto",
      ceremoniaLugarMap:
        "Pzla. del Carmen Alto, RUTA INDEPENDENCIA, Centro, 68000 Oaxaca de Juárez, Oax.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/UwAktq5FPKZyFpCcA",
      recepcionFecha: "Sábado, 13 de Abril de 2024.",
      recepcionHora: "7:30 p.m.",
      recepcionNombreLugar: "Quinta Alborada",
      recepcionLugarMap:
        "Av. Lázaro Cárdenas NUMERO 414, El Bosque Nte., 71244 Santa Lucía del Camino, Oax.",
      recepcionUrl:
        "https://maps.app.goo.gl/63zwGdvp16JvBgJ48",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "5 pm Acompáñanos a recibir la bendición de Dios",
      baileXV: "No te pierdas mi baile de XV años",
      cena: "Disfruta de una deliciosa cena",
      recepcion: "Tómate fotos con nosotros",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
    };
  
  
    const datosSeccion7 = {
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    };
  
    const datosSeccion8 = {
      descripcion:
        "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirme escoger mi regalo.",
      linkAmazon:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      linkLiverpool:
        "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    };
  
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
  
  
    const images = [
      "https://i.postimg.cc/C1RrmdZq/59528827-3e8f-4bb4-bda7-7019327a9205.jpg",
      "https://i.postimg.cc/YSsbGkXq/fe26dc99-d5df-45ff-9d90-ebee1cba53c3.jpg",
      "https://i.postimg.cc/Jhnpzt52/ca9d6c4e-52b2-48e8-b447-60efadae7798.jpg",
      "https://i.postimg.cc/SNF1fSmn/8da4fc93-b158-4ff6-9043-5d904805e893.jpg",
      "https://i.postimg.cc/hvxyRw14/03f1b36f-a2ae-431b-aa1a-87a2c4dec18e.jpg",
      "https://i.postimg.cc/c1853gGq/15d2f189-0504-48b3-9222-b3e8c7f50c28.jpg"
      // Add more image URLs as needed
    ];
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/PrW36gq2/059e3759-edb4-46b9-b882-bfc9e8ecebbd.jpg")',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
    }
  
    const names = [
      { name: "Angel Jaimes", guess: "3" },
      { name: "Bertha Haydee Benitez Cruz", guess: "2" },
      { name: "Pedro Benítez Soriano", guess: "2" },
      { name: "Felicitas Soriano Mendoza", guess: "2" },
      { name: "David Miguel Benítez Cruz", guess: "1" },
      { name: "Pedro Antonio Benítez Cruz", guess: "1" },
      { name: "José Antonio Benítez Soriano", guess: "2" },
      { name: "María José Benítez Aguilar", guess: "3" },
      { name: "Noe Santos", guess: "2" },
      { name: "Andrea Santos Benítez", guess: "2" },
      { name: "Diego Santos Benítez", guess: "5" },
      { name: "Esteban Benítez Soriano", guess: "5" },
      { name: "Jose Alberto Benítez Aguilar", guess: "2" },
      { name: "Karina Benitez Aguilar", guess: "5" },
      { name: "Delfina Benítez Soriano", guess: "2" },
      { name: "Alejandro Benítez Soriano", guess: "3" },
      { name: "Dolores Monjaraz", guess: "2" },
      { name: "Alejandra Benítez Monjaraz", guess: "3" },
      { name: "Mariela Benítez Monjaraz", guess: "3" },
      { name: "Guillermina Benítez Soriano", guess: "3" },
      { name: "Dulce Benítez", guess: "3" },
      { name: "Gabina Benítez Soriano", guess: "8" },
      { name: "Ángel Méndez Benítez", guess: "2" },
      { name: "Joel Merino", guess: "2" },
      { name: "José Martin López Ojeda", guess: "2" },
      { name: "Margarita López Ojeda", guess: "3" },
      { name: "Silvia López Ojeda", guess: "5" },
      { name: "Jose Benigno López Ojeda", guess: "6" },
      { name: "Arturo López Ojeda", guess: "4" },
      { name: "Jorge López Hernández", guess: "3" },
      { name: "Gilberto López Ojeda", guess: "4" },
      { name: "Ana López Ojeda", guess: "3" },
      { name: "Elena López Ojeda", guess: "3" },
      { name: "Remedios Josefina Ruiz Matus", guess: "4" },
      { name: "Medina Islas", guess: "3" },
      { name: "Familia Martinez Rodriguez", guess: "5" },
      { name: "Luz María Salud", guess: "3" },
      { name: "Familia Santiago Hernandez", guess: "5" },
      { name: "Familia Ávila Garcia", guess: "3" },
      { name: "Familia Garnica Espinoza", guess: "4" },
      { name: "Christian Perez Pascual", guess: "2" },
      { name: "Fam. Woolrich Sorroza", guess: "3" },
      { name: "Familia Figueroa Rubiños", guess: "3" },
      { name: "Familia Ríos Cruz", guess: "3" },
      { name: "Familia Rodriguez Ruvalcaba", guess: "3" },
      { name: "Andrea", guess: "2" },
      { name: "Zazil Palma", guess: "2" },
      { name: "Regina Aquino", guess: "5" },
      { name: "Camila Toledo", guess: "2" },
      { name: "Valeria Montero García", guess: "2" },
      { name: "Kenia Galindo", guess: "1" },
      { name: "Cristina Amezcua", guess: "1" },
      { name: "Luna Hernández", guess: "1" },
      { name: "Lía Chagoya", guess: "1" },
      { name: "María José", guess: "1" },
      { name: "Natalia", guess: "1" },
      { name: "Isabella Palacios", guess: "1" },
      { name: "Emilio Aldair", guess: "1" },
      { name: "Leo Hernández", guess: "1" },
      { name: "Gabriel Santiago", guess: "1" },
      { name: "Samuel Tonatiuh", guess: "1" },
      { name: "Gabriel Merlo", guess: "1" },
      { name: "Alexander", guess: "1" },
      { name: "Luciano Palacios", guess: "1" },
      { name: "Aaron Ramirez", guess: "1" },
      { name: "Imanol Garcia", guess: "1" },
      { name: "Héctor David", guess: "1" },
      { name: "Diego Bustillos Aguilar", guess: "1" },
      { name: "Jose Iván", guess: "1" },
      { name: "Carlos López", guess: "1" },
      { name: "Luis Ángel Antonio", guess: "1" },
      { name: "Patricia Merlin Carballido", guess: "2" },
      { name: "Laura Santiago", guess: "3" },
      { name: "Claudia Rodriguez", guess: "3" },
      { name: "Elibeth Zarate", guess: "4" },
      { name: "Lucy Orozco Gallegos", guess: "2" },
      { name: "Maria Georgina Vazquez Gutierrez", guess: "3" },
      { name: "Martha Morales Gijon", guess: "2" },
      { name: "Familia Navarrete Tapia", guess: "4" },
      { name: "Rocelia Ramirez", guess: "4" },
      { name: "Zaira Altamirano Marquez", guess: "2" },
      { name: "Jesus Emmanuel Lopez Vasquez", guess: "2" },
      { name: "Francisco Sotelo", guess: "2" },
      { name: "Francisco", guess: "2" },
      { name: "Jose Lorenzo Diaz", guess: "3" },
      { name: "Alejandro Guzman", guess: "2" },
      { name: "Luan", guess: "2"},
      { name: "Profa Luz Maria Ocampo", guess: "2" },
      { name: "Rubí Montalvo Cortez", guess: "2" },
      { name: "Manuel Garcia", guess: "3" },
      { name: "Melina Mendez Marcial", guess: "2" },
      { name: "Sodel Rodriguez", guess: "2" },
      { name: "Antonio Olivera", guess: "1" },
      { name: "Familia Tovar Zuñiga", guess: "2" },
      { name: "Catalina Martinez Jimenez", guess: "3" },
      { name: "Marina Cortez", guess: "3" },
      { name: "Rosalba Jimenez", guess: "3" },
      { name: "Sra. Oralia Ramirez y Familia", guess: "6" },
      { name: "Fernando Rosales", guess: "2" },
      { name: "Aridai Giron y Familia", guess: "5" },
      { name: "Ignacio Bernal", guess: "4" },
      { name: "Mariela Alavez", guess: "4" },
      { name: "Ignacio Alavez Vasquez", guess: "4" },
      { name: "David Alavez Cruz", guess: "4" },
      { name: "Guillermina Alavez", guess: "4" },
      { name: "Javier Alavez", guess: "5" },
      { name: "Maricela Alavez Vasquez", guess: "2" },
      { name: "Salvador Alavez", guess: "2" },
      { name: "Andrea Guadalupe Alavez", guess: "4" },
      { name: "Everardo Gonzaga Robles", guess: "2" },
      { name: "Margarita Cruz Alavez", guess: "4" },
      { name: "Teresa Suriano Aceituno", guess: "3" },
      { name: "Alina Belmonte Carretino", guess: "6" },
      { name: "José Aguilar", guess: "1" },
      { name: "Cinthia Lopez Lopez", guess: "2" },
      { name: "Lic. Enrrique Coronel", guess: "2" },
      { name: "LCP. Perla Pereyra", guess: "2" },
      { name: "Lic. Gabriel Toache", guess: "1" },
      { name: "Abimael Aragon", guess: "1" },
      { name: "Lic. Flor del Carmen Rios", guess: "1" },
      { name: "Jose Santa Cultura", guess: "1" },
      { name: "Euripides Pedro", guess: "1" },
      { name: "Abdiel Calderon", guess: "1" },
      { name: "Lcp. Obed Ortega", guess: "2" },
      { name: "Dr. Jesus Hernandez", guess: "3" },
      { name: "Mtra. Norma Rosas Paz", guess: "2" },
      { name: "Netzahualcoyotl Huerta Perez", guess: "2" },
      { name: "Erick Cervantes", guess: "1" },
      { name: "Andrés Moreno", guess: "1" },
      { name: "Victor Aragón Lopez", guess: "3" },

      
      { name: "Anita Chamorro", guess: "3" },
      { name: "Agustina Alavez", guess: "4" },
      { name: "Félix Orlando", guess: "2" },
      { name: "Tony Lorenzo", guess: "2" },
      { name: "Nieves Martinez", guess: "3" },
      { name: "⁠Abril Lopez", guess: "2" },
      { name: "Isabella ", guess: "1" },
      { name: "⁠David Rafael", guess: "1" },
      { name: "Martha Domínguez", guess: "2" },
      { name: "Luis Bravo", guess: "1" },
      { name: "⁠Javier barrita", guess: "2" },
      { name: "⁠Claudia Baez", guess: "4" },
      { name: "Javier David", guess: "2" },
      { name: "Profe. Juan Guillermo Bernardino", guess: "2" },
      { name: "Profa. Janet", guess: "1" },
      { name: "Profe. Jesús", guess: "1" },
      { name: "Profe. Irma", guess: "2" },
      { name: "Profe . David", guess: "1" },
      { name: "Profe Luis", guess: "4" },
      { name: "Profa. Madai", guess: "4" },
      { name: "Profe. Erick Oswaldo", guess: "4" },
      { name: "Profe. Gerardo", guess: "2" },
      { name: "Profa. Azaideth", guess: "3" },
      { name: "Profa Karla", guess: "3" },
      { name: "Profe. Yuri", guess: "1" },
      { name: "Profa. Metzi", guess: "2" },
      { name: "Varquidia", guess: "1" },
      { name: "Alejandra", guess: "1" },
      { name: "Alexandra", guess: "1" },
      { name: "Gabriela", guess: "1" },
      { name: "Azul García", guess: "4" },
      { name: "Elena Huesca", guess: "3" },
      { name: "Leydi", guess: "1" },
      { name: "Gretel", guess: "1" },

      { name: "Nayeli Avendaño", guess: "2" },
      { name: "Teresa Martínez", guess: "2" },
      { name: "Juan Córdova", guess: "2" },
      { name: "Salvador Mendoza", guess: "2" },
      
      { name: "Ángel Ricardo", guess: "2" },
      { name: "Nubia", guess: "1" },
      { name: "Lupita", guess: "1" },
      { name: "Pilar", guess: "1" },
  ];
    
  
    const [messageWhats, setMessageWhats] = useState("");
    const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
  
      const findName = names.find((e) => e.name == nameId);
      console.log(findName?.name, names.length);
  
      if (findName) {
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
      } else {
        window.location.href = "/";
      }
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
         <ModalXv 
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Invitados
          NameInvitation={NameInvitation}
          numGuess={numGuess}
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
          datosInvitados={datosInvitados}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] md:py-0 sm:py-28 flex flex-col w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <BsFillPeopleFill className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CALENDA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
              6 pm a 7 pm Calenda saliendo de misa
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiPartyFlags className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">SALON</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                7:30 pm Te esperamos en el salon 
              </p>
              <br />
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
                <GiPartyPopper  className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE DE XV AÑOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baileXV}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
             
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>
  
      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:p-3 md:p-0 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          {/* <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p> */}

          <div className="grid sm:grid-cols-1 md:grid-cols-2 justify-center items-center w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/Ls6nt8xr/b6d146b7-b367-4038-9e86-f2410388ec03.jpg")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[45vh]"
              data-aos="fade-right"
            >
              <p
                class="overflow-hidden relative w-full p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold z-10 group"
              >
                CALENDA
              </p>
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/nh1X9DG4/c8da864b-5345-4414-ab43-07ec755e2405.jpg")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center top",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[45vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              <p
                class="overflow-hidden relative w-full p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold z-10 group"
              >
                VALS
              </p>
            </div>
          </div>
        </div>
      </div>
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:px-3 sm:py-10 md:py-28 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/pT8SZzXT/Dise-o-sin-t-tulo-7.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
             
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/pT8SZzXT/Dise-o-sin-t-tulo-7.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="md:flex sm:hidden flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              
            </div>
          </div>
        </div>
      </div>
  
        <Confirmacion
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
