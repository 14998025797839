import React, { useEffect, useState } from 'react';
import { GiTicket } from 'react-icons/gi';

export const Invitados = ({ NameInvitation, numGuess, peltaColores, imagenesFondo, datosInvitados }) => {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  const fontFamilyStyle = { fontFamily: datosInvitados.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  // Traducción de textos si datosInvitados.ingles es true
  const titulo = datosInvitados.ingles ? 'Add Your Guests!' : datosInvitados.titulo;
  const accesosText = datosInvitados.ingles ? `You have ${numGuess} entries for ${datosInvitados.entradasText}` : `Cuentas con ${numGuess} accesos para ${datosInvitados.entradasText}`;

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${imagenesFondo.fondoInvitados || ""}`,
        backgroundColor: peltaColores.color2,
        backgroundAttachment: isIOS ? 'local' : 'fixed',
        backgroundPosition: datosInvitados.sizeFoto ? datosInvitados.sizeFoto : "center",
        backgroundBlendMode: 'darken',
      }}
      className="sm:h-auto md:h-[100vh] bg-img-fixed sm:bg-local md:bg-fixed px-2 py-5"
    >
      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]  p-2 rounded-2xl text-white"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosInvitados.titulo}
        </h1>
        <br />
        <br />
        <p
          className="text-center text-white p-[3vh] rounded-tl-3xl rounded-br-3xl font-serif text-[35px]"
          data-aos="flip-up"
          style={{
            ...fontFamilyStyle,
          }}
        >
          {NameInvitation}
        </p>
        <img
          src={datosInvitados.imgInvitados ? datosInvitados.imgInvitados : ""}
          className="md:w-[25%] sm:w-[75%] md:h-[23vh] md:mt-[-10px] sm:mt-[10px] object-contain"
          alt=""
        />
        <div className="grid sm:grid-cols-1 w-[80%] pt-[10vh]">
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            <GiTicket className="text-[50px]  p-2 rounded-2xl"
              style={{
                color: datosInvitados.colorFondoName ? datosInvitados.colorFondoName : '#a3a438',
              }}
            />
            <br />
            <p
              className=" font-serif text-center text-2xl text-white p-2 rounded-2xl"
              style={fontFamilyStyle}
            >
              {accesosText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
