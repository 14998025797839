import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvAmanda = () => {
  const [isOpen, setIsOpen] = useState(false);

  const peltaColores = {
    color1: "#1c190d",
    color2: "#f8f2f2",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDBu2aZZ0UR_y2mate.com---Taylor-Swift-The-Best-Day-Taylors-Version-Lyric-Video.mp3",
    imgPortada:
      "https://i.postimg.cc/PJjPmRw8/Gold-Black-Shine-Glitter-Phone-Wallpaper.png",
    nombre: "AMANDA",
    fechaDeBoda: "03 MAYO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "Sin papás. \\n Pase Personal",
    numeroConfirmacion: "+529531635969",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , quiero dar gracias a mis padres, mi familia y mis amigos que han sido parte de este camino, entre risas, enojos y hasta lágrimas he pasado momentos inolvidables. Es por eso que quiero que formes parte de un festejo más, en mi cena de XV años que representa el inicio de una nueva etapa.",
    fechaNewDate: "May 03, 2024 18:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/cLrkq9v3/invitacion-vertical-evento-gala-elegante-dorado.png",
    fotoDerecha:
      "https://i.postimg.cc/Fz3B7DtR/invitacion-vertical-evento-gala-elegante-dorado-2.png",
    marcoEnable: true,
    imgFondo: "https://i.postimg.cc/wjCCxgTv/Dark-Golden-Glitter-Glowing-Phone-Wallpaper-1.png",
    textColor: "#fff",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "",
    madre: "Dallany",
    padre: "Jose Roberto",
    madrina: "",
    padrino: "",
    imgFondo: "https://i.postimg.cc/wjCCxgTv/Dark-Golden-Glitter-Glowing-Phone-Wallpaper-1.png",
    textColor: "#fff",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Viernes, 03 de Mayo de 2024.",
    ceremoniaHora: "6:30 p.m.",
    ceremoniaNombreLugar: "Terraza Hotel Casa Hidalgo",
    ceremoniaLugarMap:
      "Miguel Hidalgo 417-B, Zona Lunes Feb 09, Centro, 68000 Oaxaca de Juárez, Oax.",
    ceremoniaUrl: "https://maps.app.goo.gl/2wmo8PmQAZ1XVAeUA",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 03 de Mayo de 2024.",
    recepcionHora: "6:30 p.m.",
    recepcionNombreLugar: "Terraza Hotel Casa Hidalgo",
    recepcionLugarMap:
      "Miguel Hidalgo 417-B, Zona Lunes Feb 09, Centro, 68000 Oaxaca de Juárez, Oax.",
    recepcionUrl:
      "https://maps.app.goo.gl/2wmo8PmQAZ1XVAeUA",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Formal",
    messageVestimenta: "Se reserva el color negro para la quinceañera",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/GmF6mfTC/IMG-20240424-WA0023.jpg",
    "https://i.postimg.cc/TYWZZ1Rh/IMG-20240424-WA0068.jpg",
    "https://i.postimg.cc/pdk7DnBc/IMG-20240424-WA0061.jpg",
    "https://i.postimg.cc/0jzL52DW/IMG-20240424-WA0065.jpg",
    "https://i.postimg.cc/bvqWDR12/IMG-20240424-WA0055.jpg",
    "https://i.postimg.cc/VshxGnP3/IMG-20240424-WA0066.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);



  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
          peltaColores={peltaColores}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
