import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/maroon.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import ModalCard from "../../../components/Modals/ModalCard";

export const BodaAlanYThania= () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_04/NDBw0RfGgGs_Y2meta.app---Adele---Make-You-Feel-My-Love-Oficial-Subtitulada-en-Espa%C3%B1ol-Ingl%C3%A9s-128-kbps-1-.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Jorge Alan",
    esposa: "Thania Paola",
    fechaDeBoda: "18 MAY, 2024",
    imgPortada:
      "https://i.postimg.cc/J0sNX8JL/pro-e-Tdnkc-TS.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Hay momentos en la vida que son especiales por si solos, pero compartirlos con las personas que apreciamos los convierte en mágicos e inolvidables. \\n Tu eres una de nuestras personas favoritas en todo el mundo y nos encantaría que nos acompañes el día de nuestra Boda.",
    fechaNewDate: "May 18, 2024 13:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/YCFPxT7b/0be5b62a-8230-42c2-8017-7244dc3809d1.jpg",
    fotoDerecha: "https://i.postimg.cc/HnXrsp5f/b174a5f7-ded2-45ea-b94c-d888dc7f2190.jpg",
    marcoEnable: true,
    imgFondo: "https://i.postimg.cc/SxvpSVmS/Pastel-colors-Vintage-with-Floral-Illustration-Happy-Wedding-Wishes-Instagram-Story.png",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Thania y Jorge!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Marisol Plata Vazquez",
    padre: "Miguel Angel Sanchez Martinez",
    titulo2: "Padres del Novio",
    madrina: "Ana María Rojas Esquivel",
    padrino: "Jorge Ernesto Jacobo Flores",
    titulo3: "Padrinos",
    madrina2: "Conrrado Sánchez Ayala",
    padrino2: "Robertina Martinez Velazquez",
    imgFondo: "https://i.postimg.cc/SxvpSVmS/Pastel-colors-Vintage-with-Floral-Illustration-Happy-Wedding-Wishes-Instagram-Story.png",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 18 de Mayo de 2024.",
    ceremoniaHora: "1:00 p.m.",
    ceremoniaNombreLugar: "Parroquia de la Virgen de Guadalupe y San Juan Diego",
    ceremoniaLugarMap:
      "Manuel M. Dieguez Manzana 011, Guadalupe y Club Jardín, 50010 Toluca de Lerdo, Méx.",
    ceremoniaUrl:
      "https://maps.app.goo.gl/xynphyHTSw4xSTQEA",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 18 de Mayo de 2024.",
    recepcionHora: "4:00 p.m.",
    recepcionNombreLugar: "Salón María Cristina",
    recepcionLugarMap:
      "Fray. J. de S. Alberto 209, Electricistas Locales, 50040 Toluca de Lerdo, Méx.",
    recepcionUrl:
      "https://maps.app.goo.gl/gBpDSxkcMqqigfEcA",
    imgFondo: "https://i.postimg.cc/SxvpSVmS/Pastel-colors-Vintage-with-Floral-Illustration-Happy-Wedding-Wishes-Instagram-Story.png",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "1:00 pm Acompáñanos a recibir la bendición de Dios",
    civil: "4:10 pm No te pierdas nuestro intercambio de votos",
    recepcion: "4:00 pm Tómate fotos con nosotros",
    cena: "6:00 pm Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
    imgFondo:"https://i.postimg.cc/SxvpSVmS/Pastel-colors-Vintage-with-Floral-Illustration-Happy-Wedding-Wishes-Instagram-Story.png"
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n \\n ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51428842",
    imgLink1: "https://liizt.com/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/4/3/4351566853583-image.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "https://i.postimg.cc/SxvpSVmS/Pastel-colors-Vintage-with-Floral-Illustration-Happy-Wedding-Wishes-Instagram-Story.png",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "No Niños.",
    numeroConfirmacion: "+527299634482",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "https://i.postimg.cc/SxvpSVmS/Pastel-colors-Vintage-with-Floral-Illustration-Happy-Wedding-Wishes-Instagram-Story.png",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/y84mDw8M/pro-D0Cnogqr.jpg",
    "https://i.postimg.cc/BvJ2gHDN/pro-lwzCCjUS.jpg",
    "https://i.postimg.cc/x8BKyZyb/pro-W1vrWldC.jpg",
    "https://i.postimg.cc/0QWGrjzn/pro-ujs23sdh.jpg",
    "https://i.postimg.cc/W4SgfzwV/pro-0Oq7hPeM.jpg",
    "https://i.postimg.cc/Nj61mpy5/pro-CABfzlRf.jpg",
    "https://i.postimg.cc/J0sNX8JL/pro-eTdnkcTS.jpg",
    "https://i.postimg.cc/VLb9LYm9/pro-IhQOdhkE.jpg",
    // Add more image URLs as needed
  ];

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
    <ModalCard isOpen={isOpen} setIsOpen={setIsOpen} datosSeccionPortada={datosSeccionPotadaBoda}/>
      <PortadaBodaComponent
        datosSeccionPotada={datosSeccionPotadaBoda}
        audioFondo={audioFondo}
      />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
