import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de tener Bootstrap instalado
import { PortadaXvComponent } from '../../../components/ComponentsXv/PortadaXvComponent';
import { ContadorBodaComponent } from '../../../components/ComponentsBoda/ContadorBodaComponent';
import { ConfirmacionDiamXvComponent } from '../../../components/ComponentsXv/ConfirmacionDiamXvComponent';

export const ModalFormularioXvPre = ({ isOpen, toggleModal, datos, dataEvento, ingles }) => {
  const [imageUrls, setImageUrls] = useState({
    coverBackground: null,
    counterLeft: null,
    counterRight: null,
    carousel: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadImages = () => {
      const coverBackground = datos?.coverBackgroundFile ? URL.createObjectURL(datos.coverBackgroundFile) : null;
      const counterLeft = datos?.counterLeftFile ? URL.createObjectURL(datos.counterLeftFile) : null;
      const counterRight = datos?.counterRightFile ? URL.createObjectURL(datos.counterRightFile) : null;
      const carousel = datos?.carousel ? datos.carousel.map(file => URL.createObjectURL(file?.replacement)) : [];

      setImageUrls({ coverBackground, counterLeft, counterRight, carousel });
      setLoading(false);
    };

    if (datos) {
      loadImages();
    }

    // Clean up object URLs on component unmount
    return () => {
      if (imageUrls.coverBackground) URL.revokeObjectURL(imageUrls.coverBackground);
      if (imageUrls.counterLeft) URL.revokeObjectURL(imageUrls.counterLeft);
      if (imageUrls.counterRight) URL.revokeObjectURL(imageUrls.counterRight);
      imageUrls.carousel.forEach(url => URL.revokeObjectURL(url));
    };
  }, [datos]);

  const peltaColores = {
    color1: datos?.color1 || "",
    color2: datos?.color2 || "",
  };

  const datosSeccionPotada = {
    cancion: "",
    textoConfirmacion: datos?.portada?.textoConfirmacion || "",
    nombre: datos?.D1NombreQuince || "",
    fechaDeBoda: ingles ? "DEC 12, 2024" : "12 DIC, 2024",
    imgPortada: imageUrls.coverBackground || "",
    titulo: ingles ? "MY EVENT" : "MI EVENTO",
    tipoLetra: datos?.tipoLetra || "",
  };

  const datosSeccionContador = {
    titulo: ingles ? "My Event!" : "¡Mi Evento!",
    descipcion: datos?.D2descipcion || "",
    fechaNewDate: "December 12, 2024 00:00:00 GMT+00:00",
    fotoIzquierda: imageUrls.counterLeft || "",
    fotoDerecha: imageUrls.counterRight || "",
    marcoEnable: true,
    imgFondo: datos?.imgFondo || "",
    textColor: datos?.contador?.textColor || "",
    contador: true,
    tipoLetra: datos?.contador?.tipoLetra,
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion: ingles 
      ? datos?.confirmacion?.tituloConfirmacion || "Thank you very much for your attention. We hope to count on your presence." 
      : datos?.confirmacion?.tituloConfirmacion || "Muchas gracias por su atención. Esperamos contar con su presencia.",
    aviso: datos?.confirmacion?.aviso || "",
    numeroNovia: "",
    numeroNovio: "",
    numeroConfirmacion: "",
    textoConfirmacion: "",
    textWhatsApp: "",
    textColor: datos?.confirmacion?.textColor || "",
    imgFondo: datos?.imgFondo || "",
    textColor: datos?.confirmacion?.textColor || "",
    tipoLetra: datos?.confirmacion?.tipoLetra || "",
  };

  const contador = dataEvento?.enableContador;
  const confirmacion = dataEvento?.enableConfirmacion;

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} style={{ maxWidth: '100%', width: '100%' }} className="modal-dialog-centered">
      <ModalHeader toggle={toggleModal} className="font-bold">
        {ingles ? "Sample with Photos and Background" : "Ejemplo de Solo Fotos y Fondo"}
      </ModalHeader>
      <ModalBody style={{ padding: '0' }}>
        {loading ? (
          <div>{ingles ? "Loading..." : "Cargando..."}</div>
        ) : (
          <>
            <PortadaXvComponent datosSeccionPortada={datosSeccionPotada} />
            {contador && (
              <ContadorBodaComponent
                peltaColores={peltaColores}
                datosSeccionContador={datosSeccionContador}
              />
            )}
            {confirmacion && (
              <ConfirmacionDiamXvComponent
                peltaColores={peltaColores}
                images={imageUrls.carousel}
                messageWhats=""
                datosSeccionConfirmacion={datosSeccionConfirmacion}
              />
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalFormularioXvPre;
