import React from 'react'

export const SelectSobre = ({ values, handleChange }) => {
  return (
    <div className="mt-4">
                    <span className="block text-sm font-medium text-gray-700 mb-1">
                      Elige un tipo de Sobre:
                    </span>

                    {/* Opción 1 */}
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="imageOption1"
                        name="sobre"
                        value="0"
                        checked={values?.sobre === null || values?.sobre === 0}
                        onChange={(e) =>
                          handleChange({ target: { name: "sobre", value: 0 } })
                        }
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label
                        htmlFor="imageOption1"
                        className="ml-2 block text-sm font-medium text-gray-700"
                      >
                        <img
                          src="https://digitalrsvp.mx/api/Images/75811aef-8f62-410e-b703-07b072cf7f07"
                          alt="Opción 1"
                          className="h-28 w-28 object-cover"
                        />
                      </label>
                    </div>

                    {/* Opción 2 */}
                    <div className="flex items-center mt-2">
                      <input
                        type="radio"
                        id="imageOption2"
                        name="sobre"
                        value="1"
                        checked={values?.sobre === 1}
                        onChange={(e) =>
                          handleChange({ target: { name: "sobre", value: 1 } })
                        }
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label
                        htmlFor="imageOption2"
                        className="ml-2 block text-sm font-medium text-gray-700"
                      >
                        <img
                          src="https://digitalrsvp.mx/api/Images/faa41ddb-79ae-4aea-95c1-806f74c76cd7	"
                          alt="Opción 2"
                          className="h-28 w-28 object-cover"
                        />
                      </label>
                    </div>
                  </div>
  )
}
