import React, { useEffect } from "react";

const GoogleTranslate = () => {
    useEffect(() => {
        // Cambia el metadato de idioma a inglés
        document.documentElement.lang = 'en';
    }, []);

    return null;
};

export default GoogleTranslate;
