import React, { useEffect } from "react";
import { useState } from "react";
import { CSSTransition } from 'react-transition-group';
import 'tailwindcss/tailwind.css';
import Aos from "aos";

const ModalEventos = ({ isOpen, setIsOpen, datosSeccionPortada, peltaColores }) => {
  const [flapOpen, setFlapOpen] = useState(false);

  useEffect(() => {
    // Inicializa AOS
    Aos.init({ duration: 2000 });
  }, []);

  const toggleFlap = () => {
    setFlapOpen(!flapOpen);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <CSSTransition
        in={isOpen}
        timeout={2000}
        classNames="modal"
        unmountOnExit
        appear
      >
        <div className="modal container">
          <div
            className={`envelope-wrapper ${flapOpen ? "flap" : ""}`}
            onClick={toggleFlap}
          >
            <div className="envelope">
              <div className="letter" style={{backgroundImage: `url(${datosSeccionPortada.imgPortada || ""})`}}>
                <div className="text">
                  <button
                    className="ver-invitacion-btn"
                    onClick={closeModal}
                    style={{backgroundColor: peltaColores.color1}}
                  >
                    Ver Invitación
                  </button>
                </div>
              </div>
            </div>
            <div className="heart"></div>
            <p className="texto-corazon">{datosSeccionPortada.esposo} & {datosSeccionPortada.esposa || ""}</p>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ModalEventos;
