import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaYareliYJhonatan = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#cbf0ff",
    color2: "#f8f2f2",
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_05/NElvaGCYGpR_y2mate.com---A-D%C3%B3nde-Vamos.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Jhonatan ",
    esposa: "Yareli ",
    fechaDeBoda: "29 JUNIO, 2024",
    imgPortada:
      "https://i.postimg.cc/HLFc7dw4/processed-8-EEC454-F-8-D9-D-48-AA-B015-061809-CC06-B4.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Nos complace y bendecimos el que puedan acompañarnos en este día especial para los dos, donde unimos nuestras vidas con la bendición de Dios y de nuestros padres",
    fechaNewDate: "June 29, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/dVDtHTwf/original-99-F37745-9-A75-4299-95-FD-DBBDEBC83-A83-1.jpg",
    fotoDerecha:
      "https://i.postimg.cc/ZR3982jk/processed-A3-C3-C86-F-54-C6-459-A-9610-0-BD57-E140-B10.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Hilda Reyes Rodriguez ",
    padre: "Jesus Gauna Rios",
    titulo2: "Padres del Novio",
    madrina: "María Guadalupe Flores Guerra",
    padrino: "José Ángel Mancillas Martinez",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 29 de Junio de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Real del Sol",
    ceremoniaLugarMap: "Unnamed Road, Fundadores, 89505 Cd Madero, Tamps.",
    ceremoniaUrl: "https://maps.app.goo.gl/GLZLUhDJKHnP75s4A",
    recepcionEnable: false,
    recepcionFecha: "Sábado, 29 de Junio de 2024.",
    recepcionHora: "7:00 p.m.",
    recepcionNombreLugar: "El Herradero Jardín y Eventos",
    recepcionLugarMap:
      "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
    recepcionUrl:
      "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Playero",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://i.postimg.cc/1zPzwNnV/07864772096bae697850bea9c731679d.jpg",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1:
      "https://i.postimg.cc/v8J3vQCc/Cream-Brown-Minimalist-Simple-Birthday-Invitation-2.png",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso:
      "Procurar cuidar a sus niños en no atravesarse en momentos importantes gracias",
    numeroConfirmacion: "+528119306908",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    numeroNovia: "+528119306908",
    numeroNovio: "+528143860092",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/HLFc7dw4/processed-8-EEC454-F-8-D9-D-48-AA-B015-061809-CC06-B4.jpg",
    "https://i.postimg.cc/dVDtHTwf/original-99-F37745-9-A75-4299-95-FD-DBBDEBC83-A83-1.jpg",
    "https://i.postimg.cc/ZR3982jk/processed-A3-C3-C86-F-54-C6-459-A-9610-0-BD57-E140-B10.jpg",
    "https://i.postimg.cc/1Xhqbs45/original-F2-FE824-E-E53-D-457-A-9199-5-E91-DDBEB3-AF.jpg",
    "https://i.postimg.cc/QMFKtHTd/processed-0-AC95-D8-C-595-B-4-A66-96-B8-7-D1-B7-B66-DCB6.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Nicolas Salinas y fam", guess: "3", id: 1 },
    { name: "Erick Morales", guess: "2", id: 2 },
    { name: "Aurelio Carmona y fam", guess: "4", id: 3 },
    { name: "Krystal Gomez", guess: "2", id: 4 },
    { name: "Jonathan Banda y fam", guess: "3", id: 5 },
    { name: "Ramon Sauceda y fam", guess: "4", id: 6 },
    { name: "Areli Mancillas", guess: "4", id: 7 },
    { name: "Kevin Hernadez y fam", guess: "4", id: 8 },
    { name: "Adrian Mancillas y fam", guess: "5", id: 9 },
    { name: "Judith Mancillas y fam", guess: "6", id: 10 },
    { name: "Aurora Gomez", guess: "2", id: 11 },
    { name: "Miguel Gomez", guess: "2", id: 12 },
    { name: "Juan Banda", guess: "5", id: 13 },
    { name: "Javier Salinas y fam", guess: "3", id: 14 },
    { name: "Luis Ruiz", guess: "2", id: 15 },
    { name: "Tony Saldivar", guess: "2", id: 16 },
    { name: "Alan Rangel", guess: "3", id: 17 },
    { name: "Jorge Morales", guess: "3", id: 18 },
    { name: "Aliz Morales", guess: "2", id: 19 },
    { name: "Daniel Muñoz", guess: "2", id: 20 },
    { name: "Baldemar Escobar y fam", guess: "4", id: 21 },
    { name: "Luis Leal", guess: "2", id: 22 },
    { name: "Mayra Rodriguez y fam", guess: "2", id: 23 },
    { name: "Cecilia Mancillas", guess: "4", id: 24 },
    { name: "Esteban Reyes y fam", guess: "3", id: 25 },
    { name: "Maria Castro y fam", guess: "3", id: 26 },
    { name: "Caleb Reyes", guess: "1", id: 27 },
    { name: "Alexis Leija", guess: "1", id: 28 },
    { name: "Jesus Madrid", guess: "1", id: 29 },
    { name: "Luis Perez", guess: "2", id: 30 },
    { name: "Danyel Lopez y fam", guess: "4", id: 31 },
    { name: "Raul Treviño y fam", guess: "6", id: 32 },
    { name: "Heber Reyes y fam", guess: "5", id: 33 },
    { name: "Nancy Reyes y fam", guess: "5", id: 34 },
    { name: "Alejandra Reyes y fam", guess: "2", id: 35 },
    { name: "Guadalupe Reyes y fam", guess: "8", id: 36 },
    { name: "Oziel Reyes y fam", guess: "2", id: 37 },
    { name: "Claudia Reyes y fam", guess: "4", id: 38 },
    { name: "Karen Reyes y fam", guess: "4", id: 39 },
    { name: "Susy Reyes y fam", guess: "5", id: 40 },
    { name: "Lilia Gauna y fam", guess: "6", id: 41 },
    { name: "Jorge Gauna y fam", guess: "4", id: 42 },
    { name: "Juany Gauna y fam", guess: "4", id: 43 },
    { name: "Melissa Silva y fam", guess: "4", id: 44 },
    { name: "Jessica Samantha", guess: "2", id: 45 },
    { name: "Mayra Torres", guess: "2", id: 46 },
    { name: "Adelfina Barajas", guess: "2", id: 47 },
    { name: "Ana Karen Cano", guess: "2", id: 48 },
    { name: "Michelle Macias", guess: "2", id: 49 },
    { name: "Edgar Tovar", guess: "2", id: 50 },
    { name: "Teresa Ramirez", guess: "2", id: 51 },
    { name: "Angel Aquines", guess: "2", id: 52 },
    { name: "Jesus Gamboa", guess: "2", id: 53 },
    { name: "Alondra Garcia", guess: "2", id: 54 },
    { name: "Veronica Hernandez", guess: "2", id: 55 },
    { name: "Rosaura Alanis", guess: "1", id: 56 },
    { name: "Marlen Alvarado", guess: "2", id: 57 },
    { name: "Jesus Gauna y fam", guess: "8", id: 58 },
    { name: "Gustavo Mendez y fam", guess: "4", id: 59 },
    { name: "Any Unzueta", guess: "2", id: 60 },
    { name: "Bruno Mancillas y fam", guess: "3", id: 61 },
    { name: "Andrea Rodríguez", guess: "1", id: 62 },
    { name: "Mariela Torres", guess: "2", id: 63 },
    { name: "Victoria y familia", guess: "5", id: 64 },
    { name: "Ramon Sauceda", guess: "2", id: 65 }
];

  

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
            backgroundColor: peltaColores.color1,
            backgroundAttachment: isIOS ? "local" : "fixed",

            color: peltaColores.textColor ? peltaColores.textColor : "",
          }}
          className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
        >
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Código de vestimenta
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              {datosSeccionVestimenta.vestimenta
                ? datosSeccionVestimenta.vestimenta
                : "FORMAL"}
            </p>
            <br />
            {datosSeccionVestimenta.messageVestimenta ? (
              <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
                {datosSeccionVestimenta.messageVestimenta
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            ) : (
              ""
            )}

            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-left"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaMujer ||
                    "https://i.postimg.cc/QMLTPtBj/123123-removebg-preview.png"
                  }
                  className="h-[30vh]"
                  alt=""
                />
                <br />
                <p className="font-serif text-2xl">Mujeres</p>
              </div>
              <div
                className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                data-aos="fade-right"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaHombre ||
                    "https://i.postimg.cc/sXG6wmQC/10.png"
                  }
                  className="h-[30vh]"
                  alt=""
                />
                <br />
                <p className="font-serif text-2xl">Hombres</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
