import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./AhoraDespierta.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { Vestimenta } from "../../../components/Vestimenta";

export const XvFernandaZoe = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Fernanda Zoe",
      fechaDeBoda: "04 MAYO, 2024",
      fechaNewDate: "May 04, 2024 15:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/nLSqqqyY/42c58f68-9dfd-4766-b110-cf4fc27aa3bb.jpg",
      numeroNovia: "+525520680783",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#a9ffff",
      color2: "#ffabff",
    };

    
  
    const imagenesFondo ={
        fondoInvitados:'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
        fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
        vestimenta:"TEMÁTICA DE LOS 60's",
        urlVestimentaMujer:"https://i.postimg.cc/wTqQNxPV/50338456c9dbe78c7d5eca38c1759801.jpg",
        urlVestimentaHombre:"https://www.mentendencias.com/wp-content/uploads/variedad-de-calzado-y-estilos-en-los-anos-60.jpg"
      }
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Monserrat Lira!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "May 04, 2024 15:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/QtB1mHsL/5ccd25f2-b965-4081-8acb-943325a6c1ea.jpg",
      fotoDerecha: "https://i.postimg.cc/sgyPzjPS/69b1b6bd-c916-4bdc-89f9-2304aee6e40a.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de mi madre y mi madrina!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Madrina de la Quinceañera',
      esposaMadre: "Marisol Erenice Diaz Duron",
      esposaPadre: "David Israel García Silva",
      esposoMadre: "Dulce María Diaz Duron",
      esposoPadre: "",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 04 de Mayo de 2024.",
      ceremoniaHora: "3:00 p.m.",
      ceremoniaNombreLugar: "",
      ceremoniaLugarMap:
        "Calle Santander #45, Col. San Rafael, Azcapotzalco. CP. 2010",
      ceremoniaUrl:
        "https://maps.app.goo.gl/uvVV3GvN7eZhwuRE8",
      recepcionFecha: "Sábado, 04 de Mayo de 2024.",
      recepcionHora: "4:00 p.m.",
      recepcionNombreLugar: "",
      recepcionLugarMap:
        "Segunda Privada de Industria #33, Colonia Barrio Nextengo, CP. 02070, Alcaldía de Azcapotzalco",
      recepcionUrl:
        "https://maps.app.goo.gl/i7fMJyu4cHhxGQuu9",
    };
  
  
    const images = [
      "https://i.postimg.cc/R0Zc3gDZ/72344d65-a2e1-42a8-85d2-61f8f400d85b.jpg",
      "https://i.postimg.cc/MZMmbrKc/8e779405-f62e-4d80-8f7f-587c3db58f9b.jpg",
      "https://i.postimg.cc/nLSqqqyY/42c58f68-9dfd-4766-b110-cf4fc27aa3bb.jpg",
      "https://i.postimg.cc/QtB1mHsL/5ccd25f2-b965-4081-8acb-943325a6c1ea.jpg",
"https://i.postimg.cc/sgyPzjPS/69b1b6bd-c916-4bdc-89f9-2304aee6e40a.jpg",
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <Vestimenta
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
