import React, { useEffect, useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Slider from "react-slick";
import confetti from 'canvas-confetti';

export const ConfirmacionBoda = ({
  peltaColores,
  images,
  messageWhats,
  datosSeccion1,
  datosSeccionPotadaBoda
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const componentRef = useRef(null);

  // Definimos el estilo de la fuente
  const fontFamilyStyle = { fontFamily: datosSeccion1.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  const traducirTexto = (español, ingles) => datosSeccion1.ingles ? ingles : español;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
          observer.disconnect(); // Desconectar después de lanzar el confetti
        }
      },
      { threshold: 0.5 } // Lanzar cuando al menos el 50% del componente esté visible
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={componentRef}
      style={{
        backgroundImage: `url(${datosSeccion1.imgFondo || ""})`,
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccion1?.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col px-4 py-[5vh] justify-center items-center w-full"
    >
      <h1
        className="text-center sm:text-[32px] md:text-[42px]"
        data-aos="flip-up"
        style={fontFamilyStyle}
      >
        {datosSeccion1?.tituloConfirm?.split("\\n")?.map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
        {datosSeccion1?.tituloConfirmacion?.split("\\n")?.map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
      </h1>
      <br />
      <p
        className="text-center w-[70%] sm:text-[22px] md:text-[30px]"
        style={fontFamilyStyle}
      >
        {datosSeccion1.aviso.split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
      </p>
      <br />
      <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
        {images?.map((imageUrl, index) => (
          <div data-aos="fade-down-right" className="rolImg" key={index}>
            <img
              src={imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-auto rounded-xl max-h-[70vh]"
            />
          </div>
        ))}
      </Slider>
      <br />
      <br />
      <div
        data-aos="fade-down-left"
        className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
        style={fontFamilyStyle}
      >
        <h2 className="text-2xl font-bold mb-4 justify-center flex text-black">
          {traducirTexto(
            "Confirmación de Asistencia",
            "Attendance Confirmation"
          )}{" "}
          {datosSeccion1.textoConfirmacion}{" "}
          <FaWhatsapp className="text-green-600 ml-2" />
        </h2>

        {datosSeccion1.numeroNovio ? (
          <div className="flex justify-center items-center">
            <div className="w-full text-center bg-green-700 p-2 mt-5 mx-2 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccion1.numeroNovia
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white text-center md:w-[60%] md:text-xl text-auto"
              >
                {traducirTexto(
                  "Confirma tu invitación con",
                  "RSVP with"
                )}{" "}
                {datosSeccionPotadaBoda?.esposa || traducirTexto("la Novia", "the Bride")} 💌
              </a>
            </div>
            <div className="w-full text-center bg-green-700 p-2 mt-5 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccion1.numeroNovio
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white text-center md:w-[60%] md:text-xl text-auto"
              >
                {traducirTexto(
                  "Confirma tu invitación con",
                  "RSVP with"
                )}{" "}
                {datosSeccionPotadaBoda?.esposo || traducirTexto("el Novio", "the Groom")} 💌
              </a>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <div className="w-full text-center bg-green-700 p-2 mt-5 mx-2 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccion1?.numeroConfirmacion
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white text-center md:w-[60%] md:text-xl text-auto"
              >
                {traducirTexto("Confirmanos tu invitación", "Confirm your invitation")} 💌
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
