'use client'

import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import axios from "axios"
import { Copy, Share2, Eye } from "lucide-react"
import QRCode from "react-qr-code"
import { motion } from "framer-motion"

export default function LinkClient() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const idEvento = searchParams.get("id")

  const [loading, setLoading] = useState(false)
  const [dataEvento, setDataEvento] = useState({})
  const [link, setLink] = useState("")

  const fetchInvitados = async () => {
    try {
      setLoading(true)
      const responseEvento = await axios.get(`/api/Eventos/${idEvento}`)
      setDataEvento(responseEvento.data)
      setLoading(false)
      const eventoLink = `${window.location.origin}/${
        responseEvento.data.tipoEvento === "Boda" ? "boda-auto" : "xv-auto"
      }?id=${idEvento}`
      setLink(eventoLink)
    } catch (error) {
      setLoading(false)
      console.error("Error fetching invitados:", error)
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link)
    alert("Enlace copiado al portapapeles")
  }

  const shareWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`
    window.open(whatsappUrl, "_blank")
  }

  useEffect(() => {
    fetchInvitados()
  }, [])

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-4">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full bg-white rounded-3xl shadow-2xl overflow-hidden"
      >
        <div className="p-8">
          <div className="flex justify-center mb-6">
            <motion.img
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.1, duration: 0.3 }}
              src="https://i.postimg.cc/mgYMx0Zq/430144566-122120440238202986-3770862004602796662-n.jpg?height=90&width=90"
              alt="Logo"
              className="h-24 w-24"
            />
          </div>
          <h1 className="text-4xl font-extrabold text-black mb-2 text-center">
            Comparte tu invitación
          </h1>
          <p className="text-gray-700 mb-8 text-center">
            Escanea el código QR o usa los botones para compartir
          </p>
          
          {link && (
            <motion.a 
              href={link}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="block w-full px-6 py-3 bg-black text-white rounded-full transition duration-300 text-center mb-8 shadow-lg flex items-center justify-center"
            >
              <Eye className="mr-2 h-5 w-5" />
              Ver invitación
            </motion.a>
          )}
          
          <div className="flex justify-center mb-8">
            {link && (
              <motion.div 
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.3 }}
                className="p-4 bg-white rounded-2xl shadow-lg border-2 border-[#c9b066]"
              >
                <QRCode
                  size={200}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={link}
                  viewBox="0 0 200 200"
                />
              </motion.div>
            )}
          </div>
          
          <div className="flex flex-col gap-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={copyToClipboard}
              className="w-full px-6 py-3 bg-[#c9b066] text-black rounded-full transition duration-300 flex items-center justify-center shadow-lg font-semibold"
            >
              <Copy className="mr-2 h-5 w-5" /> Copiar Enlace
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={shareWhatsApp}
              className="w-full px-6 py-3 bg-black text-white rounded-full transition duration-300 flex items-center justify-center shadow-lg font-semibold"
            >
              <Share2 className="mr-2 h-5 w-5" /> Compartir en WhatsApp
            </motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  )
}