import React, { useEffect } from "react";
import { useState } from "react";
import { CSSTransition } from 'react-transition-group';
import Aos from "aos";

const ModalBodaNew = ({ isOpen, setIsOpen, datosSeccionPortada }) => {
  const [flapOpen, setFlapOpen] = useState(false);

  useEffect(() => {
    // Inicializa AOS
    Aos.init({ duration: 2000 });
  }, []);

  const toggleFlap = () => {
    setFlapOpen(!flapOpen);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <CSSTransition
        in={isOpen}
        timeout={2000}
        unmountOnExit
        appear
      >
        <div className="modal-co container">
          <div
            className={`envelope-wrapper ${flapOpen ? "flap" : ""}`}
            onClick={toggleFlap}
          >
            <div className="envelope">
              <div className="letter" style={{backgroundImage: `url(${datosSeccionPortada.imgPortada || ""})`}}>
                <div className="text">
                  <button
                    className="ver-invitacion-btn"
                    onClick={closeModal}
                  >
                    Ver Invitación
                  </button>
                </div>
              </div>
            </div>
            <div className="heart2"></div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ModalBodaNew;
