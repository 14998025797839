import { XvAlondraDaniela } from "../screens/XV/xv-diamante/XvAlondraDaniela";
import { XvCarmen } from "../screens/XV/xv-diamante/XvCarmen";
import { XvEjemplo } from "../screens/XV/xv-diamante/XvEjemplo";
import { XvEjemploNew } from "../screens/XV/xv-diamante/XvEjemploNew";
import { XvEstefaniaLira } from "../screens/XV/xv-diamante/XvEstefaniaLira";
import { XvValeriaEstefania } from "../screens/XV/xv-diamante/XvValeriaEstefania";
import { XvLunaSakura } from "../screens/XV/xv-diamante/XvLunaSakura";
import { XvArletteYRenata } from "../screens/XV/xv-diamante/XvArletteYRenata";
import { XvJoseAlberto } from "../screens/XV/xv-diamante/XvJoseAlberto";
import { XvLailaRamos } from "../screens/XV/xv-diamante/XvLailaRamos";
import { BabyShowerRebeca } from "../screens/XV/xv-diamante/BabyShowerRebeca";
import { XvMelissaMartinez } from "../screens/XV/xv-diamante/XvMelissaMartinez";
import { XvMonserrat } from "../screens/XV/xv-diamante/XvMonserrat";
import { XvBetzaliYBetzabeth } from "../screens/XV/xv-diamante/XvBetzaliYBetzabeth";
import { XvHeidiDaiana } from "../screens/XV/xv-diamante/XvHeidiDaiana";
import { XvIngridNicole } from "../screens/XV/xv-diamante/XvIngridNicole";
import { XvEjemploSinFoto } from "../screens/XV/xv-diamante/XvEjemploSinFoto";

const RoutesXvDiamante = [
    {
      path: '/evento-diamante-xv',
      element: <XvEjemplo />
    },
    {
      path: '/evento-diamante-xv-foto',
      element: <XvEjemploSinFoto />
    },
    {
      path: '/evento-diamante-xv-ejemplo',
      element: <XvEjemploNew />
    },
    {
      path: '/evento-diamante-xv-monserratLira',
      element: <XvEstefaniaLira />
    },
    {
      path: '/evento-diamante-xv-valeriaEstefania',
      element: <XvValeriaEstefania />
    },
    {
      path: '/evento-diamante-xv-carmen',
      element: <XvCarmen />
    },
    {
      path: '/evento-plata-xv-alondraDaniela',
      element: <XvAlondraDaniela />
    },
    {
      path: '/evento-diamante-xv-luna-sakura',
      element: <XvLunaSakura/>
    },
    {
      path: '/evento-diamante-xv-arlette&renata',
      element: <XvArletteYRenata/>
    },
    {
      path: '/evento-diamante-xv-jose-alberto',
      element: <XvJoseAlberto/>
    },
    {
      path: '/evento-diamante-xv-laila-ramos',
      element: <XvLailaRamos/>
    },
    {
      path: '/evento-diamante-baby-shower-rebeca',
      element: <BabyShowerRebeca/>
    },
    {
      path: '/evento-diamante-xv-melissa-martinez',
      element: <XvMelissaMartinez />
    },
    {
      path: '/evento-diamante-xv-monserrat',
      element: <XvMonserrat />
    },
    {
      path: '/evento-diamante-xv-betzali&betzabeth',
      element: <XvBetzaliYBetzabeth />
    },
    {
      path: '/evento-diamante-xv-heidi-daiana',
      element: <XvHeidiDaiana />
    },
    {
      path: "/evento-diamante-xv-ingrid-nicole",
      element: <XvIngridNicole />,
    },

   
]

export default RoutesXvDiamante;