import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "../../bodas/Boda-diamante/assets/maroon.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvEjemplo = () => {
  const [messageWhats, setMessageWhats] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");

  const datosSeccion1 = {
    textoConfirmacion: "a los XV",
    nombre: "Jimena Torres",
    fechaDeBoda: "12 DIC, 2024",
    fechaNewDate: "December 12, 2024 00:00:00 GMT+00:00",
    imgPortada:
      "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
    numeroNovia: "4491516931",
    numeroNovio: "4491516931",
  };

  const peltaColores = {
    color1: "#fff5ff",
    color2: "#fffbff",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
  };

  const datosSeccion2 = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hay momentos en la vida que imaginamos, soñamos y esperamos, uno de esos momentos ha llegado y lo quiero compartir contigo.",
    fechaNewDate: "December 12, 2024 00:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
    fotoDerecha: "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor, apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinciañera",
    tituloDerecha: "Padrinos de la Quinciañera",
    esposaMadre: "Amparo Martha Cuellar González",
    esposaPadre: "Víctor Eduardo Cabrera Solana",
    esposoMadre: "Guadalupe Victoria Gómez",
    esposoPadre: "Eduardo Jaimes Zapata",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 12 de Diciembre de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Parroquia de San Miguel Arcángel.",
    ceremoniaLugarMap:
      "Principal S/N, Zona Centro, Centro, 37700 San Miguel de Allende, Gto.",
    ceremoniaUrl:
      "https://www.google.com/maps/place/20+de+Noviembre+1646,+El+Raicero,+47892+Ocotlán,+Jal./data=!4m2!3m1!1s0x842edf3d76b78b79:0x62ccb9b21a16a38d?sa=X&ved=2ahUKEwjJiZK8nNeDAxUDM0QIHdn-BnEQ8gF6BAgKEAA",
    recepcionFecha: "Sábado, 12 de Diciembre de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Los Jardines.",
    recepcionLugarMap:
      "Calle Río Mocorito 467, Colonia Prohogar, 21210 Mexicali, B.C.",
    recepcionUrl:
      "https://www.google.com/maps/place/20+de+Noviembre+1646,+El+Raicero,+47892+Ocotlán,+Jal./data=!4m2!3m1!1s0x842edf3d76b78b79:0x62ccb9b21a16a38d?sa=X&ved=2ahUKEwjJiZK8nNeDAxUDM0QIHdn-BnEQ8gF6BAgKEAA",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkAmazon:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    linkLiverpool:
      "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
  };

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");

  const images = [
    "https://i.postimg.cc/MZ3t62Ly/435af0ee-b3c0-425b-b966-446fe26e78d5.jpg",
    "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
    "https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg",
    "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
    "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg"
    // Add more image URLs as needed
  ];

  const imagenesFondo = {
    fondoInvitados:'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
    fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
  };

  const names = [
    { name: "Angel Jaimes", guess: "2" },
    { name: "Samuel Arroyo", guess: "3" },
  ];


  useEffect(() => {
    const findName = names.find((e) => e.name == nameId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <ModalXvCard isOpen={isOpen} setIsOpen={setIsOpen} datosSeccionPortada={datosSeccion1}/>
      <Portada datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

      <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

      <Invitados
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        imagenesFondo={imagenesFondo}
        datosInvitados={datosInvitados}
      />

      <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

      <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

      <Vestimenta peltaColores={peltaColores} imagenesFondo={imagenesFondo} />

      <Programa peltaColores={peltaColores} datosSeccion6={datosSeccion6} />

      <Hospedaje peltaColores={peltaColores} datosSeccion7={datosSeccion7} />

      <Regalos peltaColores={peltaColores} datosSeccion8={datosSeccion8} />

      <Confirmacion
        peltaColores={peltaColores}
        images={images}
        messageWhats={messageWhats}
        datosSeccion1={datosSeccion1}
      />
    </div>
  );
};
