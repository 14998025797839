import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./RapunzelVoz.mp3";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";

export const XvDannaFernanda = () => {
    const [isOpen, setIsOpen] = useState(false);

    const peltaColores = {
      color1: "#ffe3ff",
      color2: "#96c2ff",
    };
  
    const datosSeccionPortada = {
      cancion:audioFondo,
      imgPortada:
        "https://i.postimg.cc/NfZzPqLP/Whats-App-Image-2024-04-21-at-6-56-47-PM.jpg",
      nombre: "Danna Fernanda",
      fechaDeBoda: "08 JUNIO, 2024",
      titulo: "NO FALTES A MIS XV",
    };
  
    const datosSeccionConfirmacion = {
      tituloConfirmacion:
        "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
      aviso: "",
      numeroConfirmacion: "+526564434484",
      textoConfirmacion: "a los XV",
      textWhatsApp: "",
      textColor: "",
      imgFondo: "https://i.postimg.cc/6q8xRjpB/Invitación_para_15_años_moderna_ilustrada_mariposas_morada.png",
      textColor: "",
    };
  
    const datosSeccionContador = {
      titulo: "¡Mis XV años!",
      descipcion:
        "Hoy en este día tan especial, primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar este sueño. \\nHoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "June 08, 2024 19:00:00 GMT+00:00",
      fotoIzquierda:
        "https://i.postimg.cc/T2NTyRj4/36-FFD7-B8-3-D79-4170-9-FF2-63594-D9-FD165.png",
      fotoDerecha:
        "https://images.vexels.com/media/users/3/194543/isolated/preview/56affe8e232e558cad64be081dcf5677-adorno-de-trazo-de-vestido-de-quinceanera.png",
      marcoEnable: true,
      imgFondo: "",
      textColor: "",
      contador: true,
    };
  
    const datosSeccionPadres = {
      titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
      tituloIzquierda: "Padres de la Quinceañera",
      tituloDerecha: "Padrinos de la Quinceañera",
      madre: "Diana Martinez",
      padre: "Daniel Reyes",
      madrina: "Ishel Bernal",
      padrino: "Alejandro Gaytan",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionCeremonia = {
      dobleEnable: false,
      ceremoniaEnable: false,
      ceremoniaFecha: "Sábado, 23 de Diciembre de 2024.",
      ceremoniaHora: "6:00 p.m.",
      ceremoniaNombreLugar: "Parroquia del Divino Salvador",
      ceremoniaLugarMap:
        "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
      ceremoniaUrl:
        "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
      recepcionEnable: true,
      recepcionFecha: "Sábado, 08 de Junio de 2024.",
      recepcionHora: "7:00 p.m.",
      recepcionNombreLugar: "Hacienda el Patrón",
      recepcionLugarMap:
        "C. Mesa Central, Manuel J. Clouthier, 32575 Juárez, Chih.",
      recepcionUrl:
        "https://maps.app.goo.gl/Hzr3tJzWFt7AMbqL8",
      imgFondo: "https://i.postimg.cc/6q8xRjpB/Invitación_para_15_años_moderna_ilustrada_mariposas_morada.png",
      textColor: "",
    };
  
    const datosSeccionVestimenta = {
      vestimenta: "SEMI FORMAL",
      messageVestimenta: "",
      urlVestimentaMujer: "",
      urlVestimentaHombre: "",
      imgFondo:
        "https://i.postimg.cc/6q8xRjpB/Invitación_para_15_años_moderna_ilustrada_mariposas_morada.png",
      textColor: "",
    };
  
    const datosSeccionPrograma = {
      ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
      recepcion: "Tómate fotos con nosotros",
      baileXV: "No te pierdas mi baile de XV años",
      cena: "Disfruta de una deliciosa cena",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "Sacale brillo a la pista",
    };
  
    const datosSeccionHospedaje = {
      disponibleDos: false,
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
      imgFondo: "",
      textColor: "",
    };
  
    const datosSeccionRegalos = {
      cantidadRegalos: 3,
      titulo: "Mesa de regalos",
      descripcion:
        "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
      link1: "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      imgLink1: "",
      link1Enable: true,
      link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
      imgLink2: "",
      link2Enable: true,
      link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
      imgLink3: "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
      link3Enable: false,
      imgFondo:"",
      textColor:""
    };
  
    const images = [
        "https://i.postimg.cc/qMFWrqcp/8-D07-C129-BA61-4-AB2-AAFA-B2164-E4-BA24-A.png",
        "https://i.postimg.cc/T2NTyRj4/36-FFD7-B8-3-D79-4170-9-FF2-63594-D9-FD165.png",
        "https://images.vexels.com/media/users/3/194543/isolated/preview/56affe8e232e558cad64be081dcf5677-adorno-de-trazo-de-vestido-de-quinceanera.png",
        "https://cdn-icons-png.flaticon.com/512/10953/10953218.png",
      // Add more image URLs as needed
    ];
  
    const contador = true;
    const padres = true;
    const ceremonia = true;
    const vestimenta = true;
    const programa = false;
    const hospedaje = false;
    const confirmacion = true;
    const Regalos = false;
    const modal = true;
  
    useEffect(() => {
      setIsOpen(true);
      AOS.init({ duration: 2000 });
    }, []);
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
          {
          modal? (
            <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} />
          ):("")
        }
        <PortadaXvComponent
          datosSeccionPortada={datosSeccionPortada}
        />
        {contador ? (
          <ContadorXvComponent
            peltaColores={peltaColores}
            datosSeccionContador={datosSeccionContador}
          />
        ) : (
          ""
        )}
  
        {padres ? (
          <PadresXvComponent
            peltaColores={peltaColores}
            datosSeccionPadres={datosSeccionPadres}
          />
        ) : (
          ""
        )}
  
        {ceremonia ? (
          <CeremoniaXvComponent
            peltaColores={peltaColores}
            datosSeccionCeremonia={datosSeccionCeremonia}
          />
        ) : (
          ""
        )}
        {vestimenta ? (
          <VestimentaXvComponent
            peltaColores={peltaColores}
            datosSeccionVestimenta={datosSeccionVestimenta}
          />
        ) : (
          ""
        )}
  
        {programa ? (
          <ProgramaXvComponent
            peltaColores={peltaColores}
            datosSeccionPrograma={datosSeccionPrograma}
          />
        ) : (
          ""
        )}
  
        {hospedaje ? (
          <HospedajeXvComponent
            peltaColores={peltaColores}
            datosSeccionHospedaje={datosSeccionHospedaje}
          />
        ) : (
          ""
        )}
  
        {Regalos ? (
          <RegalosXvComponent
            peltaColores={peltaColores}
            datosSeccionRegalos={datosSeccionRegalos}
          />
        ) : (
          ""
        )}
  
        {confirmacion ? (
          <ConfirmacionXvComponent
            peltaColores={peltaColores}
            images={images}
            datosSeccionConfirmacion={datosSeccionConfirmacion}
          />
        ) : (
          ""
        )}
      </div>
    );
}
