import axios from 'axios';

export const validateToken = async () => {
  try {
    // Obtener el objeto tokenData desde localStorage y convertirlo en un objeto
    const storedTokenData = JSON.parse(localStorage.getItem('token'));

    // Si no existe el token, redirigir al login
    if (!storedTokenData.token) {
      window.location.href = "/admin-login";
      return;
    }

    // Validar el token con la API
    await axios.get(`/api/Admins/token?token=${storedTokenData.token}`);

    return storedTokenData.admin; // Retornar el nombre
  } catch (error) {
    // Si hay un error (token inválido o expirado), redirigir al login
    console.error('Error validando el token:', error);
    window.location.href = "/admin-login";
  }
};
