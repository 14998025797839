import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import imgFlores from "./assets/floresDiamante.png";
import audioFondo from "./assets/ThousandYearsPiano.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import { MdLinkedCamera } from "react-icons/md";

export const BodaBetsyYAlex = () => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion:'a nuestra boda',
    esposo: "Alex",
    esposa: "Betsy",
    fechaDeBoda: "31 AGO, 2024",
    fechaNewDate: "AUGUST 31, 2024 00:00:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/ZRJTG2Yc/MG-807044.jpg",
    numeroNovia: "+524521329527",
    numeroNovio: "+4491929055",
    novios:true,
    titulo:"",
    aviso:" ¡Tienes hasta el 20 de Julio para confirmar tu asistencia! \\n\\nEvento solo para adultos."
  };
 
  const peltaColores = {
    color1: "#e7e3e3",
    color2: "#f8f2f2",
    messageVestimenta:""
  };

  const datosSeccion2 = {
    titulo:'¡Nos Casamos!',
    descipcion:
      "Juntos, con nuestros corazones llenos de amor, damos el paso hacia un futuro compartido. Te esperamos para compartir este momento tan especial.",
    fechaNewDate: "August 31, 2024 19:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/bvzWphbs/MG-807045.jpg",
    fotoDerecha: "https://i.postimg.cc/CxxYsXLT/d22a08f8-b484-4dbc-9742-4d5d2dc51a8d.jpg",
  };

  const datosInvitados ={
    titulo:'¡Has sido invitado a la boda de Betsy y Alex!',
    entradasText:'disfrutar nuestro dia especial',
    colorFondoName:"#b3abab"
  }

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda:'Padres de la novia',
    tituloDerecha:'Padres del novio',
    esposaMadre: "Gloria Elena Luviano Ponce",
    esposaPadre: "José Gpe. Bucio Bucio",
    esposoMadre: "Adriana López Díaz",
    esposoPadre: "Alejandro Martínez Hernández ",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Sábado, 31 de Agosto de 2024.",
    ceremoniaHora: "4:30 p.m.",
    ceremoniaNombreLugar: "Parroquia de Cristo Rey",
    ceremoniaLugarMap: "Avenida Francisco Sarabia 40, Morelos, 60050 Uruapan, Mich.",
    ceremoniaUrl: "https://maps.app.goo.gl/ci3jGbfcWUD7quSD8",
    recepcionFecha: "Sábado, 31 de Agosto de 2024.",
    recepcionHora: "7:00 p.m.",
    recepcionNombreLugar: "Isla de Angelí Campestre",
    recepcionLugarMap:
      "Carretera Taretan - Uruapan 2000 A, 60220 Uruapan, Mich.",
    recepcionUrl: "https://maps.app.goo.gl/JUv3E5YJr6ptspdk9",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Momentos mágicos en la cámara 360",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Hotel Mi Solar",
    hotel1lugar:
      "Juan Delgado 10, Centro, 60000 Uruapan, Mich.",
    hotel1url:
      "https://maps.app.goo.gl/QEUrKBoCt5HwfFXz8",
    hotel2Titulo: "Hotel Continental",
    hotel2lugar:
      "Nicolas Bravo 31, Centro, 60000 Uruapan, Mich.",
    hotel2url:
      "https://maps.app.goo.gl/Wd8ZLNySwD8NFS5j7",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkAmazon:
      "https://www.amazon.com.mx/wedding/registry/3PZF3D5T3RQLH",
    linkLiverpool:
      "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51353262",
  };

  const imagenesFondo ={
    fondoInvitados:'url("https://i.postimg.cc/2yMd94d4/MG-807059.jpg"',
    fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
  }

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [pases, setPases] = useState(0);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/65cwSHnB/Whats-App-Image-2024-04-17-at-1-36-48-PM.jpg  ",
    "https://i.postimg.cc/5NQSYR1p/MG-807017.jpg https://i.postimg.cc/QtNqFNxW/MG-807029.jpg ",
    "https://i.postimg.cc/ydfycqkp/MG-807036.jpg ",
    "https://i.postimg.cc/ZRJTG2Yc/MG-807044.jpg",
    "https://i.postimg.cc/QtNqFNxW/MG-807029.jpg",
    "https://i.postimg.cc/7hfMNBzH/MG-807056.jpg"
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const names = [
    { name: "Angel Jaimes", guess: "2" },
    { name: "Kenia y Jorge", guess: "2" },
    { name: "Mariana y Diego", guess: "2" },
    { name: "Armando y Arlen", guess: "2" },
    { name: "Pablo y Fanny", guess: "2" },
    { name: "Ángel y Cinthia", guess: "2" },
    { name: "Male y Carlos", guess: "2" },
    { name: "Diana y Luis", guess: "2" },
    { name: "Andrea y Jafet", guess: "2" },
    { name: "Luis Garcia", guess: "2" },
    { name: "Familia Bucio Solis", guess: "2" },
    { name: "Familia Luviano Gonzalez", guess: "5" },
    { name: "Familia Méndez Chávez", guess: "5" },
    { name: "Familia Melendez Soto", guess: "4" },
    { name: "Rosy Melendez", guess: "2" },
    { name: "Familia Bucio", guess: "6" },
    { name: "Dani Flores", guess: "2" },
    { name: "USAER 49", guess: "2" },
    { name: "Familia Bucio Bucio", guess: "4" },
    { name: "Familia Martínez Rodriguez", guess: "2" },
    { name: "Alma y Fernando", guess: "2" },
    { name: "Salvador Martínez", guess: "2" },
    { name: "Miguel y Genesis", guess: "2" },
    { name: "Anabel Nieto y Jesús Romo", guess: "2" },
    { name: "Familia Martínez Lopéz", guess: "4" },
    { name: "Familia Bucio Luviano", guess: "4" },
    { name: "José Raul", guess: "2" },
    { name: "Carlos y Fabiola", guess: "2" },
    { name: "Humerto", guess: "2" },
    { name: "Martha Gamboa", guess: "5" },
    { name: "Mtro. Carlos y Mtra. Cuquis", guess: "2" },
    { name: "Alejandro Duarte", guess: "2" },
    { name: "Edgar Licón", guess: "1" },
    { name: "Directora Anacristi", guess: "2" },
    { name: "Vane Reyes", guess: "2" },
    { name: "Invitado Especial", guess: "1" },
    { name: "Invitado Especial-2", guess: "2" },
    { name: "Invitado especial", guess: "3" },
    { name: "Invitado EspeciaI", guess: "4" },
    { name: ".Invitado Especial-5", guess: "5" },
    { name: ".Invitado Especial", guess: "6" },
    { name: "Mari Luviano", guess: "5" }
    
  ]


  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    console.log(nameId, numPases);
    setPases(numPases);

    const findName = names.find((e) => e.name === nameId);

    if (findName) {
      console.log(findName.name);

      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      console.log("Nombre no encontrado");
      window.location.href = "/";
    }

    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);

    const audioElement = document.getElementById("backgroundMusic");

    if (!isMusicPlaying && audioElement) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
  };

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBoda
      datosSeccion1 ={datosSeccion1}
      audioFondo = {audioFondo}
      />

      <Contador
        peltaColores = {peltaColores}
        datosSeccion2 = {datosSeccion2}
      />

      <Invitados
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        imagenesFondo={imagenesFondo}
        datosInvitados={datosInvitados}
      />

      <Padres
        peltaColores={peltaColores}
        datosSeccion3={datosSeccion3}
      />
      
      <Ceremonia
        peltaColores={peltaColores}
        datosSeccion4={datosSeccion4}
      />

<div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Ubicaciones
          </h1>
          <br />

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
            <div
              className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundImage:
                  'url("https://lh5.googleusercontent.com/p/AF1QipNbpb1PyjyM_kain6QPCS_Y8KPbii1V_5pZA-53=w408-h544-k-no")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              >
              </div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.864425230528!2d-102.0544783!3d19.4182629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de2692c41341d%3A0x89e19b564ffbe984!2sParroquia%20de%20Cristo%20Rey!5e0!3m2!1ses!2smx!4v1713504730365!5m2!1ses!2smx"
                  width="100%"
                  height="50%"
                  className="fixed"
                ></iframe>
                <a
                  href={datosSeccion4.ceremoniaUrl}
                  class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
                >
                  Mapa de la Ceremonia
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              </div>
            </div>
            <div
              className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
              data-aos="fade-right"
            >
               <div
                style={{
                  backgroundImage:
                  'url("https://lh5.googleusercontent.com/p/AF1QipOWLobGjAYmoO8mnwmzXKDzr9K65x5nCn2lSiJE=w408-h306-k-no")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  backgroundPosition: "center center",
                }}
                className="min-h-[50%] w-full flex flex-col justify-end"
              >
              </div>
              <div className="min-h-[50%] w-full flex flex-col justify-end">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.8424774442065!2d-101.9945433!3d19.4192109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de39087931ad5%3A0x61d79cbfc24329a2!2sIsla%20de%20Angeli%20Campestre!5e0!3m2!1ses!2smx!4v1713504772277!5m2!1ses!2smx"
                width="100%"
                height="50%"
                className="fixed"
              ></iframe>
              <a
                href={datosSeccion4.recepcionUrl}
                class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
              >
                Mapa de la Recepción
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            </div>
          </div>
        </div>
      </div>

      <Vestimenta
        peltaColores={peltaColores}
        imagenesFondo={imagenesFondo}
      />

      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] py-[5vh] flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <PiChurchLight className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                CEREMONIA RELIGIOSA
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              
              <br />
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <br />
              <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">RECEPCIÓN</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <MdLinkedCamera  className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CAMARA 360</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.coctel}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>

      <Hospedaje
        peltaColores={peltaColores}
        datosSeccion7={datosSeccion7}
      />

<div
        style={{
          backgroundImage:datosSeccion8.imgFondo || "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-autoflex flex-col py-[5vh] px-3 items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[90%] pt-[8vh]">
            <div
              style={{
                backgroundImage:
                  'url("https://static.vecteezy.com/system/resources/previews/019/766/223/non_2x/amazon-logo-amazon-icon-transparent-free-png.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos="fade-right"
            >
              <a
                href={datosSeccion8.linkAmazon}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mesa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              <a
                href={datosSeccion8.linkLiverpool}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mesa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div
              style={{
                backgroundImage:
                  'url("https://i.postimg.cc/0y2xV1f9/Historia-de-Instagram-Lunes-Positivo-Minimalista-beige.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "115% 110%",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[90%] md:mt-6 rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4"
              data-aos="fade-right"
            >
            </div>
          </div>
        </div>
      </div>

      <ConfirmacionBoda
        peltaColores={peltaColores}
        images={images}
        messageWhats={messageWhats}
        datosSeccion1={datosSeccion1}
      />

    </div>
  );
};
