import React from "react";
import { GiPartyPopper } from "react-icons/gi";
import { LuPartyPopper } from "react-icons/lu";
import { BiSolidParty } from "react-icons/bi";



export const LugarfiestaXvComponent = ({ peltaColores, datosSeccionCeremonia }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionCeremonia.textColor || "",
      }}
      className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-${
            datosSeccionCeremonia.dobleEnable ? "1" : "2"
          } w-[80%] pt-[10vh]`}
        >
          {datosSeccionCeremonia.dobleEnable ? (
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <div className="flex">
                <LuPartyPopper className="text-[80px]" />
              </div>
              <br />
              <p className="font-serif text-2xl">Fiesta</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl">
                {datosSeccionCeremonia.ceremoniaFecha}
              </p>
              <p className="font-serif text-xl text-center">
                {datosSeccionCeremonia.ceremoniaHora}
              </p>
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccionCeremonia.ceremoniaNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccionCeremonia.ceremoniaLugarMap}
              </p>
              <br />
              <a
                href={datosSeccionCeremonia.ceremoniaUrl}
                class="overflow-hidden text-center relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
