import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";

export const EventoEjemplo = () => {
    const datosSeccion1 ={
        nombre:"Angel Jaimes",
        fechaDeBoda: '12 DIC, 2024',
        fechaNewDate:'December 12, 2024 00:00:00 GMT+00:00',
        textoInicio:'NO FALTES A MI CUMPLEAÑOS',
        imgPortada:"https://i.postimg.cc/0ySgMfjQ/IMG-2521.jpg",
      }
      
      const datosSeccion2 ={
        descipcion:'¡Celebra conmigo en mi cumpleaños! Tu presencia hace que este día sea aún más especial. ¡Espero verte allí!',
        fechaNewDate:'December 12, 2024 00:00:00 GMT+00:00',
        titulo:"¡Te esperamos!",
        fotoIzquierda:"https://i.postimg.cc/k4qddbn5/IMG-2120.jpg"
      }
      
      
      const datosSeccion7 ={
        descripcion:'Te esperamos en el antro para festejar mi incleible cumpleaños, no faltes.',
        hotel1Titulo:'ROD ROOM',
        hotel1lugar:'Av Independencia 1703-local c, Desarrollo Especial Galerías, 20116 Aguascalientes, Ags.',
        hotel1url:'https://www.google.com.mx/maps/place/Rod+Room/@21.9196132,-102.2987756,17z/data=!3m1!4b1!4m6!3m5!1s0x8429ef0ae655fca5:0x29abf7756610802f!8m2!3d21.9196082!4d-102.2962007!16s%2Fg%2F11s2q9548z?entry=ttu',
      }

      const plantillaColores ={
        color1:"#c7e0ff",
        color2:"#eff6ff",
        color3:"#"
      }
    
      const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
      const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    
      const [name, setName] = useState("");
      const [guests, setGuests] = useState(1);
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Assuming you have some logic to format the message for WhatsApp
        const message = `Hola, soy ${name} y confirmo mi asistencia a la boda con ${guests} invitado(s). ¡Nos vemos allí!`;
    
        // Replace with the actual WhatsApp API URL and encode the message
        const whatsappUrl = `https://api.whatsapp.com/send?phone=4491516931&text=${encodeURIComponent(
          message
        )}`;
    
        // Redirect to the WhatsApp URL
        window.location.href = whatsappUrl;
      };
    
      function calculateTimeRemaining() {
        const now = new Date().getTime();
        const difference = targetDate - now;
    
        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    
          return {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          };
        } else {
          return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
        }
      }
    
      const images = [
        "https://i.postimg.cc/TYFM3SRF/foto5.png","https://i.postimg.cc/W1Rw56Nx/IMG-5608.jpg"
        // Add more image URLs as needed
      ];
    
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        cssEase: "linear"
      };
    
      useEffect(() => {
        AOS.init({ duration: 2000 });
        const interval = setInterval(() => {
          setTimeRemaining(calculateTimeRemaining());
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);
      return (
        <div className="w-full overflow-x-hidden bg-neutral-500">
          <div
            style={{
              backgroundImage: `url(${datosSeccion1.imgPortada})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh", // Ajusta la altura del contenedor
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-slate-950 flex flex-col justify-center items-center w-full"
          >
            <div
              className="flex flex-col justify-center h-full w-full items-center pt-[40vh]"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <h1
                className="font-serif sm:text-[52px] md:text-[132px] text-white text-center flex"
                data-aos="flip-up"
              >
                {datosSeccion1.nombre}
              </h1>
              <br />
              <p
                className="text-white p-3 rounded-lg font-serif text-2xl text-center"
                data-aos="fade-up"
                // style={{
                //   background: "rgb(0,0,0)",
                //   background:
                //     "linear-gradient(0deg, rgba(0,0,0,0.7596288515406162) 28%, rgba(0,0,0,0.7596288515406162) 68%)",
                // }}
              >
                - {datosSeccion1.textoInicio} - <br /> 12 DIC, 2024
              </p>
              <br />
            </div>
          </div>
          <div
            style={{
              // backgroundImage:
              //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:plantillaColores.color1
            }}
            className="sm:h-[180vh] md:h-[120vh] flex flex-col items-center w-full p-2"
          >
            {/* =========================================================================================================================================== */}
            <div className="flex flex-col pt-[10vh] w-full items-center">
              <h1
                className="font-serif sm:text-[52px] md:text-[92px] "
                data-aos="flip-up"
              >
                {datosSeccion2.titulo}
              </h1>
              <br />
              <p
                className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
                data-aos="flip-up"
              >
                {datosSeccion2.descipcion}
              </p>
              <div className="grid sm:grid-cols-1 items-center md:grid-cols-2 w-[80%] pt-[8vh]">
                <div
                  style={{
                    backgroundImage:
                       `url(${datosSeccion2.fotoIzquierda})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "45vh", // Ajusta la altura del contenedor
                    display: "flex",
                    backgroundPosition: "center center",
                    width: "90%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="rounded-[30%]"
                  data-aos="fade-right"
                ></div>
                <div
                  className="flex p-3 rounded-lg justify-center items-center mt-10"
                  data-aos="fade-up"
                  style={{
                    border: "10px solid transparent",
                    padding: "15px",
                    borderImage:
                      "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')",
                    borderImageSlice: "46",
                    borderImageWidth: "round",
                  }}
                >
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">{timeRemaining.days}</p>
                    <p className="font-serif sm:text-xl md:text-2xl">Dias</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">{timeRemaining.hours}</p>
                    <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">{timeRemaining.minutes}</p>
                    <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">{timeRemaining.seconds}</p>
                    <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              // backgroundImage:
              //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:plantillaColores.color2
            }}
            className="sm:h-[130vh] md:h-[100vh] flex flex-col items-center w-full"
          >
            {/* =========================================================================================================================================== */}
            <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Lugar del Evento
              </h1>
              <br />
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                {datosSeccion7.descripcion}
              </p>
    
              <div className="flex justify-center items-center w-[80%] pt-[8vh]">
                <div
                  className="flex flex-col justify-center sm:w-[100%] md:w-[60%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
                  data-aos="fade-right"
                >
                  <h1 className="font-serif text-center sm:text-xl md:text-[23px] text-red-800">
                {datosSeccion7.hotel1Titulo}
                  </h1>
                  <br />
                  <p className="font-serif text-center sm:text-xl md:text-[23px]  w-[80%]">
                {datosSeccion7.hotel1lugar}
                  </p>
                  <br />
                  <br />
                  <a href={datosSeccion7.hotel1url} class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce">
                    Ver mapa!
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
    
          <div
            style={{
            // backgroundImage:
            //   'url("https://static.vecteezy.com/system/resources/thumbnails/009/657/375/small/natural-branch-watercolor-png.png")',
            //   backgroundRepeat: "repeat",
            //   backgroundSize: "25%", // or "100% auto"
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:plantillaColores.color1
            }}
            className="sm:h-[150vh] md:h-[150vh] flex flex-col justify-center items-center w-full"
          >
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              ¡Te espero para festejar mis 22 años!
            </h1>
            <br />
            <br />
            <br/>
            <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
              {images?.map((imageUrl, index) => (
                <div  data-aos="fade-down-right" className="rolImg" key={index}>
                  <img
                    src={imageUrl}
                    alt={`Slide ${index + 1}`}
                    className="w-auto rounded-xl max-h-[50vh]"
                  />
                </div>
              ))}
            </Slider>
            <br />
            <br />
            <div data-aos="fade-down-left" className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4 justify-center flex">
                Confirmación de Asistencia al Evento{" "}
                <FaWhatsapp className="text-green-600 ml-2" />
              </h2>
              <form onSubmit={handleSubmit}>
                <label className="mb-2 flex">
                  <span className="text-gray-700">Nombre:</span>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                    required
                  />
                </label>
                <br />
                <label className="flex mb-2">
                  <span className="text-gray-700">Invitados:</span>
                  <input
                    type="number"
                    value={guests}
                    onChange={(e) => setGuests(e.target.value)}
                    className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
                    min={1}
                    required
                  />
                </label>
                <br />
                <button
                  type="submit"
                  className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                >
                  Confirmar Asistencia
                </button>
              </form>
            </div>
          </div>
        </div>
      );
}
