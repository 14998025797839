
import { useState } from 'react';
import './ComboBox.css';

export const ComboBox = ({ field, values, preset, setFieldValue }) => {
    var fileField = `${field}File`;
    var textField = `${field}Img`;
    var src = values[field];
    var [value, setValue] = useState(src);
	var selectItems = [];
    console.log(preset);
    if(preset === true)
    {
        selectItems = [
                    {
              label: "Sin Fondo de Texturas",
              value: "",
            },
            {
              label: "Fondo Flores Moradas",
              value:
                "https://digitalrsvp.mx/api/Images/884e3829-7d3a-4ea5-8bc8-cd03e9a1e3ea",
            },
            {
              label: "Fondo Mexicano",
              value:
                "https://digitalrsvp.mx/api/Images/0fff99be-83e0-41b0-abc0-12fa29167a1c",
            },
            {
              label: "Fondo Plateado",
              value:
                "https://digitalrsvp.mx/api/Images/76e3850a-fb7b-4621-b3f4-3cd0eb1b5cef",
            },
            {
              label: "Fondo Dorado",
              value: "https://digitalrsvp.mx/api/Images/3a73ee2d-ffd7-4250-8a1c-5dd97d7b1264",
            },
            {
              label: "Fondo Marmoleado",
              value:
                "https://digitalrsvp.mx/api/Images/ac65eeb8-5eb3-4223-9ddf-0e1292e1a9c6",
            },
            {
              label: "Fondo Marmoleado Dorado",
              value:
                "https://digitalrsvp.mx/api/Images/a33877c9-dd6e-4cc0-ba91-9b09ac59fa60",
            },
            {
              label: "Fondo Marmoleado Claro",
              value:
                "https://digitalrsvp.mx/api/Images/ebdf3678-b771-4453-bfac-bb1bb942ad76",
            },
            {
              label: "Fondo Graduación",
              value:
                "https://digitalrsvp.mx/api/Images/c22bf00a-9bd8-4cdc-ba94-fc7e2c57fe2f",
            },
            {
              label: "Fondo Sutil Plantas",
              value:
                "https://digitalrsvp.mx/api/Images/ac26212c-8bcb-49dd-8355-1fd7cc07cfc5",
            },
            {
              label: "Fondo Verde Oscuro",
              value:
                "https://digitalrsvp.mx/api/Images/004118d3-c57b-4dd7-9359-00824d74e82b",
            },
            {
              label: "Fondo Morado con Mariposas",
              value:
                "https://digitalrsvp.mx/api/Images/6bbb5249-1fe2-4324-a189-db5f43193451    ",
            },
        {
              label: "Fondo Flores",
              value:
                "https://digitalrsvp.mx/api/Images/fdad2717-8a9a-4b6c-bdea-f0a4802cd092",
            },
            {
              label: "Fondo Rosas",
              value:
                "https://digitalrsvp.mx/api/Images/ba928da6-dac3-4f51-bee4-c81fe53d59f3",
            },
            {
              label: "Fondo Fresas",
              value: "https://digitalrsvp.mx/api/Images/8b1de41c-d820-4bd7-9df9-73545d0c6bbd",
            },
            {
              label: "Fondo Negro con Dorado",
              value:
                "https://digitalrsvp.mx/api/Images/9d7f01c9-81a4-4316-9166-cdfa72079fc4",
            },
            {
              label: "Fondo Toques Dorado",
              value:
                "https://digitalrsvp.mx/api/Images/30879c71-8071-4d03-92ce-de71697bb31e",
            },
            {
              label: "Fondo Playa",
              value:
                "https://digitalrsvp.mx/api/Images/0f7832c4-59e3-4de1-947b-bbd6401789ec",
            }
        ];
        console.log(selectItems);
        if(typeof(src) == 'string')
        {
            if(src.trim() != ""){
                if(!selectItems.some(x=> x.value == src)){
                    selectItems.push({ label: 'Imagen Original', value: src });
                }
            }
        }
        
    }
    else
    {
        if(typeof(src) == 'string')
        {
            if(src.trim() != ""){
                selectItems.push({ label: 'Original', value: src });
            }
        }
    }

    const textChange = (event) =>{
        let txtVal = event.currentTarget.value;
        setValue(txtVal);
        setFieldValue(textField, txtVal);
        let ff = document.getElementById(fileField);
        ff.value = '';
        ff.type = 'text';
        ff.type = 'file';
    }

    const selectValue = (event) =>{
        let txtVal = event.currentTarget.getAttribute("drop-value");
        setValue(txtVal);
        setFieldValue(textField, txtVal);
        let ff = document.getElementById(fileField);
        ff.value = '';
        ff.type = 'text';
        ff.type = 'file';
    }

	return (
            <div className="dropdown">
                <input type="text" id={textField} value={value} onChange={textChange} />
                <div className="dropdown-content">
                    {selectItems.map((x, index) => {
                        let selected = x.value == value ? "item selected" : "item";
                        let id = `${field}dropItem${index}`;
                        return (
                            <div id={id} className={selected} drop-value={x.value} onClick={selectValue}>{x.label}</div>
                        )
                    })}
                </div>
            </div>
            
	)
}