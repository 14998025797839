import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';

export const QrEventWinder = () => {
    
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");
  
    // Suponiendo que tienes un array de invitados
    const guests = [
      { id: 1, name: 'Angel Jaimes', guestsNumber: 3 , evento:"World Therapy Fest 2024"},
      { id: 2, name: 'Invitado 2', guestsNumber: 2 , evento:"World Therapy Fest 2024"},
      // Agregar más invitados según sea necesario
    ];
  
    // Convertimos el ID a entero
    const guestId = parseInt(nameId);
  
    // Buscamos el invitado por su ID
    const guest = guests.find(guest => guest.id === guestId);
  
    // Si no se encuentra el invitado, redireccionamos al inicio
    if (!guest) {
      console.log("no hay invitado")
      return null;
    }
  
    // Si se encuentra el invitado, mostramos sus detalles
    return (
        <div className="flex flex-col justify-center items-center h-screen">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-sm">
          <h2 className="text-4xl font-bold text-center mb-4">{guest.evento}</h2><br />
          <h1 className="text-4xl font-bold text-center mb-4 text-green-500">Acceso Aprobado</h1><br />
          <div className="text-center">
            <p className='text-2xl'><span className="font-bold">Nombre:</span> {guest.name}</p> <br />
            <p className='text-2xl'><span className="font-bold">ID:</span> {guest.id}</p><br />
            <p className='text-2xl'><span className="font-bold">Número de Invitados:</span> {guest.guestsNumber}</p><br />
          </div>
        </div>
      </div>
    );

}