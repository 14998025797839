import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./MartaXV.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper, GiRunningShoe } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaCakeCandles, FaChampagneGlasses } from "react-icons/fa6";

export const XvAlondraDaniela = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Alondra Daniela",
      fechaDeBoda: "25 MAYO, 2024",
      fechaNewDate: "May 25, 2024 17:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/j5TPTWFs/333cbacb-b739-4330-be16-2899904a802f.jpg",
      numeroNovia: "7225031880",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#cbf0ff",
      color2: "#d6d6d6",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Alondra Daniela!',
      entradasText:'disfrutar mis XV años',
      colorFondoName:"#77caec"
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:(
        <div>
          Quiero compartir este día mágico y soñado, con la bendición de Dios, junto a mi familia y amigos que me han visto crecer y me ayudaron a convertirme en la mujer que hoy soy.<br />
          Para mí madre y para mí es un placer invitarte a mis quince años.
        </div>
      ),
      fechaNewDate: "May 25, 2024 17:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/XJH5jbQ1/Imagen-de-Whats-App-2024-03-24-a-las-11-03-37-71dd9c75.jpg",
      fotoDerecha: "https://i.postimg.cc/W1VVqWB5/Imagen3.png",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, apoyo de mi Madre y mi Madrina!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Madre de la Quinceañera',
        tituloDerecha:'Madrina de la Quinceañera',
      esposaMadre: "Esmeralda Martínez González",
      esposaPadre: "",
      esposoMadre: "Irma Martínez González",
      esposoPadre: "",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
      ceremoniaHora: "5:00 p.m.",
      ceremoniaNombreLugar: "Parroquia del Sagrado Corazón de Jesús",
      ceremoniaLugarMap:
        "Calle Eduardo Hernández Chazaro esq. lago Constanza. Col. 8 cedros Toluca",
      ceremoniaUrl:
        "https://maps.app.goo.gl/WjVGjcxoeZGTUxM16",
      recepcionFecha: "Sábado, 25 de Mayo de 2024.",
      recepcionHora: "6:00 p.m.",
      recepcionNombreLugar: "Salón Jardín Anturios.",
      recepcionLugarMap:
        "Paseo Cristóbal Colón. 612. Col Villa Hogar. Toluca",
      recepcionUrl:
        "https://maps.app.goo.gl/i9PhAsn7JdD2dpAq7",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "11:00 pm Acompáñanos al pastel",
      baileXV: "7:30 pm No te pierdas mi baile de XV años",
      cena: "6:30 pm Disfruta de una deliciosa cena",
      recepcion: "9:00 pm Disfruta la cabian de fotos",
      coctel: "8:00 pm Disfruta de nuestro refrescante brindis",
      baile: "8:30 pm Para el baile no olvides traer tus tenis y agujetas fluorescentes",
    };
  
    const datosSeccion8 = {
      descripcion:
        "No hay mejor regalo que tener tu compañía, pero si deseas darme un detalle lo agradecería mucho.",
      linkAmazon:
        "",
      linkLiverpool:
        "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51394527",
    };
  
  
    const [NameInvitation, setNameInvitation] = useState("");
    const [numGuess, setNumGuess] = useState("");
  
  
    const images = [
      "https://i.postimg.cc/dV9Y2Rvw/Imagen1.png",
      "https://i.postimg.cc/ryvMMFQX/Imagen2.jpg",
      "https://i.postimg.cc/mhxdqnYJ/Imagen6.jpg",
      "https://i.postimg.cc/bYGp7209/Imagen5.jpg",
      "https://i.postimg.cc/ZKpZRtM9/Imagen7.jpg",
      "https://i.postimg.cc/XJH5jbQ1/Imagen_de_WhatsApp_2024-03-24_a_las_11.03.37_71dd9c75.jpg"
      // Add more image URLs as needed
    ];
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/ZnWd0MQb/Imagen_de_WhatsApp_2024-03-24_a_las_11.03.37_eb7e8f00.jpg")',
      fondoVestimenta:'url("https://img.freepik.com/vector-gratis/elegante-fondo-tinta-alcohol-azul-marino-flores_41066-1470.jpg")',
      vestimenta:" "
    }
  
    const names = [
        { name: "Angel Jaimes", guess: "2" },
        { name: "Fortino Martinez González", guess: "2" },
        { name: "Diana Esmeralda Martinez Francisco", guess: "2" },
        { name: "Carlos Martinez González", guess: "5" },
        { name: "Manuel González", guess: "5" },
        { name: "José Luis Martinez González", guess: "6" },
        { name: "Jonathan Lopez Serafín", guess: "3" },
        { name: "Jaime López Alvarez", guess: "3" },
        { name: "Rosario Martinez Colin", guess: "4" },
        { name: "Teresa Martinez Colin", guess: "3" },
        { name: "Alfonso Martinez Colin", guess: "4" },
        { name: "Citlali Martinez Huerta", guess: "6" },
        { name: "Fortino Martinez Colin", guess: "3" },
        { name: "Jesús Alvarez", guess: "3" },
        { name: "Javier Martinez Colin", guess: "7" },
        { name: "Carlos Rojas", guess: "7" },
        { name: "José González", guess: "6" },
        { name: "Maribel Rodríguez", guess: "5" },
        { name: "Juana Bernal Malvaez", guess: "5" },
        { name: "Mario Antonio Martinez González", guess: "5" },
        { name: "Carolina Martinez González", guess: "4" },
        { name: "Itzel Martinez González", guess: "2" },
        { name: "Mario Martínez Garcia", guess: "5" },
        { name: "Juan Carlos Cruz Miguel", guess: "1" },
        { name: "Eloísa Medina Gutierrez", guess: "1" },
        { name: "Cruz Cielo Piñón", guess: "1" },
        { name: "Martha Susana Escobedo Galindo", guess: "4" },
        { name: "Abel y Marlen", guess: "5" },
        { name: "Elizabeth Cárdenas", guess: "5" },
        { name: "Mario Flores", guess: "3" },
        { name: "Efraín Cruz", guess: "4" },
        { name: "Juan Ruiz", guess: "5" },
        { name: "Santiago González García", guess: "2" },
        { name: "Jesús González García", guess: "5" },
        { name: "José González García", guess: "3" },
        { name: "Juan Matlalcuatzi Martinez", guess: "4" },
        { name: "Maria Elena González Quintana", guess: "5" },
        { name: "Marcelina Mondragón", guess: "5" },
        { name: "Irma González García", guess: "6" },
        { name: "María González García", guess: "4" },
        { name: "Laura González Martínez", guess: "5" },
        { name: "Miguel Negrete Martinez", guess: "7" },
        { name: "Marlen", guess: "3" },
        { name: "Pedro Cardenas", guess: "3" },
        { name: "Adelina Martinez Espinosa", guess: "4" },
        { name: "Erika", guess: "5" },
        { name: "Julián Benítez", guess: "6" },
        { name: "Booh y Yareg", guess: "2" },
        { name: "Millan Archundia", guess: "4" },
        { name: "Yair y Tere", guess: "4" },
        { name: "Vero y Martin", guess: "2" },
        { name: "Omar y Martha", guess: "5" },
        { name: "Parroco Augusto", guess: "1" },
        { name: "Ximena", guess: "1" },
        { name: "Fernanda", guess: "1" },
        { name: "Karla Vargas", guess: "2" },
        { name: "María José", guess: "1" },
        { name: "Mario Zamora Suárez", guess: "4" },
        { name: "Irma Martínez González", guess: "1" },
        { name: "Esmeralda Martinez González", guess: "1" },
        { name: "Alondra Martinez González", guess: "1" },
        { name: "Horacio Fuentes Antúnez", guess: "3" },
        { name: "María Fernanda Sanchez", guess: "1" },
        { name: "Rafael Sánchez Castro", guess: "4" },
        { name: "Juan y Ramona", guess: "6" },
        
        { name: "Diego Martínez", guess: "1" },
        { name: "Erika Pedraza", guess: "1" },
        { name: "Ma. José", guess: "1" },
        { name: "Rafa y Luis", guess: "2" },
        { name: "Axel y Diego", guess: "2" },
        { name: "Karol", guess: "1" },
        { name: "Pepito", guess: "1" },
    ];
    
  
    const [messageWhats, setMessageWhats] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [isIOS2, setIsIOS2] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS2(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  
    useEffect(() => {
  
      const findName = names.find((e) => e.name == nameId);
      console.log(findName?.name, names.length);
  
      if (findName) {
        setNameInvitation(findName.name);
        setNumGuess(findName.guess);
        const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
        setMessageWhats(massageWhatss);
      } else {
        window.location.href = "/";
      }
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
         <ModalXv 
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Invitados
          NameInvitation={NameInvitation}
          numGuess={numGuess}
          peltaColores={peltaColores}
          imagenesFondo={imagenesFondo}
          datosInvitados={datosInvitados}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
  <div
        style={{
          backgroundImage:imagenesFondo.fondoVestimenta,
          backgroundColor: peltaColores.color1,
          backgroundAttachment: isIOS2 ? 'local' : 'fixed',
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
             {imagenesFondo.vestimenta ? imagenesFondo.vestimenta :"FORMAL"}
          </p>
          <br />
          {
            peltaColores.messageVestimenta ? (
              <p className='text-center pt-[5vh] font-semibold text-xl w-[80%]'> ¡Importante! Prohibido llevar vestimenta color lila, este color está reservado exclusivamente para la quinceañera.</p>
            ) : 
            ''
          }
          
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-left"
            >
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/024/680/125/small/woman-silhouette-free-png.png"
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-2xl">Mujeres: Divinas</p>
            </div>
            <div
              className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
              data-aos="fade-right"
            >
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/022/692/743/small_2x/modern-object-flower-food-work-silhouette-furniture-and-liftstyle-free-png.png"
                className="h-[30vh]"
                alt=""
              />
              <br />
              <p className="font-serif text-2xl">Hombres: Guapos</p>
            </div>
          </div>
        </div>
      </div>
  
      <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Programa
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            Hemos planeado un día lleno de diversión para disfrutar al máximo.
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
                <ImSpoonKnife className="text-[60px]" />
            <br />
            <p className="font-serif text-xl text-center">CENA</p>
            <br />
            <p className="font-serif text-xl text-center w-[60%]">
              {datosSeccion6.cena}
            </p>
            <br />
            <br />
            <hr className="bg-gray-700 h-1 w-[60%]" />
            <br />
            <GiPartyPopper  className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE DE XV AÑOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baileXV}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              <FaChampagneGlasses className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CÓCTEL</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.coctel}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>

            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
                <GiRunningShoe  className="text-[60px]" />
            <br />
            <p className="font-serif text-xl text-center">BAILE</p>
            <br />
            <p className="font-serif text-xl text-center w-[60%]">
              {datosSeccion6.baile}
            </p>
            <br />
            <hr className="bg-gray-700 h-1 w-[60%]" />
            <br />
                 <FaPhotoVideo className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CABINA DE FOTOS</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.recepcion}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
              
              
              <FaCakeCandles  className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">
                PASTEL
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaReligiosa}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
            </div>
          </div>
        </div>
      </div>
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col sm:p-3 md:p-0 sm:py-[5vh] md:pt-[15vh] items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Mesa de regalos
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
            data-aos="flip-up"
          >
            {datosSeccion8.descripcion}
          </p>

          <div className="grid sm:grid-cols-1 md:w-[50%] sm:w-[80%] pt-[8vh]">  
            <div
              style={{
                backgroundImage:
                  'url("https://industriasneon.files.wordpress.com/2015/07/liverpoollogo-copia.png?w=1000")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "90%",
                display: "flex",
                backgroundPosition: "center center",
              }}
              className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
              data-aos="fade-right"
            >
              <a
                href={datosSeccion8.linkLiverpool}
                class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
              >
                Ver mesa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
  
        <Confirmacion
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
