import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvKarla = () => {
  const [isOpen, setIsOpen] = useState(false);

  const peltaColores = {
    color1: "#ffdbd8",
    color2: "#ffd977",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_04/NDBcUXNPRCR_y2mate.com---Diego-Verdaguer-Nena.mp3",
    imgPortada:
      "https://i.postimg.cc/B6fyg1pX/original-3694D643-62C0-452F-8023-1675945F5F62.jpg",
    nombre: "Leslie",
    fechaDeBoda: "01 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Agradezco de antemano su apreciable compañía. \\n ¡No faltes!",
    aviso: "",
    numeroConfirmacion: "",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. \\nHoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan especial para mi.",
    fechaNewDate: "June 01, 2024 17:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/wM0Gg4pm/processed-76566433-0A8F-4995-BF8C-A35D0839E37C.jpg",
    fotoDerecha:
      "https://i.postimg.cc/hGd6T09k/original-6F2B16D2-A415-47A8-810C-A971E50E3FD1.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Karla Ramírez Rascón",
    padre: "Ramon Alejandro Valdez",
    madrina: "Dulce María Rodriguez Valdez",
    padrino: "Pablo César Ramirez Rascón",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 01 de Junio de 2024.",
    ceremoniaHora: "5:00 p.m.",
    ceremoniaNombreLugar: "Parroquia nuestra señora de los dolores",
    ceremoniaLugarMap:
      "Janos, Aguprieta S/N, col centro, Janos Chihuahua",
    ceremoniaUrl: "https://maps.app.goo.gl/MUrHfcXYBhWC32bMA",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 01 de Junio de 2024.",
    recepcionHora: "7:00 p.m.",
    recepcionNombreLugar: "Rancho el Peñasco",
    recepcionLugarMap: "Janos, Ascension, Chihuahua Mexico",
    recepcionUrl: "https://maps.app.goo.gl/J4kefGdzTifQKBJa6",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "El mejor regalo que me puedes dar es tu presencia, pero si gustas regalarme algo, puedes darme un Regalo de tu elección o lluvia de sobres. \\n La lluvia de sobres, es la tradición de regalar dinero en efectivo a la quinceañera en un sobre el día del evento… \\n\\n\\n¡No faltes! ",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "", 
    link2Enable: false,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/B6fyg1pX/original-3694D643-62C0-452F-8023-1675945F5F62.jpg",
    "https://i.postimg.cc/wM0Gg4pm/processed-76566433-0A8F-4995-BF8C-A35D0839E37C.jpg",
    "https://i.postimg.cc/hGd6T09k/original-6F2B16D2-A415-47A8-810C-A971E50E3FD1.jpg",
    "https://i.postimg.cc/c1BqFTVZ/original-938B5D51-F685-4284-A213-039215B2A7A6.jpg",
    "https://i.postimg.cc/nrHW8b6x/original-9F9D8113-22C7-4417-A11B-6F74CBA59394.png",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          peltaColores={peltaColores}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <>
          <RegalosXvComponent
            peltaColores={peltaColores}
            datosSeccionRegalos={datosSeccionRegalos}
          />
          <h1
            style={{
              backgroundColor: peltaColores.color1,
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              color:datosSeccionRegalos.textColor ? datosSeccionRegalos.textColor : ''
            }}
            className="font-serif sm:h-auto md:h-[100vh] flex flex-col py-[5vh] px-3 items-center w-full sm:text-[32px] md:text-[42px]"
          >
            
          </h1>
        </>
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
