import React, { useState } from "react";
import { useField } from "formik";
import { Label } from "reactstrap";

export const SelectIcons = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    {
      label: "Ceremonia Religiosa",
      value: "https://i.postimg.cc/FFyLxz6R/l-zip-74.png",
    },
    {
      label: "Ceremonia Religiosa 2",
      value: "https://i.postimg.cc/YSFJ4yd3/l-4.png",
    },
    {
      label: "Recepcion o fotos",
      value: "https://i.postimg.cc/BnGrrwQ0/Dise-o-sin-t-tulo.png",
    },
    {
      label: "Documentos Civil",
      value: "https://i.postimg.cc/hG8tFdqC/Dise-o-sin-t-tulo-31.png",
    },
    {
      label: "Corazones Boda",
      value: "https://i.postimg.cc/QtXxjd8j/Dise-o-sin-t-tulo-32.png",
    },
    {
      label: "Fiesta",
      value: "https://i.postimg.cc/br3v4LxV/l.png",
    },
    {
      label: "Sorpresa",
      value: "https://i.postimg.cc/fyNJxZsp/l.png",
    },
    {
      label: "Sobre Negro",
      value: "https://i.postimg.cc/0j8v8c76/Dise-o-sin-t-tulo.png",
    },
    {
      label: "Corazon Pareja",
      value: "https://i.postimg.cc/FK6Z3nLG/l.png",
    },
    {
      label: "Hombre Casual",
      value: "https://i.postimg.cc/mkkLFp1q/Dise-o-sin-t-tulo-41.png",
    },
    {
      label: "Mujer Casual",
      value: "https://i.postimg.cc/nz0VrfrB/Dise-o-sin-t-tulo-42.png",
    },
    {
      label: "Coctel o Brindis",
      value: "https://i.postimg.cc/90kWPPGf/Flyer-Despedida-de-Soltero-Ilustraci-n-Rojo-1.png",
    },
    {
      label: "Sombrero Vaquera",
      value: "https://i.postimg.cc/GhB3LNCT/Pink-Vibrant-Cowgirl-Hat-Circle-Sticker.png",
    },
    {
      label: "XV icono",
      value: "https://i.postimg.cc/htTSxdhS/Nashville-Bachelorette-Party-Logo.png",
    },
    {
      label: "Coctel o Brindis 2",
      value: "https://i.postimg.cc/KYVSHc19/Dise-o-sin-t-tulo-54.png",
    },
    {
      label: "Pastel",
      value: "https://i.postimg.cc/ZRdttk3J/Dise-o-sin-t-tulo-55.png",
    },
    {
      label: "Alberca",
      value: "https://i.postimg.cc/Gpm0c09T/l.png",
    },
    {
      label: "Sonido",
      value: "https://i.postimg.cc/LshFDg8Y/Dise-o-sin-t-tulo-56.png",
    },
    {
      label: "Bailarina",
      value: "https://i.postimg.cc/Hk6Cb9d1/Dise-o-sin-t-tulo-57.png",
    },
    {
      label: "Comida o Cena",
      value: "https://i.postimg.cc/cCRpCQXj/Dise-o-sin-t-tulo-58.png",
    },
    {
      label: "LLuvia de Sobres",
      value: "https://i.postimg.cc/8cg8TyBM/Dise-o-sin-t-tulo-59.png",
    },
    {
      label: "Vals Novios",
      value: "https://i.postimg.cc/KctS02xN/Dise-o-sin-t-tulo-53.png",
    },
    {
      label: "Sol",
      value: "https://i.postimg.cc/rFb1svRB/fa90720c3f648e3badafc2d8cacb2a93.png",
    },
    {
      label: "Regalos",
      value: "https://i.postimg.cc/1zyf3HMx/Cartel-P-ster-Cumplea-os-de-50-Elegante-Negro-y-Dorado-2.png",
    },
    {
      label: "50s",
      value: "https://i.postimg.cc/PfvZcSRK/50s.png",
    },
    {
      label: "Tarjeta Cuadrada Recuerdo Boda",
      value: "https://i.postimg.cc/J4wJWbw8/Tarjeta-Cuadrada-Recuerdo-Boda-Minimalista-Blanco.png",
    },
    {
      label: "Flores Blancas",
      value: "https://i.postimg.cc/HkYrpzBq/Dise-o-sin-t-tulo-34.png",
    },
    {
      label: "Mariposa Dorada",
      value: "https://i.postimg.cc/bwmsdn5s/Dise-o-sin-t-tulo-37.png",
    },
    {
      label: "Mariposa Dorada Izquierda",
      value: "https://i.postimg.cc/mrhh9DxV/Dise-o-sin-t-tulo-38.png",
    },
    {
      label: "Moño",
      value: "https://i.postimg.cc/Dy2vsZLs/Cartel-P-ster-Cumplea-os-de-50-Elegante-Negro-y-Dorado-1.png",
    },
    {
      label: "Sobre Blanco",
      value: "https://i.postimg.cc/qqrk1BFP/Dise-o-sin-t-tulo-27.png",
    },
    {
      label: "Mariposa Roja",
      value: "https://i.postimg.cc/VvFzpQQd/Dise-o-sin-t-tulo-28.png",
    },
    {
      label: "Mariposa Roja Izquierda",
      value: "https://i.postimg.cc/YqjPJBwZ/Dise-o-sin-t-tulo-29.png",
    },
    {
      label: "Flores Beige",
      value: "https://i.postimg.cc/KvVWbpGz/Dise-o-sin-t-tulo-33.png",
    },
    {
      label: "Flor",
      value: "https://i.postimg.cc/rwrPwGs9/Dise-o-sin-t-tulo-44.png",
    },
    {
      label: "Mariposa Blanca",
      value: "https://i.postimg.cc/s2PHpVpQ/Dise-o-sin-t-tulo-46.png",
    },
    {
      label: "Mariposa Rosa",
      value: "https://i.postimg.cc/hGR3zJ7R/Dise-o-sin-t-tulo-47.png",
    },
    {
      label: "Anillos",
      value: "https://i.postimg.cc/NMgJDW4N/Dise-o-sin-t-tulo-48.png",
    },
    {
      label: "Mariposas Doradas",
      value: "https://i.postimg.cc/TwCk4CNT/Dise-o-sin-t-tulo-49.png",
    },
    {
      label: "Pastel XV Blanco",
      value: "https://i.postimg.cc/6q5tRjgR/Flyer-Despedida-de-Soltero-Ilustraci-n-Rojo-8.png",
    },
    {
      label: "Trajes Hombre",
      value: "https://i.postimg.cc/yxJpw0St/trajes-hombre.png",
    },
    {
      label: "Vestidos Mujer",
      value: "https://i.postimg.cc/6Q9jBkvd/vestidos-mujer.png",
    },
    {
      label: "Caballo",
      value: "https://i.postimg.cc/NG9NCXxv/l.png",
    },
    {
      label: "Comida Chatarra",
      value: "https://i.postimg.cc/rFqtZc69/l.png",
    },
    {
      label: "Pastel Boda",
      value: "https://i.postimg.cc/mgD1Rwgz/l.png",
    },
  ];
  

  return (
    <div className="w-full">
      <Label className="block text-sm font-medium text-gray-700 mb-1">{label}</Label>
      <div className="relative">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`form-select w-full p-2 flex items-center justify-between border ${
            meta.touched && meta.error ? "border-red-500" : "border-gray-300"
          } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
        >
          {field.value ? (
            <div className="flex items-center">
              <img
                src={field.value}
                alt="Selected option"
                className="w-12 h-12 object-cover rounded-full mr-2"
              />
              <span className="truncate">{options.find(opt => opt.value === field.value)?.label || "Opción seleccionada"}</span>
            </div>
          ) : (
            <span className="text-gray-500">Seleccione un Icono</span>
          )}
          
        </button>
        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
            {options.map((option) => (
              <div
                key={option.value}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-100 transition duration-150 ease-in-out"
                onClick={() => {
                  setValue(option.value);
                  setIsOpen(false);
                }}
              >
                {option.value && (
                  <img
                    src={option.value}
                    alt={option.label}
                    className="w-10 h-10 object-cover rounded-full mr-2"
                  />
                )}
                <span className="text-sm">{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <input
        type="text"
        value={field.value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="mt-2 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
      {meta.touched && meta.error && (
        <div className="text-red-500 text-sm mt-1">{meta.error}</div>
      )}
    </div>
  );
};
