import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import Aos from "aos";

export const BodaCarlosYRosy = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  const peltaColores = {
    color1: "#ffffff",
    color2: "#c2bebe",
    // #dbd7d7
    // #ccc8c8
  };

  const datosSeccionPotadaBoda = {
    cancion: "https://www.cjoint.com/doc/24_05/NEqb2K2nomR_y2mate.com---Christina-Perri-A-Thousand-Years-1-.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Carlos",
    esposa: "Rosy",
    fechaDeBoda: "13 JULIO, 2024",
    imgPortada:
      "https://i.postimg.cc/XqWJf9N0/85de23ac-0d7e-4939-a276-5642238e0867.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 10 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    fechaNewDate: "July 13, 2024 18:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/hv0GphhQ/4ed8bdc3-0e78-4b45-85f0-790e766f76e8.jpg",
    fotoDerecha:
      "https://i.postimg.cc/c4XH3KVV/3d56d0d4-fd95-4715-92ef-7056d82f6bca.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 3,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "Rosa María Hernández",
    padre: "Miguel Ángel Ibarra",
    titulo2: "Padres del Novio",
    madrina: "Adriana Gaona",
    padrino: "Carlos Martínez",
    titulo3: "Padrinos de velación",
    madrina2: "Veronica Sanchez",
    padrino2: "Gilberto Osuna",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionPadres2 = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion: "Marlon Joseph Martínez Ibarra",
    titulo1: "Mis padres",
    madre: "Rosy Ibarra ",
    padre: "Carlos Adrián martinez",
    titulo2: "Mi padrino",
    madrina: "Cristopher alejando Osuna",
    padrino: "",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionPadres3 = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion: "Noah Damián Martínez Ibarra",
    titulo1: "Mis padres",
    madre: "Rosy Ibarra ",
    padre: "Carlos Adrián martinez",
    titulo2: "Mis padrinos",
    madrina: "Miriam Fabiola Martínez ",
    padrino: "Jorge correa ",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 13 de Julio de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia San José del castillo",
    ceremoniaLugarMap: "61, Jalisco 59, 45685 San José del Castillo, Jal.",
    ceremoniaUrl: "https://maps.app.goo.gl/TyRWcYypA9TzYJoo9",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 13 de Julio de 2024.",
    recepcionHora: "7:30 p.m.",
    recepcionNombreLugar: "Terraza Real Santa María - Salón para Eventos",
    recepcionLugarMap:
      "Calle Sta Maria 43, El Verde, 45694 San José el Verde, Jal.",
    recepcionUrl: "https://maps.app.goo.gl/vVktLmfqbL41PmnS6",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion: "Mesa de regalos dentro del salón de eventos",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/YCWv8rfR/14d253-fec6272a92ac4c4d807406d6c024be36-mv2.webp",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso:
      "Los adultos disfrutan de la pista. \\nLos niños tendrán su área exclusiva",
    numeroConfirmacion: "+523320953516",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+523320953516",
    numeroNovio: "+523318770129",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/0yjN0kvr/68c196f1-103f-4270-8554-9db0b2e61b0e.jpg",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/XqWJf9N0/85de23ac-0d7e-4939-a276-5642238e0867.jpg",
    "https://i.postimg.cc/hv0GphhQ/4ed8bdc3-0e78-4b45-85f0-790e766f76e8.jpg",
    "https://i.postimg.cc/c4XH3KVV/3d56d0d4-fd95-4715-92ef-7056d82f6bca.jpg",
    "https://i.postimg.cc/4NJynvF6/e6f3fbfc-35b2-409e-b472-38c920510dd4.jpg",
    "https://i.postimg.cc/Vs95YZ4s/494ee341-4d88-4076-b8e7-9290b20b62cf.jpg",
    "https://i.postimg.cc/0yjN0kvr/68c196f1-103f-4270-8554-9db0b2e61b0e.jpg",

    // Add more image URLs as needed
  ];

  const names = [
    { name: "Familia. Ibarra Ochoa", guess: "5", id: 1 },
    { name: "Familia. Moran Ibarra", guess: "3", id: 2 },
    { name: "Familia. Ibarra Mendoza", guess: "5", id: 3 },
    { name: "Familia. Ibarra Buenrostro", guess: "3", id: 4 },
    { name: "Familia. Lopez Ibarra", guess: "4", id: 5 },
    { name: "Familia. Ibarra Martinez", guess: "5", id: 6 },
    { name: "Familia. Ibarra Hernandez", guess: "1", id: 7 },
    { name: "Familia. Martinez Gaona", guess: "3", id: 8 },
    { name: "Familia. Ramirez Martinez", guess: "2", id: 9 },
    { name: "Familia. Osuna Sanchez", guess: "2", id: 10 },
    { name: "Familia. Gomez Osuna", guess: "2", id: 11 },
    { name: "Familia. Osuna Martinez", guess: "4", id: 12 },
    { name: "Familia. Osuna Perez", guess: "2", id: 13 },
    { name: "Familia. Osuna Espinoza", guess: "4", id: 14 },
    { name: "Familia. Sanchez Perez", guess: "1", id: 15 },
    { name: "Familia. Correa Martinez", guess: "5", id: 16 },
    { name: "Familia. Torres Acero", guess: "3", id: 17 },
    { name: "Familia. Zarate Adan", guess: "3", id: 18 },
    { name: "Familia. Flores Rios", guess: "3", id: 19 },
    { name: "Familia. Gonzales", guess: "3", id: 20 },
    { name: "Familia. Rivera Valdes", guess: "3", id: 21 },
    { name: "Familia. Rosas Jacobo", guess: "2", id: 22 },
    { name: "Familia. Cisneros Jimenez", guess: "2", id: 23 },
    { name: "Familia. Lew Vazquez", guess: "2", id: 24 },
    { name: "Familia. Rodriguez Lopez", guess: "2", id: 25 },
    { name: "Familia. Loza Moran", guess: "2", id: 26 },
    { name: "Familia. Aguilar Moran", guess: "2", id: 27 },
    { name: "Familia. Moran Ruiz", guess: "2", id: 28 },
    { name: "Familia. Ibarra", guess: "2", id: 29 },
    { name: "Familia. Abarca Ibarra", guess: "2", id: 30 },
    { name: "Familia. Mendoza Moreno", guess: "2", id: 31 },
    { name: "Familia. Ayala Lopez", guess: "4", id: 32 },
    { name: "Familia. Martinez Duran", guess: "4", id: 33 },
    { name: "Familia. Ramos", guess: "3", id: 34 },
    { name: "Familia. Lopez", guess: "4", id: 35 },
    { name: "Familia. Ponce", guess: "2", id: 36 },
    { name: "Familia. Franco", guess: "4", id: 37 },
    { name: "Familia. Basoria", guess: "2", id: 38 },
    { name: "Familia. Razo Pulido", guess: "2", id: 39 },
    { name: "Quezada Villegas", guess: "1", id: 40 },
    { name: "Berta Ornelas", guess: "2", id: 41 },
    { name: "Familia. Soto Ibarra", guess: "1", id: 41 }
];


  const targetDate = new Date(
    datosSeccionContador.fechaNewDate || ""
  ).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <div
        style={{
          backgroundImage: `url(${datosSeccionPotadaBoda.imgPortada})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgb(0 0 0 / 22%)" }}
        >
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPotadaBoda.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
          <div className="w-full flex justify-end h-full items-start fixed ">
            <button
              onClick={handlePlayButtonClick}
              className=" text-3xl  rounded animate-bounce bg-black p-1"
            >
              {isMusicPlaying ? "🔊" : "🔇"}
            </button>
          </div>
          <br />
          <br />
          <h1
            className="font-serif sm:text-4xl md:text-[132px] text-white text-center flex"
            data-aos="flip-up"
          >
            {datosSeccionPotadaBoda.esposa}{" "}
            <span>
              <img
                src="https://i.postimg.cc/6Qqt7rrB/Dise-o-sin-t-tulo-15.png"
                className="sm:h-[62px] md:h-[138px]"
                alt=""
              />
            </span>{" "}
            {datosSeccionPotadaBoda.esposo}
          </h1>
          <br />
          <br />
          <p
            className="text-white p-3 rounded-lg font-serif text-2xl text-center"
            data-aos="fade-up"
          >
            {datosSeccionPotadaBoda.titulo || ""} <br />{" "}
            {datosSeccionPotadaBoda.fechaDeBoda
              ? `- ${datosSeccionPotadaBoda.fechaDeBoda} -`
              : ""}
          </p>
          <br />
        </div>
      </div>

      {contador ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionContador.textColor
              ? datosSeccionContador.textColor
              : "",
          }}
          className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col pt-[10vh] w-full items-center">
            <h1
              className="font-serif  sm:text-[52px] md:text-[92px] "
              data-aos="flip-up"
            >
              {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
              data-aos="flip-up"
            >
              {datosSeccionContador.descipcion
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
            <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "45vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-[30%]"
                data-aos="fade-right"
              ></div>
              <br />
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Padres de la novia
              </h1>
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Rosa María Hernández
                <p className="font-serif text-center text-xl">&</p>
                Miguel Ángel Ibarra
              </p>
              <br />
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Padres del Novio
              </h1>
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Adriana Gaona
                <p className="font-serif text-center text-xl">&</p>
                Carlos Martínez
              </p>
              <br />
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Padrinos de velación
              </h1>
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Veronica Sanchez
                <p className="font-serif text-center text-xl">&</p>
                Gilberto Osuna
              </p>
              {datosSeccionContador.contador ? (
                <div
                  className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
                  data-aos="fade-up"
                  style={{
                    border: "15px solid transparent",
                    padding: "15px",
                    borderImage: datosSeccionContador.marcoEnable
                      ? "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')"
                      : "",
                    borderImageSlice: "46",
                    borderImageWidth: "round",
                  }}
                >
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.days}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Días</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.hours}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.minutes}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.seconds}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "45vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-full sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
                data-aos="fade-left"
              ></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <>
          <div
            style={{
              backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: peltaColores.color1,
              color: datosSeccionPadres.textColor
                ? datosSeccionPadres.textColor
                : "",
            }}
            className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
          >
            {/* =========================================================================================================================================== */}
            <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Mi Bautizo
              </h1>
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Noah Damián
              </h1>
              <br />
              <img
                src="https://i.postimg.cc/4NJynvF6/e6f3fbfc-35b2-409e-b472-38c920510dd4.jpg"
                style={{ height: "auto", width: "auto" }}
              />
              <br />
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Hoy, en un día especial, doy gracias a Dios y a mis padres por
                su apoyo en este momento único. En mi bautizo, inicio una nueva
                etapa espiritual y deseo compartir esta ocasión especial
                contigo. Que este día esté lleno de bendiciones y amor. ¡Gracias
                por ser parte de este momento importante para mí!.
              </p>
              <div
                className={`grid sm:grid-cols-1 md:grid-cols-${
                  datosSeccionPadres3.cantidad || "1"
                } w-[80%] pt-[10vh]`}
              >
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres3.titulo1}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.madre}
                  </p>
                  {datosSeccionPadres3.padre ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.padre}
                  </p>
                </div>
              </div>
              {datosSeccionPadres3.cantidad == 2 ||
              datosSeccionPadres3.cantidad == 3 ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres3.titulo2}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.madrina}
                  </p>
                  {datosSeccionPadres3.padrino ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres3.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${datosSeccionPadres.imgFondo || ""})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: peltaColores.color1,
              color: datosSeccionPadres.textColor
                ? datosSeccionPadres.textColor
                : "",
            }}
            className="sm:h-auto md:h-[100vh] py-[5vh] px-3 flex flex-col items-center w-full"
          >
            {/* =========================================================================================================================================== */}
            <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Mi Primera Comunion
              </h1>
              <h1
                className="font-serif text-center sm:text-[32px] md:text-[42px]"
                data-aos="flip-up"
              >
                Marlon Joseph
              </h1>
              <br />
              <img
                src="https://i.postimg.cc/Vs95YZ4s/494ee341-4d88-4076-b8e7-9290b20b62cf.jpg"
                style={{ height: "auto", width: "auto" }}
              />
              <br />
              <p
                className=" text-center pt-[5vh] font-serif text-xl"
                data-aos="flip-up"
              >
                Hoy, en un día especial, doy gracias a Dios y a mis padres por
                su apoyo en este momento único. En mi primera comunion, inicio
                una nueva etapa espiritual y deseo compartir esta ocasión
                especial contigo. Que este día esté lleno de bendiciones y amor.
                ¡Gracias por ser parte de este momento importante para mí!.
              </p>
              <div
                className={`grid sm:grid-cols-1 md:grid-cols-${
                  datosSeccionPadres2.cantidad || "1"
                } w-[80%] pt-[10vh]`}
              >
                <div
                  className="flex flex-col justify-center items-center"
                  data-aos="fade-right"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres2.titulo1}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.madre}
                  </p>
                  {datosSeccionPadres2.padre ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.padre}
                  </p>
                </div>
              </div>
              {datosSeccionPadres2.cantidad == 2 ||
              datosSeccionPadres2.cantidad == 3 ? (
                <div
                  className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                  data-aos="fade-left"
                >
                  <p className="font-semibold text-center text-2xl">
                    {datosSeccionPadres2.titulo2}
                  </p>
                  <br />
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.madrina}
                  </p>
                  {datosSeccionPadres2.padrino ? (
                    <p className="font-serif text-center text-xl">&</p>
                  ) : (
                    ""
                  )}
                  <p className="font-serif text-center text-xl">
                    {datosSeccionPadres2.padrino}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          messageWhats={messageWhats}
          images={images}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
