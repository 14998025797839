import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import ImagenComponent from "../../../components/ComponentsBoda/ImagenComponent";

export const XvHeidiDaiana = () => {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#fff",
    color2: "#fff",
  };

  const datosSeccionPortada = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEDxhHdnmSP_Y2meta.app---The-Greatest-Showman-Cast---A-Million-Dreams-Official-Audio-128-kbps-.mp3",
    imgPortada:
      "https://i.postimg.cc/G2H8FNMq/71-EF7-ABA-9-DCC-4-D78-A029-4742-C9-F6-EF91.png",
    nombre: "Heidi Daiana",
    fechaDeBoda: "21 SEPTIEMBRE, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+527771201833",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "https://i.postimg.cc/RZF1FnBH/Video-para-Dispositivos-M-viles-Boda-Floral-Azul-y-Dorado.png",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan especial para mi.",
    fechaNewDate: "September 21, 2024 14:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/nrB2nCt8/6-B999069-2310-48-FB-939-F-F2-CA9-EB1-BF74.png",
    fotoDerecha:
      "https://i.postimg.cc/HWN1xKDB/49529-CB6-EAF1-4-F15-8-D29-573-CDDE2-DA7-D.png",
    marcoEnable: true,
    imgFondo:
      "https://i.postimg.cc/RZF1FnBH/Video-para-Dispositivos-M-viles-Boda-Floral-Azul-y-Dorado.png",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Padres de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Melisa Ocampo Vargas ",
    padre: "Raymundo Rojas López ",
    madrina: "Yuridika García López",
    padrino: "Marco Antonio Ramos Gómez ",
    imgFondo:
      "https://i.postimg.cc/RZF1FnBH/Video-para-Dispositivos-M-viles-Boda-Floral-Azul-y-Dorado.png",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 21 de Septiembre de 2024.",
    ceremoniaHora: "2:00 p.m.",
    ceremoniaNombreLugar: "Parroquia San Andrés Apóstol ",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/H1LjUh7enQCfadmB7?g_st=ic",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 21 de Septiembre de 2024.",
    recepcionHora: "3:00 p.m.",
    recepcionNombreLugar: "Jardín Paraíso Nayan ",
    recepcionLugarMap: "",
    recepcionUrl: "https://maps.app.goo.gl/Je3jg9CvZSQ9S4t4A?g_st=ic",
    imgFondo:
      "https://i.postimg.cc/RZF1FnBH/Video-para-Dispositivos-M-viles-Boda-Floral-Azul-y-Dorado.png",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
    imgFondo: "https://i.postimg.cc/RZF1FnBH/Video-para-Dispositivos-M-viles-Boda-Floral-Azul-y-Dorado.png"
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía, sin embargo si deseas obsequiarme algo me encantaría fuera efectivo. Gracias por permitirme escoger mi regalo.",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "https://i.postimg.cc/RZF1FnBH/Video-para-Dispositivos-M-viles-Boda-Floral-Azul-y-Dorado.png",
    textColor: "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://i.postimg.cc/ZRGRrqv5/blue-Watercolor-Abstract-Phone-Wallpaper.png",
    titulo: `¡Has sido invitado a los XV de ${datosSeccionPortada.nombre}!`,
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const datosSeccionFoto = {
    imgFondo: "https://i.postimg.cc/RZF1FnBH/Video-para-Dispositivos-M-viles-Boda-Floral-Azul-y-Dorado.png",
    sizeFoto: "",
    imgContenidos: [
      "https://i.postimg.cc/cJbqs0Lj/Comparte-con-nosotros-todas-tus-fotograf-as-del-evento-usando-el-siguiente-hashtag-en-todas-tus-pu.png",
    ],
  };

  const images = [
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  const names = [
    { name: "Angel Jaimes", guess: "2" },
    { name: "Rojas López", guess: "4" },
    { name: "Fam Rojas López", guess: "4" },
    { name: "Fam Rodríguez Rojas", guess: "4" },
    { name: "Fam Rojas Vera", guess: "4" },
    { name: "Fam González Rojas", guess: "6" },
    { name: "Fam Marino Rojas", guess: "5" },
    { name: "Fam Rojas Flores", guess: "4" },
    { name: "Lilibeth Rojas", guess: "3" },
    { name: "Fam Rojas Suñiga", guess: "2" },
    { name: "Guadalupe Rojas Suñiga", guess: "2" },
    { name: "Hugo Rojas Suñiga", guess: "1" },
    { name: "Familia Rojas García", guess: "3" },
    { name: "José Rojas & Esposa", guess: "2" },
    { name: "Rosa Antunez", guess: "1" },
    { name: "Rojas Gaona", guess: "3" },
    { name: "Fam Perez Rojas", guess: "4" },
    { name: "Fam Román Rojas", guess: "5" },
    { name: "Ana Rojas", guess: "3" },
    { name: "Fam López Rojas", guess: "3" },
    { name: "Fam Corazon Porcayo", guess: "4" },
    { name: "Baltazar Porcayo", guess: "4" },
    { name: "Maritza Porcayo", guess: "3" },
    { name: "Jazmin Porcayo", guess: "4" },
    { name: "Beto Porcayo", guess: "5" },
    { name: "Fam Armenta López", guess: "3" },
    { name: "Eréndira Armenta", guess: "4" },
    { name: "Alejandra Armenta", guess: "3" },
    { name: "Sergio Armenta", guess: "4" },
    { name: "Lucrecia Lopez", guess: "4" },
    { name: "Fam Gómez Márquez", guess: "4" },
    { name: "Carlos Marquez", guess: "3" },
    { name: "Alberto Márquez", guess: "3" },
    { name: "Cirenia Martínez Rios", guess: "1" },
    { name: "Antonia López Martínez", guess: "1" },
    { name: "Ramos López", guess: "4" },
    { name: "Briselda López", guess: "6" },
    { name: "Armando López", guess: "3" },
    { name: "Fam Tacuba", guess: "9" },
    { name: "Fam Badillo Pedrosa", guess: "5" },
    { name: "Fam Miranda Brito", guess: "2" },
    { name: "Amy Miranda", guess: "4" },
    { name: "Pedro Miranda", guess: "4" },
    { name: "Luis Antonio Ocampo", guess: "5" },
    { name: "Miguel Díaz García", guess: "2" },
    { name: "Fam Estrada Díaz", guess: "4" },
    { name: "Fam Campos Díaz", guess: "3" },
    { name: "Fam Vargas Diaz", guess: "2" },
    { name: "Fer Vargas & Esposo", guess: "2" },
    { name: "ANYEL DIAZ", guess: "2" },
    { name: "Gregorio Diaz", guess: "2" },
    { name: "Fan Garzón Díaz", guess: "4" },
    { name: "Fam Garzón Díaz", guess: "4" },
    { name: "Fam Estrada Díaz", guess: "4" },
    { name: "Aarón Miranda", guess: "1" },
    { name: "Roman Romero", guess: "2" },
    { name: "Nicolás García", guess: "6" },
    { name: "Guadalupe catalán", guess: "3" },
    { name: "Virginia Ruiz", guess: "2" },
    { name: "Dina Rogel", guess: "4" },
    { name: "Flor Rogel", guess: "4" },
    { name: "Meliá Rogel", guess: "3" },
    { name: "Roberto González Ruiz", guess: "4" },
    { name: "Montiel Arellano", guess: "2" },
    { name: "Fam Tranquilino Montiel", guess: "4" },
    { name: "Cristian Montiel", guess: "3" },
    { name: "Fan Estrada Vargas", guess: "9" },
    { name: "Fam Estrada Vargas", guess: "9" },
    { name: "Katerine Vargas", guess: "4" },
    { name: "Ocampo Vargas", guess: "2" },
    { name: "Fam Esquivel Salgado", guess: "3" },
    { name: "Lucy Vargas", guess: "3" },
    { name: "Gerardo Ocampo", guess: "4" },
    { name: "Filiberto Ocampo", guess: "4" },
    { name: "Lola Ocampo", guess: "6" },
    { name: "José Ocampo", guess: "5" },
    { name: "Esthela García Popoca", guess: "7" },
    { name: "Filiberto Vásquez", guess: "4" },
    { name: "Noemí Vásquez", guess: "6" },
    { name: "Franco Castro", guess: "2" },
    { name: "Reyna Santos García", guess: "3" },
    { name: "Ana Guadalupe Suastegui", guess: "5" },
    { name: "Daniel Díaz", guess: "2" },
    { name: "Arath Sánchez & Fam", guess: "4" },
    { name: "Andrea Paulin", guess: "2" },
    { name: "Sofía Araujo & Fam", guess: "3" },
    { name: "Sofía Samora", guess: "1" },
    { name: "Diego Díaz", guess: "3" },
    { name: "Andrea Paulin", guess: "1" },
    { name: "Sofía Zamora", guess: "1" },
    { name: "Fam Rodriguez Díaz", guess: "4" },
    { name: "Lizbeth Mota", guess: "1" },
    { name: "Said Yasid Hernández", guess: "1" },
    { name: "Alejandro Estiubarte", guess: "1" },
    { name: "Maresa Quevedo & Fam", guess: "4" },
    { name: "Emmanuel Ramírez & Fam", guess: "5" },
    { name: "Kevin Gómez", guess: "1" },
    { name: "Fam Sandoval", guess: "10" },
    { name: "Gilberto Sánchez Kubame", guess: "8" },
    { name: "Lizbeth Michelle Rosas", guess: "3" },
    { name: "Abdiel Torres", guess: "4" },
    { name: "José Carlos Nava", guess: "4" },
    { name: "Fam Martínez Portillo", guess: "7" },
    { name: "Fam Verá Montero", guess: "4" },
    { name: "Fam Gama Montero", guess: "3" },
  ];

  useEffect(() => {
    const findName = names.find((e) => e.name == nameId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? <ModalXv isOpen={isOpen} setIsOpen={setIsOpen} /> : ""}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        // <HospedajeXvComponent
        //   peltaColores={peltaColores}
        //   datosSeccionHospedaje={datosSeccionHospedaje}
        // />
        <ImagenComponent datosSeccionFoto={datosSeccionFoto} />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
