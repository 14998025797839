/* eslint-disable eqeqeq */
import React from 'react';
import {
    Label,
    Input
} from "reactstrap";
import { ComboBox } from './ComboBox'
import './ImageFormComponent.css'

export const ImageFormComponent = ({ label, field, element, errors, touched, setFieldValue, elementClass, preset, ingles }) =>
{
    const imgField = `${field}Img`;
    const imgComplete = `${field}list`;
    const fileField = `${field}File`;
    const divClass = elementClass??"col-span-1 m-3";
    const src = element[imgField];

    return (
        <div className={`${divClass} bg-white shadow-md rounded-lg p-4`}>
            <h5 className="text-lg font-semibold mb-3 text-center">{label}</h5>
            <div className="flex flex-col items-center">
                <div className="mb-4 w-full flex justify-center">
                    <div className="previewImgParent bg-gray-100 rounded-lg overflow-hidden" style={{height: '300px', width: '200px'}}>
                        <img 
                            id={`${imgField}Preview`} 
                            className="previewImg w-full h-full object-cover" 
                            alt="" 
                            src={src || 'https://via.placeholder.com/200x300'} 
                        />
                    </div>
                </div>
                <div className="w-full max-w-xs">
                    <ComboBox field={field} values={element} setFieldValue={setFieldValue} preset={preset} className="mb-3" />
                    <div className="relative mt-3">
                        <Input
                            type="file"
                            placeholder="Seleccionar Foto"
                            id={fileField}
                            name={fileField}
                            onChange={(event) => {
                                var img = `${imgField}Preview`;
                                document.getElementById(img).setAttribute("src", URL.createObjectURL(event.currentTarget.files[0]));
                                setFieldValue(fileField, event.currentTarget.files[0]);
                            }}
                            invalid={errors[imgField] && touched[imgField]}
                            className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                        />
                        <Label htmlFor={fileField} className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer hover:bg-blue-600 transition duration-300 flex justify-center">
                            {ingles ? "Select Photo" : "Seleccionar Foto"}
                        </Label>
                    </div>
                    {touched[imgField] && errors[imgField] && (
                        <div className="text-red-500 mt-2 text-center">{errors[imgField]}</div>
                    )}
                </div>
            </div>
        </div>
    )
}