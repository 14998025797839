import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import audioFondo from "./ThousandYears.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import ModalXv from "../../../components/Modals/ModalXv";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const XvSusan = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Susan Elizabeth",
      fechaDeBoda: "08 JUNIO, 2024",
      fechaNewDate: "June 08, 2024 14:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/BZgQ96zy/pro-0-Yic-Y281.jpg",
      numeroNovia: "+524622566481",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#f0c5c5",
      color2: "#d6c798",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Monserrat Lira!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial, primero que todo le doy las gracias a Dios por haberme permitido estar aquí y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mi.",
      fechaNewDate: "June 08, 2024 14:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/52jtmVnr/pro-TBp-ETZ5v.jpg",
      fotoDerecha: "https://i.postimg.cc/T1CRr7mG/pro-H8vt1-Fu-H.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Adriana Elizabeth Villegas Mosqueda",
      esposaPadre: "José Rafael Villegas Querea",
      esposoMadre: "Lidia Mosqueda",
      esposoPadre: "Susana Querea",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Sábado, 08 de Junio de 2024.",
      ceremoniaHora: "12:00 p.m.",
      ceremoniaNombreLugar: "Templo Hospitalito",
      ceremoniaLugarMap:
        "Pedro Moreno 50, Col. Centro, 36500 Irapuato, Gto.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/2Lqmjp6zWzSac5DZA",
      recepcionFecha: "Sábado, 08 de Junio de 2024.",
      recepcionHora: "2:00 p.m.",
      recepcionNombreLugar: "C. Juan de La Barrera",
      recepcionLugarMap:
        "C. Juan de La Barrera 1549",
      recepcionUrl:
        "https://maps.app.goo.gl/muoSefgPvaxqoHeUA",
    };
  
  
    const images = [
      "https://i.postimg.cc/26N8mBrp/pro-knen-Dhc-X.jpg",
      "https://i.postimg.cc/RZ8ZsDYZ/pro-RWvr-Jrlp.jpg",
      "https://i.postimg.cc/c4FHYHMY/pro-u-Yt9r-AVN.jpg",
      "https://i.postimg.cc/R0HVjTrQ/pro-6r-ABh6-Uw.jpg",
      "https://i.postimg.cc/C1CMKLxR/pro-Bpcwa-KQI.jpg"
      // Add more image URLs as needed
    ];
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
