import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaOmarYNohemi = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");
  const [isIOS, setIsIOS] = useState(false);

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#ff00ff",
    color2: "#ff8d03",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NErbPYptYWA_y2mate.com---Carlos-Rivera-Otras-Vidas-Lyric-Video.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Omar",
    esposa: "Nohemi",
    fechaDeBoda: "29 JUNIO, 2024",
    imgPortada:
      "https://i.postimg.cc/L4QVSjNh/5dbf4c2d-dd39-4ad6-9a5e-5ead2b0105cf.jpg",
    titulo: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "Después de 2 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    fechaNewDate: "June 29, 2024 17:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/fLLNry0z/Whats-App-Image-2024-05-16-at-7-11-41-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/9f2dbbFg/45deebcb-f2a9-43c8-8546-3334dea603e7.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    titulo1: "Padres de la Novia",
    madre: "✟Miguel Hernandez Tello✟",
    padre: "María Beatriz Garcia Carranza",
    titulo2: "Padres del Novio",
    madrina: "Alejandro Garza Buenrostro",
    padrino: "Rocío Torillo Rivera",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 29 de Junio de 2024.",
    ceremoniaHora: "5:30 p.m.",
    ceremoniaNombreLugar: "Salon bunker",
    ceremoniaLugarMap: "",
    ceremoniaUrl: "https://maps.app.goo.gl/gGJcC5Z41W1TsGDK7",
    recepcionEnable: false,
    recepcionFecha: "Sábado, 29 de Junio de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "El Herradero Jardín y Eventos",
    recepcionLugarMap:
      "Camino real a paso blanco #2000 esquina con champagne, 20900 Paso Blanco, Ags.",
    recepcionUrl:
      "https://www.google.com/maps/place/El+Herradero+Jardin+%26+Eventos/@21.9643771,-102.3057911,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e561c6edc0a5:0x831a126aad45ec50!8m2!3d21.9643771!4d-102.3057911!16s%2Fg%2F11mhk0t4dn?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "No te pierdas nuestro intercambio de votos",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "Formal mexicana",
    messageVestimenta: "El color blanco queda reservado para los novios",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion: "Tu presencia es el mejor regalo, pero si gustan regalarnos algo puede ser físico.",
    link1: "",
    imgLink1:
      "https://i.postimg.cc/jjxSWZVH/christmas-day-theme-3d-illustration-with-gift-box-free-png.webp",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2:
      "https://i.postimg.cc/jqwMNWgp/Cream-Brown-Minimalist-Simple-Birthday-Invitation-3.png",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/xC4WtvYH/FCB7-AC5-B-2305-4392-BF1-C-DD932-E34-C8-E4.png ",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "",
    numeroConfirmacion: "+525524271682",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+525524271682",
    numeroNovio: "+525573440615",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = false;
  const vestimenta = true;

  const images = [
    "https://i.postimg.cc/L4QVSjNh/5dbf4c2d-dd39-4ad6-9a5e-5ead2b0105cf.jpg",
    "https://i.postimg.cc/fLLNry0z/Whats-App-Image-2024-05-16-at-7-11-41-PM.jpg",
    "https://i.postimg.cc/9f2dbbFg/45deebcb-f2a9-43c8-8546-3334dea603e7.jpg",
    "https://i.postimg.cc/d3hr3S43/8a71df72-7d24-46d9-a24c-374c662a0160.jpg",
    "https://i.postimg.cc/W3bML4ZM/8fbbc4ca-4edd-4cb0-8824-a3a920b54eb4.jpg",
    "https://i.postimg.cc/8Cbh0v7p/92e0409c-b2d0-4371-b0e1-fca4256f01e9.jpg",
    "https://i.postimg.cc/qM9Xh4fT/98cbf371-4230-449f-b7db-ea0fa98ec0fe.jpg",
    "https://i.postimg.cc/76sSxy1j/a7b16fb1-edb8-432b-888d-96dda2a09043.jpg",
    "https://i.postimg.cc/hPJ1th5s/aa223d9c-18be-4b12-94b1-a30ac7fd0bf5.jpg",
    "https://i.postimg.cc/Y0TgWfs3/b9be71d2-e0f1-46b9-a154-69235c1996dc.jpg",
    // Add more image URLs as needed
  ];

  const names = [
  
    { name: "Oscar Gómez", guess: "4", id: 3 },
    { name: "Iván Garza", guess: "2", id: 4 },
    { name: "Ivancito Garza", guess: "4", id: 5 },
    { name: "Andrés Aguilar", guess: "4", id: 6 },
    { name: "Eduardo Ramírez", guess: "2", id: 7 },
    { name: "David Ramírez", guess: "5", id: 8 },
    { name: "Said Garza", guess: "1", id: 9 },
    { name: "Luis Bouquet", guess: "2", id: 10 },
    { name: "Roberto Magaña", guess: "4", id: 11 },
    { name: "Abel", guess: "2", id: 12 },
    { name: "Jaime", guess: "2", id: 13 },
    { name: "Jorge Castillo", guess: "2", id: 14 },
    { name: "Eric Castillo", guess: "2", id: 15 },
    { name: "Jorge Ramon Castillo", guess: "2", id: 16 },
    { name: "Miriam Castillo", guess: "3", id: 17 },
    { name: "Josefina Garza", guess: "2", id: 18 },
    { name: "Luis Dante Hernández", guess: "2", id: 19 },
    { name: "María Luz Garza", guess: "1", id: 20 },
    { name: "Ariel Hernández", guess: "4", id: 21 },
    { name: "Álvaro Peralta", guess: "2", id: 22 },
    { name: "Dr. Román", guess: "2", id: 23 },
    { name: "Oscar", guess: "2", id: 24 },
    { name: "Diego", guess: "2", id: 25 },
    { name: "Margarita Ladrón", guess: "2", id: 26 },
    { name: "Erandi Eunice", guess: "2", id: 27 },
    { name: "Alfredo Torillo", guess: "2", id: 28 },
    { name: "Carlos Torillo", guess: "4", id: 29 },
    { name: "Fernando Ramírez", guess: "4", id: 30 },
    { name: "Frank Novoa", guess: "2", id: 31 },
    { name: "Uriel Gamboa", guess: "2", id: 32 },
    { name: "Sergio Martínez", guess: "2", id: 33 },
    { name: "Rubén Blass", guess: "5", id: 34 },
    { name: "Xóchilt Juárez", guess: "2", id: 35 },
    { name: "María Garcia", guess: "1", id: 36 },
    { name: "José Tinoco", guess: "5", id: 37 },
    { name: "Alma Juárez", guess: "5", id: 38 },
    { name: "Alejandro Garza", guess: "2", id: 39 },
    { name: "Carlos Vargas", guess: "4", id: 40 },
    { name: "Fernando Chávez", guess: "6", id: 41 },
    { name: "Gabriela Barsalobre", guess: "2", id: 42 },
    { name: "Gabriela Bermúdez", guess: "4", id: 43 },
    { name: "Paola Silva", guess: "2", id: 44 },
    { name: "Ana Vega", guess: "2", id: 45 },
    { name: "Víctor Alonso", guess: "2", id: 46 },
    { name: "Gloria García", guess: "5", id: 47 },
    { name: "Janeth Mendoza", guess: "2", id: 48 },
    { name: "Víctor Galicia", guess: "2", id: 49 },
    { name: "Rosario Galicia", guess: "2", id: 50 },
    { name: "Angela Franco", guess: "2", id: 51 },
    { name: "María Elena Mendoza", guess: "4", id: 52 },
    { name: "Víctor Crecencio", guess: "3", id: 53 },
    { name: "Frida Pérez", guess: "2", id: 54 },
    { name: "Erika Mundo", guess: "2", id: 55 },
    { name: "Roberto Ayala", guess: "2", id: 56 },
    { name: "Noemi González", guess: "5", id: 58 },
    { name: "Nancy Eliud", guess: "2", id: 59 },
    { name: "Fernando Martinez", guess: "2", id: 60} ,
    { name: "Roberto Saldaña", guess: "2", id: 61 }
  ];
  

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionVestimenta.imgFondo || ""})`,
            backgroundColor: peltaColores.color1,
            backgroundAttachment: isIOS ? "local" : "fixed",

            color: peltaColores.textColor ? peltaColores.textColor : "",
          }}
          className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
        >
          <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
            <h1
              className="font-serif text-center sm:text-[32px] md:text-[42px]"
              data-aos="flip-up"
            >
              Código de vestimenta
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl"
              data-aos="flip-up"
            >
              {datosSeccionVestimenta.vestimenta
                ? datosSeccionVestimenta.vestimenta
                : "FORMAL"}
            </p>
            <br />
            {datosSeccionVestimenta.messageVestimenta ? (
              <p className="text-center pt-[5vh] font-semibold text-xl w-[80%]">
                {datosSeccionVestimenta.messageVestimenta
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            ) : (
              ""
            )}

            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[5vh]">
              <div
                className="flex flex-col justify-center items-center"
                data-aos="fade-left"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaMujer ||
                    "https://i.postimg.cc/vZng3Lwk/de281060-58cc-423a-882c-c7c235696fe1-removebg-preview.png"
                  }
                  className="h-[30vh]"
                  alt=""
                />
                <br />
                <p className="font-serif text-2xl">Mujeres</p>
              </div>
              <div
                className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                data-aos="fade-right"
              >
                <img
                  src={
                    datosSeccionVestimenta.urlVestimentaHombre ||
                    "https://i.postimg.cc/9fj4SRWM/a4c00ec2-c0d0-4783-b64a-4f0baa49bc4a-removebg-preview.png"
                  }
                  className="h-[30vh]"
                  alt=""
                />
                <br />
                <p className="font-serif text-2xl">Hombres</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
