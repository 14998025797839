import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { FaPhotoVideo } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import audioFondo from "./assets/Crash.mp3";
import { Contador } from "../../../components/Contador";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Confirmacion } from "../../../components/Confirmacion";
import { PortadaBoda } from "../../../components/PortadaBoda";

export const BodaRicardoGeneral = () => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("name");
  const numPases = searchParams.get("pases");

  const datosSeccion1 = {
    textoConfirmacion: "a nuestra boda",
    esposo: "Ricardo",
    esposa: "Karla",
    fechaDeBoda: "12 ABRIL, 2024",
    fechaNewDate: "April 12, 2024 19:00:00 GMT+00:00",
    imgPortada: "https://i.postimg.cc/QdDK2V5Y/img2-LNQe-BQNWm-transformed.jpg",
    numeroNovia: "4491897906",
    numeroNovio: "4491378019",
    novios: true,
  };

  const peltaColores = {
    color1: "#e8e8e8",
    color2: "#c7c7c7",
  };

  const datosSeccion2 = {
    titulo: "¡Nos Casamos!",
    descipcion:
      "¡A ponerse guapos que nos vamos a casar! Tenemos todo… el vestido, el traje, el coche, las flores, la iglesia y el lugar de la recepción... Pero nos falta lo más importante: ¡Contar contigo en este día tan especial!",
    fechaNewDate: "April 12, 2024 19:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/4nCV7djq/im3.jpg",
    fotoDerecha: "https://i.postimg.cc/P5sQN3jJ/img4.jpg",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Karla y Ricardo!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccion3 = {
    titulo: "¡Celebra el amor y apoyo de nuestros padres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra inolvidable unión.",
    tituloIzquierda: "Padres de la novia",
    tituloDerecha: "Padres del novio",
    esposaMadre: "Hortencia Reyes Hernández",
    esposaPadre: "Héctor Hernández",
    esposoMadre: "Ma del Socorro Hernández González",
    esposoPadre: "José González Popoca",
  };

  const datosSeccion4 = {
    ceremoniaFecha: "Viernes, 12 de Abril de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Templo del Señor del Encino",
    ceremoniaLugarMap: "Jardin, Barrio del Encino, 20240 Aguascalientes",
    ceremoniaUrl: "https://maps.app.goo.gl/3Pbq9J7utP79GfLD9",
    recepcionFecha: "Viernes, 12 de Abril de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "Lago del Marqués",
    recepcionLugarMap:
      "Hacienda Nueva, San Ignacio, 20313 Aguascalientes, Ags.",
    recepcionUrl: "https://maps.app.goo.gl/tvvBqqmLXgrN7XaY6",
  };

  const datosSeccion6 = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    ceremoniaCivil: "No te pierdas nuestro intercambio de votos",
    cena: "Disfruta de una deliciosa cena",
    recepcion: "Tómate fotos con nosotros",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccion7 = {
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
  };

  const datosSeccion8 = {
    descripcion:
      "Explora con encanto nuestra selección de regalos para la boda. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. ¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    linkSears: "https://www.sears.com.mx/Mesa-de-Regalos/64631/invitacion",
    linkLiverpool:
      "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51182191",
      linkAmazon: "https://www.amazon.com.mx/wedding/registry/16JNDVLLEX9YO",
  };

  const imagenesFondo = {
    fondoInvitados: 'url("https://i.postimg.cc/jdSz8MV3/img6-transformed.jpg")',
    fondoVestimenta:
      'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
  };

  const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [pases, setPases] = useState(0);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const images = [
    "https://i.postimg.cc/7LX3sqTJ/img1.jpg",
    "https://i.postimg.cc/J0HjzJYj/img7.jpg",
    "https://i.postimg.cc/k4pxCwsn/img5.jpg",
    "https://i.postimg.cc/t43t63q5/img6.jpg",
    // Add more image URLs as needed
  ];

  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {

    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));

    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
    {/* <ModalBoda
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      /> */}

    <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

    <Contador peltaColores={peltaColores} datosSeccion2={datosSeccion2} />

    <Padres peltaColores={peltaColores} datosSeccion3={datosSeccion3} />

    <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

    <Vestimenta peltaColores={peltaColores} imagenesFondo={imagenesFondo} />

    <div
      style={{
        // backgroundImage:
        //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
      }}
      className="sm:h-auto md:h-[150vh] flex flex-col items-center w-full"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          Programa
        </h1>
        <br />
        <p
          className=" text-center pt-[5vh] font-serif text-xl"
          data-aos="flip-up"
        >
          Hemos planeado un día lleno de diversión para disfrutar al máximo.
        </p>
        <br />
        <p
          className=" text-center pt-[5vh] font-bold text-base "
          data-aos="flip-up"
        >
          Este es un evento planeado solo para adultos, respetuosamente solicitando que los niños se queden en casa.
        </p>
        <div className="grid sm:grid-cols-1 md:grid-cols-1 w-[80%] pt-[10vh]">
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            <PiChurchLight className="text-[60px]" />
            <br />
            <p className="font-serif text-xl text-center">
              CEREMONIA RELIGIOSA
            </p>
            <br />
            <p className="font-serif text-xl text-center w-[60%]">
              {datosSeccion6.ceremoniaReligiosa}
            </p>
            <br />
            <hr className="bg-gray-700 h-1 w-[60%]" />
            <br />
            <FaPhotoVideo className="text-[60px]" />
            <br />
            <p className="font-serif text-xl text-center">RECEPCIÓN</p>
            <br />
            <p className="font-serif text-xl text-center w-[60%]">
              {datosSeccion6.recepcion}
            </p>
            <br />
            <hr className="bg-gray-700 h-1 w-[60%]" />
            <br />
            <FaChampagneGlasses className="text-[60px]" />
            <br />
            <p className="font-serif text-xl text-center">CÓCTEL</p>
            <br />
            <p className="font-serif text-xl text-center w-[60%]">
              {datosSeccion6.coctel}
            </p>
            <br />
            <hr className="bg-gray-700 h-1 w-[60%]" />
            {/* <br />
              <GiBigDiamondRing className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CEREMONIA CIVIL</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.ceremoniaCivil}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" /> 
              <br />
              <ImSpoonKnife className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">CENA</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.cena}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              */}
          </div>

          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-right"
          >
            {/* <br />
              <GiMeshBall className="text-[60px]" />
              <br />
              <p className="font-serif text-xl text-center">BAILE</p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]">
                {datosSeccion6.baile}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" /> */}
          </div>
        </div>
      </div>
    </div>

    <div
      style={{
        // backgroundImage:
        //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
      }}
      className="sm:h-auto p-3 md:h-[100vh] flex flex-col items-center w-full"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          Ubicaciones
        </h1>
        <br />

        <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[8vh]">
          <div
            className="flex flex-col w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
            data-aos="fade-right"
          >
            <div
              style={{
                backgroundImage:
                'url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/P%C3%B3rtico_del_Templo_del_Se%C3%B1or_del_Encino%2C_Aguascalientes%2C_Ags..JPG/1200px-P%C3%B3rtico_del_Templo_del_Se%C3%B1or_del_Encino%2C_Aguascalientes%2C_Ags..JPG")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
              }}
              className="min-h-[50%] w-full flex flex-col justify-end"
            >
            </div>
            <div className="min-h-[50%] w-full flex flex-col justify-end">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.544796121481!2d-102.29306919999999!3d21.875087300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee796b62a955%3A0x6f40669481f287ec!2sTemplo%20del%20Se%C3%B1or%20del%20Encino!5e0!3m2!1ses-419!2smx!4v1708319635105!5m2!1ses-419!2smx"
                width="100%"
                height="50%"
                className="fixed"
              ></iframe>
              <a
                href={datosSeccion4.ceremoniaUrl}
                class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
              >
                Mapa de la Ceremonia
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
          </div>
          <div
            className="flex flex-col sm:mt-7 md:mt-0 w-[90%] rounded-2xl bg-slate-100 drop-shadow-2xl h-[70vh]"
            data-aos="fade-right"
          >
             <div
              style={{
                backgroundImage:
                'url("https://lh3.googleusercontent.com/p/AF1QipONJ5sUXCnKhS9Ard5Bdanz5CKPn9B1G4T5B4P2=w1080-h608-p-no-v0")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
              }}
              className="min-h-[50%] w-full flex flex-col justify-end"
            >
            </div>
            <div className="min-h-[50%] w-full flex flex-col justify-end">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.978330627437!2d-102.35711590000001!3d21.896910200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429e91a7cb08e6b%3A0xbc87e841133aa4ba!2sLago%20del%20Marqu%C3%A9s!5e0!3m2!1ses-419!2smx!4v1708319702363!5m2!1ses-419!2smx"
              width="100%"
              height="50%"
              className="fixed"
            ></iframe>
            <a
              href={datosSeccion4.recepcionUrl}
              class="overflow-hidden w-auto relative p-2 h-12 bg-black text-white text-center border-none rounded-md font-bold cursor-pointer z-10 group animate-bounce"
            >
              Mapa de la Recepción
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style={{
        // backgroundImage:
        //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
      }}
      className="sm:h-auto md:h-[100vh] flex flex-col sm:p-3 md:p-0 items-center w-full"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
        <h1
          className="font-serif text-center sm:text-[32px] md:text-[42px]"
          data-aos="flip-up"
        >
          Mesa de regalos
        </h1>
        <br />
        <p
          className=" text-center pt-[5vh] font-serif text-xl sm:max-w-[90%] md:max-w-[60%]"
          data-aos="flip-up"
        >
          {datosSeccion8.descripcion}
        </p>

        <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[8vh]">
          <div
            style={{
              backgroundImage:
                'url("https://centrosantafe.com.mx/cdn/shop/files/Sears.png?v=6586984057980492581")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              backgroundColor: peltaColores.color1,
            }}
            className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
            data-aos="fade-right"
          >
            <a
              href={datosSeccion8.linkSears}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mesa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
          <div
            style={{
              backgroundImage:
                'url("https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              backgroundColor: peltaColores.color1,
            }}
            className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
            data-aos="fade-right"
          >
            <a
              href={datosSeccion8.linkLiverpool}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mesa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
          <div
            style={{
              backgroundImage:
                'url("https://www.gphlegal.mx/wp/wp-content/uploads/2019/07/financiacion-amazon.jpg")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              backgroundPosition: "center center",
              backgroundColor: peltaColores.color1,
            }}
            className="flex flex-col justify-end w-[90%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] sm:mt-4 md:mt-0"
            data-aos="fade-right"
          >
            <a
              href={datosSeccion8.linkAmazon}
              class="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
            >
              Ver mesa!
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                Explore!
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <Confirmacion
      peltaColores={peltaColores}
      images={images}
      messageWhats={'Hola, confirmo mi asistencia a la boda. ¡Nos vemos allí!'}
      datosSeccion1={datosSeccion1}
    />
  </div>
  )
}
