// Importa los estilos de Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";

export const ModalEditarEvento = ({
  modalEditar,
  setModalEditar,
  infoEdit,
}) => {
  const toggleModalEditar = () => setModalEditar(false);
  const [submiting, setSubmiting] = useState(false);
  const [admins, setAdmins] = useState([]);

  // Esquema de validación usando Yup
  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required("Campo requerido"),
    nombreComprador: Yup.string().required("Campo requerido"),
    numComprador: Yup.string().required("Campo requerido"),
    tipoEvento: Yup.string().required("Campo requerido"),
    paquete: Yup.string().required("Campo requerido"),
    fechaInicio: Yup.date().required("Campo requerido"),
  });

  const fetchAdmins = async () => {
    try {
      const responseAdmin = await axios.get(`/api/Admins`);
      console.log(responseAdmin.data);
      setAdmins(responseAdmin.data);
    } catch (error) {
      console.log(error);
    }
  };
  // Función para enviar los datos del formulario
  const enviarDatos = async (values) => {
    setSubmiting(true);
    console.log("edit:", values);
    try {
      // Realizar la solicitud PUT a la URL especificada con los datos del formulario
      const response = await axios.put(
        `/api/Eventos/${values.idEvento}`,
        values
      );

      // Manejar la respuesta de la solicitud
      console.log("Respuesta del servidor:", response.data);
      setSubmiting(false);

      // Cerrar el modal después de enviar los datos
      toggleModalEditar();
    } catch (error) {
      // Manejar errores en caso de que la solicitud falle
      console.error("Error al enviar los datos:", error);
      setSubmiting(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  console.log("Info del edit", infoEdit);

  return (
    <Modal
      isOpen={modalEditar}
      toggle={toggleModalEditar}
      backdropOpacity={0.5}
    >
      <ModalHeader toggle={toggleModalEditar}>Editar Evento</ModalHeader>
      <Formik
        initialValues={{
          idEvento: infoEdit?.idEvento, // Agrega el campo 'id' para identificar el evento a editar
          nombre: infoEdit?.nombre,
          nombreComprador: infoEdit?.nombreComprador,
          numComprador: infoEdit?.numComprador,
          tipoEvento: infoEdit?.tipoEvento,
          paquete: infoEdit?.paquete,
          fechaInicio: infoEdit?.fechaInicio,
          fechaFin: infoEdit?.fechaFin,
          monto: infoEdit?.monto,
          total: infoEdit?.total,
          socio: infoEdit?.socio,
          detalles: infoEdit?.detalles,
          enableCeremonia: infoEdit?.enableCeremonia,
          enableConfirmacion: infoEdit?.enableConfirmacion,
          enableContador: infoEdit?.enableContador,
          enableHospedaje: infoEdit?.enableHospedaje,
          enableInvitados: infoEdit?.enableInvitados,
          enablePadres: infoEdit?.enablePadres,
          enablePortada: infoEdit?.enablePortada,
          enablePrograma: infoEdit?.enablePrograma,
          enableRegalos: infoEdit?.enableRegalos,
          enableUbicacion: infoEdit?.enableUbicacion,
          enableVestimenta: infoEdit?.enableVestimenta,
          enableEvento: infoEdit?.enableEvento,
          enableCarta: infoEdit?.enableCarta,
          ingles: infoEdit?.ingles,
          vendedor: infoEdit?.vendedor,
          mail: infoEdit?.mail,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          enviarDatos(values);
        }}
      >
        {({ values, handleChange, handleSubmit, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="vendedor">Vendedor</Label>
                <Input
                  type="select"
                  name="vendedor"
                  id="vendedor"
                  value={values.vendedor}
                  onChange={handleChange}
                  invalid={errors.vendedor && touched.vendedor}
                >
                  <option value="">Seleccione un tipo de evento</option>
                  {Array.isArray(admins) && admins.length > 0 ? (
                    admins.map((item) => (
                      <option key={item.nombre} value={item.nombre}>
                        {item.nombre}
                      </option>
                    ))
                  ) : (
                    <h1>Cargando...</h1> // Show this if `admins` is not loaded or is empty
                  )}
                </Input>
                <ErrorMessage
                  name="vendedor"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="nombre">Nombre</Label>
                <Input
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  invalid={errors.nombre && touched.nombre}
                />
                <ErrorMessage
                  name="nombre"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="nombreComprador">Nombre del Comprador</Label>
                <Input
                  type="text"
                  name="nombreComprador"
                  id="nombreComprador"
                  value={values.nombreComprador}
                  onChange={handleChange}
                  invalid={errors.nombreComprador && touched.nombreComprador}
                />
                <ErrorMessage
                  name="nombreComprador"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="numComprador">Número del Comprador</Label>
                <Input
                  type="text"
                  name="numComprador"
                  id="numComprador"
                  value={values.numComprador}
                  onChange={handleChange}
                  invalid={errors.numComprador && touched.numComprador}
                />
                <ErrorMessage
                  name="numComprador"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="mail">Correo del Comprador</Label>
                <Input
                  type="text"
                  name="mail"
                  id="mail"
                  value={values.mail}
                  onChange={handleChange}
                  invalid={errors.mail && touched.mail}
                />
                <ErrorMessage
                  name="mail"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="tipoEvento">Tipo de Evento</Label>
                <Input
                  type="text"
                  name="tipoEvento"
                  id="tipoEvento"
                  value={values.tipoEvento}
                  onChange={handleChange}
                  invalid={errors.tipoEvento && touched.tipoEvento}
                />
                <ErrorMessage
                  name="tipoEvento"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="paquete">Paquete</Label>
                <Input
                  type="select"
                  name="paquete"
                  id="paquete"
                  value={values.paquete}
                  onChange={handleChange}
                  invalid={errors.paquete && touched.paquete}
                >
                  <option value="">Seleccione un tipo de evento</option>
                  <option value="Plata">Plata</option>
                  <option value="Oro">Oro</option>
                  <option value="Diamante">Diamante</option>
                </Input>
                <ErrorMessage
                  name="paquete"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="fechaInicio">Fecha de Inicio</Label>
                <Input
                  type="date"
                  name="fechaInicio"
                  id="fechaInicio"
                  value={values.fechaInicio}
                  onChange={handleChange}
                  invalid={errors.fechaInicio && touched.fechaInicio}
                />
                <ErrorMessage
                  name="fechaInicio"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>

              <FormGroup>
                <Label for="fechaFin">Estatus</Label>
                <Input
                  type="select"
                  name="fechaFin"
                  id="fechaFin"
                  value={values.fechaFin}
                  onChange={handleChange}
                  invalid={errors.fechaFin && touched.fechaFin}
                >
                  <option value="">Seleccione un tipo de evento</option>
                  <option value="Nada">Nada</option>
                  <option value="Formulario">Formulario Listo</option>
                  <option value="Formateado">Formateado</option>
                  <option value="Entregado">Entregado</option>
                </Input>
                <ErrorMessage
                  name="tipoEvento"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="monto">Monto</Label>
                <Input
                  type="text"
                  name="monto"
                  id="monto"
                  value={values.monto}
                  onChange={handleChange}
                  invalid={errors.monto && touched.monto}
                />
                <ErrorMessage
                  name="monto"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="total">Precio Total</Label>
                <Input
                  type="text"
                  name="total"
                  id="total"
                  value={values.total}
                  onChange={handleChange}
                  invalid={errors.total && touched.total}
                />
                <ErrorMessage
                  name="total"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="ingles">Invitacion en Ingles</Label>
                <Input
                  type="checkbox"
                  id="ingles"
                  name="ingles"
                  checked={values?.ingles}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    })
                  }
                />
                <ErrorMessage
                  name="enableEvento"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="detalles">Extras o Detalles</Label>
                <Input
                  type="text"
                  name="detalles"
                  id="detalles"
                  value={values.detalles}
                  onChange={handleChange}
                  invalid={errors.detalles && touched.detalles}
                />
                <ErrorMessage
                  name="detalles"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>

              <div className="grid grid-cols-3">
                <FormGroup>
                  <Label for="enableCeremonia">Ceremonia</Label>
                  <Input
                    type="checkbox"
                    id="enableCeremonia"
                    name="enableCeremonia"
                    checked={values?.enableCeremonia}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableCeremonia"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableConfirmacion">Confirmacion</Label>
                  <Input
                    type="checkbox"
                    id="enableConfirmacion"
                    name="enableConfirmacion"
                    checked={values?.enableConfirmacion}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableConfirmacion"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableContador">Contador</Label>
                  <Input
                    type="checkbox"
                    id="enableContador"
                    name="enableContador"
                    checked={values?.enableContador}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableContador"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableHospedaje">Hospedaje</Label>
                  <Input
                    type="checkbox"
                    id="enableHospedaje"
                    name="enableHospedaje"
                    checked={values?.enableHospedaje}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableHospedaje"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableInvitados">Invitados</Label>
                  <Input
                    type="checkbox"
                    id="enableInvitados"
                    name="enableInvitados"
                    checked={values?.enableInvitados}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableInvitados"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enablePadres">Padres</Label>
                  <Input
                    type="checkbox"
                    id="enablePadres"
                    name="enablePadres"
                    checked={values?.enablePadres}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enablePadres"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enablePrograma">Programa</Label>
                  <Input
                    type="checkbox"
                    id="enablePrograma"
                    name="enablePrograma"
                    checked={values?.enablePrograma}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enablePrograma"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableRegalos">Regalos</Label>
                  <Input
                    type="checkbox"
                    id="enableRegalos"
                    name="enableRegalos"
                    checked={values?.enableRegalos}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableRegalos"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableUbicacion">Ubicacion</Label>
                  <Input
                    type="checkbox"
                    id="enableUbicacion"
                    name="enableUbicacion"
                    checked={values?.enableUbicacion}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableUbicacion"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableVestimenta">Vestimenta</Label>
                  <Input
                    type="checkbox"
                    id="enableVestimenta"
                    name="enableVestimenta"
                    checked={values?.enableVestimenta}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableVestimenta"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="enableEvento">Deshabilitar Evento</Label>
                  <Input
                    type="checkbox"
                    id="enableEvento"
                    name="enableEvento"
                    checked={values?.enableEvento}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableEvento"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enableCarta">Deshabilitar Carta</Label>
                  <Input
                    type="checkbox"
                    id="enableCarta"
                    name="enableCarta"
                    checked={values?.enableCarta}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableEvento"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" disabled={submiting} color="primary">
                {submiting ? "Guardando..." : "Guardar Cambios"}
              </Button>
              <Button color="secondary" onClick={toggleModalEditar}>
                Cancelar
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
