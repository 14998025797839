import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";

export const BodaGemaYJosue = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#e4d1aa",
    color2: "#eceee7",
  };

  const datosSeccionPotadaBoda = {
    cancion:
      "https://www.cjoint.com/doc/24_05/NEcfhHMcTTR_y2mate.com---Hasta-mi-final.mp3",
    textoConfirmacion: " nuestra boda",
    esposo: "Josue",
    esposa: "Gema",
    fechaDeBoda: "12 OCTUBRE, 2024",
    imgPortada: "https://i.postimg.cc/ydR5D4NK/9.jpg",
    titulo: "NUESTRA GRAN BODA",
  };

  const datosSeccionContador = {
    titulo: "¡Nos casamos!",
    descipcion:
      "Solo bastó con vernos para saber que Dios nos había puesto en el camino. Después de 6 años juntos pero en diferente lugar, hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que seas partícipe de esta unión y con nosotros celebrar que el amor a primera vista existe y que el amor a distancia tiene éxito!",
    fechaNewDate: "October 12, 2024 20:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/GhdNmfn0/2.jpg",
    fotoDerecha: "https://i.postimg.cc/x8y4PvHF/6.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: 2,
    titulo: "¡Celebra el amor, con apoyo de nuestros padres!",
    descipcion: "Únete a nosotros para ser testigo de nuestra boda.",
    titulo1: "Padres de la Novia",
    madre: "Patricia Perez Sánchez",
    padre: "Juan Roberto Vasquez Hilario",
    titulo2: "Padres del Novio",
    madrina: "Flora Castillo Martínez",
    padrino: "Jorge Amador Garcia Suarez",
    titulo3: "",
    madrina2: "",
    padrino2: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 12 de Octubre de 2024.",
    ceremoniaHora: "4:00 p.m.",
    ceremoniaNombreLugar: "Santuario de nuestra senora de guadalupe",
    ceremoniaLugarMap: "Jardín SN, Guadalupe, 25750 Monclova, Coah.",
    ceremoniaUrl: "https://maps.app.goo.gl/gc9nQcFSMpokUxWT6",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 12 de Octubre de 2024.",
    recepcionHora: "8:00 p.m.",
    recepcionNombreLugar: "Quinta Anahi Maldonado",
    recepcionLugarMap:
      "Carretera #24 km7 Rancho san, Fransisco Villa, 25740 Monclova, Coah.",
    recepcionUrl: "https://maps.app.goo.gl/ueuP4btMUw5gLqPX9",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    civil: "",
    recepcion: "Tómate fotos con nosotros",
    cena: "Disfruta de una deliciosa cena",
    coctel: "",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: true,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "Fiesta Inn Monclova",
    hotel1lugar:
      "Blvd Harold R. Pape 1909 Col, Jardines de la Salle, 25730 Monclova, Coah.",
    hotel1url: "https://maps.app.goo.gl/bZRrwRUrT6wdKnfg7",
    hotel2Titulo: "Holiday Inn Monclova",
    hotel2lugar: "Blvd Harold R. Pape 200, Guadalupe, 25750 Monclova, Coah.",
    hotel2url: "https://maps.app.goo.gl/ZAdKndcB7zemLeM19",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Lluvia de Sobres",
    descripcion:
      "Su presencia es lo mas importante en este día tan especial, pero si algo nos quieren regalar, en efectivo lo sabemos apreciar.",
    link1: "https://www.amazon.com.mx/wedding/registry/30DP7GD1Q9WGY",
    imgLink1:
      "https://i.postimg.cc/JzPq7Y4M/Estado-Whats-App-M-todos-de-Pago-Lindo-Rosa-Pastel.png",
    link1Enable: false,
    link2: "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51433283",
    imgLink2:
      "https://i.postimg.cc/vH0L7NYP/0-CA78-A8-A-1-E4-E-49-C1-A0-CA-8-D68-C77-C3-F9-B.png",
    link2Enable: false,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://i.postimg.cc/zGwKf2LD/EB2543-E7-00-B8-4-D1-B-91-CE-53-FA417565-E0.png",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirm: "Nuestro Día Especial",
    aviso: "Confirmar antes del 30 de Septiembre",
    numeroConfirmacion: "4491516931",
    textoConfirmacion: "a la boda",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
    numeroNovia: "+528661742212",
    numeroNovio: "+528661742212",
    novios: true,
  };

  const datosSeccionInvitados = {
    imgFondo: "https://i.postimg.cc/76Qd5Ccf/Happy-wedding-Your-Story.png",
    titulo: `¡Has sido invitado a la boda de ${datosSeccionPotadaBoda.esposa} y ${datosSeccionPotadaBoda.esposo}!`,
    entradasText: "disfrutar nuestra gran Boda",
    colorFondoName: "",
  };

  const contador = true;
  const padres = true;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = true;
  const ceremonia = true;
  const programa = true;
  const vestimenta = true;

  const images = [
    " https://i.postimg.cc/x1FFgTJY/1.jpg",
    "https://i.postimg.cc/sxRLS6Bh/8.jpg",
    "https://i.postimg.cc/GmM6NxfW/11.jpg",
    "https://i.postimg.cc/YCSs1mSb/4.jpg",
    "https://i.postimg.cc/5NgrYmfX/3.jpg",
    "https://i.postimg.cc/59wT1VGq/5.jpg",
    "https://i.postimg.cc/sxRLS6Bh/8.jpg",
    // Add more image URLs as needed
  ];

  const names = [
    { name: "Katia y Ramon", guess: "2", id: 1 },
    { name: "Grecia", guess: "2", id: 2 },
    { name: "Tania Martinez", guess: "2", id: 3 },
    { name: "Blanca Martinez", guess: "2", id: 4 },
    { name: "Gabriela Jacobo", guess: "2", id: 5 },
    { name: "Ramon y Sandra", guess: "2", id: 6 },
    { name: "Marisol Miranda", guess: "2", id: 7 },
    { name: "Familia Rodriguez Castillo", guess: "4", id: 8 },
    { name: "Olga", guess: "2", id: 9 },
    { name: "Familia Esquivel Vasquez", guess: "4", id: 10 },
    { name: "Familia Padilla Vasquez", guess: "4", id: 11 },
    { name: "Familia Gaona Padilla", guess: "4", id: 12 },
    { name: "Familia Castillo de los Santos", guess: "2", id: 13 },
    { name: "Familia Garza Salazar", guess: "5", id: 14 },
    { name: "Cesar Flores", guess: "2", id: 15 },
    { name: "Familia Coy Vasquez", guess: "6", id: 16 },
    { name: "Karen Ramirez", guess: "2", id: 17 },
    { name: "Alizon McBride and Famliy", guess: "4", id: 18 },
    { name: "Juanita Picazo", guess: "2", id: 19 },
    { name: "Aracely Medina", guess: "2", id: 20 },
    { name: "Cecil McBride", guess: "2", id: 21 },
    { name: "Familia Garcia Castillo", guess: "3", id: 22 },
    { name: "Familia Garcia Vazquez", guess: "3", id: 23 },
    { name: "Cynthia Garcia", guess: "2", id: 24 },
    { name: "Miguel Castillo", guess: "2", id: 25 },
    { name: "German Villasana", guess: "2", id: 26 },
    { name: "Antonio Luna", guess: "2", id: 27 },
    { name: "Jorge Ramirez", guess: "2", id: 28 },
    { name: "Adolfo Ramirez", guess: "2", id: 29 },
    { name: "Eduardo Sanchez", guess: "2", id: 30 },
    { name: "Paulo Barrera", guess: "2", id: 31 },
    { name: "Homero Ibarra", guess: "2", id: 32 },
    { name: "Daniel Peña", guess: "2", id: 33 },
    { name: "Familia Salazar Chavez", guess: "2", id: 34 },
    { name: "Alejandro Rangel", guess: "2", id: 35 },
    { name: "Ervey Ayala", guess: "2", id: 36 },
    { name: "Familia Cordova Silva", guess: "2", id: 37 },
    { name: "Jesus A Peña", guess: "2", id: 38 },
    { name: "Rafael Diaz", guess: "2", id: 39 },
    { name: "Edgar Chacon", guess: "2", id: 40 },
    { name: "Alejandro Peña", guess: "2", id: 41 },
    { name: "Omar Lleverino", guess: "2", id: 42 },
    { name: "Carlos Sanchez", guess: "2", id: 43 },
    { name: "Victor Sanchez", guess: "2", id: 44 },
    { name: "Samuel Sanchez", guess: "2", id: 45 },
    { name: "Pedro Castillo", guess: "2", id: 46 },
    { name: "Alejandro Garza", guess: "2", id: 47 },
    { name: "Armando Cordoba", guess: "2", id: 48 },
    { name: "Gustavo Cordoba", guess: "2", id: 49 },
    { name: "Ulises Pacheco", guess: "2", id: 50 },
    { name: "Familia Suarez Sanchez", guess: "2", id: 51 },
    { name: "Jose Suarez Jr", guess: "2", id: 52 },
    { name: "Familia Martell Suarez", guess: "2", id: 53 },
    { name: "Familia Garcia Cordoba", guess: "2", id: 54 },
    { name: "Familia Alonso Garcia", guess: "2", id: 55 },
    { name: "Familia Velazquez Garcia", guess: "2", id: 56 },
    { name: "Familia Sanchez Villarreal", guess: "2", id: 57 },
    { name: "Cristobal Sanchez", guess: "2", id: 58 },
    { name: "Francisco Rodriguez", guess: "2", id: 59 },
    { name: "Familia Valdez Marrero", guess: "2", id: 60 },
    { name: "Familia Flores Ayala", guess: "2", id: 61 },
    { name: "David Hernandez", guess: "2", id: 62 },
    { name: "Armando Medina", guess: "2", id: 63 },
    { name: "Eduardo Valadez", guess: "2", id: 64 },
    { name: "Sergio Villarreal", guess: "2", id: 65 },
    { name: "Ernesto Zapata", guess: "2", id: 66 },
    { name: "Familia Ibarra Cordoba", guess: "2", id: 67 },
    { name: "Mariana Cisneros", guess: "2", id: 68 },
    { name: "Ale Flores", guess: "2", id: 69 },
    { name: "Familia Cisneros Martinez", guess: "2", id: 70 },
    { name: "Elena Villasana", guess: "2", id: 71 },
    { name: "Familia Suarez Villanueva", guess: "2", id: 72 },
    { name: "Familia De Alba Iza", guess: "2", id: 73 },
    { name: "Familia Ramos", guess: "2", id: 74 },
    { name: "Familia Espionoza", guess: "2", id: 75 },
    { name: "Familia Quintero Sanchez", guess: "2", id: 76 },
    { name: "Familia Vazquez De La Cruz", guess: "2", id: 77 },
    { name: "Anabel Castillo", guess: "2", id: 78 },
    { name: "Verito Rosales", guess: "2", id: 79 },
    { name: "Lucrecia Valdes", guess: "2", id: 80 },
    { name: "Diego Aguirre", guess: "2", id: 81 },
    { name: "Familia Diaz Ramirez", guess: "2", id: 82 },
    { name: "Ana Martinez", guess: "2", id: 83 },
    { name: "Rigo Cardenas", guess: "2", id: 84 },
    { name: "Hernando Fernandez", guess: "2", id: 85 },
    { name: "Juan Monita", guess: "2", id: 86 },
    { name: "Ivan Quiroz", guess: "2", id: 87 },
    { name: "Reynol Martinez", guess: "2", id: 88 },
    { name: "Romulo Castañeda", guess: "2", id: 89 },
    { name: "Hilda de los Santos", guess: "2", id: 90 },
    { name: "Liliana Rios", guess: "2", id: 91 },
    { name: "Brenda Leos", guess: "2", id: 92 },
    { name: "Martha Martinez", guess: "2", id: 93 },
    { name: "Thelma Estrada", guess: "2", id: 94 },
    { name: "Gabriela Hidalgo", guess: "2", id: 95 },
    { name: "Edson", guess: "2", id: 96 },
    { name: "Angel Arroyo", guess: "2", id: 97 },
    { name: "Armenia", guess: "2", id: 98 },
    { name: "Cecilia y Juan", guess: "2", id: 99 },
    { name: "Perla Muzquiz", guess: "2", id: 100 },
    { name: "Yolita", guess: "2", id: 101 },
    { name: "Monica Garza", guess: "2", id: 102 },
    { name: "Iván Ibarra", guess: "2", id: 103 },
    { name: "Antonio Ramírez", guess: "2", id: 104 },
    { name: "Paulo Aarón", guess: "2", id: 105 },
    { name: "Emanuel", guess: "2", id: 106 },
    { name: "Rosy Suárez", guess: "2", id: 107 },
    { name: "Rigo Cardenas", guess: "2", id: 108 },
    { name: "Ulises Ibarra", guess: "2", id: 109 },
    { name: "Adolfo Ramirez", guess: "2", id: 110 },
    { name: "Leonor Mireles", guess: "2", id: 111 },
    { name: "Javier Amaya Liñan", guess: "2", id: 112 },
    { name: "Juan Elizalde", guess: "2", id: 113 },
    { name: "Lety Salas", guess: "2", id: 114 },
    { name: "Raul Martinez", guess: "2", id: 115 },
    { name: "Eusebio Bustos", guess: "2", id: 116 },
    { name: "Ismael Cordova", guess: "2", id: 117 },
    { name: "Salvador Peña", guess: "2", id: 118 },
    { name: "Francisco Garcia", guess: "2", id: 119 },
    { name: "Raul Hernandez", guess: "2", id: 120 },
    { name: "Sr Arturo Mendoza", guess: "2", id: 121 },
    { name: "Fam Medrano Castillo", guess: "2", id: 122 },
    { name: "Fam Guerrero Castillo", guess: "2", id: 123 },
    { name: "Fam Corondo Castillo", guess: "2", id: 124 },
    { name: "Fam Rojas Castillo", guess: "2", id: 125 },
    { name: "Tania Arreola", guess: "2", id: 126 },
    { name: "Rudy Pemberton", guess: "2", id: 127 },
    { name: "Lázaro Treviño", guess: "2", id: 128 },
    { name: "William Sandoval", guess: "2", id: 129 },
    { name: "Oscar Zertuche", guess: "2", id: 130 },
    { name: "Juanita Rodríguez", guess: "2", id: 131 },
    { name: "Jesse Guerrero", guess: "2", id: 132 },
    { name: "Rafael Charles", guess: "2", id: 133 },
    { name: "Arturo Peña", guess: "1", id: 134 },
  ];

  useEffect(() => {
    const guestId = parseInt(idInvitado);
    const findName = names.find((e) => e.id == guestId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a la Boda con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <PortadaBodaComponent datosSeccionPotada={datosSeccionPotadaBoda} />

      {contador ? (
        <ContadorBodaComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresBodaComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaBodaComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionProgramaBoda}
        />
      ) : (
        ""
      )}

      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionBoda
          peltaColores={peltaColores}
          images={images}
          messageWhats={messageWhats}
          datosSeccion1={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
