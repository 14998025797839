import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { FaWhatsapp } from "react-icons/fa6";
import Slider from "react-slick";

export const XvMayteBerenice = () => {
  const [isOpen, setIsOpen] = useState(true);

  const peltaColores = {
    color1: "#ffffff",
    color2: "#ffffff",
  };

  const datosSeccionPortada = {
    cancion: "",
    imgPortada: "https://i.postimg.cc/VLn8cjBd/IMG-1082.jpg",
    nombre: "Mayte Berenice",
    fechaDeBoda: "22 JUNIO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+523328384660",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Hoy en este día tan especial, primero que todo le doy las gracias a Dios por haberme permitido estar aquí Y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. \\nHoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan espacial para mí.",
    fechaNewDate: "June 22, 2024 21:00:00 GMT+00:00",
    fotoIzquierda: "https://i.postimg.cc/zGN1DV0p/IMG-1081.jpg",
    fotoDerecha: "https://i.postimg.cc/bvNcb4Xt/IMG-1083.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Mamá de la Quinceañera",
    tituloDerecha: "Padrinos de la Quinceañera",
    madre: "Jessica Berenice Huitrón Dena",
    padre: "",
    madrina: "María de Jesus Guitron Dena",
    padrino: "Martín Escalante",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 22 de Junio de 2024.",
    ceremoniaHora: "7:00 p.m.",
    ceremoniaNombreLugar: "Templo Madre Del Redentor",
    ceremoniaLugarMap: "Beira 946, Autocinema, 44230 Guadalajara, Jal.",
    ceremoniaUrl: "https://maps.app.goo.gl/5igBbqXq8TBFiA9b9",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 22 de Junio de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "Salon Eventos Rocco",
    recepcionLugarMap:
      "C. Los Alpes 2920, Infonavit Estadio, 44307 Guadalajara, Jal.",
    recepcionUrl: "https://maps.app.goo.gl/c6Ei46cYNmuNfzV29",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para los XV años. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const images = [
    "https://i.postimg.cc/VLn8cjBd/IMG-1082.jpg",
    "https://i.postimg.cc/zGN1DV0p/IMG-1081.jpg",
    "https://i.postimg.cc/bvNcb4Xt/IMG-1083.jpg",
    // Add more image URLs as needed
  ];

  const targetDate = new Date(
    datosSeccionContador.fechaNewDate || ""
  ).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + 8
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you have some logic to format the message for WhatsApp
    const message = `Hola, Confirmo mi asistencia ${datosSeccionConfirmacion.textoConfirmacion} de ${datosSeccionPortada.nombre}. ¡Nos vemos ahí!`;

    // Replace with the actual WhatsApp API URL and encode the message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      datosSeccionConfirmacion.numeroConfirmacion
    }&text=${encodeURIComponent(message)}`;

    // Redirect to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = false;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
        />
      ) : (
        ""
      )}
      <div
        style={{
          backgroundImage: `url(${datosSeccionPortada.imgPortada})`,
          backgroundRepeat: "no-repeat",
          height: "100vh", // Ajusta la altura del contenedor
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-slate-950 flex flex-col md:bg-full sm:bg-cover justify-center items-center w-full"
      >
        <div
          className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          <br />
          <br />
          <h1
            className="font-serif sm:text-5xl md:text-[132px] text-white text-center flex"
            data-aos="flip-up"
          >
            {datosSeccionPortada.nombre.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <br />
          <br />
          <p
            className="text-white p-3 rounded-lg font-serif text-2xl text-center"
            data-aos="fade-up"
          >
            - {datosSeccionPortada.titulo} - <br />
            {datosSeccionPortada.fechaDeBoda.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>
          <br />
        </div>
      </div>
      {contador ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color1,
            color: datosSeccionContador.textColor
              ? datosSeccionContador.textColor
              : "",
          }}
          className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
        >
          {/* =========================================================================================================================================== */}
          <div className="flex flex-col pt-[10vh] w-full items-center">
            <h1
              className="font-serif  sm:text-[52px] md:text-[92px] "
              data-aos="flip-up"
            >
              {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <br />
            <p
              className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
              data-aos="flip-up"
            >
              {datosSeccionContador.descipcion
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea}
                    <br />
                  </React.Fragment>
                ))}
            </p>
            <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "75vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-[30%]"
                data-aos="fade-right"
              ></div>
              {datosSeccionContador.contador ? (
                <div
                  className="flex sm:p-5 md:p-3 rounded-lg justify-center items-center mt-10"
                  data-aos="fade-up"
                  style={{
                    border: "15px solid transparent",
                    padding: "15px",
                    borderImage: datosSeccionContador.marcoEnable
                      ? "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')"
                      : "",
                    borderImageSlice: "46",
                    borderImageWidth: "round",
                  }}
                >
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.days}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Días</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.hours}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Hrs</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.minutes}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Mins</p>
                  </div>
                  <div className="flex flex-col m-2 justify-center items-center">
                    <p className="font-serif sm:text-xl md:text-2xl">
                      {timeRemaining.seconds}
                    </p>
                    <p className="font-serif sm:text-xl md:text-2xl">Segs</p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div
                style={{
                  backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "75vh", // Ajusta la altura del contenedor
                  display: "flex",
                  backgroundPosition: "center center",
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="rounded-full sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
                data-aos="fade-left"
              ></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
          style={{
            backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            backgroundPosition: "center center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: peltaColores.color2,
            color: datosSeccionConfirmacion.textColor
              ? datosSeccionConfirmacion.textColor
              : "",
          }}
          className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
        >
          <h1
            className="font-serif text-center sm:text-[30px] w-[90%] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccionConfirmacion.tituloConfirmacion
              .split("\\n")
              .map((linea, index) => (
                <React.Fragment key={index}>
                  {linea}
                  <br />
                </React.Fragment>
              ))}
          </h1>
          <br />
          <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
            {datosSeccionConfirmacion.aviso.split("\\n").map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
          </p>
          <br />
          <br />
          <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
            {images?.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[60vh]"
                />
              </div>
            ))}
          </Slider>
          <br />
          <br />
          {datosSeccionConfirmacion.numeroConfirmacion ? (
            <div
              data-aos="fade-down-left"
              className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
            >
              <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
                {datosSeccionConfirmacion.textWhatsApp ||
                  "Confirmación de Asistencia al Evento"}{" "}
                <FaWhatsapp className="text-green-600 ml-2" />
              </h2>
              <form onSubmit={handleSubmit}>
                <button
                  type="submit"
                  className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                >
                  Confirmar Asistencia
                </button>
              </form>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
