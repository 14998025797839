import AOS from "aos";
import React, { useEffect, useState } from "react";

export const MensajeXvComponetD2 = ({ peltaColores, datosSeccionContador }) => {

  const fontFamilyStyle = { fontFamily: datosSeccionContador.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionContador.textColor || "",
      }}
      className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
    >
      <div className="flex flex-col pt-[10vh] w-full items-center">
        <h1
          className="font-serif sm:text-[52px] md:text-[92px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))}
        </h1>
        <br />
        <p
          className="text-center pt-[5vh] font-serif text-xl sm:w-[90%] md:w-[60%]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionContador.descipcion.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))}
        </p>
        <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
            <div></div>
          <div
            style={{
              backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "45vh",
              display: "flex",
              backgroundPosition: "center center",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              border: "solid 5px white",
              boxShadow:"-4px 4px 12px 0px rgba(0, 0, 0, 0.44)"
            }}
            className="rounded-[30%]"
            data-aos="fade-right"
          ></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
