import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { LugarfiestaXvComponent } from "../../../components/ComponentsXv/LugarFiestaXvComponent";

export const PrimeraComunionMagda = () => {

  const peltaColores = {
    color1: "#e6e6ea",
    color2: "#f2f7f8",
  };

  const datosSeccionPortada = {
    cancion:"https://www.cjoint.com/doc/24_04/NDBe1YatUIR_y2mate.com---Te-regalo-Carla-Morrison.mp3",
    imgPortada:
      "https://i.postimg.cc/vZ5qYkBg/Whats-App-Image-2024-04-26-at-12-46-53-PM-1.jpg",
    nombre: "Angel",
    fechaDeBoda: "04 MAYO, 2024",
    titulo: "NO FALTES A MI PRIMERA COMUNION",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+522212022870",
    textoConfirmacion: "a la primera comunion.",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Mi Primera Comunion!",
    descipcion:
      "Mi primera comunión. Acompáñame a celebrar este día tan especial en el que recibo a Jesús en mi corazón.",
    fechaNewDate: "May 04, 2024 13:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/15n7Tf1K/Whats-App-Image-2024-04-26-at-12-46-52-PM.jpg",
    fotoDerecha:
      "https://i.postimg.cc/L6cQM2HF/Whats-App-Image-2024-04-26-at-12-46-53-PM.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de mis padres y padrinos!",
    descipcion:
      "Únete a nosotros para ser testigo de mi Primera Comunion.",
    tituloIzquierda: "Mis padres",
    tituloDerecha: "Mis padrinos",
    madre: "Magdalena Mundo Silva",
    padre: "Juan Pablo Gutiérrez Rondero",
    madrina: "Haide Mundo Silva",
    padrino: "Carlos López Badillo",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: true,
    ceremoniaFecha: "Sábado, 04 de Mayo de 2024.",
    ceremoniaHora: "3:30 p.m.",
    ceremoniaNombreLugar: "Salón Cenbali",
    ceremoniaLugarMap:
      "Calle 2a de Benito Juarez 6554, Col. Vicente Guerrero El salon es Cenbali",
    ceremoniaUrl:
      "https://maps.app.goo.gl/3xJKtwHkZk6UcH1d7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "No llevar color rosa",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "Acompáñanos a recibir la bendición de Dios",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "No te pierdas mi baile de XV años",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 3,
    titulo: "Mesa de regalos",
    descripcion:
      "Explora con encanto nuestra selección de regalos para el Bautizo. Cada elección refleja amor y cuidado, convirtiendo tu participación en un gesto memorable. \\n¡Descubre la magia de elegir el regalo perfecto para este día tan especial!",
    link1: "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "",
    link1Enable: true,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3: "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo:"",
    textColor:""
  };

  const images = [
    "https://i.postimg.cc/vZ5qYkBg/Whats-App-Image-2024-04-26-at-12-46-53-PM-1.jpg",
    "https://i.postimg.cc/15n7Tf1K/Whats-App-Image-2024-04-26-at-12-46-52-PM.jpg",
    "https://i.postimg.cc/L6cQM2HF/Whats-App-Image-2024-04-26-at-12-46-53-PM.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = false;
  const programa = false;
  const hospedaje = true;
  const confirmacion = true;
  const Regalos = false;
  const modal = true;

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
        
      <PortadaXvComponent
        datosSeccionPortada={datosSeccionPortada}
      />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <LugarfiestaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionXvComponent
          peltaColores={peltaColores}
          images={images}
          datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
};
