import audioFondo from "../../bodas/Boda-diamante/assets/maroon.mp3";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalXv from "../../../components/Modals/ModalXv";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { Invitados } from "../../../components/Invitados";
import { useLocation } from "react-router-dom";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { PiChurchLight } from "react-icons/pi";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaChampagneGlasses, FaWhatsapp } from "react-icons/fa6";
import Slider from "react-slick";

export const XvArletteYRenata = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const peltaColores = {
    color1: "#deabab",
    color2: "#b5bcff",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_04/NDExQzDhQts_Y2meta.app---Christina-Perri---A-Thousand-Years-128-kbps-.mp3",
    imgPortada:
      "https://i.postimg.cc/c4mjtbm7/Whats-App-Image-2024-04-29-at-3-18-04-PM.jpg",
    nombre: "Arlette & Renata ",
    fechaDeBoda: "25 MAYO, 2024",
    titulo: "NO FALTES A NUESTROS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarnos su asistencia. \\nAgradecemos de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+526642055829",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionContador = {
    titulo: "¡Nuestros XV años!",
    descipcion:
      "Hay momentos en la vida que son especiales por si solos, compartirlos con las personas que quieres los convierte en momentos inolvidables. Es por eso que nos complace invitarte a celebrar nuestros XV años.",
    fechaNewDate: "May 25, 2024 19:00:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/bvKFjcyP/Whats-App-Image-2024-04-29-at-3-19-14-PM-1.jpg",
    fotoDerecha:
      "https://i.postimg.cc/7ZtWmJjf/Whats-App-Image-2024-04-29-at-3-19-14-PM.jpg",
    marcoEnable: true,
    imgFondo: "",
    textColor: "",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo de nuestras madres!",
    descipcion:
      "Únete a nosotros para ser testigo de nuestra gran fiesta inolvidable.",
    tituloIzquierda: "Madres de las Quinceañeras",
    tituloDerecha: "",
    madre: "Claudia Lizeth Aguilar Bueno",
    padre: "Joanna Michel Velazquez Bueno",
    madrina: "",
    padrino: "",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: true,
    ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
    ceremoniaHora: "5:00 p.m.",
    ceremoniaNombreLugar: "Parroquia Sagrado Corazon de Jesus ( Capilla)",
    ceremoniaLugarMap:
      "Vicente Guerrero 20001, Matamoros Norte-Centro-Sur, Mariano Matamoros, 22744 Tijuana, B.C.",
    ceremoniaUrl:
      "https://maps.app.goo.gl/rCczW4w1og9pkSaw5",
    recepcionEnable: true,
    recepcionFecha: "Sábado, 25 de Mayo de 2024.",
    recepcionHora: "7:00 p.m.",
    recepcionNombreLugar: "Jardin los Tablones",
    recepcionLugarMap:
      "Francisco Villa n 9373 Fracc. Centauro, El Florido, 22236 Tijuana, B.C.",
    recepcionUrl:
      "https://maps.app.goo.gl/iJr5pjRJfTDAT3yV7",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "5:00 pm Acompáñanos a recibir la bendición de Dios",
    recepcion: "7:00 pm Te esperamos para celebrar",
    baileXV: "",
    cena: "8:30 a 9:30 Disfruta de una deliciosa cena",
    coctel: "",
    baile: "10:00 pm a 12:00 pm  Tomate fotos en la cabina",
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Su presencia es lo más importante en este día tan especial, pero si algo nos quieren regalar, en efectivo lo sabremos apreciar.",
    link1:
      "",
    imgLink1: "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionInvitados ={
    imgFondo:"https://i.postimg.cc/pVK3RT56/WhatsApp_Image_2024-04-29_at_3.20.08_PM.jpg",
    titulo: "¡Has sido invitado a los XV años de Arlette & Renata!",
    entradasText: "disfrutar nuestros XV años",
    colorFondoName:"#c0929ec2"
  }

  const images = [
    "https://i.postimg.cc/c4mjtbm7/Whats-App-Image-2024-04-29-at-3-18-04-PM.jpg",
    "https://i.postimg.cc/bvKFjcyP/Whats-App-Image-2024-04-29-at-3-19-14-PM-1.jpg",
    "https://i.postimg.cc/7ZtWmJjf/Whats-App-Image-2024-04-29-at-3-19-14-PM.jpg",
    "https://i.postimg.cc/pVK3RT56/Whats-App-Image-2024-04-29-at-3-20-08-PM.jpg",
    "https://i.postimg.cc/W11yGwWB/Whats-App-Image-2024-04-29-at-3-18-39-PM.jpg",
    "https://i.postimg.cc/vT7Kzqds/Whats-App-Image-2024-04-29-at-3-20-08-PM-1.jpg",
    "https://i.postimg.cc/g0wB5kP4/Whats-App-Image-2024-04-29-at-3-19-32-PM.jpg",
    "https://i.postimg.cc/pXx4THDX/Whats-App-Image-2024-04-29-at-3-20-07-PM.jpg",
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = false;

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Lourdes Bueno", guess: "3", id: 2 },
    { name: "Gabriela Ankrom Bueno", guess: "1", id: 3 },
    { name: "Yadira Yahaira Bueno", guess: "4", id: 4 },
    { name: "Karen Angelica Ankrom Bueno", guess: "2", id: 5 },
    { name: "Adela Bueno Ramirez", guess: "6", id: 6 },
    { name: "Olivia Bueno Ramirez", guess: "1", id: 7 },
    { name: "Tereza Bueno Ramirez", guess: "2", id: 8 },
    { name: "Lorena Sanchez", guess: "2", id: 9 },
    { name: "Ruben Velazquez Bueno", guess: "2", id: 10 },
    { name: "Brenda Bueno", guess: "4", id: 11 },
    { name: "Ivan Antonio Ortiz Bueno", guess: "2", id: 12 },
    { name: "Richard Chavez Bueno", guess: "5", id: 13 },
    { name: "Elvia y Familia", guess: "3", id: 14 },
    { name: "Cyhthia Giron Alvarez", guess: "2", id: 15 },
    { name: "Karina Blanco Delfin", guess: "5", id: 16 },
    { name: "Virginia Flores", guess: "5", id: 17 },
    { name: "Fredy Viveros Flores", guess: "4", id: 18 },
    { name: "Claudia Pineda", guess: "3", id: 19 },
    { name: "Rocio Perez", guess: "4", id: 20 },
    { name: "Elsa Reyes", guess: "2", id: 21 },
    { name: "Cynthia Liceth Flores", guess: "4", id: 22 },
    { name: "Laura Giron", guess: "2", id: 23 },
    { name: "Leticia Giron", guess: "3", id: 24 },
    { name: "Sahara", guess: "2", id: 25 },
    { name: "Carlos Munoz", guess: "1", id: 26 },
    { name: "Julieta Gutierrez", guess: "3", id: 27 },
    { name: "Magdalena Gomez", guess: "2", id: 28 },
    { name: "Nathaly Andrade", guess: "2", id: 29 },
    { name: "Denis Espinoza", guess: "2", id: 30 },
    { name: "Dinora Agraz", guess: "4", id: 31 },
    { name: "Rosa Elena Franco", guess: "2", id: 32 },
    { name: "Veronica Pescador", guess: "5", id: 33 },
    { name: "Jorge Pescador", guess: "1", id: 34 },
    { name: "Laura Patrica Arcos Ruiz", guess: "4", id: 35 },
    { name: "Divanny Tejeda", guess: "2", id: 36 },
    { name: "Cesar Gallardo", guess: "2", id: 37 },
    { name: "Jose Herrera", guess: "2", id: 38 },
    { name: "Nahary Najera", guess: "2", id: 39 },
    { name: "Elizabeth Pescador", guess: "4", id: 40 },
    { name: "Carmen Zuniga", guess: "1", id: 41 },
    { name: "Perla Franco", guess: "1", id: 42 },
    { name: "Pamela Bennetts", guess: "1", id: 43 },
    { name: "Alejandra Ruiz", guess: "1", id: 44 },
    { name: "Veronica Tlapale", guess: "2", id: 45 },
    { name: "Sara Mares", guess: "3", id: 46 },
    { name: "Saide Donaji", guess: "1", id: 47 },
    { name: "Jazmin Contreras", guess: "1", id: 48 },
    { name: "Joanna Morales", guess: "2", id: 49 },
    { name: "Jazmin Garcia Guzman", guess: "1", id: 50 },
    { name: "Alondra Juarez", guess: "1", id: 51 },
    { name: "Lorena Santiz", guess: "3", id: 52 },
    { name: "Karina Guerrero", guess: "2", id: 53 },
    { name: "Hortencia y Magaly", guess: "3", id: 54 },
    { name: "Anabel Villagran", guess: "4", id: 55 },
    { name: "Diana Zazueta", guess: "3", id: 56 },
    { name: "Mari Gutierrez", guess: "2", id: 57 },
    { name: "Clara Del Rio", guess: "4", id: 58 },
    { name: "Rafael Ramos Guerrero", guess: "2", id: 59 },
    { name: "Miguel Angel Viveros Flores", guess: "1", id: 60 },
    { name: "Wendy Velazquez", guess: "3", id: 61 },
    { name: "Cristian Fernandez", guess: "2", id: 62 },
    { name: "Karen Olvera", guess: "1", id: 63 },
    { name: "Sahara y Levi", guess: "2", id: 64 },
    { name: "Especialmente para ti", guess: "1", id: 65 },
    { name: "Especialmente para ti", guess: "2", id: 66 }
  ];
  

  useEffect(() => {
    const findName = names.find((e) => e.id == nameId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setIsOpen(true);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
       {modal ? (
        <ModalXvCard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          datosSeccionPortada={datosSeccionPortada}
          peltaColores={peltaColores}
        />
      ) : (
        ""
      )}
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
         <div
         style={{
           backgroundImage:`url(${datosSeccionPrograma.imgFondo || ""})`,
           backgroundRepeat: "no-repeat",
           backgroundSize: "cover",
           display: "flex",
           backgroundPosition: "center center",
           justifyContent: "center",
           alignItems: "center",
           backgroundColor: peltaColores.color2,
           
           color: datosSeccionPrograma.textColor
             ? datosSeccionPrograma.textColor
             : "",
         }}
         className="sm:h-auto md:h-[150vh] flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
       >
         {/* =========================================================================================================================================== */}
         <div className="flex flex-col w-full justify-center items-center p-2">
           <h1
             className="font-serif text-center sm:text-[32px] md:text-[42px]"
             data-aos="flip-up"
           >
             Programa
           </h1>
           <br />
           <p
             className=" text-center pt-[5vh] font-serif text-xl"
             data-aos="flip-up"
           >
             Hemos planeado un día lleno de diversión para disfrutar al máximo.
           </p>
           <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
             <div
               className="flex flex-col justify-center items-center"
               data-aos="fade-right"
             >
               {datosSeccionPrograma.ceremoniaReligiosa ? (
                 <>
                   <PiChurchLight className="text-[60px]" />
                   <br />
                   <p className="font-serif text-xl text-center">
                     CEREMONIA RELIGIOSA
                   </p>
                   <br />
                   <p className="font-serif text-xl text-center w-[60%]">
                     {datosSeccionPrograma.ceremoniaReligiosa}
                   </p>
                   <br />
                   <hr className="bg-gray-700 h-1 w-[60%]" />
                   <br />
                 </>
               ) : (
                 ""
               )}
   
               {datosSeccionPrograma.recepcion ? (
                 <>
                   <GiPartyPopper className="text-[60px]" />
                   <br />
                   <p className="font-serif text-xl text-center">RECEPCIÓN</p>
                   <br />
                   <p className="font-serif text-xl text-center w-[60%]">
                     {datosSeccionPrograma.recepcion}
                   </p>
                   <br />
                   <hr className="bg-gray-700 h-1 w-[60%]" />
                   <br />
                 </>
               ) : (
                 ""
               )}
   
               {datosSeccionPrograma.baileXV ? (
                 <>
                   <GiPartyPopper className="text-[60px]" />
                   <br />
                   <p className="font-serif text-xl text-center">
                     BAILE DE XV AÑOS
                   </p>
                   <br />
                   <p className="font-serif text-xl text-center w-[60%]">
                     {datosSeccionPrograma.baileXV}
                   </p>
                   <br />
                   <hr className="bg-gray-700 h-1 w-[60%]" />
                   <br />
                 </>
               ) : (
                 ""
               )}
             </div>
   
             <div
               className="flex flex-col justify-center items-center"
               data-aos="fade-right"
             >
               {datosSeccionPrograma.cena ?(
                   <>
                    <ImSpoonKnife className="text-[60px]" />
               <br />
               <p className="font-serif text-xl text-center">CENA</p>
               <br />
               <p className="font-serif text-xl text-center w-[60%]">
                 {datosSeccionPrograma.cena}
               </p>
               <br />
               <br />
               <hr className="bg-gray-700 h-1 w-[60%]" />
               <br />
                   </>
               ):("")}
              
              {datosSeccionPrograma.coctel ? (
               <>
                <FaChampagneGlasses className="text-[60px]" />
               <br />
               <p className="font-serif text-xl text-center">CÓCTEL</p>
               <br />
               <p className="font-serif text-xl text-center w-[60%]">
                 {datosSeccionPrograma.coctel}
               </p>
               <br />
               <hr className="bg-gray-700 h-1 w-[60%]" />
               <br />
               </>
              ):("")}
              
              {datosSeccionPrograma.baile ? (
               <>
               <FaPhotoVideo className="text-[60px]" />
               <br />
               <p className="font-serif text-xl text-center">CABINA DE FOTOS</p>
               <br />
               <p className="font-serif text-xl text-center w-[60%]">
                 {datosSeccionPrograma.baile}
               </p>
               <br />
               <hr className="bg-gray-700 h-1 w-[60%]" />
               <br />
               </>
              ):("")}
               
             </div>
           </div>
         </div>
       </div>
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <div
        style={{
          // backgroundImage:
          //   'url("https://static.vecteezy.com/system/resources/thumbnails/009/657/375/small/natural-branch-watercolor-png.png")',
          //   backgroundRepeat: "repeat",
          //   backgroundSize: "25%", // or "100% auto"
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full "
      >
        <h1
          className="font-serif text-center sm:text-[22px] w-[90%] md:text-[42px]"
          data-aos="flip-up"
        >
          {datosSeccionConfirmacion.tituloConfirmacion
            .split("\\n")
            .map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
        </h1>
        <br />
        <p className="sm:text-[22px] font-serif text-center w-[70%] md:text-[30px]">
          {datosSeccionConfirmacion.aviso || ""}
        </p>
        <br />
        <br />
        <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
          {images?.map((imageUrl, index) => (
            <div data-aos="fade-down-right" className="rolImg" key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-auto rounded-xl max-h-[60vh]"
              />
            </div>
          ))}
        </Slider>
        <br />
        <br />
        <div
          data-aos="fade-down-left"
          className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
        >
          <h2 className="text-xl font-bold mb-4 justify-center flex">
          Nuestros XV están muy cerca y nos gustaría saber si contaremos con su compañía para ese día tan especial para nosotras.
            <FaWhatsapp className="text-green-600 ml-2" />
          </h2>
          <br />
          {datosSeccionConfirmacion.mensajeAsistencia ? (
            <p className="text-center font-semibold">
              {datosSeccionConfirmacion.mensajeAsistencia}
            </p>
          ) : (
            ""
          )}
          <div className="flex justify-center items-center">
            <div className="w-full text-center p-4 bg-green-700 mt-5 rounded-lg">
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  datosSeccionConfirmacion.numeroConfirmacion
                }&text=${encodeURIComponent(messageWhats)}`}
                className="text-white text-center md:w-[60%] md:text-xl text-auto"
              >
                Confirma tu invitación con nosotros 💌
              </a>
            </div>
          </div>
        </div>
      </div>
      ) : (
        ""
      )}
    </div>
  );
};
