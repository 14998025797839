import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import audioFondo from '../Boda-diamante/assets/Crash.mp3'
import { PortadaBoda } from "../../../components/PortadaBoda";
import { Ceremonia } from "../../../components/Ceremonia";

export const BodaCecilia = () => {

      const datosSeccion1 = {
        textoConfirmacion: "a nuestra boda",
        esposo: "Jonathan",
        esposa: "Cecilia",
        fechaDeBoda: "27 ABRIL, 2024",
        fechaNewDate: "April 27, 2024 16:00:00 GMT+00:00",
        imgPortada: "https://i.postimg.cc/G2HLL3FP/image1.png",
        numeroNovia: "4491097554",
        numeroNovio: "4493871381",
        novios: true,
      };

      const peltaColores = {
        color1: "#e8e8e8",
        color2: "#f6d9bf",
      };
    
      const datosSeccion2 = {
        descipcion:
          "Cuando te das cuenta de que quieres pasar el resto de tu vida con alguien, deseas que el resto de tu vida comience lo antes posible. Por eso hemos decidido unir nuestro amor, donde Dios será nuestro único testigo.",
        fechaNewDate: "April 27, 2024 00:00:00 GMT+00:00",
        fotoIzquierda:
          "https://i.postimg.cc/50wpPk9h/image3.png",
      };

      const datosSeccion4 = {
        ceremoniaFecha: "Sabado, 27 de Abril de 2024.",
        ceremoniaHora: "4:00 p.m.",
        ceremoniaNombreLugar: "Parroquia de Jesús Nazareno",
        ceremoniaLugarMap: "Ignacio Allende 205, Zona Centro, 20900 Jesús María, Ags.",
        ceremoniaUrl: "https://www.google.com.mx/maps/place/Parroquia+de+Jesús+Nazareno/@21.9616333,-102.3476713,17z/data=!3m1!4b1!4m6!3m5!1s0x8429e5ff8d9b9551:0xfce02549068aa11!8m2!3d21.9616333!4d-102.345091!16s%2Fg%2F1tl7p0sm?entry=ttu",
        recepcionFecha: "Sabado, 27 de Abril de 2024.",
        recepcionHora: "7:00 p.m.",
        recepcionNombreLugar: "Salón de la Recepción",
        recepcionLugarMap:
          "Zona metropolitana de Aguascalientes, Ags.",
        recepcionUrl: "https://maps.app.goo.gl/pzoQ8Qir5Sik6zV7A",
      };
    
      const targetDate = new Date(datosSeccion2.fechaNewDate).getTime();
      const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    
      const [name, setName] = useState("");
      const [guests, setGuests] = useState(1);
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Assuming you have some logic to format the message for WhatsApp
        const message = `Hola, soy ${name} y confirmo mi asistencia a la boda con ${guests} invitado(s). ¡Nos vemos allí!`;
    
        // Replace with the actual WhatsApp API URL and encode the message
        const whatsappUrl = `https://api.whatsapp.com/send?phone=4491097554&text=${encodeURIComponent(
          message
        )}`;
    
        // Redirect to the WhatsApp URL
        window.location.href = whatsappUrl;
      };
    
      function calculateTimeRemaining() {
        const now = new Date().getTime();
        const difference = targetDate - now;
    
        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    
          return {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          };
        } else {
          return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
        }
      }
    
      const images = [
        "https://i.postimg.cc/50wpPk9h/image3.png",
        "https://i.postimg.cc/k5mY8bmM/image0.jpg",
        "https://i.postimg.cc/5tS7m24B/image2.jpg"
        // Add more image URLs as needed
      ];
    
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1800,
        cssEase: "linear",
      };
    
      const audioUrl = "ruta/de/tu/musica.mp3"; // Reemplaza con la ruta correcta de tu archivo de música
    
      useEffect(() => {
        AOS.init({ duration: 2000 });
        const interval = setInterval(() => {
          setTimeRemaining(calculateTimeRemaining());
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);

      
  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">

    <PortadaBoda datosSeccion1={datosSeccion1} audioFondo={audioFondo} />

    <div
      style={{
        // backgroundImage:
        //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f8f2f2",
      }}
      className="sm:h-[180vh] md:h-[120vh] flex flex-col items-center w-full p-2"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col pt-[10vh] w-full items-center">
        <h1
          className="font-serif sm:text-[52px] md:text-[92px] "
          data-aos="flip-up"
        >
          ¡Nos casamos!
        </h1>
        <br />
        <p
          className=" text-center pt-[5vh] font-serif text-xl sm:w-[80%] md:w-[60%]"
          data-aos="flip-up"
        >
          {datosSeccion2.descipcion}
        </p>
        <div className="grid sm:grid-cols-1 items-center md:grid-cols-2 w-[80%] pt-[8vh]">
          <div
            style={{
              backgroundImage: `url(${datosSeccion2.fotoIzquierda})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "45vh", // Ajusta la altura del contenedor
              display: "flex",
              backgroundPosition: "center center",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="rounded-[30%]"
            data-aos="fade-right"
          ></div>
          <div
            className="flex p-3 rounded-lg justify-center items-center mt-10"
            data-aos="fade-up"
            style={{
              border: "10px solid transparent",
              padding: "15px",
              borderImage:
                "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')",
              borderImageSlice: "46",
              borderImageWidth: "round",
            }}
          >
            <div className="flex flex-col m-2 justify-center items-center">
              <p className="font-serif text-2xl">{timeRemaining.days}</p>
              <p className="font-serif text-2xl">Dias</p>
            </div>
            <div className="flex flex-col m-2 justify-center items-center">
              <p className="font-serif text-2xl">{timeRemaining.hours}</p>
              <p className="font-serif text-2xl">Hrs</p>
            </div>
            <div className="flex flex-col m-2 justify-center items-center">
              <p className="font-serif text-2xl">{timeRemaining.minutes}</p>
              <p className="font-serif text-2xl">Mins</p>
            </div>
            <div className="flex flex-col m-2 justify-center items-center">
              <p className="font-serif text-2xl">{timeRemaining.seconds}</p>
              <p className="font-serif text-2xl">Segs</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Ceremonia peltaColores={peltaColores} datosSeccion4={datosSeccion4} />

    <div
      style={{
        // backgroundImage:
        //   'url("https://static.vecteezy.com/system/resources/thumbnails/009/657/375/small/natural-branch-watercolor-png.png")',
        //   backgroundRepeat: "repeat",
        //   backgroundSize: "25%", // or "100% auto"
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f8f2f2",
      }}
      className="sm:h-[150vh] md:h-[150vh] flex flex-col justify-center items-center w-full"
    >
      <h1
        className="font-serif text-center sm:text-[32px] md:text-[42px]"
        data-aos="flip-up"
      >
        Nuestro amor
      </h1>
      <br />
      <br />
      <br />
      {/* <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
        {images?.map((imageUrl, index) => (
          <div data-aos="fade-down-right" key={index}>
            <img
              src={imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-full rounded-xl"
            />
          </div>
        ))}
      </Slider> */}
      <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
        {images?.map((imageUrl, index) => (
          <div data-aos="fade-down-right" className="rolImg" key={index}>
            <img
              src={imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-auto rounded-xl max-h-[40vh]"
            />
          </div>
        ))}
      </Slider>
      <br />
      <br />
      <div
        data-aos="fade-down-left"
        className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
      >
        <h2 className="text-2xl font-bold mb-4 justify-center flex">
          Confirmación de Asistencia a la Boda{" "}
          <FaWhatsapp className="text-green-600 ml-2" />
        </h2>
        <form onSubmit={handleSubmit}>
          <label className="mb-2 flex">
            <span className="text-gray-700">Nombre:</span>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
              required
            />
          </label>
          <br />
          <label className="flex mb-2">
            <span className="text-gray-700">Invitados:</span>
            <input
              type="number"
              value={guests}
              onChange={(e) => setGuests(e.target.value)}
              className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
              min={1}
              required
            />
          </label>
          <br />
          <button
            type="submit"
            className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
          >
            Confirmar Asistencia
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}
