import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPhotoVideo, FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import axios from "axios";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { ImSpoonKnife, ImWoman } from "react-icons/im";
import { PiChurchLight } from "react-icons/pi";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { LiaStarSolid } from "react-icons/lia";

export const XvAutomaticaProg = () => {
  const [seccionPortadaData, setSeccionPortadaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seccionImagenes, setSeccionImagenes] = useState([]);
  const [estado, setEstado] = useState({});
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idClient = searchParams.get("id");

  // Función para hacer la solicitud HTTP
  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/Eventos/${idClient}`);
      setEstado(response.data);
      console.log(response.data);
      try {
        setLoading(true);
        const response = await axios.get(
          `/api/Seccionportada/obtenerXv/${idClient}`
        );
        console.log(response.data);
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
        const validarDate = regex.test(response.data?.portada?.fechaDeBoda);

        if (validarDate) {
          const dataConvertida = await converData(response.data);
          setSeccionPortadaData(dataConvertida);
        } else {
          setSeccionPortadaData(response.data);
        }

        try {
          const responseImg = await axios.get(`/api/Imagenes?id=${idClient}`);
          const mapImg = responseImg.data.map((item) => item.urlFoto);
          setSeccionImagenes(mapImg);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          alert("Error");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        alert("Error Intento Nuevamente");
      }
    } catch (error) {
      setLoading(false);
      alert("Error Evento Nuevamente");
    }
  };

  const converData = (items) => {
    const date = new Date(items?.portada?.fechaDeBoda || "");

    const dayName = date.toLocaleString("default", { weekday: "long" }); // Día de la semana
    const monthName = date.toLocaleString("default", { month: "long" }); // Mes
    const dateNumber = date.getDate(); // Día del mes
    const year = date.getFullYear(); // Año
    const hours = date.getHours();
    const minutes = date.getMinutes();

    items.portada.fechaDeBoda = `${dateNumber} ${monthName.toUpperCase()}, ${year}`;

    switch (monthName.toLowerCase()) {
      case "enero":
        items.contador.fechaNewDate = `January ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "febrero":
        items.contador.fechaNewDate = `February ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "marzo":
        items.contador.fechaNewDate = `March ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "abril":
        items.contador.fechaNewDate = `April ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "mayo":
        items.contador.fechaNewDate = `May ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "junio":
        items.contador.fechaNewDate = `June ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "julio":
        items.contador.fechaNewDate = `July ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "agosto":
        items.contador.fechaNewDate = `August ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "septiembre":
        items.contador.fechaNewDate = `September ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "octubre":
        items.contador.fechaNewDate = `October ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "noviembre":
        items.contador.fechaNewDate = `November ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      case "diciembre":
        items.contador.fechaNewDate = `December ${dateNumber}, ${year} ${hours}:${minutes}:00 GMT+00:00`;
        break;
      default:
        console.error("Nombre de mes no válido");
        break;
    }

    const dia = capitalizeWords(dayName);
    const mes = capitalizeWords(monthName);

    if (items.ceremonia.ceremoniaFecha == "Sábado, 25 de Mayo de 2024.") {
      items.ceremonia.ceremoniaFecha = `${dia}, ${dateNumber} de ${mes} de ${year}`;
    }

    if (items.ceremonia.recepcionFecha == "Sábado, 25 de Mayo de 2024.") {
      items.ceremonia.recepcionFecha = `${dia}, ${dateNumber} de ${mes} de ${year}`;
    }

    console.log(
      dayName,
      monthName,
      dateNumber,
      year,
      hours,
      "December 12, 2024 00:00:00 GMT+00:00",
      items.ceremonia.ceremoniaFecha
    );
    return items;
  };

  function capitalizeWords(string) {
    return string
      .toLowerCase() // Convertir toda la cadena a minúsculas para un formato consistente
      .split(" ") // Dividir la cadena en un array de palabras
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar la primera letra de cada palabra
      .join(" "); // Volver a unir el array en una sola cadena
  }

  // Llamar a la función fetchData cuando el componente se monte
  useEffect(() => {
    fetchData();
  }, []);

  const peltaColores = {
    color1: seccionPortadaData?.colores?.color1 || "",
    color2: seccionPortadaData?.colores?.color2 || "",
  };

  const datosSeccionPotada = {
    cancion: seccionPortadaData?.portada?.cancion || "",
    textoConfirmacion: seccionPortadaData?.portada?.textoConfirmacion || "",
    nombre: seccionPortadaData?.portada?.nombre || "",
    fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
    imgPortada: seccionPortadaData?.portada?.imgPortada || "",
    titulo: seccionPortadaData?.portada?.titulo || "",
    textColor: seccionPortadaData?.portada?.textColor || "",
  };

  const datosSeccionContador = {
    titulo: seccionPortadaData?.contador?.titulo || "",
    descipcion: seccionPortadaData?.contador?.descripcion || "",
    fechaNewDate: seccionPortadaData?.contador?.fechaNewDate || "",
    fotoIzquierda: seccionPortadaData?.contador?.fotoIzquierda || "",
    fotoDerecha: seccionPortadaData?.contador?.fotoDerecha || "",
    marcoEnable: seccionPortadaData?.contador?.marcoEnable,
    imgFondo: seccionPortadaData?.contador?.imgFondo || "",
    textColor: seccionPortadaData?.contador?.textColor || "",
    contador: seccionPortadaData?.contador?.contador,
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    cantidad: seccionPortadaData?.padres?.cantidad || "",
    titulo: seccionPortadaData?.padres?.titulo || "",
    descripcion: seccionPortadaData?.padres?.descripcion || "",
    titulo1: seccionPortadaData?.padres?.titulo1 || "",
    madre: seccionPortadaData?.padres?.madre || "",
    padre: seccionPortadaData?.padres?.padre || "",
    titulo2: seccionPortadaData?.padres?.titulo2 || "",
    madrina: seccionPortadaData?.padres?.madrina || "",
    padrino: seccionPortadaData?.padres?.padrino || "",
    titulo3: seccionPortadaData?.padres?.titulo3 || "",
    madrina2: seccionPortadaData?.padres?.madrina2 || "",
    padrino2: seccionPortadaData?.padres?.padrino2 || "",
    imgFondo: seccionPortadaData?.padres?.imgFondo || "",
    textColor: seccionPortadaData?.padres?.textColor || "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: seccionPortadaData?.ceremonia?.dobleEnable,
    ceremoniaEnable: seccionPortadaData?.ceremonia?.ceremoniaEnable,
    ceremoniaFecha: seccionPortadaData?.ceremonia?.ceremoniaFecha || "",
    ceremoniaHora: seccionPortadaData?.ceremonia?.ceremoniaHora || "",
    ceremoniaNombreLugar:
      seccionPortadaData?.ceremonia?.ceremoniaNombreLugar || "",
    ceremoniaLugarMap: seccionPortadaData?.ceremonia?.ceremoniaLugarMap || "",
    ceremoniaUrl: seccionPortadaData?.ceremonia?.ceremoniaUrl || "",
    recepcionEnable: seccionPortadaData?.ceremonia?.recepcionEnable,
    recepcionFecha: seccionPortadaData?.ceremonia?.recepcionFecha || "",
    recepcionHora: seccionPortadaData?.ceremonia?.recepcionHora || "",
    recepcionNombreLugar:
      seccionPortadaData?.ceremonia?.recepcionNombreLugar || "",
    recepcionLugarMap: seccionPortadaData?.ceremonia?.recepcionLugarMap || "",
    recepcionUrl: seccionPortadaData?.ceremonia?.recepcionUrl || "",
    imgFondo: seccionPortadaData?.ceremonia?.imgFondo || "",
    textColor: seccionPortadaData?.ceremonia?.textColor || "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: seccionPortadaData?.programa?.ceremoniaReligiosa || "",
    recepcion: seccionPortadaData?.programa?.recepcion || "",
    baileXv: seccionPortadaData?.programa?.baileXv || "",
    cena: seccionPortadaData?.programa?.cena || "",
    coctel: seccionPortadaData?.programa?.coctel || "",
    baile: seccionPortadaData?.programa?.baile || "",
    imgFondo: seccionPortadaData?.programa?.imgFondo || "",
    textColor: seccionPortadaData?.programa?.textColor || "",
  };

  const datosSeccionVestimenta = {
    vestimenta: seccionPortadaData?.vestimenta?.vestimenta || "",
    messageVestimenta: seccionPortadaData?.vestimenta?.messageVestimenta || "",
    urlVestimentaMujer:
      seccionPortadaData?.vestimenta?.urlVestimentaMujer || "",
    urlVestimentaHombre:
      seccionPortadaData?.vestimenta?.urlVestimentaHombre || "",
    imgFondo: seccionPortadaData?.vestimenta?.imgFondo || "",
    textColor: seccionPortadaData?.vestimenta?.textColor || "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: seccionPortadaData?.hospedaje?.disponibleDos || "",
    descripcion: seccionPortadaData?.hospedaje?.descripcion || "",
    hotel1Titulo: seccionPortadaData?.hospedaje?.hotel1Titulo || "",
    hotel1lugar: seccionPortadaData?.hospedaje?.hotel1lugar || "",
    hotel1url: seccionPortadaData?.hospedaje?.hotel1url || "",
    hotel2Titulo: seccionPortadaData?.hospedaje?.hotel2Titulo || "",
    hotel2lugar: seccionPortadaData?.hospedaje?.hotel2lugar || "",
    hotel2url: seccionPortadaData?.hospedaje?.hotel2url || "",
    imgFondo: seccionPortadaData?.hospedaje?.imgFondo || "",
    textColor: seccionPortadaData?.hospedaje?.textColor || "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: seccionPortadaData?.regalos?.cantidadRegalos || "",
    titulo: seccionPortadaData?.regalos?.titulo || "",
    descripcion: seccionPortadaData?.regalos?.descripcion || "",
    link1: seccionPortadaData?.regalos?.link1 || "",
    imgLink1: seccionPortadaData?.regalos?.imgLink1 || "",
    link1Enable: seccionPortadaData?.regalos?.link1Enable || "",
    link2: seccionPortadaData?.regalos?.link2 || "",
    imgLink2: seccionPortadaData?.regalos?.imgLink2 || "",
    link2Enable: seccionPortadaData?.regalos?.link2Enable || "",
    link3: seccionPortadaData?.regalos?.link3 || "",
    imgLink3: seccionPortadaData?.regalos?.imgLink3 || "",
    link3Enable: seccionPortadaData?.regalos?.link3Enable || "",
    imgFondo: seccionPortadaData?.regalos?.imgFondo || "",
    textColor: seccionPortadaData?.regalos?.textColor || "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      seccionPortadaData?.confirmacion?.tituloConfirmacion || "",
    aviso: seccionPortadaData?.confirmacion?.aviso || "",
    numeroConfirmacion:
      seccionPortadaData?.confirmacion?.numeroConfirmacion || "",
    textoConfirmacion:
      seccionPortadaData?.confirmacion?.textoConfirmacion || "",
    textWhatsApp: seccionPortadaData?.confirmacion?.textWhatsApp || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    imgFondo: seccionPortadaData?.confirmacion?.imgFondo || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    enableNum: seccionPortadaData?.confirmacion?.enableNum || "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = estado?.enableContador;
  const padres = estado?.enablePadres;
  const hospedaje = estado?.enableHospedaje;
  const confirmacion = estado?.enableConfirmacion;
  const Regalos = estado?.enableRegalos;
  const ceremonia = estado?.enableCeremonia;
  const programa = estado?.enablePrograma;
  const vestimenta = estado?.enableVestimenta;

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center animate-spin">
          <h2>Cargando...</h2>
        </div>
      ) : (
        <>
          <ModalXvCard
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            datosSeccionPortada={datosSeccionPotada}
          />
          <PortadaXvComponent datosSeccionPortada={datosSeccionPotada} />

          {contador ? (
            <ContadorXvComponent
              peltaColores={peltaColores}
              datosSeccionContador={datosSeccionContador}
            />
          ) : (
            ""
          )}

          {padres ? (
            <PadresBodaComponent
              peltaColores={peltaColores}
              datosSeccionPadres={datosSeccionPadres}
            />
          ) : (
            ""
          )}

          {ceremonia ? (
            <CeremoniaXvComponent
              peltaColores={peltaColores}
              datosSeccionCeremonia={datosSeccionCeremonia}
            />
          ) : (
            ""
          )}

          {programa ? (
            <div
              style={{
                backgroundImage: `url(${datosSeccionPrograma.imgFondo || ""})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                backgroundPosition: "center center",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: peltaColores.color2,

                color: datosSeccionPrograma.textColor
                  ? datosSeccionPrograma.textColor
                  : "",
              }}
              className="sm:h-auto md:h-auto flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
            >
              {/* =========================================================================================================================================== */}
              <div className="flex flex-col w-full justify-center items-center p-2">
                <h1
                  className="font-serif text-center sm:text-[32px] md:text-[42px]"
                  data-aos="flip-up"
                >
                  Programa
                </h1>
                <br />
                <p
                  className=" text-center pt-[5vh] font-serif text-xl"
                  data-aos="flip-up"
                >
                  Hemos planeado un día lleno de diversión para disfrutar al
                  máximo.
                </p>
                <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
                  <div
                    className="flex flex-col justify-center items-center"
                    data-aos="fade-right"
                  >
                    {datosSeccionPrograma.ceremoniaReligiosa ? (
                      <>
                        <PiChurchLight className="text-[60px]" />
                        <br />
                        <p className="font-serif text-xl text-center">
                          CEREMONIA RELIGIOSA
                        </p>
                        <br />
                        <p className="font-serif text-xl text-center w-[60%]">
                          {datosSeccionPrograma.ceremoniaReligiosa
                            .split("\\n")
                            .map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                        <br />
                        <hr className="bg-gray-700 h-1 w-[60%]" />
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {datosSeccionPrograma.recepcion ? (
                      <>
                        <FaPhotoVideo className="text-[60px]" />
                        <br />
                        <p className="font-serif text-xl text-center">
                          RECEPCIÓN
                        </p>
                        <br />
                        <p className="font-serif text-xl text-center w-[60%]">
                          {datosSeccionPrograma.recepcion
                            .split("\\n")
                            .map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                        <br />
                        <hr className="bg-gray-700 h-1 w-[60%]" />
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {datosSeccionPrograma.coctel ? (
                      <>
                        <LiaStarSolid   className="text-[60px]" />
                        <br />
                        <p className="font-serif text-xl text-center">
                          PRECENTACIÓN QUINCEAÑERA
                        </p>
                        <br />
                        <p className="font-serif text-xl text-center w-[60%]">
                          {datosSeccionPrograma.coctel
                            .split("\\n")
                            .map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                        <br />
                        <hr className="bg-gray-700 h-1 w-[60%]" />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className="flex flex-col justify-center items-center"
                    data-aos="fade-right"
                  >
                    {datosSeccionPrograma.cena ? (
                      <>
                        <ImSpoonKnife className="text-[60px]" />
                        <br />
                        <p className="font-serif text-xl text-center">CENA</p>
                        <br />
                        <p className="font-serif text-xl text-center w-[60%]">
                          {datosSeccionPrograma.cena
                            .split("\\n")
                            .map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                        <br />
                        <br />
                        <hr className="bg-gray-700 h-1 w-[60%]" />
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {datosSeccionPrograma.baileXv ? (
                      <>
                        <GiPartyPopper className="text-[60px]" />
                        <br />
                        <p className="font-serif text-xl text-center">
                          BAILE DE XV AÑOS
                        </p>
                        <br />
                        <p className="font-serif text-xl text-center w-[60%]">
                          {datosSeccionPrograma.baileXv
                            .split("\\n")
                            .map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                        <br />
                        <hr className="bg-gray-700 h-1 w-[60%]" />
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {datosSeccionPrograma.baile ? (
                      <>
                        <GiMeshBall className="text-[60px]" />
                        <br />
                        <p className="font-serif text-xl text-center">BAILE</p>
                        <br />
                        <p className="font-serif text-xl text-center w-[60%]">
                          {datosSeccionPrograma.baile
                            .split("\\n")
                            .map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                        <br />
                        <hr className="bg-gray-700 h-1 w-[60%]" />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {vestimenta ? (
            <VestimentaXvComponent
              peltaColores={peltaColores}
              datosSeccionVestimenta={datosSeccionVestimenta}
            />
          ) : (
            ""
          )}

          {hospedaje ? (
            <HospedajeXvComponent
              peltaColores={peltaColores}
              datosSeccionHospedaje={datosSeccionHospedaje}
            />
          ) : (
            ""
          )}

          {Regalos ? (
            <RegalosXvComponent
              peltaColores={peltaColores}
              datosSeccionRegalos={datosSeccionRegalos}
            />
          ) : (
            ""
          )}

          {confirmacion ? (
            <ConfirmacionXvComponent
              peltaColores={peltaColores}
              images={seccionImagenes}
              messageWhats={messageWhats}
              datosSeccionConfirmacion={datosSeccionConfirmacion}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
