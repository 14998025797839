import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./loveable.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const XVCarolina = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'a los XV',
      nombre: "Carolina",
      fechaDeBoda: "22 JUNIO, 2024",
      fechaNewDate: "JUNE 22, 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/Z5FvvtS6/1713200447539.jpg",
      numeroNovia: "3515097742",
      numeroNovio: "",
    };
  
    const peltaColores = {
      color1: "#e5fff1",
      color2: "#ffffd9",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Carolina!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "Hoy en este día tan especial , primero que todo le doy las gracias a Dios por haberme permitido estar aquí Y a mis padres por darme el apoyo para realizar el sueño de toda quinceañera. Hoy dejaré de ser una niña para comenzar una nueva etapa de mi vida y te invito a que compartas este día tan especial para mi.",
      fechaNewDate: "June 22, 2024 12:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/fyNhpXXG/IMG-20240226-WA0034.jpg",
      fotoDerecha: " https://i.postimg.cc/bNnt8SVC/1713200447562.jpg"
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Rosario Pedroza Alvarado",
      esposaPadre: "Guillermo Sánchez Sánchez",
      esposoMadre: "Alejandra Elizabeth Aviña Manzo",
      esposoPadre: "Francisco Sánchez Sánchez",
    };
  
    const datosSeccion4 = {
        ceremoniaFecha: "Sábado, 22 de Junio de 2024.",
        ceremoniaHora: "12:00 p.m.",
        ceremoniaNombreLugar: "Templo la medalla Milagrosa",
        ceremoniaLugarMap:
          "546 Francisco I. Madero Sur, Zamora de Hidalgo, Michoacán",
        ceremoniaUrl:
          "https://maps.app.goo.gl/NZ8u52HXNSrW34n67",
        recepcionFecha: "Sábado, 22 de Jumio de 2024.",
        recepcionHora: "2:00 p.m.",
        recepcionNombreLugar: "Quinta el Jardín de María",
        recepcionLugarMap:
          "Circunvalación 4, Centro, 59675 Zamora de Hidalgo, Mich.",
        recepcionUrl:
          "https://maps.app.goo.gl/rmnbAAQX9xgTsSiY7",
      };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "6:00 pm ceremonia religiosa ",
      baileXV: "8:30 pm Baile de XV años",
      cena: "9:30 Disfruta de una deliciosa cena",
      recepcion: "8:00 pm recepción ",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "10:00 pm Sácale brilló a la pista 💃🏻🕺🏽",
    };
  
  
    const datosSeccion7 = {
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    };
  
    const datosSeccion8 = {
      descripcion:
        "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirme escoger mi regalo.",
      linkAmazon:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      linkLiverpool:
        "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    };
  
  
    const images = [
      "https://i.postimg.cc/Z5FvvtS6/1713200447539.jpg",
      "https://i.postimg.cc/5t4Yw74T/1713200447588.jpg",
      "https://i.postimg.cc/j5NWHLw3/IMG-20240415-WA0004.jpg",
      "https://i.postimg.cc/sgRZ2ftV/1713200447516.jpg",
    
      // Add more image URLs as needed
    ];
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")'
    }
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
        <Padres
          peltaColores={peltaColores}
          datosSeccion3={datosSeccion3}
        />
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
