import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./assets/MujerAdmirable.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";

export const CumpleAurora = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'los 89 años de Aurora',
      nombre: "AURORA SABAS DENIZ",
      fechaDeBoda: "21 ABRIL, 2024",
      fechaNewDate: "April 21, 2024 12:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/cJQ8wTzq/2.jpg",
      numeroNovia: "3121122252",
      numeroNovio: "4491516931",
      titulo:'NO FALTES A MI CUMPLEAÑOS'
    };
  
    const peltaColores = {
      color1: "#e7e3e8",
      color2: "#f4f8f2",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Monserrat Lira!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Cumplo 89 años!',
      descipcion:(
        <div>
            AGRADEZCO A DIOS LA OPORTUNIDAD DE CUMPLIR 89 AÑOS DE VIDA <br /><br />
ES UN HONOR QUE NOS ACOMPAÑES EL DOMINGO 21 DE ABRIL DE
2024 
        </div>
      ),
      fechaNewDate: "April 21, 2024 12:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/RF4JRKrW/3.jpg",
      fotoDerecha: "https://i.postimg.cc/N0hHKZp7/5.jpg",
    };
  
    const datosSeccion3 = {
      titulo: "¡Celebra mis 89 años, con apoyo de mis hermosos hijos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Mis hijos',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Magdalena Vázquez Prieto",
      esposaPadre: "Adolfo Lira González",
      esposoMadre: "Rosio Garibay Cervantes",
      esposoPadre: "Juan Carlos Lira Vázquez",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "Domingo, 21 de Abril de 2024.",
      ceremoniaHora: "12:00 p.m.",
      ceremoniaNombreLugar: "TEMPLO DE SAN JOSE OBRERO",
      ceremoniaLugarMap:
        "49873 Pihuamo, Jal.",
      ceremoniaUrl:
        "https://maps.app.goo.gl/xJcs6uPNtaq1JZA4A",
      recepcionFecha: "Domingo, 21 de Abril de 2024.",
      recepcionHora: "12:00 p.m. despues de la Ceremonia",
      recepcionNombreLugar: "LOPEZ MATEOS 7",
      recepcionLugarMap:
        "",
      recepcionUrl:
        "https://maps.app.goo.gl/xJcs6uPNtaq1JZA4A",
    };
  
    const images = [
      "https://i.postimg.cc/ydjgrxBv/4.jpg",
      "https://i.postimg.cc/RF4JRKrW/3.jpg",
"https://i.postimg.cc/cJQ8wTzq/2.jpg",
      "https://i.postimg.cc/N0hHKZp7/5.jpg",
      // Add more image URLs as needed
    ];
  
    const [messageWhats, setMessageWhats] = useState("");
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
        {/* <ModalXv 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        /> */}
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color1,
          color:datosSeccion3.textColor ? datosSeccion3.textColor : '' 
        }}
        className="sm:h-[130vh] md:h-[100vh] p-2 flex flex-col items-center w-full"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            {datosSeccion3.titulo}
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
            {datosSeccion3.descipcion}
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-1 w-[80%] pt-[10vh]">
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <p className="font-semibold text-center text-2xl">{datosSeccion3.tituloIzquierda}</p>
              <br />
              <p className="font-serif text-center text-xl">
              NATY
              </p>
              <p className="font-serif text-center text-xl">&</p>
              <p className="font-serif text-center text-xl">
              ISAIAS
              </p>
              <p className="font-serif text-center text-xl">&</p>
              <p className="font-serif text-center text-xl">
              SALVADOR
              </p>
              <p className="font-serif text-center text-xl">&</p>
              <p className="font-serif text-center text-xl">
              LUIS ENRIQUE
              </p>

              
              
            </div>
          </div>
        </div>
      </div>
  
        <Ceremonia
          peltaColores={peltaColores}
          datosSeccion4={datosSeccion4}
        />
  
        <ConfirmacionGeneral
          peltaColores={peltaColores}
          images={images}
          datosSeccion1={datosSeccion1}
        />
  
      </div>
    );
}
