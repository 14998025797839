import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { FaPhotoVideo } from "react-icons/fa";
import { GiMeshBall } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { useLocation } from "react-router-dom";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ModalXv from "../../../components/Modals/ModalXv";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ContadorXvComponent } from "../../../components/ComponentsXv/ContadorXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { PadresXvComponent } from "../../../components/ComponentsXv/PadresXvComponent";
import ModalXvCard from "../../../components/Modals/ModalXvCard";

export const XvJoseAlberto = () => {
    const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameId = searchParams.get("id");

  const [NameInvitation, setNameInvitation] = useState("");
  const [numGuess, setNumGuess] = useState("");
  const [messageWhats, setMessageWhats] = useState("");

  const peltaColores = {
    color1: "#447ba6",
    color2: "#2d6666",
  };

  const datosSeccionPortada = {
    cancion: "https://www.cjoint.com/doc/24_05/NEiez34E3np_Y2meta.app---Voices---LiQWYD-MUSICA-De-Fondo-Para-Videos-Sin-Copyright-128-kbps-.mp3",
    imgPortada:
      "https://i.postimg.cc/xTYz9XT4/Blue-and-Golden-Illustrated-10-Anniversary-Instagram-Post-2.png",
    nombre: "José Alberto",
    fechaDeBoda: "24 MAYO, 2024",
    titulo: "NO FALTES A MIS XV",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      "Espero puedan confirmarme su asistencia. \\nAgradezco de antemano su apreciable compañía.",
    aviso: "",
    numeroConfirmacion: "+526442103507",
    textoConfirmacion: "a los XV",
    textWhatsApp: "",
    textColor: "",
    imgFondo: "",
    textColor: "#fff",
  };

  const datosSeccionContador = {
    titulo: "¡Mis XV años!",
    descipcion:
      "Este día, Agradezco infinitamente a Dios Por concederme hace XV años el regalo de la vida. A mi Familia por apoyarme en todo momento. Doy inicio a nueva etapa, donde ya mi niñez se queda en un baúl de Buenos recuerdos. Te invito a ser participe de esta celebración, me gustaría contar con tu presencia. \\nGracias",
    fechaNewDate: "May 24, 2024 20:30:00 GMT+00:00",
    fotoIzquierda:
      "https://i.postimg.cc/P5pZRmFB/Historia-de-Instagram-Felicitaci-n-de-Cumplea-os-Moderno-Azul-1.png",
    fotoDerecha:
      "https://i.postimg.cc/4x64yyKY/Historia-de-instagram-animada-de-cumplea-os-50-a-os-negra-1.png",
    marcoEnable: true,
    imgFondo: "",
    textColor: "#ffff",
    contador: true,
  };

  const datosSeccionPadres = {
    titulo: "¡Celebra el amor, con apoyo mi madre!",
    descipcion:
      "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
    tituloIzquierda: "Madre del Quinceañero",
    tituloDerecha: "",
    madre: "Emma Lidia Reyes",
    padre: "",
    madrina: "",
    padrino: "",
    imgFondo: "",
    textColor: "#ffff",
  };

  const datosSeccionCeremonia = {
    dobleEnable: false,
    ceremoniaEnable: false,
    ceremoniaFecha: "Sábado, 23 de Diciembre de 2024.",
    ceremoniaHora: "6:00 p.m.",
    ceremoniaNombreLugar: "Parroquia del Divino Salvador",
    ceremoniaLugarMap:
      "Av. Niños Heroes 120, Trojes de Alonso, 20908 Aguascalientes, Ags.",
    ceremoniaUrl:
      "https://www.google.com/maps/place/Parroquia+del+Divino+Salvador/@21.9378831,-102.2966837,17z/data=!3m1!4b1!4m6!3m5!1s0x8429efa1a0aac581:0x2630401c2c5a339e!8m2!3d21.9378831!4d-102.2966837!16s%2Fg%2F11cky7dqfw?entry=ttu",
    recepcionEnable: true,
    recepcionFecha: "Viernes, 24 de Mayo de 2024.",
    recepcionHora: "9:00 p.m.",
    recepcionNombreLugar: "Jardin Colonial, Salón",
    recepcionLugarMap:
      "Blvd. De la Lluvia 539, entre boulevard casa blanca y pedregal, Casa Blanca, 85134 Cd. Obregón, Son.",
    recepcionUrl:
      "https://maps.app.goo.gl/bz7ptuh3xU8jqDK46",
    imgFondo: "",
    textColor: "#ffff",
  };

  const datosSeccionVestimenta = {
    vestimenta: "",
    messageVestimenta: "",
    urlVestimentaMujer: "",
    urlVestimentaHombre: "",
    imgFondo:
      "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
    textColor: "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: "",
    recepcion: "Tómate fotos con nosotros",
    baileXV: "",
    cena: "Disfruta de una deliciosa cena",
    coctel: "Disfruta de un refrescante cóctel",
    baile: "Sacale brillo a la pista",
    textColor:"#ffff"
  };

  const datosSeccionHospedaje = {
    disponibleDos: false,
    descripcion:
      "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
    hotel1Titulo: "LAS TROJES",
    hotel1lugar:
      "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
    hotel1url:
      "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
    hotel2Titulo: "MARRIOTT HOTEL",
    hotel2lugar:
      "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
    hotel2url:
      "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    imgFondo: "",
    textColor: "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: 1,
    titulo: "Mesa de regalos",
    descripcion:
      "Un regalo nunca será mejor que tu grata compañia en este momento tan importante, sin embargo si deseas hacerme llegar un presente, podría ser lluvia de sobres.",
    link1:
      "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
    imgLink1: "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
    link1Enable: false,
    link2: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink2: "",
    link2Enable: true,
    link3: "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    imgLink3:
      "https://logowik.com/content/uploads/images/sears-mexico1493.logowik.com.webp",
    link3Enable: false,
    imgFondo: "",
    textColor: "#ffff",
  };

  const datosSeccionInvitados ={
    imgFondo:"https://i.postimg.cc/wj6y95hB/Historia-de-Instagram-Felicitaci-n-de-Cumplea-os-Moderno-Azul.png",
    titulo: `¡Has sido invitado a los XV años de ${datosSeccionPortada.nombre}!`,
    entradasText: "disfrutar mis XV años",
    colorFondoName:"#2d6666",
    imgInvitados:"https://png.pngtree.com/png-clipart/20221124/ourmid/pngtree-wedding-ornament-line-png-image_6479353.png"
  }

  const images = [
    
    // Add more image URLs as needed
  ];

  const contador = true;
  const padres = true;
  const ceremonia = true;
  const vestimenta = true;
  const programa = true;
  const hospedaje = false;
  const confirmacion = true;
  const Regalos = true;
  const modal = true;

  const names = [
    { name: "Sr. Juan Reyes", guess: "3", id: 1 },
    { name: "Sra. Guadalupe Reyes", guess: "3", id: 2 },
    { name: "Fam. Rojas", guess: "5", id: 3 },
    { name: "Srita. Regina", guess: "4", id: 4 },
    { name: "Sr. Maximiliano Pacheco", guess: "3", id: 5 },
    { name: "Fam.Islas Duarte", guess: "5", id: 6 },
    { name: "Fam. Soto Bojórquez", guess: "4", id: 7 },
    { name: "Sra. Mayra Soto", guess: "3", id: 8 },
    { name: "Fam. Rodríguez Pérez", guess: "3", id: 9 },
    { name: "Sra. Lourdes Navarro", guess: "3", id: 10 },
    { name: "Fam. Bojórquez Granillo", guess: "4", id: 11 },
    { name: "Sra. Alma Félix", guess: "2", id: 12 },
    { name: "Sra. Luz Ma. Otero", guess: "2", id: 13 },
    { name: "Sra. Amparo", guess: "2", id: 14 },
    { name: "Sr. Ariel Rubio", guess: "2", id: 15 },
    { name: "Sr. Omar Gómez", guess: "2", id: 16 },
    { name: "Sra. Suggey Gil", guess: "2", id: 17 },
    { name: "Sra. Martha Campoy", guess: "2", id: 18 },
    { name: "Sra. Lirio Cruz", guess: "2", id: 19 },
    { name: "Sr. Ricardo Parra", guess: "2", id: 20 },
    { name: "Sr. Rene Romero", guess: "2", id: 21 },
    { name: "Sra. Marcela López", guess: "2", id: 22 },
    { name: "Sra.Karina Méndez", guess: "2", id: 23 },
    { name: "Sr. Luis Zazueta", guess: "2", id: 24 },
    { name: "Sra. Yolanda Reyes", guess: "2", id: 25 },
    { name: "Sr. Humberto Acosta", guess: "2", id: 26 },
    { name: "Sr. Rogelio Reyes", guess: "2", id: 27 },
    { name: "Sra. Iveth Alvárez", guess: "3", id: 28 },
    { name: "Sra. Patricia Cabrera", guess: "4", id: 29 },
    { name: "Sra. Teresa Verdiales", guess: "4", id: 30 },
    { name: "Sra. Mabel Ibarra", guess: "4", id: 31 },
    { name: "Sr. Josias Valenzuela", guess: "2", id: 32 },
    { name: "Sr. Jesús Carrillo", guess: "2", id: 33 },
    { name: "Sra. Patricia Rodelo", guess: "6", id: 34 },
    { name: "Sr. Rene Almada", guess: "2", id: 35 },
    { name: "Sra. Claudia Muñoz", guess: "2", id: 36 },
    { name: "Srita.Andrea Torres", guess: "1", id: 37 },
    { name: "Sr. Carlos Rodríguez", guess: "3", id: 38 },
    { name: "Sra. Many Romero", guess: "2", id: 39 },
    { name: "Sra. Irma Romero", guess: "2", id: 40 },
    { name: "Sra. Josefina Segovia", guess: "2", id: 41 },
    { name: "Sra . Paula Soto", guess: "2", id: 42 },
    { name: "Dra. Reyna", guess: "2", id: 43 },
    { name: "Sra. Adhara", guess: "2", id: 44 },
    { name: "Sra. Bianka García", guess: "3", id: 45 },
    { name: "Sra. Devora Huicoy", guess: "2", id: 46 },
    { name: "Sra. Guadalupe Valenzuela", guess: "2", id: 47 },
    { name: "Srita. Gloria Burboa", guess: "1", id: 48 },
    { name: "Sra. Nancy Servin", guess: "4", id: 49 },
    { name: "Sra. Suggey Reyes", guess: "2", id: 50 },
    { name: "Sra. Imelda Islas", guess: "2", id: 51 },
    { name: "Sr. Iván Castro", guess: "3", id: 52 },
    { name: "Sr. Enrique Cañez", guess: "1", id: 53 },
    { name: "Sra. Elizabeth Sánchez", guess: "1", id: 54 },
    { name: "Sra. Karina Ramírez", guess: "2", id: 55 },
    { name: "Sr. Ramón Moralez", guess: "2", id: 56 },
    { name: "Sra. Arely Valdez", guess: "2", id: 57 },
    { name: "Dr. Gómez", guess: "2", id: 58 },
    { name: "Sra. Griselda Varela", guess: "2", id: 59 },
    { name: "Sra. Esperanza Zamorano", guess: "2", id: 60 },
    { name: "Sra. Perla Román", guess: "2", id: 61 },
    { name: "Sra. Griselda Ramírez", guess: "2", id: 62 },
    { name: "Sr. Mercelino Lamas", guess: "2", id: 63 },
    { name: "Sr. Cipriano Balderrama", guess: "2", id: 64 },
    { name: "Sr. Alfredo Aragón", guess: "2", id: 65 },
    { name: "Sra. Clara Cosme", guess: "2", id: 66 },
    { name: "Srita. Yessenia Chávez", guess: "2", id: 67 },
    { name: "Sr. José Tejeda", guess: "1", id: 68 },
    { name: "Sr. Aldahir Moreno", guess: "4", id: 69 },
    { name: "Sr. Ezau Escalante", guess: "3", id: 70 },
    { name: "Sra.Marielos Espinoza", guess: "2", id: 71 },
    { name: "Sr. Hugo Fraijo", guess: "1", id: 72 },
    { name: "Sra. Belem Álvarez", guess: "2", id: 73 },
    { name: "Sr. JoséTapia", guess: "2", id: 74 },
    { name: "Sra. Marcela Campa", guess: "4", id: 75 },
    { name: "Sra. Marisa Campa", guess: "3", id: 76 },
    { name: "Sr. José Navarrete", guess: "2", id: 77 },
    { name: "Sra. Julia Galavíz", guess: "3", id: 78 },
    { name: "Sra. Balbaneda Arias", guess: "4", id: 79 },
    { name: "Sr. Alan Hernández", guess: "2", id: 80 },
    { name: "Sr. Ramiro Rendon", guess: "2", id: 81 }
];


  useEffect(() => {
    const findName = names.find((e) => e.id == nameId);
    console.log(findName?.name);

    if (findName) {
      setNameInvitation(findName.name);
      setNumGuess(findName.guess);
      const massageWhatss = `Hola, soy ${findName.name} y confirmo mi asistencia a los XV años con ${findName.guess} invitado(s). ¡Nos vemos allí!`;
      setMessageWhats(massageWhatss);
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
       {
        modal? (
            <ModalXvCard isOpen={isOpen} setIsOpen={setIsOpen} datosSeccionPortada={datosSeccionPortada}/>
        ):("")
      }
      <PortadaXvComponent datosSeccionPortada={datosSeccionPortada} />
      {contador ? (
        <ContadorXvComponent
          peltaColores={peltaColores}
          datosSeccionContador={datosSeccionContador}
        />
      ) : (
        ""
      )}

      <InvitadosXvComponent
        NameInvitation={NameInvitation}
        numGuess={numGuess}
        peltaColores={peltaColores}
        datosSeccionInvitados={datosSeccionInvitados}
      />

      {padres ? (
        <PadresXvComponent
          peltaColores={peltaColores}
          datosSeccionPadres={datosSeccionPadres}
        />
      ) : (
        ""
      )}

      {ceremonia ? (
        <CeremoniaXvComponent
          peltaColores={peltaColores}
          datosSeccionCeremonia={datosSeccionCeremonia}
        />
      ) : (
        ""
      )}
      {vestimenta ? (
        <VestimentaXvComponent
          peltaColores={peltaColores}
          datosSeccionVestimenta={datosSeccionVestimenta}
        />
      ) : (
        ""
      )}

      {programa ? (
        <ProgramaXvComponent
          peltaColores={peltaColores}
          datosSeccionPrograma={datosSeccionPrograma}
        />
      ) : (
        ""
      )}

      {hospedaje ? (
        <HospedajeXvComponent
          peltaColores={peltaColores}
          datosSeccionHospedaje={datosSeccionHospedaje}
        />
      ) : (
        ""
      )}

      {Regalos ? (
        <RegalosXvComponent
          peltaColores={peltaColores}
          datosSeccionRegalos={datosSeccionRegalos}
        />
      ) : (
        ""
      )}

      {confirmacion ? (
        <ConfirmacionDiamXvComponent
        peltaColores={peltaColores}
        images={images}
        messageWhats={messageWhats}
        datosSeccionConfirmacion={datosSeccionConfirmacion}
        />
      ) : (
        ""
      )}
    </div>
  );
}
