import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { PiChurchLight } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import audioFondo from "./fluorescentadolescent.mp3";
import { Portada } from "../../../components/Portada";
import { Contador } from "../../../components/Contador";
import { Invitados } from "../../../components/Invitados";
import { Padres } from "../../../components/Padres";
import { Ceremonia } from "../../../components/Ceremonia";
import { Vestimenta } from "../../../components/Vestimenta";
import { Programa } from "../../../components/Programa";
import { Hospedaje } from "../../../components/Hospedaje";
import { Regalos } from "../../../components/Regalos";
import { Confirmacion } from "../../../components/Confirmacion";
import ModalXv from "../../../components/Modals/ModalXv";
import { FaPhotoVideo, FaWhatsapp } from "react-icons/fa";
import { GiMeshBall, GiPartyPopper } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { ConfirmacionGeneral } from "../../../components/ConfirmacionGeneral";
import { FaChampagneGlasses } from "react-icons/fa6";
import Slider from "react-slick";

export const XVBibiana = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameId = searchParams.get("name");
    const numPases = searchParams.get("pases");
  
    const datosSeccion1 = {
      textoConfirmacion:'los XV',
      nombre: "Bibiana",
      fechaDeBoda: "17 MAYO, 2024",
      fechaNewDate: "May 17, 2024 18:00:00 GMT+00:00",
      imgPortada: "https://i.postimg.cc/MTNGkmCp/image1-1.jpg",
      numeroNovia: "2215315145",
      numeroNovio: "",
      textWhatsApp:"CONFIRMA TU ASISTENCIA",
      tituloConfirmacion:"Confirma tu asistencia. Agradezco tu apreciable compañía."
    };
  
    const peltaColores = {
      color1: "#ebebeb",
      color2: "#f8f2f2",
    };
  
  
    const datosInvitados ={
      titulo:'¡Has sido invitado a los XV años de Monserrat Lira!',
      entradasText:'disfrutar mis XV años'
    }
  
    const datosSeccion2 = {
      titulo:'¡Mis XV años!',
      descipcion:
        "",
      fechaNewDate: "May 17, 2024 18:00:00 GMT+00:00",
      fotoIzquierda: "https://i.postimg.cc/4dm3FSF0/image3-1.jpg ",
      fotoDerecha: "https://i.postimg.cc/5t95jf6F/image0-1.jpg ",
      marco:"none"
    };
  
    const datosSeccion3 ={
      titulo: "¡Celebra el amor, con apoyo de nuestros padres y padrinos!",
      descipcion:
        "Únete a nosotros para ser testigo de mi gran fiesta inolvidable.",
        tituloIzquierda:'Padres de la Quinceañera',
        tituloDerecha:'Padrinos de la Quinceañera',
      esposaMadre: "Magdalena Vázquez Prieto",
      esposaPadre: "Adolfo Lira González",
      esposoMadre: "Rosio Garibay Cervantes",
      esposoPadre: "Juan Carlos Lira Vázquez",
    };
  
    const datosSeccion4 = {
      ceremoniaFecha: "",
      ceremoniaHora: "",
      ceremoniaNombreLugar: "",
      ceremoniaLugarMap:
        "",
      ceremoniaUrl:
        "",
      recepcionFecha: "Viernes, 17 de Mayo de 2024.",
      recepcionHora: "6:00 p.m.",
      recepcionNombreLugar: "Jardín Angelopolis",
      recepcionLugarMap:
        "Hnos. Serdán 310, Reserva Territorial Atlixcáyotl, San Miguel la Rosa, 72190 Heroica Puebla de Zaragoza, Pue.",
      recepcionUrl:
        "https://maps.app.goo.gl/mu5wU9fWXPq9WELC9",
    };
  
    const datosSeccion6 = {
      ceremoniaReligiosa: "6:00 pm ceremonia religiosa ",
      baileXV: "8:30 pm Baile de XV años",
      cena: "9:30 Disfruta de una deliciosa cena",
      recepcion: "8:00 pm recepción ",
      coctel: "Disfruta de un refrescante cóctel",
      baile: "10:00 pm Sácale brilló a la pista 💃🏻🕺🏽",
    };
  
  
    const datosSeccion7 = {
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "LAS TROJES",
      hotel1lugar:
        "792, Las Trojes, Blvd. Luis Donaldo Colosio Murrieta, 20110 Aguascalientes",
      hotel1url:
        "https://www.google.com.mx/maps/place/Hotel+Las+Trojes/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ee65efefb587:0x66e679041b0f11e7!5m2!4m1!1i2!8m2!3d21.9263889!4d-102.2927778!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fg%2F1thz1_48?entry=ttu",
      hotel2Titulo: "MARRIOTT HOTEL",
      hotel2lugar:
        "Boulevard Zacatecas Norte S/N, Col, Trojes De Alonso, 20116 Aguascalientes, Ags.",
      hotel2url:
        "https://www.google.com.mx/maps/place/Aguascalientes+Marriott+Hotel/@21.9263889,-102.2979276,16z/data=!4m19!1m8!2m7!1shotel!5m4!5m2!4m1!1i2!16i1037!6e3!3m9!1s0x8429ef0b92240f29:0x461392a0274e4dc4!5m2!4m1!1i2!8m2!3d21.9270674!4d-102.2904932!15sCgVob3RlbJIBBWhvdGVs4AEA!16s%2Fm%2F0z5mvsc?entry=ttu",
    };
  
    const datosSeccion8 = {
      descripcion:
        "Lluvia de sobres. En este día tan especial el mejor regalo es tu compañía , sin embargo si deseas obsequiarme algo me encantaría fuera efectivo . Gracias por permitirme escoger mi regalo.",
      linkAmazon:
        "https://www.amazon.com.mx/hz/wishlist/ls/RQQLVOTP1UDO?ref_=wl_share",
      linkLiverpool:
        "http://mesaderegalos.liverpool.com.mx/milistaderegalos/51377410",
    };
  
  
    const images = [
      "https://i.postimg.cc/5yz2fFJq/image2-1.jpg",
      "https://i.postimg.cc/tgrTkjDP/image4.jpg",
      "https://i.postimg.cc/MTNGkmCp/image1-1.jpg",
      "https://i.postimg.cc/4dm3FSF0/image3-1.jpg",
      "https://i.postimg.cc/5t95jf6F/image0-1.jpg"
      // Add more image URLs as needed
    ];
  
    const imagenesFondo ={
      fondoInvitados:'url("https://i.postimg.cc/tCL56dpJ/aae520e4-5eb9-49a1-b390-1872cef66579.jpg")',
      fondoVestimenta:'url("https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww")',
      vestimenta:"CASUAL: NEGRO Y ROSA"
    }
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      cssEase: "linear"
    };

    const [name, setName] = useState("");
  const [guests, setGuests] = useState(0);
  const handleSubmit = (e) => {
      e.preventDefault();
  
      // Assuming you have some logic to format the message for WhatsApp
      const message = `Hola, soy ${name} y confirmo mi asistencia a ${datosSeccion1.textoConfirmacion}, numero de mi padre o madre es ${guests}. ¡Nos vemos allí!`;
  
      // Replace with the actual WhatsApp API URL and encode the message
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${datosSeccion1.numeroNovia}&text=${encodeURIComponent(
        message
      )}`;
  
      // Redirect to the WhatsApp URL
      window.location.href = whatsappUrl;
    };
  
    useEffect(() => {
  
      
      setIsOpen(true)
      AOS.init({ duration: 2000 });
    }, []);

    const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);
  
  
    return (
      <div className="w-full overflow-x-hidden bg-neutral-500">
       
        <Portada
        datosSeccion1 ={datosSeccion1}
        audioFondo = {audioFondo}
        />
  
        <Contador
          peltaColores = {peltaColores}
          datosSeccion2 = {datosSeccion2}
        />
  
  <div
        style={{
          // backgroundImage:
          //   'url("https://media.istockphoto.com/id/1335074792/es/foto/gypsophila-fondo-espacio-de-copia-colores-pastel-vista-superior-plantilla-de-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=yHx5ars6EocSFiiMVL_IBoOu_gae8-BXX_1v03eK2Yg=")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          backgroundPosition: "center center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: peltaColores.color2,
        }}
        className="sm:h-auto md:h-[100vh] flex flex-col items-center w-full px-2 py-5"
      >
        {/* =========================================================================================================================================== */}
        <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-3 w-[80%] pt-[10vh]">
            <div></div>

            <div
              className="flex flex-col justify-center items-center  md:pt-0"
              data-aos="fade-right"
            >
              <FaChampagneGlasses className="text-[80px]" />
              <br />
              <p className="font-serif text-2xl">Aquí es la fiesta</p>
              <br />
              <hr className="bg-amber-700 h-2 w-[80%]" />
              <br />
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionFecha}
              </p>
              <p className="font-serif text-xl text-center">
                {datosSeccion4.recepcionHora}
              </p>
              <br />
              <p className="font-serif text-xl">
                {datosSeccion4.recepcionNombreLugar}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]">
                {datosSeccion4.recepcionLugarMap}
              </p>
              <br />
              <a
                href={datosSeccion4.recepcionUrl}
                class="text-center overflow-hidden relative w-32 p-2 h-12 bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
              >
                Ver mapa!
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span class="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                  Explore!
                </span>
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>
  
      
      <div
        style={{
          backgroundColor: peltaColores.color2,
          backgroundAttachment: isIOS ? 'local' : 'fixed',
          
          color:peltaColores.textColor ? peltaColores.textColor : '' 
        }}
        className="sm:h-auto bg-img-fixed md:h-[100vh] w-full px-2 py-5"
      >
        <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-4">
          <h1
            className="font-serif text-center sm:text-[32px] md:text-[42px]"
            data-aos="flip-up"
          >
            Código de vestimenta
          </h1>
          <br />
          <p
            className=" text-center pt-[5vh] font-serif text-xl"
            data-aos="flip-up"
          >
             {imagenesFondo.vestimenta ? imagenesFondo.vestimenta :"FORMAL"}
          </p>
          <br />
          {
            peltaColores.messageVestimenta ? (
              <p className='text-center pt-[5vh] font-semibold text-xl w-[80%]'>{peltaColores.messageVestimenta} </p>
            ) : 
            ''
          }
          
          
        </div>
      </div>
  
      <div
    style={{
      display: "flex",
      backgroundPosition: "center center",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor:peltaColores.color2,
      color:datosSeccion1.textColor ? datosSeccion1.textColor : '' 
    }}
    className="sm:h-auto md:h-[150vh] flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full"
  >
    <h1
      className="font-serif text-center sm:text-[32px] w-[90%] md:text-[42px]"
      data-aos="flip-up"
    >
     Confirma tu asistencia. <br /> Agradezco tu apreciable compañía.
    </h1>
    <br />
    <p className='sm:text-[22px] text-center w-[70%] md:text-[30px]'>{datosSeccion1.aviso || ''}</p>
    <br />
    <br/>
    <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
      {images?.map((imageUrl, index) => (
        <div  data-aos="fade-down-right" className="rolImg" key={index}>
          <img
            src={imageUrl}
            alt={`Slide ${index + 1}`}
            className="w-auto rounded-xl max-h-[60vh]"
          />
        </div>
      ))}
    </Slider>
    <br />
    <br />
    <div data-aos="fade-down-left" className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl text-gray-700 font-bold mb-4 justify-center flex">
        {datosSeccion1.textWhatsApp || "Confirmación de Asistencia al Evento"}{" "}
        <FaWhatsapp className="text-green-600 ml-2" />
      </h2>
      <form onSubmit={handleSubmit}>
        <label className="mb-2 flex">
          <span className="text-gray-700">Nombre:</span>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
            required
          />
        </label>
        <br />
        <label className="flex mb-2">
          <span className="text-gray-700">Teléfono de Papá/Mamá:</span>
          <input
            type="number"
            value={guests}
            onChange={(e) => setGuests(e.target.value)}
            className="form-input ml-1 border-b-2 w-full focus:outline-none focus:border-green-500"
            min={1}
            required
          />
        </label>
        <br />
        <button
          type="submit"
          className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
        >
          Confirmar Asistencia
        </button>
      </form>
    </div>
  </div>
  
      </div>
    );
}
