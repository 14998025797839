import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import videoProceso from "../../../assets/proceso-compra.mp4";
import { FaArrowDown } from "react-icons/fa6";

// Example images - replace with actual paths
const headerImages = [
  "https://i.postimg.cc/vm64RVvN/processed-F06-DD24-A-19-AE-4955-9-A95-87-E55-A61-B7-AA.jpg",
  "https://digitalrsvp.mx/api/Images/11deb978-d2b8-4841-9549-d6632e58951c",
  "https://i.postimg.cc/6qz3VL9y/processed-D9-CCB483-842-C-4-C9-C-962-D-49-C384-AE84-FF.jpg",
  "https://i.postimg.cc/QNqHrsph/processed-91-FF5269-18-A0-406-E-8-C70-D255-E1-CFA6-EC.jpg",
];
const plataImage =
  "https://i.postimg.cc/vm64RVvN/processed-F06-DD24-A-19-AE-4955-9-A95-87-E55-A61-B7-AA.jpg";
const oroImage =
  "https://i.postimg.cc/xC3TYyNt/processed-8-B45-EF6-D-415-F-4-A5-E-A01-D-AD55-A76-F9-B36.jpg";
const diamanteImage =
  "https://i.postimg.cc/QNqHrsph/processed-91-FF5269-18-A0-406-E-8-C70-D255-E1-CFA6-EC.jpg";

const packages = [
  {
    name: "PAQUETE DIAMANTE",
    imageUrl: diamanteImage,
    designs: [
      {
        preciceDec: "2,500",
        precice: 895,
        name: "Ejemplo con Fotos",
        link: "/boda-diamante-ejemplo?name=Angel%20Jaimes",
        name2: "Ejemplos sin Fotos",
        link2: "/boda-diamante-ejemplo-fotos?name=Angel%20Jaimes",
      },
    ],
  },
  {
    name: "PAQUETE ORO",
    imageUrl: oroImage,
    designs: [
      {
        preciceDec: "1,200",
        precice: 595,
        name: "Ejemplo con Fotos",
        link: "/boda-oro-ejemplo",
        name2: "Ejemplos sin Fotos",
        link2: "/boda-oro-ejemplo-fotos",
      },
    ],
  },
  {
    name: "PAQUETE PLATA",
    imageUrl: plataImage,
    designs: [
      {
        preciceDec: 600,
        precice: 295,
        name: "Ejemplo con Fotos",
        link: "/boda-plata-ejemplo",
        name2: "Ejemplo sin Fotos",
        link2: "/boda-plata-ejemplo-fotos",
      },
    ],
  },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

export const EjemplosBoda = () => {
  return (
    <div className="relative w-full min-h-screen bg-gradient-to-b from-gray-100 to-gray-200"
    style={{
      backgroundImage: `url('https://digitalrsvp.mx/api/Images/ac65eeb8-5eb3-4223-9ddf-0e1292e1a9c6')`,
    }}>
      <div className="relative mx-auto overflow-x-hidden max-w-7xl">
        {/* Header Section */}
        <div className="w-full shadow-lg">
          <Slider {...sliderSettings}>
            {headerImages.map((image, index) => (
              <div key={index} className="relative h-[60vh] md:h-[70vh]">
                <img
                  src={image}
                  className="h-full w-full object-cover"
                  alt=""
                />
                <div className="absolute inset-0 bg-opacity-40 flex items-center justify-center">
                  <p className="text-2xl md:text-4xl font-serif text-white text-center px-4 py-2 bg-black bg-opacity-50 rounded-lg">
                    Aprovecha Nuestros Paquetes Exclusivos
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Packages Section */}
        <div className="w-full p-8 mx-auto mt-16 bg-white bg-opacity-90 rounded-lg shadow-xl">
          {packages.map((pkg, index) => (
            <div
              key={index}
              className="mb-20 flex flex-col justify-center items-center"
            >
              <h2 className="text-3xl font-serif md:text-4xl font-bold mb-8 text-center text-gray-800">
                {pkg.name}
              </h2>
              <div className="w-full md:flex md:items-center md:space-x-8">
                <img
                  src={pkg.imageUrl}
                  alt={`${pkg.name} package`}
                  className="w-full md:w-1/2 h-[50vh] mb-6 rounded-lg shadow-lg object-cover transition-transform duration-300 hover:scale-105"
                />
                <div className="md:w-1/2">
                  {pkg.designs.map((design, idx) => (
                    <div
                      key={idx}
                      className="p-6 text-center bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                    >
                      <h3 className="text-xl font-serif md:text-2xl font-semibold mb-4 text-gray-800">
                        Precio Especial
                      </h3>
                      <h4 className="text-lg md:text-xl font-semibold mb-6 text-gray-700">
                        <span className="text-red-500 mx-2 line-through">
                          ${design.preciceDec}
                        </span>
                        <span className="mx-2 text-green-600">${design.precice}</span>
                      </h4>
                      <div className="space-y-4">
                        <a
                          href={design.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block py-3 px-6 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-full hover:from-blue-600 hover:to-blue-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                        >
                          {design.name}
                        </a>
                        <a
                          href={design.link2}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block py-3 px-6 bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-full hover:from-purple-600 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                        >
                          {design.name2}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="p-8 w-full flex flex-col items-center bg-white bg-opacity-90 rounded-lg shadow-xl mt-16">
          <h1 className="text-3xl text-center font-serif mb-6 text-gray-800">Cómo adquirir tu invitación</h1>
          <FaArrowDown className="text-5xl text-blue-600 animate-bounce mb-8" />
          <div className="relative w-full md:hidden" style={{ paddingBottom: "150%" }}>
            <video
              className="absolute inset-0 w-full h-full object-contain md:object-cover rounded-lg shadow-lg"
              src={videoProceso}
              controls
              autoPlay
              loop
              muted
            />
          </div>
          <div className="hidden md:block relative w-3/4 lg:w-2/3 xl:w-1/2" style={{ paddingBottom: "42.1875%" }}>
            <video
              className="absolute inset-0 w-full h-full object-contain rounded-lg shadow-lg"
              src={videoProceso}
              controls
              autoPlay
              loop
              muted
            />
          </div>
        </div>
        <div className="h-16"></div> {/* Spacer */}
      </div>
    </div>
  );
};
